import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import isAfter from 'date-fns/isAfter';
import format from 'date-fns/format';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { trimStr } from 'utils';
import { useAlerts } from 'hooks';
import { Box, useMediaQuery, styled } from 'components';
import { useForm } from 'components/form';
import { Button, ButtonBase, CheckBox, DatePicker, Input, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import { UPDATE_USER_EXP, getOnboardingStatistics } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
const MAX_DATE = new Date();
const MIN_DATE = new Date(1900, 0, 1);

function Experience({ calledToasts, setCalledToasts, formKey, form, onChange, onGoToNextStep }) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { showToast } = useAlerts();

  const [postEmployeeExperience, { data = {}, error = {}, loading = false }] =
    useMutation(UPDATE_USER_EXP);

  const { set, get, usePartial, withValidation } = useForm({
    initial: {
      items: [
        {
          currentJob: false,
          name: '',
          pos: '',
          startDate: null,
          endDate: null
        }
      ]
    }
  });
  const formItems = get('items');

  useEffect(() => {
    showAnalyticToast();
  }, []);

  const showAnalyticToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      const info = (await getOnboardingStatistics(form.zip)) || {};
      if (info.randomPerson) {
        setCalledToasts([...calledToasts, formKey]);
        showToast({
          title: `${info.randomPerson} just got hired in your area! You can be next! 🙂`,
          icon: () => <MdExclamation />,
          flourishText: 'This could be you!',
          position: { top: false, right: false }
        });
      }
    }
  };

  const addItem = () => {
    const items = [...formItems];
    items.push({
      currentJob: false,
      name: '',
      pos: '',
      startDate: new Date(),
      endDate: new Date()
    });
    set({ items });
  };

  const next = withValidation(async (attrs) => {
    const experience = reduce(
      attrs.items,
      (result, val) => {
        const nextRes = [...result];
        const name = trimStr(val.name);
        const pos = trimStr(val.pos);
        const startDate = format(val.startDate, SERVER_DATE_FORMAT);
        const endDate = val.endDate ? format(val.endDate, SERVER_DATE_FORMAT) : val.endDate;

        if (!name && !pos) return nextRes;
        nextRes.push({ ...val, name, pos, startDate, endDate });
        return nextRes;
      },
      []
    ).filter(Boolean);

    if (experience.length) {
      await postEmployeeExperience({ variables: { experience } });
      onGoToNextStep();
    } else {
      onGoToNextStep();
    }
  });

  const skip = async (attrs) => {
    onGoToNextStep(undefined, { skipped: true });
  };

  return (
    <>
      {map(formItems, (item, i) => (
        <ItemForm key={i} usePartial={usePartial} index={i} />
      ))}
      <ButtonBase
        sx={{ mb: '30px', color: (theme) => theme.palette.primary.main }}
        onClick={addItem}
      >
        Add Another
      </ButtonBase>
      <div style={{ maxWidth: 320 }}>
        <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
          Look at me now!
        </p>
        <p className="secondaryText" style={{ marginBottom: 16 }}>
          Experience boosts your chances of getting a job by 45%, don’t worry if you don’t have any,
          we have tools to help you get some!
        </p>
      </div>
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        className="nextBtn"
        sx={{ mb: isDesktop ? '25px' : '13px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        className="nextBtn"
        sx={{ mb: isDesktop ? '25px' : '13px' }}
        onClick={skip}
        testID="onboarding-next-button"
      >
        No Experience Yet
      </Button>
      <PrivacyClauseButton />
    </>
  );
}

function ItemForm({ index, usePartial }) {
  const { $, attrs, set, getError } = usePartial({
    prefix: `items.${index}`,
    validations: {
      name: {
        rules: [{ presence: { allowEmpty: false } }],
        partialDeps: ['pos']
      },
      pos: {
        rules: [{ presence: { allowEmpty: false } }],
        partialDeps: ['name']
      },
      startDate: {
        rules: [
          'presence',
          {
            date: {
              earliest: MIN_DATE,
              latest: MAX_DATE,
              earliestFormat: 'yyyy',
              latestFormat: 'yyyy'
            }
          },
          (value, { attrs }) => {
            if (!value) return;
            const endDate = attrs.items?.[index]?.endDate;
            if (endDate && isAfter(value, endDate)) {
              return 'Must be less than or equal to end date';
            }
          }
        ],
        partialDeps: ['endDate', 'pos', 'name']
      },
      endDate: {
        rules: [
          {
            date: {
              earliest: MIN_DATE,
              latest: MAX_DATE,
              earliestFormat: 'yyyy',
              latestFormat: 'yyyy'
            }
          },
          (value, { attrs }) => {
            if (!value && attrs.items?.[index]?.currentJob) return;
            if (!value) return 'Cannot be blank';
          }
        ],
        partialDeps: ['currentJob', 'startDate']
      }
    }
  });

  const handleCurJobChange = () => {
    set({
      currentJob: !attrs.currentJob,
      endDate: null
    });
  };

  const handleValueChange = useCallback((e, { name }) => {
    set(name.split('.').pop(), e.target.value);
  }, []);

  const handleStartDateChange = useCallback((date) => {
    const startDate = date ? new Date(date.getFullYear(), date.getMonth(), 1) : date;
    set({ startDate });
  }, []);

  const handleEndDateChange = useCallback((date) => {
    const endDate = date ? new Date(date.getFullYear(), date.getMonth(), 1) : date;
    set({ endDate });
  }, []);

  const renderCurrentJobCheckbox = () => (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <span>Company*</span>
      <CheckBox
        checked={attrs.currentJob}
        label="Current Job"
        labelPlacement="start"
        sx={{
          '& .MuiCheckbox-root': { width: 25, height: 25 },
          '& .MuiFormControlLabel-label': {
            marginLeft: 0,
            marginRight: '15px',
            fontSize: 14
          }
        }}
        onChange={handleCurJobChange}
        testID={`current-job-${index}`}
      />
    </Box>
  );

  const commonPickerProps = {
    inputFormat: 'MM/yyyy',
    views: ['year', 'month'],
    mask: '__/____',
    disableMaskedInput: false, // fix broken mask for month/year view
    disableFuture: true
  };

  return (
    <div style={{ width: '100%', marginBottom: 40 }}>
      <Input
        {...$('name', handleValueChange)}
        variant="textfield"
        id={`work-name-input-${index}`}
        label={renderCurrentJobCheckbox()}
        inputProps={{ required: true }}
        FormControlProps={{
          sx: { marginBottom: '20px' }
        }}
        withHelperText
        analyticParams={{
          key: 'Employee working place focused (onboarding)',
          trigger: 'focus'
        }}
        testID={`work-position-input-${index}`}
      />
      <Input
        {...$('pos', handleValueChange)}
        variant="textfield"
        id={`work-position-input-${index}`}
        required
        label="Position"
        FormControlProps={{
          sx: { marginBottom: '20px' }
        }}
        withHelperText
        analyticParams={{
          key: 'Employee work position focused (onboarding)',
          trigger: 'focus'
        }}
        testID={`work-position-input-${index}`}
      />
      <div style={{ width: '100%', display: 'flex' }}>
        <DatePicker
          {...$('startDate')}
          label="Start Month"
          onChange={handleStartDateChange}
          {...commonPickerProps}
          InputComponentProps={{
            id: `work-start-input-${index}`,
            required: true,
            analyticParams: {
              key: 'Employee work start date focused (onboarding)',
              trigger: 'focus'
            },
            testID: `work-start-input-${index}`
          }}
        />
        <DatePicker
          {...$('endDate')}
          label="End Month"
          inputFormat="MM/yyyy"
          disabled={attrs.currentJob}
          disableOpenPicker={attrs.currentJob}
          onChange={handleEndDateChange}
          {...commonPickerProps}
          InputComponentProps={{
            id: `work-end-input-${index}`,
            required: !attrs.currentJob,
            FormControlProps: { sx: { marginLeft: '16px' } },
            analyticParams: {
              key: 'Employee work end date focused (onboarding)',
              trigger: 'focus'
            },
            testID: `work-end-input-${index}`
          }}
        />
      </div>
    </div>
  );
}

Experience.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const StyledExperience = styled(Experience)(styles);
// const StyledItemForm = styled(ItemForm)(styles);

export default StyledExperience;
