import { gql } from '@apollo/client';

export const APPROVE_EMPLOYEE = gql`
  mutation approveEmployee(
    $jobId: Int
    $userId: Int
    $approved: Boolean
    $passed: Boolean
    $starred: Boolean
    $message: String
  ) {
    approveEmployee(
      jobId: $jobId
      userId: $userId
      approved: $approved
      passed: $passed
      starred: $starred
      message: $message
    ) {
      approved
      jobsId
      passed
      starred
    }
  }
`;

export const SET_EMPLOYEE_NOTES = gql`
  mutation setEmployeeNotes($employeeProfileId: Int!, $employerProfileId: Int!, $notes: String) {
    setEmployeeNotes(
      employeeProfileId: $employeeProfileId
      employerProfileId: $employerProfileId
      notes: $notes
    ) {
      notes
    }
  }
`;

export const GET_EMPLOYEE_NOTES = gql`
  mutation getEmployeeNotes($employeeProfileId: Int!, $employerProfileId: Int!) {
    getEmployeeNotes(employeeProfileId: $employeeProfileId, employerProfileId: $employerProfileId) {
      notes
    }
  }
`;

export const GET_EMPLOYEE_NOTES_TO_JOB = gql`
  mutation getEmployeeNotesToJob($employeeProfileId: Int!, $jobId: Int!) {
    getEmployeeNotesToJob(employeeProfileId: $employeeProfileId, jobId: $jobId) {
      notes
    }
  }
`;

export const SEND_INVITE = gql`
  mutation sendInvite($id: Int!, $employeeUserId: Int!) {
    sendInvite(id: $id, employeeUserId: $employeeUserId) {
      id
    }
  }
`;
