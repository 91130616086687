import React, { forwardRef } from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { Box, FormHelperText, Select as MuiSelect, MenuItem, styled } from 'components';
import Input from '../Input';
import { MdExpandMore } from '../../icons';

const PREFIX = 'StyledSelect';
const classes = {
  select: `${PREFIX}-select`,
  selectIcon: `${PREFIX}-select__icon`,
  menuPaper: `${PREFIX}-menuPaper`,
  menuList: `${PREFIX}-menuList`,
  helperText: `${PREFIX}-helperText`
};

// eslint-disable-next-line react/prop-types
const SelectInput = forwardRef(({ inputVariant, ...props }, ref) => (
  <Input {...props} variant={inputVariant} ref={ref} />
));

function Select(props) {
  const {
    analyticParams,
    className,
    children,
    containerProps,
    emptyOption,
    error,
    hint,
    helperTextProps,
    id,
    inputProps,
    inputVariant,
    InputComponentProps,
    label,
    labelProps,
    options,
    required,
    testID,
    withEmptyOption,
    withHelperText,
    ...selectProps
  } = props;

  const isError = !!error;

  const labelId = id ? `${id}-label` : selectProps.labelId;
  const inputId = id ? `${id}-input` : selectProps.inputId;
  const helperId = id ? `${id}-helper` : selectProps.helperId;

  const menuProps = {
    disablePortal: true,
    MenuListProps: {
      variant: 'selectedMenu'
    },
    classes: {
      paper: classes.menuPaper,
      list: classes.menuList
    }
  };

  const renderOptions = () => {
    if (selectProps.native) {
      return map(options, ({ value: optionValue, label: optionLabel, ...rest }, i) => (
        <option key={`option__${i}`} value={optionValue} {...rest}>
          {optionLabel}
        </option>
      ));
    }
    return map(options, ({ value: optionValue, label: optionLabel, ...rest }, i) => (
      <MenuItem key={`option__${i}`} value={optionValue} {...rest}>
        {optionLabel}
      </MenuItem>
    ));
  };

  const renderEmptyOption = () =>
    selectProps.native ? (
      <option value="" aria-label="none" className="hidden" />
    ) : (
      <MenuItem value="" className="hidden" />
    );

  return (
    <Box className={className} {...containerProps}>
      <MuiSelect
        // variant="standard"
        id={id}
        labelId={labelId}
        required={required}
        input={
          <SelectInput
            label={label}
            inputVariant={inputVariant}
            inputProps={{ id: inputId, ...inputProps }}
            InputLabelProps={{ id: labelId, ...labelProps }}
            FormControlProps={{
              // required,
              error: isError,
              ...(InputComponentProps.FormControlProps || {})
            }}
            analyticParams={analyticParams}
            testID={testID}
            {...InputComponentProps}
          />
        }
        IconComponent={MdExpandMore}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon
        }}
        {...selectProps}
      >
        {withEmptyOption ? renderEmptyOption() : emptyOption}
        {options?.length ? renderOptions() : children}
      </MuiSelect>

      {withHelperText && (hint || error) && (
        <FormHelperText
          id={helperId}
          error={isError}
          className={classes.helperText}
          {...helperTextProps}
        >
          {hint || error}
        </FormHelperText>
      )}
    </Box>
  );
}

Select.propTypes = {
  analyticParams: PT.shape({
    trigger: PT.oneOf(['focus']),
    key: PT.string,
    params: PT.objectOf(PT.any)
  }),
  children: PT.node,
  className: PT.string,
  containerProps: PT.objectOf(PT.any),
  error: PT.string,
  emptyOption: PT.node,
  hint: PT.string,
  helperTextProps: PT.objectOf(PT.any),
  id: PT.oneOfType([PT.string, PT.number]),
  inputVariant: PT.string,
  InputComponentProps: PT.objectOf(PT.any),
  inputProps: PT.objectOf(PT.any),
  labelProps: PT.objectOf(PT.any),
  label: PT.oneOfType([PT.string, PT.node]),
  options: PT.arrayOf(
    PT.shape({
      label: PT.string,
      value: PT.oneOfType([PT.string, PT.number])
    })
  ),
  required: PT.bool,
  testID: PT.string,
  withEmptyOption: PT.bool,
  withHelperText: PT.bool
};

Select.defaultProps = {
  analyticParams: { key: '', trigger: 'focus' },
  children: null,
  className: '',
  containerProps: {},
  error: '',
  emptyOption: null,
  id: '',
  InputComponentProps: {},
  hint: '',
  helperTextProps: {},
  inputProps: {},
  inputVariant: 'textfield',
  labelProps: {},
  label: '',
  options: [],
  required: false,
  testID: '',
  withEmptyOption: false,
  withHelperText: false
};

const StyledSelect = styled(Select)(({ theme }) => ({
  [`& .${classes.select}`]: {
    '&.MuiSelect-select.StyledInput-inputBase__input': {
      minHeight: 'auto'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  [`& .${classes.selectIcon}`]: {
    right: 0,
    color: theme.palette.primary.main
  },
  [`& .${classes.helperText}`]: {
    marginTop: 6,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '17px'
  },
  [`& .${classes.menuPaper}`]: {
    display: 'flex'
  },
  [`& .${classes.menuList}`]: {
    minWidth: 300,
    overflow: 'auto',
    flex: 1,
    '& > option, li': {
      padding: '15px 10px',
      borderBottom: `1px solid ${theme.palette.moreColors.grey_2}`,
      cursor: 'pointer',
      transition: `.25s ease background-color`,
      '&.hidden': {
        display: 'none'
      },
      '&:hover': {
        backgroundColor: theme.palette.moreColors.grey_2
      }
    }
  }
}));

export default StyledSelect;
