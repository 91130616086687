import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import format from 'date-fns/format';
import { Dialog, styled } from 'components';
import styles from 'styles/Dashboard/EmployeeJobCard';
import Button, { IconButton } from '../Button';
import Spinner from '../Spinner';
import { MdArrowBack } from '../../icons';
import JobInterviewSchedule from './JobInterviewSchedule';
import useSchedule from './useSchedule';

const StyledDialog = styled(Dialog)(styles);

function EmployeeInterviewScheduleModal(props) {
  const {
    DialogProps,
    employerProfileName,
    employerProfileId,
    isOpen,
    jobId,
    jobTitle,
    onClose,
    onConfirm
  } = props;
  const {
    getSelectedInterviewDateLoading,
    handleInterviewConfirm,
    inactiveDates,
    isScheduleOpen,
    postInterviewLoading,
    setIsScheduleOpen,
    selectedInterviewDate,
    setInterviewDate
  } = useSchedule({ isOpen, jobId, role: 'employee' });
  const [interviewDate, employerScheduleId] = selectedInterviewDate;
  const [isInterviewConfirmation, setIsInterviewConfirmation] = useState(false);

  const handleInterviewDateSelect = useCallback((...params) => {
    const date = params[0];
    setInterviewDate([...params]);
    setIsInterviewConfirmation(!!date);
  }, []);

  const confirmNewInterviewDate = async (...params) => {
    const date = params[0];
    await handleInterviewConfirm(...params, () => {
      setIsInterviewConfirmation(true);
      setInterviewDate([null, '']);
      onConfirm(date, jobId);
    });
  };

  return (
    <StyledDialog
      open={isScheduleOpen}
      fullScreen
      onClose={onClose}
      classes={{ paperWidthSm: 'scheduleModal' }}
      {...DialogProps}
    >
      <div className="mobileScheduleModal__header">
        <IconButton
          color="primary"
          sx={{ width: 0, height: 0 }}
          onClick={onClose}
          testID="schedule-modal-close-button"
        >
          <MdArrowBack />
        </IconButton>
        <div style={{ width: '100%', paddingLeft: 6, paddingRight: 6 }}>
          <h2 className="mobileScheduleModal__title">Select Interview Time</h2>
          <div className="mobileScheduleModal__subTitle">{`${jobTitle} @ ${employerProfileName}`}</div>
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <JobInterviewSchedule
          employerProfileId={employerProfileId}
          inactiveDates={inactiveDates}
          selectedDate={selectedInterviewDate}
          qaId={jobTitle}
          withCancel={false}
          withConfirmation={false}
          onDateSelect={handleInterviewDateSelect}
        />
      </div>
      {isInterviewConfirmation && interviewDate && (
        <div
          style={{
            marginBottom: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div className="mobileScheduleModal__confirmTitle">Confirm interview on</div>
          <div className="mobileScheduleModal__confirmDate">
            {format(interviewDate, "EEEE, MMM do 'at' hh:mm a?")}
          </div>
          <Button
            variant="filled-primary"
            disabled={postInterviewLoading}
            endIcon={postInterviewLoading ? <Spinner size={24} /> : null}
            sx={{ width: 162, height: 46 }}
            onClick={() => confirmNewInterviewDate(interviewDate, employerScheduleId)}
            testID="schedule-modal-submit-button"
          >
            Go get it!
          </Button>
        </div>
      )}
    </StyledDialog>
  );
}

EmployeeInterviewScheduleModal.propTypes = {
  employerProfileName: PT.string.isRequired,
  employerProfileId: PT.number.isRequired,
  isOpen: PT.bool.isRequired,
  jobId: PT.number.isRequired,
  jobTitle: PT.string.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired
};

export default EmployeeInterviewScheduleModal;
