import React from 'react';
import { getDisplayName } from 'utils';
import { CommonUIProvider } from 'providers';

export default function withCommonUI(WrappedComponent) {
  function WithCommonUI(props) {
    return (
      <CommonUIProvider>
        <WrappedComponent {...props} />
      </CommonUIProvider>
    );
  }
  WithCommonUI.displayName = `WithCommonUI(${getDisplayName(WrappedComponent)})`;
  return WithCommonUI;
}
