import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, MuiCheckbox, styled } from 'components';
import { track } from 'utils/segmentAnalytics';
import { qaAttr } from 'utils';
import { MdCheck } from '../../icons';

const PREFIX = 'StyledCheckbox';
const classes = {
  checkbox: `${PREFIX}-checkbox`,
  formControl: `${PREFIX}-formControl`,
  label: `${PREFIX}-label`
};

function CheckBox(props) {
  const {
    analyticParams,
    className,
    formControlLabelStyles,
    checkboxStyles,
    checkboxProps,
    checkedIconProps = {},
    onFocus,
    testID,
    // variant,
    ...rest
  } = props;
  const { inputProps, ...restCheckboxProps } = checkboxProps;

  const handleFocus = (e) => {
    if (analyticParams && analyticParams.trigger === 'focus' && analyticParams.key) {
      track(analyticParams.key, analyticParams?.params);
    }
    const focusFunc = onFocus || inputProps?.onFocus;
    if (focusFunc) focusFunc(e);
  };

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          color="primary"
          icon={<svg style={{ display: 'none' }} />}
          checkedIcon={<MdCheck {...checkedIconProps} />}
          classes={{
            root: classes.checkbox
          }}
          inputProps={{
            onFocus: handleFocus,
            ...(testID && qaAttr(`checkbox-input-${testID}`)),
            ...inputProps
          }}
          {...restCheckboxProps}
        />
      }
      className={className}
      classes={{
        root: classes.formControl,
        label: classes.label
      }}
      {...(testID && qaAttr(`checkbox-${testID}`))}
      {...rest}
    />
  );
}

CheckBox.propTypes = {
  analyticParams: PropTypes.shape({
    trigger: PropTypes.oneOf(['focus']),
    key: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any)
  }),
  className: PropTypes.string,
  checkedIconProps: PropTypes.objectOf(PropTypes.any),
  formControlLabelStyles: PropTypes.shape({
    root: PropTypes.objectOf(PropTypes.any),
    label: PropTypes.objectOf(PropTypes.any)
  }),
  checkboxProps: PropTypes.objectOf(PropTypes.any),
  checkboxStyles: PropTypes.shape({
    checkbox__root: PropTypes.objectOf(PropTypes.any)
  }),
  onFocus: PropTypes.func,
  testID: PropTypes.string
};

CheckBox.defaultProps = {
  analyticParams: { key: '', trigger: 'focus' },
  className: '',
  checkedIconProps: {},
  formControlLabelStyles: {
    root: {},
    label: {}
  },
  checkboxProps: {},
  checkboxStyles: {
    checkbox__root: {}
  },
  onFocus: () => {},
  testID: ''
};

const StyledCheckBox = styled(CheckBox)(({ theme }) => ({
  width: '100%',
  margin: 0,
  display: 'inline-flex',
  [`&.${classes.label}`]: {
    marginLeft: 15,
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: '17px'
  },
  [`& .${classes.checkbox}`]: {
    minWidth: 24,
    width: 33,
    height: 33,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)'
  }
}));

export default StyledCheckBox;
