import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'components';
import spinner from 'assets/img/spinner.png';

const StyledRoot = styled('img')({
  '&.spinner': {
    animation: 'rotate 1s linear infinite'
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});

function Spinner({ className, size, ...props }) {
  const sizes = { width: size, height: size };
  return (
    <StyledRoot className={`spinner ${className}`} src={spinner} alt="" {...sizes} {...props} />
  );
}

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number
};

Spinner.defaultProps = {
  className: '',
  size: 20
};

export default Spinner;
