const {
  ALLOW_EMPLOYER_SCHEDULE_ASSISTANT = '',
  APP_STORE_URL = '',
  BETA_SCHEDULE_TESTERS = '[]',
  BETA_PAYMENT_TESTERS = '[]',
  EMPLOYEE_TUTORIAL_URL = '',
  EMPLOYER_TUTORIAL_URL = '',
  LANDING_PUBLIC_PATH = '',
  PLAY_MARKET_URL = '',
  TRACKING_PARAM = '',
  ALLOW_PAYMENT = '',
  BETA_TEAM_MANAGEMENT_TESTERS = '[]'
} = process.env;
const enableScheduleAssistant = ALLOW_EMPLOYER_SCHEDULE_ASSISTANT === 'true';
const enablePayment = ALLOW_PAYMENT === 'true';
const betaScheduleTesters = BETA_SCHEDULE_TESTERS ? JSON.parse(BETA_SCHEDULE_TESTERS) : [];
const betaPaymentTesters = BETA_PAYMENT_TESTERS ? JSON.parse(BETA_PAYMENT_TESTERS) : [];
const betaTeamManagementTesters = BETA_TEAM_MANAGEMENT_TESTERS
  ? JSON.parse(BETA_TEAM_MANAGEMENT_TESTERS)
  : [];

const landingPublicPath = 'https://getquickhire.com';
const privacyPolicyPath = `${
  LANDING_PUBLIC_PATH || landingPublicPath
}/privacy/QuickHire%20Website%20Privacy%20Policy.pdf`;
const termsPath = `${
  LANDING_PUBLIC_PATH || landingPublicPath
}/privacy/QuickHire%20Website%20Terms%20of%20Use%202021.pdf`;

// For debugging
const SCHEDULE_TIME_DOUBLE_CONVERT = true; // true = convert slot time to employer tz
const TIME_SLOT_TO_EMPLOYEE_TZ = true; // true = convert slot time to employee tz, otherwise to device local time

const isPaymentEnabled = () => {
  const userId = localStorage.getItem('userId');
  return enablePayment || betaPaymentTesters.indexOf(Number(userId)) !== -1;
};

export {
  ALLOW_PAYMENT,
  ALLOW_EMPLOYER_SCHEDULE_ASSISTANT,
  APP_STORE_URL,
  EMPLOYEE_TUTORIAL_URL,
  EMPLOYER_TUTORIAL_URL,
  PLAY_MARKET_URL,
  TRACKING_PARAM,
  TIME_SLOT_TO_EMPLOYEE_TZ,
  SCHEDULE_TIME_DOUBLE_CONVERT,
  enableScheduleAssistant,
  enablePayment,
  landingPublicPath,
  privacyPolicyPath,
  termsPath,
  betaScheduleTesters,
  betaPaymentTesters,
  betaTeamManagementTesters,
  isPaymentEnabled
};
