import React, { useEffect, useCallback, useMemo, useReducer } from 'react';
import { useApolloClient } from '@apollo/client';
import PT from 'prop-types';
import { AuthContext } from './context';
import AuthRef from './AuthRef';

const token = localStorage.getItem('token');
const userId = localStorage.getItem('userId');

const initialState = {
  authed: !!(token && userId),
  externalReferrer: ''
};

function reducer(state, action) {
  switch (action.type) {
    case 'signin':
      return { ...state, authed: true, ...action.payload };
    case 'signout':
      return { ...state, authed: false, externalReferrer: '', ...action.payload };
    case 'set-external-referrer':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// eslint-disable-next-line react/prop-types
function AuthProvider({ children, context }) {
  const client = useApolloClient();
  const [{ authed, externalReferrer }, dispatch] = useReducer(reducer, initialState);
  console.log('%c[externalReferrer]', 'color:violet;', externalReferrer);

  const signin = useCallback((params = {}) => {
    const { payload = {}, callback = () => {} } = params;
    dispatch({ type: 'signin', payload });
    callback();
  }, []);

  const signout = useCallback((params = {}) => {
    const { payload = {}, callback = () => {} } = params;
    client.logout();
    dispatch({ type: 'signout', payload });
    callback();
  }, []);

  const setExternalReferrer = useCallback((link) => {
    dispatch({ type: 'set-external-referrer', payload: { externalReferrer: link } });
  }, []);

  useEffect(() => {
    AuthRef.current = {
      signin,
      signout
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      authed,
      signout,
      signin,
      setExternalReferrer,
      externalReferrer
    }),
    [authed, externalReferrer]
  );

  const Context = context || AuthContext;

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

AuthProvider.propTypes = {
  children: PT.node.isRequired
};

export default AuthProvider;
