import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { qaAttr, EMPLOYEE_TUTORIAL_URL } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { Box, styled } from 'components';
import { CheckBox, Button, IconButton, Spinner, Select } from 'components/shared';
import { MdClose } from 'components/icons';
import { GET_GROW_INDUSTRIES, GET_JOB_TYPES_BY_INDUSTRY } from 'api';
import styles from 'styles/Grow/employee';

const StyledRoot = styled('div')(styles);

function SelectSpinner() {
  return <Spinner size={18} />;
}

function CareerDevSetup({ saveInitialCareerDev, isSetupFailed }) {
  const client = useApolloClient();
  const [industries, setIndustries] = useState(null);
  const [industriesLoading, setIndustriesLoading] = useState(false);
  const [chosenIndustry, setChosenIndustry] = useState('');
  const [currentJob, setCurrentJob] = useState('');
  const [futureJob, setFutureJob] = useState('');
  const [currentlySearching, setCurrentlySearching] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypesLoading, setJobTypesLoading] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(true);

  const getIndustries = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && !isEmpty(userId)) {
      const industriesResp = await client.query({
        query: GET_GROW_INDUSTRIES,
        fetchPolicy: 'cache',
        errorPolicy: 'all'
      });
      setIndustries(get(industriesResp, 'data.industries'));
    }
    setIndustriesLoading(false);
  };

  useEffect(() => {
    if (chosenIndustry != null && chosenIndustry !== '') {
      getJobTypes();
    }
  }, [chosenIndustry]);

  const getJobTypes = async () => {
    const token = localStorage.getItem('token');
    if (token && !isEmpty(chosenIndustry)) {
      const jobTypesLocal = await client.query({
        query: GET_JOB_TYPES_BY_INDUSTRY,
        variables: {
          industryId: Number(chosenIndustry)
        },
        fetchPolicy: 'cache',
        errorPolicy: 'all'
      });
      setJobTypes(get(jobTypesLocal, 'data.jobTypesByIndustryId'));
    }
    setJobTypesLoading(false);
  };

  useEffect(() => {
    setIndustriesLoading(true);
    getIndustries();
  }, []);

  const handleIndustryChange = async (e) => {
    setJobTypesLoading(true);
    setChosenIndustry(e.target.value);
    // await getJobTypes();
  };

  const hidVideo = () => setIsVideoVisible(false);

  const isButtonDisabled =
    (Number(currentJob) && currentlySearching) || !futureJob || !chosenIndustry;

  return !industries ? null : (
    <StyledRoot className="introContainer">
      <div className="introContentContainer">
        <h1 className="title">Congratulations</h1>
        <p className="subTitle">
          on starting your journey to a better career and ultimately a better life!
        </p>
        {isVideoVisible && (
          <>
            <p className="videoTitle">
              Check out this video to see how you can improve your career
            </p>
            <div className="videoContainer">
              <IconButton variant="outlined" className="videoCloseBtn" onClick={hidVideo}>
                <MdClose color="primary" style={{ fontSize: 18 }} />
              </IconButton>
              <iframe
                title="tutorial"
                width="100%"
                height="100%"
                src={EMPLOYEE_TUTORIAL_URL}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </>
        )}
        <div className="introModalBackground">
          <div className="introModalContentContainer">
            <div className="introSelectContainer">
              <p className="introSelectLabel">Industry</p>
              <Select
                value={chosenIndustry}
                id="industry"
                name="industry"
                required
                variant="textfield"
                native
                className="introSelect"
                options={map(industries, (ind, i) => ({
                  key: `industry__${i}`,
                  value: ind.industry_id,
                  label: ind.name,
                  ...qaAttr(`qh-grow-industry-option-${ind.industry_id}`)
                }))}
                emptyOption={
                  <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
                    Industry
                  </Box>
                }
                onChange={handleIndustryChange}
                testID="qh-grow-industry-select"
              />
            </div>
            <div className="introSelectContainer">
              <p className="introSelectLabel">I am a:</p>
              <Select
                value={currentJob}
                id="currentJob"
                name="current job"
                required
                variant="textfield"
                native
                disabled={
                  jobTypesLoading ||
                  industriesLoading ||
                  (!Number(chosenIndustry) && jobTypes != null) ||
                  (!Number(currentJob) && currentlySearching)
                }
                className="introSelect"
                inputProps={{
                  onFocus: () => {
                    track('Qh Grow current job focused');
                  }
                }}
                options={map(jobTypes, (j, i) => ({
                  key: `curJob__${i}`,
                  value: j.id,
                  label: j.name,
                  ...qaAttr(`qh-grow-current-job-option-${j.id}`)
                }))}
                emptyOption={
                  <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
                    Current Job
                  </Box>
                }
                onChange={(e) => setCurrentJob(e.target.value)}
                testID="qh-grow-current-job-select"
                {...((jobTypesLoading || industriesLoading) && { IconComponent: SelectSpinner })}
              />
            </div>
            <div className="introSelectContainer">
              <p className="introSelectLabel">I want to be a:</p>
              <Select
                value={futureJob}
                id="futureJob"
                name="future job"
                required
                variant="textfield"
                native
                disabled={
                  jobTypesLoading ||
                  industriesLoading ||
                  ((chosenIndustry === '' || chosenIndustry == null) && jobTypes != null)
                }
                className="introSelect"
                inputProps={{
                  onFocus: () => {
                    track('Qh Grow future job focused');
                  }
                }}
                options={map(jobTypes, (j, i) => ({
                  key: `futureJob__${i}`,
                  value: j.id,
                  label: j.name,
                  ...qaAttr(`qh-grow-future-job-option-${j.id}`)
                }))}
                emptyOption={
                  <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
                    Future Job
                  </Box>
                }
                onChange={(e) => setFutureJob(e.target.value)}
                testID="qh-grow-future-job-select"
                {...((jobTypesLoading || industriesLoading) && { IconComponent: SelectSpinner })}
              />
            </div>
          </div>
          <div className="introActionContainer">
            <div>
              <CheckBox
                value={currentlySearching}
                checked={currentlySearching}
                label="Currently searching for a career"
                className="introCheckbox"
                onChange={() => {
                  setCurrentJob('');
                  setCurrentlySearching(!currentlySearching);
                }}
                checkboxProps={{
                  inputProps: { ...qaAttr('currently-searching-checkbox-input') }
                }}
                {...qaAttr('currently-searching-checkbox')}
              />
            </div>
            <Button
              disabled={isButtonDisabled}
              sx={{ marginTop: '20px', padding: '10px 20px ', borderRadius: 50 }}
              variant="filled-primary"
              onClick={() =>
                saveInitialCareerDev({
                  industryId: chosenIndustry,
                  curJobTypeId: currentJob,
                  futureJobTypeId: futureJob,
                  currentlySearching
                })
              }
              testID="qh-grow-submit-button"
            >
              See your path
            </Button>
            {isSetupFailed && (
              <p className="introError">
                Failed to create your career development, please try again. If this problem persist,
                please contact support
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledRoot>
  );
}

CareerDevSetup.propTypes = {
  isSetupFailed: PropTypes.bool.isRequired,
  saveInitialCareerDev: PropTypes.func.isRequired
};

export default CareerDevSetup;
