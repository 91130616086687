import { createTheme } from 'components';
import fontFaces from './font-faces';

const commonStyles = {
  helperText: {
    margin: '8px 0',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '17px'
  }
};

export default createTheme({
  commonStyles,
  desktopBreakPoint: 1024,
  palette: {
    primary: {
      light: '#796ed4',
      main: '#4743A2',
      dark: '#051c72',
      contrastText: '#fff',
      text: '#2C2C2C'
    },
    error: {
      main: '#BE1E2D'
    },
    success: {
      main: '#2BB673'
    },
    neutral: {
      main: '#1C75BC'
    },
    background: {
      default: '#fff'
    },
    moreColors: {
      disabled: '#ECECF5',
      black: '#2C2C2C',
      black_1: 'rgba(44,44,44,0.4)',
      black_2: '#333',
      yellow: '#FFB810',
      purpleLanding: '#48479A',
      purpleLight: 'rgba(71,67,162,0.1)',
      purpleLight_1: '#8783CD',
      mainPrimaryLucid: 'rgba(71,67,162,0.5)',
      white: '#f4f4f4',
      grey_1: '#A4A4A5',
      grey_2: '#ECECF5',
      grey_3: '#A7A9AC',
      grey_4: '#E4E4E4',
      grey_5: '#808285',
      grey_6: '#58595b',
      grey_7: '#AFAFAF',
      grey_8: '#E2E2E2'
    }
  },
  typography: {
    // overrides font-family for all Material-ui components
    fontFamily: ['Comfortaa', 'sans-serif'].join(', ')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${fontFaces.join(' ')}
        body {
          font-size: 0.875rem;
          line-height: 1.43;
          letter-spacing: 0.01071em;
          font-family: Comfortaa, sans-serif;
          color: #2C2C2C;
          overflow-x: hidden;
        }
        p, h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
        a {
          text-decoration: none;
        }
        button {
          font-family: inherit;
        }
        input, textarea {
          caret-color: #4743A2;
        }
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-track {
          background-color: #FFF;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #f1f1f1;
          border-radius: 10px;
        }
      `
    },
    MuiDatePicker: {
      defaultProps: {
        PaperProps: {
          sx: {
            borderRadius: '10px'
          }
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.1)'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: 15,
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.25)'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.2)'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: 15,
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.25)'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 59,
          height: 32,
          padding: 0,
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: 16
        },
        switchBase: {
          color: '#fff',
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(90%)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#4743A2'
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: '#fff'
            }
          }
        },
        thumb: {
          width: 26,
          height: 26,
          backgroundColor: '#D8D8D8',
          boxShadow: 'none'
        },
        track: {
          opacity: 1,
          backgroundColor: 'transparent'
        }
      }
    }
  }
});
