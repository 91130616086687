import React, { useState, useCallback, useMemo } from 'react';
import UpgradeExpiredPlanDialog from 'components/dialogs/Payment/UpgradeExpiredPlanDialog';
import PaymentDialog from 'components/dialogs/Payment/PaymentDialog';
import ChatDialog from 'components/dialogs/ChatDialog';
import { GRID_LAYOUT } from 'components/ApplicantsDashboard/data';
import { CommonUIContext } from './context';

const initModalsState = {
  updatePlanModal: false,
  paymentModal: false,
  chatModal: false,
  chatModalProps: {},
  updatePlanModalProps: {},
  paymentModalProps: {
    type: ''
  }
};

export default function CommonUIProvider({ children, context }) {
  const [modals, setModals] = useState(initModalsState);
  const [employerJobsFilter, setEmployerJobsFilter] = useState(0);
  const [employeeJobsTab, setEmployeeJobsTab] = useState(0);
  const [hirePromptWasShown, setHirePromptWasShown] = useState(false);
  const [newJobPromptWasShown, setNewJobPromptWasShown] = useState(false);
  const [applicantsDashboardLayout, setApplicantsDashboardLayout] = useState(GRID_LAYOUT);
  const isUpdatePlanModalOpen = modals.updatePlanModal;
  const isPaymentModalOpen = modals.paymentModal;
  const isChatModalOpen = modals.chatModal;

  const manageModals = useCallback((params = {}) => {
    setModals((prev) => ({ ...prev, ...params }));
  }, []);

  const openUpdatePlanModal = useCallback((params = {}, ...rest) => {
    manageModals({
      updatePlanModal: true,
      updatePlanModalProps: { ...params },
      ...rest
    });
  }, []);

  const closeUpdatePlanModal = useCallback((params = {}) => {
    manageModals({ updatePlanModal: false, updatePlanModalProps: {}, ...params });
  }, []);

  const openPaymentModal = useCallback((params = {}, ...rest) => {
    manageModals({
      paymentModal: true,
      paymentModalProps: { ...params },
      ...rest
    });
  }, []);

  const closePaymentModal = useCallback((params = {}) => {
    manageModals({
      paymentModal: false,
      paymentModalProps: { ...initModalsState.paymentModalProps },
      ...params
    });
  }, []);

  const openChatModal = useCallback((params = {}, ...rest) => {
    manageModals({
      chatModal: true,
      chatModalProps: { ...params },
      ...rest
    });
  }, []);

  const closeChatModal = useCallback((params = {}) => {
    manageModals({
      chatModal: false,
      chatModalProps: { ...initModalsState.chatModalProps },
      ...params
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      applicantsDashboardLayout,
      closeChatModal,
      closePaymentModal,
      closeUpdatePlanModal,
      employeeJobsTab,
      employerJobsFilter,
      hirePromptWasShown,
      manageModals,
      newJobPromptWasShown,
      openChatModal,
      openPaymentModal,
      openUpdatePlanModal,
      setApplicantsDashboardLayout,
      setEmployeeJobsTab,
      setEmployerJobsFilter,
      setHirePromptWasShown,
      setNewJobPromptWasShown
    }),
    [
      applicantsDashboardLayout,
      closeChatModal,
      closePaymentModal,
      closeUpdatePlanModal,
      employeeJobsTab,
      employerJobsFilter,
      hirePromptWasShown,
      manageModals,
      newJobPromptWasShown,
      openChatModal,
      openPaymentModal,
      openUpdatePlanModal,
      setApplicantsDashboardLayout
    ]
  );

  const Context = context || CommonUIContext;

  return (
    <Context.Provider value={contextValue}>
      {children}
      <UpgradeExpiredPlanDialog
        isOpen={isUpdatePlanModalOpen}
        onClose={closeUpdatePlanModal}
        openPaymentModal={openPaymentModal}
        closePaymentModal={closePaymentModal}
        {...modals.updatePlanModalProps}
      />
      <PaymentDialog
        isOpen={isPaymentModalOpen}
        onClose={closePaymentModal}
        {...modals.paymentModalProps}
      />
      {isChatModalOpen && (
        <ChatDialog isOpen={isChatModalOpen} onClose={closeChatModal} {...modals.chatModalProps} />
      )}
    </Context.Provider>
  );
}
