import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { qaAttr } from 'utils';
import { Box, styled } from 'components';
import {
  ContactsForm,
  CvForm,
  GenderForm,
  NameForm,
  EthnicityForm,
  Experience,
  // PhoneForm,
  Survey,
  UserSkillsForm,
  UserPhotoForm,
  UserBirthdayForm,
  EmployeeStepper
} from 'components/Onboarding/employee';
import { employee_steps } from 'components/Onboarding/data';

import styles from 'styles/Onboarding';

function EmployeeOnboardingSteps(props) {
  const { form, onFormChange, onGoToNextStep, onFinish, step } = props;
  const [calledToasts, setCalledToasts] = useState([]);

  const renderSurvey = () => (
    <div className="stepContent" style={{ alignItems: 'center' }}>
      <h1 className="surveyTitle">You're about halfway there, how's it coming along?</h1>
      <Survey onGoToNextStep={onGoToNextStep} />
    </div>
  );

  const renderContent = () => {
    let title = '';
    let subTitle = null;
    let subStepperContent = null;
    let content = null;
    const commonProps = {
      onChange: onFormChange,
      form,
      calledToasts,
      onGoToNextStep,
      setCalledToasts
    };

    switch (step) {
      case 'userName': {
        title = 'What’s your full name?';
        subStepperContent = (
          <>
            <div className="commonStepper__title">
              {`${employee_steps.length - 1} steps?! SAY WHAT??`}
            </div>
            <p className="commonStepper__subTitle secondaryText">
              It looks like alot, but it's very fast. There are no part A, B,C's like those tests we
              all hate.
            </p>
          </>
        );
        content = <NameForm formKey="userName" {...commonProps} />;
        break;
      }
      case 'userPhoto': {
        title = 'Your Profile Photo';
        content = <UserPhotoForm formKey="userPhoto" {...commonProps} />;
        break;
      }
      case 'contacts': {
        title = '';
        content = <ContactsForm formKey="contacts" {...commonProps} />;
        break;
      }
      // case 'zipCode': {
      //   title = 'ZIP Code';
      //   content = <ZipCodeForm formKey="zipCode" {...commonProps} />;
      //   break;
      // }
      // case 'phone': {
      //   title = 'Your phone number';
      //   content = <PhoneForm formKey="phone" {...commonProps} />;
      //   break;
      // }
      case 'birthday': {
        title = 'Date of Birth';
        content = <UserBirthdayForm formKey="birthday" {...commonProps} />;
        break;
      }
      case 'gender': {
        title = 'Gender';
        content = <GenderForm formKey="gender" form={form} {...commonProps} />;
        break;
      }
      case 'ethnicity': {
        title = 'Ethnicity';
        content = <EthnicityForm formKey="ethnicity" {...commonProps} />;
        break;
      }
      case 'skills': {
        title = 'Skills';
        content = <UserSkillsForm formKey="skills" {...commonProps} />;
        break;
      }
      case 'experience': {
        title = 'Job experience';
        subTitle = (
          <h2 className="pageSubTitle" style={{ marginBottom: 33 }}>
            What positions have you occupied?
          </h2>
        );
        content = <Experience formKey="experience" {...commonProps} />;
        break;
      }
      case 'cv': {
        title = 'Upload your intro video';
        content = <CvForm formKey="cv" {...commonProps} onFinish={onFinish} />;
        break;
      }
      default:
    }

    return (
      <div className="stepContent">
        <EmployeeStepper step={step} />
        {subStepperContent}
        {title && (
          <Box
            component="h1"
            maxWidth={350}
            mx="auto"
            mb={step === 'skills' ? '4px' : '20px'}
            className="pageTitle"
            {...qaAttr('onboarding-form-title')}
          >
            {title}
          </Box>
        )}
        {subTitle}
        <div className="pageContent">{content}</div>
      </div>
    );
  };

  if (step === 'survey') {
    return renderSurvey();
  }

  return renderContent();
}

EmployeeOnboardingSteps.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onFormChange: PropTypes.func.isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired
};

const StyledRoot = styled(EmployeeOnboardingSteps)(styles);

export default StyledRoot;
