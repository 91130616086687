import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { getUserId, getRoutes } from 'utils';
import { POST_EMPLOYEE_STILL_HIRED } from 'api';
import { styled } from 'components';
import { Button } from 'components/shared';

const StyledRoot = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .title': {
    marginBottom: 10,
    textAlign: 'center'
  },
  '& .action': {
    height: 50,
    width: 100,
    '&:last-child': {
      marginLeft: 20
    }
  }
}));

const ROUTES = getRoutes();

function EmployeeStillHired(props) {
  const navigate = useNavigate();
  const [postAnswer] = useMutation(POST_EMPLOYEE_STILL_HIRED);

  const sendAnswer = (answer) => {
    const uid = getUserId();
    postAnswer({
      variables: {
        employeeUserId: Number(uid),
        response: answer
      }
    });

    navigate(ROUTES.employee.dashboard, { replace: true });
  };

  return (
    <StyledRoot>
      <div>
        <h1 className="title">Still hired?</h1>
        <Button variant="filled-secondary" className="action" onClick={() => sendAnswer(false)}>
          No
        </Button>
        <Button variant="filled-primary" className="action" onClick={() => sendAnswer(true)}>
          Yes
        </Button>
      </div>
    </StyledRoot>
  );
}

export default EmployeeStillHired;
