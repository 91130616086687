import React from 'react';
import PropTypes from 'prop-types';
import { getRoutes } from 'utils';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import styles from 'styles/Dashboard/JobCard';
import defaultImg from 'assets/img/job_default.png';

const StyledRoot = styled(Button)(styles);
const Dot = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  marginRight: 6,
  marginBottom: 2,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main
}));

function JobCard({ data, onClick, ...rest }) {
  const {
    applied = 0,
    qmCount = 0,
    id = '',
    title = '',
    new_count = 0,
    imageUrl = '',
    status = '',
    active,
    draft = false
  } = data;
  const isClosed = !active;

  return (
    <StyledRoot
      isRouterLink
      to={getRoutes({ id }).employer.job}
      // disabled={isClosed}
      className="card"
      onClick={onClick}
      testID={`job-link-${title}`}
      {...rest}
    >
      <img src={imageUrl || defaultImg} alt="" className="card__photoArea" />
      <Box p="24px 18px 31px">
        <h2 className="card__name">{title}</h2>
        <div className="card__stats">
          {isClosed ? (
            <span>Position Closed</span>
          ) : (
            <>
              <Dot />
              <Box mr="16px" color="primary.main" fontWeight="bold">{`${new_count} new`}</Box>
              <Box mr="16px">{`${qmCount + applied} applied`}</Box>
              {draft && <div className="draftLabel">Draft</div>}
            </>
          )}
        </div>
      </Box>
    </StyledRoot>
  );
}

JobCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired
};

export default JobCard;
