import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import map from 'lodash/map';
import { useMediaQuery } from 'components';
import { MdArrowBack, MdAccountRemove, MdClose, MdStar } from 'components/icons';
import { IconButton, Spinner } from 'components/shared';

const windowWidth = window.innerWidth;

function ManagedConnections(props) {
  const {
    closeModal,
    managedConnections,
    onProfileOpen,
    onStar,
    onUnStar,
    starredProfileIdLoading
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const handleStar = (e, profileId) => {
    e.stopPropagation();
    onStar(profileId);
  };

  const handleUnStar = (e, profileId) => {
    e.stopPropagation();
    onUnStar(profileId);
  };

  return (
    <Spring
      from={{ right: isDesktop ? -200 : windowWidth * -1 }}
      to={{ right: isDesktop ? 0 : 20 }}
      config={{
        duration: 250,
        easing: easePolyOut.exponent(2.0)
      }}
    >
      {(animStyles) => (
        <animated.div
          className="managedConnectionsContainer"
          style={{ right: animStyles.right, zIndex: 5 }}
        >
          <div className="managedConnectionsHeader">
            <div className="managedConnectionsCloseBtnContainer">
              <IconButton
                className="managedConnectionsCloseBtn"
                onClick={closeModal}
                testID="qh-grow-managed-close-button"
              >
                {isDesktop ? (
                  <MdArrowBack color="primary" />
                ) : (
                  <MdClose fontSize="inherit" htmlColor="#A4A4A5" />
                )}
              </IconButton>
            </div>
            <h2 className="managedConnectionsTitle">{`Manage ${
              isDesktop ? 'Your' : ''
            } Connections`}</h2>
          </div>
          <div
            className="managedConnectionsContent"
            style={{ justifyContent: managedConnections.length ? 'flex-start' : 'center' }}
          >
            {managedConnections.length ? (
              <div style={{ width: '100%' }}>
                {map(managedConnections, (connection, idx) => {
                  const isStarred = true;
                  const isStarLoading = starredProfileIdLoading === connection.profile_id;

                  return (
                    <div key={`managed__${idx}`} className="managedConnectionsItem">
                      <div
                        className="managedConnectionsItemContent"
                        onClick={() => onProfileOpen(connection.user_id, idx, 'managed')}
                        testID="`qh-grow-connection-button-${connection.name}`"
                      >
                        <div style={{ marginRight: 10, position: 'relative' }}>
                          <MdStar className="managedConnectionsItem__starIcon" />
                          <div className="connectionImgContainer" style={{ margin: 0 }}>
                            {connection.imageUrl ? (
                              <img src={connection.imageUrl} className="connectionImg" alt="" />
                            ) : (
                              <div className="connectionNoImage">
                                <span>{connection.name.slice(0, 1).toUpperCase()}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="managedConnectionsItem__details">
                          <p className="connection connectionName">{connection.name}</p>
                          <p className="connection connectionTitle">
                            {connection.companyName}
                            {connection.companyPosition ? ` | ${connection.companyPosition}` : ''}
                          </p>
                        </div>
                      </div>
                      <div>
                        <IconButton
                          disabled={isStarLoading}
                          onClick={(e) =>
                            isStarred
                              ? handleUnStar(e, connection.profile_id)
                              : handleStar(e, connection.profile_id)
                          }
                          testID={`qh-grow-connection-remove-button-${connection.name}`}
                        >
                          {isStarLoading ? (
                            <Spinner size={24} />
                          ) : (
                            <MdAccountRemove color="primary" />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No Connections Available</div>
            )}
          </div>
        </animated.div>
      )}
    </Spring>
  );
}

ManagedConnections.propTypes = {
  closeModal: PropTypes.func.isRequired,
  managedConnections: PropTypes.arrayOf(PropTypes.any).isRequired,
  onProfileOpen: PropTypes.func.isRequired,
  onStar: PropTypes.func.isRequired,
  onUnStar: PropTypes.func.isRequired,
  starredProfileIdLoading: PropTypes.number
};

ManagedConnections.defaultProps = {
  starredProfileIdLoading: null
};

export default ManagedConnections;
