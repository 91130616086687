import React from 'react';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import PropTypes from 'prop-types';

const SectionProgressBar = ({ percent }) => (
  <Spring
    from={{ x: 0 }}
    to={{ x: percent || 0 }}
    config={{ duration: 1250, tension: 300, friction: 0, easing: easePolyOut.exponent(2.0) }}
  >
    {({ x }) => (
      <div
        style={{
          width: '100%',
          height: 4,
          position: 'relative',
          marginTop: 7
        }}
      >
        <div
          className="bar"
          style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
            background: '#ECECF5'
          }}
        />
        <animated.div
          className="bar"
          style={{
            width: x.to((v) => `${v}%`),
            height: '100%',
            zIndex: 1,
            top: 0,
            left: 0,
            position: 'absolute',
            background: '#8787F9'
          }}
        />
      </div>
    )}
  </Spring>
);

SectionProgressBar.propTypes = {
  percent: PropTypes.number
};

SectionProgressBar.defaultProps = {
  percent: 0
};

export default SectionProgressBar;
