import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { getLocaleTimeZone, getRoutes, employeeStartPage } from 'utils';
import { group, identify } from 'utils/segmentAnalytics';
import { useRestrictions, useTimeZone, useAuth } from 'hooks';
import { Spinner } from 'components/shared';
import { fetchEmployerProfile } from 'api';
import { PrivateLayout } from 'containers';

const ROUTES = getRoutes();

function ProtectedEmployerRoute() {
  const { authed, signout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { timeZone: appTimeZone, setTimeZone, updateUserTimeZone } = useTimeZone();
  const { setRestrictions } = useRestrictions();

  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);

  const initUserInSegment = (profile) => {
    identify({ name: profile?.name, email: profile?.email, profileType: 'employer' });
    group(
      {
        address: profile?.address,
        industry: profile?.industry,
        size: profile?.size,
        jobCount: profile?.jobCount
      },
      'employer'
    );
  };

  const checkProfileByRole = async () => {
    const [profile] = await fetchEmployerProfile({
      queryParams: { fetchPolicy: 'cache-first' }
    });

    if (profile) {
      const { timeZone, profileWizard, allowPlan, restrictions } = profile;

      if (!timeZone) updateUserTimeZone(getLocaleTimeZone());
      else setTimeZone(timeZone);

      initUserInSegment(profile);

      setRestrictions(
        allowPlan
          ? { ...(restrictions || {}), showEnterpriseDashboard: true }
          : {
              allowEmployeeActions: true,
              allowEnterprise: false,
              showEnterpriseDashboard: false, // if allowPlan === false - allow everything, except enterprise board
              jobPosting: true
            }
      );

      if (profileWizard) {
        return true;
      }
      navigate(ROUTES.onboarding.default);
      return false;
    }
    if (!location?.state?.notEmployee) {
      // check if user is an employee if he wasn't checked before
      navigate(employeeStartPage, { state: { notEmployer: true } });
    } else {
      signout();
    }
    return false;
  };

  const authMiddleware = async () => {
    if (authed) {
      const checked = await checkProfileByRole();
      setIsUserRoleChecked(checked);
    } else {
      navigate(ROUTES.login.default, { state: { from: { location } } });
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <PrivateLayout userRole="employer">
        <Outlet />
      </PrivateLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed ? renderContent() : <Navigate to={ROUTES.login.default} />;
}

export default ProtectedEmployerRoute;
