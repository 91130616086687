import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import {
  employeeStartPage,
  employerStartPage,
  getRoutes,
  getUserId,
  getLocaleTimeZone,
  qaAttr
} from 'utils';
import { useAuth } from 'hooks';
import { TimeZoneContext, RestrictionsContext } from 'providers/context';
import { Box, MuiIconButton, MobileStepper, styled } from 'components';
import { Button, Footer, RadioGroup } from 'components/shared';
import { MdArrowBack } from 'components/icons';
import EmployerOnboardingSteps from './EmployerOnboardingSteps';
import EmployeeOnboardingSteps from './EmployeeOnboardingSteps';
import {
  employer_steps,
  employer_all_steps,
  employee_steps,
  employee_all_steps
} from 'components/Onboarding/data';
import { client, POST_USER_TYPE, SET_REG_STEP } from 'api';

import styles from 'styles/Onboarding';

const StyledRoot = styled('div')(styles);
const ROUTES = getRoutes();

class Onboarding extends Component {
  default_employer_state = {
    address: '',
    name: '',
    phone: '',
    size: '1-49',
    image: '',
    industry: [],
    preview: '',
    zip_code: ''
  };
  default_employee_state = {
    birth_date: '',
    file: '',
    gender: 'female',
    name: '',
    photo: '',
    phone: '',
    preview: '',
    race: 'American Indian or Alaska Na..',
    skills: {},
    video: null,
    website: '',
    zip: ''
  };

  state = {
    onboardingType: 'employee' /* employee, employer */,
    step: 'default' /* /components/Onboarding/data.js */,
    form: this.default_employee_state
  };

  componentDidMount() {
    const { location } = this.props;
    const { regStep = '' } = location?.state || {};
    const [role, step] = regStep.split('_');

    if (role && step) {
      const stepIdx = parseInt(step);
      const steps = role === 'employer' ? employer_steps : employee_steps;
      this.setState({ step: steps[stepIdx], onboardingType: role });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onboardingType } = this.state;

    if (prevState.onboardingType !== onboardingType) {
      this.setState({ form: this[`default_${onboardingType}_state`] });
    }
  }

  setRegistrationStep = async () => {
    const { onboardingType, step } = this.state;
    const countableSteps = onboardingType === 'employer' ? employer_steps : employee_steps;
    const currCountableStepIdx = countableSteps.indexOf(step);

    if (currCountableStepIdx + 1 >= 1) {
      const userId = getUserId();
      try {
        const resp = await client.mutate({
          mutation: SET_REG_STEP,
          variables: {
            userId: parseInt(userId),
            regStep: currCountableStepIdx + 1
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  goToNextStep = async (func, options) => {
    const { skipped = false } = options || {};
    const { onboardingType, step } = this.state;
    const steps = onboardingType === 'employer' ? employer_all_steps : employee_all_steps;
    const currStep = steps[steps.indexOf(step)];

    if (skipped) await this.setRegistrationStep();

    if (func) {
      func().then((data) => {
        if (data?.postEmployeeName?.profile_id) {
          localStorage.setItem('profileId', data.postEmployeeName.profile_id);
        } else if (data?.data?.setEmployerName?.profile_id) {
          localStorage.setItem('profileId', data.data.setEmployerName.profile_id);
        }
        this.setState({ step: steps[steps.indexOf(step) + 1] });
      });
    } else {
      this.setState({ step: steps[steps.indexOf(step) + 1] });
    }
  };

  goToPrevStep = () => {
    const { onboardingType, step } = this.state;
    const steps = onboardingType === 'employer' ? employer_all_steps : employee_all_steps;
    this.setState({ step: steps[steps.indexOf(step) - 1] });
  };

  navigateAfterFinish = async (role) => {
    const { location, navigate, setRestrictions } = this.props;
    const {
      pathname: referrer,
      search: referrerSearch,
      state: referrerState
    } = location?.state?.from?.location || {};

    if (role === 'employer') {
      navigate(referrer || employerStartPage, { state: referrerState });
    } else if (role === 'employee') {
      navigate(referrer || employeeStartPage, { state: referrerState });
    }
  };

  handleOnboardingFinish = async (onboardingType, func, options) => {
    const { skipped } = options || {};

    if (skipped) await this.setRegistrationStep();
    if (onboardingType === 'employee' || onboardingType === 'employer') {
      // clear cached profile so that it will be fetched with up to date data
      // at ProtectedEmployeeRoute/ProtectedEmployerRoute
      client.cache.evict({ fieldName: `${onboardingType}Profile` });
    }

    if (func) {
      func().then((data) => {
        this.navigateAfterFinish(onboardingType);
      });
    } else {
      this.navigateAfterFinish(onboardingType);
    }
  };

  handleFormChange = (nextForm) => {
    this.setState({ form: nextForm });
  };

  handleOnboardingTypeChange = (e) => {
    this.setState({ onboardingType: e.target.value });
  };

  renderOnboardingSteps = (type) => {
    const { form, step } = this.state;

    if (type === 'employer') {
      return (
        <EmployerOnboardingSteps
          step={step}
          form={form}
          onFinish={(func, ...rest) => this.handleOnboardingFinish('employer', func, ...rest)}
          onFormChange={this.handleFormChange}
          onGoToNextStep={this.goToNextStep}
        />
      );
    } else if (type === 'employee') {
      return (
        <EmployeeOnboardingSteps
          step={step}
          form={form}
          onFinish={(func, ...rest) => this.handleOnboardingFinish('employee', func, ...rest)}
          onFormChange={this.handleFormChange}
          onGoToNextStep={this.goToNextStep}
        />
      );
    }
    return null;
  };

  signOut = () => {
    const { navigate, signout } = this.props;
    signout();
  };

  renderDefaultStep = () => {
    const { onboardingType } = this.state;
    const { updateUserTimeZone } = this.props;
    const timeZone = getLocaleTimeZone();

    return (
      <div className="defaultStepContent">
        <Box
          component="h1"
          mb="24px"
          className="pageTitle"
          {...qaAttr('onboarding-default-step-title')}
        >
          Let’s Finish Your Profile
        </Box>
        <Box width="100%" maxWidth={320} mx="auto">
          <Box mb="16px" fontSize={14} lineHeight="22px" textAlign="center">
            I am
          </Box>
          <RadioGroup
            data={[
              { label: 'Looking for a Job', value: 'employee' },
              { label: 'Posting a Job', value: 'employer' }
            ]}
            value={onboardingType}
            defaultValue="employee"
            name="onboarding_type"
            sx={{ mb: '15px' }}
            onChange={this.handleOnboardingTypeChange}
          />
          <Mutation
            mutation={POST_USER_TYPE}
            variables={{ profileType: onboardingType, timeZone }}
            onCompleted={() => updateUserTimeZone(timeZone)}
          >
            {(func) => (
              <Button
                variant="filled-primary"
                width="100%"
                height={50}
                onClick={() => this.goToNextStep(func)}
                testID="onboarding-default-step-next-button"
              >
                Next
              </Button>
            )}
          </Mutation>
        </Box>
      </div>
    );
  };

  render() {
    const { onboardingType, step } = this.state;
    const steps = onboardingType === 'employer' ? employer_all_steps : employee_steps;
    const totalSteps = steps.length + 1;
    const activeStepIndex = steps.indexOf(step) + 1;

    return (
      <StyledRoot className="pageContainer">
        <div className="contentWrapper">
          <Box className="header">
            <div className="companyName">QuickHire</div>
            {step !== 'default' ? (
              <MuiIconButton
                edge="start"
                color="primary"
                aria-label="return"
                className="mobilePrevBtn"
                onClick={this.goToPrevStep}
                {...qaAttr('onboarding-go-to-prev-mobile-button')}
              >
                <MdArrowBack fontSize="inherit" />
              </MuiIconButton>
            ) : (
              <Box minWidth={36} height={48} />
            )}
            <Button variant="text" onClick={this.signOut} testID="onboarding-signout-button">
              Sign Out
            </Button>
          </Box>
          <MobileStepper
            variant="progress"
            position="static"
            steps={totalSteps}
            activeStep={activeStepIndex}
            classes={{ root: 'stepper', progress: 'progressBar' }}
          />
          {step !== 'default' && (
            <div className="desktopPrevBtn">
              <MuiIconButton
                edge="start"
                color="primary"
                aria-label="return"
                className="arrowBtn"
                onClick={this.goToPrevStep}
                {...qaAttr('onboarding-go-to-prev-button')}
              >
                <MdArrowBack fontSize="inherit" />
              </MuiIconButton>
            </div>
          )}
          {step === 'default'
            ? this.renderDefaultStep()
            : this.renderOnboardingSteps(onboardingType)}
        </div>
        {/* <div className={classes.footerContainer}>
          <Footer containerProps={{ p: '28px 30px' }} />
        </div> */}
      </StyledRoot>
    );
  }
}

function EnhancedOnboarding(props) {
  const { signout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <TimeZoneContext.Consumer>
      {({ updateUserTimeZone }) => (
        <RestrictionsContext.Consumer>
          {({ setRestrictions }) => (
            <Onboarding
              {...props}
              updateUserTimeZone={updateUserTimeZone}
              setRestrictions={setRestrictions}
              navigate={navigate}
              location={location}
              signout={signout}
            />
          )}
        </RestrictionsContext.Consumer>
      )}
    </TimeZoneContext.Consumer>
  );
}

export default EnhancedOnboarding;
