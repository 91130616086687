import { getUserId } from 'utils';
import getEmployerSubscriptionInfo from 'utils/getEmployerSubscriptionInfo'
import { client } from '../graphql';
import { GET_EMPLOYER_PROFILE } from '../queries';
import { updateEmployerSubscriptionVar } from '../reactiveVars';

export default async function fetchEmployerProfile(params) {
  const userId = getUserId();
  const {
    client: clientParam,
    cache = true,
    onError,
    queryParams = {},
    variables: variablesParam = {}
  } = params || {};
  const clientToUse = clientParam || client;

  if (variablesParam?.userId || userId) {
    const variables = { userId: Number(userId), ...variablesParam };
    const queryObject = {
      query: GET_EMPLOYER_PROFILE,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables,
      ...queryParams
    };
    const isNoCachePolicy = queryObject.fetchPolicy === 'no-cache';
    try {
      const response = await clientToUse.query(queryObject);
      if (response) {
        const result = response?.data?.employerProfile;
        const subscriptionInfo = result ? getEmployerSubscriptionInfo(result) : {};
        if (result && isNoCachePolicy) {
          if (cache) {
            const ref = clientToUse.writeQuery({
              query: GET_EMPLOYER_PROFILE,
              data: { employerProfile: { ...result } },
              variables
            });
          }
          updateEmployerSubscriptionVar(subscriptionInfo);
        }
        return [result, { response, subscriptionInfo }];
      }
      return [undefined, { response: {}, subscriptionInfo: {} }];
    } catch (error) {
      if (onError) onError(error);
      return [undefined, { response: {}, subscriptionInfo: {} }];
    }
  } else {
    throw new Error('Missed variables');
  }
}
