import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import format from 'date-fns/format';
import map from 'lodash/map';
import { qaAttr } from 'utils';
import { useRestrictions, useCommonUI } from 'hooks';
import { Box, styled } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdStar, MdTrendingUp, MdCheck, MdClose } from 'components/icons';
import styles from 'styles/ApplicantsDashboard/ListRowItem';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import ApplicantActionsPopup from './ApplicantActionsPopup';
import {
  STARRED_TAB,
  QH_MATCHES_TAB,
  APPROVED_TAB,
  REJECTED_TAB,
  APPLICANTS_TAB,
  REJECTED_STATUS,
  ACCEPTED_STATUS,
  CHAT_MESSAGE_TEMPLATE
} from './data';
import { getCandidateActionName, getApplicantUserId, getApplicantProfile } from './utils';

const StyledRoot = styled('div', { shouldForwardProp: (prop) => prop !== 'variant' })(styles);

function ListRowItem(props) {
  const {
    approveStatus,
    data,
    jobId,
    onSelect,
    onAccept,
    onReject,
    onStar,
    onRejectFromChatSuccess,
    starred: starredProp,
    type,
    webUrl,
    withActions
  } = props;
  const { restrictions = {} } = useRestrictions();
  const { openChatModal } = useCommonUI();
  const canManageEmployee = restrictions.allowEmployeeActions;
  const actionNames = getCandidateActionName(webUrl);
  const withQuestions = type === APPLICANTS_TAB;

  const styleVariant = type === APPLICANTS_TAB || type === QH_MATCHES_TAB ? 'default' : 'filled';

  const { createdAt, jobs, starredJob, approvedJob, passedJob } = data;
  const userId = getApplicantUserId(data);
  const profile = getApplicantProfile(data) || {};
  const { imageUrl = '', motto = '', name = '' } = profile;
  const answers = jobs?.questions || [];
  const isStarred = starredProp || type === STARRED_TAB || starredJob;
  const isRejected = type === REJECTED_TAB || approveStatus === REJECTED_STATUS || passedJob;
  const isApproved = type === APPROVED_TAB || approveStatus === ACCEPTED_STATUS || approvedJob;
  const withApproveActions =
    type === APPLICANTS_TAB || type === QH_MATCHES_TAB || type === STARRED_TAB;
  const showRejectButton = !(isRejected || isApproved) && withApproveActions;
  const showApproveButton = !(isRejected || isApproved) && withApproveActions;
  const showStarButton =
    type === STARRED_TAB || (!(isRejected || isApproved) && withApproveActions);
  const showApprovingStatus =
    type !== REJECTED_TAB && type !== APPROVED_TAB && (approveStatus || approvedJob || passedJob);

  let imgIcon =
    type === STARRED_TAB ? (
      <div className="avatarIcon">
        <MdStar fontSize="inherit" color="inherit" />
      </div>
    ) : null;
  if (type === QH_MATCHES_TAB) {
    imgIcon = (
      <div className="avatarIcon">
        <MdTrendingUp fontSize="inherit" color="inherit" />
      </div>
    );
  }

  const openChat = () => {
    openChatModal({
      dashBoardType: 'employer',
      variant: 'chat-only',
      jobId,
      candidateId: userId,
      onApplicantReject: onRejectFromChatSuccess,
      withHeaderActions: withApproveActions,
      suggestMessageTemplate: CHAT_MESSAGE_TEMPLATE
    });
  };

  const renderStatus = () => {
    // if (isStarred) {
    //   return (
    //     <span className="statusText">
    //       <MdStar fontSize="inherit" />
    //       <Box component="span" ml="6px">
    //         Starred
    //       </Box>
    //     </span>
    //   );
    // }
    if (isApproved) {
      return (
        <span className="statusText">
          <MdCheck fontSize="inherit" />
          <Box component="span" ml="6px">
            Accepted
          </Box>
        </span>
      );
    }
    if (isRejected) {
      return (
        <span className="statusText error">
          <MdClose fontSize="inherit" />
          <Box component="span" ml="6px">
            Rejected
          </Box>
        </span>
      );
    }
    return null;
  };

  const renderApplyDate = () =>
    type !== QH_MATCHES_TAB && createdAt ? format(new Date(createdAt), "'Applied' MM.dd") : '';

  return (
    <StyledRoot
      variant={styleVariant}
      className="container"
      {...qaAttr(`candidates-table-applicant-item-${name}`)}
    >
      <div className="innerContainer">
        <Button
          className="clickablePart"
          disableTouchRipple
          onClick={() => onSelect(data)}
          {...qaAttr(`candidates-table-applicant-button-${name}`)}
        >
          <div className="dataCell">
            <div className="avatar">
              <img src={imageUrl || defaultEmployeeImg} alt="" width="38" height="38" />
              {imgIcon}
            </div>
          </div>
          <div className="dataCell detailsContainer">
            <div className="dataCell textMain">{name}</div>
            <div className="dataCell textSecondary mottoExcerpt">{motto}</div>
          </div>
          <div className="dataCell_last textSecondary">
            {(() => {
              if (showApprovingStatus) return renderStatus();
              if (!isRejected) return renderApplyDate(createdAt);
              return '';
            })()}
          </div>
          {/* <MdChevronRight className="chevronIcon" /> */}
        </Button>
        <div className={clsx('dataCell', 'dataCell_last', withActions && 'dataCell__withActions')}>
          <ApplicantActionsPopup
            actionTitles={{
              accept: actionNames.accept.title,
              reject: actionNames.reject.title,
              star: actionNames.star.title
            }}
            disableAccept={!canManageEmployee}
            disableReject={!canManageEmployee}
            disableStar={!canManageEmployee}
            id={name || userId}
            isStarred={isStarred}
            onChat={() => openChat(data)}
            onAccept={() => onAccept(data)}
            onReject={() => onReject(data)}
            onStar={() => onStar(data, !isStarred)}
            withAccept={showApproveButton}
            withReject={showRejectButton}
            withStar={showStarButton}
            withChat
          />
        </div>
      </div>
      {!!answers.length && withQuestions && (
        <div className="questionsContainer">
          <div className="questionsContainer__title">Questions</div>
          {map(answers, ({ answer, name: question, questions_id }, i) => (
            <div key={`answer__${i}`} className="questionItem">
              <div className="questionItem__q">{question}</div>
              <div className="questionItem__a">{answer === 'true' ? 'yes' : 'no'}</div>
            </div>
          ))}
        </div>
      )}
    </StyledRoot>
  );
}

ListRowItem.propTypes = {
  approveStatus: PT.oneOf(['', ACCEPTED_STATUS, REJECTED_STATUS]),
  data: PT.objectOf(PT.any).isRequired,
  jobId: PT.number.isRequired,
  onAccept: PT.func,
  onReject: PT.func,
  onStar: PT.func,
  onSelect: PT.func.isRequired,
  onRejectFromChatSuccess: PT.func,
  starred: PT.bool,
  type: PT.oneOf(['', STARRED_TAB, QH_MATCHES_TAB, APPROVED_TAB, REJECTED_TAB, APPLICANTS_TAB]),
  webUrl: PT.string,
  withActions: PT.bool
};

ListRowItem.defaultProps = {
  approveStatus: '',
  onAccept: () => {},
  onReject: () => {},
  onStar: () => {},
  onRejectFromChatSuccess: () => {},
  starred: false,
  type: '',
  webUrl: '',
  withActions: true
};

export default ListRowItem;
