import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import map from 'lodash/map';
import pullAt from 'lodash/pullAt';
import findIndex from 'lodash/findIndex';
import { Box, InputAdornment } from 'components';
import { Button, CheckBoxGroup, Input } from 'components/shared';
import { useForm } from 'components/form';
import { MdSearch } from 'components/icons';
import { GET_INDUSTRY } from 'api';

function IndustriesForm({
  CheckBoxGroupProps,
  form,
  fieldName,
  InputProps,
  onSubmit,
  onChange,
  onSkip,
  withSkipBtn,
  withSubmitBtn
}) {
  const [
    getIndustry,
    { data: industryData = {}, error: industryError = {}, loading: industryLoading = true }
  ] = useLazyQuery(GET_INDUSTRY, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [options, setOptions] = useState([]);

  useEffect(() => {
    getIndustry();
  }, []);

  useEffect(() => {
    setOptions(industryData?.getIndustry);
  }, [JSON.stringify(industryData)]);

  const { $, get, set } = useForm({
    initial: { list: form[fieldName] || [] },
    validations: {}
  });
  const listAttr = get('list');

  const submit = () => {
    onSubmit({ ...form, [fieldName]: listAttr });
  };

  const skip = () => {
    onSkip();
  };

  const handleSelect = (data, checked) => {
    const nextList = [...listAttr];
    if (!checked) {
      pullAt(nextList, findIndex(nextList, ['name', data.value]));
    } else {
      nextList.push({ id: data.id, name: data.value });
    }
    set('list', nextList);

    if (onChange) onChange({ [fieldName]: nextList });
  };

  return (
    <>
      {/*<Input*/}
      {/*placeholder="Look up an industry"*/}
      {/*startAdornment={(*/}
      {/*<Box component={InputAdornment} minWidth={24} pl="16.5px" fontSize={24} color="rgba(0,0,0,0.5)">*/}
      {/*<MdSearch fontSize="inherit" color="inherit" />*/}
      {/*</Box>*/}
      {/*)}*/}
      {/*{...InputProps}*/}
      {/*/>*/}
      <CheckBoxGroup
        data={map(options, (opt) => ({
          id: opt.industry_id,
          label: opt.name,
          value: opt.name,
          checked: findIndex(listAttr, ['name', opt.name]) !== -1
        }))}
        aria-label="industry"
        sx={{ marginBottom: '16px', overflow: 'auto' }}
        checkboxesContainerProps={{
          sx: { overflowY: 'auto' }
        }}
        onChange={handleSelect}
        analyticParams={{
          key: 'Industry focused',
          trigger: 'focus'
        }}
        {...CheckBoxGroupProps}
      />
      {withSubmitBtn && (
        <Button
          variant="filled-primary"
          sx={{ width: '100%', height: 50, minHeight: 50 }}
          onClick={submit}
          testID="next-button"
        >
          Next
        </Button>
      )}
      {withSkipBtn && (
        <Button
          variant="text"
          sx={{ width: '100%', height: 50, minHeight: 50, fontSize: 14, lineHeight: '22px' }}
          onClick={skip}
          testID="skip-button"
        >
          Skip
        </Button>
      )}
    </>
  );
}

IndustriesForm.propTypes = {
  CheckBoxGroupProps: PropTypes.objectOf(PropTypes.any),
  fieldName: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  InputProps: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSkip: PropTypes.func,
  withSkipBtn: PropTypes.bool,
  withSubmitBtn: PropTypes.bool
};

IndustriesForm.defaultProps = {
  CheckBoxGroupProps: {},
  fieldName: 'industry',
  InputProps: {},
  onChange: () => {},
  onSubmit: () => {},
  onSkip: () => {},
  withSkipBtn: true,
  withSubmitBtn: true
};

export default IndustriesForm;
