import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Dialog, styled } from 'components';
import { Button, CheckBoxGroup, Input } from 'components/shared';
import { useForm } from 'components/form';
import { GET_HIRE_REASONS } from 'api';
import find from 'lodash/find';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .textarea': {
    height: 105,
    alignItems: 'flex-start',
    borderRadius: 5
  },
  '& .textareaInput': {
    padding: 6
  },
  '& .action': {
    width: '100%',
    maxWidth: 200,
    height: 45,
    '&:not(:first-of-type)': {
      marginLeft: 20
    }
  }
}));

function HireReasonsModal(props) {
  const { hired, isOpen, onClose, onConfirm, title } = props;

  const [selectedReasons, setSelectedReasons] = useState([]);
  const [notes, setNotes] = useState('');

  const { data: hireReasonsData, loading: reasonsLoading } = useQuery(GET_HIRE_REASONS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });
  const reasons = hireReasonsData?.getReasons?.filter((o) => o.type === hired) || [];

  useEffect(() => {
    if (isOpen) {
      setSelectedReasons([]);
      setNotes('');
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const formattedReasons = selectedReasons.map(({ reason, id }) => ({ reason, id }));
    onConfirm({ reasons: formattedReasons, notes });
  };

  const onCheck = (data, checked) => {
    let newChecked = [...selectedReasons];
    if (!checked) newChecked = newChecked.filter((o) => o.id !== data.id);
    else newChecked = [...newChecked, data];
    setSelectedReasons(newChecked);
  };

  return (
    <StyledDialog open={isOpen} fullWidth onClose={onClose}>
      <Box py="25px" px="20px" display="flex" flexDirection="column" alignItems="center">
        <h1>{title}</h1>
        <Box mt="20px">
          <CheckBoxGroup
            data={reasons.map(({ __typename, ...opt }) => ({
              ...opt,
              label: opt.reason,
              value: opt.reason,
              checked: !!find(selectedReasons, { id: opt.id })
            }))}
            aria-label="reasons"
            disabled={reasonsLoading}
            withHelperText
            sx={{
              minHeight: 150,
              maxHeight: 300,
              marginBottom: '16px',
              flex: 1,
              overflow: 'auto'
            }}
            checkboxesContainerProps={{ sx: { overflow: 'auto' } }}
            onChange={onCheck}
          />
        </Box>
        <Box width="100%" mb="30px">
          <Input
            id="hire-notes-input"
            value={notes}
            label="Notes"
            multiline
            inputProps={{ rowsmax: 5 }}
            inputClassName="textarea"
            htmlInputClassName="textareaInput"
            labelClassName="label"
            onChange={(e) => setNotes(e.target.value)}
            testID="hire-modal-notes-input"
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          <Button variant="filled-secondary" className="action" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="filled-primary" className="action" onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default HireReasonsModal;
