const employer_all_steps = [
  'default',
  'companyName',
  'companyImage',
  'employeesCount',
  'address',
  'phone',
  /*'zipCode',*/
  'industry'
];
const employer_steps = employer_all_steps.filter((step) => step !== 'default');
const employee_all_steps = [
  'default',
  'userName',
  'userPhoto',
  'contacts',
  /*'zipCode',
  'phone',*/
  'birthday',
  'gender',
  'survey',
  'ethnicity',
  'skills',
  'experience',
  'cv'
];
const employee_steps = employee_all_steps.filter((step) => step !== 'default' && step !== 'survey');

export { employer_steps, employer_all_steps, employee_steps, employee_all_steps };
