import React from 'react';
import { SvgIcon } from 'components';

function MdArrowDropDown(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M7 10l5 5 5-5z"/>
    </SvgIcon>
  );
}

export default MdArrowDropDown;
