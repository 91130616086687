import React, { useCallback, useRef, useImperativeHandle, useMemo, memo, forwardRef } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import format from 'date-fns/format';
import { qaAttr } from 'utils';
import { useRestrictions, useCommonUI } from 'hooks';
import { Box, styled } from 'components';
import { Button, Deck, IconButton, Spinner } from 'components/shared';
import { MdClose, MdCheck, MdArrowBack, MdArrowForward, MdStarBorder } from 'components/icons';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import ApplicantActionsPopup from './ApplicantActionsPopup';
import { QH_MATCHES_TAB, CHAT_MESSAGE_TEMPLATE } from './data';
import { getCandidateActionName, getApplicantProfile, getApplicantUserId } from './utils';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  '& .swiperLabel': {
    position: 'absolute',
    top: 12,
    zIndex: 2,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 10,
    backgroundColor: '#fff'
  },
  '& .pass': {
    right: 12,
    color: '#BC2626'
  },
  '& .apply': {
    left: 12,
    color: '#529C59'
  },
  '& .mobileApplicantCard__name': {
    marginBottom: 6,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    color: '#3D3D3D'
  },
  '& .mobileApplicantCard__details': {
    // marginBottom: 6,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    // opacity: 0.8,
    color: '#6A6A6A'
  },
  '& .deckWrapper': {
    marginBottom: 15
  },
  '& .card': {
    width: '100%',
    maxWidth: 382
  },
  '& .cardButtonContainer': {
    position: 'relative',
    width: '100%'
  },
  '& .cardButton': {
    width: '100%',
    minHeight: 280,
    maxHeight: 600,
    height: 'calc(100vh - 260px)',
    padding: 35,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'grey'
  },
  '& .employeeName': {
    marginBottom: 6,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    color: '#3D3D3D'
  },
  '& .employee__details': {
    // marginBottom: 6,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    // opacity: 0.8,
    color: '#6A6A6A'
  },
  '& .mobileControllers': {
    width: '100%',
    padding: '0 16px 80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .applicantPopupTrigger': {
    position: 'absolute',
    right: 4,
    top: 4,
    zIndex: 1,
    width: 45,
    height: 45,
    fontSize: 34,
    backgroundColor: '#FFF'
  }
}));

const ApplicantsSwiper = forwardRef((props, ref) => {
  const {
    cancelLoading,
    cards,
    cardIndex,
    jobId,
    onReject,
    onAccept,
    onStar,
    onSkip,
    onReturn,
    onSelect,
    onRejectFromChatSuccess,
    starredApplicantsByUID,
    webUrl
  } = props;
  const { openChatModal } = useCommonUI();
  const { restrictions = {} } = useRestrictions();
  const canManageEmployee = restrictions.allowEmployeeActions;
  const actionNames = getCandidateActionName(webUrl);

  const swiperRef = useRef();

  const openChat = useCallback(
    (userId) => {
      openChatModal({
        dashBoardType: 'employer',
        variant: 'chat-only',
        jobId,
        candidateId: userId,
        onApplicantReject: onRejectFromChatSuccess,
        withHeaderActions: true,
        suggestMessageTemplate: CHAT_MESSAGE_TEMPLATE
      });
    },
    [jobId, onRejectFromChatSuccess, openChatModal]
  );

  const handleReturn = () => {
    const { item, index } = swiperRef.current?.swipeBack();
    onReturn(item, { index });
  };

  const handleSkip = () => {
    const nextIdx = cardIndex + 1;
    swiperRef.current?.jumpToCardIndex(nextIdx);
    onSkip(nextIdx);
  };

  const handleAccept = () => {
    const { item, index } = swiperRef.current?.swipeRight();
    onAccept(item, { index });
  };

  const handleReject = () => {
    const { item, index } = swiperRef.current?.swipeLeft();
    onReject(item, { index });
  };

  const handleStar = () => {
    const applicant = cards[cardIndex];
    const applicantUID = getApplicantUserId(applicant);
    const isStarred = starredApplicantsByUID[applicantUID] || applicant.starredJob;
    onStar(cards[cardIndex], !isStarred);
  };

  const handleSelect = useCallback(
    (item, index) => {
      onSelect(item, { index });
    },
    [onSelect]
  );

  const handleSwipeLeft = useCallback(
    (item, index) => {
      if (canManageEmployee) onReject(item, { index });
    },
    [onReject, canManageEmployee]
  );

  const handleSwipeRight = useCallback(
    (item, index) => {
      if (canManageEmployee) onAccept(item, { index });
    },
    [onAccept, canManageEmployee]
  );

  useImperativeHandle(ref, () => ({
    swipeBack: () => swiperRef.current.swipeBack(),
    handleAccept: () => handleAccept(),
    handleReject: () => handleReject(),
    handleSkip: () => handleSkip(),
    handleReturn: () => handleReturn()
  }));

  const deckLeftLabel = useMemo(() => <div className="swiperLabel pass">Pass</div>, []);
  const deckRightLabel = useMemo(() => <div className="swiperLabel apply">Apply</div>, []);
  const deckClassNames = useMemo(
    () => ({
      deckWrapper: 'deckWrapper',
      card: 'card'
    }),
    []
  );

  const getAppliedDate = (createdAt) => {
    if (createdAt) {
      const date = new Date(createdAt);
      const appliedToday = isToday(date);
      const appliedYesterday = isYesterday(date);
      if (appliedToday) return 'Applied today';
      if (appliedYesterday) return 'Applied yesterday';
      return format(date, "'Applied' MMM dd");
    }
    return '';
  };

  const renderDeckItem = useCallback(
    (applicant, { index, disabled: cardIsDisabled }) => {
      const { createdAt, starred, status, _type } = applicant;
      const applicantProfile = getApplicantProfile(applicant) || {};
      const { imageUrl = '', motto = '', name = '', zip } = applicantProfile;
      const userId = getApplicantUserId(applicant);

      return (
        <div className="cardButtonContainer">
          <ApplicantActionsPopup
            id={name || userId}
            onChat={() => openChat(userId)}
            withAccept={false}
            withReject={false}
            withStar={false}
          />
          <Button
            className="cardButton"
            style={{ backgroundImage: `url("${imageUrl || defaultEmployeeImg}")` }}
            onClick={() => handleSelect(applicant, index)}
            testID="candidates-table-deck-card-button"
          >
            <Box position="relative" zIndex={1} textAlign="center">
              {/*<div className={classes.mobileApplicantCard__trendIcon} style={{ marginBottom: 10 }}>*/}
              {/*<MdTrendingUp fontSize="inherit" color="inherit" />*/}
              {/*</div>*/}
              {/*<div className={classes.mobileApplicantCard__label}>QuickHire Match</div>*/}
              {/* <div className={classes.mobileApplicantCard__name}>{name}</div>
          <div className={classes.mobileApplicantCard__details}>
            {zip && <span>{zip} &bull;</span>} <span>{getAppliedDate(createdAt)}</span>
          </div> */}
              {/* <div className={classes.mobileApplicantCard__motto}>{motto}</div> */}
            </Box>
            {/* <div className={classes.mobileApplicantCard__overlay} /> */}
          </Button>
        </div>
      );
    },
    [handleSelect, openChat]
  );

  const controllersCommonProps = {
    variant: 'outlined',
    color: 'primary',
    withTooltip: true
  };

  const { createdAt } = cards[cardIndex] || {};
  const { name, zip } = getApplicantProfile(cards[cardIndex]) || {};

  return (
    <StyledRoot
      className="container"
      // justifyContent={!deck.length && !applicantsLoading ? 'center' : 'flex-start'}
    >
      <div style={{ display: !cards.length ? 'none' : 'block' }}>
        <Deck
          ref={swiperRef}
          items={cards}
          initIndex={cardIndex}
          renderItem={renderDeckItem}
          leftLabel={deckLeftLabel}
          rightLabel={deckRightLabel}
          classNames={deckClassNames}
          onSwipeLeft={handleSwipeLeft}
          onSwipeRight={handleSwipeRight}
        />
        <Box mb="40px">
          <div className="employeeName">{name}</div>
          <div className="employee__details">
            {zip && <span>{zip} &bull;</span>} <span>{getAppliedDate(createdAt)}</span>
          </div>
        </Box>
        {cards.length ? (
          <div className="mobileControllers">
            <IconButton
              {...controllersCommonProps}
              aria-label="previous"
              toolTipProps={{ title: 'Previous' }}
              disabled={cancelLoading || !canManageEmployee || cardIndex === 0}
              sx={{ width: 42, height: 42 }}
              onClick={handleReturn}
              testID="candidates-table-mobile-retract-button"
            >
              {cancelLoading ? <Spinner size={24} /> : <MdArrowBack />}
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label={actionNames.reject.title}
              toolTipProps={{ title: actionNames.reject.title }}
              disabled={!canManageEmployee}
              className="deckAction"
              sx={{ ml: '14px', width: 42, height: 42 }}
              onClick={handleReject}
              testID="candidates-table-mobile-reject-button"
            >
              <MdClose fontSize="inherit" />
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label={actionNames.accept.title}
              toolTipProps={{ title: actionNames.accept.title }}
              disabled={!canManageEmployee}
              classes="deckAction big"
              sx={{ ml: '14px', fontSize: 32 }}
              onClick={handleAccept}
              testID="candidates-table-mobile-accept-button"
            >
              <MdCheck fontSize="inherit" />
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label={actionNames.star.title}
              toolTipProps={{ title: actionNames.star.title }}
              disabled={!canManageEmployee}
              sx={{ ml: '14px', width: 42, height: 42 }}
              onClick={handleStar}
              testID="candidates-table-mobile-star-button"
            >
              <MdStarBorder />
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label="review later"
              toolTipProps={{ title: 'Review Later' }}
              disabled={cardIndex === cards.length - 1}
              sx={{ ml: '14px', width: 42, height: 42 }}
              onClick={handleSkip}
              testID="candidates-table-mobile-review-later-button"
            >
              <MdArrowForward />
            </IconButton>
          </div>
        ) : null}
      </div>
    </StyledRoot>
  );
});

ApplicantsSwiper.propTypes = {
  cancelLoading: PT.bool.isRequired,
  cards: PT.arrayOf(PT.any).isRequired,
  cardIndex: PT.number.isRequired,
  jobId: PT.number.isRequired,
  onReject: PT.func.isRequired,
  onAccept: PT.func.isRequired,
  onStar: PT.func.isRequired,
  onSkip: PT.func.isRequired,
  onReturn: PT.func.isRequired,
  onSelect: PT.func.isRequired,
  onRejectFromChatSuccess: PT.func,
  starredApplicantsByUID: PT.objectOf(PT.bool),
  webUrl: PT.string.isRequired
};

ApplicantsSwiper.defaultProps = {
  onRejectFromChatSuccess: () => {},
  starredApplicantsByUID: {}
};

export default memo(ApplicantsSwiper);
