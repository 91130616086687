import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Box, Dialog, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { POST_EMPLOYEE_ANSWER } from 'api';

function EmployerPromptDialog({
  className,
  employer,
  isOpen,
  job,
  onConfirm,
  onCancel,
  onClose,
  questions,
  ...rest
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [inProcessQuestionIndex, setInProcessQuestionIndex] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [postEmployeeAnswer, { loading: loadingPostMute = false }] =
    useMutation(POST_EMPLOYEE_ANSWER);

  useEffect(() => {
    setCurrentQuestionIndex(0);
    setInProcessQuestionIndex(null);
    setAnswers([]);
  }, [isOpen]);

  function passOffer() {
    onCancel(job);
  }

  async function handleAnswer(answer) {
    const userId = localStorage.getItem('userId');
    if (inProcessQuestionIndex == null && !loadingPostMute && questions.length && userId) {
      const allAnswers = [...answers, answer];
      setInProcessQuestionIndex(currentQuestionIndex);
      setAnswers(allAnswers);
      await postEmployeeAnswer({
        variables: {
          userId: parseInt(userId),
          answer,
          questionId: parseInt(questions[currentQuestionIndex].questions_id)
        }
      });

      if (questions[currentQuestionIndex + 1]) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setInProcessQuestionIndex(null);
      } else {
        onConfirm(job, allAnswers);
      }
    }
  }

  return (
    <Dialog open={isOpen} fullWidth className={className} onClose={onClose} {...rest}>
      {loadingPostMute && <Spinner size={30} className="loader" />}
      <Box p="60px 31px 50px">
        {questions.length && questions?.[currentQuestionIndex] && (
          <Box component="h2" mb="16px" className="title">
            {questions[currentQuestionIndex].name}
          </Box>
        )}
        <Box component="p" mb="24px" className="message">
          {`This is a job requirement from '${employer}'`}
        </Box>
        <Button
          variant="filled-primary"
          sx={{ width: '100%', height: 50, mb: '16px' }}
          onClick={() => handleAnswer(true)}
          testID="questions-modal-yes-button"
        >
          Yes, apply
        </Button>
        <Button
          variant="filled-secondary"
          sx={{ width: '100%', height: 50, mb: '8px' }}
          onClick={() => handleAnswer(false)}
          testID="questions-modal-no-button"
        >
          No, still apply
        </Button>
        <Button
          sx={{ width: '100%', height: 50, color: 'primary.main' }}
          onClick={passOffer}
          testID="questions-modal-pass-button"
        >
          Pass this offer
        </Button>
      </Box>
    </Dialog>
  );
}

EmployerPromptDialog.propTypes = {
  className: PropTypes.string.isRequired,
  employer: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  job: PropTypes.objectOf(PropTypes.any).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.any).isRequired
};

EmployerPromptDialog.defaultProps = {
  employer: ''
};

const Styled = styled(EmployerPromptDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: 382,
    width: 'calc(100% - 32px)',
    margin: '32px 16px'
  },
  '& .title': {
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '26px',
    textAlign: 'center',
    color: '#000'
  },
  '& .message': {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000'
  },
  '& .loader': {
    position: 'absolute',
    top: 10,
    right: 10
  }
}));

export default memo(Styled);
