const scheduleBtnPaddingX = 40;
const cardActionSize = 45;

export default ({ theme }) => ({
  '&.cardRoot': {
    position: 'relative',
    width: '100%',
    border: '1px solid rgba(0,0,0,0.1)',
    flexDirection: 'column',
    alignItems: 'initial',
    justifyContent: 'initial',
    borderRadius: 16,
    transition: 'box-shadow 300ms linear',
    backgroundColor: '#fff',
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.16)',
      '& .card__company': {
        visibility: 'hidden',
        [theme.breakpoints.down(theme.desktopBreakPoint)]: {
          visibility: 'visible'
        }
      },
      '& .card__actions': {
        '&.card__actions_default': {
          display: 'flex',
          '& .card__action': {
            '&:nth-of-type(1)': {
              animation: 'fadeInY 300ms cubic-bezier(0, 0, 0, 0.54) 100ms forwards',
              [theme.breakpoints.down(theme.desktopBreakPoint)]: {
                animation: 'none'
              }
            },
            '&:nth-of-type(2)': {
              animation: 'fadeInY 300ms cubic-bezier(0, 0, 0, 0.54) 300ms forwards',
              [theme.breakpoints.down(theme.desktopBreakPoint)]: {
                animation: 'none'
              }
            },
            '&:nth-of-type(3)': {
              animation: 'fadeInY 300ms cubic-bezier(0, 0, 0, 0.54) 500ms forwards',
              [theme.breakpoints.down(theme.desktopBreakPoint)]: {
                animation: 'none'
              }
            }
          },
          [theme.breakpoints.down(theme.desktopBreakPoint)]: {
            display: 'none'
          }
        }
      }
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      // minHeight: 'auto',
      height: '100%',
      border: 'none',
      boxShadow: '0 6px 16px 0 rgba(0,0,0,0.08)',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  },
  '& .bestFitLabel': {
    position: 'absolute',
    left: -27,
    top: 25,
    zIndex: 10,
    padding: '5px 16px',
    transform: 'rotateZ(90deg)',
    transformOrigin: 'bottom left',
    fontSize: 11,
    fontWeight: 'bold',
    color: '#FFF',
    boxShadow: '4px 2px 6px rgb(0 0 0 / 50%)',
    borderBottom: '2px solid #b2aef5',
    letterSpacing: 1.25,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: '#6962F0',
    backgroundImage:
      'radial-gradient(#9996f7 20%, transparent 20%), radial-gradient(#a19ef3 20%, transparent 20%)',
    backgroundPosition: '0 0, 2.5px 2.5px',
    backgroundSize: '5px 5px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      right: -2,
      zIndex: -1,
      background: '#4641a7',
      width: 4,
      height: '100%',
      borderRadius: '50%'
    }
  },
  '& .card__content': {
    width: '100%',
    minHeight: 388,
    display: 'flex',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minHeight: 'auto',
      // height is calculated in Deck
      height: 'inherit',
      flex: 1
    }
  },
  '& .footer__placeholder': {
    width: '100%',
    height: 80
  },
  '& .card__actions': {
    position: 'absolute',
    left: 3,
    right: 3,
    bottom: 3,
    padding: '60px 18px 25px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage:
      'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.91) 30%, #fff 100%)',
    borderRadius: '0 0 16px 16px'
  },
  '& .card__actions_default': {
    display: 'none'
  },
  '& .card__actions_withSchedule': {
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    '& .card__action': {
      '&:nth-of-type(1), &:nth-of-type(2)': {
        transform: 'translateY(0px) translateX(40px)',
        zIndex: -1
      }
    },
    '& .actions__container': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& .actions__innerContainer': {
        display: 'flex',
        '& .card__action': {
          position: 'absolute',
          zIndex: -1,
          '&:nth-of-type(1)': {
            left: scheduleBtnPaddingX
          },
          '&:nth-of-type(2)': {
            left: '50%',
            transform: 'translateX(-50%)',
            marginLeft: 0
          }
        }
      },
      '&:hover, &:focus': {
        '& .actions__innerContainer': {
          '& .card__scheduleBtn': {
            boxShadow: 'none',
            animation: 'toggleIn 300ms linear forwards',
            '& .scheduleBtnTitle': {
              visibility: 'hidden',
              opacity: 0,
              maxWidth: 0,
              transition: 'all 0s linear 0.2s',
              [theme.breakpoints.down(theme.desktopBreakPoint)]: {
                display: 'initial'
              }
            },
            [theme.breakpoints.down(theme.desktopBreakPoint)]: {
              boxShadow: ' 0 0 10px 0 rgba(0,0,0,0.06)',
              animation: 'none'
            }
          },
          '& .card__action': {
            '&:nth-of-type(1)': {
              animation: 'fadeInX 200ms cubic-bezier(0, 0, 0, 0.54) 200ms forwards',
              zIndex: 100,
              [theme.breakpoints.down(theme.desktopBreakPoint)]: {
                animation: 'none'
              }
            },
            '&:nth-of-type(2)': {
              animation: 'fadeInXCentered 200ms cubic-bezier(0, 0, 0, 0.54) 400ms forwards',
              zIndex: 100,
              [theme.breakpoints.down(theme.desktopBreakPoint)]: {
                animation: 'none'
              }
            }
          }
        }
      }
    }
  },
  '& .actions__innerContainer': {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  '& .card__action': {
    opacity: 0,
    transform: 'translateY(40px)',
    '&.card__scheduleBtn': {
      // opacity: 1,
      // transform: 'translateY(0px)',
    },
    '&:not(.card__scheduleBtn)': {
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        display: 'none'
      }
    }
  },
  '@keyframes fadeInY': {
    '100%': {
      opacity: 1,
      transform: 'translateY(0px)'
    }
  },
  '@keyframes fadeInX': {
    '100%': {
      opacity: 1,
      transform: 'translateX(0px)'
    }
  },
  '@keyframes fadeInXCentered': {
    '100%': {
      opacity: 1,
      transform: 'translateX(-50%)'
    }
  },
  '@keyframes toggleIn': {
    '0%': {
      padding: `0 ${scheduleBtnPaddingX}px`
    },
    '100%': {
      padding: 0,
      right: 0,
      width: cardActionSize
    }
  },
  '@keyframes toggleOut': {
    '0%': {
      padding: 0,
      right: 0,
      width: cardActionSize
    },
    '100%': {
      padding: `0 ${scheduleBtnPaddingX}px`
    }
  },
  '& .card__scheduleBtnContainer': {
    position: 'relative',
    width: 260,
    height: cardActionSize,
    marginLeft: -scheduleBtnPaddingX,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginLeft: 0
    }
  },
  '& .card__scheduleBtn': {
    position: 'absolute',
    top: 0,
    right: -scheduleBtnPaddingX,
    zIndex: 100,
    width: '100%',
    height: cardActionSize,
    padding: `0 ${scheduleBtnPaddingX}px`,
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 'bold',
    fontSize: 9,
    lineHeight: '10px',
    boxShadow: ' 0 0 10px 0 rgba(0,0,0,0.06)',
    animation: 'toggleOut 300ms linear forwards',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'static',
      maxWidth: 300,
      height: 55,
      animation: 'none'
    }
  },
  '& .scheduleBtnTitle': {
    visibility: 'visible',
    opacity: 1,
    maxWidth: 113,
    transition: 'all 0s linear 0.3s'
  },
  '& .scheduleBtnIconWrapper': {
    minWidth: cardActionSize,
    height: cardActionSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .schedule__title': {
    marginBottom: 12,
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: '12px',
    color: theme.palette.moreColors.grey_7
  },
  '& .card__btn': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    justifyContent: 'flex-start',
    borderRadius: 12
  },
  '&.card__applied': {
    border: '3px solid',
    borderColor: theme.palette.success.main
  },
  '& .card__photoArea': {
    position: 'relative',
    height: 160,
    width: '98%',
    // width: '100%',
    margin: '3px 0 0',
    alignSelf: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 12,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minHeight: 80,
      maxHeight: 400,
      height: 'calc(100vh - 550px)',
      margin: '4px 4px 0'
    }
  },
  '& .card__ratingContainer': {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translateX(-50%)',
    minWidth: 160,
    padding: '9px 9px 7px',
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: '12px',
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
    borderRadius: '4px 4px 0 0',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minWidth: 155,
      padding: 11,
      fontSize: 11,
      lineHeight: '12px'
    }
  },
  '& .card__ratingDelimiter': { color: '#E2E2E2' },
  '& .rating': {
    display: 'inline-flex',
    alignItems: 'center'
  },
  '& .rating__starWrapper': {
    position: 'relative'
  },
  '& .rating__star': {
    fontSize: 14,
    '&.fullStar': { color: theme.palette.primary.main },
    '&.emptyStar': { color: '#E2E2E2' }
  },
  '& .halfStar': {
    position: 'absolute',
    left: 0,
    width: '50%',
    zIndex: 1,
    overflow: 'hidden'
  },
  '& .card__mainContent': {
    flex: 1,
    padding: '7px 8px 18px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '12px 10px 21px'
    }
  },
  '& .card__name': {
    marginBottom: 5,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '19px',
    letterSpacing: 0,
    color: theme.palette.moreColors.black_2,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 6,
      fontSize: 24,
      lineHeight: '27px'
    }
  },
  '& .card__employerContacts': {
    marginBottom: 15,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: '16px',
    color: theme.palette.moreColors.grey_7,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 19,
      fontSize: 13,
      lineHeight: '14px'
    }
  },
  '& .card__employerAddress': {
    color: theme.palette.moreColors.purpleLight_1
  },
  // card__jobStats: {
  //   marginBottom: 18,
  //   display: 'flex',
  //   alignItems: 'center',
  //   fontWeight: 'bold',
  //   fontSize: 14,
  //   lineHeight: '17px',
  //   letterSpacing: 0,
  //   color: theme.palette.primary.main,
  //   [theme.breakpoints.down(theme.desktopBreakPoint)]: {
  //     marginBottom: 16,
  //     fontSize: 16,
  //     lineHeight: '17px',
  //   }
  // },
  '& .navIcon': {
    display: 'inline-block',
    paddingLeft: 9,
    paddingRight: 5,
    verticalAlign: 'text-bottom'
  },
  '& .card__jobDescription': {
    textAlign: 'left',
    fontSize: 10,
    lineHeight: '19px',
    letterSpacing: 0,
    color: theme.palette.moreColors.black_2,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 16,
      fontSize: 14,
      lineHeight: '19px'
    }
  },
  '& .card__company': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .card__companyLogo': {
    height: 48,
    minWidth: 48,
    border: '0.5px solid rgba(0,0,0,0.1)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '50%'
  },
  '& .card__companyName': {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 14,
      lineHeight: '17px'
    }
  },
  '& .status': {
    position: 'absolute',
    top: 12,
    left: 12,
    height: 32,
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 16,
    backgroundColor: '#fff',
    transition: 'background-color .25s ease-out',
    '&.applied': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.success.main
    },
    '&.rejected': {
      color: theme.palette.success.main
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .status__icon': {
    marginRight: 6
  },
  '& .mobileScheduleModal__header': {
    padding: '20px 18px 8px',
    paddingRight: 24,
    display: 'flex',
    alignItems: 'flex-start'
  },
  '& .mobileScheduleModal__title': {
    marginBottom: 11,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.moreColors.black_2
  },
  '& .mobileScheduleModal__subTitle': {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '15px',
    color: theme.palette.moreColors.purpleLight_1
  },
  '& .mobileScheduleModal__confirmTitle': {
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.moreColors.black_2
  },
  '& .mobileScheduleModal__confirmDate': {
    marginBottom: 25,
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: '14px',
    color: theme.palette.moreColors.purpleLight_1
  },
  '& .scheduleModal': {
    width: '100%',
    maxWidth: 400
  },
  '& .shareButton': {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 100,
    width: 35,
    height: 35,
    fontSize: 18,
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  }
});
