import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/shared';
import { MdAddChart, MdClose, MdPersonPin } from 'components/icons';
import ChartTitle from 'components/Grow/employer/teamStructure/ChartTitle';
import EmployeeCard from './EmployeeCard';
import EmployerGrowContext from '../../EmployerGrowContext';

function TeamChartColumn({ name, employeeProfiles, index, id }) {
  const {
    isEditable,
    deleteColumn,
    updateEmployerCtx,
    columnsToUpdate = {},
    currentChartIdx,
    employerCharts
  } = useContext(EmployerGrowContext);

  const handleOnBlur = (columnIdx, columnId) => (inputValue) => {
    updateEmployerCtx({
      columnsToUpdate: {
        ...columnsToUpdate,
        [columnId]: { name: inputValue, index: columnIdx, id: columnId }
      }
    });
  };

  return (
    <div className="columnContainer">
      <div className="columnContainerName">
        <ChartTitle
          FormControlProps={{ sx: { width: isEditable ? '80%' : '100%' } }}
          sx={{
            borderBottom: '1px solid #e4e4e4',
            padding: '5px',
            paddingBottom: 0,
            marginBottom: '10px',
            '& .StyledInput-inputBase__input': {
              padding: '.75rem!important'
            }
          }}
          handleOnBlur={handleOnBlur(index, id)}
          titleValue={name}
          testID="chart-col-input"
        />
        {isEditable && (
          <IconButton
            color="primary"
            onClick={deleteColumn(index, currentChartIdx, employerCharts)}
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            testID="chart-col-delete-col-button"
          >
            <MdClose />
          </IconButton>
        )}
      </div>
      {employeeProfiles &&
        employeeProfiles.map((employeeProfile, idx) => (
          <EmployeeCard
            employeeProfile={employeeProfile}
            index={idx}
            id={employeeProfile.id}
            key={employeeProfile.id}
            columnIndex={index}
          />
        ))}
    </div>
  );
}

TeamChartColumn.propTypes = {
  name: PropTypes.string,
  employeeProfiles: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number,
  id: PropTypes.number
};

TeamChartColumn.defaultProps = {
  name: '',
  employeeProfiles: [],
  index: 0,
  id: 0
};

export default TeamChartColumn;
