import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { map, find, filter } from 'lodash';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import ConnectionProfile from 'components/Grow/employee/ConnectionProfile';
import styles from 'styles/Grow/employee';
import {
  GET_CAREER_DEVELOPMENT_WITH_HEALTH_SCORE,
  GET_EMPLOYEES_BY_JOB_TYPE_ID,
  GET_EMPLOYEES_BY_INDUSTRY_ID,
  GET_EMPLOYEE_STARRED,
  CAREER_DEV_BY_USER_ID,
  POST_EMPLOYEE_STARRED
} from 'api';

const StyledProfileRoot = styled('div')(styles);
const StyledRoot = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  maxWidth: 474 + 16 * 2,
  margin: '0 auto',
  padding: '0 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // justifyContent: 'center'
  '& .title': {
    marginBottom: 18,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 400,
    lineHeight: '42px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .connectionsContainer': {
    width: '100%',
    overflow: 'auto',
    flex: 1
  },
  '& .connectionItem': {
    padding: '10px 0',
    borderBottom: '1px solid #E4E4E4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .connectionItem__img': {
    width: 45,
    height: 45,
    minWidth: 45,
    minHeight: 45,
    marginRight: 10,
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    objectFit: 'cover'
  },
  '& .connectionItem__name': {
    fontSize: 12,
    fontWeight: 800
  },
  '& .connectionItem__pos': {
    fontSize: 10,
    color: 'grey'
  },
  '& .action': {
    width: 58,
    height: 22,
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 11,
    borderRadius: 10,
    '&:not(:first-of-type)': {
      marginLeft: 12
    }
  }
}));

const formatConnections = (arr) =>
  arr.map((o) => {
    const { user_id, profile_id, name, imageUrl, experience } = o;
    const currentJob = experience.filter((exp) => exp?.currentJob)?.[0] || {};
    const companyInfo = {
      companyName: currentJob?.name || null,
      companyPosition: currentJob?.pos || null
    };

    return {
      user_id,
      profile_id,
      name,
      imageUrl,
      experience,
      ...companyInfo
    };
  });

const filterConnectionsByUnStarred = (starred, connections) =>
  filter(connections, (o) => !find(starred, ['profile_id', o.profile_id]));

function EmployeeProgressConnections(props) {
  const [suggestedConnections, setSuggestedConnections] = useState([]);
  const [connectionCareerDev, setConnectionCareerDev] = useState(null);

  const [fetchCareerDev, { data: careerDevData }] = useLazyQuery(
    GET_CAREER_DEVELOPMENT_WITH_HEALTH_SCORE,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );
  const {
    futureJobTypeId,
    industryId,
    id: careerDevId
  } = careerDevData?.careerDevelopmentByUserId || {};

  const [fetchConnectionCareerDev] = useLazyQuery(CAREER_DEV_BY_USER_ID, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const [fetchConnectionsByJobTypeId] = useLazyQuery(GET_EMPLOYEES_BY_JOB_TYPE_ID, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  const [fetchConnectionsByIndustryId] = useLazyQuery(GET_EMPLOYEES_BY_INDUSTRY_ID, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  const [fetchStarredConnection] = useMutation(GET_EMPLOYEE_STARRED, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const [starConnection] = useMutation(POST_EMPLOYEE_STARRED);

  const getCareerDev = () => {
    const uid = getUserId();
    fetchCareerDev({ variables: { userId: Number(uid) } });
  };

  const getConnections = async () => {
    const starredRes = await fetchStarredConnection({
      variables: { careerDevelopmentId: careerDevId }
    });
    const fetchedStarredConnections = (starredRes?.data?.getEmployeeStarred || [])
      .map((o) => o.employeeProfile)
      .filter(Boolean);
    const formattedStarred = formatConnections(fetchedStarredConnections);

    const futureRes = await fetchConnectionsByJobTypeId({
      variables: { jobTypeId: futureJobTypeId }
    });
    const futureConnections = futureRes?.data?.employeesByJobTypeId || [];

    if (!futureConnections.length && industryId) {
      const byIndRes = await fetchConnectionsByIndustryId({ variables: { industryId } });
      const connectionsByIndustry = byIndRes?.data?.employeesByIndustryId || [];
      const formatted = formatConnections(connectionsByIndustry);
      const usStarred = filterConnectionsByUnStarred(formattedStarred, formatted);
      setSuggestedConnections(usStarred);
    } else {
      const formatted = formatConnections(futureConnections);
      const usStarred = filterConnectionsByUnStarred(formattedStarred, formatted);
      setSuggestedConnections(usStarred);
    }
  };

  useEffect(() => {
    getCareerDev();
  }, []);

  useEffect(() => {
    if (futureJobTypeId) getConnections();
  }, [futureJobTypeId]);

  const handleStar = async (connectionPID) => {
    await starConnection({
      variables: {
        careerDevelopmentId: Number(careerDevId),
        employeeProfileId: Number(connectionPID)
      }
    });
    getConnections();
    if (connectionCareerDev) setConnectionCareerDev(null);
  };

  const showProfile = async (connectionUID) => {
    const res = await fetchConnectionCareerDev({
      variables: { connectionUserId: Number(connectionUID) }
    });
    const profile = res.data?.connectionCareerDevelopmentByUserId;
    setConnectionCareerDev(profile);
  };

  const closeProfile = () => setConnectionCareerDev(null);

  const renderConnection = (connection, i) => (
    <div key={`connection__${i}`} className="connectionItem">
      <Box display="flex" alignItems="center">
        <img src={connection.imageUrl} alt="" className="connectionItem__img" />
        <div>
          <div className="connectionItem__name">{connection.name}</div>
          {connection.companyName && (
            <div className="connectionItem__pos">
              {`${connection.companyName} | ${connection.companyPosition}`}
            </div>
          )}
        </div>
      </Box>
      <div>
        <Button
          variant="filled-primary"
          className="action"
          onClick={() => handleStar(connection.profile_id)}
        >
          Add +
        </Button>
        <Button
          variant="filled-primary"
          className="action"
          onClick={() => showProfile(connection.user_id)}
        >
          View
        </Button>
      </div>
    </div>
  );

  return (
    <StyledRoot>
      <h1 className="title">Suggested Connections</h1>
      {suggestedConnections.length ? (
        <div className="connectionsContainer">{map(suggestedConnections, renderConnection)}</div>
      ) : (
        <Box py="20px" textAlign="center">
          No Connections at the moment
        </Box>
      )}
      {connectionCareerDev && (
        <StyledProfileRoot>
          <ConnectionProfile
            employeeCareerDev={connectionCareerDev}
            // next={navNextConnection}
            // prev={navPrevConnection}
            closeModal={closeProfile}
            // openJob={openJob}
            onStar={handleStar}
            // onUnStar={unStarConnection}
            // activeJobIdx={activeJobIdx}
            // jobTypes={jobTypes}
            withNavigateActions={false}
            suggestedJobs={[]}
            canNavigate={false}
            isStarred={false}
            isStarredLoading={false}
            starredProfileIdLoading={null}
          />
        </StyledProfileRoot>
      )}
    </StyledRoot>
  );
}

EmployeeProgressConnections.propTypes = {};

export default EmployeeProgressConnections;
