import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { getUserId } from 'utils';
import { SET_TIMEZONE } from 'api';
import { TimeZoneContext } from './context';

// eslint-disable-next-line react/prop-types
function TimeZoneProvider({ children, context, initTimeZone = '' }) {
  const client = useApolloClient();
  const [timeZone, setTimeZone] = useState(initTimeZone);
  console.log('%c[⌛ timezone]', 'color:green', timeZone);

  const postUserTimeZone = useCallback(async (userId, tz) => {
    return await client.query({
      query: SET_TIMEZONE,
      variables: {
        user_id: Number(userId),
        timeZone: tz
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
  }, []);

  const updateUserTimeZone = useCallback(async (newTZ, uId) => {
    const userId = uId || getUserId();

    if (newTZ) {
      if (userId) {
        await postUserTimeZone(userId, newTZ);
        setTimeZone(newTZ);
      } else {
        throw new Error('No userId found');
      }
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      postUserTimeZone,
      setTimeZone,
      timeZone,
      updateUserTimeZone
    }),
    [timeZone]
  );

  const Context = context || TimeZoneContext;

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

TimeZoneProvider.propTypes = {
  children: PT.node.isRequired
};

export default TimeZoneProvider;
