const wrappers = ({ theme }) => ({
  '&.container': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  '& .contentWrapper': {
    padding: '50px 30px 30px',
    overflow: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '24px 18px 20px'
    }
  },
  '& .layoutColumn': {
    width: '100%',
    '&:first-of-type': {
      maxWidth: 256
    },
    '&:not(:first-of-type)': {
      width: '100%',
      marginLeft: 30
    }
  },
  '& .header': {
    padding: '0 30px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 18px 6px'
    }
  },
  '& .imageContainer': {
    marginBottom: 56,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 25
    }
  },
  '& .questionsContainer': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 30
    }
  },
  '& .formItem': {
    marginBottom: 25,
    '&.row': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        flexWrap: 'wrap',
        marginLeft: -20,
        marginTop: -20
      },
      '& .rowInput:not(:first-of-type)': {
        marginLeft: 25,
        [theme.breakpoints.down(theme.desktopBreakPoint)]: {
          marginLeft: 0
        }
      },
      '& .rowInput': {
        width: '100%',
        '&.col25': {
          width: '25%',
          [theme.breakpoints.down(theme.desktopBreakPoint)]: {
            width: '50%'
          }
        },
        [theme.breakpoints.down(theme.desktopBreakPoint)]: {
          width: '50%',
          paddingLeft: 20,
          paddingTop: 20,
          '&.mobOrder-1': {
            order: 1
          },
          '&.mobOrder-2': {
            order: 2
          },
          '&.mobOrder-3': {
            order: 3
          },
          '&.mobOrder-4': {
            order: 4
          },
          '&.mobFullWidth': {
            width: '100%'
          }
        }
      }
    }
  },
  '& .loaderOverlay': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)'
  },
  '& .chips': {
    marginTop: -13,
    display: 'flex',
    flexWrap: 'wrap'
  },
  '& .chips__item': {
    marginTop: 13,
    padding: '9px 8px',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#FFF',
    background: '#8782BC',
    borderRadius: 6
  },
  '& .avatarEditor': {
    border: '1px solid #ECECF5',
    cursor: 'move',
    '&$error': {
      borderColor: theme.palette.error.main
    }
  },
  '& .imagePreview': {
    height: 200,
    width: 200,
    objectFit: 'cover',
    backgroundColor: '#F6F5FA',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: 227,
      height: 227
    }
  }
});

const buttons = ({ theme }) => ({
  '& .jobAction': {
    minWidth: 137,
    height: 43,
    padding: '0 12px',
    marginTop: 8,
    borderRadius: 22,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minWidth: 88,
      height: 28,
      fontSize: 11
    },
    '&:not(:last-child)': {
      marginRight: 25,
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        marginRight: 15
      }
    },
    '&.danger': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  },
  '& .mainAction': {
    padding: '15px 19px',
    fontSize: 14,
    lineHeight: '16px',
    borderRadius: 3,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 13,
      lineHeight: '14px',
      borderRadius: 7
    }
  },
  '& .chipsBtn': {
    marginTop: 22,
    fontSize: 12
  },
  '& .questionsButton': {
    width: '100%',
    maxWidth: 256,
    height: 48,
    borderRadius: 5,
    fontSize: 13,
    lineHeight: '15px'
  }
});

const form = ({ theme }) => ({
  '& .label': {
    marginBottom: 13,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: '#A4A3A4',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 17
    }
  },
  '& .value': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '18px',
    color: '#3D3D3D'
  },
  '& .select': {
    borderRadius: 5
  },
  '& .select__input': {
    '&.StyledInput-inputBase__input': {
      padding: '6px 0',
      paddingLeft: 6,
      paddingRight: 24
    }
  },
  '& .textarea': {
    height: 105,
    alignItems: 'flex-start',
    borderRadius: 5
  },
  '& .textareaInput': {
    '&.StyledInput-inputBase__input': {
      padding: 6
    }
  },
  '& .currencyInput': {
    maxWidth: 80,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 'initial'
    }
  }
});

const text = ({ theme }) => ({
  '& .title': {
    fontSize: 22,
    lineHeight: '26px',
    color: '#6A6A6A',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .imageTypeTip': {
    marginTop: 4,
    marginBottom: 20,
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: '10px',
    color: '#A4A3A4',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginTop: 14,
      marginBottom: 24,
      fontSize: 10,
      lineHeight: '11px'
    }
  },
  '& .disclaimerText': {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '19px',
    color: '#A4A3A4'
  },
  '& .questionsTitle': {
    marginBottom: 23,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '13px',
    color: theme.palette.primary.main
  }
});

export default ({ theme }) => ({
  ...wrappers({ theme }),
  ...buttons({ theme }),
  ...form({ theme }),
  ...text({ theme })
});
