import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { trimStr } from 'utils';
import { Button, Input, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { SET_COMPANY_NAME } from 'api';

const NAME = 'name';

function CompanyNameForm({ form, onChange, onGoToNextStep }) {
  const [postCompanyName, { loading }] = useMutation(SET_COMPANY_NAME);
  const { $, set, withValidation } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: [{ presence: { allowEmpty: false } }]
    }
  });

  const handleFieldChange = (field) => (e) => {
    set(field, e.target.value);
    onChange({ ...form, [field]: e.target.value });
  };

  const next = () => {
    onGoToNextStep(
      withValidation((attrs) => postCompanyName({ variables: { [NAME]: trimStr(attrs[NAME]) } }))
    );
  };

  return (
    <>
      <Input
        id="companyName"
        {...$(NAME)}
        placeholder="Company Inc."
        autoComplete="off"
        required
        withHelperText
        highlightInputOnError={false}
        disabled={loading}
        sx={{ mb: '20px' }}
        analyticParams={{
          key: 'Employer name focused (onboarding)',
          trigger: 'focus'
        }}
        onChange={handleFieldChange(NAME)}
        testID="onboarding-name-input"
      />
      <Button
        variant="filled-primary"
        width="100%"
        height={50}
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

CompanyNameForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CompanyNameForm;
