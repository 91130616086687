import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { qaAttr } from 'utils';
import { Backdrop, Box, InputAdornment, Switch, styled } from 'components';
import { MdAdd, MdExpandMore, MdSend } from 'components/icons';
import { Button, IconButton, Input } from 'components/shared';
// import jobStyles from 'styles/dialogs/CreateJobDialog';
import styles from 'styles/Dashboard/EmployerManual';
import arrow from 'assets/img/arrow_straight.png';
import curvedArrow from 'assets/img/right_top_curved_arrow.png';

// const useStyles = makeStyles((theme) => createStyles(css(theme)));
// const useJobFormStyles = makeStyles((theme) =>
//   createStyles({
//     ...jobFormCss(theme)
//   })
// );

function EmployerDashboardManual({ isOpen, onComplete }) {
  const [mobileOverlayHeight, setMobileOverlayHeight] = useState(0);
  const contentRef = useCallback(
    (node) => {
      if (node !== null && !mobileOverlayHeight) {
        setMobileOverlayHeight(node.clientHeight);
      }
    },
    [mobileOverlayHeight]
  );

  function renderForm() {
    return (
      <div className={classes.formContainer}>
        <Box display="flex" flexDirection="column">
          <div className={jobFormClasses.dialogHeader}>
            <Box minWidth={36} />
            <h2 className={jobFormClasses.dialogTitle}>Post a Job</h2>
            <div className={classes.submitBtn__desktop}>
              <IconButton variant="outlined" color="primary" onClick={() => {}}>
                <MdSend />
              </IconButton>
            </div>
            <div className={classes.submitBtn__mob}>
              <IconButton edge="end" color="primary" onClick={() => {}}>
                <MdSend />
              </IconButton>
            </div>
          </div>
          <div className={jobFormClasses.dialogContent}>
            <Box component="label" htmlFor="job-image-input" display="block" mb="36px">
              <Button
                variant="filled-secondary"
                component="span"
                sx={{ height: 50, width: '100%', fontSize: 14 }}
              >
                Select Cover Image (Optional)...
              </Button>
            </Box>
            <Input
              readOnly
              variant="textfield"
              label="Job Title"
              FormControlProps={{ sx: { mb: '29px' } }}
            />
            <Input
              readOnly
              variant="textfield"
              label="Location"
              FormControlProps={{ sx: { mb: '29px' } }}
            />
            <Box width="100%" mb="29px" display="flex">
              <Input
                readOnly
                variant="textfield"
                label="Pay Range"
                FormControlProps={{
                  sx: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }
                }}
              />
              <Input
                readOnly
                variant="textfield"
                label="Hire Date"
                FormControlProps={{
                  sx: {
                    ml: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }
                }}
              />
            </Box>
            <Input
              readOnly
              variant="textfield"
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>Job Description</span>
                  <span className={jobFormClasses.labelHint}>0/250</span>
                </Box>
              }
              FormControlProps={{ mb: '29px', padding: 0 }}
            />
            <Input
              readOnly
              variant="textfield"
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>Requirements</span>
                  <span className={jobFormClasses.labelHint}>0/250</span>
                </Box>
              }
              FormControlProps={{ mb: '29px', padding: 0 }}
            />
            {/*<Input*/}
            {/*readOnly*/}
            {/*variant="textfield"*/}
            {/*label="Location"*/}
            {/*endAdornment={*/}
            {/*<InputAdornment>*/}
            {/*<Switch*/}
            {/*color="primary"*/}
            {/*classes={{ root: jobFormClasses.switcher__root }}*/}
            {/*/>*/}
            {/*</InputAdornment>*/}
            {/*}*/}
            {/*boxProps={{ mb: '29px' }}*/}
            {/*/>*/}
            <Input readOnly variant="textfield" label="Address" FormControlProps={{ mb: '29px' }} />
            <Input
              readOnly
              variant="textfield"
              FormControlProps={{ mb: '29px' }}
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>End Posting Date</span>
                  <MdExpandMore color="primary" />
                </Box>
              }
            />
            <Input
              readOnly
              variant="textfield"
              label="Maximum Application Amount"
              FormControlProps={{ mb: '29px' }}
            />
            <Button
              variant="filled-secondary"
              startIcon={<MdAdd fontSize="inherit" />}
              sx={{
                height: 50,
                width: '100%',
                mb: '29px',
                paddingLeft: 16,
                fontSize: 14,
                justifyContent: 'flex-start'
                // startIcon: { fontSize: 16 }
              }}
              onClick={() => {}}
            >
              Add Option Applicant Question
            </Button>
            <Box component="p" fontSize={14} lineHeight="24px" color="moreColors.black_1">
              By pressing Post, you agree that this job will be posted and applications will be
              processed in accordance with QuickHire's Privacy Policy and Terms of Service. You
              consent to QuickHire informing a user that you have opened, viewed or made a decision
              regarding the user's application.
            </Box>
          </div>
        </Box>
      </div>
    );
  }

  return (
    <Backdrop open={isOpen} classes={{ root: 'backdrop__root' }}>
      <div ref={contentRef}>
        <Box className="desktopTipRow" top={85}>
          <div className="leftTipCol" style={{ justifyContent: 'flex-end' }}>
            <span className="tip">Click here to add job image</span>
            <img src={arrow} alt="" style={{ marginRight: -25 }} />
          </div>
          <Box width="100%" maxWidth={418} />
          <div className="rightTipCol" />
        </Box>
        <Box className="desktopTipRow" bottom={225}>
          <div className="leftTipCol" style={{ justifyContent: 'flex-end' }}>
            <span className="tip">Add job-related questions</span>
            <img src={arrow} alt="" style={{ marginRight: -25 }} />
          </div>
          <Box width="100%" maxWidth={418} />
          <div className="rightTipCol" />
        </Box>
        <Box className="desktopTipRow" top={30}>
          <div className="leftTipCol" />
          <Box width="100%" maxWidth={418} />
          <div className="rightTipCol" style={{ display: 'block' }}>
            <div>
              <img src={curvedArrow} alt="" style={{ marginLeft: -10 }} />
            </div>
            <Box maxWidth={219} pl="35px">
              <Box mb="16px" className="tip">
                When done, click here to post a job
              </Box>
              <Button
                variant="filled-primary"
                boxProps={{ height: 50, width: '100%' }}
                onClick={onComplete}
                {...qaAttr('got-it-button')}
              >
                Got it
              </Button>
            </Box>
          </div>
        </Box>

        <Backdrop
          open={true}
          classes={{ root: 'mobileOverlay' }}
          style={{ ...(mobileOverlayHeight ? { height: mobileOverlayHeight } : {}) }}
        >
          <div className="mobileTipsContainer">
            <div className="mobilePostBtnTip">
              <div className="tip">When done, click here to post a job</div>
              <img src={arrow} alt="" />
              <IconButton edge="end" color="primary" onClick={() => {}}>
                <MdSend />
              </IconButton>
            </div>
            <div className="mobileImgUploadTipContainer">
              <Box component="label" display="block">
                <Button
                  variant="filled-secondary"
                  component="span"
                  sx={{
                    height: 50,
                    width: '100%',
                    mb: '12px',
                    fontSize: 14,
                    backgroundColor: '#ECECF5 !important'
                  }}
                >
                  Select Cover Image (Optional)...
                </Button>
              </Box>
              <Box textAlign="center">
                <img src={arrow} alt="" style={{ marginBottom: 12, transform: 'rotate(-90deg)' }} />
                <div className="tip">Click here to add job image</div>
              </Box>
            </div>
            <div className="mobileGotItBtn">
              <Button
                variant="filled-primary"
                sx={{ height: 50, width: 219 }}
                onClick={onComplete}
                testID="got-it-button"
              >
                Got it
              </Button>
            </div>
            <div className="mobileQuestionsTipContainer">
              <Box textAlign="center">
                <div className="tip" style={{ marginBottom: 16 }}>
                  Add job-related questions
                </div>
                <img src={arrow} alt="" style={{ marginBottom: 24, transform: 'rotate(90deg)' }} />
                <Button
                  variant="filled-secondary"
                  startIcon={<MdAdd fontSize="inherit" />}
                  sx={{
                    height: 50,
                    width: '100%',
                    mb: '29px',
                    paddingLeft: 16,
                    fontSize: 14,
                    justifyContent: 'flex-start',
                    backgroundColor: '#ECECF5 !important'
                    // startIcon: { fontSize: 16 }
                  }}
                  boxProps={{}}
                  onClick={() => {}}
                >
                  Add Option Applicant Question
                </Button>
              </Box>
            </div>
          </div>
        </Backdrop>

        {renderForm()}
      </div>
    </Backdrop>
  );
}

EmployerDashboardManual.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default memo(EmployerDashboardManual);
