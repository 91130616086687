import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useGoogleLogin } from 'react-google-login';
import FacebookAuth from 'react-facebook-auth';
import isEmpty from 'lodash/isEmpty';
import {
  checkReferrerPage,
  employeeStartPage,
  employerStartPage,
  getRoutes,
  getUrlParameter,
  getLocaleTimeZone,
  landingPublicPath,
  qaAttr
} from 'utils';
import { gtmTrackOnSignIn, gtmTrackOnSignUp } from 'utils/gtm';
import { track } from 'utils/segmentAnalytics';
import { useRestrictions, useTimeZone, useAuth } from 'hooks';
import { Box, Divider, FormHelperText, styled } from 'components';
import { Button, IconButton, Input } from 'components/shared';
import { MdArrowBack, MdCheck, MdEmail } from 'components/icons';
import { useForm } from 'components/form';
import styles from 'styles/Login';
import {
  client,
  ASK_PASSWORD_RESET,
  GET_USER_PROFILE,
  LOGIN,
  POST_NEW_PASSWORD,
  POST_VERIFY,
  SIGN_UP,
  SET_TIMEZONE,
  RESEND_VERIFICATION_MAIL,
  fetchEmployerProfile,
  fetchEmployeeProfile
} from 'api';

const StyledRoot = styled('div')(styles);

const GOOGLE_CLIENT_ID = '945727527332-82okcq827179d1slghvfktkfkvjgc57p.apps.googleusercontent.com';
const FACEBOOK_CLIENT_ID = '700927053798332';
const { LANDING_PUBLIC_PATH } = process.env;

const ROUTES = getRoutes();
const ALLOWED_PLAN_IDS = [1, 2, 3];

const getReferrerLocation = (location) => location?.state?.from?.location || {};

function LoginForm(props) {
  const { page } = props;
  const { signin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { setRestrictions = () => {} } = useRestrictions();
  const defaultForm = { email: '', password: '', passwordConfirm: '' };
  const defaultFormConfig = {
    initial: defaultForm,
    validations: {
      email: ['presence', 'email'],
      password: 'presence'
    }
  };
  const [emailForResendVerification, setEmailForResendVerification] = useState(null);
  const [resentEmailAttempts, setResentEmailAttempts] = useState(0);
  const [currentPage, setCurrentPage] = useState(page);
  const { $, attrs, set, useConfig, validate, reset, getError } = useForm({ initial: defaultForm });
  const [login, { data = {}, error: signInError = {}, loading: loadingContinue = false }] =
    useMutation(LOGIN);
  const [
    signUp,
    { data: signUpData = {}, error: signUpError = {}, loading: loadingSignUp = false }
  ] = useMutation(SIGN_UP);
  const [askPwdReset, { data: askPwdResetData = {}, error: askPwdResetError = {} }] =
    useMutation(ASK_PASSWORD_RESET);
  const [resetPwd, { data: resetPwdData = {}, error: resetPwdError = {} }] =
    useMutation(POST_NEW_PASSWORD);
  const [backendError, setBackendError] = useState('');

  const getPlanIdParam = () => {
    const search = getReferrerLocation(location).search || location.search || '';
    const planIdParam = search ? getUrlParameter(search, 'planId') : '';
    const planId = planIdParam ? Number(planIdParam) : undefined;
    return planId && ALLOWED_PLAN_IDS.indexOf(planId) !== -1 ? planId : undefined;
  };

  const handleGoogleSignIn = function (dataParam) {
    const { googleId, tokenId, accessToken, tokenObj, profileObj } = dataParam;
    setBackendError('');

    if (tokenId && profileObj?.email) {
      const isSignIn = currentPage === 'sign-in' || currentPage === 'sign-up-verify';
      if (isSignIn) {
        login({ variables: { email: profileObj?.email, googleToken: tokenId } });
      } else {
        signUp({
          variables: { email: profileObj?.email, googleToken: tokenId, planId: getPlanIdParam() }
        });
      }
    }
  };
  const loginFb = (dataParam) => {
    const { accessToken, email } = dataParam;
    setBackendError('');

    if (accessToken && email) {
      const isSignIn = currentPage === 'sign-in' || currentPage === 'sign-up-verify';
      if (isSignIn) {
        login({ variables: { email, fbToken: accessToken } });
      } else {
        signUp({ variables: { email, fbToken: accessToken, planId: getPlanIdParam() } });
      }
    }
  };
  const handleGoogleFailure = function (dataParam) {
    console.log('handleGoogleFailure', dataParam);
  };
  const { signIn, loaded } = useGoogleLogin({
    onSuccess: handleGoogleSignIn,
    onFailure: handleGoogleFailure,
    clientId: GOOGLE_CLIENT_ID
  });

  const authAsEmployee = async (employeeProfile) => {
    const { profile_id, profileWizard, regStep = '' } = employeeProfile;
    localStorage.setItem('profileId', profile_id);
    track('User Signed In');

    if (profileWizard) {
      const {
        pathname: referrer,
        search: referrerSearch = '',
        state: referrerState
      } = getReferrerLocation(location);

      signin({
        callback: () => {
          navigate((referrer || employeeStartPage) + referrerSearch, { state: referrerState });
        }
      });
    } else {
      const referrerLocation = getReferrerLocation(location);
      signin({
        callback: () => {
          navigate(ROUTES.onboarding.default, {
            state: { from: { location: referrerLocation }, regStep: `employee_${regStep}` }
          });
        }
      });
    }
  };

  const authAsEmployer = async (employerProfile) => {
    const { profile_id, profileWizard, regStep = '' } = employerProfile;
    const planIdParam = getPlanIdParam();
    localStorage.setItem('profileId', profile_id);
    track('User Signed In');

    if (profileWizard) {
      let {
        pathname: referrer,
        // eslint-disable-next-line prefer-const
        search: referrerSearch = '',
        state: referrerState
      } = getReferrerLocation(location);

      // if planId presence - navigate to profile and show plans modal
      referrer = planIdParam ? ROUTES.employer.profile : referrer;
      referrerState = planIdParam ? { planId: planIdParam } : referrerState;

      signin({
        callback: () => {
          navigate((referrer || employeeStartPage) + referrerSearch, { state: referrerState });
        }
      });
    } else {
      const referrerLocation = getReferrerLocation(location);
      signin({
        callback: () => {
          navigate(ROUTES.onboarding.default, {
            state: { from: { location: referrerLocation }, regStep: `employer_${regStep}` }
          });
        }
      });
    }
  };

  const authUser = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && userId) {
      const [employeeProfile] = await fetchEmployeeProfile({
        queryParams: { fetchPolicy: 'cache-first' }
      });

      if (employeeProfile) {
        authAsEmployee(employeeProfile);
        return;
      }

      const [employerProfile] = await fetchEmployerProfile({
        queryParams: { fetchPolicy: 'cache-first' }
      });

      if (employerProfile) {
        authAsEmployer(employerProfile);
      } else {
        track('User Signed In');
        const referrerLocation = getReferrerLocation(location);
        signin({
          callback: () => {
            navigate(ROUTES.onboarding.default, {
              state: { from: { location: referrerLocation } }
            });
          }
        });
      }
    }
  };

  function completeSignIn(token = '', userId = '') {
    gtmTrackOnSignIn();
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    localStorage.removeItem('post_signup_referrer');
    authUser();
  }

  useConfig(() => {
    const repeatPwdValidation = {
      password: 'presence',
      passwordConfirm: {
        rules: [
          'presence',
          function (value, { attrs }) {
            if (value && value !== attrs.password) return 'Password do not match';
          }
        ],
        deps: ['password']
      }
    };
    switch (currentPage) {
      case 'sign-in': {
        return defaultFormConfig;
      }
      case 'sign-up': {
        return {
          validations: {
            email: ['presence', 'email'],
            ...repeatPwdValidation
          }
        };
      }
      case 'password-reset': {
        return {
          validations: {
            email: ['presence', 'email'],
            password: undefined,
            passwordConfirm: undefined
          }
        };
      }
      case 'new-password': {
        return {
          validations: {
            email: undefined,
            ...repeatPwdValidation
          }
        };
      }
      default:
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 'sign-in' && signInError.graphQLErrors?.[0]?.message)
      setBackendError(signInError.graphQLErrors[0].message);
    if (currentPage === 'sign-up' && signUpError.graphQLErrors?.[0]?.message)
      setBackendError(signUpError.graphQLErrors[0].message);
    if (currentPage === 'password-reset' && askPwdResetError.graphQLErrors?.[0]?.message)
      setBackendError(askPwdResetError.graphQLErrors[0].message);
    if (currentPage === 'new-password' && resetPwdError.graphQLErrors?.[0]?.message)
      setBackendError(resetPwdError.graphQLErrors[0].message);
  }, [
    JSON.stringify(signInError.graphQLErrors),
    JSON.stringify(signUpError.graphQLErrors),
    JSON.stringify(askPwdResetError.graphQLErrors),
    JSON.stringify(resetPwdError.graphQLErrors)
  ]);

  useEffect(() => {
    if (location.pathname === ROUTES.login.newPwd) setCurrentPage('new-password');
    if (location.pathname === ROUTES.login.default) setCurrentPage('sign-in');
    if (location.pathname === ROUTES.login.verify) setCurrentPage('sign-up-verify');
  }, [location]);

  useEffect(() => {
    async function verifySignUpToken() {
      const tokenParam = location.search ? getUrlParameter(location.search, 'token') : '';
      const planIdParam = location.search ? getUrlParameter(location.search, 'plan_id') : '';
      if (tokenParam) {
        try {
          const verify = await client.mutate({
            mutation: POST_VERIFY,
            variables: { token: tokenParam }
          });
          const token = verify?.data?.postEmployeeVerify?.token;
          const userId = verify?.data?.postEmployeeVerify?.userId;
          if (token && userId) {
            const postSignupReferrer = localStorage.getItem('post_signup_referrer');
            let referrerPage = JSON.parse(postSignupReferrer)?.referrer;
            const referrerPageState = {};
            if (planIdParam) {
              referrerPage = ROUTES.employer.profile;
              referrerPageState.planId = planIdParam;
            }
            gtmTrackOnSignUp();
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            localStorage.removeItem('post_signup_referrer');
            navigate(ROUTES.onboarding.default, {
              state: {
                from: {
                  location: {
                    pathname: referrerPage,
                    state: referrerPageState
                  }
                }
              }
            });
          }
        } catch (error) {
          const errorMsg = error.graphQLErrors?.[0]?.message;
          console.error('Verify token error:', errorMsg);
          setBackendError(errorMsg);
        }
      }
    }

    setBackendError('');
    reset();

    if (currentPage === 'sign-up-verify') verifySignUpToken();
  }, [currentPage]);

  useEffect(() => {
    authUser();
  }, []);

  useEffect(() => {
    const token = data?.logIn?.token;
    const userId = data?.logIn?.userId;
    if (token) completeSignIn(token, userId);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (signUpData && 'signUp' in signUpData) {
      track('User Signed Up');

      if (signUpData.signUp?.token)
        completeSignIn(signUpData.signUp?.token, signUpData.signUp?.userId);
      else {
        const referrer = getReferrerLocation(location)?.pathname;
        if (referrer) localStorage.setItem('post_signup_referrer', JSON.stringify({ referrer }));
        setCurrentPage('sign-up-success');
      }
    }
  }, [JSON.stringify(signUpData)]);

  useEffect(() => {
    if (askPwdResetData && 'postEmployeeResetPasswordLinkWeb' in askPwdResetData) {
      setCurrentPage('password-reset-success');
    }
  }, [JSON.stringify(askPwdResetData)]);

  useEffect(() => {
    if (resetPwdData && 'postEmployeeResetPassword' in resetPwdData) {
      setCurrentPage('new-password-success');
    }
  }, [JSON.stringify(resetPwdData)]);

  function changePage(pageParam) {
    return () => setCurrentPage(pageParam);
  }

  function handleFormFieldChange(field) {
    return (e) => set(field, e.target.value);
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    setBackendError('');

    validate()
      .then((validAttrs) => {
        if (currentPage === 'sign-in') {
          login({ variables: { password: validAttrs.password, email: validAttrs.email } });
        } else if (currentPage === 'sign-up') {
          signUp({
            variables: {
              password: validAttrs.password,
              email: validAttrs.email,
              planId: getPlanIdParam()
            }
          });
          setEmailForResendVerification(validAttrs.email);
        } else if (currentPage === 'password-reset') {
          askPwdReset({ variables: { email: validAttrs.email } });
        } else if (currentPage === 'new-password') {
          const token = location.search ? getUrlParameter(location.search, 'token') : '';
          if (token) resetPwd({ variables: { password: validAttrs.password, token } });
        }
      })
      .catch((errors) => {
        console.error('errors', errors);
      });
  }

  const openEmailClient = (emailClient) => {
    window.open(
      `https://${emailClient === 'yahoo' ? 'login.' : 'www.'}${emailClient}.com`,
      '_blank'
    );
  };

  const resendEmailVerification = async () => {
    const sendEmailVerificationLinkRes = await client.mutate({
      mutation: RESEND_VERIFICATION_MAIL,
      variables: { email: emailForResendVerification }
    });
    setResentEmailAttempts(resentEmailAttempts + 1);
  };

  function renderLoginForm() {
    const isSignIn = currentPage === 'sign-in' || currentPage === 'sign-up-verify';
    const title = isSignIn ? 'Sign In' : 'Sign Up';
    return currentPage === 'sign-up-success' ? (
      <Box flex={1} display="flex" flexDirection="column">
        <div className="subPageHeader">
          <IconButton color="primary" onClick={changePage('sign-in')} testID="go-to-sign-in">
            <MdArrowBack color="inherit" />
          </IconButton>
        </div>
        <Box className="subPageContent" style={{ maxWidth: 450 }}>
          <Box mb="34px" fontSize={36} lineHeight={0} textAlign="center" color="primary.main">
            <MdEmail fontSize="inherit" color="inherit" />
          </Box>
          <Box
            component="h1"
            mb="18px"
            textAlign="center"
            fontSize={26}
            fontWeight="bold"
            letterSpacing={1}
            lineHeight="26px"
          >
            Verify Email
          </Box>
          <Box component="p" mb="32px" textAlign="center" fontSize={16} lineHeight="24px">
            We've sent you an email with a verification link. Please click that link to verify your
            account.
          </Box>
          <Box component="p" mb="32px" textAlign="center" fontSize={12} lineHeight="24px">
            If you do not receive it soon, check your spam folder or{' '}
            <Box className="resendLink" component="span" onClick={resendEmailVerification}>
              resend
            </Box>
            {resentEmailAttempts > 0 && (
              <Box>
                We have resent the verification email{' '}
                {resentEmailAttempts > 1 ? `${resentEmailAttempts} times` : ''}
              </Box>
            )}
            {/*  */}
          </Box>
          <Box
            component="div"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            display="flex"
          >
            <Button variant="filled-primary" height={40} onClick={() => openEmailClient('gmail')}>
              Open Gmail
            </Button>
            <Button
              variant="filled-primary"
              height={40}
              sx={{ px: '10px', fontSize: 14 }}
              onClick={() => openEmailClient('outlook')}
            >
              Open Outlook
            </Button>
            <Button
              variant="filled-primary"
              height={40}
              sx={{ px: '10px', fontSize: 14 }}
              onClick={() => openEmailClient('yahoo')}
            >
              Open Yahoo
            </Button>
          </Box>
        </Box>
        <div className="subPageFooter" />
      </Box>
    ) : (
      <Box flex={1} display="flex" flexDirection="column">
        {isSignIn ? (
          <div className="homeBtnWrapper">
            <IconButton
              component="a"
              href={LANDING_PUBLIC_PATH || landingPublicPath}
              color="primary"
              aria-label="to landing home"
            >
              <MdArrowBack color="inherit" />
            </IconButton>
          </div>
        ) : (
          <div className="homeBtnWrapper" />
        )}
        <div className="mainContent">
          <Box
            component="h1"
            mb="16px"
            textAlign="center"
            fontSize={26}
            fontWeight="bold"
            letterSpacing={0.96}
            lineHeight="26px"
            testID={`sign-${isSignIn ? 'in' : 'up'}-title`}
          >
            {title}
          </Box>
          <Box
            maxWidth={320}
            mx="auto"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Button
              variant="filled-secondary"
              width="100%"
              height={50}
              sx={{ mb: '16px', fontSize: 14 }}
              onClick={signIn}
              testID={`sign-${isSignIn ? 'in' : 'up'}-google-button`}
            >
              {`${isSignIn ? 'Sign In' : 'Sign Up'} With Google`}
            </Button>
            <FacebookAuth
              appId={FACEBOOK_CLIENT_ID}
              fields="name,email"
              callback={loginFb}
              component={(renderProps) => (
                <Button
                  variant="filled-secondary"
                  width="100%"
                  height={50}
                  sx={{ mb: '16px', fontSize: 14 }}
                  onClick={renderProps.onClick}
                  testID={`sign-${isSignIn ? 'in' : 'up'}-fb-button`}
                >
                  {`${isSignIn ? 'Sign In' : 'Sign Up'} With Facebook`}
                </Button>
              )}
            />
          </Box>
          {/*<Box mb="16px" textAlign="center">or</Box>*/}
          <Box component="form" noValidate maxWidth={320} mx="auto" onSubmit={handleFormSubmit}>
            <Input
              id="email"
              {...$('email')}
              placeholder="Email"
              type="email"
              required
              withHelperText
              highlightInputOnError={false}
              autoComplete={isSignIn ? 'email' : 'off'}
              FormControlProps={{ sx: { mb: '16px' } }}
              analyticParams={{
                key: `Email focused (Sign ${isSignIn ? 'In' : 'Up'})`,
                trigger: 'focus'
              }}
              testID={`sign-${isSignIn ? 'in' : 'up'}-email-input`}
              onChange={handleFormFieldChange('email')}
            />
            <Box pb="16px">
              <Input
                id="password"
                {...$('password')}
                placeholder="Password"
                type="password"
                withHelperText
                highlightInputOnError={false}
                autoComplete={isSignIn ? 'current-password' : 'off'}
                FormControlProps={{ sx: { mb: !isSignIn ? '16px' : 0 } }}
                analyticParams={{
                  key: `Password focused (Sign ${isSignIn ? 'In' : 'Up'})`,
                  trigger: 'focus'
                }}
                testID={`sign-${isSignIn ? 'in' : 'up'}-password-input`}
                onChange={handleFormFieldChange('password')}
              />
              {!isSignIn && (
                <Input
                  id="passwordConfirm"
                  {...$('passwordConfirm')}
                  placeholder="Repeat Password"
                  type="password"
                  withHelperText
                  highlightInputOnError={false}
                  analyticParams={{
                    key: `Password confirmation focused (Sign ${isSignIn ? 'In' : 'Up'})`,
                    trigger: 'focus'
                  }}
                  testID="sign-up-confirm-password-input"
                  onChange={handleFormFieldChange('passwordConfirm')}
                />
              )}
              {backendError && (
                <FormHelperText error className="helperText">
                  {backendError}
                </FormHelperText>
              )}
            </Box>
            <Button
              type="submit"
              variant="filled-primary"
              width="100%"
              height={50}
              sx={{ mb: '16px' }}
              testID={`sign-${isSignIn ? 'in' : 'up'}-submit-button`}
            >
              {isSignIn ? 'Sign In' : 'Sign Up'}
            </Button>
            <Button
              variant="text"
              width="100%"
              sx={{ mb: '16px', fontSize: 14, lineHeight: '22px' }}
              onClick={changePage('password-reset')}
              testID="go-to-forgot-password-page"
            >
              Forgot Password
            </Button>
          </Box>
        </div>
        <div className="secondaryContent">
          <Divider style={{ marginBottom: 16 }} />
          <Box fontSize={14} textAlign="center" lineHeight="22px">
            <Box component="p" fontWeight="bold">
              {isSignIn ? 'Don’t Have an Account?' : 'Already Have an Account?'}
            </Box>
            <Button
              variant="text"
              width="100%"
              sx={{ fontSize: 14, lineHeight: '22px' }}
              onClick={changePage(isSignIn ? 'sign-up' : 'sign-in')}
              testID={`go-to-sign-${isSignIn ? 'up' : 'in'}-page`}
            >
              {isSignIn ? 'Sign Up' : 'Sign In'}
            </Button>
          </Box>
        </div>
      </Box>
    );
  }

  function renderPasswordResetForm() {
    return (
      <Box flex={1} display="flex" flexDirection="column">
        <div className="subPageHeader">
          <IconButton color="primary" onClick={changePage('sign-in')} testID="go-to-sign-in">
            <MdArrowBack color="inherit" />
          </IconButton>
        </div>
        <div className="subPageContent">
          {currentPage === 'password-reset-success' ? (
            <>
              <Box
                component="p"
                mb="16px"
                textAlign="center"
                fontSize={26}
                fontWeight="bold"
                letterSpacing={1}
                lineHeight="26px"
              >
                Link to reset password has been sent.
              </Box>
              <Box component="p" mb="32px" textAlign="center" fontSize={16} lineHeight="24px">
                Please check your email!
              </Box>
              <Button
                variant="filled-primary"
                width="100%"
                height={50}
                onClick={changePage('sign-in')}
                testID="continue-button"
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              <Box
                component="h1"
                mb="34px"
                textAlign="center"
                fontSize={26}
                fontWeight="bold"
                letterSpacing={0.96}
                lineHeight="26px"
                {...qaAttr('reset-password-title')}
              >
                Forgot Password
              </Box>
              <Box component="form" noValidate width="100%" onSubmit={handleFormSubmit}>
                <Box pb="16px">
                  <Input
                    id="email"
                    {...$('email')}
                    placeholder="Email"
                    type="email"
                    withHelperText
                    highlightInputOnError={false}
                    analyticParams={{ key: 'Email focused (password reset)', trigger: 'focus' }}
                    onChange={handleFormFieldChange('email')}
                    testID="reset-password-input"
                  />
                  {backendError && (
                    <FormHelperText error className="helperText">
                      {backendError}
                    </FormHelperText>
                  )}
                </Box>
                <Button
                  type="submit"
                  variant="filled-primary"
                  width="100%"
                  height={50}
                  testID="reset-password-submit-button"
                >
                  Send Reset Link
                </Button>
              </Box>
            </>
          )}
        </div>
        <div className="subPageFooter" />
      </Box>
    );
  }

  function renderNewPasswordForm() {
    return (
      <Box flex={1} display="flex" flexDirection="column">
        <div
          className="subPageContent"
          style={{ maxWidth: currentPage === 'new-password-success' ? 402 : 352 }}
        >
          {currentPage === 'new-password-success' ? (
            <>
              <Box mb="25px" fontSize={36} lineHeight={0} textAlign="center" color="primary.main">
                <MdCheck fontSize="inherit" color="inherit" />
              </Box>
              <Box
                component="p"
                mb="32px"
                textAlign="center"
                fontSize={26}
                fontWeight="bold"
                letterSpacing={1}
                lineHeight="26px"
              >
                Password Has Been Reset
              </Box>
              <Button
                variant="filled-primary"
                width="100%"
                height={50}
                sx={{ maxWidth: 320 }}
                onClick={() => navigate(ROUTES.login.default)}
              >
                Take Me To The App
              </Button>
            </>
          ) : (
            <>
              <Box
                component="h1"
                mb="34px"
                textAlign="center"
                fontSize={26}
                fontWeight="bold"
                letterSpacing={0.96}
                lineHeight="26px"
                {...qaAttr('new-password-title')}
              >
                Reset Password
              </Box>
              <Box
                component="form"
                noValidate
                maxWidth={320}
                width="100%"
                onSubmit={handleFormSubmit}
              >
                <Box pb="16px">
                  <Input
                    id="password"
                    {...$('password')}
                    placeholder="New Password"
                    type="password"
                    withHelperText
                    highlightInputOnError={false}
                    FormControlProps={{ sx: { mb: '16px' } }}
                    analyticParams={{ key: 'Password focused (password reset)', trigger: 'focus' }}
                    onChange={handleFormFieldChange('password')}
                    testID="new-password-input"
                  />
                  <Input
                    id="passwordConfirm"
                    {...$('passwordConfirm')}
                    placeholder="Repeat New Password"
                    type="password"
                    withHelperText
                    highlightInputOnError={false}
                    analyticParams={{
                      key: 'Password confirmation focused (password reset)',
                      trigger: 'focus'
                    }}
                    onChange={handleFormFieldChange('passwordConfirm')}
                    testID="new-password-confirm-input"
                  />
                  {backendError && (
                    <FormHelperText error className="helperText">
                      {backendError}
                    </FormHelperText>
                  )}
                </Box>
                <Button
                  type="submit"
                  variant="filled-primary"
                  width="100%"
                  height={50}
                  testID="new-password-submit-button"
                >
                  Reset
                </Button>
              </Box>
            </>
          )}
        </div>
        <div className="subPageFooter" />
      </Box>
    );
  }

  function renderPage() {
    if (
      currentPage === 'sign-in' ||
      currentPage === 'sign-up' ||
      currentPage === 'sign-up-success' ||
      currentPage === 'sign-up-verify'
    )
      return renderLoginForm();
    if (currentPage === 'password-reset' || currentPage === 'password-reset-success')
      return renderPasswordResetForm();
    if (currentPage === 'new-password' || currentPage === 'new-password-success')
      return renderNewPasswordForm();
    return null;
  }

  return <StyledRoot>{renderPage()}</StyledRoot>;
}

LoginForm.propTypes = {
  page: PropTypes.oneOf(['sign-in', 'sign-up', 'password-reset', 'new-password', 'sign-up-verify'])
};

LoginForm.defaultProps = {
  page: 'sign-in'
};

export default LoginForm;
