import { TRACKING_PARAM } from 'utils/app';
import { getProfileId } from 'utils';
import { GET_JOB } from '../queries';
import { SET_USER_ANALYTICS } from '../mutations';
import { client } from '../graphql';

export default async function trackUserOriginByUrlParam(search, jobId, clientParam) {
  if (jobId && search && TRACKING_PARAM) {
    const origin = getUrlParameter(search, TRACKING_PARAM);
    const pid = getProfileId();
    const clientToUse = clientParam || client;

    if (origin && pid) {
      try {
        const res = await clientToUse.query({
          query: GET_JOB,
          variables: { jobId },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        if (res?.data?.job?.employerProfileProfileId) {
          await clientToUse.mutate({
            mutation: SET_USER_ANALYTICS,
            variables: {
              origin,
              jobId,
              employeeId: Number(pid),
              employerId: res.data.job.employerProfileProfileId
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          });
        }
      } catch (error) {
        console.error('trackUserOriginByUrlParam error', error);
      }
    }
  }
}
