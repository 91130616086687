import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, RadioGroup, Input } from 'components/shared';
import { useForm } from 'components/form';

function CompanySizeForm({
  form,
  fieldName,
  onChange,
  onSubmit,
  onSkip,
  withSubmitBtn,
  withSkipBtn
}) {
  const { $, attrs, get, set } = useForm({
    initial: { [fieldName]: form[fieldName] || '' },
    validations: {}
  });

  const submit = () => {
    onSubmit({ ...form, [fieldName]: attrs[fieldName] });
  };

  const skip = () => {
    onSkip();
  };

  const handleChange = (e) => {
    set(fieldName, e.target.value);
    if (onChange) onChange({ [fieldName]: e.target.value });
  };

  return (
    <>
      <RadioGroup
        data={[
          { label: '1-49', value: '1-49' },
          { label: '50-999', value: '50-999' },
          { label: '1,000-4,999', value: '1,000-4,999' },
          { label: '5,000 or more', value: '5,000 or more' },
          { label: "Don't know", value: "Don't know" }
        ]}
        value={attrs[fieldName]}
        defaultValue="1-49"
        aria-label="employees count"
        sx={{ marginBottom: '16px' }}
        onChange={handleChange}
      />
      {withSubmitBtn && (
        <Button
          variant="filled-primary"
          sx={{ width: '100%', height: 50 }}
          onClick={submit}
          testID="next-button"
        >
          Next
        </Button>
      )}
      {withSkipBtn && (
        <Button
          variant="text"
          sx={{ width: '100%', height: 50, fontSize: 14, lineHeight: '22px' }}
          onClick={skip}
          testID="skip-button"
        >
          Skip
        </Button>
      )}
    </>
  );
}

CompanySizeForm.propTypes = {
  fieldName: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSkip: PropTypes.func,
  withSkipBtn: PropTypes.bool,
  withSubmitBtn: PropTypes.bool
};

CompanySizeForm.defaultProps = {
  fieldName: 'size',
  onChange: () => {},
  onSubmit: () => {},
  onSkip: () => {},
  withSkipBtn: true,
  withSubmitBtn: true
};

export default CompanySizeForm;
