import { gql } from '@apollo/client';

// get employer schedule
export const GET_EMPLOYER_INTERVIEW_SCHEDULE = gql`
  query getScheduleTimesEmployer($employerProfileId: Int!) {
    getScheduleTimesEmployer(employerProfileId: $employerProfileId) {
      schedule
      startDate
      length
      careerHealthScore
      day
      employerProfileId
      experience
      id
      industry
      location
      skills
      timeZone
    }
  }
`;

// get all interviews for selected job
export const GET_INTERVIEW_SCHEDULE_FOR_JOB = gql`
  query getScheduleTimesForJob($jobId: Int!, $employeeProfileId: Int!) {
    getScheduleTimesForJob(jobId: $jobId, employeeProfileId: $employeeProfileId) {
      careerHealthScore
      day
      employerScheduleId
      employeeProfileId
      employerProfileId
      experience
      interviewDate
      id
      industry
      location
      skills
      timeZone
    }
  }
`;

// get employer interviews
export const GET_EMPLOYER_SCHEDULED_INTERVIEWS = gql`
  query getScheduleEmployer($employerProfileId: Int) {
    getScheduleEmployer(employerProfileId: $employerProfileId) {
      day
      employerProfileId
      employeeProfileId
      employeeName
      employeeImage
      id
      interviewDate
      hiredStatus
      jobId
      jobTitle
      jobLocation
      schedule
      status
      statusEnum
      timeZone
      user_id
    }
  }
`;

// hire/deny reasons
export const GET_HIRE_REASONS = gql`
  query getReasons {
    getReasons {
      id
      reason
      type
    }
  }
`;
