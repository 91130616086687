import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query employeeProfile($userId: Int!) {
    employeeProfile(userId: $userId) {
      address
      birth_date
      createdAt
      city
      countryId
      education {
        education_id
        name
        pos
        startDate
        endDate
        currentJob
      }
      email
      experience {
        experience_id
        name
        pos
        startDate
        endDate
        currentJob
      }
      gender
      imageUrl
      industry {
        id: industry_id
        name
      }
      motto
      name
      notes
      phone
      profile_id
      profileWizard
      profileComplete
      race
      regStep
      resumeUrl
      stateId
      skills {
        skill_id
        name
      }
      timeZone
      user_id
      updatedAt
      videoUrl
      website
      zip

      inappNewMessages
      emailNewMessages
      inappNewJobs
      emailNewJobs
      inappTips
      emailTips
    }
  }
`;

export const GET_CERTIFICATES = gql`
  query getCertificates($employeeProfileId: Int, $validatedByEmployerProfileId: Int) {
    getCertificates(
      employeeProfileId: $employeeProfileId
      validatedByEmployerProfileId: $validatedByEmployerProfileId
    ) {
      completionDate
      complete
      employeeProfileId
      id
      name
      validatedByEmployerProfileId
    }
  }
`;

export const GET_USER_PROFILE_VIDEO = gql`
  query employeeProfile($userId: Int!) {
    employeeProfile(userId: $userId) {
      videoUrl
    }
  }
`;
