import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import { useGetPlacePredictions } from 'hooks';
import { Box, InputAdornment } from 'components';
import { Button, CheckBoxGroup, Input, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { MdSearch } from 'components/icons';

const CITIES = [
  { label: 'New York, NY', value: 'New York, NY' },
  { label: 'Los Angeles, CA', value: 'Los Angeles, CA' },
  { label: 'Chicago, IL', value: 'Chicago, IL' },
  { label: 'Houston, TX', value: 'Houston, TX' },
  { label: 'Philadelphia, PA', value: 'Philadelphia, PA' },
  { label: 'San Antonio, TX', value: 'San Antonio, TX' },
  { label: 'San Diego, CA', value: 'San Diego, CA' },
  { label: 'Dallas, TX', value: 'Dallas, TX' },
  { label: 'San Jose, CA', value: 'San Jose, CA' },
  { label: 'Austin, TX', value: 'Austin, TX' },
  { label: 'Jacksonville, FL', value: 'Jacksonville, FL' },
  { label: 'Fort Worth, TX', value: 'Fort Worth, TX' },
  { label: 'Columbus, OH', value: 'Columbus, OH' }
];

function CitiesForm(props) {
  const {
    CheckBoxGroupProps,
    form,
    fieldName,
    InputProps,
    onSubmit,
    onChange,
    onSkip,
    withSkipBtn,
    withSubmitBtn
  } = props;
  const [searchVal, setSearchVal] = useState('');
  const [options, setOptions] = useState(CITIES);
  const { getPlacePredictions, loading: isSearching } = useGetPlacePredictions();

  const { $, attrs, get, set } = useForm({
    initial: { city: form[fieldName] || '' },
    validations: {}
  });

  const handleSearch = useCallback(
    (e, newVal) => {
      const val = newVal || e?.target.value;
      setSearchVal(val);
      getPlacePredictions({ input: val }, (predictions, status) => {
        if (status === 'OK')
          setOptions(map(predictions, (p) => ({ label: p.description, value: p.description })));
        else setOptions(CITIES);
      });
    },
    [getPlacePredictions]
  );

  useEffect(() => {
    if (form[fieldName] && !find(CITIES, ['value', form[fieldName]]))
      handleSearch(undefined, form[fieldName]);
  }, []);

  const submit = () => {
    onSubmit({ ...form, [fieldName]: attrs.city });
  };

  function skip() {
    onSkip();
  }

  function handleSelect(data, checked) {
    set('city', checked ? data.value : '');

    if (onChange) onChange({ [fieldName]: checked ? data.value : '' });
  }

  return (
    <>
      <Input
        value={searchVal}
        placeholder="Search for a city"
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ minWidth: 24, paddingLeft: '16px', fontSize: 24, color: 'rgba(0,0,0,0.5)' }}
          >
            <MdSearch fontSize="inherit" color="inherit" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end" style={{ minWidth: 30 }}>
            {isSearching && <Spinner size={20} />}
          </InputAdornment>
        }
        FormControlProps={{
          sx: { mb: '16px', minHeight: 50 }
        }}
        onChange={handleSearch}
        testID="cities-form-search-input"
        {...InputProps}
      />
      <CheckBoxGroup
        data={map(options, (opt) => ({ ...opt, checked: attrs.city === opt.value }))}
        aria-label="location"
        sx={{ marginBottom: '16px', overflow: 'hidden' }}
        checkboxesContainerProps={{
          sx: { overflow: 'auto' }
        }}
        onChange={handleSelect}
        {...CheckBoxGroupProps}
      />
      {withSubmitBtn && (
        <Button
          variant="filled-primary"
          sx={{ width: '100%', height: 50 }}
          onClick={submit}
          testID="next-button"
        >
          Next
        </Button>
      )}
      {withSkipBtn && (
        <Button
          variant="text"
          sx={{ width: '100%', height: 50, fontSize: 14, lineHeight: '22px' }}
          onClick={skip}
          testID="skip-button"
        >
          Skip
        </Button>
      )}
    </>
  );
}

CitiesForm.propTypes = {
  CheckBoxGroupProps: PropTypes.objectOf(PropTypes.any),
  fieldName: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  InputProps: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSkip: PropTypes.func,
  withSkipBtn: PropTypes.bool,
  withSubmitBtn: PropTypes.bool
};

CitiesForm.defaultProps = {
  CheckBoxGroupProps: {},
  fieldName: 'location',
  InputProps: {},
  onChange: () => {},
  onSubmit: () => {},
  onSkip: () => {},
  withSkipBtn: true,
  withSubmitBtn: true
};

export default CitiesForm;
