import React from 'react';
import { SvgIcon } from 'components';

function MdExclamation(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="M 11,4L 13,4L 13,15L 11,15L 11,4 Z M 13,18L 13,20L 11,20L 11,18L 13,18 Z" />
    </SvgIcon>
  );
}

export default MdExclamation;
