import React, { useState, useCallback } from 'react';
import { getDisplayName } from 'utils';
import ApplicantProfileDialog from 'components/dialogs/ApplicantProfile/ApplicantProfileDialog';

function withApplicantProfileDialog(WrappedComponent) {
  function WithApplicantProfileDialog(props) {
    const [dialogProps, setDialogProps] = useState({
      approveStatus: '',
      isQhMatch: false,
      isStarred: false,
      id: null,
      jobId: null,
      jobLink: '',
      isOpen: false,
      onAccept: () => {},
      onReject: () => {},
      onStar: () => {},
      withActions: true,
      withEmployerCourses: false
    });

    const handleClose = useCallback(
      () => setDialogProps((prev) => ({ ...prev, isOpen: false })),
      []
    );

    const handleOpen = useCallback(
      (params) => setDialogProps((prev) => ({ ...prev, ...params, isOpen: true })),
      []
    );

    return (
      <>
        <WrappedComponent
          setApplicantProfileDialogProps={setDialogProps}
          openApplicantProfileDialog={handleOpen}
          closeApplicantProfileDialog={handleClose}
          isApplicantProfileDialogOpen={dialogProps.isOpen}
          {...props}
        />

        {dialogProps.isOpen && <ApplicantProfileDialog onClose={handleClose} {...dialogProps} />}
      </>
    );
  }

  WithApplicantProfileDialog.displayName = `WithApplicantProfileDialog(${getDisplayName(
    WrappedComponent
  )})`;
  return WithApplicantProfileDialog;
}

export default withApplicantProfileDialog;
