import { gql } from '@apollo/client';

export const SAVE_INITIAL_CAREER_DEV = gql`
  mutation postCareerDevelopment(
    $curJobTypeId: Int
    $futureJobTypeId: Int
    $industryId: Int
    $currentlySearching: Boolean
    $showEmployerData: Boolean
  ) {
    postCareerDevelopment(
      curJobTypeId: $curJobTypeId
      futureJobTypeId: $futureJobTypeId
      industryId: $industryId
      currentlySearching: $currentlySearching
      showEmployerData: $showEmployerData
    ) {
      id
    }
  }
`;

export const POST_EMPLOYEE_STARRED = gql`
  mutation postEmployeeStarred($careerDevelopmentId: Int, $employeeProfileId: Int) {
    postEmployeeStarred(careerDevelopmentId: $careerDevelopmentId, employeeProfileId: $employeeProfileId) {
      careerDevelopmentId
      id
    }
  }
`;

export const REMOVE_EMPLOYEE_STARRED = gql`
  mutation removeEmployeeStarred($careerDevelopmentId: Int, $employeeProfileId: Int) {
    removeEmployeeStarred(careerDevelopmentId: $careerDevelopmentId, employeeProfileId: $employeeProfileId) {
      careerDevelopmentId
      id
    }
  }
`;

export const GET_EMPLOYEE_STARRED = gql`
  mutation getEmployeeStarred($careerDevelopmentId: Int) {
    getEmployeeStarred(careerDevelopmentId: $careerDevelopmentId) {
      id
      employeeProfileId
      employeeProfile {
        profile_id
        user_id
        imageUrl
        name
        experience {
          name
          pos
          currentJob
        }
      }
    }
  }
`;
