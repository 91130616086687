import { gql } from '@apollo/client';

export const UPDATE_EMPLOYER_PROFILE_IMG = gql`
  mutation setEmployerImage($file: Upload!) {
    setEmployerImage(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

export const UPDATE_EMPLOYER_PROFILE = gql`
  mutation setEmployerUpdate(
    $size: String
    $address: String
    $name: String
    $timeZone: String
    $phone: String
    $website: String
    $stateId: Int
    $countryId: Int
    $city: String
    $zip: String
  ) {
    setEmployerUpdate(
      size: $size
      address: $address
      name: $name
      timeZone: $timeZone
      phone: $phone
      website: $website
      stateId: $stateId
      countryId: $countryId
      city: $city
      zip: $zip
    ) {
      address
      createdAt
      countryId
      city
      imageUrl
      name
      phone
      profile_id
      profileWizard
      size
      stateId
      timeZone
      website
      updatedAt
      user_id
      zip
    }
  }
`;

export const UPDATE_EMPLOYER_INDUSTRY = gql`
  mutation setEmployerIndustry($industry: [IndustryInputType]) {
    setEmployerIndustry(industry: $industry) {
      user_id
    }
  }
`;

export const UPDATE_EMPLOYER_PASSWORD = gql`
  mutation postEmployerNewPassword($userId: Int!, $pass: String!, $oldPass: String!) {
    postEmployerNewPassword(user_id: $userId, pass: $pass, oldPass: $oldPass) {
      user_id
      pass
    }
  }
`;

export const UPDATE_EMPLOYER_NOTIFICATIONS = gql`
  mutation setEmployerUpdateNotification(
    $inappNewApplicants: Boolean
    $emailNewApplicants: Boolean
    $inappInboxMessages: Boolean
    $emailInboxMessages: Boolean
    $inappTimeRespondEnds: Boolean
    $emailTimeRespondEnds: Boolean
  ) {
    setEmployerUpdateNotification(
      inappNewApplicants: $inappNewApplicants
      emailNewApplicants: $emailNewApplicants
      inappInboxMessages: $inappInboxMessages
      emailInboxMessages: $emailInboxMessages
      inappTimeRespondEnds: $inappTimeRespondEnds
      emailTimeRespondEnds: $emailTimeRespondEnds
    ) {
      inappNewApplicants
      emailNewApplicants
      inappInboxMessages
      emailInboxMessages
      inappTimeRespondEnds
      emailTimeRespondEnds
    }
  }
`;
