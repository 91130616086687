export default ({ theme, fullWidth, maxWidth }) => ({
  position: 'relative',
  height: '100vh',
  // maxWidth: 1024,
  width: '100%',
  maxWidth: fullWidth ? '100%' : maxWidth,
  margin: '0 auto',

  '& .contentWrapper': {
    paddingBottom: 74,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      paddingBottom: 0
    }
  },
  '& .footerContainer': {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    height: 74,
    width: '100%',
    maxWidth: 1024,
    margin: '0 auto',
    zIndex: 1,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  }
});
