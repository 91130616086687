export default ({ theme }) => ({
  '&.enterpriseDashboardContainer': {
    padding: '0 30px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 0
    }
  },
  '& .container': {
    display: 'flex',
    marginLeft: -15,
    marginRight: -15,
    marginBottom: -30,
    alignItems: 'flex-start',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      margin: 0
    }
  },
  '& .mainTemplateContainer': {
    flex: 2,
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flex: 1
    }
  },
  '& .sideTemplateContainer': {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .card': {
    width: '100%',
    maxWidth: '33.33%',
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 30,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      paddingRight: 8,
      paddingLeft: 8,
      paddingBottom: 16
    }
  },
  '& .cardBtn': {
    height: 184,
    width: '100%',
    padding: 0,
    paddingTop: 19,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 16,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 'auto',
      padding: '0 6px',
      borderRadius: 10,
      flexDirection: 'column',
      alignItems: 'center'
    },
    '&.Mui-disabled': {
      backgroundColor: 'inherit',
      color: 'inherit',
      opacity: 0.5
    },
    '& .cardBtn__icon': {
      minWidth: 90,
      height: 90,
      marginBottom: 16,
      fontSize: 38,
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        minWidth: 'auto',
        height: 'auto',
        marginBottom: 12,
        // fontSize: 36,
        backgroundColor: 'transparent'
      },
      [theme.breakpoints.down(768)]: {
        fontSize: 30
      },
      [theme.breakpoints.down(425)]: {
        fontSize: 26
      }
    },
    '&.sideCard': {
      height: 122,
      marginBottom: 30,
      paddingTop: 0,
      paddingLeft: 23,
      paddingRight: 25,
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& .cardBtn__icon': {
        minWidth: 48,
        height: 48,
        marginBottom: 0,
        marginRight: 24,
        fontSize: 19
      },
      '&.tutorialCardBtn': {
        height: 200,
        padding: 12,
        backgroundColor: '#F6F5FA',
        [theme.breakpoints.down(theme.desktopBreakPoint)]: {
          height: '100%',
          marginBottom: 0,
          padding: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent'
        }
      }
    }
  },
  '& .cardBtn__icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    borderRadius: '50%',
    backgroundColor: '#F6F5FA'
  },
  '& .cardBtn__title': {
    lineHeight: '20px',
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down(768)]: {
      lineHeight: '14px',
      fontSize: 12
    },
    [theme.breakpoints.down(425)]: {
      lineHeight: '9px',
      fontSize: 8
    }
  },
  '& .contactUsCard__mobile': {
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'block'
    }
  },
  '& .jobsCard__desktop': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .profileCard__desktop': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .profileCard__mobile': {
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      marginBottom: 16,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderBottom: '1px solid #E5E5E5',
      borderTop: '1px solid #E5E5E5',
      borderRadius: '0px !important',
      textAlign: 'left'
    }
  },
  '& .profileLoaderWrapper': {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .companyLogo': {
    minWidth: 48,
    height: 48,
    marginRight: 24,
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '50%',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minWidth: 48,
      height: 48,
      marginRight: 16
    }
  },
  '& .companyName': {
    marginBottom: 12,
    lineHeight: '24px',
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 8,
      lineHeight: '18px',
      fontSize: 16,
      color: '#000'
    }
  },
  '& .plan': {
    lineHeight: '17px',
    fontSize: 14
  },
  '& .plan__trial': {
    marginLeft: 12,
    opacity: 0.5
  }
});
