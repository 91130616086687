import React from 'react';
import PT from 'prop-types';
import { Dialog, styled } from 'components';
import { IconButton } from 'components/shared';
import { SkillsForm } from 'components/dialogs/components';
import { MdArrowBack } from 'components/icons';
import { qaAttr } from 'utils';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .dialogHeader': {
    padding: '13px 13px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '5px 17px'
    }
  },
  '& .dialogTitle': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 16,
      lineHeight: '18px',
      color: '#000'
    }
  },
  '& .headerAction_start': {
    flex: '0 0 44px'
  },
  '& .headerAction_end': {
    flex: '0 0 44px'
  },
  '& .dialogContent': {
    padding: '0 17px 33px',
    flex: '1 1 auto',
    overflowY: 'auto'
  }
}));

function SkillsModal(props) {
  const { formAttrs, isOpen, onClose, onChange } = props;

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <div className="dialogHeader">
        <div className="headerAction_start">
          <IconButton
            color="primary"
            aria-label="return"
            className="headerAction_end"
            onClick={onClose}
            testID="close-modal-button"
          >
            <MdArrowBack />
          </IconButton>
        </div>
        <h2 className="dialogTitle" {...qaAttr('skills-form-title')}>
          Skills
        </h2>
        <div className="headerAction_end" />
      </div>
      <div className="dialogContent" style={{ display: 'flex' }}>
        <SkillsForm
          form={formAttrs}
          withSubmitBtn={false}
          withSkipBtn={false}
          onChange={onChange}
        />
      </div>
    </StyledDialog>
  );
}

SkillsModal.propTypes = {
  formAttrs: PT.shape({
    skills: PT.arrayOf(PT.objectOf(PT.any))
  }).isRequired,
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onChange: PT.func.isRequired
};

export default SkillsModal;
