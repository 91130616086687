import { gql } from '@apollo/client';

export const POST_EMPLOYEE_REFERRAL = gql`
  mutation postEmployeeReferral($employeeUserId: Int!, $jobId: Int!, $referralUrl: String!) {
    postEmployeeReferral(
      employeeUserId: $employeeUserId
      jobId: $jobId
      referralUrl: $referralUrl
    ) {
      id
    }
  }
`;

export const UPDATE_JOB_IMG = gql`
  mutation postJobImage($file: Upload!, $jobId: Int!) {
    postJobImage(file: $file, jobId: $jobId) {
      filename
      mimetype
      encoding
    }
  }
`;

export const ADD_JOB = gql`
  mutation addJob(
    $title: String
    $location: String
    $payRange: String
    $hireDate: String
    $description: String
    $requirements: String
    $endDate: String
    $applicantAmount: Int
    $webUrl: String
    $draft: Boolean
    $skills: [SkillsInputType]
    $industry: [IndustryInputType]
    $stateId: Int
    $countryId: Int
    $city: String
    $zip: String
    $payPeriodId: Int
    $payCurrencyId: Int
    $payMin: Float
    $payMax: Float
  ) {
    addJob(
      title: $title
      location: $location
      payRange: $payRange
      hireDate: $hireDate
      description: $description
      requirements: $requirements
      endDate: $endDate
      applicantAmount: $applicantAmount
      webUrl: $webUrl
      draft: $draft
      skills: $skills
      industry: $industry
      stateId: $stateId
      countryId: $countryId
      city: $city
      zip: $zip
      payPeriodId: $payPeriodId
      payCurrencyId: $payCurrencyId
      payMin: $payMin
      payMax: $payMax
    ) {
      applicantAmount
      createdAt
      countryId
      city
      description
      endDate
      employerProfileProfileId
      hireDate
      id
      industry {
        name
        industry_id
      }
      location
      payRange
      payPeriodId
      payCurrencyId
      payMin
      payMax
      stateId
      skills {
        skill_id
        name
      }
      title
      requirements
      updatedAt
      webUrl
      draft
      zip
    }
  }
`;

export const POST_JOB = gql`
  mutation postJob(
    $id: Int
    $title: String
    $location: String
    $payRange: String
    $hireDate: String
    $description: String
    $requirements: String
    $endDate: String
    $applicantAmount: Int
    $webUrl: String
    $draft: Boolean
    $skills: [SkillsInputType]
    $industry: [IndustryInputType]
    $stateId: Int
    $countryId: Int
    $city: String
    $zip: String
    $payPeriodId: Int
    $payCurrencyId: Int
    $payMin: Float
    $payMax: Float
  ) {
    postJob(
      id: $id
      title: $title
      location: $location
      payRange: $payRange
      hireDate: $hireDate
      description: $description
      requirements: $requirements
      endDate: $endDate
      applicantAmount: $applicantAmount
      webUrl: $webUrl
      draft: $draft
      skills: $skills
      industry: $industry
      stateId: $stateId
      countryId: $countryId
      city: $city
      zip: $zip
      payPeriodId: $payPeriodId
      payCurrencyId: $payCurrencyId
      payMin: $payMin
      payMax: $payMax
    ) {
      applicantAmount
      createdAt
      countryId
      city
      description
      endDate
      employerProfileProfileId
      hireDate
      id
      industry {
        name
        industry_id
      }
      location
      payRange
      payPeriodId
      payCurrencyId
      payMin
      payMax
      stateId
      skills {
        skill_id
        name
      }
      title
      requirements
      updatedAt
      webUrl
      draft
      zip
    }
  }
`;

export const CLOSE_JOB = gql`
  mutation activeJob($id: Int, $active: Boolean) {
    activeJob(id: $id, active: $active) {
      active
      id
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: Int) {
    deleteJob(id: $id) {
      id
    }
  }
`;

export const CLOSE_JOB_WITH_FEEDBACK = gql`
  mutation closeJob(
    $jobId: Int
    $closeReasonId: Int
    $hiringPlatformId: Int
    $rating: Int
    $feedback: String
    $hiredEmployeeProfileId: Int
  ) {
    closeJob(
      jobId: $jobId
      closeReasonId: $closeReasonId
      hiringPlatformId: $hiringPlatformId
      rating: $rating
      feedback: $feedback
      hiredEmployeeProfileId: $hiredEmployeeProfileId
    ) {
      id
      closeJobId
      jobId
      closeJob {
        id
        closeReasonId
        hiringPlatformId
        hiredEmployeeProfileId
        rating
        feedback
        hiringPlatforms {
          name
          id
        }
        closeReasons {
          name
          id
        }
      }
    }
  }
`;

export const APPROVE_JOB = gql`
  mutation approveJob(
    $jobId: Int
    $approved: Boolean
    $passed: Boolean
    $starred: Boolean
    $token: String
    $employeeProfileId: Int
    $days: [employerToScheduleTimesInputType]
  ) {
    approveJob(
      jobId: $jobId
      approved: $approved
      passed: $passed
      starred: $starred
      token: $token
      employeeProfileId: $employeeProfileId
      days: $days
    ) {
      approved
      isSchedule
      jobsId
      passed
      scheduleStatusCount
      starred
      usersUserId
    }
  }
`;

export const VIEWED_JOB = gql`
  mutation viewedJob($jobId: Int) {
    viewedJob(jobId: $jobId) {
      id
    }
  }
`;

export const POST_EMPLOYER_QUESTION = gql`
  mutation postEmployerQuestions($questions: [QuestionsInputType], $jobsId: Int!) {
    postEmployerQuestions(questions: $questions, jobsId: $jobsId) {
      id
    }
  }
`;

export const POST_EMPLOYEE_ANSWER = gql`
  mutation postEmployeeAnswer($userId: Int, $answer: Boolean!, $questionId: Int) {
    postEmployeeAnswer(userId: $userId, answer: $answer, questionId: $questionId) {
      answer
    }
  }
`;

export const POST_COMPLAIN = gql`
  mutation postComplain($userId: Int, $jobId: Int, $employeeId: Int) {
    postComplain(userId: $userId, jobId: $jobId, employeeId: $employeeId) {
      success
    }
  }
`;
