import React from 'react';
import { getDisplayName } from 'utils';
import { AlertsProvider } from 'providers';

export default function withAlerts(WrappedComponent) {
  function WithAlerts(props) {
    return (
      <AlertsProvider>
        <WrappedComponent {...props} />
      </AlertsProvider>
    );
  }
  WithAlerts.displayName = `WithAlerts(${getDisplayName(WrappedComponent)})`;
  return WithAlerts;
}
