export default ({ theme }) => ({
  '&.backdrop__root': {
    zIndex: 2000,
    // '-webkit-filter': 'blur(3px)',
    // filter: 'blur(3px)',
    alignItems: 'normal',
    justifyContent: 'normal',
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  '& .contentContainer': {
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto 50px',
    padding: '137px 30px 0',
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'flex-end'
  },
  '& .cardContainer': {
    maxWidth: 300,
    width: '100%',
    position: 'relative'
  },
  '& .text': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: 0,
    color: '#FFF',
    textShadow: '0 0 14px 0 rgba(0,0,0,0.5)'
  },
  '& .tipsContainer': {
    width: '100%',
    padding: '0 20px',
    position: 'absolute',
    bottom: -50,
    display: 'flex',
    justifyContent: 'space-between'
  },
  '& .tip': {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0,
    color: '#FFF',
    textShadow: '0 0 14px 0 rgba(0,0,0,0.5)'
  },
  '& .pointerIcon': {
    position: 'absolute',
    right: -8,
    bottom: -12
  }
});
