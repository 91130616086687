import React from 'react';
import { SvgIcon } from 'components';

function MdMenu(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
    </SvgIcon>
  );
}

export default MdMenu;
