import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useLocation, useParams, useMatch } from 'react-router-dom';
import { getLocaleTimeZone, getRoutes, employerStartPage } from 'utils';
import getAgeByBirthDate from 'utils/getAgeByBirthDate';
import { group, identify } from 'utils/segmentAnalytics';
import { useTimeZone, useAuth } from 'hooks';
import { Spinner } from 'components/shared';
import { fetchEmployeeProfile, trackUserOriginByUrlParam } from 'api';
import { PrivateLayout } from 'containers';

const ROUTES = getRoutes();

function ProtectedEmployeeRoute() {
  const { authed, signout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { timeZone: appTimeZone, setTimeZone, updateUserTimeZone } = useTimeZone();

  const matchJob = useMatch(ROUTES.employee.job); // ! Warning: could match with other employee routes
  const isEmployeeJobPage =
    idParam && matchJob?.pathname === getRoutes({ id: idParam }).employee.job;
  const isPublicRoute = isEmployeeJobPage;

  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);

  const initUserInSegment = (profile) => {
    identify({ name: profile?.name, email: profile?.email, profileType: 'employee' });
    group(
      {
        gender: profile?.gender,
        age: getAgeByBirthDate(profile?.birth_date),
        race: profile?.race,
        zip: profile?.zip,
        profileComplete: profile?.profileComplete,
        skills: profile?.skills,
        industry: profile?.industry
      },
      'employee'
    );
  };

  const checkProfileByRole = async () => {
    const [profile, { response }] = await fetchEmployeeProfile({
      queryParams: { fetchPolicy: 'cache-first' }
    });

    if (profile) {
      const { timeZone, profileWizard, allowPlan, restrictions } = profile;
      updateUserTimeZone(getLocaleTimeZone());

      initUserInSegment(profile);

      if (profileWizard) {
        if (idParam) trackUserOriginByUrlParam(location.search, Number(idParam)); // track job id page with special param
        return true;
      }
      navigate(ROUTES.onboarding.default);
      return false;
    }
    if (!location?.state?.notEmployer) {
      // check if user is an employer if he wasn't checked before
      navigate(employerStartPage, { state: { notEmployee: true } });
    } else {
      signout();
    }
    return false;
  };

  const authMiddleware = async () => {
    if (authed) {
      const checked = await checkProfileByRole();
      setIsUserRoleChecked(checked);
    } else {
      if (isPublicRoute) {
        // job is publicly available
        setIsUserRoleChecked(true);
        return;
      }
      navigate(ROUTES.login.default, { state: { from: { location } } });
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <PrivateLayout userRole="employee">
        <Outlet />
      </PrivateLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed || isPublicRoute ? renderContent() : <Navigate to={ROUTES.login.default} />;
}

export default ProtectedEmployeeRoute;
