import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { APPROVE_JOB, GET_JOB, GET_PUBLIC_JOB, GET_QUESTIONS } from 'api';
import { filter, trim } from 'lodash';
import parseDate from 'date-fns/parse';
import formatDate from 'date-fns/format';
import { utcToZonedTime } from 'date-fns-tz';
import { getRoutes, urlWithHttp } from 'utils';
import { gtmTrackJobAccept, gtmTrackJobReject, gtmTrackJobStar } from 'utils/gtm';
import { useWindowSize, useCountryStateQuery, useAuth } from 'hooks';
import { Box, styled, useMediaQuery } from 'components';
import { Button, EmployeeInterviewScheduleModal, IconButton, Spinner } from 'components/shared';
import {
  ConfirmationDialog,
  EmployeeInterviewCancelAlert,
  EmployeeInterviewRescheduleAlert,
  EmployerPromptDialog
} from 'components/dialogs';
import { MdArrowBack, MdIosShare, MdStarBorder } from 'components/icons';
import { ShareJob } from 'components/Job';
import styles from 'styles/Dashboard/EmployeeJob';
import jobDefaultImg from 'assets/img/job_default.png';

const StyledRoot = styled('div')(styles);

const modalsConfigDefault = {
  // shareModal: false,
  questionsModal: false,
  applyAlertModal: false,
  webLinkModal: false,
  cancelInterviewAlert: false,
  rescheduleAlert: false,
  rescheduleAlertReason: '', // 'cancel' | 'reschedule'
  scheduleModal: false
};

function getFilledQuestions(questions = []) {
  return questions.length ? filter(questions, (q) => q.name && trim(q.name).length > 0) : [];
}

function calcImageSize(screenWidth = 0) {
  const w = screenWidth <= 600 ? screenWidth - 32 : 600 - 32;
  return {
    width: w,
    height: (w / 4) * 3
  };
}

const ACTIONS = {
  APPLY: 'accept',
  REJECT: 'pass',
  RETRACT: 'retract',
  INTERVIEW: 'accept_interview'
};
const CANCEL_LIMIT = 1; // max 2 per week
const ROUTES = getRoutes();

function EmployeeJob(props) {
  const { id: jobId } = useParams();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const { authed: isAuthenticated } = useAuth();
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { width, height } = useWindowSize();

  const [job, setJob] = useState({});
  const [questions, setQuestions] = useState([]);
  const [modals, setModals] = useState(modalsConfigDefault);
  const [isApplied, setIsApplied] = useState(false);
  const [isStarred, setIsStarred] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [interviewAppointed, setInterviewAppointed] = useState(false);
  const [interviewDate, setInterviewDate] = useState(null);
  const [action, setAction] = useState('');

  const { fetchCountryState, getCountryById, getStateById } = useCountryStateQuery();

  const [fetchJob, { loading: jobLoading = false }] = useLazyQuery(GET_JOB, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.job) {
        const { approved, passed, starred, userInterviewDate } = data.job;

        if (approved) setIsApplied(true);
        if (starred) setIsStarred(true);
        if (userInterviewDate) {
          setInterviewAppointed(true);
          setInterviewDate(new Date(userInterviewDate));
        }

        setJob(data.job);
      }
    }
  });

  const [fetchPublicJob, { loading: publicJobLoading = false }] = useLazyQuery(GET_PUBLIC_JOB, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.publicJob) setJob(data.publicJob);
    }
  });

  const [fetchQuestions, { loading: questionsLoading }] = useLazyQuery(GET_QUESTIONS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.questions?.[0]?.questions)
        setQuestions(getFilledQuestions(data.questions[0].questions));
    }
  });

  const [applyJob, { loading: applyLoading = false }] = useMutation(APPROVE_JOB, {
    onCompleted: (data) => {
      setIsApplied(true);
      setIsRejected(false);
      setAction(ACTIONS.APPLY);
      gtmTrackJobAccept(job.employerProfileProfileId);
    }
  });
  const [rejectJob, { loading: rejectLoading = false }] = useMutation(APPROVE_JOB, {
    onCompleted: (data) => {
      setIsApplied(false);
      setIsRejected(true);
      setIsStarred(false);
      setAction(ACTIONS.REJECT);
      gtmTrackJobReject(job.employerProfileProfileId);
    }
  });
  const [starJob, { loading: starLoading = false }] = useMutation(APPROVE_JOB, {
    onCompleted: (data) => {
      setIsStarred(!isStarred);
      if (!isStarred) setAction(ACTIONS.APPLY);
      else setAction('');
      gtmTrackJobStar(job.employerProfileProfileId);
    }
  });
  const [retractJob, { loading: retractLoading = false }] = useMutation(APPROVE_JOB, {
    onCompleted: (data) => {
      setIsApplied(false);
      setIsRejected(false);
      setIsStarred(false);
      setInterviewAppointed(false);
      setAction(ACTIONS.RETRACT);
      fetchJobById(); // update scheduleStatusCount and isSchedule
    }
  });

  const isJobApproving = applyLoading || rejectLoading || starLoading || retractLoading;
  const isApplyDisabled = isApplied || isRejected || isJobApproving;
  const isRejectDisabled = isRejected || isJobApproving;

  const fetchJobById = () => {
    if (jobId) {
      const id = Number(jobId);
      if (isAuthenticated) {
        fetchJob({ variables: { jobId: id } });
        fetchQuestions({ variables: { jobsJobsId: id } });
      } else {
        fetchPublicJob({ variables: { jobId: id } });
      }
    }
  };

  useEffect(() => {
    navigate(routerLocation.pathname, { replace: true, state: {} });
    fetchJobById();
    fetchCountryState();
  }, [jobId]);

  const {
    active = false,
    applicantAmount,
    city,
    countryId,
    description = 'N/D',
    employerProfile = {},
    employerProfileProfileId, // ! not available on public
    endDate,
    hireDate,
    imageUrl,
    isSchedule = false,
    location = 'N/D',
    payRange = 'N/D',
    requirements = 'N/D',
    scheduleStatusCount = 0,
    stateId,
    title: jobTitle = 'N/D',
    webUrl,
    zip
  } = job;
  const image = imageUrl || jobDefaultImg;
  const { imageUrl: companyLogo, name: companyName } = employerProfile;

  const formattedHireDate = useMemo(
    () =>
      hireDate ? formatDate(parseDate(hireDate, 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy') : 'N/D',
    [hireDate]
  );

  const selectedState = useMemo(() => getStateById(stateId), [getStateById, stateId]);

  const selectedCountry = useMemo(() => getCountryById(countryId), [getCountryById, countryId]);

  const getJobAddress = () =>
    city && selectedCountry.name && selectedState.code && zip
      ? `${city}, ${selectedState.code}, ${zip}, ${selectedCountry.code}`
      : 'N/D';

  const manageModals = useCallback((newConfig = {}) => {
    setModals((prev) => ({ ...prev, ...newConfig }));
  }, []);

  const callAuthAction = (callback) => {
    if (isAuthenticated) callback();
    else
      navigate(ROUTES.login.default, {
        state: { from: { location: routerLocation } }
      });
  };

  const handleApply = () => {
    if (jobId) {
      applyJob({
        variables: {
          jobId: Number(jobId),
          approved: true,
          passed: false
        }
      });
    }
  };

  const handleReject = () => {
    callAuthAction(() => {
      if (jobId) {
        rejectJob({
          variables: {
            jobId: Number(jobId),
            approved: false,
            passed: true
          }
        });
      }
    });
  };

  const handleStar = () => {
    callAuthAction(() => {
      if (jobId) {
        starJob({
          variables: {
            jobId: Number(jobId),
            passed: false,
            approved: false,
            starred: !isStarred
          }
        });
      }
    });
  };

  const handleRetract = () => {
    callAuthAction(() => {
      if (jobId) {
        retractJob({
          variables: {
            jobId: Number(jobId),
            approved: false,
            passed: true
          }
        });
      }
    });
  };

  const handleCancelInterview = () => {
    manageModals({
      cancelInterviewAlert: false,
      rescheduleAlert: false,
      rescheduleAlertReason: ''
    });
    handleRetract();
  };

  const handleReSchedule = () => {
    if (scheduleStatusCount >= CANCEL_LIMIT) {
      manageModals({ rescheduleAlert: true, rescheduleAlertReason: 'reschedule' });
    } else {
      manageModals({ scheduleModal: true, rescheduleAlert: false });
    }
  };

  const onInterviewAccept = useCallback(
    (date) => {
      manageModals({ scheduleModal: false });
      setInterviewAppointed(true);
      setInterviewDate(date);
      setAction(ACTIONS.INTERVIEW);
      fetchJobById(); // update scheduleStatusCount and isSchedule
    },
    [jobId]
  );

  const handleQuestionComplete = useCallback(
    (_, answers) => {
      if (isSchedule) {
        if (answers.filter((a) => !a).length) {
          manageModals({ questionsModal: false, webLinkModal: !!webUrl });
          handleApply();
        } else {
          manageModals({ scheduleModal: true, questionsModal: false });
        }
      } else {
        manageModals({ questionsModal: false, webLinkModal: !!webUrl });
        handleApply();
      }
    },
    [isSchedule]
  );

  const confirmApply = useCallback(() => {
    if (questions.length) {
      manageModals({ questionsModal: true, applyAlertModal: false });
    } else if (isSchedule) {
      manageModals({ scheduleModal: true, applyAlertModal: false });
    } else {
      manageModals({ applyAlertModal: false, webLinkModal: !!webUrl });
      handleApply();
    }
  }, [isSchedule, questions, webUrl]);

  const confirmInterviewCanceling = (e) => {
    e.stopPropagation();
    if (scheduleStatusCount >= CANCEL_LIMIT) {
      manageModals({
        rescheduleAlert: true,
        cancelInterviewAlert: false,
        rescheduleAlertReason: 'cancel'
      });
    } else {
      handleCancelInterview();
    }
  };

  const confirmReschedule = () => {
    manageModals({ scheduleModal: true, rescheduleAlert: false, rescheduleAlertReason: '' });
  };

  const onApply = () => {
    callAuthAction(() => {
      if (isSchedule) confirmApply();
      else manageModals({ applyAlertModal: true });
    });
  };

  const followExternalLink = () => {
    if (webUrl) window.open(urlWithHttp(webUrl), '__blank', 'noopener, noreferrer');
  };

  const onInterviewCancel = () => {
    manageModals({ cancelInterviewAlert: true });
  };

  const closeCloseConfirmation = useCallback(() => {
    manageModals({ applyAlertModal: false /* , cancelInterviewAlert: false */ });
  }, []);

  const closeEmployerPromptDialog = useCallback(() => {
    manageModals({ questionsModal: false });
  }, []);

  const closeScheduleDialog = useCallback(() => {
    manageModals({ scheduleModal: false });
  }, []);

  const closeInterviewCancelAlert = useCallback(() => {
    manageModals({ cancelInterviewAlert: false });
  }, []);

  const closeRescheduleAlert = useCallback(() => {
    manageModals({ rescheduleAlert: false });
  }, []);

  const closeWebUrlModal = useCallback(() => {
    manageModals({ webLinkModal: false });
  }, []);

  const getConfirmationModalProps = () => {
    const title = 'Apply to job?';
    const message = <Box textAlign="center">{`Do you want apply ${jobTitle}?`}</Box>;
    const confirmBtnName = 'Ok';
    let cancelBtnName;
    const onConfirm = confirmApply;

    return {
      isOpen: modals.applyAlertModal /* || modals.cancelInterviewAlert */,
      title,
      message,
      confirmBtnName,
      cancelBtnName,
      onConfirm,
      onClose: closeCloseConfirmation,
      onCancel: closeCloseConfirmation
    };
  };

  const renderReturnBtn = () => {
    const historyState = action
      ? {
          jobId,
          action
        }
      : { jobId };
    return (
      <IconButton
        isRouterLink
        to={{
          pathname: ROUTES.employee.dashboard,
          state: historyState
        }}
        variant="outlined"
        color="primary"
        className="goBackBtn"
      >
        <MdArrowBack />
      </IconButton>
    );
  };

  let applyButtonTitle = isSchedule ? 'Schedule Interview' : 'Apply Now!';
  applyButtonTitle = isApplied ? 'Applied' : applyButtonTitle;

  const renderActions = () => (
    <div className="actionsContainer">
      {interviewAppointed && interviewDate && (
        <div className="interviewDate">
          {formatDate(interviewDate, "'Scheduled:' MM-dd-yyyy '@' hh:mm aaa")}
        </div>
      )}
      <div className="actionsBtnsWrapper">
        {interviewAppointed ? (
          <>
            <Button
              variant="filled-primary"
              disabled={isJobApproving}
              endIcon={retractLoading ? <Spinner size={24} /> : null}
              className="applyBtn"
              onClick={onInterviewCancel}
            >
              Cancel
            </Button>
            <Button
              variant="filled-primary"
              disabled={!isSchedule || isJobApproving}
              endIcon={applyLoading ? <Spinner size={24} /> : null}
              className="applyBtn secondaryActionWrapper"
              onClick={handleReSchedule}
            >
              Re-Schedule
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="filled-primary"
              disabled={isApplyDisabled}
              endIcon={applyLoading ? <Spinner size={24} /> : null}
              className={`applyBtn ${isApplied ? 'applied' : ''}`}
              onClick={onApply}
            >
              {applyButtonTitle}
            </Button>
            <Button
              variant="outlined-secondary"
              disabled={isRejectDisabled}
              endIcon={rejectLoading || retractLoading ? <Spinner size={24} /> : null}
              className="cancelBtn"
              onClick={isApplied || interviewAppointed ? handleRetract : handleReject}
            >
              {isApplied ? 'Cancel' : 'Skip Job'}
            </Button>
            <div className="secondaryActionWrapper secondaryActionWrapper_labeled">
              <IconButton
                variant={isStarred ? 'filled-primary' : 'outlined'}
                color="primary"
                aria-label={isStarred ? 'Unstar' : 'Save for Later'}
                withTooltip
                toolTipProps={{ title: isStarred ? 'Unstar' : 'Save for Later' }}
                disabled={isJobApproving}
                className="secondaryAction"
                onClick={handleStar}
              >
                {starLoading ? <Spinner size={24} /> : <MdStarBorder fontSize="inherit" />}
              </IconButton>
              <div className="secondaryAction__label">
                {isStarred ? 'Unstar' : isDesktop ? 'Save for Later' : 'Save'}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const renderShareButton = () => (
    <ShareJob jobId={jobId} jobTitle={jobTitle}>
      {(share) => (
        <Button
          variant="filled-primary"
          endIcon={<MdIosShare />}
          className="shareButton"
          onClick={share}
        >
          Show a Friend
        </Button>
      )}
    </ShareJob>
  );

  const renderDesktopLayout = () => (
    <>
      {isAuthenticated && <Box mb="20px">{renderReturnBtn()}</Box>}
      <div className="job__employer">{companyName}</div>
      <Box display="flex">
        <div>
          <img src={image} alt="" width={242} height={213} className="job__photo" />
          <div className="label">Pay Range</div>
          <div className="text">{payRange && payRange !== 'N/D' ? payRange : 'N/D'}</div>
          <div className="label">Location</div>
          <div className="text">{getJobAddress()}</div>
          <div className="label">Interview Street Address</div>
          <div className="text">{location}</div>
          <div className="label">Hire Date</div>
          <div className="text">{formattedHireDate}</div>
        </div>
        <Box ml="24px">
          <h1 className="job__title">{jobTitle}</h1>
          <h2 className="label">Job Description</h2>
          <p className="text">{description}</p>
          <h3 className="label">Requirements</h3>
          <p className="text">{requirements}</p>
          {active && renderActions()}
          <div>{renderShareButton()}</div>
        </Box>
      </Box>
    </>
  );

  const renderMobileLayout = () => (
    <>
      <Box position="relative">
        {isAuthenticated && renderReturnBtn()}
        <img
          src={image}
          alt=""
          // width={260}
          // height={260}
          className="job__photo"
          style={calcImageSize(width)}
        />
      </Box>
      <div className="job__employer">{companyName}</div>
      <div className="job__title">{jobTitle}</div>
      <div className="row">
        <div className="label">Pay Range</div>
        <div className={`$"text" $"rowColumnRight"`}>
          {payRange && payRange !== 'N/D' ? payRange : 'N/D'}
        </div>
      </div>
      <div className="row">
        <div className="label">Location</div>
        <div className={`$"text" $"rowColumnRight"`}>{getJobAddress()}</div>
      </div>
      <div className="row">
        <div className="label">Interview Street Address</div>
        <div className="text">{location}</div>
      </div>
      <div className="row">
        <div className="label">Hire Date</div>
        <div className="text">{formattedHireDate}</div>
      </div>
      <div className="label">Job Description</div>
      <div className="text">{description}</div>
      <div className="label">Requirements</div>
      <div className="text">{requirements}</div>
      <div>{renderShareButton()}</div>
      {active && renderActions()}
    </>
  );

  return (
    <StyledRoot>
      {(jobLoading || publicJobLoading) && (
        <div className="loaderOverlay">
          <Spinner size={48} />
        </div>
      )}
      <div className="content">{isDesktop ? renderDesktopLayout() : renderMobileLayout()}</div>
      <ConfirmationDialog {...getConfirmationModalProps()} />
      <ConfirmationDialog
        isOpen={modals.webLinkModal}
        title="External Job Weblink"
        message={
          <Box textAlign="center">
            {`By pressing Ok you agree to be redirected to the external website with selected job: ${webUrl}`}
          </Box>
        }
        confirmBtnName="Ok"
        onConfirm={followExternalLink}
        onCancel={closeWebUrlModal}
        onClose={closeWebUrlModal}
      />
      <EmployerPromptDialog
        isOpen={modals.questionsModal}
        employer={companyName}
        questions={questions}
        job={job}
        onConfirm={handleQuestionComplete}
        onCancel={closeEmployerPromptDialog}
        onClose={closeEmployerPromptDialog}
      />
      {modals.scheduleModal && employerProfileProfileId && (
        <EmployeeInterviewScheduleModal
          isOpen={modals.scheduleModal}
          employerProfileId={employerProfileProfileId}
          employerProfileName={companyName}
          jobId={Number(jobId)}
          jobTitle={jobTitle}
          onConfirm={onInterviewAccept}
          onClose={closeScheduleDialog}
          DialogProps={{ fullScreen: !isDesktop }}
        />
      )}
      <EmployeeInterviewCancelAlert
        isOpen={modals.cancelInterviewAlert}
        onClose={closeInterviewCancelAlert}
        onConfirm={confirmInterviewCanceling}
      />
      <EmployeeInterviewRescheduleAlert
        isOpen={modals.rescheduleAlert}
        onClose={closeRescheduleAlert}
        onConfirm={() => {
          if (modals.rescheduleAlertReason === 'cancel') handleCancelInterview();
          if (modals.rescheduleAlertReason === 'reschedule') confirmReschedule();
        }}
      />
    </StyledRoot>
  );
}

EmployeeJob.propTypes = {};

export default EmployeeJob;
