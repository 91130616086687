import React, { useCallback, useEffect, useState, memo } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';
import { getUserId, getRoutes } from 'utils';
import { useAuth } from 'hooks';
import { Box, FormHelperText, styled, useMediaQuery } from 'components';
import { useForm } from 'components/form';
import { Button, Input, Spinner } from 'components/shared';
import { ConfirmationDialog } from 'components/dialogs';
import { client, DEACTIVATE_ACCOUNT, UPDATE_USER_PASSWORD } from 'api';
import styles from 'styles/Profile/EmployeeProfile';
import SectionLayout from '../SectionLayout';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();

function Account(props) {
  const { sectionLayoutProps } = props;
  const { signout } = useAuth();
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeactivationAlertOpen, setIsDeactivationAlertOpen] = useState(false);

  const [postNewPwd, { data = {}, error: mutationErrors = {}, loading: newPwdLoading = false }] =
    useMutation(UPDATE_USER_PASSWORD);
  const [postDeactivateAccount, { error: deactivateError, loading: deactivateLoading }] =
    useMutation(DEACTIVATE_ACCOUNT, {
      onCompleted: (data) => {
        signOut();
      }
    });

  const { set, $, withValidation, reset } = useForm({
    initial: { oldPwd: '', newPwd: '', repeatPwd: '' },
    validations: {
      oldPwd: 'presence',
      newPwd: 'presence',
      repeatPwd: {
        rules: [
          'presence',
          (value, { attrs }) => {
            if (!value) return;
            if (attrs.newPwd && value !== attrs.newPwd) return "Passwords don't match";
          }
        ],
        deps: ['newPwd']
      }
    }
  });

  useEffect(() => {
    if (data?.postEmployerNewPassword) {
      setIsSuccess(true);
      reset();
    }
  }, [data]);

  const signOut = () => {
    signout();
  };

  const submitNewPwd = withValidation(async (attrs) => {
    const userId = getUserId();
    const { oldPwd, newPwd } = attrs;
    setIsSuccess(false);
    if (userId) {
      postNewPwd({
        variables: {
          userId: parseInt(userId),
          pass: newPwd,
          oldPass: oldPwd
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
    }
  });

  const handleChange = useCallback((event, { name }) => {
    set(name, event.target.value);
  }, []);

  const handleUserDeactivate = () => {
    const userId = getUserId();
    setIsDeactivationAlertOpen(false);

    if (userId) {
      postDeactivateAccount({
        variables: { userId: parseInt(userId) }
      });
    }
  };

  return (
    <SectionLayout {...sectionLayoutProps}>
      <StyledRoot className="account__container">
        <div className="account__pwdForm">
          <h3 className="value account__title">Change Password</h3>
          <div className="account__formItem">
            <Input
              {...$('oldPwd', handleChange)}
              id="old-password-input"
              type="password"
              variant="textfield"
              label="Old Password"
              labelClassName="label"
              required
              withHelperText
              analyticParams={{
                key: 'Old password focused (employee profile)',
                trigger: 'focus'
              }}
              testID="old-password-input"
            />
          </div>
          <div className="account__doubleCol">
            <div className="account__formItem">
              <Input
                {...$('newPwd', handleChange)}
                id="new-password-input"
                type="password"
                variant="textfield"
                label="New Password"
                labelClassName="label"
                required
                withHelperText
                analyticParams={{
                  key: 'New password focused (employee profile)',
                  trigger: 'focus'
                }}
                testID="new-password-input"
              />
            </div>
            <div className="account__formItem">
              <Input
                {...$('repeatPwd', handleChange)}
                id="repeat-password-input"
                type="password"
                variant="textfield"
                label="Repeat New Password"
                labelClassName="label"
                required
                withHelperText
                analyticParams={{
                  key: 'Repeat password focused (employee profile)',
                  trigger: 'focus'
                }}
                testID="confirm-password-input"
              />
            </div>
          </div>
          <div>
            <Button
              variant="filled-primary"
              className="mainAction"
              disabled={newPwdLoading}
              endIcon={newPwdLoading ? <Spinner size={24} /> : null}
              onClick={submitNewPwd}
              testID="change-password-submit"
            >
              {isDesktop ? 'Change Password' : 'Change'}
            </Button>
            {mutationErrors?.graphQLErrors &&
              map(mutationErrors.graphQLErrors, (o, i, arr) => (
                <FormHelperText
                  key={`mutationError__${i}`}
                  error
                  style={{ marginBottom: i === arr.length - 1 ? 20 : 0 }}
                >
                  {o.message}
                </FormHelperText>
              ))}
            {isSuccess && (
              <Box textAlign="center" color="success.main">
                Password changed successfully
              </Box>
            )}
          </div>
        </div>

        <div>
          <h3 className="value account__title">Deactivate Account</h3>
          <Button
            variant="filled-primary"
            className="mainAction"
            disabled={deactivateLoading}
            endIcon={deactivateLoading ? <Spinner size={24} /> : null}
            onClick={() => setIsDeactivationAlertOpen(true)}
            testID="deactivate-account-button"
          >
            Deactivate
          </Button>

          <ConfirmationDialog
            isOpen={isDeactivationAlertOpen}
            title="Warning"
            message="This action will deactivate your account. Do you want to continue?"
            confirmBtnName="Ok"
            onConfirm={handleUserDeactivate}
            onCancel={() => setIsDeactivationAlertOpen(false)}
            onClose={() => setIsDeactivationAlertOpen(false)}
          />
        </div>
      </StyledRoot>
    </SectionLayout>
  );
}

Account.propTypes = {
  sectionLayoutProps: PT.objectOf(PT.any)
};

Account.defaultProps = {
  sectionLayoutProps: {}
};

export default memo(Account);
