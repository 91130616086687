import React, { Fragment, useState, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useMatch, useNavigate, useParams, useLocation, matchRoutes } from 'react-router-dom';
import { useApolloClient, useReactiveVar, useQuery, useLazyQuery } from '@apollo/client';
import { getUserId, getRoutes, getEmployerJobPaths, qaAttr } from 'utils';
import { useNotificationsSubscribe, useRestrictions, useCommonUI } from 'hooks';
import { Box, styled, Popover, useMediaQuery } from 'components';
import { Button, ButtonBase, IconButton } from 'components/shared';
import { ConfirmationDialog } from 'components/dialogs';
import { MdAdd, MdArrowDropDown, MdFlashOn } from 'components/icons';
import { LayoutViewController } from 'components/ApplicantsDashboard';
import { GET_JOB, GET_EMPLOYER_PROFILE, employerSubscriptionVar } from 'api';
import styles from 'styles/Dashboard/Header';
import ChatButton from './ChatButton';
import LogoLink from './LogoLink';
import ProfileLink from './ProfileLink';
import ReturnLink from './ReturnLink';
import LogoutButton from './LogoutButton';
import HeaderTemplate from './HeaderTemplate';

const ROUTES = getRoutes();

// eslint-disable-next-line react/prop-types
function EmployerHeader({ className }) {
  // const client = useApolloClient();
  const { id: idParam } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { restrictions = {}, fetchRestrictions = () => {} } = useRestrictions();
  const { subscribe: subscribeToNotifications, isNewMessage } = useNotificationsSubscribe({
    role: 'employer'
  });
  const {
    applicantsDashboardLayout,
    setApplicantsDashboardLayout,
    employerJobsFilter,
    setEmployerJobsFilter
  } = useCommonUI();
  const employerSubsInfo = useReactiveVar(employerSubscriptionVar);
  const { planName } = employerSubsInfo;

  const { data: employerProfileData } = useQuery(GET_EMPLOYER_PROFILE, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    variables: { userId: Number(getUserId()) }
  });
  const employerProfile = employerProfileData?.employerProfile || {};
  const isJobPostingAllowed = employerProfile?.allowJobPosting;

  const [fetchJob, { data: jobData }] = useLazyQuery(GET_JOB, {
    cachePolicy: 'cache-only',
    errorPolicy: 'all'
  });
  const employerJobDetails = jobData?.job || {};

  const [jobsFilterAnchor, setJobsFilterAnchor] = useState(null);
  const [mobileSelectorAnchor, setMobileSelectorAnchor] = useState(null);
  const [jobPostingAlertOpen, setJobPostingAlertOpen] = useState(false);

  const isEnterpriseDashboard = pathname === ROUTES.employer.enterprise;
  const isEnterpriseSearch = pathname === ROUTES.employer.enterprise_search;
  const isSchedule = pathname === ROUTES.employer.enterprise_schedule;
  const isGrow = pathname === ROUTES.employer.enterprise_grow;
  const isPlansPage = pathname === ROUTES.employer.plans;
  const isEmployerProfile = pathname === ROUTES.employer.profile;
  const isJobsPage = pathname === ROUTES.employer.dashboard;
  const isNewJobPage = pathname === ROUTES.employer.job_new;
  const paths = [...getEmployerJobPaths().map((p) => ({ path: p }))];
  const employerJobMatches = useMemo(
    // ! could match with non job routes
    () => matchRoutes(paths, location.pathname),
    [location.pathname]
  );
  const isEditJobPage = employerJobMatches?.[0]?.route.path === ROUTES.employer.job_edit;
  const isNewApplicantsPage = employerJobMatches?.[0]?.route.path === ROUTES.employer.job;
  const isJobPostingPage = isNewJobPage || isEditJobPage;
  const isJobDetailsPage = idParam && !isJobPostingPage && !!employerJobMatches?.[0];

  useEffect(() => {
    subscribeToNotifications();
  }, []);

  useEffect(() => {
    if (isJobDetailsPage) fetchJob({ variables: { jobId: Number(idParam) } });
  }, [isJobDetailsPage]);

  const handleJobPostClick = () => {
    // if (!restrictions.jobPosting && isEmployerInstructed) return;
    // if (!isEmployerInstructed) openJobPostingInstruction();
    if (isJobPostingAllowed) navigate(ROUTES.employer.job_new);
    // eslint-disable-next-line no-use-before-define
    else openJobPostingAlert();
  };

  const openJobsFilter = useCallback((e) => setJobsFilterAnchor(e.currentTarget), []);
  const closeJobsFilter = useCallback(() => setJobsFilterAnchor(null), []);

  const openMobileSelector = useCallback((e) => setMobileSelectorAnchor(e.currentTarget), []);
  const closeMobileSelector = useCallback(() => setMobileSelectorAnchor(null), []);

  const openJobPostingAlert = useCallback(() => setJobPostingAlertOpen(true), []);
  const closeJobPostingAlert = useCallback(() => setJobPostingAlertOpen(false), []);

  const selectJobsFilterOption = (e, value) => {
    setEmployerJobsFilter(value);
    closeJobsFilter();
  };

  const renderLogo = (logoProps) => <LogoLink key="header-logo" {...logoProps} />;

  const renderLogoutButton = () => <LogoutButton key="header-logout" />;

  const renderTryPremiumButton = () => (
    <Button
      key="header-premium"
      isRouterLink
      to={ROUTES.employer.plans}
      variant="outlined-secondary"
      startIcon={<MdFlashOn />}
      className="premiumBtn headerElement"
      testID="try-premium-button"
    >
      Try Premium
    </Button>
  );

  const renderChatButton = () => (
    <ChatButton key="header-chat" userRole="employer" isNewMessage={isNewMessage} />
  );

  const renderProfileButton = () => <ProfileLink key="header-profile" userRole="employer" />;

  const renderPostJobButton = () => (
    <Button
      key="header-job-post"
      disabled={!isJobPostingAllowed || !restrictions.jobPosting}
      variant="filled-primary"
      startIcon={<MdAdd />}
      className="postJobBtn headerElement"
      onClick={handleJobPostClick}
      testID="post-job-button"
    >
      Post a Job
    </Button>
  );

  const renderMobilePostJobButton = () => (
    <div key="header-job-post" className="mobilePostJobBtnContainer">
      {/* {isInitialEmployerUiTipVisible && renderInitialEmployerUiTip(false)} */}
      <IconButton
        disabled={!isJobPostingAllowed || !restrictions.jobPosting}
        variant="filled-primary"
        aria-label="post a job"
        className="mobilePostJobBtn"
        onClick={handleJobPostClick}
        testID="post-job-button"
      >
        <MdAdd style={{ fontSize: 30 }} />
      </IconButton>
    </div>
  );

  const renderBackButton = (title = '', btnProps = {}) => (
    <ReturnLink key="header-back" title={title} {...btnProps} />
  );

  const renderJobsFilter = () => (
    <Fragment key="jobsFilter">
      <Button
        variant="text"
        endIcon={<MdArrowDropDown color="inherit" />}
        className="jobsFilterBtn headerElement"
        onClick={openJobsFilter}
        testID="jobs-dashboard-dropdown-button"
      >
        {employerJobsFilter === 0 ? 'My Jobs' : 'Archived Job'}
      </Button>
      <Popover
        open={!!jobsFilterAnchor}
        anchorEl={jobsFilterAnchor}
        classes={{ paper: 'optionsPopupPaper' }}
        anchorOrigin={{
          vertical: 30,
          horizontal: isDesktop ? 'left' : 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isDesktop ? 'left' : 'center'
        }}
        disablePortal
        onClose={closeJobsFilter}
      >
        <ButtonBase
          className="optionsPopupBtn"
          onClick={(e) => selectJobsFilterOption(e, 0)}
          {...qaAttr('my-jobs-dropdown-option-button')}
        >
          {isDesktop ? 'My Jobs' : 'Current Jobs'}
        </ButtonBase>
        <ButtonBase
          className="optionsPopupBtn"
          onClick={(e) => selectJobsFilterOption(e, 1)}
          {...qaAttr('archived-jobs-dropdown-option-button')}
        >
          Archived Jobs
        </ButtonBase>
      </Popover>
    </Fragment>
  );

  const renderJobDetailSubPageSelector = () => {
    const getCurrentSelectedPage = () => {
      const path = employerJobMatches?.[0]?.route.path;
      if (path === ROUTES.employer.job) return 'Applicants';
      if (path === ROUTES.employer.job_match) return 'Quick Hire Match';
      if (path === ROUTES.employer.job_approved) return 'Approved';
      if (path === ROUTES.employer.job_rejected) return 'Rejected';
      if (path === ROUTES.employer.job_starred) return 'Starred';
    };
    const linkProps = {
      isRouterLink: true,
      onClick: closeMobileSelector,
      className: 'selectorBtn'
    };
    const routesWithId = getRoutes({ id: idParam });

    return (
      <Box key="jobDetailSubPageSelector" display="flex" justifyContent="center" flex={1}>
        <Button
          endIcon={<MdArrowDropDown color="inherit" />}
          className="selectorTriggerBtn"
          onClick={openMobileSelector}
          testID="job-dashboard-mobile-dropdown"
        >
          {getCurrentSelectedPage()}
        </Button>
        <Popover
          open={!!mobileSelectorAnchor}
          anchorEl={mobileSelectorAnchor}
          classes={{ paper: 'selectorPopupPaper' }}
          anchorOrigin={{
            vertical: 30,
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          disablePortal
          onClose={closeMobileSelector}
        >
          <Button to={routesWithId.employer.job} {...linkProps} testID="dropdown-applicants-link">
            Applicants
          </Button>
          {/*<Button to={`/employer-dashboard/${idParam}/match`} {...linkProps}>Quick Hire Match</Button>*/}
          <Button
            to={routesWithId.employer.job_approved}
            {...linkProps}
            testID="dropdown-approved-link"
          >
            {employerJobDetails?.webUrl ? 'Approved' : 'Hired'}
          </Button>
          <Button
            to={routesWithId.employer.job_rejected}
            {...linkProps}
            testID="dropdown-rejected-link"
          >
            Rejected
          </Button>
          <Button
            to={routesWithId.employer.job_starred}
            {...linkProps}
            testID="dropdown-starred-link"
          >
            Starred
          </Button>
        </Popover>
      </Box>
    );
  };

  const renderPlansPageTitle = () => (
    <span>
      Plans: Current Plan -{' '}
      <Box component="span" color="primary.main">
        {planName}
      </Box>
    </span>
  );

  const renderHomePageLayout = (params) => {
    const left = isDesktop ? [renderLogo()] : [renderLogoutButton()];
    const right = isDesktop
      ? [renderTryPremiumButton(), renderChatButton(), renderProfileButton()]
      : [renderProfileButton(), renderChatButton()];

    return <HeaderTemplate leftElements={left} rightElements={right} {...params} />;
  };

  const renderContent = () => {
    const commonLeftElements = [
      renderBackButton('Dashboard', { isRouterLink: true, to: ROUTES.employer.enterprise })
    ];
    const commonRightElements = isDesktop
      ? [renderTryPremiumButton(), renderLogoutButton(), renderChatButton()]
      : [renderChatButton(), renderLogoutButton()];

    switch (true) {
      case isEnterpriseDashboard:
        return renderHomePageLayout({
          title: 'Dashboard',
          titleQaId: 'enterprise-dashboard-title'
        });
      case isEmployerProfile: {
        return (
          <HeaderTemplate
            title="Account"
            titleQaId="employer-profile"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isSchedule: {
        return (
          <HeaderTemplate
            title="Scheduling"
            titleQaId="employer-schedule"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isGrow: {
        return (
          <HeaderTemplate
            title="QH Grow"
            titleQaId="qh-grow"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isEnterpriseSearch: {
        return (
          <HeaderTemplate
            title={isDesktop ? 'Search Candidate Database' : 'Search Candidates'}
            titleQaId="search-database"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isJobsPage: {
        const center = isDesktop ? [renderJobsFilter()] : [];
        const right = isDesktop
          ? [
              renderTryPremiumButton(),
              renderPostJobButton(),
              renderChatButton(),
              renderProfileButton()
            ]
          : [renderProfileButton(), renderChatButton()];

        return (
          <HeaderTemplate
            title={isDesktop ? '' : 'Jobs'}
            titleQaId={isDesktop ? '' : 'jobs'}
            leftElements={commonLeftElements}
            rightElements={right}
            centerElements={center}
          />
        );
      }
      case isJobDetailsPage: {
        const left = [
          renderBackButton('Jobs', { isRouterLink: true, to: ROUTES.employer.dashboard })
        ];
        let right = [renderChatButton(), renderProfileButton()];
        if (!isDesktop) {
          if (isNewApplicantsPage) {
            right = (
              <LayoutViewController
                value={applicantsDashboardLayout}
                onChange={setApplicantsDashboardLayout}
              />
            );
          } else {
            right = [renderProfileButton(), renderChatButton()];
          }
        }
        const center = isDesktop ? [] : [renderJobDetailSubPageSelector()];

        return (
          <HeaderTemplate
            title={
              isDesktop && employerJobDetails?.title
                ? `Applicants to '${employerJobDetails?.title || ''}'`
                : ''
            }
            titleQaId="applicants-to"
            leftElements={left}
            rightElements={right}
            centerElements={center}
          />
        );
      }
      case isJobPostingPage: {
        const left = [
          renderBackButton('Jobs', { isRouterLink: true, to: ROUTES.employer.dashboard })
        ];
        return (
          <HeaderTemplate
            title="Post a Job"
            titleQaId="post-job"
            leftElements={left}
            rightElements={commonRightElements}
          />
        );
      }
      case isPlansPage: {
        const left = [
          renderBackButton('Jobs', { isRouterLink: true, to: ROUTES.employer.dashboard })
        ];
        const right = isDesktop
          ? [renderChatButton(), renderProfileButton()]
          : [renderProfileButton(), renderChatButton()];
        return (
          <HeaderTemplate
            title={planName ? renderPlansPageTitle() : 'Plans'}
            titleQaId="employer-plans"
            leftElements={left}
            rightElements={right}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={className}>
      {renderContent()}
      {isJobsPage && renderMobilePostJobButton()}

      <ConfirmationDialog
        isOpen={jobPostingAlertOpen}
        message={
          <Box textAlign="center">
            <p>Please upgrade your account</p>
          </Box>
        }
        confirmBtnName="Ok"
        titleProps={{ style: { padding: '0 8px' } }}
        headerProps={{ alignItems: 'flex-start' }}
        onConfirm={closeJobPostingAlert}
        onClose={closeJobPostingAlert}
        onCancel={closeJobPostingAlert}
      />
    </div>
  );
}

const StyledHeader = styled(EmployerHeader)(styles);

export default memo(StyledHeader);
