import React, { useContext } from 'react';
import PT from 'prop-types';
import { MdCheck, MdClose, MdLink, MdStarBorder, MdTouchApp, MdWork } from 'components/icons';
import { Spinner } from 'components/shared';
import EmployerGrowContext from '../../EmployerGrowContext';

function JobPostingScores(props) {
  const { isMainDataLoading, jobPostingScores = {} } = useContext(EmployerGrowContext);
  const {
    openedJobs = 0,
    totalApplicants = 0,
    totalStarred = 0,
    totalPassed = 0,
    totalViewed = 0,
    totalReferral = 0,
    totalClicks = 0
  } = jobPostingScores;

  return (
    <div className="jobPostingScoresContainer">
      {isMainDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle jobPostingScoresTitle">Job Posting Scores</h4>
      <div className="jobPostingScoresContent">
        <div className="jobPostingScoresRow">
          <MdWork color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Open Jobs</div>
          <div className="jobPostingScoresValue">{openedJobs}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdCheck color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Total Applicants</div>
          <div className="jobPostingScoresValue">{totalApplicants}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdStarBorder color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Applicants Starred</div>
          <div className="jobPostingScoresValue">{totalStarred}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdClose color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Applicants Rejected</div>
          <div className="jobPostingScoresValue">{totalPassed}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdTouchApp color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Clicks on Job Cards</div>
          <div className="jobPostingScoresValue">{totalClicks}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdLink color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Referral Links</div>
          <div className="jobPostingScoresValue">{totalReferral}</div>
        </div>
      </div>
    </div>
  );
}

JobPostingScores.propTypes = {};

export default JobPostingScores;
