import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';
import { qaAttr, getRoutes } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useCommonUI, useCountryStateQuery, useEmployerProfileQuery } from 'hooks';
import { Box, Dialog, Popover, styled, useMediaQuery } from 'components';
import { Button, Spinner, Select } from 'components/shared';
import { MdChevronRight, MdArrowDropDown } from 'components/icons';
import { JobCard } from 'components/Dashboard/employer';
import { GET_EMPLOYER_JOBS } from 'api';
import styles from 'styles/Dashboard/EmployerJobs';
import defaultJobImg from 'assets/img/job_default.png';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();

function EmployerJobs(props) {
  const isDesktop = useMediaQuery('(min-width:1024px)', { noSsr: true });
  const navigate = useNavigate();
  const {
    employerJobsFilter,
    hirePromptWasShown,
    newJobPromptWasShown,
    setEmployerJobsFilter,
    setHirePromptWasShown,
    setNewJobPromptWasShown
  } = useCommonUI();
  const [hirePromptOpen, setHirePromptOpen] = useState(false);
  const [newJobPromptOpen, setNewJobPromptOpen] = useState(false);

  const { fetchProfile, canPostJob } = useEmployerProfileQuery();
  const { fetchCountryState, getStateById, getCountryById } = useCountryStateQuery();

  const [fetchJobs, { data: jobsData = {}, loading: jobsLoading = true, called }] = useLazyQuery(
    GET_EMPLOYER_JOBS,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );
  const jobsFetchComplete = !jobsLoading && called;
  const jobs = jobsData?.employerJobs || [];

  const oldestJob = useMemo(() => {
    const jobByDays = jobs
      .map((job) => {
        const diff = new Date().getTime() - +job.createdAt;
        return [Math.ceil(diff / (1000 * 3600 * 24)), job];
      })
      .filter(([days, job]) => days >= 7);
    const { length } = jobByDays;

    if (length > 0) {
      const idx = Math.floor(Math.random() * (length - 0) + 0);
      return jobByDays[idx][1];
    }

    return null;
  }, [JSON.stringify(jobs)]);

  const getJobAddress = (job) => {
    const { city, stateId, countryId, zip } = job;
    const selectedState = getStateById(stateId);
    const selectedCountry = getCountryById(countryId);

    return city && selectedCountry.name && selectedState.code && zip
      ? `${city}, ${selectedState.code}, ${zip}, ${selectedCountry.code}`
      : '';
  };

  useEffect(() => {
    fetchJobs({ variables: { active: employerJobsFilter === 0 } });
  }, [employerJobsFilter]);

  useEffect(() => {
    fetchProfile();
    fetchCountryState();
  }, []);

  useEffect(() => {
    if (employerJobsFilter === 0 && oldestJob && !hirePromptWasShown) {
      setHirePromptWasShown(true);
      setHirePromptOpen(true);
    }
  }, [employerJobsFilter, oldestJob, hirePromptWasShown]);

  useEffect(() => {
    if (employerJobsFilter === 0 && !newJobPromptWasShown && jobsFetchComplete && !jobs.length) {
      setNewJobPromptWasShown(true);
      setNewJobPromptOpen(true);
    }
  }, [employerJobsFilter, jobsFetchComplete, jobs.length]);

  const renderDesktopJobCard = (job) => (
    <JobCard
      data={job}
      isRouterLink
      to={getRoutes({ id: job.id }).employer[job.draft ? 'job_edit' : 'job']}
      onClick={() => {
        track('Employer Job Card Clicked');
      }}
    />
  );

  const renderHirePrompt = () => (
    <Dialog
      open={hirePromptOpen}
      container={() => document.getElementById('modals-root')}
      classes={{ paper: 'hirePrompt' }}
      onClose={() => setHirePromptOpen(false)}
    >
      <div className="hirePrompt__content">
        <div className="hirePrompt__title">Hey There</div>
        <div className="hirePrompt__descr">
          Looks like this jobs has been open for awhile, have you hired someone for it yet?
        </div>
        <div className="hirePrompt__jobTitle">{oldestJob?.title}</div>
      </div>
      <div className="hirePrompt__actions">
        <Button
          isRouterLink
          to={getRoutes({ id: oldestJob?.id }).employer.job_approved}
          className="hirePrompt__btn"
        >
          Hired Someone
        </Button>
        <Button className="hirePrompt__btn" onClick={() => setHirePromptOpen(false)}>
          Not Yet
        </Button>
      </div>
    </Dialog>
  );

  const renderNewJobPrompt = () => (
    <Dialog
      open={newJobPromptOpen}
      container={() => document.getElementById('modals-root')}
      classes={{ paper: 'hirePrompt' }}
      onClose={() => setNewJobPromptOpen(false)}
    >
      <Box p="20px">
        <Box component="p" textAlign="center">
          Looks like you don't have any active jobs
        </Box>
      </Box>
      <Box pb="20px" textAlign="center">
        <Button
          variant="filled-primary"
          isRouterLink
          to={ROUTES.employer.job_new}
          className="newJobPrompt__btn"
          disabled={!canPostJob}
        >
          Post a Job
        </Button>
      </Box>
    </Dialog>
  );

  const renderMobileJobCard = (job) => {
    const address = getJobAddress(job);
    return (
      <div
        // isRouterLink
        // to={getRoutes({ id: job.id }).employer[job.draft ? 'job_edit' : 'job']}
        className="mobileJobCard"
        {...qaAttr(`job-link-${job.title}`)}
      >
        <Box display="flex" flexDirection="column">
          <img src={job.imageUrl || defaultJobImg} alt="" className="mobileJobCard__photo" />
          <Button
            isRouterLink
            to={getRoutes({ id: job.id }).employer.job_approved}
            variant="filled-primary"
            className="mobileJobCard__hiredBtn"
          >
            Hired
          </Button>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignSelf="normal"
          onClick={() =>
            navigate(getRoutes({ id: job.id }).employer[job.draft ? 'job_edit' : 'job'])
          }
        >
          <Box width="100%" ml="12px" mb="10px" textAlign="left">
            <Box mb="4px" fontSize={11} color="#000" fontWeight="bold">
              {job.title}
            </Box>
            <Box mb="13px">
              <Box fontSize={10} color="#8283BF" style={{ textTransform: 'uppercase' }}>
                {address}
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box fontSize={10} lineHeight="12px" fontWeight="bold" color="#8283BF">
                • 0 NEW
              </Box>
              <Box ml="6px" fontSize={10} lineHeight="12px" color="#A4A3A4">
                {employerJobsFilter === 1 ? 'Inactive' : `• ${job.qmCount + job.applied} APPLIED`}
              </Box>
              {job.draft && <div className="draftLabel">Draft</div>}
            </Box>
          </Box>
          <MdChevronRight className="mobileJobCard__arrow" />
        </Box>
      </div>
    );
  };

  const renderFilter = () => (
    <Box px="16px" pb="13px">
      <Select
        value={employerJobsFilter}
        inputVariant="outlined"
        options={[
          { label: 'Current Jobs', value: 0, ...qaAttr('my-jobs-dropdown-option-button') },
          { label: 'Archived Jobs', value: 1, ...qaAttr('archived-jobs-dropdown-option-button') }
        ]}
        InputComponentProps={{
          htmlInputClassName: 'filterInput'
        }}
        onChange={(e) => setEmployerJobsFilter(e.target.value)}
        testID="jobs-dashboard-dropdown-button"
      />
    </Box>
  );

  return (
    <StyledRoot className="container">
      {!isDesktop && renderFilter()}
      <div className="jobsContainer">
        {jobsLoading && (
          <div className="jobsLoader">
            <Spinner size={30} />
          </div>
        )}
        {map(jobs, (job, i) => (
          <div key={`job__${i}`} className="cardContainer">
            {isDesktop ? renderDesktopJobCard(job, i) : renderMobileJobCard(job, i)}
          </div>
        ))}
      </div>
      {renderHirePrompt()}
      {renderNewJobPrompt()}
      <div id="modals-root" />
    </StyledRoot>
  );
}

export default EmployerJobs;
