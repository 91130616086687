import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getUserId } from 'utils';
import { GET_EMPLOYER_PROFILE } from 'api';

function useEmployerProfileQuery(props) {
  const { queryOptions = {} } = props || {};

  const [fetch, { data, loading }] = useLazyQuery(GET_EMPLOYER_PROFILE, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    ...queryOptions
  });
  const profile = data?.employerProfile || {};
  const { allowJobPosting, restrictions = {} } = profile;
  const canPostJob = allowJobPosting && restrictions?.jobPosting;
  const canManageEmployee = restrictions.allowEmployeeActions;

  const fetchProfile = useCallback(async (fetchParams = {}) => {
    const uid = fetchParams?.variables?.userId || getUserId();

    if (uid) fetch({ variables: { userId: parseInt(uid) }, ...fetchParams });
  }, []);

  return { fetchProfile, profile, loading, canPostJob, canManageEmployee };
}

export default useEmployerProfileQuery;
