import React from 'react';
import { Outlet } from 'react-router-dom';
import flowRight from 'lodash/flowRight';
import { withTimeZone, withCommonUI, withRestrictions, withAlerts, withNotifications } from 'hocs';

const enhance = flowRight(
  withRestrictions,
  withTimeZone,
  withCommonUI,
  withNotifications,
  withAlerts
);

function RootLayout() {
  return <Outlet />;
}

export default enhance(RootLayout);
