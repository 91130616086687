import React, { memo } from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { Button, IconButton, PopupMenu } from 'components/shared';
import { MdMoreVert, MdClose, MdStarBorder, MdCheck, MdChatBubbleOutline } from 'components/icons';

const StyledPopupMenu = styled(PopupMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent'
  },
  '& .actionsContainer': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .action': {
    justifyContent: 'flex-start',
    fontSize: 14,
    '& .StyledButton-startIcon': {
      fontSize: 18
    }
  }
}));

const popupTrigger = ({ setAnchorEl, id }) => (
  <IconButton
    color="primary"
    width={35}
    height={35}
    className="applicantPopupTrigger"
    onClick={(e) => setAnchorEl(e.currentTarget)}
    testID={`candidates-actions-popup-trigger-${id}`}
  >
    <MdMoreVert fontSize="inherit" color="inherit" />
  </IconButton>
);

function ApplicantActionsPopup(props) {
  const {
    actionTitles,
    className,
    disableAccept,
    disableReject,
    disableStar,
    id,
    isStarred,
    onAccept,
    onReject,
    onStar,
    onChat,
    withAccept,
    withReject,
    withStar,
    withChat,
    ...rest
  } = props;

  const onActionClick = (closePopup, action) => {
    closePopup();
    action();
  };

  return (
    <StyledPopupMenu getTrigger={(o) => popupTrigger({ ...o, id })} className={className} {...rest}>
      {({ closePopup }) => (
        <div className="actionsContainer">
          {withAccept && (
            <Button
              className="action"
              startIcon={<MdCheck color="primary" fontSize="inherit" />}
              disabled={disableAccept}
              onClick={() => onActionClick(closePopup, onAccept)}
              testID={`candidate-accept-button-${id}`}
            >
              {actionTitles.accept}
            </Button>
          )}
          {withStar && (
            <Button
              className="action"
              startIcon={<MdStarBorder color="primary" fontSize="inherit" />}
              disabled={disableStar}
              onClick={() => onActionClick(closePopup, onStar)}
              testID={`candidate-star-button-${id}`}
            >
              {isStarred ? 'Unstar' : actionTitles.star}
            </Button>
          )}
          {withReject && (
            <Button
              className="action"
              startIcon={<MdClose color="primary" fontSize="inherit" />}
              disabled={disableReject}
              onClick={() => onActionClick(closePopup, onReject)}
              testID={`candidate-reject-button-${id}`}
            >
              {actionTitles.reject}
            </Button>
          )}
          {withChat && (
            <Button
              className="action"
              startIcon={<MdChatBubbleOutline color="primary" fontSize="inherit" />}
              onClick={() => onActionClick(closePopup, onChat)}
              testID={`candidate-chat-button-${id}`}
            >
              Send Message
            </Button>
          )}
        </div>
      )}
    </StyledPopupMenu>
  );
}

ApplicantActionsPopup.propTypes = {
  actionTitles: PT.shape({
    accept: PT.string,
    reject: PT.string,
    star: PT.string
  }),
  className: PT.string,
  disableAccept: PT.bool,
  disableReject: PT.bool,
  disableStar: PT.bool,
  id: PT.oneOfType([PT.string, PT.number]),
  isStarred: PT.bool,
  onAccept: PT.func,
  onReject: PT.func,
  onStar: PT.func,
  onChat: PT.func,
  withAccept: PT.bool,
  withReject: PT.bool,
  withStar: PT.bool,
  withChat: PT.bool
};

ApplicantActionsPopup.defaultProps = {
  actionTitles: {
    accept: 'Apply',
    reject: 'Reject',
    star: 'Star'
  },
  className: '',
  disableAccept: false,
  disableReject: false,
  disableStar: false,
  id: 'popup',
  isStarred: false,
  onAccept: () => {},
  onReject: () => {},
  onStar: () => {},
  onChat: () => {},
  withAccept: true,
  withReject: true,
  withStar: true,
  withChat: true
};

export default memo(ApplicantActionsPopup);
