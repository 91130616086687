import React from 'react';
import PT from 'prop-types';
import { Box } from 'components';
import { Button } from 'components/shared';
// import styles from 'styles/ApplicantsDashboard';

function EmptyApplicants(props) {
  return (
    <Box textAlign="center">
      <Box mb="12px" className="emptyApplicants__title">
        No New Applicants
      </Box>
      {/* <Box mb="24px" className="textSecondary">
        We can notify you when you have new candidates.
      </Box> */}
      {/* <Button variant="filled-primary" sx={{ height: 44, width: 221 }}>
        Notify Me by Email
      </Button> */}
    </Box>
  );
}

EmptyApplicants.propTypes = {};

export default EmptyApplicants;
