import { makeVar } from '@apollo/client';

export const employerSubscriptionVar = makeVar({});

export const updateEmployerSubscriptionVar = (params = {}) => {
  try {
    const prev = employerSubscriptionVar() || {};
    employerSubscriptionVar({ ...prev, ...params });
  } catch (error) {
    console.error('updateEmployerSubscriptionVar ', error);
  }
};

export const restrictionsVar = makeVar({}); // @TODO: use it as replacement for useRestrictions hook in future

export const updateRestrictionsVar = (params = {}) => {
  const prev = restrictionsVar() || {};
  restrictionsVar({ ...prev, ...params });
};
