import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { Spinner } from 'components/shared';
import { getProfileId } from 'utils';
import growApi from '../../api';
import { makeQueryFetch } from '../../api/util';
import EmployerGrowContext from '../../EmployerGrowContext';

function ChurnRate(props) {
  // const { } = useContext(EmployerGrowContext);
  const client = useApolloClient();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [rate, setRate] = useState(0);

  const fetchData = async () => {
    const profileId = Number(getProfileId());

    if (profileId) {
      setIsDataLoading(true);

      const data = await makeQueryFetch(
        {
          query: growApi.query.EMPLOYER_CHURN_RATE,
          path: 'getChurnRateEmployer',
          variables: { employerProfileProfileId: profileId }
        },
        client
      );

      if (data?.churnRateEmployer) setRate(data.churnRateEmployer);

      setIsDataLoading(false);
    } else {
      throw new Error('getHiringRateEmployer error: profileId is not defined');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="chart">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Employee Churn Rate</h4>
      <p className="hiringRateDescription">
        The amount of employees that leave your company or are looking for new jobs within 1 year of
        being hired.
      </p>
      <div className="hiringRateValue">{`${rate}%`}</div>
    </div>
  );
}

export default ChurnRate;
