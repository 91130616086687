import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Box } from 'components';
import { LandingHeader } from 'components/shared';
import LoginForm from 'components/Login';
import AppLayout from './Layouts/AppLayout';

function Login() {
  const { pathname } = useLocation();
  let loginPage = pathname === '/new-password' ? 'new-password' : 'sign-in';
  if (pathname === '/verify') loginPage = 'sign-up-verify';

  if (pathname === '/') return <Navigate to="/login" />;

  return (
    <AppLayout>
      <LandingHeader />
      <Box flex="1 1 auto" display="flex" flexDirection="column" overflow="auto">
        <LoginForm page={loginPage} />
      </Box>
    </AppLayout>
  );
}

export default Login;
