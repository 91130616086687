import { gql } from '@apollo/client';

export const GET_CAREER_DEVELOPMENT_WITH_HEALTH_SCORE = gql`
  query careerDevelopmentByUserId {
    careerDevelopmentByUserId {
      careerHealthScore {
        rating {
          name
        }
        score
        sectionScores {
          attributes {
            complete
            label
            value
          }
          careerScoreTypeId
          careerScoreType {
            name
          }
          impact {
            name
          }
          percent
          score
        }
      }
      curJobTypeId
      currentlySearching
      futureJobTypeId
      id
      industryId
      showEmployerData
    }
  }
`;

export const CAREER_DEV_BY_USER_ID = gql`
  query connectionCareerDevelopmentByUserId($connectionUserId: Int) {
    connectionCareerDevelopmentByUserId(connectionUserId: $connectionUserId) {
      curJobTypeId
      employeeProfileProfileId
      futureJobTypeId
      id
      industryId
      employeeProfile {
        education {
          name
          pos
          startDate
          endDate
          currentJob
        }
        experience {
          name
          pos
          startDate
          endDate
          currentJob
        }
        imageUrl
        industry {
          industry_id
          name
        }
        motto
        name
        skills {
          name
        }
        profile_id
        user_id
      }
    }
  }
`;

export const GET_GROW_INDUSTRIES = gql`
  query industries {
    industries {
      industry_id
      name
    }
  }
`;

