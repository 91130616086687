import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Draggable } from 'react-beautiful-dnd';
import { Popover } from 'components';
import { Button, ButtonBase, IconButton } from 'components/shared';
import { MdClose, MdCheck, MdExpandMore } from 'components/icons';
import { get, map } from 'lodash';
import { SEND_INVITE } from 'api';
import defaultJobImg from 'assets/img/job_default.png';
import EmployerGrowContext from '../../EmployerGrowContext';

const getItemStyle = (isDragging, draggableStyle) => ({
  boxShadow: isDragging ? '0 0 20px rgba(0, 0, 0, .3)' : '0 0 6px rgba(0, 0, 0, .13)',
  borderColor: isDragging ? '#4743A2' : 'white',
  // styles needed to apply on draggables
  ...draggableStyle
});

function EmployeeCard({ id, index, employeeProfile: emp }) {
  const { employeeProfileProfileId } = emp;
  const { name, imageUrl, user_id } = emp.employeeProfile;
  const {
    closeEmployeeInvitePopup,
    deleteEmployee,
    isEditable,
    invitePopupAnchorEl,
    invitePopupId,
    jobs,
    jobTypes,
    showEmployeeInvitePopup,
    showEmployeeProfile
  } = useContext(EmployerGrowContext);
  const [invited, setInvited] = useState(false);

  const [sendInvite, { loading }] = useMutation(SEND_INVITE);

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteEmployee(employeeProfileProfileId);
  };

  const handleProfileView = () => {
    showEmployeeProfile(user_id);
  };

  const openInvitePopup = (e) => {
    e.stopPropagation();
    showEmployeeInvitePopup(e, user_id);
  };

  const handleInvite = (jobId) => {
    if (user_id) {
      sendInvite({ variables: { employeeUserId: user_id, id: jobId } });
      setInvited(true);
    }
    closeEmployeeInvitePopup();
  };

  const renderInvitePopup = () => (
    <Popover
      open={!!invitePopupAnchorEl && invitePopupId === user_id}
      anchorEl={invitePopupAnchorEl}
      classes={{ paper: 'inviteJobPopupPaper' }}
      anchorOrigin={{
        vertical: 30,
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disablePortal
      onClose={closeEmployeeInvitePopup}
    >
      <div className="inviteJobPopupBody">
        {map(jobs, (job) => (
          <ButtonBase
            className="inviteJobPopupBtn"
            key={`vacancy__${user_id}-${job.id}`}
            onClick={() => handleInvite(job.id)}
          >
            <div
              style={{ backgroundImage: `url("${job.imageUrl || defaultJobImg}")` }}
              className="inviteJobImg"
            />
            <div className="inviteJobName">{job.title}</div>
          </ButtonBase>
        ))}
      </div>
    </Popover>
  );

  return (
    <Draggable key={id} draggableId={`${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          className="employeeCardContainer"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style)
          }}
          onClick={handleProfileView}
        >
          {isEditable && (
            <IconButton
              aria-label="remove employee"
              className="employeeCardDeleteBtn"
              onClick={handleDelete}
            >
              <MdClose fontSize="inherit" color="inherit" />
            </IconButton>
          )}
          <div>
            {imageUrl ? (
              <img className="cardProfileImage" src={imageUrl} alt="" />
            ) : (
              <span className="cardProfileImage">{name[0]}</span>
            )}
          </div>
          <div>
            <span className="cardEmployeeName">{name}</span>
            <div className="cardEmployeeGrowStats">
              {emp.careerDevelopment && emp.careerDevelopment.showEmployerData ? (
                <>
                  <span className="cardEmployeeCurrentPos">
                    {get(jobTypes, `[${emp.careerDevelopment.curJobTypeId}].name`, 'N/A')}
                  </span>
                  <span className="cardEmployeeFuturePos">
                    {' '}
                    | {get(jobTypes, `[${emp.careerDevelopment.futureJobTypeId}].name`, 'N/A')}
                  </span>
                </>
              ) : (
                <span className="cardEmployeeNA">N/A</span>
              )}
              {invited ? (
                <div className="invitedMessage">
                  <MdCheck color="inherit" fontSize="inherit" /> Invited
                </div>
              ) : (
                <div>
                  <Button
                    variant="text"
                    endIcon={<MdExpandMore fontSize="inherit" />}
                    sx={{ fontSize: 14 }}
                    onClick={openInvitePopup}
                  >
                    Invite to apply
                  </Button>
                  {renderInvitePopup()}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

EmployeeCard.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  employeeProfile: PropTypes.objectOf(PropTypes.any)
};

EmployeeCard.defaultProps = {
  id: 0,
  index: 0,
  employeeProfile: {
    employeeProfileProfileId: null,
    employeeProfile: {
      name: '',
      imageUrl: '',
      user_id: null
    }
  }
};

export default EmployeeCard;
