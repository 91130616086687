import React, { Fragment, useState, useEffect, useCallback, useRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import isSameDay from 'date-fns/isSameDay';
import map from 'lodash/map';
import trim from 'lodash/trim';
import truncate from 'lodash/truncate';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import clsx from 'clsx';
import parseHTML from 'html-react-parser';
import { qaAttr } from 'utils';
import withApplicantActions from 'hocs/withApplicantActions';
import { useRestrictions } from 'hooks';
import { Box, Divider, Dialog, InputAdornment, Popover, styled, useMediaQuery } from 'components';
import { Button, ButtonBase, IconButton, Input, Spinner } from 'components/shared';
import {
  MdArrowBack,
  MdAttachFile,
  MdChevronRight,
  MdClose,
  MdErrorOutline,
  MdMoreVert
} from 'components/icons';
import defaultImg from 'assets/img/job_default.png';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import defaultEmployerImg from 'assets/img/employer_default.png';
import {
  client,
  APPROVE_JOB,
  APPROVE_EMPLOYEE,
  GET_APPROVED_JOBS_MESSAGES,
  GET_APPROVED_APPLICANTS_MESSAGES,
  GET_CHAT,
  GET_JOB,
  GET_USER_PROFILE,
  POST_EMPLOYEE_CHAT,
  SET_EMPLOYER_CHAT
} from 'api';
import styles from 'styles/dialogs/MatchesDialog';
import ConfirmationDialog from './ConfirmationDialog';

const StyledRoot = styled(Dialog)(styles);

const REJECT_MESSAGE =
  'Thanks for submitting your application to our open position. Unfortunately, we don’t think it’s a perfect match right now. Please keep an eye out for more roles from us that may be a better fit!';

function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) => `<a target="_blank" noopener noreferrer href="${url}">${url}</a>`
  );
}

function ChatDialog({
  candidateId,
  dialogProps = {},
  dashBoardType,
  isOpen,
  jobId,
  onClose,
  onApplicantReject,
  rejectApplicant,
  suggestMessageTemplate,
  variant,
  withHeaderActions
}) {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { restrictions = {} } = useRestrictions();
  const isEmployeeActionsAllowed = restrictions.allowEmployeeActions;
  const currentUserId = parseInt(localStorage.getItem('userId'));
  const [page, setPage] = useState(variant === 'matches' ? 'matches' : 'chat'); /* matches, chat */
  const [chat, setChat] = useState({
    jobId: '',
    receiverId: '',
    receiverName: '',
    receiverPhoto: '',
    dialog: []
  });
  const [curMessage, setCurMessage] = useState('');
  const [isTipOpen, setIsTipOpen] = useState(true);
  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const [rejectDialog, setRejectDialog] = useState({ open: false, jobId: null, userId: null });
  const [rejectDialogMessage, setRejectDialogMessage] = useState(REJECT_MESSAGE);
  const [suggestMessageModal, setSuggestMessageModal] = useState(false);
  const [chatOptions, setChatOptions] = useState(null);
  const chatRef = useRef();

  const [getChat, { data: chatData = {}, loading: chatLoading = true }] = useLazyQuery(GET_CHAT, {
    variables: {
      role: dashBoardType
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  const [getApprovedApplicants, { data: applicantsData = {}, loading: applicantsLoading = true }] =
    useLazyQuery(GET_APPROVED_APPLICANTS_MESSAGES, {
      variables: {
        usersUserId: parseInt(currentUserId)
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
  const employerMatches = useMemo(() => {
    const appliedApplicants = applicantsData.getApprovedEmployerJobMessages || [];
    const fullChats = appliedApplicants.filter((o) => o.message);
    const sortedByDate = sortBy(fullChats, (o) => new Date(o.updatedAt).valueOf());
    const sortedByNew = sortBy(sortedByDate, (o) => o.messageTo > 0).reverse();
    return sortedByNew;
  }, [JSON.stringify(applicantsData)]);

  const [getApprovedJobs, { data: approvedJobsData = {}, loading: approvedJobsLoading = true }] =
    useLazyQuery(GET_APPROVED_JOBS_MESSAGES, {
      variables: {
        usersUserId: parseInt(currentUserId)
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
  const employeeMatches = useMemo(() => {
    const approvedJobs = approvedJobsData?.getApprovedJobMessages || [];
    const fullChats = approvedJobs.filter((o) => o.message);
    const sortedByDate = sortBy(fullChats, (o) => new Date(o.updatedAt).valueOf());
    const sortedByNew = sortBy(sortedByDate, (o) => o.messageTo > 0).reverse();
    return sortedByNew;
  }, [JSON.stringify(approvedJobsData)]);

  const [getJob, { data: jobData = {}, loading: jobLoading = true, updateQuery }] = useLazyQuery(
    GET_JOB,
    {
      variables: {
        jobId: parseInt(jobId)
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );
  const { webUrl } = jobData?.job || {};

  const [getApplicant, { data: applicantData = {}, loading: applicantLoading = true }] =
    useLazyQuery(GET_USER_PROFILE, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });

  const [postEmployeeChat] = useMutation(POST_EMPLOYEE_CHAT);

  const [setEmployerChat] = useMutation(SET_EMPLOYER_CHAT);

  const [approveEmployee, { loading: approveEmployeeLoading = false }] =
    useMutation(APPROVE_EMPLOYEE);
  const [approveJob, { loading: approveJobLoading = false }] = useMutation(APPROVE_JOB);

  const handleRejectApplicant = useCallback(() => {
    rejectApplicant({
      applicantData: { user_id: candidateId },
      jobId,
      jobWebUrl: webUrl,
      onSuccess: () => {
        setChatOptions(null);
        if (variant === 'chat-only') {
          onClose();
          onApplicantReject();
        } else {
          setPage('matches');
          setChat({ jobId: '', receiverId: '', receiverPhoto: '', receiverName: '', dialog: [] });
          getApprovedApplicants();
          onApplicantReject();
        }
      }
      // onCancel: () => {}
    });
  }, [variant, candidateId, jobId, webUrl, rejectApplicant, onApplicantReject, onClose]);

  const openJobDialog = useCallback(() => setIsJobDialogOpen(true), []);
  const closeJobDialog = useCallback(() => setIsJobDialogOpen(false), []);

  const openRejectDialog = useCallback((userId, jobId) => {
    setRejectDialog({ open: true, jobId, userId });
  }, []);
  const closeRejectDialog = useCallback(() => {
    setRejectDialog({ open: false, jobId: null, userId: null });
  }, []);
  const handleRejectMessageSet = useCallback((e) => {
    setRejectDialogMessage(e.target.value);
  }, []);
  const handleRejectSend = useCallback(async () => {
    const { jobId, userId } = rejectDialog;
    if (userId && jobId && rejectDialogMessage.trim().length > 0) {
      await approveEmployee({
        variables: {
          jobId,
          userId,
          approved: false,
          passed: true,
          starred: false,
          message: rejectDialogMessage
        }
      });
      setChatOptions(null);
      if (variant === 'chat-only') {
        onClose();
        onApplicantReject();
      } else {
        setPage('matches');
        setChat({ jobId: '', receiverId: '', receiverPhoto: '', receiverName: '', dialog: [] });
        if (dashBoardType === 'employee') getApprovedJobs();
        if (dashBoardType === 'employer') {
          getApprovedApplicants();
          onApplicantReject();
        }
      }
    }
  }, [variant, dashBoardType, rejectDialog.jobId, rejectDialog.userId, rejectDialogMessage]);

  async function handleRetract(jobId) {
    if (jobId) {
      await approveJob({
        variables: {
          jobId: jobId,
          approved: false,
          passed: true,
          starred: false
        }
      });
      setChatOptions(null);
      if (variant === 'chat-only') onClose();
      else {
        setPage('matches');
        setChat({ jobId: '', receiverId: '', receiverPhoto: '', receiverName: '', dialog: [] });
        if (dashBoardType === 'employee') getApprovedJobs();
        if (dashBoardType === 'employer') getApprovedApplicants();
      }
    }
  }

  function openChat({ jobId, receiverId, receiverPhoto = '', receiverName = '' }) {
    return async () => {
      if (jobId) {
        setPage('chat');
        setChat({ jobId, receiverId, receiverPhoto, receiverName, dialog: [] });
        getJob({ variables: { jobId: parseInt(jobId) } });

        // Employee chat will be fetched after job data fetch. Check related useEffect
        if (dashBoardType === 'employer' && receiverId) {
          getApplicant({ variables: { userId: parseInt(receiverId) } });
          getChat({
            variables: {
              jobsId: parseInt(jobId),
              messageTo: currentUserId,
              messageFrom: receiverId
            }
          });
        }
      }
    };
  }

  function openMatchesPage() {
    setPage('matches');
    setChat({ jobId: '', receiverId: '', receiverPhoto: '', receiverName: '', dialog: [] });
  }

  function closeTip() {
    setIsTipOpen(false);
  }

  function toggleMatchOptions(open) {
    return (e) => {
      if (open) setChatOptions(e.currentTarget);
      else setChatOptions(null);
    };
  }

  function handleMessageType(e) {
    setCurMessage(e.target.value);
  }

  function getParsedMessage(m) {
    try {
      return JSON.parse(m);
    } catch (err) {
      return m;
    }
  }

  function getBeautifiedMessage(m) {
    const parsed = getParsedMessage(m);
    return parsed?.comment || parsed;
  }

  function handleMessageSend(e) {
    e.preventDefault();
    if (trim(curMessage).length > 0) {
      const { jobId, receiverId } = chat;

      if (currentUserId && jobId) {
        if (dashBoardType === 'employee') {
          postEmployeeChat({
            variables: {
              messageTo: parseInt(receiverId),
              messageFrom: parseInt(currentUserId),
              jobsId: parseInt(jobId),
              usersUserId: parseInt(currentUserId),
              message: curMessage
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          });
        } else {
          setEmployerChat({
            variables: {
              messageTo: parseInt(currentUserId),
              messageFrom: parseInt(receiverId),
              jobsId: parseInt(jobId),
              usersUserId: parseInt(currentUserId),
              message: curMessage
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          });
        }

        setChat({
          ...chat,
          dialog: [
            ...chat.dialog,
            {
              messageTo: dashBoardType === 'employer' ? currentUserId : receiverId,
              messageFrom: dashBoardType === 'employer' ? receiverId : currentUserId,
              message: curMessage,
              createdAt: formatISO(new Date()),
              usersUserId: currentUserId
            }
          ]
        });
        setCurMessage('');
      }
    }
  }

  useEffect(() => {
    setPage(variant === 'matches' ? 'matches' : 'chat');
  }, [variant]);

  useEffect(() => {
    async function fetchChat() {
      if ((variant === 'chat' || variant === 'chat-only') && jobId) {
        const job = await client.query({
          query: GET_JOB,
          variables: {
            jobId: parseInt(jobId)
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        if (job?.data?.job) {
          if (dashBoardType === 'employer' && candidateId) {
            const candidate = await client.query({
              query: GET_USER_PROFILE,
              variables: {
                userId: parseInt(candidateId)
              },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            });
            if (candidate?.data?.employeeProfile) {
              const receiverPhoto = candidate.data.employeeProfile.imageUrl || defaultEmployeeImg;
              const receiverName = candidate.data.employeeProfile.name;
              const receiverId = candidateId;
              setChat({ jobId, receiverId, receiverPhoto, receiverName, dialog: [] });
              getChat({
                variables: {
                  jobsId: parseInt(jobId),
                  messageTo: currentUserId,
                  messageFrom: receiverId
                }
              });
            }
          } else {
            const receiverPhoto = job.data.job.employerProfile.imageUrl || defaultEmployerImg;
            const receiverName = job.data.job.employerProfile.name;
            const receiverId = job.data.job.employerProfileProfileId;
            setChat({ jobId, receiverId, receiverPhoto, receiverName, dialog: [] });
            getChat({
              variables: {
                jobsId: parseInt(jobId),
                messageTo: receiverId,
                messageFrom: currentUserId
              }
            });
          }

          getJob();
        }
      }
    }
    if (jobId) fetchChat();
  }, [candidateId, jobId]);

  useEffect(() => {
    if (jobData?.job && dashBoardType === 'employee') {
      const receiverId = jobData.job?.employerProfileProfileId;
      const jobId = jobData?.job?.id;
      setChat({
        ...chat,
        jobId,
        receiverId,
        receiverPhoto: jobData.job?.employerProfile?.imageUrl,
        receiverName: jobData.job?.employerProfile?.name
      });
      getChat({
        variables: {
          jobsId: parseInt(jobId),
          messageTo: receiverId,
          messageFrom: currentUserId
        }
      });
    }
  }, [JSON.stringify(jobData)]);

  useEffect(() => {
    if (applicantData?.employeeProfile && dashBoardType === 'employer') {
      setChat({
        ...chat,
        receiverPhoto: applicantData.employeeProfile?.imageUrl,
        receiverName: applicantData.employeeProfile?.name
      });
    }
  }, [JSON.stringify(applicantData)]);

  useEffect(() => {
    if (chatData?.chat) {
      if (!chatData.chat.length && suggestMessageTemplate) {
        setSuggestMessageModal(true);
      }

      setChat({
        ...chat,
        dialog: map(chatData.chat, ({ __typename, ...rest }) => ({ ...rest }))
      });
    }
  }, [JSON.stringify(chatData)]);

  useEffect(() => {
    if (chat.dialog.length && isOpen && page === 'chat' && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [JSON.stringify(chat.dialog)]);

  useEffect(() => {
    if (isOpen && variant === 'matches' && page === 'matches') {
      openMatchesPage();
      if (dashBoardType === 'employee') getApprovedJobs();
      if (dashBoardType === 'employer') getApprovedApplicants();
    }
    if (variant === 'chat') {
      if (dashBoardType === 'employee') getApprovedJobs();
      if (dashBoardType === 'employer') getApprovedApplicants();
    }
  }, [isOpen, page]);

  useEffect(() => {
    if (!isOpen) {
      setPage('matches');
      setChat({
        jobId: '',
        receiverId: '',
        receiverName: '',
        receiverPhoto: '',
        dialog: []
      });
    } else {
      setPage(variant === 'matches' ? 'matches' : 'chat');
    }
  }, [isOpen]);

  const emptyMatchesMessage = () => (
    <div className="emptyMatchesMessage">{`No matches yet. ${
      dashBoardType === 'employee' ? 'Apply for jobs first to get some matches' : ''
    }`}</div>
  );

  function renderMatchesList() {
    const matches = dashBoardType === 'employee' ? employeeMatches : employerMatches;
    const matchesAreEmpty = !matches || !matches.length;
    const matchesLoading = applicantsLoading || approvedJobsLoading;
    return (
      <div
        className="dialogContent matchesPage"
        style={{ ...(matchesAreEmpty && { justifyContent: 'center' }) }}
      >
        {matchesLoading && (
          <Box
            width="100%"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            zIndex={1000}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner />
          </Box>
        )}
        {(() => {
          if (!matchesAreEmpty) {
            return map(matches, (o, i) => {
              // On employee receiverName & receiverPhoto will be taken from fetched job data
              // On employer receiverName & receiverPhoto will be taken from fetched applicant data
              const receiverId = dashBoardType === 'employee' ? null : o.messageFrom;
              const receiverPhoto =
                dashBoardType === 'employee' ? defaultEmployerImg : defaultEmployeeImg; // @note: dashBoardType === 'employee' ? companyImg : applicantImg
              const receiverName = ''; // @note: dashBoardType === 'employee' ? companyName : applicantName
              const jobId = o.jobs?.id;
              const msgCount = o.messageTo;

              return o.message ? (
                <Fragment key={`match__${i}`}>
                  <ButtonBase
                    className="matchItemBtn"
                    onClick={openChat({ jobId, receiverId, receiverPhoto, receiverName })}
                    {...qaAttr(`chat-match-button-${o?.jobs?.title}`)}
                  >
                    <div
                      style={{
                        backgroundImage: `url("${
                          o?.users?.employeeProfile?.imageUrl || defaultImg
                        }")`
                      }}
                      className="userPhoto"
                    />
                    <Box
                      ml="16px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      textAlign="left"
                    >
                      <Box mb="6px" className="secondaryText" style={{ wordBreak: 'break-word' }}>
                        {o?.jobs?.title || ''}
                      </Box>
                      <Box mb="6px" className="secondaryText" style={{ wordBreak: 'break-word' }}>
                        {o?.users?.employeeProfile?.name || ''}
                      </Box>
                      <div className="mainText" style={{ wordBreak: 'break-word' }}>
                        {truncate(getBeautifiedMessage(o.message), { length: 38 })}
                      </div>
                    </Box>
                    <Box ml="auto" mr={0} display="flex" alignItems="center">
                      {msgCount > 0 && (
                        <div className="counter">
                          <span>{msgCount}</span>
                        </div>
                      )}
                      <MdChevronRight style={{ marginLeft: 22 }} color="primary" />
                    </Box>
                  </ButtonBase>
                  <Divider />
                </Fragment>
              ) : null;
            });
          }
          if (!matchesLoading) {
            return emptyMatchesMessage();
          }
          return null;
        })()}
        {isTipOpen && dashBoardType === 'employee' && !matchesAreEmpty && !matchesLoading && (
          <div className="tipMessage">
            <MdErrorOutline color="inherit" />
            <p className="tipMessage__text">
              A response within 48 hours will increase your chances for hire
            </p>
            <IconButton
              edge="end"
              color="primary"
              sx={{ ml: '20px', mr: '-10px', p: '10px' }}
              onClick={closeTip}
            >
              <MdClose color="inherit" />
            </IconButton>
          </div>
        )}
      </div>
    );
  }

  const renderMessageBox = (messageData) => {
    const { usersUserId, message } = messageData;
    const htmlText = urlify(message);
    const parserHtml = parseHTML(htmlText);

    return usersUserId === currentUserId ? (
      <Box mb="16px" display="flex" justifyContent="flex-end">
        <div className="chatMessage my_message">{message}</div>
      </Box>
    ) : (
      <Box mb="16px" display="flex" justifyContent="flex-start">
        <Box display="flex" alignItems="flex-start">
          <div
            style={{ backgroundImage: `url("${chat.receiverPhoto}")` }}
            className="chatAddresseePhoto"
          />
          <Box ml="16px" className="chatMessage">
            {parserHtml}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderChat = () => (
    <div>
      {chat.dialog.length > 0
        ? map(chat.dialog, (o, i) => {
            const messageDate = new Date(o.createdAt);
            const today = new Date();
            const parsedDate = isSameDay(messageDate, today)
              ? format(messageDate, "'Today' hh:mm a")
              : format(messageDate, 'MM.dd.yyyy hh:mm a');
            return (
              <Fragment key={`message__${i}`}>
                {parsedDate && (
                  <Box mb="16px" className="chatDate">
                    {parsedDate}
                  </Box>
                )}
                {renderMessageBox(o)}
              </Fragment>
            );
          })
        : null}
    </div>
  );

  const renderChatPage = () => {
    const { id, imageUrl: jobPhoto = '', title: jobTitle = '' } = jobData?.job || {};
    return (
      <>
        {chatLoading && (
          <Box
            width="100%"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            zIndex={1000}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner />
          </Box>
        )}
        <Box pb="24px" pt="8px" px="17px" flex="0 0 auto">
          <Button
            className="jobLink"
            width="100%"
            height={64}
            onClick={openJobDialog}
            testID="chat-job-button"
          >
            <div
              style={{ backgroundImage: `url("${jobPhoto || defaultImg}")` }}
              className="jobPhoto"
            />
            <Box
              ml="16px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="left"
            >
              <Box mb="6px" className="mainText">
                Position:
              </Box>
              <div className="secondaryText">{jobTitle}</div>
            </Box>
            <Box ml="auto" mr={0} display="flex" alignItems="center">
              <Box component={MdChevronRight} ml="8px" color="primary.main" />
            </Box>
          </Button>
        </Box>
        <div ref={chatRef} className="dialogContent chatPage">
          {renderChat()}
        </div>
        <form className="dialogFooter" onSubmit={handleMessageSend}>
          <Input
            value={curMessage}
            variant="outlined"
            placeholder="Type your message"
            endAdornment={
              <InputAdornment position="end">
                <ButtonBase
                  type="submit"
                  className="chatSendBtn"
                  {...qaAttr('send-chat-message-button')}
                >
                  Send
                </ButtonBase>
              </InputAdornment>
            }
            InputProps={{
              sx: { paddingRight: '16px' }
            }}
            htmlInputClassName="chatInput"
            onChange={handleMessageType}
            testID="chat-input"
          />
          <IconButton
            disabled
            variant="outlined"
            color="primary"
            aria-label="attach file"
            sx={{ marginLeft: '16px' }}
          >
            <MdAttachFile />
          </IconButton>
        </form>
      </>
    );
  };

  const renderContent = () => {
    if (page === 'matches') return renderMatchesList();
    if (page === 'chat') return renderChatPage();
    return null;
  };

  const renderTitle = () => {
    if (page === 'matches') {
      return (
        <div className="dialogHeader matchesPage">
          <IconButton
            color="primary"
            aria-label="close"
            edge="start"
            className="dialogCloseBtn"
            onClick={onClose}
            testID="chat-matches-close-button"
          >
            <MdClose />
          </IconButton>
          <h2 className="dialogTitle" {...qaAttr('chat-matches-title')}>
            Inbox
          </h2>
        </div>
      );
    }
    if (page === 'chat') {
      return (
        <div className="dialogHeader chatPage">
          <div>
            <IconButton
              variant={isDesktop ? 'outlined' : ''}
              edge={isDesktop ? false : 'start'}
              color="primary"
              aria-label="close chat"
              onClick={variant === 'chat-only' ? onClose : openMatchesPage}
              testID="chat-close-button"
            >
              <MdArrowBack />
            </IconButton>
          </div>
          <h2
            className={clsx('dialogTitle', !withHeaderActions && 'emptyRightElement')}
            {...qaAttr('chat-title')}
          >
            {jobLoading || applicantLoading ? <Spinner size={24} /> : chat.receiverName}
          </h2>
          {withHeaderActions && (
            <Box position="relative">
              <IconButton
                variant={isDesktop ? 'outlined' : ''}
                edge={isDesktop ? false : 'end'}
                color="primary"
                aria-label="match options"
                onClick={toggleMatchOptions(true)}
                testID="chat-options-button"
              >
                <MdMoreVert />
              </IconButton>
              <Popover
                open={!!chatOptions}
                anchorEl={chatOptions}
                classes={{ paper: 'matchOptionsPaper' }}
                anchorOrigin={{
                  vertical: 50,
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                disablePortal
                onClose={toggleMatchOptions(false)}
              >
                <ButtonBase
                  className="matchOptionsBtn"
                  disabled={
                    dashBoardType === 'employer'
                      ? !isEmployeeActionsAllowed ||
                        !chat.receiverId ||
                        !jobData?.job?.id ||
                        approveEmployeeLoading
                      : !jobData?.job?.id || approveJobLoading
                  }
                  onClick={
                    dashBoardType === 'employer'
                      ? () => handleRejectApplicant()
                      : () => handleRetract(jobData?.job?.id)
                  }
                  {...qaAttr(`${dashBoardType === 'employer' ? 'reject' : 'retract'}-button`)}
                >
                  {dashBoardType === 'employer' ? (
                    <>
                      <span>Reject Candidate</span>
                      {approveEmployeeLoading && (
                        <Spinner style={{ marginLeft: 4 }} width={22} height={22} />
                      )}
                    </>
                  ) : (
                    <>
                      <span>Retract Application</span>
                      {approveJobLoading && (
                        <Spinner style={{ marginLeft: 4 }} width={22} height={22} />
                      )}
                    </>
                  )}
                </ButtonBase>
                <ConfirmationDialog
                  isOpen={rejectDialog.open}
                  title="Reject Message"
                  message={
                    <Input
                      value={rejectDialogMessage}
                      multiline
                      autoFocus
                      inputProps={{
                        ...qaAttr('confirmation-input')
                      }}
                      classes={{ root: 'messageInputRoot', input: 'messageInput' }}
                      onChange={handleRejectMessageSet}
                    />
                  }
                  onClose={closeRejectDialog}
                  onConfirm={handleRejectSend}
                  onCancel={closeRejectDialog}
                />
              </Popover>
            </Box>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <StyledRoot
      open={isOpen}
      fullWidth
      classes={{ paper: 'paper', container: 'container' }}
      BackdropProps={{
        classes: { root: 'backdrop' }
      }}
      scroll="paper"
      onClose={onClose}
      {...dialogProps}
    >
      {renderTitle()}
      {renderContent()}
      <SuggestMessageModal
        message={suggestMessageTemplate}
        isOpen={suggestMessageModal}
        setMessage={setCurMessage}
        onClose={() => setSuggestMessageModal(false)}
      />
    </StyledRoot>
  );
}

export function SuggestMessageModal({ isOpen, onClose, message, setMessage }) {
  const confirm = () => {
    setMessage(message);
    onClose();
  };

  return (
    <StyledRoot open={isOpen} maxWidth="xs" onClose={onClose}>
      <Box px="16px" py="12px">
        <h2 className="templateMessageModal__title">Use this template message?</h2>
        <p className="templateMessageModal__message">{message}</p>
        <Box textAlign="right">
          <Button
            variant="filled-secondary"
            className="templateMessageModal__cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="filled-primary"
            className="templateMessageModal__confirm"
            onClick={confirm}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </StyledRoot>
  );
}

ChatDialog.propTypes = {
  candidateId: PropTypes.number,
  dashBoardType: PropTypes.oneOf(['employer', 'employee']).isRequired,
  isOpen: PropTypes.bool.isRequired,
  jobId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onApplicantReject: PropTypes.func,
  rejectApplicant: PropTypes.func.isRequired,
  suggestMessageTemplate: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['matches', 'chat', 'chat-only']),
  withHeaderActions: PropTypes.bool
};

ChatDialog.defaultProps = {
  candidateId: null,
  jobId: null,
  variant: 'matches',
  onApplicantReject: () => {},
  suggestMessageTemplate: '',
  withHeaderActions: true
};

export default memo(withApplicantActions(ChatDialog));
