export function getUserId() {
  return localStorage.getItem('userId');
}

export function getProfileId() {
  return localStorage.getItem('profileId');
}

export function getAuthToken() {
  return localStorage.getItem('token');
}
