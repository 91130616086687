import React, { useState } from 'react';
import Input from '../Input';

function InputAlertInput({
  titleValue,
  label = '',
  handleOnBlur,
  onClick = null,
  disabled = false,
  ...rest
}) {
  const [inputValue, setInputValue] = useState(titleValue);
  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Input
      disabled={disabled}
      variant="textfield"
      label={label}
      value={inputValue}
      onChange={handleOnChange}
      onBlur={(e) => handleOnBlur(inputValue, e)}
      onClick={onClick}
      {...rest}
    />
  );
}
export default InputAlertInput;
