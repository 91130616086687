export default ({ theme }) => ({
  '&.container': {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  '& .toolbarContainer': {
    width: '100%',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .scrollable': {
    overflow: 'auto'
  },
  '&.boardContainer': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  '& .boardTitle': {
    marginBottom: 8,
    paddingLeft: 25,
    paddingRight: 30,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.moreColors.black_1,
    '&.boardTitle_withIcon': {
      display: 'flex'
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      paddingLeft: 16,
      paddingRight: 16,
      // display: 'none',
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .boardLoader': {
    width: '100%',
    height: 72,
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .searchContainer': {
    marginBottom: 28,
    paddingLeft: 25,
    paddingRight: 30,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 382,
      width: '100%',
      padding: '0 16px',
      margin: '0 auto 12px'
    }
  },
  '& .boardListContainer': {
    paddingLeft: 25,
    paddingRight: 30,
    paddingBottom: 30,
    overflow: 'auto',
    flex: 1,
    '&.boardListContainer_grid': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: 30
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 16px 20px'
    }
  },
  '& .emptyApplicants__title': {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '26px',
    letterSpacing: 1
  }
});
