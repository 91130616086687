import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { client } from 'api/graphql';
import get from 'lodash/get';
import { emojiRates, qaAttr } from 'utils';
import { useAlerts } from 'hooks';
import InputAlertInput from 'components/shared/InputAlert/InputAlertInput';
import { MdAddLink } from 'components/icons';
import { Button, Spinner } from 'components/shared';
import {
  ASSOCIATE_EMPLOYEE_TO_EMPLOYER,
  EMPLOYEE_PROFILE_TO_EMPLOYER_CODE,
  EMPLOYEE_RATING_BY_EXP_ID,
  RATING_REASONS
} from 'api';
import RateEmployer from './components/RateEmployer';

function LinkOrRateCompany({ exp, renderLinkButton, renderRateButton }) {
  const { setInputAlert } = useAlerts();
  const ratingRef = useRef();
  const [employeeRatingLoading, setEmployeeRatingLoading] = useState(true);
  const [compCodeAssociation, setCompCodeAssociation] = useState(null);
  const [ratingReasons, setRatingReasons] = useState([]);

  const fetchEmployeeRating = async () => {
    const employeeAssociationResp = await client.query({
      query: EMPLOYEE_PROFILE_TO_EMPLOYER_CODE,
      variables: {
        experienceId: exp.experience_id
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    const employeeAssociation = get(
      employeeAssociationResp,
      'data.codeAssociationByExperienceId',
      null
    );
    setCompCodeAssociation(employeeAssociation);

    const ratingReasonsResp = await client.query({
      query: RATING_REASONS,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    setRatingReasons(get(ratingReasonsResp, 'data.ratingReasons'));

    if (employeeAssociation) {
      const empRating = await client.query({
        query: EMPLOYEE_RATING_BY_EXP_ID,
        variables: {
          experienceId: exp.experience_id
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      ratingRef.current = get(empRating, 'data.employerRatingByExperienceId.score');
    } else {
      ratingRef.current = undefined;
    }
    setEmployeeRatingLoading(false);
  };

  useEffect(() => {
    if (exp.experience_id) fetchEmployeeRating();
  }, [exp?.experience_id]);

  const [associate, { loading: associateEmpLoading }] = useMutation(ASSOCIATE_EMPLOYEE_TO_EMPLOYER);
  let companyLinkValue = '';

  const onCompanyCodeChange = (value) => {
    companyLinkValue = value;
  };

  const associateCompanyLinkCode = async () => {
    const res = await associate({
      variables: { code: companyLinkValue, experienceId: exp.experience_id }
    });
    const codeAssociation = get(res, 'data.createEmployeeProfileToEmployerCode');
    if (!codeAssociation) {
      setInputAlert({
        title: 'There was an issue',
        subtitle: 'Looks like that code is not accurate',
        isOpen: true
      });
      return;
    }
    setCompCodeAssociation(codeAssociation);
  };

  const retrieveEmployerCode = () => {
    setInputAlert({
      isOpen: true,
      title: 'Enter Company Code',
      subtitle: 'Enter the Code that your company provided you with to rate your company',
      inputs: (
        <InputAlertInput
          label="Company Code"
          handleOnBlur={onCompanyCodeChange}
          inputProps={{ ...qaAttr('company-code-input') }}
        />
      ),
      onSuccess: associateCompanyLinkCode
    });
  };

  if (employeeRatingLoading || associateEmpLoading) {
    return <Spinner size={26} />;
  }

  if (ratingRef.current || compCodeAssociation) {
    const currentRating = (ratingRef.current && emojiRates[ratingRef.current - 1]) || '\u{1F937}';

    return (
      <RateEmployer
        icon={currentRating}
        exp={exp}
        ratingRef={ratingRef}
        ratingReasons={ratingReasons}
        renderRateButton={renderRateButton}
      />
    );
  }

  const linkButtonProps = {
    onClick: retrieveEmployerCode,
    ...qaAttr('company-link-button')
  };

  if (exp.currentJob) {
    if (renderLinkButton) return renderLinkButton(linkButtonProps);
    return (
      <MdAddLink sx={{ marginLeft: 10, fill: '#4743A2', cursor: 'pointer' }} {...linkButtonProps} />
    );
  }

  return null;
}

export default LinkOrRateCompany;
