import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  useMemo,
  memo,
  Fragment
} from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { map, forEach } from 'lodash';
import {
  addWeeks,
  startOfDay,
  isBefore,
  isAfter,
  isSameMinute,
  areIntervalsOverlapping
} from 'date-fns';
import { qaAttr } from 'utils';
import { DEFAULT_SCHEDULE_WEEK } from 'utils/schedules';
import { Box, MuiButtonBase, styled } from 'components';
import { Button, DatePicker, IconButton, Input, Select } from 'components/shared';
import { MdAdd, MdExpandLess, MdExpandMore, MdChevronRight } from 'components/icons';
import { useSchedulesForm } from 'containers/employer/EmployerScheduleFormProvider';
import EmployerScheduleContext from 'containers/employer/EmployerScheduleContext';
import styles from 'styles/Dashboard/EmployerShedule';

const StyledRoot = styled('div')(styles);

const TIMES = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const defaultCriteria = {
  industry: null,
  skills: null,
  careerHealthScore: null,
  experience: null
};

const createEmptyWeek = () => Array(7).fill([]);
const emptyWeek = createEmptyWeek();

function Schedule() {
  const { $, attrs, set } = useSchedulesForm();
  const { updateScheduleCtx, selectedScheduleIdx } = useContext(EmployerScheduleContext);
  const [scrollUpDisabled, setScrollUpDisabled] = useState(true);
  const [scrollDownDisabled, setScrollDownDisabled] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const graphRef = useRef();
  const graphColumnsRef = useRef();
  const graphMobileHeaderRef = useRef();

  const { schedule = { ...DEFAULT_SCHEDULE_WEEK } } = attrs.schedules?.[selectedScheduleIdx] || {};

  const otherSchedules = useMemo(
    () => attrs.schedules.filter((_, i) => i !== selectedScheduleIdx),
    [JSON.stringify(attrs.schedules), selectedScheduleIdx]
  );

  useEffect(() => {
    setEnableScroll();
  }, [graphRef, scrollTop]);

  const calcScheduleEndDate = (startDate, length) => addWeeks(startDate, length);

  const reCalcNextSchedule = (prevSchedule = {}, nextSchedule = {}) => {
    const startDate = prevSchedule.endDate || startOfDay(new Date());
    const endDate = calcScheduleEndDate(startDate, nextSchedule.length || 1);

    return {
      ...defaultCriteria,
      schedule: { ...DEFAULT_SCHEDULE_WEEK },
      length: 1,
      ...nextSchedule,
      startDate,
      endDate
    };
  };

  const isDateWithinSchedule = (date, startDate, endDate) => {
    const start = startDate;
    const end = endDate;

    return (isSameMinute(date, start) || isAfter(date, start)) && isBefore(date, end);
  };

  const adjustOverlappedSchedules = (startScheduleIdx, startScheduleObj) => {
    const startSchedule = startScheduleObj || attrs.schedules[startScheduleIdx];
    const nextSchedule = attrs.schedules[startScheduleIdx + 1];
    let adjustedSchedules = [];

    if (nextSchedule) {
      const intervalLeft = {
        start: startSchedule.startDate,
        end: startSchedule.endDate
      };
      const intervalRight = {
        start: nextSchedule.startDate,
        end: nextSchedule.endDate
      };
      if (
        areIntervalsOverlapping(intervalLeft, intervalRight, { inclusive: true }) ||
        isBefore(intervalRight.end, intervalLeft.start)
      ) {
        const adjustedNextSchedule = reCalcNextSchedule(startSchedule, nextSchedule);
        adjustedSchedules = [...adjustedSchedules, adjustedNextSchedule];
        const arr = adjustOverlappedSchedules(startScheduleIdx + 1, adjustedNextSchedule);
        adjustedSchedules = [...adjustedSchedules, ...arr];
      } else {
        adjustedSchedules = [...adjustedSchedules, nextSchedule];
        const arr = adjustOverlappedSchedules(startScheduleIdx + 1, nextSchedule);
        adjustedSchedules = [...adjustedSchedules, ...arr];
      }
    }
    return adjustedSchedules;
  };

  const addSchedule = () => {
    const { schedules } = attrs;
    const nextSchedules = [...schedules];
    const prevSchedule = nextSchedules[nextSchedules.length - 1];
    const newSchedule = reCalcNextSchedule(prevSchedule);

    nextSchedules.push(newSchedule);
    set('schedules', nextSchedules);
  };

  const selectSchedule = (index) => {
    updateScheduleCtx({ selectedScheduleIdx: index });
  };

  const handleDateChange = (field) => (date) => {
    let nextSchedules = [...attrs.schedules];
    const scheduleToEdit = attrs.schedules[selectedScheduleIdx];
    const newStartDate = date;
    const newEndDate = calcScheduleEndDate(newStartDate, scheduleToEdit.length);
    const newSchedule = {
      ...scheduleToEdit,
      startDate: newStartDate,
      endDate: newEndDate
    };
    const adjustedSchedules = adjustOverlappedSchedules(selectedScheduleIdx, newSchedule);
    nextSchedules = nextSchedules
      .slice(0, selectedScheduleIdx)
      .concat([newSchedule, ...adjustedSchedules]);

    set('schedules', nextSchedules);
  };

  const handleLengthChange = (e, { name }) => {
    let nextSchedules = [...attrs.schedules];
    const { startDate } = attrs.schedules[selectedScheduleIdx];
    const numLength = Number(e.target.value);
    const newEndDate = calcScheduleEndDate(startDate, numLength);
    const newSchedule = {
      ...attrs.schedules[selectedScheduleIdx],
      length: numLength,
      endDate: newEndDate
    };

    const adjustedSchedules = adjustOverlappedSchedules(selectedScheduleIdx, newSchedule);
    nextSchedules = nextSchedules
      .slice(0, selectedScheduleIdx)
      .concat([newSchedule, ...adjustedSchedules]);

    set('schedules', nextSchedules);
  };

  const setEnableScroll = () => {
    if (graphRef.current) {
      setScrollUpDisabled(graphRef.current.scrollTop === 0);
      setScrollDownDisabled(graphRef.current.scrollTop === TIMES.length * 2 * 35);
    }
  };

  const handlePillClick = (dayIdx, slotKey) => {
    const slots = schedule[dayIdx];
    if (slots.includes(slotKey)) {
      set(
        `schedules.${selectedScheduleIdx}.schedule.${dayIdx}`,
        slots.filter((item) => item !== slotKey)
      );
    } else {
      set(`schedules.${selectedScheduleIdx}.schedule.${dayIdx}`, [...slots, slotKey]);
    }
  };

  const scrollUp = () => {
    if (!scrollUpDisabled) {
      const pos = graphRef.current.scrollTop - 35;
      graphRef.current.scrollTop = pos;
      setScrollTop(pos);
    }
  };
  const scrollDown = () => {
    if (!scrollDownDisabled) {
      const pos = graphRef.current.scrollTop + 35;
      graphRef.current.scrollTop = pos;
      setScrollTop(pos);
    }
  };

  const onScroll = (n) => {
    graphMobileHeaderRef.current.scrollLeft = n.currentTarget.scrollLeft;
  };

  const scrollHorizontally = () => {
    const pos = graphColumnsRef.current.scrollWidth - graphColumnsRef.current.clientWidth;
    graphColumnsRef.current.scrollLeft = pos;
    graphMobileHeaderRef.current.scrollLeft = pos;
  };

  const disabledDate = useCallback(
    (date) => {
      const prevSchedule = attrs.schedules[selectedScheduleIdx - 1];
      if (prevSchedule && isBefore(date, prevSchedule.endDate)) return true;
      let disabled = false;
      forEach(otherSchedules, ({ startDate, endDate }) => {
        if (isDateWithinSchedule(date, startDate, endDate)) {
          disabled = true;
          return false;
        }
      });

      return disabled;
    },
    [JSON.stringify(otherSchedules)]
  );

  const renderDatePicker = (name) => {
    const label = name === 'startDate' ? 'Start Date' : 'End Date';
    const formAttr = $(`schedules.${selectedScheduleIdx}.${name}`);
    const id = `${name}-input-${selectedScheduleIdx}`;
    const isEndDate = name === 'endDate';

    return (
      <DatePicker
        value={formAttr.value}
        error={formAttr.error}
        label={label}
        disabled={isEndDate}
        shouldDisableDate={disabledDate}
        disableOpenPicker={isEndDate}
        onChange={handleDateChange(formAttr.name)}
        InputComponentProps={{
          id,
          testID: id,
          variant: 'textfield',
          labelClassName: 'scheduleFormLabel',
          htmlInputClassName: 'scheduleFormInput',
          helperTextClassName: 'scheduleFormInputError',
          FormControlProps: {
            sx: { maxWidth: 130, marginLeft: '16px' }
          }
        }}
      />
    );
  };

  const formatSlotKey = (idx) => (idx < 10 ? '0' : '') + idx.toString();

  return (
    <StyledRoot className="schedulesContentContainer">
      <p component="p" mb="24px" className="scheduleTip">
        In order to create multiple schedules, click the plus icon on the right chooses a start date
        for the new schedule. On the day of the start date of a new schedule the previous schedule
        will no longer be active. Change the start date of the previous schedule in order to make it
        active again. There is a max of 4 schedules per account.
      </p>
      <div className="schedulesControllersContainer">
        <div className="scheduleBtnsContainer">
          {map(attrs.schedules, (_, i) => (
            <Button
              key={`scheduleBtn__${i}`}
              className={clsx('scheduleBtn', i === selectedScheduleIdx && 'selected')}
              onClick={() => selectSchedule(i)}
              testID={`schedule-button-${selectedScheduleIdx}`}
            >
              {`Schedule ${ALPHABET[i]}`}
            </Button>
          ))}
        </div>
        {attrs.schedules.length < 4 && (
          <IconButton
            aria-label="Add Schedule"
            className="scheduleAddBtn"
            onClick={addSchedule}
            testID="add-schedule-button"
          >
            <MdAdd color="primary" />
          </IconButton>
        )}
      </div>
      <Box mb="20px" display="flex">
        <Select
          {...$(`schedules.${selectedScheduleIdx}.length`, handleLengthChange)}
          native
          id="length-select"
          label="Length"
          required
          withHelperText
          containerProps={{
            sx: { maxWidth: 122, width: '100%' }
          }}
          InputComponentProps={{
            htmlInputClassName: 'scheduleFormInput'
          }}
          labelProps={{ className: 'scheduleFormLabel' }}
          inputVariant="textfield"
          options={[
            { value: 1, label: '1 Week', ...qaAttr('length-option-1') },
            { value: 2, label: '2 Week', ...qaAttr('length-option-2') },
            { value: 3, label: '3 Week', ...qaAttr('length-option-3') },
            { value: 4, label: '4 Week', ...qaAttr('length-option-4') }
          ]}
          testID={`length-input-${selectedScheduleIdx}`}
        />
        {renderDatePicker('startDate')}
        {renderDatePicker('endDate')}
      </Box>

      <div className="graphMobileContainer" ref={graphMobileHeaderRef}>
        {map(WEEK_DAYS, (day, dayIdx) => (
          <div key={`mobile__${day}`} className="graphMobileContainer__col">
            <div>{day}</div>
            <div>30 min</div>
          </div>
        ))}
      </div>
      <div className="graphContainer" ref={graphRef}>
        <div className="graphTimes">
          <div className="graphTimesTop">
            <div className="timesSpacer" />
            <MuiButtonBase
              disabled={scrollUpDisabled}
              className="scrollUp scrollArrow"
              aria-label="scroll up"
              onClick={!scrollUpDisabled ? scrollUp : null}
            >
              <MdExpandLess color="inherit" />
            </MuiButtonBase>
          </div>
          {map(TIMES, (time, idx) => (
            <Fragment key={`time__${idx}`}>
              <div className="timeSlot">
                {time}:00 <sup>{idx <= 11 ? 'A.M.' : 'P.M.'}</sup>
              </div>
              <div className="timeSlot">
                {time}:30 <sup>{idx <= 11 ? 'A.M.' : 'P.M.'}</sup>
              </div>
            </Fragment>
          ))}

          <MuiButtonBase
            disabled={scrollDownDisabled}
            className="scrollDown scrollArrow"
            aria-label="scroll down"
            onClick={!scrollDownDisabled ? scrollDown : null}
          >
            <MdExpandMore color="inherit" />
          </MuiButtonBase>
        </div>
        <div className="graphColumnsContainer" ref={graphColumnsRef} onScroll={onScroll}>
          {map(WEEK_DAYS, (day, dayIdx) => (
            <div key={day} className="graphCol">
              <div className="graphHeader">
                <div>{day}</div>
                <div>30 min</div>
              </div>
              <div className="pillColumn">
                {map(TIMES, (_, slot) => {
                  const slotKey = formatSlotKey(slot);
                  return (
                    <Fragment key={`timeSlot__${day}_${slot}`}>
                      <MuiButtonBase
                        onClick={() => handlePillClick(dayIdx, `${slotKey}00`)}
                        key={`slot-${slot}`}
                        aria-label={`${day} ${slotKey}:00`}
                        className={clsx(
                          'pill',
                          schedule[dayIdx].includes(`${slotKey}00`) && 'active'
                        )}
                      >
                        <div />
                      </MuiButtonBase>
                      <MuiButtonBase
                        onClick={() => handlePillClick(dayIdx, `${slotKey}30`)}
                        key={`slot-${slot}-30`}
                        aria-label={`${day} ${slotKey}:30`}
                        className={clsx(
                          'pill',
                          schedule[dayIdx].includes(`${slotKey}30`) && 'active'
                        )}
                      >
                        <div />
                      </MuiButtonBase>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <IconButton color="primary" className="rightScrollBtn" onClick={scrollHorizontally}>
          <MdChevronRight />
        </IconButton>
      </div>
    </StyledRoot>
  );
}

Schedule.propTypes = {};

export default memo(Schedule);
