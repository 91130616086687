// import { fade } from '@mui/material/styles/colorManipulator';

const baseStyles = ({ theme }) => ({
  '&.pageContainer': {
    flex: 1,
    width: '100%',
    maxWidth: 1024,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flexDirection: 'column',
      height: 'auto',
      overflow: 'auto'
    }
  },
  '& .modalContainer': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'fixed',
      zIndex: 1,
      background: theme.palette.background.default,
      left: 20,
      top: 20,
      borderRadius: 20,
      padding: '1rem .5rem',
      height: 'calc(100% - 40px)',
      width: 'calc(100% - 40px)',
      boxShadow: '0 0 65px rgb(0 0 0 / 25%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden'
    }
  },
  '& .modalCloseButton': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      alignSelf: 'center',
      padding: '.5rem 2rem',
      borderRadius: '50px!important'
    }
  }
});

const careerHealthStyles = ({ theme }) => ({
  '& .careerHealthContainer': {
    flexBasis: '30%',
    minWidth: 250,
    // boxShadow: `7px 7px 7px ${fade(theme.palette.moreColors.black_1, 0.15)}`, @FIXME: remove fade()
    overflow: 'scroll',
    paddingBottom: '5rem',
    paddingTop: '2rem',
    zIndex: 1,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      paddingBottom: 0,
      paddingTop: '1rem',
      minHeight: 275,
      position: 'relative',
      overflow: 'initial'
    }
  },
  '& .careerHealthScoreContainer': {
    height: 260,
    width: 260,
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 240,
      width: 240
    }
  },
  '& .careerHealthScoreButton': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'absolute',
      padding: '13px 45px',
      borderRadius: '50px!important',
      bottom: -38,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: 214
    }
  },
  '& .careerHealthScoreProgress': {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  '& .careerHealthScoreDetails': {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    top: '55%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)'
  },
  '& .careerHealthScoreProgressGradient': {
    position: 'absolute',
    width: '98%',
    height: '98%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background:
      'linear-gradient(to bottom, rgba(135,135,249,0.09) 0%,rgba(135,135,249,0.09) 1%,rgba(135,135,249,0) 100%)',
    borderRadius: '50%',
    zIndex: '-1'
  },
  '& .careerHealthScoreTitle': {
    fontSize: 75,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 65
    }
  },
  '& .careerHealthScoreIcon': { position: 'absolute', height: 30, width: 30, top: 22 },
  '& .careerHealthScoreLabel': {
    fontWeight: 800,
    fontSize: 13,
    color: theme.palette.primary.text,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'relative',
      top: -10
    }
  },
  '& .careerHealthScoreLevel': {
    backgroundColor: theme.palette.primary.light,
    padding: '5px 20px',
    textTransform: 'capitalize',
    color: 'white',
    marginTop: 20,
    borderRadius: 10
  },
  '& .careerHealthScoreSubTitle': {
    fontSize: 13,
    marginBottom: 10,
    color: theme.palette.primary.text
  },
  '& .careerHealthScoreDetailsContainer': { padding: '0 1rem' },
  // list detail row styles start
  '& .listDetailsContainer': { paddingBottom: 20 },
  '& .listDetailsHeaderContainer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .listDetailsTitle': { fontSize: 15, letterSpacing: '0.25px' },
  '& .listDetailsInfo': { color: '#A4A4A5', fontSize: 11 },
  '& .listDetailImpactLevel': { color: theme.palette.primary.light, textTransform: 'capitalize' },
  '& .listDetailExpandButton': {
    background: '#ECECF5',
    borderRadius: 50,
    width: 25,
    height: 25,
    transform: 'rotate(90deg)'
  },
  '& .listDetailExpandIcon': {
    fontSize: 15,
    color: '#4743A2',
    transition: 'transform .3s ease-out',
    transform: 'rotate(0deg)'
  },
  '& .listDetailExpandIconActive': {
    fontSize: 15,
    color: theme.palette.primary.main,
    transform: 'rotate(180deg)'
  },
  '& .sectionToExpand': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height .35s ease-out'
  },
  '& .sectionToExpandActive': {
    listStyle: 'none',
    margin: '10px 0',
    padding: 0,
    maxHeight: 300,
    overflow: 'visible',
    transition: 'max-height .35s ease-out'
  },
  '& .listDetailListItem': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.moreColors.grey_1,
    fontSize: 12,
    paddingTop: 2.5,
    fontWeight: 800
  },
  '& .listDetailListItemComplete': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.light,
    fontSize: 12,
    paddingTop: 2.5
  },
  '& .listDetailActionItemButton': {
    color: theme.palette.primary.main,
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    outline: 0,
    border: 'none',
    background: 'transparent',
    padding: 0,
    paddingTop: 7,
    fontWeight: 800
  },
  '& .listDetailActionItemAnchor': {
    color: theme.palette.primary.main,
    fontSize: 11,
    display: 'block',
    background: 'transparent',
    padding: 0,
    paddingTop: 7,
    fontWeight: 800
  },
  '& .listDetailActionItemText': { fontSize: 10, marginLeft: 10 },
  '& .tutorialTrigger': {
    position: 'absolute',
    right: -26,
    top: 0,
    width: 26,
    height: 26,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#FFF !important'
  },
  '& .mobileVideoModal': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.83)'
    }
  },
  '& .mobileVideoContainer': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'relative',
      width: '100%',
      '&::after': {
        content: '""',
        display: 'block',
        paddingTop: '56.25%'
      }
    }
  },
  '& .mobileVideoIframe': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  },
  '& .mobileVideoModalCloseBtn': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'absolute',
      top: 20,
      right: 20
    }
  }
});

const careerDevContent = ({ theme }) => ({
  '& .careerDevContent': {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '70%',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'block'
    }
  },
  '& .topContainer': {
    background: '#ECECF5',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    position: 'sticky',
    top: '0',
    padding: '2rem',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'static',
      paddingBottom: '1rem'
    }
  },
  '& .bottomContainer': {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  '& .blogContainer': {
    flexBasis: '70%',
    overflow: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flexBasis: 'initial',
      overflow: 'initial',
      paddingBottom: '2rem'
    }
  },
  '& .blogScrollableContainer': {
    padding: '2rem',
    paddingBottom: '5rem',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '16px',
      overflow: 'scroll'
    }
  },
  '& .blogSectionTitle': { fontSize: 14 },
  '& .blogListContainer': { padding: '1rem 0', borderBottom: '1px solid #e4e4e4' },
  '& .blogLinkTitle': { color: theme.palette.primary.main, fontSize: 16, marginBottom: '1rem' },
  '& .blogSocialContainer': { color: theme.palette.primary.main, paddingTop: '.25rem' },
  '& .blogSocialLink': {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  '& .blogSocialIcon': { marginRight: '.5rem', fontSize: 20 },
  '& .saveCareerDevButton': {
    height: 50,
    width: 50
  },
  '& .blogSection': {
    marginBottom: 18,
    borderBottom: '3px solid #E5E5E5'
  },
  '& .course': {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .course__title': {
    fontSize: 16,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 14
    }
  },
  '& .course__link': {
    fontSize: 14,
    color: '#3D3D3D',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 12
    }
  },
  '& .course__action': {
    padding: '7px 22px',
    fontSize: 10,
    borderRadius: 16,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '7px 12px'
    }
  }
});

const careerDevIntro = ({ theme }) => ({
  '&.introContainer': {
    height: '100%',
    backgroundImage: 'url(/assets/img/setup_blurred_background.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  '& .introContentContainer': {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flex: 1,
      marginBottom: 40
    }
  },
  '& .introModalBackground': {
    width: 500,
    maxHeight: 412,
    padding: '3rem',
    background: '#FFF',
    boxShadow: '0 0 46px rgba(0, 0, 0, .25)',
    borderRadius: 17,
    position: 'relative',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 348,
      maxHeight: 440,
      padding: '1rem'
    }
  },
  '& .introModalContentContainer': { width: '100%', display: 'flex', flexDirection: 'column' },
  '& .introSelectContainer': {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: 25,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flexDirection: 'column'
    }
  },
  '& .introSelectLabel': {
    paddingRight: 15,
    minWidth: 160,
    color: theme.palette.primary.main,
    fontSize: 18,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      fontSize: 13
    }
  },
  '& .introSelect': {
    flexBasis: '100%'
  },
  '& .introCheckbox': {
    '& .StyledCheckbox-label': {
      marginLeft: 15,
      fontSize: 12
    }
  },
  '& .introActionContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 30
  },
  '& .title': {
    marginBottom: 14,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: '23px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 6,
      fontSize: 26,
      lineHeight: '29px'
    }
  },
  '& .subTitle': {
    maxWidth: 294,
    marginBottom: 23,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '13px',
    color: '#5E6060',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 320,
      fontSize: 15,
      lineHeight: '17px'
    }
  },
  '& .videoTitle': {
    maxWidth: 304,
    marginBottom: 9,
    textAlign: 'center',
    fontSize: 9,
    lineHeight: '10px',
    color: '#728699',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 320,
      marginBottom: 12,
      fontSize: 13,
      lineHeight: '14px'
    }
  },
  '& .videoContainer': {
    position: 'relative',
    width: 388,
    height: 226,
    marginBottom: 18,
    backgroundColor: '#000',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      maxWidth: 300,
      height: 188,
      marginBottom: 23
    }
  },
  '& .videoCloseBtn': {
    position: 'absolute',
    top: -5,
    right: -5,
    width: 25,
    height: 25,
    backgroundColor: '#fff !important',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: 21,
      height: 21
    }
  },
  '& .introError': {
    margin: '10px 0',
    textAlign: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 13
    }
  }
});

const connections = ({ theme }) => ({
  '& .connectionContainer': {
    flexBasis: '30%',
    overflow: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flexBasis: 'initial',
      overflow: 'initial'
    }
  },
  '& .connectionDetailContainer': {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0 10px .75rem',
    borderBottom: '1px solid #e4e4e4',
    position: 'relative',
    '&:hover .connectionDetailActions': {
      display: 'flex'
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 0 10px .75rem',
      marginBottom: 10
    }
  },
  '& .connectionDetailContainerContent': {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  '& .connectionDetailActions': {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(255,255,255,0.7)'
  },
  '& .connectionDetailAction': {
    width: 58,
    height: 22,
    borderRadius: 10,
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: '11px',
    letterSpacing: 0,
    '&.added': {
      backgroundColor: theme.palette.moreColors.yellow
    }
  },
  '& .connectionOverlay': {
    position: 'absolute',
    backgroundColor: 'white',
    opacity: '.7',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 22,
    paddingBottom: 10
  },
  '& .connectionImgWrapper': {
    position: 'relative',
    width: 45,
    height: 45,
    marginRight: 10
  },
  '& .connectionImgContainer': {
    position: 'relative',
    width: 45,
    height: 45,
    minWidth: 45,
    minHeight: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    border: '3px solid #4743A2',
    borderRadius: 10
  },
  '& .connectionNoImage': {
    position: 'absolute',
    backgroundColor: 'grey',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: 20,
    fontWeight: 800
  },
  '& .connectionImg': {
    position: 'absolute',
    maxWidth: 100
  },
  '& .connectionListTitle': {
    fontSize: 12,
    marginBottom: 7.5,
    borderBottom: '3px solid #8787F9',
    paddingBottom: 7.5,
    marginLeft: '.75rem',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 18,
      paddingBottom: 16,
      marginLeft: 0,
      marginBottom: 0,
      borderBottom: 'none'
    }
  },
  '& .connectionListAction': {
    fontSize: 11,
    fontWeight: 800,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: 100,
      height: 30,
      fontWeight: 'normal',
      fontSize: 13,
      lineHeight: 16,
      borderRadius: 25
    }
  },
  '& .connectionListSeeAll': {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 800,
    paddingBottom: '1rem'
  },
  '& .connectionListActions': {
    width: 100,
    height: 30,
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 16,
    borderRadius: 25
  },
  '& .connectionListContainer': {
    display: 'flex',
    overflowX: 'scroll',
    padding: '0 1rem'
  },
  '& .connectionSpacer': {
    width: 25,
    height: 25,
    display: 'table'
  },
  '& .connectionListTitleContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '& .connectionName': {
    fontSize: 12,
    fontWeight: 800
  },
  '& .connectionTitle': { fontSize: 10, color: 'grey' },
  // connectionProfile
  '& .profileContainer': {
    position: 'absolute',
    top: '2px',
    height: 'calc(100% - 75px)',
    background: 'white',
    width: 'calc(1024px / 3 * 2 / 3 + 50px)',
    boxShadow: '-6px 0 7px rgb(0 0 0 / 17%)',
    zIndex: 10,
    paddingTop: '1rem',
    boxSizing: 'border-box',
    overflow: 'scroll',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'fixed',
      top: 20,
      height: 'calc(100% - 40px)',
      width: 'calc(100% - 40px)',
      boxShadow: '0 0 65px rgb(0 0 0 / 25%)'
    }
  },
  '& .profilePadContainer': { padding: '1rem' },
  '& .profileHeaderContainer': { position: 'relative' },
  '& .profileImageContainer': {
    width: '100%',
    aspectRatio: '16 / 9',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .profileImage': {
    maxWidth: 220,
    position: 'absolute'
  },
  '& .profileNoImageContainer': {
    width: '100%',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ECECF5',
    fontSize: 50,
    fontWeight: 800,
    color: 'white'
  },
  '& .profileCloseButton': {
    position: 'absolute',
    right: '-7px',
    top: '-7px',
    zIndex: 1,
    transform: 'scale(.8)'
  },
  '& .profileStarredBtn': {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.moreColors.grey_1}`,
    backgroundColor: theme.palette.moreColors.grey_1
  },
  '& .profileStarredBtn_active': {
    border: `1px solid ${theme.palette.moreColors.yellow}`,
    backgroundColor: '#FFF'
  },
  '& .profileStarredIcon': {
    fontSize: 20,
    color: '#FFF'
  },
  '& .profileStarredIcon_active': {
    color: theme.palette.moreColors.yellow
  },
  '& .profileNameContainer': {
    padding: '1rem 0',
    borderBottom: '1px solid #e4e4e4'
  },
  '& .profileMailBtn': {
    width: 35,
    height: 35,
    borderRadius: '50%'
  },
  '& .sentMessage': {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 11,
    lineHeight: '12px',
    fontWeight: 'bold'
  },
  '& .viewMessagesBtn': {
    fontSize: 11,
    lineHeight: '12px'
  },
  '& .profileName': {
    fontSize: 16,
    paddingBottom: '.5rem'
  },
  '& .profileExpName': {
    color: '#545454',
    display: 'block'
  },
  '& .profileSubTitle': {
    fontSize: 10
  },
  '& .profileSectionContainer': {
    padding: '.5rem 0'
  },
  '& .profileExpContainer': {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    position: 'relative'
  },
  '& .profileExpCircle': {
    height: 3,
    width: 3,
    borderRadius: '50%',
    position: 'absolute',
    left: -2,
    top: '50%'
  },
  '& .profileCareerGoalSection': {
    paddingBottom: 7
  },
  '& .profileCareerGoalSubTitle': {
    display: 'block',
    fontSize: 11
  },
  '& .profileCareerGoalTitle': {
    color: theme.palette.primary.light
  },
  '& .profileCareerPathContainer': {
    backgroundColor: '#F4F4F4',
    padding: 12,
    borderRadius: 10,
    margin: '1rem 0'
  },
  '& .profileEduContainer': {
    padding: '5px 0'
  },
  '& .profileMotto': {
    color: theme.palette.moreColors.grey_1,
    fontSize: 12
  },
  '& .profileSectionTitle': {
    textTransform: 'uppercase',
    fontSize: 11,
    color: '#999999',
    paddingBottom: 2,
    borderBottom: '1px solid #ECECF5',
    marginBottom: '.5rem'
  },
  '& .profileNavContainer': {
    position: 'sticky',
    bottom: 0,
    padding: '.75rem 1rem .5rem',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 -3px 4px rgb(0 0 0 / 3%)',
    backgroundColor: 'white',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'block'
    }
  },
  '& .profileNavButton': {
    fontSize: 13,
    padding: '5px 10px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginRight: 10
    }
  },
  '& .suggestedJobsContainer': {
    marginBottom: 10,
    cursor: 'pointer',
    borderLeftWidth: 5,
    borderLeftColor: 'white',
    borderLeftStyle: 'solid',
    padding: '.5rem',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      borderLeftWidth: 0,
      padding: 0
    }
  },
  '& .suggestedJobsContainerActive': {
    borderLeftColor: theme.palette.primary.main
  },
  '& .jobCardContainer': {
    background: 'white',
    position: 'absolute',
    right: 'calc(1024px / 3 * 2 / 3 + 60px)',
    zIndex: 5,
    maxWidth: 250,
    borderRadius: 18,
    boxShadow: '0 0 07 rgba(0, 0, 0, .25)'
  },
  '& .suggestedJobsTitle': {
    fontSize: 14,
    color: theme.palette.primary.main
  },
  '& .suggestedJobsCompany': {
    fontSize: 11,
    color: theme.palette.moreColors.grey_1
  },
  '& .scrollToTop': {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 1,
    width: 1
  },
  '& .managedConnectionsContainer': {
    position: 'absolute',
    top: '2px',
    zIndex: 10,
    width: 'calc(1024px / 3 * 2 / 3 + 50px)',
    height: 'calc(100% - 75px)',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-6px 0 7px rgb(0 0 0 / 17%)',
    background: '#FFF',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'fixed',
      top: 20,
      width: 'calc(100% - 40px)',
      height: 'calc(100% - 40px)',
      borderRadius: 20,
      boxShadow: '0 0 65px rgb(0 0 0 / 25%)'
    }
  },
  '& .managedConnectionsHeader': {
    marginBottom: 16,
    padding: '13px 12px 0',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'relative',
      marginBottom: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 12,
        height: 1,
        width: '100%',
        maxWidth: '40%',
        display: 'block',
        backgroundColor: theme.palette.moreColors.purpleLight_1
      }
    }
  },
  '& .managedConnectionsContent': {
    flex: 1,
    padding: '13px 12px 0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 13px 12px'
    }
  },
  '& .managedConnectionsCloseBtnContainer': {
    marginBottom: 27,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 0,
      order: 1
    }
  },
  '& .managedConnectionsCloseBtn': {
    width: 45,
    height: 45,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.21)',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: 35,
      height: 35,
      marginRight: 5,
      boxShadow: 'none'
    }
  },
  '& .managedConnectionsTitle': {
    fontWeight: 'bold',
    lineHeight: '13px',
    fontSize: 12,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 16
    }
  },
  '& .managedConnectionsItem': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid #e4e4e4'
  },
  '& .managedConnectionsItemContent': {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  '& .managedConnectionsItem__details': {
    width: '100%'
  },
  '& .managedConnectionsItem__starIcon': {
    position: 'absolute',
    top: -4,
    right: -4,
    zIndex: 1,
    fontSize: 12,
    color: theme.palette.moreColors.yellow,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      top: -3,
      right: -3,
      fontSize: 16
    }
  }
});

export const connectionRowStyles = ({ theme }) => ({
  '& .connectionDetailContainer.row': {
    width: 86,
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '0 0 10px .75rem',
    marginBottom: 10,
    // borderBottom: '1px solid #e4e4e4',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    '& .connectionDetailContainerContent': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .connectionOverlay': {
      position: 'absolute',
      backgroundColor: 'white',
      opacity: '.7',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 22,
      paddingBottom: 10
    },
    '& .connectionImgWrapper': {
      position: 'relative',
      width: 70,
      height: 70,
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .connectionImgContainer': {
      position: 'relative',
      width: 70,
      height: 70,
      minWidth: 45,
      minHeight: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      border: '3px solid #4743A2',
      borderRadius: 50
    },
    '& .connectionAvatarStarBtn': {
      position: 'absolute',
      zIndex: 1,
      bottom: -2,
      right: 2,
      width: 24,
      height: 24,
      padding: 0,
      fontSize: 12,
      borderRadius: '50%',
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
      '&.starred': {
        backgroundColor: theme.palette.moreColors.yellow
      }
    },
    '& .connectionNoImage': {
      position: 'absolute',
      backgroundColor: 'grey',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontSize: 20,
      fontWeight: 800,
      paddingTop: 5
    },
    '& .connectionImg': {
      position: 'absolute',
      maxWidth: 100
    },
    '& .connectionDetailsContainer': {
      width: '100%'
    },
    '& .connectionName': {
      fontSize: 10,
      fontWeight: 800,
      paddingTop: 2.5
    },
    '& .connectionTitle': { fontSize: 7.5, color: 'grey', paddingTop: 2.5 }
  }
});

export default ({ theme }) => ({
  ...baseStyles({ theme }),
  ...careerDevContent({ theme }),
  ...careerHealthStyles({ theme }),
  ...connections({ theme }),
  ...careerDevIntro({ theme }),
  ...connectionRowStyles({ theme })
});
