import React from 'react';
import { SvgIcon } from 'components';

function MdChevronRight(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
    </SvgIcon>
  );
}

export default MdChevronRight;
