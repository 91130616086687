import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import map from 'lodash/map';
import trim from 'lodash/trim';
import filter from 'lodash/filter';
import { Box, InputAdornment, styled, useMediaQuery } from 'components';
import { Button, ButtonBase, CheckBoxGroup, Input, Spinner } from 'components/shared';
import { useForm } from 'src/components/form';
import { GET_SKILLS, POST_EMPLOYEE_SKILLS } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const NAME = 'skills';

function UserSkillsForm({ form, onChange, onGoToNextStep }) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const [fetchSkills, { loading: skillsLoading = true, data: skillsData }] = useLazyQuery(
    GET_SKILLS,
    {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    }
  );

  const [postUserSkills, { loading }] = useMutation(POST_EMPLOYEE_SKILLS);
  const { attrs, $, set, getError, withValidation } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: [
        'presence',
        (value) => {
          if (!value) return;
          if (filter(value, (o) => o.checked).length < 2) {
            return 'Please, select at least 2';
          }
        }
      ]
    }
  });

  // @todo: check if id is necessary here
  // @todo: add id for custom skills
  const [options, setOptions] = useState(() => [
    // ...map(form.new_skills || [], skill => ({ /*id: '',*/ label: skill, value: skill })),
  ]);
  const [val, setVal] = useState('');

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (skillsData?.getSkills) setOptions(skillsData.getSkills || []);
  }, [JSON.stringify(skillsData)]);

  const handleChange = (data, checked) => {
    let prevState = { ...attrs[NAME] };
    if (!checked) prevState[data.name] = { ...prevState[data.name], checked: false };
    else prevState[data.name] = { skill_id: data.skill_id, name: data.name, checked: true };

    set(NAME, prevState);
    onChange({ ...form, [NAME]: prevState });
  };

  // function handleValueChange(e) {
  //   setVal(e.target.value);
  // }

  // function handleSkillAdd(e) {
  //   e.preventDefault();
  //   if (trim(val).length > 0) {
  //     const prevState = { ...form[NAME] };
  //     if (!prevState[val]) prevState[val] = { name: val, skill_id: null, checked: true, custom: true };
  //
  //     set(NAME, prevState);
  //     onChange({ ...form, [NAME]: prevState });
  //     setOptions([ { name: val, skill_id: null }, ...options ]);
  //     setVal('');
  //   }
  // }

  const next = withValidation(async (validAttrs) => {
    const postData = filter(validAttrs[NAME], (o) => o.checked).map(({ checked, ...rest }) => ({
      ...rest
    }));
    await postUserSkills({ variables: { [NAME]: postData } });
    onGoToNextStep();
  });

  return (
    <>
      <RequirementText>Select at least 2</RequirementText>
      {/*<form onSubmit={handleSkillAdd}>*/}
      {/*  <Input*/}
      {/*    id="skill-input"*/}
      {/*    value={val}*/}
      {/*    placeholder="Type in a skill"*/}
      {/*    variant="outlined"*/}
      {/*    endAdornment={(*/}
      {/*      <InputAdornment>*/}
      {/*        <AddButton type="submit">Add</AddButton>*/}
      {/*      </InputAdornment>*/}
      {/*    )}*/}
      {/*    className={classes.input}*/}
      {/*    onChange={handleValueChange}*/}
      {/*  />*/}
      {/*</form>*/}
      {skillsLoading ? (
        <Box py="10px" display="flex" justifyContent="center">
          <Spinner size={48} />
        </Box>
      ) : (
        <CheckBoxGroup
          data={map(options, (opt) => ({
            ...opt,
            label: opt.name,
            value: opt.name,
            checked: form[NAME][opt.name]?.checked || false
          }))}
          aria-label="skills"
          disabled={loading}
          error={getError(NAME)}
          withHelperText
          sx={{
            width: '100%',
            minHeight: 150,
            maxHeight: 300,
            marginBottom: '16px',
            flex: 1,
            overflow: 'auto'
          }}
          checkboxesContainerProps={{
            sx: { overflow: 'auto' }
          }}
          analyticParams={{
            key: 'Employee skills focused (onboarding)',
            trigger: 'focus'
          }}
          onChange={handleChange}
        />
      )}
      <div style={{ maxWidth: 320 }}>
        <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
          You got this!
        </p>
        <p className="secondaryText" style={{ marginBottom: 16 }}>
          Your skills help QH give you customized results
        </p>
      </div>
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        className="nextBtn"
        sx={{ mb: isDesktop ? '25px' : '13px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
      <PrivacyClauseButton />
    </>
  );
}

UserSkillsForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const AddButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: '17px',
  letterSpacing: 0,
  color: theme.palette.primary.main
}));
const RequirementText = styled('p')(({ theme }) => ({
  marginBottom: 24,
  textAlign: 'center',
  fontSize: 14,
  color: 'rgba(44,44,44,0.5)'
}));
const StyledUserSkillsForm = styled(UserSkillsForm)(styles);

export default StyledUserSkillsForm;
