import React from 'react';
import PT from 'prop-types';
import { qaAttr } from 'utils';
import { useMutation } from '@apollo/client';
import { useForm } from 'components/form';
import { Button, PhoneInput, Spinner } from 'components/shared';
import { unformatPhone } from 'utils';
import { POST_USER_PHONE } from 'api';

const NAME = 'phone';

function Phone({ form, onChange, onGoToNextStep }) {
  const [postPhone, { loading }] = useMutation(POST_USER_PHONE);
  const { $, set, withValidation } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: ['presence', 'phone']
    }
  });

  function handleFieldChange(field) {
    return (e) => {
      const unformattedValue = unformatPhone(e.target.value);
      set(field, unformattedValue);
      onChange({ ...form, [field]: unformattedValue });
    };
  }

  function next() {
    onGoToNextStep(
      withValidation((attrs) => postPhone({ variables: { [NAME]: unformatPhone(attrs[NAME]) } }))
    );
  }

  return (
    <>
      <PhoneInput
        id="phone"
        {...$(NAME)}
        placeholder="1-123-456-7890"
        autoComplete="off"
        type="tel"
        ariaLabel="user phone number"
        required
        withHelperText
        highlightInputOnError={false}
        disabled={loading}
        analyticParams={{
          key: 'Employee phone focused (onboarding)',
          trigger: 'focus'
        }}
        onChange={handleFieldChange(NAME)}
        testID="onboarding-phone-input"
      />
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        sx={{ height: 50, width: '100%', mb: '25px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

Phone.propTypes = {
  form: PT.objectOf(PT.any).isRequired,
  onGoToNextStep: PT.func.isRequired,
  onChange: PT.func.isRequired
};

export default Phone;
