import React from 'react';
import { SvgIcon } from 'components';

function MdPlayArrow(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M8 5v14l11-7z"/>
    </SvgIcon>
  );
}

export default MdPlayArrow;
