import { format, parse } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { sortBy } from 'lodash';

export const getLocaleTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || '';
};

export const dateToISOStringWithOffset = (date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
};

export const convertZonedDateToUTC = (date, dateTZ) => zonedTimeToUtc(date, dateTZ);

export const convertUTCToZonedDate = (isoDate, dateTZ) => utcToZonedTime(isoDate, dateTZ);

export const convertDateToTZ = (date, sourceTZ, targetTZ) => {
  if (sourceTZ && targetTZ) {
    const dateInSourceTZ = zonedTimeToUtc(date, sourceTZ);
    const isoDate = dateInSourceTZ.toISOString();
    return utcToZonedTime(isoDate, targetTZ);
  }
  return date;
};

export const sortDateRanges = (ranges = [], dateFormat = 'yyyy-MM-dd') => {
  return sortBy(ranges, [({ startDate }) => startDate && parse(startDate, dateFormat, new Date())]);
};
