import React, { useState, useEffect, useCallback, useMemo, useRef, memo } from 'react';
import PT from 'prop-types';
import { useParams, useMatch, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import AvatarEditor from 'react-avatar-editor';
import clsx from 'clsx';
import { map, find, reduce } from 'lodash';
import format from 'date-fns/format';
import parseDate from 'date-fns/parse';
import isValidDate from 'date-fns/isValid';
import { useRestrictions, useEmployerProfileQuery } from 'hooks';
import { getRoutes, imageTypeHelperText, qaAttr, makeInitForm, trimStr } from 'utils';
import { Box, FormHelperText, InputAdornment, styled, useMediaQuery } from 'components';
import { Button, DatePicker, Input, IconButton, Select, Spinner } from 'components/shared';
import { ConfirmationDialog, IndustryModal, SkillsModal } from 'components/dialogs';
import { MdChevronRight, MdDelete } from 'components/icons';
import { useForm } from 'components/form';
import styles from 'styles/Dashboard/JobPosting';
import {
  GET_JOB,
  GET_QUESTIONS,
  GET_CURRENCIES,
  GET_COUNTRIES,
  GET_STATES,
  GET_PAY_PERIODS,
  POST_JOB,
  ADD_JOB,
  DELETE_JOB,
  POST_EMPLOYER_QUESTION,
  CLOSE_JOB,
  UPDATE_JOB_IMG
} from 'api';
import defaultImg from 'assets/img/job_default.png';

const StyledRoot = styled('div')(styles);

const MAX_DESCR_LENGTH = 350;
const MAX_REQ_LENGTH = 250;
const ROUTES = getRoutes();
const NO_PREV_FIELDS = ['active', 'draft', 'imageUrl', 'newImageFile'];
const INIT_ADDRESS_FORM = {
  city: '',
  countryId: 1, // USA
  stateId: '',
  zip: ''
};
const INIT_PAY_FORM = {
  payCurrencyId: 1, // usd
  payPeriodId: 5, // hourly
  payMin: 0,
  payMax: 0
};
const INIT_FORM = {
  ...INIT_ADDRESS_FORM,
  ...INIT_PAY_FORM,
  active: false,
  applicantAmount: 0,
  description: '',
  draft: false,
  endDate: null,
  hireDate: null,
  imageUrl: '',
  industry: [],
  location: '',
  newImageFile: null,
  questions: [],
  requirements: '',
  skills: [],
  title: '',
  webUrl: ''
};
const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
const FRONT_DATE_FORMAT = 'MM-dd-yyyy';
const serverDateToDateObj = (string) => parseDate(string, SERVER_DATE_FORMAT, new Date());

function generateForm(data) {
  return makeInitForm(data, [], NO_PREV_FIELDS);
}

function JobPosting(props) {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { restrictions = {}, fetchRestrictions } = useRestrictions();

  const matchNewJob = useMatch(ROUTES.employer.job_new);
  const isNewJobPage = !!matchNewJob;
  const matchEditJob = useMatch(getRoutes({ id: idParam }).employer.job_edit);
  const isEditJobPage = !!matchEditJob;

  const editorRef = useRef();
  const initForm = useMemo(() => generateForm(INIT_FORM), []);

  const [fetchJob, { data: jobData, loading: jobLoading }] = useLazyQuery(GET_JOB, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [fetchQuestions, { data: questionsData, loading: questionsLoading }] = useLazyQuery(
    GET_QUESTIONS,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const { fetchProfile, profile, canPostJob, loading: profileLoading } = useEmployerProfileQuery();
  const canPost = isNewJobPage || profile?.draft ? canPostJob : true;
  const canCopy = canPostJob;
  const canSaveDraft = isNewJobPage ? canPost : true;
  const canActivate = !profile?.active && canPostJob;

  const [fetchCurrencies, { data: currenciesData }] = useLazyQuery(GET_CURRENCIES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [fetchCountries, { data: countriesData }] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [fetchStates, { data: statesData }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [fetchPayPeriods, { data: payPeriodsData }] = useLazyQuery(GET_PAY_PERIODS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [activeJob, { loading: jobActivationLoading = false }] = useMutation(CLOSE_JOB);

  const [createJob, { loading: createJobLoading }] = useMutation(ADD_JOB);
  const [updateJob, { loading: updateJobLoading }] = useMutation(POST_JOB);
  const [deleteJob, { loading: deleteJobLoading }] = useMutation(DELETE_JOB);
  const [updateQuestions, { loading: updateQuestionsLoading }] =
    useMutation(POST_EMPLOYER_QUESTION);
  const [updateJobImg, { loading: updateImgLoading }] = useMutation(UPDATE_JOB_IMG);

  const [isEdit, setIsEdit] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [payPeriods, setPayPeriods] = useState([]);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isImageChanged, setImageChanged] = useState(false);
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);

  const jobProcessing =
    createJobLoading ||
    updateJobLoading ||
    updateQuestionsLoading ||
    isImageLoading ||
    deleteJobLoading;

  const jobPostLoading = isImageLoading || questionsLoading || createJobLoading || updateJobLoading;

  const { $, attrs, set, validate, getError, useConfig, reset } = useForm({
    initial: initForm,
    validations: {
      imageUrl: 'presence',
      title: [{ presence: { allowEmpty: false } }],
      location: [{ presence: { allowEmpty: false } }],
      webUrl: [{ url: { protocol: true } }],
      description: [
        {
          presence: { allowEmpty: false },
          ...(MAX_DESCR_LENGTH > 0 ? { maxLength: { max: MAX_DESCR_LENGTH } } : {})
        }
      ],
      requirements: [
        {
          ...(MAX_REQ_LENGTH > 0 ? { maxLength: { max: MAX_REQ_LENGTH } } : {})
        }
      ],
      applicantAmount: ['presence', { numericality: { greaterThan: 0 } }],
      'questions.*.name': [{ presence: { allowEmpty: false } }],
      city: [{ presence: { allowEmpty: false } }],
      countryId: ['presence', 'numericality'],
      stateId: ['presence', 'numericality'],
      payCurrencyId: ['presence', 'numericality'],
      payPeriodId: ['presence', 'numericality'],
      payMin: [
        'presence',
        {
          numericality: {
            greaterThan: 0,
            numberOfDecimals: 2,
            onlyInteger: false,
            message: {
              notValid: 'Please, enter a valid number (e.g., 123, 123.99)'
            }
          }
        }
      ],
      payMax: [
        'presence',
        {
          numericality: {
            greaterThan: 0,
            numberOfDecimals: 2,
            onlyInteger: false,
            message: {
              notValid: 'Please, enter a valid number (e.g., 123, 123.99)'
            }
          }
        }
      ],
      endDate: [
        { date: { earliest: new Date(), messages: { tooEarly: 'Must not be in the past' } } }
      ],
      hireDate: [
        { date: { earliest: new Date(), messages: { tooEarly: 'Must not be in the past' } } }
      ]
    }
  });

  useConfig(() => {
    const countryCode = find(countries, ['id', Number(attrs.countryId)])?.code || '';
    return {
      validations: {
        zip: ['presence', { zip: { countryCode } }],
        payMax: [
          'presence',
          {
            numericality: {
              greaterThan: Number(attrs.payMin) || 0,
              numberOfDecimals: 2,
              onlyInteger: false,
              message: {
                notValid: 'Please, enter a valid number (e.g., 123, 123.99)'
              }
            }
          }
        ]
      }
    };
  }, [attrs.countryId, attrs.payMin, attrs.payMax, countries]);

  const {
    active,
    applicantAmount,
    city,
    countryId,
    currency,
    draft,
    description,
    endDate,
    frequency,
    hireDate,
    imageUrl,
    industry,
    location,
    min,
    max,
    newImageFile,
    payMin,
    payMax,
    payCurrencyId,
    payPeriodId,
    questions,
    requirements,
    stateId,
    skills,
    title,
    webUrl,
    zip
  } = attrs;

  const formattedHireDate = useMemo(
    () => (hireDate && isValidDate(hireDate) ? format(hireDate, FRONT_DATE_FORMAT) : ''),
    [hireDate]
  );
  const formattedEndDate = useMemo(
    () => (endDate && isValidDate(endDate) ? format(endDate, FRONT_DATE_FORMAT) : ''),
    [endDate]
  );

  const selectedCountry = useMemo(
    () => (countries.length ? find(countries, ['id', Number(countryId)])?.name || '' : ''),
    [JSON.stringify(countries), countryId]
  );

  const selectedStateOption = useMemo(() => {
    if (statesData?.getAllStates && stateId) {
      return find(statesData.getAllStates, ['id', Number(stateId)]) || {};
    }
    return {};
  }, [JSON.stringify(statesData), stateId]);

  const selectedCurrency = useMemo(
    () =>
      currencyOptions.length
        ? find(currencyOptions, ['id', Number(payCurrencyId)])?.name || ''
        : '',
    [JSON.stringify(currencyOptions), payCurrencyId]
  );

  const selectedPayPeriod = useMemo(
    () => (payPeriods.length ? find(payPeriods, ['id', Number(payPeriodId)])?.name || '' : ''),
    [JSON.stringify(payPeriods), payPeriodId]
  );

  const fetchJobById = async () => {
    const id = Number(idParam);
    if (id) {
      await fetchJob({ variables: { jobId: id } });
      await fetchQuestions({ variables: { jobsJobsId: id } });
    }
  };

  const resetForm = () => {
    set(generateForm(INIT_FORM));
  };

  useEffect(() => {
    resetForm();
    if (idParam) fetchJobById();
    fetchProfile();
    fetchCurrencies();
    fetchPayPeriods();
    fetchCountries();
    fetchStates();
  }, []);

  useEffect(() => {
    if (jobData?.job) {
      const { job } = jobData;
      const formattedSkills = map(job.skills, ({ __typename, ...sk }) => ({ ...sk }));
      const formattedIndustries = map(job.industry, ({ __typename, ...ind }) => ({
        ...ind
      }));
      const hireDateToDate = job.hireDate ? serverDateToDateObj(job.hireDate) : null;
      const endDateToDate = job.endDate ? serverDateToDateObj(job.endDate) : null;
      const preparedData = reduce(
        job,
        (result, value, key) => {
          const copy = { ...result };
          if (Object.prototype.hasOwnProperty.call(INIT_FORM, key)) {
            copy[key] = value || INIT_FORM[key];
          }
          return copy;
        },
        { ...INIT_FORM }
      );
      const nextForm = generateForm({
        ...preparedData,
        hireDate: hireDateToDate,
        endDate: endDateToDate
      });
      set({
        ...nextForm,
        skills: formattedSkills,
        industry: formattedIndustries
      });
    }
  }, [JSON.stringify(jobData)]);

  useEffect(() => {
    if (questionsData?.questions) {
      const fetchedQuestions = questionsData.questions?.[0]?.questions || [];
      if (fetchedQuestions) {
        const formattedQuestions = map(fetchedQuestions, ({ __typename, ...q }) => ({ ...q }));
        set({ questions: formattedQuestions });
      }
    }
  }, [JSON.stringify(questionsData)]);

  useEffect(() => {
    if (profile) {
      if (isNewJobPage) {
        const { address, stateId, countryId, city, zip } = profile;
        set({
          location: address || '',
          stateId: stateId || INIT_ADDRESS_FORM.stateId,
          countryId: countryId || INIT_ADDRESS_FORM.countryId,
          city: city || INIT_ADDRESS_FORM.city,
          zip: zip || INIT_ADDRESS_FORM.zip
        });
      }
      if (isNewJobPage && profile.imageUrl) {
        // @TODO: think how to prefill with profile image
        // const response = await fetch(data.employerProfile.imageUrl);
        // const blob = await response.blob();
        // const imageUrl = window.URL.createObjectURL(blob);
        // set({ imageUrl });
        // setImageChanged(true);
      }
    }
  }, [JSON.stringify(profile)]);

  useEffect(() => {
    if (currenciesData?.getAllCurrencies) setCurrencyOptions(currenciesData.getAllCurrencies);
  }, [JSON.stringify(currenciesData)]);

  useEffect(() => {
    if (countriesData?.getAllCountries) setCountries(countriesData.getAllCountries);
  }, [JSON.stringify(countriesData)]);

  useEffect(() => {
    if (statesData?.getAllStates) {
      const mappedStates = reduce(
        statesData.getAllStates,
        (result, value) => {
          const countryName = value.country.name;
          if (result[countryName]) result[countryName].push(value);
          else {
            result[countryName] = [{ ...value }];
          }
          return result;
        },
        {}
      );
      setStates(mappedStates);
    }
  }, [JSON.stringify(statesData)]);

  useEffect(() => {
    if (payPeriodsData?.getAllPayPeriods) setPayPeriods(payPeriodsData.getAllPayPeriods);
  }, [JSON.stringify(payPeriodsData)]);

  useEffect(() => {
    if (
      selectedCountry &&
      selectedStateOption.name &&
      selectedStateOption?.country?.name !== selectedCountry
    ) {
      set({ stateId: '' });
    }
  }, [selectedCountry, selectedStateOption.name]);

  const openJob = async () => {
    const job = await activeJob({
      variables: {
        id: parseInt(idParam),
        active: true
      }
    });
    fetchRestrictions();
    navigate(ROUTES.employer.dashboard);
  };

  const closeJob = async () => {
    const job = await activeJob({
      variables: {
        id: parseInt(idParam),
        active: false
      }
    });
    fetchRestrictions();
    navigate(ROUTES.employer.dashboard);
  };

  const handleJobPost = (isDraft = false, isCopy = false) => {
    validate()
      .then(async (validAttrs) => {
        const payMinParam = Number(validAttrs.payMin);
        const payMaxParam = Number(validAttrs.payMax);
        const payRange = `${payMin}-${payMax} ${selectedCurrency} ${selectedPayPeriod}`;
        const hireDateParam = validAttrs.hireDate
          ? format(validAttrs.hireDate, SERVER_DATE_FORMAT)
          : null;
        const endDateParam = validAttrs.endDate
          ? format(validAttrs.endDate, SERVER_DATE_FORMAT)
          : null;

        const jobDataParams = {
          applicantAmount: Number(validAttrs.applicantAmount),
          city: trimStr(validAttrs.city),
          countryId: Number(validAttrs.countryId),
          description: validAttrs.description,
          draft: isDraft,
          endDate: endDateParam,
          hireDate: hireDateParam,
          industry: validAttrs.industry,
          location: trimStr(validAttrs.location),
          payRange, // ! currently required on server
          payMin: payMinParam,
          payMax: payMaxParam,
          payPeriodId: Number(validAttrs.payPeriodId),
          payCurrencyId: Number(validAttrs.payCurrencyId),
          requirements: validAttrs.requirements,
          skills: validAttrs.skills,
          stateId: Number(validAttrs.stateId),
          title: trimStr(validAttrs.title),
          webUrl: validAttrs.webUrl,
          zip: trimStr(validAttrs.zip)
        };

        if (isNewJobPage || isCopy) {
          const response = await createJob({ variables: jobDataParams });
          if (response?.data?.addJob?.id) {
            if (isImageChanged) await postImage(response.data.addJob.id);
            await handlePostQuestions(response.data.addJob.id, validAttrs.questions);
          }
        } else {
          await updateJob({ variables: { id: Number(idParam), ...jobDataParams } });
          await handlePostQuestions(idParam, validAttrs.questions);
          if (isImageChanged) await postImage(idParam);
        }

        await fetchRestrictions();
        if (isNewJobPage || isCopy) {
          navigate(ROUTES.employer.dashboard);
        } else {
          set(generateForm(jobDataParams));
        }
      })
      .catch((errors) => {
        const fieldsWithErr = Object.keys(errors).filter((field) => errors[field]);
        if (fieldsWithErr[0]) {
          const errorElement = document.getElementById(`${fieldsWithErr[0]}-input-helper`);
          if (errorElement) errorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
  };

  const handlePostQuestions = async (jobId, questionsToPost) =>
    updateQuestions({
      variables: { jobsId: Number(jobId), questions: questionsToPost }
    });

  const handleJobCopy = async () => {
    handleJobPost(draft, true);
  };

  const confirmDelete = async () => {
    await deleteJob({ variables: { id: Number(idParam) } });
    await fetchRestrictions();
    setIsConfirmModalOpen(false);
    navigate(ROUTES.employer.dashboard);
  };

  const handleJobDelete = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const toggleEditMode = () => {
    setIsEdit(!isEdit);
  };

  const toggleSkillsModal = () => {
    setIsSkillsModalOpen(!isSkillsModalOpen);
  };

  const toggleIndustriesModal = () => {
    setIsIndustryModalOpen(!isIndustryModalOpen);
  };

  const handleFieldChange = useCallback((e, { name }) => {
    set(name, e.target.value);
  }, []);

  const handleFormChange = useCallback((nextForm) => {
    set({ ...nextForm });
  }, []);

  const handleQuestionsChange = useCallback(
    (e, { name }) => {
      const val = e.target.value;
      set(name, val);
    },
    [JSON.stringify(questions)]
  );

  const addQuestion = () => {
    const nextQuestions = [...questions];
    nextQuestions.push({ name: '' });
    set('questions', nextQuestions);
  };

  const deleteQuestion = (index) => {
    const questionsCopy = [...questions];
    const nextQuestions = [...questionsCopy.slice(0, index), ...questionsCopy.slice(index + 1)];
    set('questions', nextQuestions);
  };

  function handleDateChange(field) {
    return (date, inputValue) => {
      set(field, date);
    };
  }

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setImageChanged(true);
      set({ imageUrl: reader.result, newImageFile: file });
    };
    if (file) reader.readAsDataURL(file);
  };

  const postImage = async (jobId) => {
    const file = attrs.newImageFile;
    const imgUrl = attrs.imageUrl;

    if (editorRef.current && (file || imgUrl)) {
      setImageLoading(true);
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const fileName = file?.name || `${jobId}-image`;
      const ext = fileName.split('.').pop();
      return new Promise((resolve, reject) => {
        canvasScaled.toBlob(async (blob) => {
          const blobToFile = new File([blob], fileName);
          await updateJobImg({ variables: { jobId: Number(jobId), file: blobToFile } });
          const newImageUrl = window.URL.createObjectURL(blobToFile);
          set({ imageUrl: newImageUrl, newImageFile: null });
          setImageChanged(false);
          setImageLoading(false);
          resolve();
        }, `image/${ext}`);
      });
    }
  };

  const selectProps = {
    inputVariant: 'outlined',
    InputComponentProps: {
      labelClassName: 'label',
      inputClassName: 'select',
      htmlInputClassName: 'select__input'
    },
    native: false,
    required: true,
    withHelperText: true,
    withEmptyOption: true
  };

  const renderHeader = () => (
    <div className="header">
      <div className="title">New Job Posting</div>
      <Box mt="-8px" display="flex" alignItems="center" flexWrap="wrap">
        {isEditJobPage && draft && (
          <Button
            variant="outlined-primary"
            className="jobAction danger"
            endIcon={deleteJobLoading ? <Spinner size={12} /> : null}
            disabled={jobProcessing}
            onClick={handleJobDelete}
            testID="delete-job-button"
          >
            Delete
          </Button>
        )}
        {(draft || isNewJobPage) && (
          <Button
            variant="outlined-primary"
            className="jobAction"
            endIcon={jobPostLoading ? <Spinner size={15} /> : null}
            disabled={!canSaveDraft || jobProcessing}
            onClick={() => handleJobPost(true)}
            testID="save-draft-button"
          >
            Save Draft
          </Button>
        )}
        {!isNewJobPage && active && (
          <Button
            variant="outlined-primary"
            className="jobAction"
            endIcon={jobPostLoading ? <Spinner size={15} /> : null}
            disabled={!canCopy || jobProcessing}
            onClick={() => handleJobCopy()}
            testID="copy-job-button"
          >
            {`Copy ${draft ? 'Draft' : 'Job'}`}
          </Button>
        )}
        {!isNewJobPage && (
          <Button
            variant="outlined-primary"
            className="jobAction"
            endIcon={jobActivationLoading ? <Spinner size={15} /> : null}
            disabled={(!active && !canActivate) || jobActivationLoading}
            onClick={active ? closeJob : openJob}
            testID="activate-job-button"
          >
            {active ? 'Close Job Posting' : 'Re-open Job'}
          </Button>
        )}
        <Button
          variant="filled-primary"
          className="jobAction"
          endIcon={jobPostLoading ? <Spinner size={15} /> : null}
          disabled={!canPost || jobProcessing}
          onClick={() => handleJobPost()}
          testID="post-job-button"
        >
          Post Job
        </Button>
      </Box>
    </div>
  );

  const renderImage = () => {
    const imgError = getError('imageUrl');
    return isEdit ? (
      <div className="imageContainer">
        <div className="label">Job Post Image</div>
        <AvatarEditor
          ref={editorRef}
          image={newImageFile || imageUrl}
          width={230}
          height={230}
          border={0}
          borderRadius={0}
          color={[255, 255, 255, 0.6]}
          scale={1.1}
          rotate={0}
          className={clsx('avatarEditor', imgError && 'error')}
        />
        {imgError && (
          <FormHelperText id="imageUrl-input-helper" error>
            {imgError}
          </FormHelperText>
        )}
        <p className="imageTypeTip">{imageTypeHelperText}</p>
        <input
          accept="image/*"
          id="image-input"
          type="file"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
          {...qaAttr('image-input')}
        />
        <label htmlFor="image-input">
          <Button
            component="span"
            variant="filled-primary"
            disabled={jobProcessing}
            className="mainAction"
            testID="photo-edit-button"
          >
            Upload New
          </Button>
        </label>
      </div>
    ) : (
      <div className="imageContainer">
        <div className="label">Job Post Image</div>
        <img src={imageUrl || defaultImg} className="imagePreview" alt="" />
      </div>
    );
  };

  const renderDisclaimer = () => (
    <p className="disclaimerText">
      By pressing Post, you agree that this job will be posted and applications will be processed in
      accordance with QuickHire's Privacy Policy and Terms of Service. You consent to QuickHire
      informing a user that you have opened, viewed or made a decision regarding the user's
      application.
    </p>
  );

  const renderQuestions = () => (
    <div className="questionsContainer">
      <div className="questionsTitle">Questions:</div>
      {questionsLoading && (
        <Box py="10px" display="flex" justifyContent="center">
          <Spinner size={24} />
        </Box>
      )}
      {isEdit ? (
        <>
          {map(questions, ({ name: questionName }, i, arr) => (
            <Input
              key={`optionalQuestion__${i}`}
              {...$(`questions.${i}.name`, handleQuestionsChange)}
              variant="textfield"
              id={`question.${i}.name-input`}
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>{`Option Applicant Question #${i + 1}`}</span>
                  <span className="labelHint">Y/N</span>
                </Box>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => deleteQuestion(i)}
                    testID={`delete-question-button-${i + 1}`}
                  >
                    <MdDelete color="error" />
                  </IconButton>
                </InputAdornment>
              }
              withHelperText
              multiline
              inputProps={{ required: true, rowsMax: 5 }}
              FormControlProps={{ sx: { mb: i === arr.length - 1 ? '20px' : '16px' } }}
              analyticParams={{
                key: 'Job question focused',
                trigger: 'focus'
              }}
              testID={`question-input-${i + 1}`}
            />
          ))}
          <Button
            variant="outlined-primary"
            className="questionsButton"
            disabled={jobProcessing}
            onClick={addQuestion}
            testID="add-question-button"
          >
            + Add Applicant Question
          </Button>
        </>
      ) : (
        map(questions, ({ name: questionName }, i, arr) => (
          <div key={`question__${i}`}>
            <div>{`Option Applicant Question #${i + 1}`}</div>
            <div>{questionName}</div>
          </div>
        ))
      )}
    </div>
  );

  const renderAddressFields = () => (
    <div className="formItem row">
      {isEdit ? (
        <Input
          {...$('city', handleFieldChange)}
          variant="textfield"
          id="city-input"
          required
          label="City"
          labelClassName="label"
          className="rowInput col25"
          withHelperText
          analyticParams={{
            key: 'Job city focused',
            trigger: 'focus'
          }}
          testID="city-input"
        />
      ) : (
        <div className="rowInput col25">
          <div className="label">City</div>
          <div className="value">{city}</div>
        </div>
      )}
      {isEdit ? (
        <Select
          {...$('stateId', handleFieldChange)}
          {...selectProps}
          id="stateId-select"
          required
          label="State"
          className="rowInput col25"
          analyticParams={{
            key: 'Job state focused',
            trigger: 'focus'
          }}
          options={map(states?.[selectedCountry], (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`state-option-${i}`)
          }))}
          testID="stateId-input"
        />
      ) : (
        <div className="rowInput col25">
          <div className="label">State</div>
          <div className="value">{selectedStateOption.name}</div>
        </div>
      )}
      {isEdit ? (
        <Input
          {...$('zip', handleFieldChange)}
          variant="textfield"
          id="zip-input"
          required
          label="Zip Code"
          labelClassName="label"
          className="rowInput col25"
          withHelperText
          analyticParams={{
            key: 'Job zip focused',
            trigger: 'focus'
          }}
          testID="zip-input"
        />
      ) : (
        <div className="rowInput col25">
          <div className="label">Zip Code</div>
          <div className="value">{zip}</div>
        </div>
      )}
      {isEdit ? (
        <Select
          {...$('countryId', handleFieldChange)}
          {...selectProps}
          id="countryId-select"
          label="Country"
          className="rowInput col25"
          analyticParams={{
            key: 'Job country focused',
            trigger: 'focus'
          }}
          options={map(countries, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`country-option-${i}`)
          }))}
          testID="countryId-input"
        />
      ) : (
        <div className="rowInput col25">
          <div className="label">Country</div>
          <div className="value">{selectedCountry}</div>
        </div>
      )}
    </div>
  );

  const renderPayRangeFields = () => (
    <div className="formItem row">
      {isEdit ? (
        <Select
          {...$('payCurrencyId', handleFieldChange)}
          {...selectProps}
          id="payCurrencyId-select"
          label="Currency"
          className="currencyInput rowInput mobOrder-1"
          analyticParams={{
            key: 'Job currency focused',
            trigger: 'focus'
          }}
          options={map(currencyOptions, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`payCurrencyId-option-${i}`)
          }))}
          testID="payCurrencyId-input"
        />
      ) : (
        <div className="rowInput mobOrder-1">
          <div className="label">Currency</div>
          <div className="value">{selectedCurrency}</div>
        </div>
      )}
      {isEdit ? (
        <Input
          {...$('payMin', handleFieldChange)}
          variant="textfield"
          id="payMin-input"
          required
          label="Min Pay"
          labelClassName="label"
          className="rowInput mobOrder-3"
          withHelperText
          analyticParams={{
            key: 'Job min pay focused',
            trigger: 'focus'
          }}
          testID="payMin-input"
        />
      ) : (
        <div className="rowInput mobOrder-3">
          <div className="label">Min Pay</div>
          <div className="value">{payMin}</div>
        </div>
      )}
      {isEdit ? (
        <Input
          {...$('payMax', handleFieldChange)}
          variant="textfield"
          id="payMax-input"
          required
          label="Max Pay"
          labelClassName="label"
          className="rowInput mobOrder-4"
          withHelperText
          analyticParams={{
            key: 'Job max pay focused',
            trigger: 'focus'
          }}
          testID="payMax-input"
        />
      ) : (
        <div className="rowInput mobOrder-4">
          <div className="label">Max Pay</div>
          <div className="value">{payMax}</div>
        </div>
      )}
      {isEdit ? (
        <Select
          {...$('payPeriodId', handleFieldChange)}
          {...selectProps}
          id="payPeriodId-select"
          label="Frequency"
          className="rowInput mobOrder-2"
          analyticParams={{
            key: 'Job pay frequency focused',
            trigger: 'focus'
          }}
          options={map(payPeriods, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`payPeriodId-option-${i}`)
          }))}
          testID="payPeriodId-input"
        />
      ) : (
        <div className="rowInput mobOrder-2">
          <div className="label">Frequency</div>
          <div className="value">{selectedPayPeriod}</div>
        </div>
      )}
    </div>
  );

  const renderFields = () => (
    <>
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('title', handleFieldChange)}
            variant="textfield"
            id="title-input"
            required
            label="Job Title"
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'Job title focused',
              trigger: 'focus'
            }}
            testID="title-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">Job Title</div>
          <div className="value">{title}</div>
        </div>
      )}
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('location', handleFieldChange)}
            variant="textfield"
            id="location-input"
            required
            label="Interview Street Address"
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'Job location focused',
              trigger: 'focus'
            }}
            testID="location-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">Interview Street Address</div>
          <div className="value">{location}</div>
        </div>
      )}
      {renderAddressFields()}
      {renderPayRangeFields()}
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('description', handleFieldChange)}
            variant="outlined"
            id="description-input"
            multiline
            label={
              MAX_DESCR_LENGTH > 0 ? (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>Job Description*</span>
                  <span className="labelHint">{`${description.length}/${MAX_DESCR_LENGTH}`}</span>
                </Box>
              ) : (
                'Job Description*'
              )
            }
            inputProps={{
              required: true,
              rowsmax: 5,
              ...(MAX_DESCR_LENGTH > 0 && { maxLength: MAX_DESCR_LENGTH })
            }}
            labelClassName="label"
            inputClassName="textarea"
            htmlInputClassName="textareaInput"
            withHelperText
            analyticParams={{
              key: 'Job description focused',
              trigger: 'focus'
            }}
            testID="description-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">Job Description</div>
          <div className="value">{description}</div>
        </div>
      )}
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('requirements', handleFieldChange)}
            variant="outlined"
            id="requirements-input"
            multiline
            label={
              MAX_REQ_LENGTH > 0 ? (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span>Job Requirements (separate each requirement by a comma)</span>
                  <span className="labelHint">{`${requirements.length}/${MAX_REQ_LENGTH}`}</span>
                </Box>
              ) : (
                'Job requirements'
              )
            }
            inputProps={{
              rowsmax: 5,
              ...(MAX_REQ_LENGTH > 0 && { maxLength: MAX_REQ_LENGTH })
            }}
            labelClassName="label"
            inputClassName="textarea"
            htmlInputClassName="textareaInput"
            withHelperText
            analyticParams={{
              key: 'Job requirements focused',
              trigger: 'focus'
            }}
            testID="requirements-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">Job Requirements</div>
          <div className="value">{requirements}</div>
        </div>
      )}
      <div className="formItem row">
        {isEdit ? (
          <DatePicker
            {...$('endDate')}
            label="End Posting Date"
            onChange={handleDateChange('endDate')}
            disablePast
            InputComponentProps={{
              id: 'endDate-input',
              className: 'rowInput',
              labelClassName: 'label',
              analyticParams: {
                key: 'Job end posting date focused',
                trigger: 'focus'
              },
              testID: 'endDate-input'
            }}
          />
        ) : (
          <div className="rowInput">
            <div className="label">End Posting Date</div>
            <div className="value">{formattedEndDate}</div>
          </div>
        )}
        {isEdit ? (
          <DatePicker
            {...$('hireDate')}
            label="Date Needed"
            onChange={handleDateChange('hireDate')}
            disablePast
            InputComponentProps={{
              id: 'hireDate-input',
              className: 'rowInput',
              labelClassName: 'label',
              analyticParams: {
                key: 'Job hire date focused',
                trigger: 'focus'
              },
              testID: 'hireDate-input'
            }}
          />
        ) : (
          <div className="rowInput">
            <div className="label">Date Needed</div>
            <div className="value">{formattedHireDate}</div>
          </div>
        )}
        {isEdit ? (
          <Input
            {...$('applicantAmount', handleFieldChange)}
            type="number"
            variant="textfield"
            id="applicantAmount-input"
            required
            label="Maximum Applicant Amount"
            labelClassName="label"
            className="rowInput mobFullWidth"
            withHelperText
            inputProps={{ min: 1, step: 1 }}
            analyticParams={{
              key: 'Job max applicant amount focused',
              trigger: 'focus'
            }}
            testID="applicantAmount-input"
          />
        ) : (
          <div className="rowInput mobFullWidth">
            <div className="label">Maximum Applicant Amount</div>
            <div className="value">{applicantAmount}</div>
          </div>
        )}
      </div>
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('webUrl', handleFieldChange)}
            variant="textfield"
            id="webUrl-input"
            placeholder="http://example.com"
            label="ATS Link (Will send to applicant upon approval of applicant)"
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'Job web url focused',
              trigger: 'focus'
            }}
            testID="webUrl-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">ATS Link (Will send to applicant upon approval of applicant)</div>
          <div className="value">{webUrl}</div>
        </div>
      )}
      <div className="formItem">
        <div className="label">Skills</div>
        <div className="chips">
          {map(skills, ({ name: skillName, skill_id: skillId }, i) => (
            <div key={`skill__${skillId}`} className="chips__item" style={{ marginRight: 13 }}>
              {skillName}
            </div>
          ))}
        </div>
        {isEdit && (
          <Button
            variant="text"
            endIcon={<MdChevronRight color="primary" />}
            className="chipsBtn"
            onClick={toggleSkillsModal}
            testID="skills-modal-trigger-button"
          >
            Update Skills
          </Button>
        )}
        <IndustryModal
          isOpen={isIndustryModalOpen}
          formAttrs={attrs}
          onChange={handleFormChange}
          onClose={toggleIndustriesModal}
        />
      </div>
      <div className="formItem">
        <div className="label">Industries</div>
        <div className="chips">
          {map(industry, ({ name: industryName, id: industryId }, i) => (
            <div
              key={`industry__${industryId}`}
              className="chips__item"
              style={{ marginRight: 13 }}
            >
              {industryName}
            </div>
          ))}
        </div>
        {isEdit && (
          <Button
            variant="text"
            endIcon={<MdChevronRight color="primary" />}
            className="chipsBtn"
            onClick={toggleIndustriesModal}
            testID="industry-modal-trigger-button"
          >
            Update Industries
          </Button>
        )}
      </div>

      <SkillsModal
        isOpen={isSkillsModalOpen}
        formAttrs={attrs}
        onChange={handleFormChange}
        onClose={toggleSkillsModal}
      />
    </>
  );

  const renderDesktopLayout = () => (
    <>
      {renderHeader()}
      <div className="contentWrapper">
        <Box display="flex">
          <div className="layoutColumn">
            {renderImage()}
            {renderQuestions()}
          </div>
          <div className="layoutColumn">
            {renderFields()}
            {renderDisclaimer()}
          </div>
        </Box>
      </div>
    </>
  );

  const renderMobileLayout = () => (
    <>
      {renderHeader()}
      <div className="contentWrapper">
        {renderImage()}
        {renderFields()}
        {renderQuestions()}
        {renderDisclaimer()}
      </div>
    </>
  );

  return (
    <StyledRoot className="container">
      {jobLoading && (
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      )}
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
      <ConfirmationDialog
        isOpen={isConfirmModalOpen}
        title="Are you sure you want to delete job?"
        onConfirm={confirmDelete}
        onCancel={closeConfirmModal}
        onClose={closeConfirmModal}
      />
    </StyledRoot>
  );
}

export default memo(JobPosting);
