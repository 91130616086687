export const getChartData = (employees, jobTypes) => {
	const chartInfo = employees.reduce((acc, employee) => {
		if (employee.careerDevelopment) {
			const jobTypeId = employee.careerDevelopment.curJobTypeId;
			return {
				...acc,
				[jobTypeId]: acc[jobTypeId] ? acc[jobTypeId] + 1 : 1
			};
		}
		return {
			...acc,
			['N/A']: acc['N/A'] ? acc['N/A'] + 1 : 1
		};
	}, {});

	const chartLabels = Object.keys(chartInfo).map((key) =>
		!Number.isNaN(Number(key)) ? jobTypes[key].name : key
	);
	const chartData = Object.values(chartInfo);
	return { chartData, chartLabels };
};


export const getBarChartData = ({rootLabel, labels, data}) => ({
	labels,
	datasets: [
		{
			label: rootLabel,
			data,
			backgroundColor: [
				"#5D5CA9",
				"#5D5CA9",
				"#5D5CA9",
				"#5D5CA9",
				"#5D5CA9",
				"#5D5CA9"
			]
		}
	]
});

export const calculateAverageTenure = months =>
	(months.reduce((acc, cur) => acc + cur, 0) / months.length).toFixed(2);
