import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box } from 'components';
import { Spinner } from 'components/shared';
import { MdLocalFireDepartment } from 'components/icons';
import { getProfileId } from 'utils';
import growApi from '../../api';
import { makeQueryFetch } from '../../api/util';
import EmployerGrowContext from '../../EmployerGrowContext';

const { EMPLOYER_COMPETITION_BREAKDOWN, EMPLOYER_COMPETITION_BREAKDOWN_ADMIN } = growApi.query;

function Competition(props) {
  const { isAdmin } = useContext(EmployerGrowContext);
  const client = useApolloClient();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [scores, setScores] = useState({ marketPositionScore: 0, jobPostActivityScore: 0 });
  const [topJobs, setTopJobs] = useState([]);

  const fetchData = async () => {
    const profileId = Number(getProfileId());

    if (profileId) {
      const data = await makeQueryFetch(
        {
          query: isAdmin ? EMPLOYER_COMPETITION_BREAKDOWN_ADMIN : EMPLOYER_COMPETITION_BREAKDOWN,
          path: isAdmin ? 'getChartsScoreAdmin' : 'getChartsScore',
          variables: { employerProfileProfileId: profileId }
        },
        client
      );
      const { marketPositionScore, jobPostActivityScore, hotJobs } = data || {};

      if (hotJobs) {
        const jobs = JSON.parse(hotJobs);
        setTopJobs(jobs);
      }

      setScores({ marketPositionScore, jobPostActivityScore });
      setIsDataLoading(false);
    } else {
      throw new Error('getChartsScore error: profileId is not defined');
    }
  };

  useEffect(() => {
    if (isAdmin !== null) fetchData();
  }, [isAdmin]);

  return (
    <div className="chart">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Competition Breakdown</h4>
      <div className="competitionItem">
        <div className="competitionScoreText">Market Position Score</div>
        <div>
          <MdLocalFireDepartment className="competitionScoreFireIcon" />
          <span className="competitionScoreValue">{`${scores.marketPositionScore}/100`}</span>
        </div>
      </div>
      {/* <div className="competitionItem">
        <div className="competitionScoreText">Pay Comparison Score</div>
        <div>
          <span className="competitionScoreValue">100/100</span>
        </div>
      </div> */}
      <div className="competitionItem">
        <div className="competitionScoreText">Job Post Activity Score</div>
        <div>
          <span className="competitionScoreValue">{`${scores.jobPostActivityScore}/100`}</span>
        </div>
      </div>
      <Box mx="auto" display="flex" alignItems="center">
        <span className="competitionScoreText">Hot Jobs</span>
        <MdLocalFireDepartment className="competitionScoreFireIcon" />
      </Box>
      <div className="competitionHotJobs">{topJobs.map((o) => o['jobs.title']).join(' | ')}</div>
    </div>
  );
}

export default Competition;
