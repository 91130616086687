import React from 'react';
import { SvgIcon } from 'components';

function MdExpandLess(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>
    </SvgIcon>
  );
}

export default MdExpandLess;
