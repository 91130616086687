const teamStructureBase = ({ theme }) => ({
  '& .teemStructureContainer': {
    position: 'relative'
  },
  '& .notYetAvailable': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    alignSelf: 'center',
    margin: '0 auto',
    textAlign: 'center',
    alignItems: 'center'
  },
  '& .header': {
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  '& .headerButtonContainer': {
    width: 75,
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .headerText': {
    fontSize: 16,
    borderBottom: '5px solid #F1F2F2'
  },
  '& .chartContainerNames': {
    display: 'grid',
    flexDirection: 'row',
    backgroundColor: '#F1F2F2',
    boxShadow: 'inset 0 -3px 4px rgb(0 0 0 / 5%)',
    gridTemplateColumns: 'auto 110px',
    gridTemplateRows: 'auto'
  },
  '& .chartContainerNamesParent': {
    overflow: 'scroll',
    display: 'flex'
  },
  '& .navigateCharts': { display: 'flex', background: 'white' },
  '& .chartColumnsContainer': {
    display: 'grid',
    gridTemplateColumns: 'auto 75px',
    gridTemplateRows: 325,
    borderBottom: '4px solid #e4e4e4'
  },
  '& .chartColumnsParent': {
    display: 'flex',
    position: 'relative',
    overflowX: 'scroll'
  },
  '& .chartWelcomeContainer': {
    alignSelf: 'center',
    width: '100%',
    textAlign: 'center'
  },
  '& .chartWelcomeTitle': {
    color: theme.palette.primary.main,
    marginBottom: 10
  },
  '& .chartWelcomeIcon': {
    position: 'relative',
    top: 8
  },
  '& .employeeCardContainer': {
    position: 'relative',
    userSelect: 'none',
    borderWidth: 2,
    borderStyle: 'solid',
    backgroundColor: 'white',
    padding: 10,
    margin: 5,
    marginBottom: 13,
    display: 'flex',
    // alignItems: 'center',
    borderRadius: 5
  },
  '& .employeeCardDeleteBtn': {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 18,
    height: 18,
    padding: 0,
    fontSize: 14,
    color: '#FFF',
    backgroundColor: theme.palette.primary.main
  },
  '& .cardProfileImage': {
    height: 40,
    width: 40,
    minWidth: 40,
    border: '2px solid #4743A2',
    borderRadius: 50,
    backgroundColor: '#ECECF5',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginRight: 10,
    overflow: 'auto',
    objectFit: 'cover'
  },
  '& .cardEmployeeName': { fontWeight: 800 },
  '& .cardEmployeeGrowStats': { fontSize: 12 },
  '& .cardEmployeeCurrentPos': {
    color: theme.palette.moreColors.grey_1
  },
  '& .cardEmployeeFuturePos': {
    color: theme.palette.primary.light
  },
  '& .cardEmployeeNA': {
    color: theme.palette.moreColors.grey_1
  },
  '& .chartToolbarContainer': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    borderLeft: '1px solid #e4e4e4',
    height: 'calc(100% - 10px)',
    padding: 8
  },
  '& .chartToolbarLink': { color: theme.palette.primary.main },
  '& .chartColumnContainer': {
    borderLeft: '1px solid #e4e4e4',
    width: 240,
    minWidth: 240,
    padding: '0 5px',
    display: 'flex',
    flexDirection: 'column',
    height: 320,
    overflow: 'scroll'
  },
  // chart column
  '& .columnContainer': {
    height: '100%'
  },
  '& .columnContainerName': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    background: 'white'
  },
  '& .inviteJobPopupPaper': {
    minWidth: 253,
    maxWidth: 263,
    overflow: 'hidden'
  },
  '& .inviteJobPopupBody': {
    maxHeight: 168,
    overflow: 'auto'
  },
  '& .inviteJobPopupBtn': {
    width: '100%',
    height: 55,
    padding: '9px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
  },
  '& .inviteJobImg': {
    minWidth: 38,
    height: 38,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 3,
    backgroundColor: '#F6F5FA'
  },
  '& .inviteJobName': {
    textAlign: 'left',
    marginLeft: 13,
    lineHeight: '15px',
    fontSize: 14,
    fontWeight: 'bold'
  },
  '& .invitedMessage': {
    maxWidth: 240,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '17px',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
});

const tenureChart = () => ({
  '& .tenureChartContainer': {
    position: 'relative',
    padding: '18px 22px',
    border: '1px solid #e4e4e4',
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  '& .tenureChartParent': {
    maxWidth: 'calc(1024px / 3 - 100)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0.875)'
  },
  '& .tenureChartCanvas': { width: '100%', height: '100%' }
});

const moodChart = ({ theme }) => ({
  '& .moodChartContainer': {
    position: 'relative',
    padding: '18px 22px 0',
    border: '1px solid #e4e4e4',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  '& .moodChartParent': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20
  },
  '& .moodChartAlignment': { textAlign: 'center' },
  '& .moodChartEmojiParent': { fontSize: 30 },
  '& .moodChartEmoji': { paddingRight: 15 },
  '& .moodChartReasonsContainer': { paddingTop: 10 },
  '& .moodChartReasonsTitle': {
    color: theme.palette.moreColors.grey_1,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottom: '1px solid #F1F2F2'
  },
  '& .moodChartReason': { color: theme.palette.primary.light }
});

const companyBreakdown = ({ theme }) => ({
  '& .companyBreakdownContainer': {
    border: '1px solid #e4e4e4',
    borderRadius: 12,
    display: 'grid',
    gridTemplateRows: '40px auto',
    gridTemplateColumns: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative'
  },
  '& .companyBreakdownTitle': { textAlign: 'center', padding: '1.5rem 0 .5rem' },
  '& .companyBreakdownChartContainer': {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '51% 100px',
    justifyContent: 'center',
    position: 'relative',
    gridColumnGap: 25
  },
  '& .companyBreakdownChartPosition': { position: 'relative' },
  '& .companyBreakdownChartPercContainer': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  '& .companyBreakdownPercText1': { fontSize: 35 },
  '& .companyBreakdownPercText2': { fontSize: 14 },
  '& .companyBreakdownPercText3': { fontSize: 12 },
  '& .companyBreakdownLegendContainer': { alignSelf: 'center', justifySelf: 'flex-end' },
  '& .companyBreakdownLabelParent': { display: 'flex', alignItems: 'center' },
  '& .companyBreakdownLegendColor': { minHeight: 10, minWidth: 10, marginRight: 10 }
});

const jobPostingScores = ({ theme }) => ({
  '& .jobPostingScoresContainer': {
    position: 'relative',
    padding: '22px 46px 25px',
    border: `1px solid ${theme.palette.moreColors.grey_4}`,
    borderRadius: 12
  },
  '& .jobPostingScoresTitle': {
    height: 'auto',
    marginBottom: 24,
    padding: 0,
    textAlign: 'center'
  },
  '& .jobPostingScoresRow': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 12
    }
  },
  '& .jobPostingScoresLabel': {
    flex: 1,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.moreColors.grey_5
  },
  '& .jobPostingScoresValue': {
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: theme.palette.moreColors.grey_5
  },
  '& .jobPostingScoresIcon': {
    marginRight: 33,
    fontSize: 21
  }
});

const jobStatistics = ({ theme }) => ({
  '& .jobStatisticsContainer': {
    position: 'relative',
    gridArea: 'doubleColArea',
    padding: '18px 22px 35px',
    border: `1px solid ${theme.palette.moreColors.grey_4}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  '& .jobStatisticsHeader': {
    marginBottom: 27,
    paddingLeft: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .jobStatisticsTitle': {
    height: 'auto',
    marginBottom: 0,
    padding: 0
  },
  '& .sortControllersContainer': {
    margin: 0,
    padding: 0,
    border: 0,
    display: 'flex',
    alignItems: 'center'
  },
  '& .sortControllersContainerTitle': {
    marginRight: 29,
    fontSize: 10,
    color: theme.palette.moreColors.grey_3
  },
  '& .sortController': {
    marginRight: 8,
    padding: '6px 19px 7px',
    borderRadius: 19,
    color: theme.palette.moreColors.grey_3,
    backgroundColor: 'transparent',
    '&.checked': {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main
    }
  },
  '& .sortControllerLabel': {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 1.1
  },
  '& .jobStatisticsTableContainer': {
    flex: 1,
    overflow: 'auto'
  },
  '& .jobStatisticsTable': {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    fontSize: 12,
    color: '#414042',
    '& > thead > tr > th': {
      position: 'sticky',
      top: 0,
      paddingBottom: 18,
      fontSize: 8,
      color: theme.palette.moreColors.grey_5,
      backgroundColor: '#FFF'
    },
    '& > tbody th, & > tbody td': {
      paddingBottom: 13
    }
  },
  '& .rowHeadCell': {
    fontSize: 13
  },
  '& .accentedCell': {
    fontSize: 10,
    color: theme.palette.moreColors.grey_5
  }
});

const hiringRate = ({ theme }) => ({
  '& .hiringRateDescription': {
    maxWidth: 230,
    margin: '0 auto 20px',
    fontSize: 11,
    lineHeight: '13px',
    textAlign: 'center',
    color: '#1A1A1A'
  },
  '& .hiringRateValue': {
    textAlign: 'center',
    fontSize: 68,
    color: '#584F4E'
  }
});

const equityScore = ({ theme }) => ({
  '& .equityScoreContainerWrapper': {
    height: 176,
    width: 176,
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  '& .equityScoreScoreProgress': {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  '& .equityScoreScoreDetails': {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)'
  },
  '& .equityScoreScoreProgressGradient': {
    position: 'absolute',
    width: '98%',
    height: '98%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background:
      'linear-gradient(to bottom, rgba(135,135,249,0.09) 0%,rgba(135,135,249,0.09) 1%,rgba(135,135,249,0) 100%)',
    borderRadius: '50%',
    zIndex: '-1'
  },
  '& .equityScoreScoreTitle': { fontSize: 62, color: theme.palette.primary.main },
  '& .equityScoreScoreIcon': { position: 'absolute', top: 12 },
  '& .equityScoreScoreLabel': { fontWeight: 800, fontSize: 13, color: theme.palette.primary.text },
  '& .equityScoreScoreLevel': {
    position: 'absolute',
    bottom: 15,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '5px 20px',
    backgroundColor: '#7B6CB1',
    textTransform: 'capitalize',
    color: 'white',
    borderRadius: 10,
    fontSize: 10
  }
});

const competition = ({ theme }) => ({
  '& .competitionItem': {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .competitionScoreText': {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: '#666666'
  },
  '& .competitionScoreValue': {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1A1A1A'
  },
  '& .competitionScoreFireIcon': {
    fontSize: 16,
    color: '#C2000B'
  },
  '& .competitionHotJobs': {
    maxWidth: 230,
    margin: '0 auto',
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.primary.light
  }
});

const charts = ({ theme }) => ({
  '& .chartsContainer': {
    display: 'grid',
    marginTop: '1rem',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    gridTemplateColumns: 'auto auto auto',
    gridTemplateRows: '273px 250px',
    gridTemplateAreas: '". doubleColArea doubleColArea" ". . ." ". . ."'
  },
  '& .chartLoaderContainer': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)'
  },
  '& .chart': {
    position: 'relative',
    padding: '18px 22px',
    border: `1px solid ${theme.palette.moreColors.grey_4}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column'
  },
  '& .chartTitle': {
    marginBottom: 30,
    textAlign: 'center',
    color: '#414042'
  },
  ...tenureChart({ theme }),
  ...moodChart({ theme }),
  ...companyBreakdown({ theme }),
  ...jobPostingScores({ theme }),
  ...jobStatistics({ theme }),
  ...hiringRate({ theme }),
  ...equityScore({ theme }),
  ...competition({ theme })
});

const columnGap = 15;
const columns = 4;
const columnWidth = 100 / columns; // percentage(5/100);
const column = `calc(${columnWidth}% - ${columnGap + columnGap / columns}px)`;

const stores = ({ theme }) => ({
  '& .storesTopSection': {
    padding: '20px 0',
    borderBottom: '3px solid #f1f2f2',
    '& h1': {
      fontSize: 18,
      fontWeight: 100,
      color: theme.palette.moreColors.grey_6
    }
  },
  '& .storesTopBar': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    '& .storesTitle': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 310,
      '& svg': {
        maxWidth: 22,
        width: 22
      }
    }
  },
  '& .storesRightBar': {
    display: 'flex',
    '& button': {
      marginRight: 10,
      border: 'none',
      backgroundColor: '#FFF',
      color: '#F37C7C',
      cursor: 'pointer'
    }
  },
  '& .storesSearch': {
    position: 'relative',
    display: 'flex',
    '& label': {
      fontSize: 12,
      position: 'absolute',
      left: 5,
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '& input': {
      padding: 5,
      paddingLeft: 60,
      width: 200,
      border: 'none',
      outline: 'none',
      borderBottom: `1px solid ${theme.palette.moreColors.grey_2}`
    },
    '& button:last-of-type': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      height: 27,
      width: 27,
      color: '#FFF',
      outline: 'none',
      border: 'none'
    }
  },
  '& .storesPagination': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
    '& .paginationButtonContainer': {
      display: 'flex',
      position: 'relative',
      '& .activePage': {
        color: '#FFF'
      },
      '& .activePageOverlay': {
        display: 'block',
        height: 30,
        width: 30,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        position: 'absolute',
        left: 0,
        zIndex: -1,
        transition: 'transform 0.2s ease'
      }
    },
    '& > div:not(.paginationButtonContainer), & .paginationButtonContainer > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px 10px',
      width: 30,
      height: 30,
      borderRadius: 50,
      marginRight: 10,
      color: theme.palette.moreColors.grey_6,
      lineHeight: 0,
      fontSize: 12,
      cursor: 'pointer',
      transition: 'color 0.2s ease',
      '&.active': {
        color: '#FFF'
      },
      '&.forwardBackButton': {
        color: theme.palette.primary.main,
        fontSize: 17,
        fontWeight: 800
      },
      '&.disabled': {
        opacity: 0.7,
        pointerEvents: 'none',
        color: '#808080'
      }
    }
  },
  '& .pills': {
    content: `"${columnWidth}%"`,
    display: 'grid',
    gridTemplateColumns: `${column} ${column} ${column} ${column}`,
    gridTemplateRows: 'auto auto auto',
    columnGap,
    rowGap: columnGap,
    justifyContent: 'center',
    position: 'relative',
    '& .pill': {
      padding: '15px 10px',
      backgroundColor: '#FFF',
      borderRadius: 7.5,
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.095)',
      position: 'relative',
      overflow: 'hidden',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        zIndex: 1,
        borderRadius: 7.5
      },
      '&::after': {
        transition: 'transform 0.2s ease-out',
        backgroundColor: theme.palette.primary.main,
        transform: 'translate(-50%, -50%) scale(0.9)'
      },
      '&::before': {
        width: 'calc(100% - 5px)',
        height: 'calc(100% - 5px)',
        backgroundColor: '#FFF',
        zIndex: 2,
        borderRadius: 6
      },
      '&.active': {
        '&::after': {
          transform: 'translate(-50%, -50%) scale(1)'
        }
      },
      '&.empty': {
        boxShadow: 'none'
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 3
      }
    }
  },
  '& .pillsLoaderContainer': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)'
  },
  '& .pillStore': {
    width: 'calc(100% - 60px)',
    paddingLeft: 15,
    '& .pillName': {
      fontSize: 11,
      letterSpacing: 0.5,
      lineHeight: 1,
      fontWeight: 800,
      paddingBottom: 7.5
    },
    '& .pillApplicants': {
      color: theme.palette.primary.light,
      fontWeight: 100,
      fontSize: 10,
      paddingBottom: 7.5
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        borderRight: '1px solid #ececf5',
        padding: '0 7px',
        display: 'flex',
        width: 25,
        height: 15,
        fontSize: 12,
        boxSizing: 'content-box',
        '&:last-of-type': {
          borderRight: 'none',
          '& svg': {
            maxWidth: 4
          }
        },
        '&:first-of-type': {
          paddingLeft: 0
        }
      },
      '& svg': {
        maxWidth: 12,
        marginLeft: 5,
        width: 12
      }
    }
  },
  '& .pillImg': {
    width: 45,
    height: 45,
    display: 'block',
    borderRadius: 50,
    border: `2px solid ${theme.palette.primary.main}`,
    objectFit: 'cover'
  },
  '& .storesFilterModal__paper': {
    position: 'absolute',
    left: 0,
    top: 0,
    maxWidth: 300,
    minHeight: 'calc(100vh - 22px)',
    margin: 0,
    flexDirection: 'row'
  },
  '& .storesFilterContainer': {
    width: '100%',
    padding: 16
  }
});

export default ({ theme }) => ({
  ...teamStructureBase({ theme }),
  ...charts({ theme }),
  ...stores({ theme })
});
