import { defValidation } from 're-use-form';
import { isNumber, isString, isEmpty, isInteger } from 'lodash';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import formatDate from 'date-fns/format';
import { isUSPhone, trimStr, getUrlRegex, unformatPhone } from 'utils';

const isEmptyValue = (val) => isEmpty(trimStr(val));

const USZipPattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
const CanadaZipPattern = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\s*?[0-9][A-Z][0-9]$/;

defValidation('presence', (value, { message, allowEmpty = true }) => {
  if (allowEmpty !== false ? !value : isEmptyValue(value)) {
    return message || 'Cannot be blank';
  }
});

defValidation(
  'date',
  (
    value,
    {
      message,
      messages = {},
      earliest,
      earliestFormat = 'MM-dd-yyyy',
      latest,
      latestFormat = 'MM-dd-yyyy'
    }
  ) => {
    if (!value) return '';
    if (!isDate(value)) return message || 'Invalid date';
    if (!isValid(value)) return messages.notValid || message || 'Invalid date';
    if (earliest && isBefore(value, earliest)) {
      return (
        messages.tooEarly ||
        message ||
        `Must not be earlier than ${formatDate(earliest, earliestFormat)}`
      );
    }
    if (latest && isAfter(value, latest)) {
      return (
        messages.tooLate || message || `Must not be later than ${formatDate(latest, latestFormat)}`
      );
    }
    return '';
  }
);

defValidation('email', (value, { message }) => {
  if (!value) return;

  try {
    if (!/.+@.+/.test(value) || value.indexOf(' ') >= 0) {
      return message || 'Should be a valid email address';
    }
  } catch (error) {
    return 'Invalid email format';
  }
});

defValidation('format', (value, { pattern, message }) => {
  if (!value) return;

  if (!pattern.test(value)) {
    return message || 'Invalid format';
  }
});

defValidation('maxLength', (value, { max = 100, message }) => {
  if (value.length > max) {
    return message || `Maximum characters are ${max}`;
  }
});

defValidation('minLength', (value, { min = 1, message }) => {
  if (value.length < min) {
    return message || `Minimum characters are ${min}`;
  }
});

defValidation(
  'numericality',
  (
    value,
    {
      onlyInteger = true,
      greaterThan,
      greaterThanOrEqual,
      lessThan,
      lessThanOrEqual,
      leadingZeros = false,
      numberOfDecimals = 0,
      message
    }
  ) => {
    const {
      notValid = '',
      notNumber = '',
      notInteger = '',
      notGreaterThan = '',
      notGreaterThanOrEqual = '',
      notLessThan = '',
      notLessThanOrEqual = ''
    } = message || {};
    let num = value;

    if (num) {
      let pattern = `^-?(0|[${leadingZeros ? 0 : 1}-9]\\d*)`;
      if (!onlyInteger) {
        pattern += `(\\.\\d${numberOfDecimals > 0 ? `{0,${numberOfDecimals}}` : '+'})?`;
      }
      pattern += '$';
      if (!new RegExp(pattern).test(num)) return notValid || 'Must be a valid number';
      if (isString(num) && !isEmpty(num)) num = +num;
      if (!isNumber(num)) return notNumber || 'Is not a number';
      if (onlyInteger && !isInteger(num)) return notInteger || 'Must be an integer';
      if (typeof greaterThan !== 'undefined' && num <= greaterThan)
        return notGreaterThan || `Must be greater than ${greaterThan}`;
      if (typeof greaterThanOrEqual !== 'undefined' && num < greaterThanOrEqual)
        return notGreaterThanOrEqual || `Must be greater than or equal to ${greaterThanOrEqual}`;
      if (typeof lessThan !== 'undefined' && num >= lessThan)
        return notLessThan || `Must be less than ${lessThan}`;
      if (typeof lessThanOrEqual !== 'undefined' && num > lessThanOrEqual)
        return notLessThanOrEqual || `Must be less than or equal to ${lessThanOrEqual}`;
    }
  }
);

defValidation('phone', (value) => {
  if (!value) return;

  if (!isUSPhone(value)) {
    return 'Please enter a valid phone number';
  }
});

defValidation('url', (value, { message, protocol = true }) => {
  // const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (!value) return;
  if (!getUrlRegex({ protocol }).test(value)) return message || 'Invalid URL';
});

defValidation('zip', (value, { message = '', countryCode = '' }) => {
  // use https://www.npmjs.com/package/postcode-validator if countries list will expand
  if (!value) return;

  if ((countryCode === 'US' || countryCode === 'USA') && !USZipPattern.test(value)) {
    return message || 'Please enter a valid US ZIP Code';
  }

  if (countryCode === 'CA' && !CanadaZipPattern.test(value)) {
    return message || 'Please enter a valid Canada ZIP Code';
  }

  if (!(USZipPattern.test(value) || CanadaZipPattern.test(value))) {
    return message || 'Please enter a valid US or Canada ZIP Code';
  }
});

export * from 're-use-form';
