import React from 'react';
import PT from 'prop-types';
import { styled, useMediaQuery } from 'components';
import { IconButton } from 'components/shared';
import { MdPersonOutline, MdPerson } from 'components/icons';
import { getRoutes } from 'utils';

const MobileIconButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));

const ROUTES = getRoutes();

function ProfileLink(props) {
  const { userRole } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  return isDesktop ? (
    <IconButton
      isRouterLink
      to={ROUTES[userRole].profile}
      variant="outlined"
      color="primary"
      aria-label="profile"
      className="headerElement"
      testID={`${userRole}-profile-button`}
    >
      <MdPersonOutline />
    </IconButton>
  ) : (
    <MobileIconButton
      isRouterLink
      to={ROUTES[userRole].profile}
      edge="start"
      color="primary"
      aria-label="profile"
      className="headerElement"
      testID={`${userRole}-profile-button`}
    >
      <MdPerson />
    </MobileIconButton>
  );
}

ProfileLink.propTypes = {
  userRole: PT.string.isRequired
};

export default ProfileLink;
