import React from 'react';
import PT from 'prop-types';
import format from 'date-fns/format';
import { useRestrictions, useCommonUI } from 'hooks';
import { Box, styled } from 'components';
import { ButtonBase, IconButton } from 'components/shared';
import { MdClose, MdCheck, MdStarBorder, MdMailOutline } from 'components/icons';
import styles from 'styles/ApplicantsDashboard/ListCardItem';
// import navIcon from 'assets/img/navigation_icon.png';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import {
  STARRED_TAB,
  QH_MATCHES_TAB,
  APPROVED_TAB,
  REJECTED_TAB,
  APPLICANTS_TAB,
  ACCEPTED_STATUS,
  REJECTED_STATUS,
  CHAT_MESSAGE_TEMPLATE
} from './data';
import { getCandidateActionName, getApplicantUserId, getApplicantProfile } from './utils';

const StyledRoot = styled('div')(styles);

function ListCardItem(props) {
  const {
    approveStatus,
    data,
    jobId,
    onSelect,
    onAccept,
    onReject,
    onStar,
    onRejectFromChatSuccess,
    starred: starredProp,
    type,
    webUrl,
    withActions
  } = props;
  const { restrictions = {} } = useRestrictions();
  const { openChatModal } = useCommonUI();
  const canManageEmployee = restrictions.allowEmployeeActions;
  const actionNames = getCandidateActionName(webUrl);

  // const withQuestions = type === APPLICANTS_TAB;

  const { createdAt, jobs, starredJob, approvedJob, passedJob } = data;
  const userId = getApplicantUserId(data);
  const profile = getApplicantProfile(data) || {};
  const { imageUrl = '', motto = '', name = '' } = profile;
  // const answers = jobs?.questions || [];
  const isStarred = starredProp || type === STARRED_TAB || starredJob;
  const isRejected = type === REJECTED_TAB || approveStatus === REJECTED_STATUS || passedJob;
  const isApproved = type === APPROVED_TAB || approveStatus === ACCEPTED_STATUS || approvedJob;
  const withApproveActions =
    type === APPLICANTS_TAB || type === QH_MATCHES_TAB || type === STARRED_TAB;
  const showRejectButton = !(isRejected || isApproved) && withApproveActions;
  const showApproveButton = !(isRejected || isApproved) && withApproveActions;
  const showStarButton =
    type === STARRED_TAB || (!(isRejected || isApproved) && withApproveActions);

  const openChat = () => {
    openChatModal({
      dashBoardType: 'employer',
      variant: 'chat-only',
      jobId,
      candidateId: userId,
      onApplicantReject: onRejectFromChatSuccess,
      suggestMessageTemplate: CHAT_MESSAGE_TEMPLATE
    });
  };

  const renderApplyDate = () => format(new Date(createdAt), "'Applied' MM.dd");

  const iconButtonProps = {
    variant: 'outlined',
    color: 'primary',
    withTooltip: true,
    withHeaderActions: withApproveActions
  };

  return (
    <StyledRoot className="container">
      <div className="innerContainer withActions">
        <ButtonBase className="clickablePart" onClick={() => onSelect(data)}>
          <div className="avatar">
            <img src={imageUrl || defaultEmployeeImg} alt="" />
          </div>
          <Box width="100%" p="22px 18px">
            <h3 className="name">{name}</h3>
            <div className="userInfoContainer">
              <Box textAlign="left">
                {!isRejected && type !== QH_MATCHES_TAB && createdAt && renderApplyDate(createdAt)}
                {/* <img className="navIcon" src={navIcon} alt="" />
                <span /> */}
                {/* @todo: add location */}
              </Box>
            </div>
            <div className="motto">{motto}</div>
          </Box>
        </ButtonBase>
        {withActions && (
          <div className="actionsContainer">
            <div>
              <IconButton
                {...iconButtonProps}
                aria-label="chat"
                withTooltip={false}
                sx={{ padding: '7px' }}
                onClick={() => openChat(data)}
              >
                <MdMailOutline />
              </IconButton>
              {showRejectButton && (
                <IconButton
                  {...iconButtonProps}
                  aria-label={actionNames.reject.title}
                  toolTipProps={{ title: actionNames.reject.title }}
                  sx={{ marginLeft: '12px', padding: '7px' }}
                  disabled={!canManageEmployee}
                  onClick={() => onReject(data)}
                >
                  <MdClose />
                </IconButton>
              )}
              {showApproveButton && (
                <IconButton
                  {...iconButtonProps}
                  aria-label={actionNames.accept.title}
                  toolTipProps={{ title: actionNames.accept.title }}
                  sx={{ marginLeft: '12px', padding: '7px' }}
                  disabled={!canManageEmployee}
                  onClick={() => onAccept(data)}
                >
                  <MdCheck />
                </IconButton>
              )}
              {showStarButton && (
                <IconButton
                  {...iconButtonProps}
                  aria-label={isStarred ? 'unstar' : actionNames.star.title}
                  toolTipProps={{ title: isStarred ? 'Unstar' : actionNames.star.title }}
                  sx={{ marginLeft: '12px', padding: '7px' }}
                  disabled={!canManageEmployee}
                  onClick={() => onStar(data, !isStarred)}
                >
                  <MdStarBorder />
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
    </StyledRoot>
  );
}

ListCardItem.propTypes = {
  approveStatus: PT.oneOf(['', ACCEPTED_STATUS, REJECTED_STATUS]),
  data: PT.objectOf(PT.any).isRequired,
  jobId: PT.number.isRequired,
  onAccept: PT.func,
  onReject: PT.func,
  onStar: PT.func,
  onSelect: PT.func.isRequired,
  onRejectFromChatSuccess: PT.func,
  starred: PT.bool,
  type: PT.oneOf(['', STARRED_TAB, QH_MATCHES_TAB, APPROVED_TAB, REJECTED_TAB, APPLICANTS_TAB]),
  webUrl: PT.string,
  withActions: PT.bool
};

ListCardItem.defaultProps = {
  approveStatus: '',
  onAccept: () => {},
  onReject: () => {},
  onStar: () => {},
  onRejectFromChatSuccess: () => {},
  starred: false,
  type: '',
  webUrl: '',
  withActions: true
};

export default ListCardItem;
