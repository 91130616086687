import { getUserId } from 'utils';
import { client } from '../graphql';
import { GET_USER_PROFILE } from '../queries';

export default async function fetchEmployeeProfile(params) {
  const userId = getUserId();
  const {
    client: clientParam,
    cache = true,
    onError,
    queryParams = {},
    variables: variablesParam = {}
  } = params || {};
  const clientToUse = clientParam || client;

  if (variablesParam?.userId || userId) {
    const variables = { userId: Number(userId), ...variablesParam };
    const queryObject = {
      query: GET_USER_PROFILE,
      fetchPolicy: 'no-cache', // do not use cache-and-network
      errorPolicy: 'all',
      variables,
      ...queryParams
    };
    const isNoCachePolicy = queryObject.fetchPolicy === 'no-cache';
    try {
      const response = await clientToUse.query(queryObject);
      if (response) {
        const result = response?.data?.employeeProfile;
        if (result && isNoCachePolicy) {
          if (cache) {
            const ref = clientToUse.writeQuery({
              query: GET_USER_PROFILE,
              data: { employeeProfile: { ...result } },
              variables
            });
          }
        }
        return [result, { response }];
      }
      return [undefined, { response: {} }];
    } catch (error) {
      if (onError) onError(error);
      return [undefined, { response: {} }];
    }
  } else {
    throw new Error('Missed variables');
  }
}
