import { gql } from '@apollo/client';

export const GET_JOB = gql`
  query job($jobId: Int!) {
    job(jobId: $jobId) {
      active
      approved
      approvedJob
      applicantAmount
      createdAt
      countryId
      city
      draft
      description
      employerProfileProfileId
      employerProfile {
        name
        imageUrl
        profile_id
      }
      endDate
      hireDate
      id
      industry {
        name
        id: industry_id
      }
      isSchedule
      imageUrl
      location
      passed
      passedJob
      payRange
      payMin
      payMax
      payCurrencyId
      payPeriodId
      requirements
      scheduleStatusCount
      starred
      starredJob
      stateId
      skills {
        skill_id
        name
      }
      title
      updatedAt
      userInterviewDate
      webUrl
      zip
    }
  }
`;

export const GET_PUBLIC_JOB = gql`
  query publicJob($jobId: Int!) {
    publicJob(jobId: $jobId) {
      active
      createdAt
      countryId
      city
      description
      employerProfile {
        name
        imageUrl
      }
      hireDate
      id
      isSchedule
      imageUrl
      location
      payRange
      payMin
      payMax
      payCurrencyId
      payPeriodId
      requirements
      skills {
        skill_id
        name
      }
      stateId
      title
      updatedAt
      zip
    }
  }
`;

export const GET_QUESTIONS = gql`
  query questions($jobsJobsId: Int) {
    questions(jobsJobsId: $jobsJobsId) {
      id
      questions {
        name
        questions_id
      }
    }
  }
`;

export const CLOSE_JOB_TO_JOB_BY_JOB_ID = gql`
  query closeJobToJobByJobId($jobId: Int) {
    closeJobToJobByJobId(jobId: $jobId) {
      id
      jobId
      closeJob {
        rating
        feedback
        hiredEmployeeProfileId
        closeReasonId
        hiringPlatformId
      }
    }
  }
`;

export const CLOSE_REASONS = gql`
  query closeReasons {
    closeReasons {
      id
      name
    }
  }
`;

export const HIRING_PLATFORMS = gql`
  query hiringPlatforms {
    hiringPlatforms {
      id
      name
    }
  }
`;
