import isEmpty from 'lodash/isEmpty';
import numberToUsdFormatter from './numberToUsdFormatter';

// export const numberToUsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export default function getEmployerSubscriptionInfo(employerProfile = {}) {
  const {
    subscriptionPlan = null,
    newSubscriptionPlan = null,
    trialTimePlan,
    showPlanModal = 'false'
  } = employerProfile || {};
  const {
    autorenew,
    endDate,
    id: planId,
    name: plan = '',
    price = 0,
    paid = false,
    paused: isPlanPaused = false,
    planEmployerId: currentPlanId,
    status = false
  } = subscriptionPlan || {};
  const {
    name: newPlan = '',
    price: newPlanPrice = 0,
    paid: newPlanPaid = false,
    planEmployerId: nextPlanEmployerId,
    status: newPlanStatus = false,
    id: newPlanId
  } = newSubscriptionPlan || {};
  const isProfileEmpty = isEmpty(employerProfile);
  const isUpgradeRequired =
    !isProfileEmpty && !trialTimePlan && !subscriptionPlan && !newSubscriptionPlan;
  // const isPaymentRequired = (status && !paid) || (newPlanStatus && !newPlanPaid);
  const isPaymentRequired =
    (newSubscriptionPlan && newPlanId && !newPlanPaid && currentPlanId !== nextPlanEmployerId) ||
    (showPlanModal === 'true' && newPlanId);
  const isFreePlan =
    /* trialTimePlan || */ !subscriptionPlan &&
    !newSubscriptionPlan; /*  || (!trialTimePlan && !paid && !newPlanPaid) */
  const isTrialExpired = !trialTimePlan;
  const isPlanCancelable = !trialTimePlan && paid && autorenew;

  const nextPlan = newPlan;
  const nextPlanPrice = numberToUsdFormatter.format(
    newPlanPrice / 100
  ); /* Example: price = 1000 is equal to $10 */
  const nextPlanStrippedPrice = newPlanPrice;

  let planName = isFreePlan || isPlanPaused ? 'No Plan' : plan;
  let planPrice = isFreePlan ? null : numberToUsdFormatter.format(price / 100);
  const planStrippedPrice = isFreePlan ? null : price;
  // if (newSubscriptionPlan && newPlanStatus && newPlanPaid) {
  //   planName = newPlan;
  //   planPrice = newPlanPrice;
  // }
  // const trialStatus = isFreePlan
  //   ? (trialTimePlan ? `Free Trial Ends in ${trialTimePlan} day${trialTimePlan > 1 ? 's' : ''}` : 'Free Trial has expired') : '';

  const trialStatus = isFreePlan ? (trialTimePlan ? 'Contact us' : '') : '';

  const planIdToPayFor = newPlanId; // not base plan id

  return {
    autorenew,
    endDate,
    isUpgradeRequired,
    isPaymentRequired,
    isFreePlan,
    isTrialExpired,
    isPlanPaused,
    isPlanCancelable,
    paid,
    planId, // base plan id
    planName,
    planPrice,
    planStrippedPrice,
    currentPlanId,
    trialStatus,
    nextPlan,
    nextPlanPrice,
    nextPlanStrippedPrice,
    planIdToPayFor
  };
}
