import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import find from 'lodash/find';
import { getUserId } from 'utils';
import { styled, InputAdornment } from 'components';
import { GET_USER_COUPON } from 'api';
import Input from './Input';
import Button from '../Button';
import Spinner from '../Spinner';
import { MdCheck } from '../../icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {
    display: 'flex',
    alignItems: 'flex-end'
  },
  '& .input': {
    border: 'none',
    '&.Mui-error input': {
      borderColor: theme.palette.error.main
    }
  },
  '& .htmlInput': {
    borderBottom: '1px solid rgba(0,0,0,0.1)'
  },
  '& .button': {
    marginLeft: 15,
    padding: '8px 10px',
    fontSize: 14,
    '&.checked': {
      backgroundColor: theme.palette.success.main
    },
    '& .StyledButton-startIcon': {
      fontSize: 17
    }
  }
}));

function CouponInput(props) {
  const { onSuccess, onLoading } = props;
  const [coupon, setCoupon] = useState('');
  const [checked, setChecked] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);

  // useLazyQuery loading field doesn't update for some reason
  const [fetchUserCoupon] = useLazyQuery(GET_USER_COUPON, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  useEffect(() => {
    if (onLoading) onLoading(loading);
  }, [onLoading, loading]);

  const check = async () => {
    const uid = getUserId();
    setLoading(true);

    try {
      const result = await fetchUserCoupon({ variables: { userId: Number(uid) } });

      if (result?.data?.getUserCoupon) {
        const validCoupon = find(result.data.getUserCoupon, { name: coupon, isValid: true });
        if (validCoupon) {
          const { couponId, percentOff: discount } = validCoupon;
          setChecked(true);
          onSuccess({ id: couponId, discount });
        } else {
          setInvalid(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    if (invalid) setInvalid(false);
    setCoupon(e.target.value);
  };

  return (
    <StyledRoot className="container">
      <Input
        id="coupon-input"
        variant="textfield"
        value={coupon}
        label="Coupon"
        error={invalid ? 'Invalid coupon, try another one' : ''}
        withHelperText
        inputClassName="input"
        htmlInputClassName="htmlInput"
        onChange={handleChange}
        testID="coupon-input"
        endAdornment={
          <InputAdornment position="end">
            <Button
              variant="filled-primary"
              className={clsx('button', checked && 'checked')}
              startIcon={checked && <MdCheck fontSize="inherit" color="inherit" />}
              endIcon={loading && <Spinner size={12} />}
              disabled={loading || checked || !coupon}
              onClick={check}
              testID="coupon-check-button"
            >
              {checked ? 'Valid' : 'Apply'}
            </Button>
          </InputAdornment>
        }
      />
    </StyledRoot>
  );
}

CouponInput.propTypes = {
  onSuccess: PT.func,
  onLoading: PT.func
};

CouponInput.defaultProps = {
  onSuccess: ({ id, discount }) => {},
  onLoading: () => {}
};

export default CouponInput;
