import { keyframes } from '@emotion/react';

const toggleIn = keyframes({
  '0%': {
    width: 53
  },
  '100%': {
    width: '100%'
  }
});
const toggleOut = keyframes({
  '0%': {
    width: '100%'
  },
  '100%': {
    width: 53
  }
});
const zoomIn = keyframes({
  '0%': {
    width: 53,
    height: 53,
    right: 0
  },
  '100%': {
    width: 47,
    height: 47,
    right: 3
  }
});
const zoomOut = keyframes({
  '0%': {
    width: 47,
    height: 47,
    right: 3
  },
  '100%': {
    width: 53,
    height: 53,
    right: 0
  }
});

export default ({ theme }) => ({
  overflow: 'auto',
  paddingTop: 3,
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(theme.desktopBreakPoint)]: {
    // overflow: 'hidden',
    overflow: 'initial'
  },

  '& .qhGrowBanner': {
    width: '100%',
    height: 53,
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.moreColors.purpleLight_1}`,
    borderRadius: 25,
    backgroundColor: '#EFEEF6'
  },

  '& .toggleContainer': {
    position: 'relative',
    marginBottom: 16,
    paddingRight: 68,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&.toggleContainer_active': {
      '& .animatedSearchContainer': {
        animation: `${toggleIn} 200ms linear forwards`
      },
      '& .searchToggleInBtn': {
        animation: `${zoomIn} 200ms linear forwards`
      }
    }
  },
  // '& .toggleContainer_active': {},
  '& .searchToggleInBtn': {
    position: 'absolute',
    right: 0,
    zIndex: 300,
    width: 53,
    height: 53,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    animation: `${zoomOut} 200ms linear forwards`
  },
  '& .searchToggleOutBtn': {
    position: 'absolute',
    zIndex: 300,
    width: 47,
    height: 47,
    marginLeft: 3,
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.moreColors.grey_8}`,
    color: theme.palette.moreColors.grey_8,
    borderRadius: 100
  },
  '& .animatedSearchContainer': {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 200,
    height: 53,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF',
    border: `1px solid ${theme.palette.moreColors.grey_8}`,
    borderRadius: 25,
    animation: `${toggleOut} 200ms linear forwards`,
    '& .searchInput': {
      '&::placeholder': {
        fontSize: 14,
        lineHeight: '16px',
        color: theme.palette.moreColors.grey_7
      }
    },
    '& .animatedSearch__input': {
      '& .StyledInput-inputBase': {
        paddingLeft: 55,
        paddingRight: 53,
        border: 'none'
      }
    }
  },
  '& .autocompleteContainer': {
    width: '100%',
    marginBottom: 16,
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 12,
      padding: '0 16px'
    }
  },
  '& .contentWrapper': {
    position: 'relative',
    overflow: 'auto',
    padding: '0 30px',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 0,
      // overflowY: 'scroll',
      // overflowX: 'hidden',
      overflow: 'initial'
    }
  },
  '& .searchInput': {
    paddingLeft: '6px !important',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 16,
      paddingLeft: '6px !important'
    }
  },
  '& .allJobsContainer': {
    // flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 30,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flex: 1,
      margin: 0,
      flexFlow: 'column',
      overflow: 'hidden',
      display: 'flex'
    }
  },
  '& .myJobsContainer': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 16px'
    }
  },
  '& .jobCol': {
    width: '100%',
    display: 'flex'
  },
  '& .jobsDeckWrapper': {
    marginBottom: 5
  },
  '& .deckLabel__pass': {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 2,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 10,
    backgroundColor: '#fff',
    color: '#BC2626'
  },
  '& .deckLabel__apply': {
    position: 'absolute',
    top: 12,
    left: 12,
    zIndex: 2,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 10,
    backgroundColor: '#fff',
    color: '#529C59'
  },
  '& .mobileJobCardWrapper': {
    padding: '0px 16px'
  },
  '& .mobileJobCard': {
    width: '100%',
    maxWidth: 382
  },
  '& .mobileControllers': {
    width: '100%',
    padding: '0px 16px 90px',
    display: 'none',
    // alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'flex'
    }
  },
  '& .mobileControllerWrapper': {
    maxWidth: 64,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:not(:first-of-type)': {
      marginLeft: 24
    }
  },
  '& .mobileControllerBtnWrapper': {
    height: 58,
    // marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .mobileControllerLabel': {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  '& .myJobsFilterContainer': {
    marginLeft: 30,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginLeft: 24
    }
  },
  '& .layoutRadioGroup': {
    marginLeft: 30,
    '& label': {
      margin: 0
    },
    '& label:not(:first-of-type)': {
      marginLeft: 16
    }
  },
  '& .layoutRadio': {
    padding: 0,
    color: theme.palette.moreColors.black,
    opacity: 0.4
  },
  '& .layoutRadio_checked': {
    color: theme.palette.primary.main,
    opacity: 1
  },
  '& .textMain': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .textSecondary': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    opacity: 0.4,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontWeight: 'bold'
    }
  },
  '& .listItem': {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    '&:hover': {
      '& .listItem_withActions': {
        marginLeft: 20,
        '& .listItem__mainData': {
          display: 'none'
        },
        '& .listItem__actions': {
          display: 'flex'
        }
      }
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 10,
      borderBottom: 'none'
    }
  },
  '& .listItem__data': {
    '&:not(:first-of-type)': {
      marginLeft: 16
    },
    '&:last-child': {
      marginLeft: 'auto',
      marginRight: 0,
      paddingRight: 0
    }
  },
  '& .listItem__avatar': {
    position: 'relative',
    display: 'block',
    minWidth: 38,
    height: 38,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 3,
    backgroundColor: '#F6F5FA',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minWidth: 76,
      height: 76
    }
  },
  '& .listItem__actions': {
    display: 'none'
  },
  '& .mobileListItem__button': {
    width: '100%',
    padding: 10,
    paddingRight: 8,
    justifyContent: 'flex-start',
    backgroundColor: '#ECECF5'
  },
  '& .mobileListItem__infoContainer': {
    width: '100%',
    maxWidth: 'calc(100% - 108px)',
    padding: '8px 0',
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'flex-start'
  },
  '& .mobileListItem__title': {
    color: '#000'
  },
  '& .mobileListItem__employer': {
    textAlign: 'left'
  },
  '& .mobileListItem__jobInfo': {
    textAlign: 'left'
  },
  '& .mobileListItem__icon': {
    marginLeft: 6,
    color: theme.palette.primary.main
  },
  '& .avatarMarkerIcon': {
    position: 'absolute',
    right: -6,
    bottom: -4,
    width: 24,
    height: 24,
    border: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: '#fff',
    borderRadius: '50%',
    backgroundColor: theme.palette.moreColors.yellow,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: 22,
      height: 22,
      bottom: -7,
      right: -7,
      borderColor: '#ECECF5'
    }
  },
  '& .ellipsisText': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '& .optionsPopupPaper': {
    minWidth: 255
  },
  '& .optionsPopupBtn': {
    width: '100%',
    height: 55,
    extAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: 0,
    color: '#000',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
  },
  '& .fusion': {
    position: 'fixed',
    bottom: 74,
    left: 0,
    right: 0,
    height: 20,
    backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0) 30%, #fff 100%)',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .gridContainer': {
    margin: '0 -15px',
    display: 'flex',
    flexFlow: 'row wrap'
  },
  '& .gridColumn': {
    width: '100%',
    maxWidth: '33.33%',
    padding: '0 15px 30px',
    flex: '1 1 33.33%'
  },
  /* GRID CARD */
  '& .card': {
    position: 'relative',
    minHeight: 396,
    width: '100%',
    border: '1px solid rgba(0,0,0,0.1)',
    flexDirection: 'column',
    alignItems: 'initial',
    justifyContent: 'initial',
    borderRadius: 16,
    transition: 'box-shadow 300ms linear',
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.16)',
      '& .card__company': {
        visibility: 'hidden'
      },
      '& .card__actions': {
        display: 'flex'
      }
    }
  },
  '& .shareButton': {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 100,
    width: 35,
    height: 35,
    fontSize: 18,
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  '& .card__actions': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '60px 18px 25px',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage:
      'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.91) 30%, #fff 100%)',
    borderRadius: '0 0 16px 16px'
  },
  '& .card__btn': {
    width: '100%',
    display: 'block',
    flexDirection: 'row'
  },
  '& .card__photoArea': {
    height: 180,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '16px 16px 0 0'
  },
  '& .card__name': {
    marginBottom: 8,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0
  },
  '& .card__jobStats': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.primary.main
  },
  '& .navIcon': {
    display: 'inline-block',
    paddingLeft: 9,
    paddingRight: 5,
    verticalAlign: 'text-bottom'
  },
  '& .card__jobDescription': {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0
  },
  '& .card__company': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .card__companyLogo': {
    height: 48,
    minWidth: 48,
    border: '0.5px solid rgba(0,0,0,0.1)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '50%'
  },
  '& .card__companyName': {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0
  },
  '& .deckAction': {
    '&.big': {
      width: 58,
      height: 58
    }
  },
  '& .noJobs': {
    padding: '76px 16px 0'
    // flex: 1,
    // flexDirection: 'column',
    // display: 'flex',
    // justifyContent: 'center',
  },
  '& .noJobs__icon': {
    width: 111,
    height: 111,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: 58,
    backgroundColor: '#F1F2F2',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 16,
      backgroundColor: 'transparent'
    }
  },
  '& .noJobs__box': {
    maxWidth: 360,
    margin: '0 auto',
    borderRadius: 18,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 16,
      backgroundColor: '#F1F2F2'
    }
  },
  '& .noJobs__title': {
    marginBottom: 14,
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.primary.main
  },
  '& .noJobs__text': {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '17px',
    color: '#5E6060'
  },
  '& .noJobs__subTitle': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 14,
      color: theme.palette.primary.main
    }
  },
  '& .filterOptionContainer': {
    marginTop: -13,
    marginRight: -13,
    marginBottom: 16,
    padding: '0 30px',
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 0
    }
  },
  '& .filterOption': {
    position: 'relative',
    height: 28,
    marginTop: 13,
    marginRight: 13,
    padding: '0 18px',
    paddingRight: 45,
    border: '1px solid #D3D3DD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    color: '#D3D3DD',
    userSelect: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 23,
      marginTop: 10,
      padding: '0 12px',
      paddingRight: 30,
      fontSize: 12
    }
  },
  '& .filterOption__deleteBtn': {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 26,
    width: 26,
    fontSize: 15,
    color: '#8782BE',
    backgroundColor: '#ECECF6',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 20,
      width: 20,
      padding: 0
    }
  },
  '& .filterAddBtn': {
    height: 28,
    width: 28,
    marginTop: 13,
    marginRight: 15,
    borderColor: theme.palette.primary.main,
    fontSize: 15,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 23,
      width: 23,
      marginTop: 10
    }
  },
  '& .filtersAccordionHeader': {
    minHeight: 15,
    '&.Mui-expanded': {
      minHeight: 15
    }
  },
  '& .filtersAccordionHeader__content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: '10px 0'
    }
  },
  '& .filtersAccordionHeader__expandIcon': {
    padding: 6
  }
});
