import React, { useState } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import map from 'lodash/map';
import { qaAttr, trimStr, getUserId, emoji } from 'utils';
import { useMediaQuery, styled } from 'components';
import { useForm } from 'components/form';
import { Button, ButtonBase, Input, Spinner } from 'components/shared';
import { POST_SURVEY } from 'api';
import styles from 'styles/Onboarding';

const { ANGRY_FACE, SAD_FACE, SMILING_FACE, STAR_EYES } = emoji;
const RATES = [
  { ...ANGRY_FACE, value: 1, withFeedback: true },
  { ...SAD_FACE, value: 2, withFeedback: true },
  { ...SMILING_FACE, value: 3, withFeedback: false },
  { ...STAR_EYES, value: 4, withFeedback: false }
];

function Survey({ onGoToNextStep }) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const [postSurvey, { data = {}, error = {}, loading = false }] = useMutation(POST_SURVEY);
  const [showForm, setShowForm] = useState(false);
  const { attrs, set, get, $, validate, withValidation } = useForm({
    initial: { comment: '', rate: null }
  });

  const selectRate = (rate, value, withFeedback) => {
    set({ rate });

    if (withFeedback) {
      setShowForm(true);
    } else {
      setShowForm(false);
      goNext(undefined, rate);
    }
  };

  const goNext = async (e, rate) => {
    const userId = getUserId();

    if (userId) {
      await postSurvey({
        variables: {
          userId: parseInt(userId),
          ratingValue: rate || attrs.rate,
          ratingNotes: trimStr(attrs.comment)
        }
      });
      onGoToNextStep();
    }
  };

  const changeComment = (e, { name }) => set(name, e.target.value);

  return (
    <>
      <Rates style={{ maxWidth: isDesktop ? '100%' : 320 }}>
        <div className="ratesContainer">
          {map(RATES, ({ code, label, value, withFeedback }, i) => (
            <div key={`rate__${i}`} className="rateBtnWrapper">
              <ButtonBase
                key={`rateBtn__${i}`}
                className="rateBtn"
                style={{ backgroundColor: i + 1 === attrs.rate ? '#F2F2F2' : '#FFF' }}
                onClick={() => selectRate(i + 1, value, withFeedback)}
                {...qaAttr(`rate-button-${value}`)}
              >
                {code}
              </ButtonBase>
            </div>
          ))}
        </div>
      </Rates>
      {showForm && (
        <FormContainer>
          <h2 className="formTitle">Sorry you feel that way, what's troubling you?</h2>
          <Input
            {...$('comment', changeComment)}
            multiline
            rows={6}
            sx={{ mb: '27px' }}
            withHelperText
            analyticParams={{
              key: 'Survey feedback focused (onboarding)',
              trigger: 'focus'
            }}
          />
          <div style={{ display: 'flex' }}>
            <Button
              variant="filled-primary"
              endIcon={loading && <Spinner size={24} />}
              width="100%"
              height={48}
              disabled={loading}
              onClick={goNext}
            >
              Submit
            </Button>
            <Button variant="text" width="100%" sx={{ ml: 20 }} disabled={loading} onClick={goNext}>
              Skip
            </Button>
          </div>
        </FormContainer>
      )}
    </>
  );
}

Survey.propTypes = {
  onGoToNextStep: PT.func.isRequired
};

const Rates = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& .ratesContainer': {
    width: '100%',
    marginBottom: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      margin: '0 16px -40px',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }
  },
  '& .rateBtnWrapper': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      maxWidth: '50%',
      padding: '0 16px 40px'
    }
  },
  '& .rateBtn': {
    width: 114,
    height: 114,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #EAEAEA',
    fontSize: 60,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      borderRadius: 10,
      boxShadow: '0 3px 4px rgba(0,0,0,0.08)'
    }
  }
}));
const FormContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 360,
  marginTop: 35,
  [theme.breakpoints.down(theme.desktopBreakPoint)]: {
    maxWidth: 300
  },
  formTitle: {
    marginBottom: 20,
    lineHeight: '13px',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#7A7A7A'
  }
}));
const StyledSurvey = styled(Survey)(styles);

export default StyledSurvey;
