import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { qaAttr } from 'utils';
import { useAlerts } from 'hooks';
import { Box, FormHelperText, useTheme, useMediaQuery, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import { useForm } from 'components/form';
import styles from 'styles/Onboarding';
import { UPDATE_EMPLOYEE_PROFILE_VIDEO, getOnboardingStatistics } from 'api';

function CvForm({
  calledToasts,
  setCalledToasts,
  formKey,
  form,
  onChange,
  onGoToNextStep,
  onFinish
}) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const theme = useTheme();
  const { showToast } = useAlerts();
  // const [upladedFileName, setUpladedFileName] = useState('');
  const [mutationLoading, setMutationLoading] = useState(false);
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const [videoUploadError, setVideoUploadError] = useState('');

  const [postEmployeeProfileVideo, { loading: postVideoLoading = false }] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_VIDEO
  );

  const { attrs, $, set, getError, withValidation } = useForm({
    initial: { video: form.video },
    validations: {
      video: [
        (value) => {
          if (!value) return 'Please add video';
        }
      ]
    }
  });
  const validationError = getError('video');

  useEffect(() => {
    showAnalyticToast();
  }, []);

  const showAnalyticToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      const info = (await getOnboardingStatistics(form.zip)) || {};
      if (info.numberOfJobsInZip) {
        const single = info.numberOfJobsInZip === 1;
        setCalledToasts([...calledToasts, formKey]);
        showToast({
          title: `There ${single ? 'is' : 'are'} ${info.numberOfJobsInZip} job${
            single ? '' : 's'
          } waiting for you`,
          icon: () => <MdExclamation />,
          flourishText: '',
          position: { top: false, right: false }
        });
      }
    }
  };

  // function handleFieldChange(field) {
  //   return (e) => onChange({ ...form, [field]: e.target.value });
  // }

  function handleVideoUpload(e) {
    setVideoUploadError('');
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setVideoUploadLoading(true);
      if (file) {
        set('video', file);
        onChange({ ...form, video: file });
      }
      setVideoUploadLoading(false);
    };
    if (file) reader.readAsDataURL(file);
  }

  async function handleSaveVideo(e) {
    if (form.video) {
      // setUpladedFileName('');
      setVideoUploadError('');
      setMutationLoading(true);

      const { name } = form.video;
      const ext = name.split('.').pop();

      try {
        const result = await postEmployeeProfileVideo({ variables: { file: form.video } });
        if (result?.data?.data?.postEmployeeVideo?.filename) {
          // setUpladedFileName(result.data.data.postEmployeeVideo.filename);
          onFinish();
        } else if (result?.data?.errors?.[0]?.message) {
          setVideoUploadError(result.data.errors[0].message);
        }
      } catch (error) {
        console.error('handleSaveVideo error', error);
      } finally {
        setMutationLoading(false);
      }
    }
  }

  function next() {
    onFinish(withValidation((attrs) => handleSaveVideo()));
  }

  return (
    <>
      <Description>
        Tell Employers Who you are, Where you're from and What skills you have!
      </Description>
      {form.video && !videoUploadLoading && (
        <Box component="p" my="20px" color="primary.main" textAlign="center">
          Upload complete
        </Box>
      )}
      <UploadButtonLabel htmlFor="video-input">
        <Button
          component="span"
          endIcon={videoUploadLoading ? <Spinner size={24} /> : null}
          variant="filled-secondary"
          disabled={videoUploadLoading || mutationLoading}
          className="nextBtn"
          testID="onboarding-video-upload-button"
        >
          Select from Library
        </Button>
        {videoUploadError && (
          <FormHelperText error style={{ ...theme.commonStyles.helperText, textAlign: 'center' }}>
            {videoUploadError}
          </FormHelperText>
        )}
        {validationError && (
          <FormHelperText error style={{ ...theme.commonStyles.helperText, textAlign: 'center' }}>
            {validationError}
          </FormHelperText>
        )}
      </UploadButtonLabel>
      <Box
        component="input"
        accept="video/mp4,video/x-m4v,video/*"
        display="none"
        id="video-input"
        type="file"
        onChange={handleVideoUpload}
        {...qaAttr('onboarding-video-input')}
      />
      <Button
        variant="filled-primary"
        disabled={videoUploadLoading || mutationLoading}
        endIcon={mutationLoading ? <Spinner size={24} /> : null}
        className="nextBtn"
        sx={{ mb: isDesktop ? '25px' : '13px' }}
        onClick={next}
        testID="onboarding-finish-button"
      >
        Finish
      </Button>
      <Button
        variant="text"
        sx={{ width: '100%', mb: '26px', fontSize: isDesktop ? 16 : 11 }}
        onClick={() => onFinish(undefined, { skipped: true })}
        testID="onboarding-skip-button"
      >
        Skip
      </Button>
      <div style={{ maxWidth: isDesktop ? '100%' : 282 }}>
        <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
          Would you look at that?
        </p>
        <p className="secondaryText" style={{ marginBottom: 15 }}>
          Applicants with video intros have a 50% higher response rate from employers than ones
          without.
        </p>
      </div>
      <DisclaimerText>
        By pressing Finish, you agree to our Terms of Service and Privacy Policy. By applying for a
        position through QuickHire, you agree and provide your consent that (a) QuickHire may share
        and transmit your information to potential employers, (b) your information will be processed
        and used in accordance with such employer's terms and conditions and privacy policies and
        (c) QuickHire is not responsible for the processing and use of your information by any such
        employers
      </DisclaimerText>
    </>
  );
}

CvForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const DisclaimerText = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '24px',
  color: '#C1C0C0',
  [theme.breakpoints.down(theme.desktopBreakPoint)]: {
    maxWidth: 282,
    lineHeight: '15px',
    fontSize: 10
  }
}));
const UploadButtonLabel = styled('label')(({ theme }) => ({
  width: '100%',
  marginBottom: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));
const Description = styled('p')(({ theme }) => ({
  marginBottom: 20,
  fontSize: 13,
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.moreColors.grey_5
}));
const StyledCvForm = styled(CvForm)(styles);

export default StyledCvForm;
