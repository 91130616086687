import React from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import { CircularProgressbar } from 'react-circular-progressbar';

const AnimatedProgress = animated(({ value }) => (
  <CircularProgressbar
    styles={{ root: { transform: 'rotate(-135deg)' }, path: { stroke: '#4743A2' } }}
    value={value}
    circleRatio={0.75}
    strokeWidth={5}
  />
));

function ScoreProgress({ score }) {
  return (
    <>
      <Spring
        config={{
          duration: 1250,
          tension: 300,
          friction: 0,
          easing: easePolyOut.exponent(2.0)
        }}
        from={{ score: 0 }}
        to={{ score }}
      >
        {({ score: animScore }) => <AnimatedProgress value={animScore} />}
      </Spring>
      <div className="careerHealthScoreProgressGradient" />
    </>
  );
}

ScoreProgress.propTypes = {
  score: PropTypes.number
};

ScoreProgress.defaultProps = {
  score: 0
};

export default ScoreProgress;
