import React from 'react';
import { getDisplayName } from 'utils';
import { RestrictionsProvider } from 'providers';

export default function withRestrictions(WrappedComponent) {
  function WithRestrictions(props) {
    return (
      <RestrictionsProvider>
        <WrappedComponent {...props} />
      </RestrictionsProvider>
    );
  }
  WithRestrictions.displayName = `WithRestrictions(${getDisplayName(WrappedComponent)})`;
  return WithRestrictions;
}
