import React from 'react';
import PT from 'prop-types';
import { styled, useMediaQuery } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdLogout } from 'components/icons';
import { useAuth } from 'hooks';

const StyledButton = styled(Button)(({ theme }) => ({
  width: 126,
  height: 45,
  fontSize: 14,
  lineHeight: '16px',
  borderRadius: 3
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));

function LogoutButton(props) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { signout } = useAuth();

  const logOut = () => {
    signout();
  };

  return isDesktop ? (
    <StyledButton
      variant="filled-secondary"
      className="headerElement"
      onClick={logOut}
      testID="logout-button"
    >
      Logout
    </StyledButton>
  ) : (
    <StyledIconButton
      color="primary"
      aria-label="log out"
      className="headerElement"
      onClick={logOut}
      testID="logout-button"
    >
      <MdLogout />
    </StyledIconButton>
  );
}

export default LogoutButton;
