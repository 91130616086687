import React, { useCallback, useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { qaAttr } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useAlerts } from 'hooks';
import { styled, Switch, useMediaQuery } from 'components';
import { useForm } from 'components/form';
import { Spinner } from 'components/shared';
import { UPDATE_USER_NOTIFICATIONS } from 'api';
import styles from 'styles/Profile/EmployeeProfile';
import SectionLayout from '../SectionLayout';

const StyledRoot = styled('div')(styles);

function Notifications(props) {
  const { notifications, sectionLayoutProps } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const [updateEmployeeNotification] = useMutation(UPDATE_USER_NOTIFICATIONS);
  const [isDirty, setIsDirty] = useState(false);
  const { attrs, set, $ } = useForm();
  const { emailNewJobs, emailNewMessages, emailTips, inappNewJobs, inappNewMessages, inappTips } =
    attrs;

  useEffect(() => {
    set(notifications);
  }, [JSON.stringify(notifications)]);

  useEffect(() => {
    const update = async () => {
      await updateEmployeeNotification({
        variables: { ...attrs }
      });
    };
    if (isDirty) update();
  }, [attrs]);

  const handleSwitchChange = useCallback((event, { name }) => {
    setIsDirty(true);
    set(name, event.target.checked);
  }, []);

  const sectionHeader = isDesktop ? (
    <div className="notice__sectionHeader">
      <div className="notice__sectionHeader__item">In-App</div>
      <div className="notice__sectionHeader__item">Email</div>
    </div>
  ) : null;

  return (
    <SectionLayout
      {...sectionLayoutProps}
      sectionHeaderContent={sectionHeader}
      sectionHeaderProps={{
        style: { display: 'flex', justifyContent: 'space-between' }
      }}
    >
      <StyledRoot className="notice__container">
        <div className="notice__header">
          <div className="notice__header__item">In-App</div>
          <div className="notice__header__item">Email</div>
        </div>
        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">New Messages</h3>
            </div>
            <div className="notice__actionsContainer">
              <Switch
                {...$('inappNewMessages', handleSwitchChange)}
                checked={inappNewMessages || false}
                color="primary"
                inputProps={{
                  'aria-label': 'in-app new messages',
                  ...qaAttr('in-app-new-messages-checkbox'),
                  onFocus: () => {
                    track('In-app new messages focused (employee profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
              <Switch
                {...$('emailNewMessages', handleSwitchChange)}
                checked={emailNewMessages || false}
                color="primary"
                inputProps={{
                  'aria-label': 'email new messages',
                  ...qaAttr('email-new-messages-checkbox'),
                  onFocus: () => {
                    track('Email new messages focused (employee profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
            </div>
          </div>
          <div className="notice__descr">
            <p className="label notice__descr__text">
              Notify you via push notification or email that you have received new applicants on
              your job posting
            </p>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">New Job Matches</h3>
            </div>
            <div className="notice__actionsContainer">
              <Switch
                {...$('inappNewJobs', handleSwitchChange)}
                checked={inappNewJobs || false}
                color="primary"
                inputProps={{
                  'aria-label': 'in-app new job matches',
                  ...qaAttr('in-app-new-jobs-checkbox'),
                  onFocus: () => {
                    track('In-app new job matches focused (employee profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
              <Switch
                {...$('emailNewJobs', handleSwitchChange)}
                checked={emailNewJobs || false}
                color="primary"
                inputProps={{
                  'aria-label': 'email new job matches',
                  ...qaAttr('email-new-jobs-checkbox'),
                  onFocus: () => {
                    track('Email new job matches focused (employee profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
            </div>
          </div>
          <div className="notice__descr">
            <p className="label notice__descr__text">
              Notifications about daily Job statistics, QH Matches and other items relative to your
              postings.
            </p>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">QuickHire Tips</h3>
            </div>
            <div className="notice__actionsContainer">
              <Switch
                {...$('inappTips', handleSwitchChange)}
                checked={inappTips || false}
                color="primary"
                inputProps={{
                  'aria-label': 'in-app qh tips',
                  ...qaAttr('in-app-qh-tips-checkbox'),
                  onFocus: () => {
                    track('In-app qh tips focused (employee profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
              <Switch
                {...$('emailTips', handleSwitchChange)}
                checked={emailTips || false}
                color="primary"
                inputProps={{
                  'aria-label': 'email qh tips',
                  ...qaAttr('email-qh-tips-checkbox'),
                  onFocus: () => {
                    track('Email qh tips focused (employee profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
            </div>
          </div>
          <div className="notice__descr">
            <p className="label notice__descr__text">
              I’m not entirely sure what this notification does honestly
            </p>
          </div>
        </div>
      </StyledRoot>
    </SectionLayout>
  );
}

Notifications.propTypes = {
  sectionLayoutProps: PT.objectOf(PT.any).isRequired,
  notifications: PT.shape({
    inappNewApplicants: PT.bool,
    emailNewApplicants: PT.bool,
    inappInboxMessages: PT.bool,
    emailInboxMessages: PT.bool,
    inappTimeRespondEnds: PT.bool,
    emailTimeRespondEnds: PT.bool
  }).isRequired
};

export default memo(Notifications);
