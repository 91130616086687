import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider } from '@mui/material/styles'; // @TODO: remove after migrations
import LiveChat from 'react-livechat';
import TagManager from 'react-gtm-module';
import { client } from 'api';
import { AdapterDateFns, CssBaseline, LocalizationProvider, ThemeProvider } from 'components';
import { AuthProvider } from 'providers';
import { AppRouter } from 'router';
import theme from 'styles/theme';

const { LIVE_CHAT_LICENSE, GTM_ID } = process.env;

if (GTM_ID) TagManager.initialize({ gtmId: GTM_ID });

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <AppRouter />
            </LocalizationProvider>
          </ThemeProvider>
          {LIVE_CHAT_LICENSE && <LiveChat license={parseInt(LIVE_CHAT_LICENSE)} />}
        </AuthProvider>
      </ApolloProvider>
    </StyledEngineProvider>
  );
}

export default App;
