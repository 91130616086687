import React, { useEffect, useCallback, useRef, useState, useMemo, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import AvatarEditor from 'react-avatar-editor';
import { join, map, isEqual, forEach, find, reduce } from 'lodash';
import clsx from 'clsx';
import {
  capitalizeFirstLetter,
  getComparableFields,
  getLocaleTimeZone,
  imageTypeHelperText,
  makeInitForm,
  qaAttr,
  trimStr,
  unformatPhone
} from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useTimeZone } from 'hooks';
import { Dialog, styled, useMediaQuery } from 'components';
import { Button, IconButton, Input, PhoneInput, Spinner, Select } from 'components/shared';
import { MdArrowBack } from 'components/icons';
import { useForm } from 'components/form';
import { CompanySizeForm, IndustriesForm } from 'components/dialogs/components';
import defaultEmployerImg from 'assets/img/employer_default.png';
import {
  client,
  UPDATE_EMPLOYER_PROFILE_IMG,
  UPDATE_EMPLOYER_PROFILE,
  UPDATE_EMPLOYER_INDUSTRY,
  GET_COUNTRIES,
  GET_STATES
} from 'api';
import styles from 'styles/Profile/EmployerProfile';
import SectionLayout from '../SectionLayout';
import timeZones from './timezones.json';

const StyledRoot = styled('div')(styles);
const StyledSubDialog = styled(Dialog)(styles);

const NO_PREV_FIELDS = ['imageUrl', 'newImageFile'];

function renderIndustriesString(industry) {
  return industry.length > 0 ? join(map(industry, 'name'), ', ') : '';
}

function generateForm(profile) {
  return makeInitForm(profile, [], NO_PREV_FIELDS);
}

function Profile(props) {
  const {
    expanded,
    fetchEmployerProfile,
    jumpLink,
    onExpand,
    profile,
    sectionName,
    setExpandedSection
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { setTimeZone } = useTimeZone();
  const editorRef = useRef();
  const initProfile = useMemo(() => generateForm(profile), [JSON.stringify(profile)]);

  const [setEmployerUpdate, { loading: setProfileUpdateLoading = false }] =
    useMutation(UPDATE_EMPLOYER_PROFILE);
  const [postEmployerIndustriesUpdate, { loading: postIndustriesLoading = false }] =
    useMutation(UPDATE_EMPLOYER_INDUSTRY);
  const [postEmployerProfileImg, { loading: postImageLoading = false }] = useMutation(
    UPDATE_EMPLOYER_PROFILE_IMG
  );

  const [fetchCountries, { data: countriesData }] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [fetchStates, { data: statesData }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [isEdit, setIsEdit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState({});
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isImageChanged, setImageChanged] = useState(false);
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);
  const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
  const profileUpdateLoading = setProfileUpdateLoading || postIndustriesLoading || isImageLoading;

  const { $, attrs, set, get, validate, withValidation, useConfig } = useForm({
    initial: initProfile,
    validations: {
      name: [{ presence: { allowEmpty: false } }],
      address: [{ presence: { allowEmpty: false } }],
      timeZone: [{ presence: { allowEmpty: false } }],
      phone: ['presence', 'phone'],
      website: ['url'],
      city: [{ presence: { allowEmpty: false } }],
      countryId: ['presence', 'numericality'],
      stateId: ['presence', 'numericality']
    }
  });

  const selectedCountryOption = useMemo(
    () => (countries.length ? find(countries, ['id', Number(attrs.countryId)]) || {} : {}),
    [JSON.stringify(countries), attrs.countryId]
  );

  const selectedStateOption = useMemo(() => {
    if (statesData?.getAllStates && attrs.stateId) {
      return find(statesData.getAllStates, ['id', Number(attrs.stateId)]) || {};
    }
    return {};
  }, [JSON.stringify(statesData), attrs.stateId]);

  useConfig(() => {
    if (selectedCountryOption.code) {
      return {
        validations: {
          zip: ['presence', { zip: { countryCode: selectedCountryOption.code } }]
        }
      };
    }
  }, [selectedCountryOption.code]);

  useEffect(() => {
    fetchCountries();
    fetchStates();
  }, []);

  useEffect(() => {
    if (countriesData?.getAllCountries) setCountries(countriesData.getAllCountries);
  }, [JSON.stringify(countriesData)]);

  useEffect(() => {
    if (statesData?.getAllStates) {
      const mappedStates = reduce(
        statesData.getAllStates,
        (result, value) => {
          const countryName = value.country.name;
          if (result[countryName]) result[countryName].push(value);
          else {
            result[countryName] = [{ ...value }];
          }
          return result;
        },
        {}
      );
      setStates(mappedStates);
    }
  }, [JSON.stringify(statesData)]);

  // useEffect(() => {
  //   if (isProfileUpdate) {
  //     set(generateForm(profile)); // completely update form
  //     setIsProfileUpdate(false);
  //   } else {
  //     // preserve newly typed info but update 'prev' fields
  //     const updForm = generateForm(profile);
  //     forEach(profile, (val, key) => {
  //       const prevField = `prev${capitalizeFirstLetter(key)}`;
  //       if (updForm[prevField]) updForm[key] = attrs[key];
  //       if (key === 'imageUrl') {
  //         if (isImageChanged) {
  //           updForm.imageUrl = attrs.imageUrl;
  //           updForm.newImageFile = attrs.newImageFile;
  //         }
  //       }
  //     });
  //     set(updForm);
  //   }
  // }, [JSON.stringify(profile)]);

  useEffect(() => {
    set(initProfile);
  }, [JSON.stringify(initProfile)]);

  useEffect(() => {
    if (!isEdit && (isIndustryModalOpen || isSizeModalOpen)) {
      // close modals on cancel
      setIsIndustryModalOpen(false);
      setIsSizeModalOpen(false);
    }
    // if (!isEdit) {
    //   // reset changes on cancel
    //   set(initProfile);
    // }
    if (!isDesktop && isEdit && !expanded) {
      // expand on 'edit' button click
      setExpandedSection(sectionName);
    }
  }, [isEdit]);

  useEffect(() => {
    if (
      selectedCountryOption.name &&
      selectedStateOption.name &&
      selectedStateOption?.country?.name !== selectedCountryOption.name
    ) {
      set({ stateId: '' });
    }
  }, [selectedCountryOption.name, selectedStateOption.name]);

  const toggleEditMode = (e) => {
    e.stopPropagation(); // for mobile
    setIsEdit(!isEdit);
  };

  const handleFieldChange = useCallback((e, { name }) => {
    set(name, name === 'phone' ? unformatPhone(e.target.value) : e.target.value);
  }, []);

  const handleFormChange = useCallback((nextForm) => {
    set({ ...nextForm });
  }, []);

  const handlePhotoUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setImageChanged(true);
      set({ imageUrl: reader.result, newImageFile: file });
    };
    if (file) reader.readAsDataURL(file);
  };

  const postProfilePhoto = async () => {
    const file = attrs.newImageFile;

    if (editorRef.current && file) {
      setImageLoading(true);
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const fileName = file.name;
      const ext = fileName.split('.').pop();

      return new Promise((resolve, reject) => {
        canvasScaled.toBlob(async (blob) => {
          const blobToFile = new File([blob], fileName);
          await postEmployerProfileImg({ variables: { file: blobToFile } });
          const imageUrl = window.URL.createObjectURL(blob);
          set({ imageUrl, newImageFile: null });
          setImageChanged(false);
          setImageLoading(false);
          resolve();
        }, `image/${ext}`);
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    validate()
      .then(async (validatedAttrs) => {
        setIsProfileUpdate(true);
        const { current, prev } = getComparableFields(validatedAttrs, [
          'size',
          'industry',
          'address',
          'timeZone',
          'website',
          'name',
          'phone',
          'city',
          'countryId',
          'stateId',
          'zip'
        ]);
        let formChanged = false;
        if (isImageChanged) {
          formChanged = true;
          await postProfilePhoto();
        }
        if (!isEqual(prev, current)) {
          formChanged = true;
          const profile = await setEmployerUpdate({
            variables: {
              size: validatedAttrs.size,
              address: trimStr(validatedAttrs.address),
              zip: trimStr(validatedAttrs.zip),
              city: trimStr(validatedAttrs.city),
              countryId: Number(validatedAttrs.countryId),
              stateId: Number(validatedAttrs.stateId),
              timeZone: trimStr(validatedAttrs.timeZone),
              website: trimStr(validatedAttrs.website),
              name: trimStr(validatedAttrs.name),
              phone: trimStr(validatedAttrs.phone)
            }
          });

          setTimeZone(trimStr(validatedAttrs.timeZone));
          const ind = await postEmployerIndustriesUpdate({
            variables: {
              industry: validatedAttrs.industry || []
            }
          });
        }

        if (formChanged) {
          track('Employer Profile Updated');
          fetchEmployerProfile();
        }
        // setIsProfileUpdate(false);
        setIsEdit(false);
      })
      .catch((errors) => {
        const fieldsWithErr = Object.keys(errors).filter((field) => errors[field]);
        if (fieldsWithErr[0]) {
          const errorElement = document.getElementById(`${fieldsWithErr[0]}-input-helper`);
          if (errorElement) errorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
  };

  const toggleIndustryModal = () => {
    setIsIndustryModalOpen(!isIndustryModalOpen);
  };

  const toggleSizeModal = () => {
    setIsSizeModalOpen(!isSizeModalOpen);
  };

  const changeTimeZone = (e) => {
    set('timeZone', e.target.value);
  };

  const {
    address,
    city,
    imageUrl,
    industry,
    name,
    newImageFile,
    phone,
    size,
    timeZone,
    website,
    zip
  } = attrs;

  const defaultTimeZone = getLocaleTimeZone();

  const sectionHeader =
    isDesktop || expanded ? (
      <Button
        variant={isEdit ? 'filled-primary' : 'text'}
        className={clsx('profile__headerAction', isEdit && 'profile_saveBtn')}
        disabled={profileUpdateLoading}
        endIcon={profileUpdateLoading ? <Spinner size={12} /> : null}
        onClick={isEdit ? handleSave : toggleEditMode}
        testID="profile-edit-button"
      >
        {isEdit ? 'Save' : 'edit'}
      </Button>
    ) : null;

  const renderIndustryModal = () => (
    <StyledSubDialog open={isIndustryModalOpen} onClose={toggleIndustryModal}>
      <div className="dialogHeader">
        <div className="headerAction_start">
          <IconButton
            color="primary"
            aria-label="return"
            className="headerAction_end"
            onClick={toggleIndustryModal}
            {...qaAttr('close-modal-button')}
          >
            <MdArrowBack />
          </IconButton>
        </div>
        <h2 className="dialogTitle" {...qaAttr('industries-form-title')}>
          Industries
        </h2>
        <div className="headerAction_end" />
      </div>
      <div className="dialogContent" style={{ display: 'flex' }}>
        <IndustriesForm
          form={attrs}
          withSubmitBtn={false}
          withSkipBtn={false}
          onChange={handleFormChange}
        />
      </div>
    </StyledSubDialog>
  );

  const renderSizeModal = () => (
    <StyledSubDialog open={isSizeModalOpen} onClose={toggleSizeModal}>
      <div className="dialogHeader">
        <div className="headerAction_start">
          <IconButton
            color="primary"
            aria-label="return"
            className="headerAction_end"
            onClick={toggleSizeModal}
            {...qaAttr('close-modal-button')}
          >
            <MdArrowBack />
          </IconButton>
        </div>
        <h2 className="dialogTitle" {...qaAttr('size-form-title')}>
          How many employees do you have?
        </h2>
        <div className="headerAction_end" />
      </div>
      <div className="dialogContent" style={{ display: 'flex' }}>
        <CompanySizeForm
          form={attrs}
          withSubmitBtn={false}
          withSkipBtn={false}
          onChange={handleFormChange}
        />
      </div>
    </StyledSubDialog>
  );

  const selectProps = {
    className: 'select',
    labelProps: { className: 'label' },
    native: false,
    required: true,
    withEmptyOption: true,
    withHelperText: true
  };

  const renderAddressFields = () => (
    <div className="formItem row">
      {isEdit ? (
        <Input
          {...$('city', handleFieldChange)}
          variant="textfield"
          id="city-input"
          required
          label="City"
          labelClassName="label"
          className="rowInput"
          withHelperText
          analyticParams={{
            key: 'City focused (employer profile)',
            trigger: 'focus'
          }}
          testID="city-input"
        />
      ) : (
        <div className="rowInput">
          <div className="label">City</div>
          <div className="value">{city}</div>
        </div>
      )}
      {isEdit ? (
        <Select
          {...$('stateId', handleFieldChange)}
          {...selectProps}
          inputVariant="outlined"
          label="State"
          id="stateId-select"
          className="select rowInput"
          analyticParams={{
            key: 'State focused (employer profile)',
            trigger: 'focus'
          }}
          options={map(states?.[selectedCountryOption.name], (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`state-option-${i}`)
          }))}
          testID="stateId-input"
        />
      ) : (
        <div className="rowInput">
          <div className="label">State</div>
          <div className="value">{selectedStateOption.name}</div>
        </div>
      )}
      {isEdit ? (
        <Input
          {...$('zip', handleFieldChange)}
          variant="textfield"
          id="zip-input"
          required
          label="Zip Code"
          labelClassName="label"
          className="rowInput"
          withHelperText
          analyticParams={{
            key: 'Zip focused (employer profile)',
            trigger: 'focus'
          }}
          testID="zip-input"
        />
      ) : (
        <div className="rowInput">
          <div className="label">Zip Code</div>
          <div className="value">{zip}</div>
        </div>
      )}
      {isEdit ? (
        <Select
          {...$('countryId', handleFieldChange)}
          {...selectProps}
          inputVariant="outlined"
          label="Country"
          id="countryId-select"
          className="select rowInput"
          analyticParams={{
            key: 'Country focused (employer profile)',
            trigger: 'focus'
          }}
          options={map(countries, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`country-option-${i}`)
          }))}
          testID="country-input"
        />
      ) : (
        <div className="rowInput">
          <div className="label">Country</div>
          <div className="value">{selectedCountryOption.name}</div>
        </div>
      )}
    </div>
  );

  return (
    <SectionLayout
      expanded={expanded}
      onExpand={onExpand}
      jumpLink={jumpLink}
      sectionName={sectionName}
      sectionHeaderContent={sectionHeader}
      sectionHeaderProps={{ style: isEdit ? { display: 'flex', alignItems: 'flex-start' } : {} }}
    >
      <StyledRoot className="profile__container">
        <div className="profile__formGroup">
          {isEdit ? (
            <div className="formItem">
              <Input
                {...$('name', handleFieldChange)}
                variant="textfield"
                id="name-input"
                required
                label="Company Name"
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Name focused (employer profile)',
                  trigger: 'focus'
                }}
                testID="employer-profile-name-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Company Name</div>
              <div className="value">{name}</div>
            </div>
          )}
          {isEdit ? (
            <div className="formItem">
              <div className="label">Company Logo</div>
              <AvatarEditor
                ref={editorRef}
                image={newImageFile || imageUrl}
                width={200}
                height={200}
                border={0}
                borderRadius={0}
                color={[255, 255, 255, 0.6]}
                scale={1.1}
                rotate={0}
                style={{ border: '1px solid #ECECF5', cursor: 'move' }}
              />
              <p className="profile__imageTypeTip">{imageTypeHelperText}</p>
              <input
                accept="image/*"
                id="profile-image-input"
                type="file"
                onChange={handlePhotoUpload}
                style={{ display: 'none' }}
                {...qaAttr('employer-profile-photo-edit-input')}
              />
              <label htmlFor="profile-image-input">
                <Button
                  component="span"
                  variant="filled-primary"
                  disabled={profileUpdateLoading}
                  className="mainAction"
                  testID="employer-profile-photo-edit-button"
                >
                  Upload New
                </Button>
              </label>
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Company Logo</div>
              <img src={imageUrl || defaultEmployerImg} alt="" className="profile__imagePreview" />
            </div>
          )}
        </div>
        <div className="profile__formGroup">
          {isEdit ? (
            <div className="formItem">
              <Input
                {...$('address', handleFieldChange)}
                variant="textfield"
                id="address-input"
                required
                label="Street Address"
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Address focused (employer profile)',
                  trigger: 'focus'
                }}
                testID="employer-profile-address-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Street Address</div>
              <div className="value">{address}</div>
            </div>
          )}
          {renderAddressFields()}
          {isEdit ? (
            <div className="formItem">
              <PhoneInput
                {...$('phone', handleFieldChange)}
                variant="textfield"
                id="phone-input"
                required
                label="Phone number"
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Phone focused (employer profile)',
                  trigger: 'focus'
                }}
                testID="employer-profile-phone-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Phone number</div>
              <div className="value">{phone}</div>
            </div>
          )}
          {isEdit ? (
            <div className="formItem">
              <Select
                {...$('timeZone', changeTimeZone)}
                {...selectProps}
                label="Time zone"
                id="timeZone-select"
                analyticParams={{
                  key: 'Timezone focused (employer profile)',
                  trigger: 'focus'
                }}
                testID="employer-profile-time-zone"
                options={map(timeZones, (o, i) => ({
                  value: o.tzCode,
                  label: o.tzCode,
                  ...qaAttr(`employer-profile-time-zone-option-${i}`)
                }))}
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Time zone</div>
              <div className="value">{timeZone}</div>
            </div>
          )}
          {isEdit ? (
            <div className="formItem">
              <Input
                value={renderIndustriesString(industry)}
                readOnly
                variant="textfield"
                id="industries-input"
                required
                label="Industry"
                labelClassName="label"
                htmlInputClassName="ellipsisText"
                analyticParams={{
                  key: 'Industry focused (employer profile)',
                  trigger: 'focus'
                }}
                onClick={toggleIndustryModal}
                testID="employer-profile-industries-input"
              />
              {renderIndustryModal()}
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Industry</div>
              <div className="value ellipsisText">{renderIndustriesString(industry)}</div>
            </div>
          )}
          {isEdit ? (
            <div className="formItem">
              <Input
                value={size}
                readOnly
                variant="textfield"
                id="size-input"
                required
                label="Company Size"
                labelClassName="label"
                htmlInputClassName="ellipsisText"
                analyticParams={{
                  key: 'Company size focused (employer profile)',
                  trigger: 'focus'
                }}
                onClick={toggleSizeModal}
                testID="employer-profile-size-input"
              />
              {renderSizeModal()}
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Company Size</div>
              {size && (
                <div className="value">{`${size} ${size !== "Don't know" ? 'employees' : ''}`}</div>
              )}
            </div>
          )}
          {/* fake fields are a workaround for chrome autofill getting the wrong fields */}
          <input style={{ display: 'none' }} type="text" name="fakeuserwebsiteremembered" />
          {isEdit ? (
            <div className="formItem">
              <Input
                {...$('website', handleFieldChange)}
                variant="textfield"
                id="website-input"
                label="Company website"
                labelClassName="label"
                placeholder="http://example.com"
                autoComplete="off"
                withHelperText
                analyticParams={{
                  key: 'Website focused (employer profile)',
                  trigger: 'focus'
                }}
                testID="employer-profile-website-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Company Website</div>
              {website ? (
                <div className="value">
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    {website}
                  </a>
                </div>
              ) : (
                <div className="value">N/D</div>
              )}
            </div>
          )}
        </div>
      </StyledRoot>
    </SectionLayout>
  );
}

Profile.propTypes = {
  expanded: PT.bool.isRequired,
  fetchEmployerProfile: PT.func.isRequired,
  jumpLink: PT.string,
  onExpand: PT.func.isRequired,
  profile: PT.object.isRequired,
  sectionName: PT.string.isRequired,
  setExpandedSection: PT.func.isRequired
};

Profile.defaultProps = {
  jumpLink: ''
};

export default memo(Profile);
