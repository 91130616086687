import React, { useState, useEffect, useCallback } from 'react';
import PT from 'prop-types';
import format from 'date-fns/format';
import { qaAttr } from 'utils';
// import { useTimeZone } from 'hooks';
import { Dialog, styled, useMediaQuery } from 'components';
import styles from 'styles/Dashboard/EmployeeJobCard';
import Button, { IconButton } from '../Button';
import Spinner from '../Spinner';
import { MdArrowBack } from '../../icons';
import JobInterviewSchedule from './JobInterviewSchedule';
import useSchedule from './useSchedule';

const StyledDialog = styled(Dialog)(styles);

function EmployerInterviewScheduleModal(props) {
  const {
    isOpen,
    // date,
    // day,
    jobId,
    jobTitle,
    // employerName,
    employeeProfileId,
    onClose,
    onConfirm,
    DialogProps
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const employerProfileId = Number(localStorage.getItem('profileId'));
  const {
    getSelectedInterviewDateLoading,
    handleInterviewConfirm,
    inactiveDates,
    isScheduleOpen,
    postInterviewLoading,
    setIsScheduleOpen,
    selectedInterviewDate,
    setInterviewDate
  } = useSchedule({ isOpen, jobId, employeeProfileId, role: 'employer' });
  const [interviewDate, employerScheduleId] = selectedInterviewDate;
  const [isInterviewConfirmation, setIsInterviewConfirmation] = useState(false);

  const handleInterviewDateSelect = useCallback((...params) => {
    const date = params[0];
    setInterviewDate([...params]);
    setIsInterviewConfirmation(!!date);
  }, []);

  const confirmNewInterviewDate = async (...params) => {
    await handleInterviewConfirm(...params, () => {
      setIsInterviewConfirmation(false);
      setInterviewDate();
      onConfirm();
    });
  };

  return (
    <StyledDialog open={isScheduleOpen} fullScreen={!isDesktop} onClose={onClose} {...DialogProps}>
      <div className="mobileScheduleModal__header">
        <IconButton
          color="primary"
          sx={{ width: 0, height: 0 }}
          onClick={onClose}
          testID="schedule-modal-close-button"
        >
          <MdArrowBack />
        </IconButton>
        <div style={{ width: '100%', paddingLeft: 6, paddingRight: 6 }}>
          <h2 className="mobileScheduleModal__title">Select Interview Time</h2>
          <div className="mobileScheduleModal__subTitle">{`${jobTitle}`}</div>
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <JobInterviewSchedule
          role="employer"
          employerProfileId={employerProfileId}
          inactiveDates={inactiveDates}
          selectedDate={selectedInterviewDate}
          qaId={jobTitle}
          withCancel={false}
          withConfirmation={false}
          onDateSelect={handleInterviewDateSelect}
        />
      </div>
      {isInterviewConfirmation && interviewDate && (
        <div
          style={{
            marginBottom: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div className="mobileScheduleModal__confirmTitle">Confirm interview on</div>
          <div className="mobileScheduleModal__confirmDate">
            {format(interviewDate, "EEEE, MMM do 'at' hh:mm a?")}
          </div>
          <Button
            variant="filled-primary"
            disabled={postInterviewLoading}
            endIcon={postInterviewLoading ? <Spinner size={24} /> : null}
            sx={{ width: 162, height: 46 }}
            onClick={() => confirmNewInterviewDate(interviewDate, employerScheduleId)}
            testID="schedule-modal-submit-button"
          >
            Go get it!
          </Button>
        </div>
      )}
    </StyledDialog>
  );
}

EmployerInterviewScheduleModal.propTypes = {
  employeeProfileId: PT.number.isRequired,
  isOpen: PT.bool.isRequired,
  jobId: PT.number.isRequired,
  jobTitle: PT.string.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired
};

export default EmployerInterviewScheduleModal;
