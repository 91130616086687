import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { qaAttr } from 'utils';
import { IconButton } from 'components/shared';
import { MdPlayArrow } from 'components/icons';
import SectionProgressBar from './SectionProgressBar';

function CareerHealthScoreDetailRow({ sectionToExpand, expandSection, section, link }) {
  const { percent, impact, attributes, careerScoreType } = section;

  const sectionActionItem = () => {
    if (link && link.action) {
      switch (typeof link.action) {
        case 'function':
          return (
            <button
              className="listDetailActionItemButton"
              type="button"
              onClick={link.action}
              {...qaAttr(`stats-action-button-${link.text}`)}
            >
              <span>{link.text}</span>
              <MdPlayArrow className="listDetailActionItemText" />
            </button>
          );
        case 'string':
          return (
            <a
              className="listDetailActionItemAnchor"
              href={link.action}
              {...qaAttr(`stats-action-button-${link.text}`)}
            >
              <span>{link.text}</span>
              <MdPlayArrow className="listDetailActionItemText" />
            </a>
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div className="listDetailsContainer">
      <div className="listDetailsHeaderContainer">
        <div>
          <h2 className="listDetailsTitle">{careerScoreType.name}</h2>
          <div className="listDetailsInfo">
            <span>{percent}%</span> | <span className="listDetailImpactLevel">{impact.name}</span>
          </div>
        </div>
        <IconButton
          disabled={['Feedback', 'QH Match'].includes(careerScoreType.name)}
          variant=""
          aria-label="Expand Section"
          onClick={() => expandSection(section)}
          className="listDetailExpandButton"
        >
          <MdPlayArrow
            className={clsx(
              'listDetailExpandIcon',
              sectionToExpand === section && 'listDetailExpandIconActive'
            )}
          />
        </IconButton>
      </div>
      <SectionProgressBar percent={percent} />
      {sectionActionItem()}
      {attributes && (
        <ul className={sectionToExpand === section ? 'sectionToExpandActive' : 'sectionToExpand'}>
          {attributes.map((item) => (
            <li
              key={item.label}
              className={item.complete ? 'listDetailListItemComplete' : 'listDetailListItem'}
            >
              <span>{item.label}</span>
              <span>
                {item.complete ? '+' : ''}
                {item.value}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

CareerHealthScoreDetailRow.propTypes = {
  sectionToExpand: PropTypes.string,
  expandSection: PropTypes.func.isRequired,
  section: PropTypes.objectOf(PropTypes.any),
  link: PropTypes.objectOf(PropTypes.any)
};

CareerHealthScoreDetailRow.defaultProps = {
  sectionToExpand: null,
  section: {},
  link: null
};

export default CareerHealthScoreDetailRow;
