import React, { memo } from 'react';
import clsx from 'clsx';
import isAfter from 'date-fns/isAfter';
import { qaAttr } from 'utils';
import { Box, styled } from 'components';
import { Button, CheckBox, DatePicker, Input } from 'components/shared';
import { LinkOrRateCompany } from 'components/dialogs';
import { MdCheckCircle } from 'components/icons';
import styles from 'styles/Profile/EmployeeProfile';

const StyledRoot = styled('div')(styles);

const MAX_DATE = new Date();
const MIN_DATE = new Date(1900, 0, 1);

const educationValidations = ({ index }) => ({
  name: { presence: { allowEmpty: false } },
  pos: { presence: { allowEmpty: false } },
  endDate: {
    rules: [
      'date',
      (value, { attrs }) => {
        if (!value && attrs.education?.[index]?.currentJob) return;
        if (!value) return 'Cannot be blank';
      }
    ],
    partialDeps: ['currentJob', 'startDate']
  },
  startDate: {
    rules: [
      'presence',
      'date',
      (value, { attrs }) => {
        if (!value) return;
        const endDate = attrs.education?.[index]?.endDate;
        if (endDate && isAfter(value, endDate)) {
          return 'Must be less than or equal to end date';
        }
      }
    ],
    partialDeps: ['endDate']
  }
});

const experienceValidations = ({ index }) => ({
  name: { presence: { allowEmpty: false } },
  pos: { presence: { allowEmpty: false } },
  endDate: {
    rules: [
      {
        date: { earliest: MIN_DATE, latest: MAX_DATE, earliestFormat: 'yyyy', latestFormat: 'yyyy' }
      },
      (value, { attrs }) => {
        if (!value && attrs.experience?.[index]?.currentJob) return;
        if (!value) return 'Cannot be blank';
      }
    ],
    partialDeps: ['currentJob', 'startDate']
  },
  startDate: {
    rules: [
      'presence',
      {
        date: { earliest: MIN_DATE, latest: MAX_DATE, earliestFormat: 'yyyy', latestFormat: 'yyyy' }
      },
      (value, { attrs }) => {
        if (!value) return;
        const endDate = attrs.experience?.[index]?.endDate;
        if (endDate && isAfter(value, endDate)) {
          return 'Must be less than or equal to end date';
        }
      }
    ],
    partialDeps: ['endDate']
  }
});

const certificatesValidations = ({ index }) => ({
  name: { presence: { allowEmpty: false } },
  completionDate: [
    'presence',
    {
      date: { earliest: MIN_DATE, latest: MAX_DATE, earliestFormat: 'yyyy', latestFormat: 'yyyy' }
    }
  ]
});

function MultiFormItem(props) {
  const { usePartial, index, type, bordered, isEdit, onDelete } = props;
  const isEducationForm = type === 'education';
  const isExperienceForm = type === 'experience';
  const isCertificateForm = type === 'certificates';
  const labels = {
    education_name: 'Name',
    education_curJob: 'Currently Enrolled',
    education_pos: 'Study',
    education_start: 'Start Year',
    education_end: 'End Year',
    experience_name: 'Company',
    experience_curJob: 'Current Job',
    experience_pos: 'Position',
    experience_start: 'Start Date',
    experience_end: 'End Date',
    certificates_name: 'Certificate Name',
    certificates_date: 'Completion Date'
  };

  const { $, attrs, set, getError } = usePartial({
    prefix: `${type}.${index}`,
    validations: {
      ...(isEducationForm ? educationValidations({ index }) : {}),
      ...(isExperienceForm ? experienceValidations({ index }) : {}),
      ...(isCertificateForm ? certificatesValidations({ index }) : {})
    }
  });

  const handleValChange = (e, { name }) => {
    const field = name.split('.')[2];
    set(field, e.target.value);
  };

  const handleDateChange = (date, { name }) => {
    const field = name.split('.')[2];
    if (isExperienceForm || isCertificateForm) {
      const newDate = date ? new Date(date.getFullYear(), date.getMonth(), 1) : date;
      set(field, newDate);
    }
    if (isEducationForm) {
      const newDate = date ? new Date(date.getFullYear(), 0, 1) : date;
      set(field, newDate);
    }
  };

  const handleCheckBoxChange = (e) => {
    set('currentJob', e.target.checked);
    if (e.target.checked) set('endDate', null);
  };

  const getLabel = (inputName) => labels[`${type}_${inputName}`];

  const commonInputProps = {
    required: true,
    withHelperText: true,
    variant: 'textfield',
    readOnly: !isEdit,
    className: 'multiFormItem__input',
    labelClassName: 'label multiFormItem__label',
    helperTextClassName: 'multiFormItem__helperText'
  };

  const commonDatePickerInputProps = {
    required: true,
    className: 'multiFormItem__input',
    labelClassName: 'label multiFormItem__label',
    helperTextClassName: 'multiFormItem__helperText'
  };

  const renderEducationCheckBox = () => (
    <CheckBox
      checked={attrs.currentJob}
      label={getLabel('curJob')}
      labelPlacement="start"
      disabled={!isEdit}
      onChange={handleCheckBoxChange}
      checkboxProps={{
        inputProps: {
          id: `${type}.${index}.currentJob`,
          ...qaAttr(`${type}-currentJob-input-${index}`)
        }
      }}
      className="multiFormItem__checkBox"
      checkedIconProps={{ fontSize: 'inherit' }}
      {...qaAttr(`${type}-currentJob-checkbox-${index}`)}
    />
  );

  const renderExperienceLinking = () => (
    <Box mt="16px" display="flex" justifyContent="center">
      <LinkOrRateCompany
        exp={attrs}
        renderLinkButton={(linkButtonProps) => (
          <Button variant="outlined-secondary" className="linkCompanyBtn" {...linkButtonProps}>
            Connect to Employer
          </Button>
        )}
        renderRateButton={(rateButtonProps, rateIcon) => (
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <span className="linkCompanyText">Connected</span>
            <Button
              variant="outlined-secondary"
              className="rateCompanyBtn"
              endIcon={<span className="rateCompanyBtn__icon">{rateIcon}</span>}
              {...rateButtonProps}
            >
              Rating
            </Button>
          </Box>
        )}
      />
    </Box>
  );

  const renderEducationForm = () => (
    <>
      <div className="multiFormItem__inputItem">
        {renderEducationCheckBox()}
        <Input
          {...$('name', handleValChange)}
          id={`${type}.${index}.name`}
          label={getLabel('name')}
          analyticParams={{
            key: isExperienceForm
              ? 'Working place focused (employee profile)'
              : 'Study place focused (employee profile)',
            trigger: 'focus'
          }}
          testID={`${type}-name-input-${index}`}
          {...commonInputProps}
        />
      </div>
      <div className="multiFormItem__inputItem">
        <Input
          {...$('pos', handleValChange)}
          variant={isEdit ? 'textfield' : 'noedit'}
          readOnly={!isEdit}
          id={`${type}.${index}.pos`}
          label={getLabel('pos')}
          analyticParams={{
            key: isExperienceForm
              ? 'Work position focused (employee profile)'
              : 'Study focused (employee profile)',
            trigger: 'focus'
          }}
          testID={`${type}-pos-input-${index}`}
          {...commonInputProps}
        />
      </div>

      <Box display="flex" justifyContent="space-between">
        <DatePicker
          {...$('startDate', handleDateChange)}
          readOnly={!isEdit}
          disableOpenPicker={!isEdit}
          inputFormat={isEducationForm ? 'yyyy' : 'MM/yyyy'}
          maxDate={MAX_DATE}
          label={getLabel('start')}
          views={isEducationForm ? ['year'] : ['year', 'month']}
          mask={isEducationForm ? '____' : '__/____'}
          disableMaskedInput={false} // fix broken mask for month/year view
          OpenPickerButtonProps={{
            size: 'small',
            sx: { '& .MuiSvgIcon-root': { fontSize: '18px' } }
          }}
          InputComponentProps={{
            id: `${type}.${index}.startDate`,
            required: true,
            analyticParams: {
              key: `${
                isExperienceForm ? 'Work' : 'Education'
              } start date focused (employee profile)`,
              trigger: 'focus'
            },
            testID: `${type}-start-input-${index}`,
            ...commonDatePickerInputProps
          }}
        />
        <DatePicker
          {...$('endDate', handleDateChange)}
          value={attrs.currentJob ? null : attrs.endDate || null}
          readOnly={!isEdit}
          disableOpenPicker={!isEdit}
          inputFormat={isEducationForm ? 'yyyy' : 'MM/yyyy'}
          maxDate={MAX_DATE}
          // emptyInputText={attrs.currentJob ? 'Now' : ''}
          label={getLabel('end')}
          disabled={attrs.currentJob}
          views={isEducationForm ? ['year'] : ['year', 'month']}
          mask={isEducationForm ? '____' : '__/____'}
          disableMaskedInput={false} // fix broken mask for month/year view
          OpenPickerButtonProps={{
            size: 'small',
            sx: { '& .MuiSvgIcon-root': { fontSize: '18px' } }
          }}
          InputComponentProps={{
            id: `${type}.${index}.endDate`,
            required: !attrs.currentJob,
            FormControlProps: { sx: { marginLeft: '25px' } },
            analyticParams: {
              key: `${isExperienceForm ? 'Work' : 'Education'} end date focused (employee profile)`,
              trigger: 'focus'
            },
            testID: `${type}-end-input-${index}`,
            ...commonDatePickerInputProps
          }}
        />
      </Box>

      {!isEdit && isExperienceForm && attrs.experience_id && renderExperienceLinking()}
    </>
  );

  const renderCertificateForm = () => (
    <>
      <div className="multiFormItem__inputItem">
        <Input
          {...$('name', handleValChange)}
          id={`${type}.${index}.name`}
          label={getLabel('name')}
          analyticParams={{
            key: 'Certificate name focused (employee profile)',
            trigger: 'focus'
          }}
          testID={`${type}-name-input-${index}`}
          {...commonInputProps}
        />
      </div>
      <Box display="flex">
        <DatePicker
          {...$('completionDate', handleDateChange)}
          readOnly={!isEdit}
          disableOpenPicker={!isEdit}
          inputFormat="MM/yyyy"
          mask="__/____"
          disableMaskedInput={false} // fix broken mask for month/year view
          maxDate={MAX_DATE}
          label={getLabel('date')}
          views={['year', 'month']}
          OpenPickerButtonProps={{
            size: 'small',
            sx: { '& .MuiSvgIcon-root': { fontSize: '18px' } }
          }}
          InputComponentProps={{
            id: `${type}.${index}.date`,
            required: true,
            FormControlProps: { sx: { flex: 1 } },
            analyticParams: {
              key: 'Certificate completion date focused (employee profile)',
              trigger: 'focus'
            },
            testID: `${type}-date-input-${index}`,
            ...commonDatePickerInputProps
          }}
        />
        <Box flex={1} ml="14px" mt="-3px">
          <Box mb="10px" display="flex" justifyContent="space-between" alignItems="center">
            <span className="label multiFormItem__label" style={{ marginBottom: 0 }}>
              Complete
            </span>
            <MdCheckCircle
              className={clsx('certificates__checkIcon', attrs.complete && 'certificates_checked')}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <span className="label multiFormItem__label" style={{ marginBottom: 0 }}>
              Verified
            </span>
            <MdCheckCircle
              className={clsx(
                'certificates__checkIcon',
                !!attrs.validatedByEmployerProfileId && 'certificates_checked'
              )}
            />
          </Box>
        </Box>
      </Box>
    </>
  );

  const renderContent = () => {
    if (isExperienceForm || isEducationForm) return renderEducationForm();
    if (isCertificateForm) return renderCertificateForm();
    return null;
  };

  return (
    <StyledRoot>
      <div
        className={clsx('multiFormItemOuter', bordered && 'multiFormItemOuter_withBorderedInner')}
      >
        {isEdit && (
          <Box pb="10px" display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              className="multiFormItem__headerAction"
              {...qaAttr(`${type}-remove-form-${index}`)}
              onClick={() => onDelete(type, index)}
            >
              Remove
            </Button>
          </Box>
        )}
        <div
          className={clsx(
            'multiFormItemInner',
            bordered && 'multiFormItemInner_bordered',
            bordered && attrs.currentJob && 'multiFormItemInner_bordered_main'
          )}
        >
          {renderContent()}
        </div>
      </div>
    </StyledRoot>
  );
}

export default memo(MultiFormItem);
