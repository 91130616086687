import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { track } from 'utils/segmentAnalytics';
import { MuiRadioGroup, styled } from 'components';
import Radio from './Radio';

function RadioGroup(props) {
  const {
    analyticParams,
    className,
    children,
    data,
    disabled,
    onChange,
    onFocus,
    RadioProps,
    ...rest
  } = props;

  const handleFocus = (e) => {
    if (analyticParams && analyticParams.trigger === 'focus' && analyticParams.key) {
      track(analyticParams.key, analyticParams?.params);
    }
    if (onFocus) onFocus(e);
  };

  return (
    <MuiRadioGroup className={className} onChange={onChange} {...rest}>
      {data && data.length > 0
        ? map(data, (o, i) => (
            <Radio
              key={`radio__${i}`}
              disabled={disabled}
              label={o.label || ''}
              value={o.value}
              radioProps={{
                inputProps: { onFocus: handleFocus }
              }}
              testID={o.value}
              {...RadioProps}
            />
          ))
        : children}
    </MuiRadioGroup>
  );
}

RadioGroup.propTypes = {
  analyticParams: PropTypes.shape({
    trigger: PropTypes.oneOf(['focus']),
    key: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any)
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  RadioProps: PropTypes.objectOf(PropTypes.any)
};

RadioGroup.defaultProps = {
  analyticParams: { key: '', trigger: 'focus' },
  children: null,
  className: '',
  data: [],
  disabled: false,
  onFocus: () => {},
  RadioProps: {}
};

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  width: '100%',
  flexWrap: 'initial',
  overflow: 'auto',
  borderRadius: 10,
  border: '1px solid rgba(0,0,0,0.1)',
  '& .MuiFormControlLabel-root': {
    border: 'none',
    borderRadius: 0
  },
  '& .MuiFormControlLabel-root:not(:last-child)': {
    borderBottom: '1px solid rgba(0,0,0,0.1)'
  },
  '& .MuiFormControlLabel-root:last-child': {
    borderBottom: 'none'
  }
}));

export default StyledRadioGroup;
