import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { qaAttr } from 'utils';
import { Backdrop, styled } from 'components';
import { MdArrowBack, MdClose, MdCheck, MdStarBorder } from 'components/icons';
import Button, { IconButton } from 'components/shared/Button';
import styles from 'styles/Dashboard/EmployeeMobileManual';
import swipeRightArrow from 'assets/img/swipe_right.png';
import swipeLeftArrow from 'assets/img/swipe_left.png';

function EmployeeDashboardMobileManual({ className, deckHeight, isOpen, onComplete }) {
  return (
    <Backdrop open={isOpen} className={className}>
      <div className="contentContainer">
        <div
          className="deckContainer"
          style={{ minHeight: deckHeight > window.innerHeight - 123 ? 100 : deckHeight }}
        >
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 32, textAlign: 'center' }}>
              <img
                src={swipeRightArrow}
                width={32}
                height={18}
                alt=""
                style={{ marginBottom: 12 }}
              />
              <div className="text">Swipe right to apply</div>
            </div>
            <div style={{ marginBottom: 32, textAlign: 'center' }}>
              <img
                src={swipeLeftArrow}
                width={32}
                height={18}
                alt=""
                style={{ marginBottom: 12 }}
              />
              <div className="text">Swipe left to pass</div>
            </div>
            <Button
              variant="filled-primary"
              sx={{ height: 50, width: 219 }}
              onClick={onComplete}
              {...qaAttr('got-it-button')}
            >
              Got it
            </Button>
          </div>
        </div>
        <div className="actions">
          <div style={{ position: 'relative' }}>
            <div className="text actionTip" style={{ top: -42 }}>
              Previous
            </div>
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="previous"
              sx={{ width: 42, height: 42, backgroundColor: '#fff' }}
            >
              <MdArrowBack />
            </IconButton>
          </div>
          <div style={{ position: 'relative', marginLeft: 24 }}>
            <div className="text actionTip" style={{ top: -34 }}>
              Pass
            </div>
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="reject"
              sx={{ width: 58, height: 58, fontSize: 32, backgroundColor: '#fff' }}
            >
              <MdClose fontSize="inherit" />
            </IconButton>
          </div>
          <div style={{ position: 'relative', marginLeft: 24 }}>
            <div className="text actionTip" style={{ top: -34 }}>
              Apply
            </div>
            <IconButton
              variant="filled-primary"
              color="primary"
              aria-label="accept"
              sx={{ width: 58, height: 58, fontSize: 32 }}
            >
              <MdCheck fontSize="inherit" />
            </IconButton>
          </div>
          <div style={{ position: 'relative', marginLeft: 24 }}>
            <div className="text actionTip" style={{ top: -42 }}>
              Save
            </div>
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="star"
              sx={{ width: 42, height: 42, backgroundColor: '#fff' }}
            >
              <MdStarBorder />
            </IconButton>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

EmployeeDashboardMobileManual.propTypes = {
  className: PropTypes.string.isRequired,
  deckHeight: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};

const Styled = styled(EmployeeDashboardMobileManual)(styles);

export default memo(Styled);
