import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Box, Dialog, styled } from 'components';
import { Button, CheckBox } from 'components/shared';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { qaAttr } from 'utils';
import EmployerGrowContext from '../EmployerGrowContext';

import jobScoresImg from '../assets/images/jobScores.png';
import employeeSatisfactionImg from '../assets/images/employeeSatisfaction.png';
import companyBreakdownImg from '../assets/images/companyBreakdown.png';
import jobStatsImg from '../assets/images/jobStats.png';
import avgTenImg from '../assets/images/avgTen.png';
import hiringRateImg from '../assets/images/hiringRate.png';
// import equityScoreImg from '../assets/images/equityScore.png';
import competitionBreakdownImg from '../assets/images/competitionBreakdown.png';
import employeeChurnRateImg from '../assets/images/employeeChurnRate.png';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .content': {
    padding: '20px 16px'
  },
  '& .title': {
    marginBottom: 20,
    fontSize: 20
  },
  '& .container': {
    marginBottom: 15,
    display: 'flex',
    flexFlow: 'row wrap'
  },
  '& .item': {
    width: '100%',
    maxWidth: '50%',
    padding: '2px 0',
    borderBottom: '1px solid #CECECE'
  },
  '& .label': {
    marginLeft: 15,
    fontSize: 16,
    lineHeight: '17px'
  },
  '& .checkboxContainer': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .checkbox': {
    position: 'absolute',
    zIndex: -1
  },
  '& .checkedImg': {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5
  },
  '& .formControlRoot': {
    minHeight: 40,
    width: '100%',
    padding: '0 16px',
    border: 'none'
  },
  '& .button': {
    height: 30,
    width: 80,
    borderRadius: 18,
    '&:last-of-type': {
      marginLeft: 10
    }
  }
}));

const SECTIONS = [
  { label: 'Job Post Scores', value: 'jobScores', img: jobScoresImg },
  { label: 'Employee Satisfaction', value: 'employeeSatisfaction', img: employeeSatisfactionImg },
  { label: 'Company Breakdown', value: 'companyBreakdown', img: companyBreakdownImg },
  { label: 'Job Stats', value: 'jobStats', img: jobStatsImg },
  { label: 'Avg. Tenure', value: 'avgTen', img: avgTenImg },
  { label: 'Hiring Rate', value: 'hiringRate', img: hiringRateImg },
  // { label: 'Equity Score', value: 'equityScore', img: equityScoreImg },
  { label: 'Competition Breakdown', value: 'competitionBreakdown', img: competitionBreakdownImg },
  { label: 'Employee Churn Rate', value: 'employeeChurnRate', img: employeeChurnRateImg }
];

const getInitState = (ctx) =>
  reduce(
    SECTIONS,
    (result, o) => {
      const res = { ...result };
      if (ctx) res[o.value] = ctx[o.value] || false;
      return res;
    },
    {}
  );

function GrowSettingsModal(props) {
  const { isOpen, onClose, onConfirm } = props;
  const { showJobsAnalytics, updateEmployerCtx, ...ctx } = useContext(EmployerGrowContext);
  const [selected, setSelected] = useState(() => getInitState(ctx));

  useEffect(() => {
    if (isOpen) setSelected(getInitState(ctx));
  }, [isOpen]);

  const handleChange = (e) => {
    const event = e.nativeEvent;
    setSelected((prev) => ({ ...prev, [event.target.value]: event.target.checked }));
  };

  const confirm = () => {
    onConfirm({ equityScore: true, ...selected });
    onClose();
  };

  return (
    <StyledDialog open={isOpen} fullWidth onClose={onClose}>
      <div className="content">
        <h1 className="title">Choose Charts to display</h1>
        <div className="container">
          {map(SECTIONS, (o, i, arr) => {
            const checked = selected[o.value];

            return (
              <Fragment key={`item__${i}`}>
                <div className="item">
                  <label htmlFor={o.value} className="checkboxContainer">
                    {o.img && (
                      <img
                        src={o.img}
                        alt=""
                        width="50px"
                        className={`${checked ? 'checkedImg' : ''}`}
                      />
                    )}
                    <input
                      type="checkbox"
                      checked={checked}
                      id={o.value}
                      value={o.value}
                      className="checkbox"
                      onChange={handleChange}
                    />
                    <span className="label">{o.label}</span>
                  </label>
                  {/* <CheckBox
                    variant="default"
                    checked={checked}
                    label={o.label || ''}
                    value={o.value}
                    radioProps={{
                      inputProps: { ...qaAttr(`grow-settings-radio-input-${o.value}`) }
                    }}
                    classes={{
                      root: 'formControlRoot'
                    }}
                    onChange={handleChange}
                    {...qaAttr(`grow-settings-radio-${o.value}`)}
                  /> */}
                </div>
                {i === arr.length - 1 && arr.length % 2 !== 0 && <div className="item" />}
              </Fragment>
            );
          })}
        </div>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="filled-primary"
            className="button"
            onClick={onClose}
            testID="grow-settings-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="filled-primary"
            className="button"
            onClick={confirm}
            testID="grow-settings-ok-button"
          >
            OK
          </Button>
        </Box>
      </div>
    </StyledDialog>
  );
}

export default GrowSettingsModal;
