import React, { useState, useRef, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

export default function useGetPlacePredictions(config = {}) {
  const { wait = 800 } = config;
  const [loading, setLoading] = useState(false);
  const AutocompleteService = useRef();

  const getPredictions = useCallback((request, callback) => {
    if (AutocompleteService.current) {
      if (request.input) {
        setLoading(true);
        const req = {
          types: ['(cities)'],
          componentRestrictions: {
            country: 'us'
          },
          ...request
        };
        AutocompleteService.current.getPlacePredictions(req, (predictions, status) => {
          setLoading(false);
          callback(predictions, status);
        });
      } else {
        callback([], '');
      }
    }
  } , []);

  const getPlacePredictions = useCallback(debounce(getPredictions, wait), [getPredictions, wait]);

  useEffect(() => {
    AutocompleteService.current = google ? new google.maps.places.AutocompleteService(null) : undefined;
  }, []);

  return { getPlacePredictions, loading };
};
