import React from 'react';
import { SvgIcon } from 'components';

function MdAddChart(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} enableBackground="new 0 0 24 24">
      <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z" />
    </SvgIcon>
  );
}

export default MdAddChart;
