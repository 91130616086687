import React, { useState, useEffect, useContext, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import growApi from 'components/Grow/api';
import { IconButton, Spinner } from 'components/shared';
import InputAlertInput from 'components/shared/InputAlert/InputAlertInput';
import { MdAddChart, MdArrowBack, MdPersonPin, MdSettings } from 'components/icons';
import { qaAttr, getProfileId } from 'utils';
import { useAlerts } from 'hooks';
import { makeQueryFetch, makeMutation } from '../../api/util';
import ChartToolbar from './ChartToolbar';
import TeamChartColumns from './ChartColumns';
import ChartTitle from './ChartTitle';
import EmployerGrowContext from '../../EmployerGrowContext';

// eslint-disable-next-line react/prop-types
function WelcomeMessaging({ employeesCount }) {
  return (
    <div className="chartWelcomeContainer">
      <h1 className="chartWelcomeTitle">Welcome!</h1>
      <p>This is the Team Structure Tool for QuickHire Grow.</p>
      {employeesCount > 0 ? (
        <p>
          To get started, click the new chart icon{' '}
          <span className="chartWelcomeIcon">
            <MdAddChart style={{ fill: '#4743A2' }} />
          </span>{' '}
          on the right toolbar
        </p>
      ) : (
        <p style={{ maxWidth: 500, margin: '0 auto' }}>
          To get started, click the invite icon{' '}
          <span className="chartWelcomeIcon">
            <MdPersonPin style={{ fill: '#4743A2' }} />
          </span>{' '}
          on the right toolbar to invite your employees and get structuring!
        </p>
      )}
    </div>
  );
}

function TeamChart() {
  const { setSimpleAlert, setInputAlert } = useAlerts();
  const employerCtx = useContext(EmployerGrowContext);
  const {
    currentChartIdx,
    employerCharts,
    updateEmployerCtx,
    isEditable,
    employerCode,
    employees,
    showSettings
  } = employerCtx;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const chartNamesRef = useRef(null);
  const chartColumnsRef = useRef(null);

  const setErrorModal = (subtitle, title) => {
    setSimpleAlert({
      isOpen: true,
      title: 'QuickHire Grow',
      subtitle
    });
  };

  const setCurrentChartIdx = (idx) => {
    employerCtx.updateEmployerCtx({
      currentChartIdx: idx
    });
  };

  const fetchEmployerChartData = async () => {
    setIsDataLoading(true);

    const employerCode = await makeQueryFetch({
      query: growApi.query.EMPLOYER_CODE_BY_EMPLOYER,
      path: 'employerCodeByEmployer'
    }).then((data) => data.code);
    const data = await makeQueryFetch({
      query: growApi.query.EMPLOYER_STRUCTURE_CHART_BY_ID,
      path: 'employerStructureChartById'
    });
    if (data) updateEmployerCtx({ employerCharts: data, employerCode });
    else {
      updateEmployerCtx({ employerCode });
      setErrorModal('To get started create a chart and add your team members');
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    fetchEmployerChartData();
  }, []);

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    const { id: destinationColumnId, index: destinationColumnIndex } = JSON.parse(
      destination.droppableId
    );
    const { id: sourceColumnId, index: sourceColumnIndex } = JSON.parse(source.droppableId);
    const newLayout = [...employerCharts];

    const droppableEmp = get(
      newLayout,
      `[${currentChartIdx}].columns[${sourceColumnIndex}].employeeProfiles[${result.source.index}]`,
      null
    );
    if (!droppableEmp) {
      setErrorModal(`There was an error with the employee you just dropped, please try again`);
      return;
    }

    // remove card from source column and store it
    const employeeCard = newLayout[currentChartIdx].columns[
      sourceColumnIndex
    ].employeeProfiles.splice(result.source.index, 1)[0];

    // add to new index at destination column id
    newLayout[currentChartIdx].columns[destinationColumnIndex].employeeProfiles.splice(
      result.destination.index,
      0,
      employeeCard
    );

    const saveCardPlacement = async (colIdx, colId) => {
      const cards = newLayout[currentChartIdx].columns[colIdx].employeeProfiles.map(
        ({ id }, index) => ({
          id,
          index,
          columnId: colId
        })
      );
      const cardResp = await makeMutation({
        mutation: growApi.mutation.PUT_EMPLOYEE_CARDS_TO_COLUMN,
        variables: { cards },
        path: 'putEmployeeCardsToColumn'
      });

      if (!cardResp.success) {
        setErrorModal(
          'There was an error saving the card you placed, if this persists, please contact support'
        );
      }
    };

    // save chart cards by column
    if (sourceColumnIndex !== destinationColumnIndex) {
      // Not the same column, save destination column as well
      await saveCardPlacement(destinationColumnIndex, destinationColumnId);
    }

    await saveCardPlacement(sourceColumnIndex, sourceColumnId);

    // setChangesMade(true);
    updateEmployerCtx({ employerCharts: newLayout });
  };

  const handleChartNameChange = (id) => (inputValue) => {
    employerCtx.updateEmployerCtx({
      chartsToUpdate: {
        ...(employerCtx.chartsToUpdate || {}),
        [id]: inputValue
      }
    });
  };

  const deleteChart = () => {
    const doDeleteChart = async () => {
      try {
        const deleteResp = await makeMutation({
          mutation: growApi.mutation.DELETE_EMPLOYER_CHART_BY_ID,
          variables: {
            chartId: employerCharts[currentChartIdx].id
          },
          path: 'deleteEmployerChartById'
        });
        if (!deleteResp.success) {
          throw new Error(
            'Deleting the chart has failed. If the problem persists, please contact support'
          );
        } else {
          const newLayout = [...employerCharts];
          newLayout.splice(currentChartIdx, 1);
          updateEmployerCtx({ employerCharts: newLayout, currentChartIdx: 0 });
        }
      } catch (err) {
        console.log(err);
        setErrorModal(
          'Deleting the chart has failed. If the problem persists, please contact support'
        );
      }
    };

    const alertMessage =
      employerCharts.length === 1
        ? 'Looks like this is the last chart in your team structure setup, are you sure you want to delete it?'
        : `Are you sure you want to delete the "${employerCharts[currentChartIdx].name}" chart?`;

    setSimpleAlert({
      isOpen: true,
      title: 'Delete Chart?',
      subtitle: alertMessage,
      onSuccess: doDeleteChart
    });
  };

  const copyChart = async () => {
    try {
      const newLayout = [...employerCharts];
      const newChart = await makeMutation({
        mutation: growApi.mutation.COPY_CHART,
        variables: {
          chartId: employerCharts[currentChartIdx].id
        },
        path: 'copyChart'
      });
      if (newChart) {
        updateEmployerCtx({
          employerCharts: [...newLayout, newChart],
          currentChartIdx: newLayout.length - 1
        });
      } else {
        throw new Error('There was an error creating the chart');
      }
    } catch (err) {
      console.log(err);
      setErrorModal(
        'There was an error copying the chart, if this persists, please contact support'
      );
    }
  };

  const createChart = () => {
    const newLayout = [...employerCharts];
    let newChartName = '';

    const doCreate = async () => {
      // create new chart
      const newChart = await makeMutation({
        mutation: growApi.mutation.CREATE_CHART,
        variables: {
          name: newChartName
        },
        path: 'createChart'
      });

      if (newChart) {
        newLayout.push(newChart);
        updateEmployerCtx({ employerCharts: newLayout, currentChartIdx: newLayout.length - 1 });
      } else {
        setErrorModal(
          'There was an error creating the chart, if this persists, please contact support'
        );
      }
    };

    const handleNewChartNameOnBlur = (chartInput) => {
      newChartName = chartInput;
    };

    setInputAlert({
      isOpen: true,
      title: 'Create Chart',
      subtitle: '',
      onSuccess: doCreate,
      inputs: <InputAlertInput label="New Chart Name" handleOnBlur={handleNewChartNameOnBlur} />
    });
  };

  const editChart = () => employerCtx.updateEmployerCtx({ isEditable: true });

  const saveChartAndColumns = async () => {
    const { chartsToUpdate, columnsToUpdate } = employerCtx;
    // update the columns
    if (columnsToUpdate) {
      const formattedColumns = Object.keys(columnsToUpdate).map((key) => ({
        chartId: employerCharts[currentChartIdx].id,
        name: columnsToUpdate[key].name,
        index: columnsToUpdate[key].index,
        id: columnsToUpdate[key].id
      }));
      const columnUpdateResp = await makeMutation({
        mutation: growApi.mutation.UPDATE_CHART_COLUMNS_BY_INDEX,
        variables: {
          columns: formattedColumns
        },
        path: 'updateChartColumnsByIndex'
      });
      if (!columnUpdateResp.success) {
        setErrorModal(
          'There was an error saving the changes, if this persists, please contact support'
        );
      }
    }
    // update the charts
    if (chartsToUpdate) {
      const formattedCharts = Object.keys(chartsToUpdate).map((key) => ({
        name: chartsToUpdate[key],
        id: Number(key)
      }));
      const chartUpdateResp = await makeMutation({
        mutation: growApi.mutation.UPDATE_EMPLOYER_CHARTS_BY_ID,
        variables: {
          charts: formattedCharts
        },
        path: 'updateEmployerChartsById'
      });
      if (!chartUpdateResp.success) {
        setErrorModal(
          'There was an error updating your chart, if this persists, please contact support'
        );
      }
    }
    // remove values from context on update
    updateEmployerCtx({
      chartsToUpdate: {},
      columnsToUpdate: {},
      isEditable: false
    });
  };

  const createColumn = async () => {
    try {
      const newLayout = [...employerCharts];
      const createColumnResp = await makeMutation({
        mutation: growApi.mutation.CREATE_CHART_COLUMN_BY_INDEX,
        variables: {
          index: newLayout[currentChartIdx].columns.length,
          name: 'Untitled',
          chartId: newLayout[currentChartIdx].id
        },
        path: 'createChartColumnByIndex'
      });
      newLayout[currentChartIdx].columns.push({
        ...createColumnResp,
        employeeProfiles: []
      });
      updateEmployerCtx({ employerCharts: newLayout, isEditable: true });

      chartColumnsRef.current.scrollTo({
        left: newLayout[currentChartIdx].columns.length * 240 + 240,
        behavior: 'smooth'
      });
    } catch (err) {
      console.log(err);
      setErrorModal(
        'There was an error creating a column, if this persists, please contact support'
      );
    }
  };

  const deleteColumn = (idx, chartIdx, charts) => (e) => {
    const newLayout = [...charts];
    const doDeleteColumn = async () => {
      if (newLayout[chartIdx].columns.length === 1) {
        // last column in the chart
        setTimeout(() => {
          setSimpleAlert({
            isOpen: true,
            title: 'Wait a Second',
            subtitle: `It looks like you're trying to delete the last column of the chart. If you do not want this chart any longer, please delete the chart with the trash icon on the toolbar.`
          });
        }, 100);
        return;
      }
      try {
        const updatedChart = await makeMutation({
          mutation: growApi.mutation.DELETE_CHART_COLUMN_BY_INDEX,
          variables: {
            chartId: newLayout[chartIdx].id,
            columnIndex: idx
          },
          path: 'deleteEmployerChartColumnByIndex'
        });
        if (updatedChart) {
          newLayout[chartIdx] = updatedChart;
          updateEmployerCtx({ employerCharts: newLayout, currentChartIdx: chartIdx });
        } else {
          throw new Error('Error deleting charts');
        }
      } catch (err) {
        console.log(err);
        setErrorModal(
          'There was an error deleting your column, if this persists, please contact support'
        );
      }
    };

    setSimpleAlert({
      isOpen: true,
      title: 'Delete Column?',
      subtitle: `Are you sure you want to delete this column? Any employees under it will be automatically moved to the first column.`,
      onSuccess: doDeleteColumn
    });
  };

  const deleteEmployee = (employeeProfileId) => {
    const doDeleteEmployee = async (employeeProfileProfileId) => {
      const employerProfileId = getProfileId();

      if (employerProfileId && employeeProfileProfileId) {
        await makeMutation({
          mutation: growApi.mutation.DELETE_EMPLOYEE_FROM_EMPLOYER_GROW,
          variables: {
            employeeProfileProfileId: parseInt(employeeProfileProfileId),
            employerProfileProfileId: parseInt(employerProfileId)
          },
          path: 'removeEmployee'
        });
        fetchEmployerChartData();
      }
    };

    setSimpleAlert({
      isOpen: true,
      title: 'Delete employee?',
      subtitle:
        'Are you sure you want to remove this employee? It will remove them from your company entirely',
      onSuccess: () => doDeleteEmployee(employeeProfileId),
      onCancel: () => setSimpleAlert({ isOpen: false })
    });
  };

  useEffect(() => {
    if (!isEmpty(employerCharts))
      employerCtx.updateEmployerCtx({
        isEditable: false,
        deleteColumn,
        deleteEmployee
      });
  }, [employerCharts]);

  return (
    <div>
      <div className="header">
        <h1 className="headerText" {...qaAttr('team-structure-title')}>
          Team Structure Tool - Employer Code: {employerCode}
        </h1>
        <div className="headerButtonContainer">
          <IconButton color="primary" onClick={showSettings}>
            <MdSettings />
          </IconButton>
        </div>
      </div>
      <div className="teemStructureContainer">
        {isDataLoading && (
          <div className="chartLoaderContainer">
            <Spinner size={48} />
          </div>
        )}
        <div className="chartContainerNames">
          <div className="chartContainerNamesParent" ref={chartNamesRef}>
            {employerCharts.map((chart, idx) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                style={{
                  backgroundColor: currentChartIdx === idx ? '#fff' : ''
                }}
                onClick={isEditable ? null : () => setCurrentChartIdx(idx)}
                key={chart.name}
                {...qaAttr(`team-structure-chart-button-${chart.name}`)}
              >
                <ChartTitle
                  sx={{
                    '& .StyledInput-inputBase__input': {
                      padding: '.75rem'
                    }
                  }}
                  FormControlProps={{
                    sx: { minWidth: 240, borderLeft: '1px solid #e4e4e4' }
                  }}
                  onClick={isEditable ? null : () => setCurrentChartIdx(idx)}
                  titleValue={chart.name}
                  handleOnBlur={handleChartNameChange(chart.id)}
                  testID={`team-structure-chart-input-${chart.name}`}
                />
              </div>
            ))}
          </div>
          <div className="navigateCharts">
            <IconButton
              disabled={currentChartIdx === 0 || employerCharts.length === 0}
              color="primary"
              onClick={() => {
                setCurrentChartIdx(currentChartIdx - 1);
                chartNamesRef.current.scrollTo({
                  left: chartNamesRef.current.scrollLeft - 240,
                  behavior: 'smooth'
                });
              }}
              withTooltip
              toolTipProps={{
                title: 'Previous Chart',
                placement: 'top',
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'white',
                    boxShadow: '0 0 4px rgba(0, 0, 0, .25)'
                  }
                }
              }}
              testID="team-structure-prev-chart-button"
            >
              <MdArrowBack />
            </IconButton>
            <IconButton
              disabled={
                currentChartIdx === employerCharts.length - 1 || employerCharts.length === 0
              }
              color="primary"
              onClick={() => {
                setCurrentChartIdx(currentChartIdx + 1);
                chartNamesRef.current.scrollTo({
                  left: chartNamesRef.current.scrollLeft + 240,
                  behavior: 'smooth'
                });
              }}
              withTooltip
              toolTipProps={{
                title: 'Next Chart',
                placement: 'top',
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'white',
                    boxShadow: '0 0 4px rgba(0, 0, 0, .25)'
                  }
                }
              }}
              sx={{
                transform: 'rotate(180deg)'
              }}
              testID="team-structure-prev-next-button"
            >
              <MdArrowBack />
            </IconButton>
          </div>
        </div>
        <div className="chartColumnsContainer">
          <div className="chartColumnsParent" ref={chartColumnsRef}>
            {employerCharts.length === 0 ? (
              <WelcomeMessaging employeesCount={employees.length} />
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <TeamChartColumns
                  columns={get(employerCharts, `[${currentChartIdx}].columns`, [])}
                  chartId={get(employerCharts, '[currentChartIdx].id', null)}
                />
              </DragDropContext>
            )}
          </div>
          <ChartToolbar
            deleteChart={deleteChart}
            copyChart={copyChart}
            createChart={createChart}
            editChart={editChart}
            createColumn={createColumn}
            saveChartAndColumns={saveChartAndColumns}
            fetchEmployerChartData={fetchEmployerChartData}
          />
        </div>
      </div>
    </div>
  );
}

export default TeamChart;
