import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useMatch, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { getRoutes, qaAttr } from 'utils';
import { useNotificationsSubscribe, useCommonUI, useAuth } from 'hooks';
import { styled, Tabs, Tab, useMediaQuery } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdInsights } from 'components/icons';
import styles from 'styles/Dashboard/Header';
import ChatButton from './ChatButton';
import LogoLink from './LogoLink';
import ProfileLink from './ProfileLink';
import ReturnLink from './ReturnLink';
import LogoutButton from './LogoutButton';
import PublicHeader from './PublicHeader';
import HeaderTemplate from './HeaderTemplate';

const ROUTES = getRoutes();

// eslint-disable-next-line react/prop-types
function EmployeeHeader({ className }) {
  // const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname } = location;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { authed: isAuthenticated } = useAuth();
  const { subscribe: subscribeToNotifications, isNewMessage } = useNotificationsSubscribe({
    role: 'employee'
  });
  const { employeeJobsTab, setEmployeeJobsTab } = useCommonUI();

  const isCareerDevelopmentAllowed = process.env.ALLOW_CAREER_DEVELOPMENT === 'true';

  const isCareerDevelopment = isCareerDevelopmentAllowed && pathname === ROUTES.employee.grow;
  const isDashboard = pathname === ROUTES.employee.dashboard;
  const isEmployeeProfile = pathname === ROUTES.employee.profile;
  const isSkillsProgress = pathname === ROUTES.employee.progress_skills;
  const isConnectionsProgress = pathname === ROUTES.employee.progress_connections;
  const isStillHired = pathname === ROUTES.employee.progress_hired;
  const matchJob = useMatch(ROUTES.employee.job); // ! Warning: could match with other employee routes
  const isEmployeeJobPage = matchJob?.pathname === getRoutes({ id: idParam }).employee.job;

  useEffect(() => {
    subscribeToNotifications();
  }, []);

  const handleJobsTabChange = useCallback(
    (e, val) => {
      if (!isDashboard) navigate(ROUTES.employee.dashboard, { replace: true });
      setEmployeeJobsTab(val);
    },
    [isDashboard]
  );

  const renderLogo = (logoProps) => <LogoLink key="header-logo" {...logoProps} />;

  const renderLogoutButton = () => <LogoutButton key="header-logout" />;

  const renderJobsPageTabs = () => (
    <Tabs
      key="jobsPageTabs"
      value={employeeJobsTab}
      aria-label="content tabs"
      className="employeeJobsTabs headerElement"
      onChange={handleJobsTabChange}
    >
      <Tab label="All Jobs" value={0} className="employeeJobsTab" {...qaAttr('all-jobs-button')} />
      <Tab label="My Jobs" value={1} className="employeeJobsTab" {...qaAttr('my-jobs-button')} />
    </Tabs>
  );

  const renderGrowIconButton = () => (
    <IconButton
      key="header-grow"
      variant={isCareerDevelopment ? 'filled-primary' : 'outlined'}
      color="primary"
      aria-label="profile"
      className="headerElement"
      onClick={() => (isCareerDevelopment ? null : navigate(ROUTES.employee.grow))}
      testID="qh-grow-button"
    >
      <MdInsights />
    </IconButton>
  );

  const renderGrowButton = () => (
    <Button
      key="growButton"
      endIcon={<MdInsights />}
      variant="filled-secondary"
      className="employeeCareerDevButton headerElement"
      onClick={() => (isCareerDevelopment ? null : navigate(ROUTES.employee.grow))}
      testID="qh-grow-button"
    >
      New! QH Grow
    </Button>
  );

  const renderChatButton = () => (
    <ChatButton key="header-chat" userRole="employee" isNewMessage={isNewMessage} />
  );

  const renderProfileButton = () => <ProfileLink key="header-profile" userRole="employee" />;

  const renderBackButton = (title = '', btnProps = {}) => (
    <ReturnLink key="header-back" title={title} {...btnProps} />
  );

  const renderContent = () => {
    switch (true) {
      case isCareerDevelopment: {
        const left = [
          renderBackButton('View Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderChatButton(), renderGrowIconButton(), renderProfileButton()]
          : [renderChatButton()];

        return (
          <HeaderTemplate
            title="QH Grow Portal"
            titleQaId="enterprise-dashboard-title"
            leftElements={left}
            rightElements={right}
          />
        );
      }
      case isDashboard: {
        const left = isDesktop ? [renderLogo()] : [renderProfileButton()];
        const right = isDesktop
          ? [renderChatButton(), renderProfileButton()]
          : [renderChatButton()];
        const center = isDesktop
          ? [renderJobsPageTabs(), renderGrowButton()]
          : [renderJobsPageTabs()];

        return <HeaderTemplate leftElements={left} rightElements={right} centerElements={center} />;
      }
      case isEmployeeJobPage: {
        if (!isAuthenticated) return <PublicHeader />;

        const left = isDesktop ? [renderLogo()] : [renderProfileButton()];
        const right = isDesktop
          ? [renderChatButton(), renderProfileButton()]
          : [renderChatButton()];
        const center = isDesktop
          ? [renderJobsPageTabs(), renderGrowButton()]
          : [renderJobsPageTabs()];

        return <HeaderTemplate leftElements={left} rightElements={right} centerElements={center} />;
      }
      case isEmployeeProfile: {
        const left = [
          renderBackButton('View Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderLogoutButton(), renderChatButton(), renderGrowIconButton()]
          : [renderChatButton(), renderLogoutButton()];

        return (
          <HeaderTemplate
            title="Account"
            titleQaId="employee-profile"
            leftElements={left}
            rightElements={right}
          />
        );
      }
      case isSkillsProgress: {
        const left = [
          renderBackButton('My Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop ? [renderChatButton(), renderProfileButton()] : [];
        const title = isDesktop ? '' : 'New Gained Skills';

        return (
          <HeaderTemplate title={title} titleQaId="" leftElements={left} rightElements={right} />
        );
      }
      case isConnectionsProgress: {
        const left = [
          renderBackButton('My Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop ? [renderChatButton(), renderProfileButton()] : [];
        const title = isDesktop ? '' : 'Suggested Connections';

        return (
          <HeaderTemplate title={title} titleQaId="" leftElements={left} rightElements={right} />
        );
      }
      case isStillHired: {
        const left = [
          renderBackButton('My Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop ? [renderChatButton(), renderProfileButton()] : [];

        return <HeaderTemplate titleQaId="" leftElements={left} rightElements={right} />;
      }
      default:
        return null;
    }
  };

  return <div className={className}>{renderContent()}</div>;
}

const StyledHeader = styled(EmployeeHeader)(styles);

export default memo(StyledHeader);
