import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import AvatarEditor from 'react-avatar-editor';
import { imageTypeHelperText, qaAttr } from 'utils';
import { Box } from 'components';
import { Button, Spinner } from 'components/shared';
import { UPDATE_EMPLOYER_PROFILE_IMG } from 'api';

function CompanyImageForm({ form, onChange, onGoToNextStep }) {
  const [loading, setLoading] = useState(false);
  const editorRef = useRef();

  const [postEmployerProfileImg, { loading: postImageLoading = false }] = useMutation(
    UPDATE_EMPLOYER_PROFILE_IMG
  );

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setLoading(true);
      onChange({ ...form, image: file /*, preview: reader.result*/ });
      setLoading(false);
    };
    reader.onerror = () => {
      setLoading(false);
    };
    if (file) reader.readAsDataURL(file);
  };

  const postImage = async () => {
    if (editorRef.current && form.image) {
      setLoading(true);
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const fileName = form.image.name;
      const ext = fileName.split('.').pop();
      canvasScaled.toBlob(async (blob) => {
        const blobToFile = new File([blob], fileName);
        await postEmployerProfileImg({ variables: { file: blobToFile } });
        setLoading(false);
        onGoToNextStep();
      }, `image/${ext}`);
    }
  };

  return (
    <>
      {form.image && (
        <AvatarEditor
          ref={editorRef}
          image={form.image}
          width={288}
          height={288}
          border={0}
          borderRadius="10px"
          color={[255, 255, 255, 0.6]} // RGBA
          scale={1.1}
          rotate={0}
          style={{ margin: '0 auto 16px', borderRadius: 10, cursor: 'move' }}
        />
      )}
      <Box
        component="input"
        accept="image/*"
        display="none"
        id="company-image-input"
        type="file"
        onChange={handleImageUpload}
        {...qaAttr('onboarding-photo-input')}
      />
      <Box component="label" htmlFor="company-image-input" display="block" mb="10px">
        <Button
          variant="filled-secondary"
          component="span"
          width="100%"
          height={50}
          testID="onboarding-photo-upload-button"
        >
          {form.image ? 'Replace...' : 'Select or drop file here...'}
        </Button>
      </Box>
      <p style={{ marginBottom: 20, fontSize: 12 }}>{imageTypeHelperText}</p>
      <Button
        variant="filled-primary"
        width="100%"
        height={50}
        endIcon={loading && <Spinner size={24} />}
        disabled={!form.image || loading}
        onClick={postImage}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

CompanyImageForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CompanyImageForm;
