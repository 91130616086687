import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { Footer } from 'components/shared';
import styles from 'styles/Dashboard';

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'maxWidth'
})(styles);

function AppLayout(props) {
  const { children, fullWidth, maxWidth } = props;
  return (
    <StyledRoot fullWidth={fullWidth} maxWidth={maxWidth}>
      <div className="contentWrapper">{children}</div>
      <div className="footerContainer">
        <Footer containerProps={{ p: '28px 30px' }} />
      </div>
    </StyledRoot>
  );
}

AppLayout.propTypes = {
  children: PT.node,
  fullWidth: PT.bool,
  maxWidth: PT.number
};

AppLayout.defaultProps = {
  children: null,
  fullWidth: false,
  maxWidth: 1024
};

export default AppLayout;
