import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import map from 'lodash/map';
import { getUserId, getRoutes } from 'utils';
import { styled } from 'components';
import { Button } from 'components/shared';
import { SkillsForm } from 'components/dialogs/components';
import { MdClose } from 'components/icons';
import { GET_USER_PROFILE, POST_EMPLOYEE_SKILLS } from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  maxWidth: 474 + 16 * 2,
  margin: '0 auto',
  padding: '0 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& .title': {
    marginBottom: 18,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 400,
    lineHeight: '42px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .footer': {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flexDirection: 'column'
    }
  },
  '& .selectedContainer': {
    width: '100%',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 40
    }
  },
  '& .selectedItemsContainer': {
    overflow: 'auto',
    maxHeight: 120,
    margin: '0 -10px',
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      margin: '-10px 0',
      display: 'block'
    }
  },
  '& .selectedContainer__title': {
    fontSize: 14,
    color: 'rgba(0,0,0,0.5)',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      float: 'left'
    }
  },
  '& .selectedItem': {
    padding: 10,
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.primary.main
  },
  '& .submitBtn': {
    height: 50,
    width: 160,
    marginLeft: 20,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      marginBottom: 50,
      marginLeft: 0
    }
  }
}));

const ROUTES = getRoutes();

function EmployeeProgressSkills(props) {
  const navigate = useNavigate();
  // const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const [fetchProfile] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const [postSkills] = useMutation(POST_EMPLOYEE_SKILLS);

  const [form, setForm] = useState({ skills: [] });

  const getProfile = async () => {
    const uid = getUserId();
    const profileRes = await fetchProfile({ variables: { userId: Number(uid) } });
    const profile = profileRes?.data?.employeeProfile || {};
    const skills = profile?.skills.map(({ __typename, ...rest }) => ({ ...rest }));
    setForm({ skills });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleFormChange = (nextForm) => {
    setForm(nextForm);
  };

  const removeSkill = (id) => {
    const skillsCopy = [...form.skills];
    const filtered = skillsCopy.filter((o) => o.skill_id !== id);
    setForm({ skills: filtered });
  };

  const send = () => {
    postSkills({ variables: { skills: form.skills } });
    navigate(ROUTES.employee.dashboard, { replace: true });
  };

  return (
    <StyledRoot>
      <h1 className="title">Select new skills you've gained</h1>
      <SkillsForm
        form={form}
        onChange={handleFormChange}
        withSkipBtn={false}
        withSubmitBtn={false}
      />
      {!!form.skills.length && (
        <div className="footer">
          <div className="selectedContainer">
            <div className="selectedContainer__title">Chosen skills:</div>
            <div className="selectedItemsContainer">
              {map(form.skills, (sk) => (
                <Button className="selectedItem" onClick={() => removeSkill(sk.skill_id)}>
                  {sk.name}
                  <MdClose sx={{ fontSize: 10 }} />
                </Button>
              ))}
            </div>
          </div>
          <Button variant="filled-primary" className="submitBtn" onClick={send}>
            Send
          </Button>
        </div>
      )}
    </StyledRoot>
  );
}

EmployeeProgressSkills.propTypes = {};

export default EmployeeProgressSkills;
