import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebook, MdShare } from 'components/icons';

function BlogPost({ title, link }) {
  return (
    <div className="blogListContainer">
      <a className="blogLinkTitle" href={link} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
      <div className="blogSocialContainer">
        <a href={`mailto:?subject=&body=Check this out, ${link}`} className="blogSocialLink">
          <MdShare className="blogSocialIcon" />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
          target="_blank"
          rel="noreferrer"
          className="blogSocialLink"
        >
          <FaFacebook className="blogSocialIcon" />
        </a>
      </div>
    </div>
  );
}

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default BlogPost;
