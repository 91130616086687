import React from 'react';
import { SvgIcon } from 'components';

function MdGetApp(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
    </SvgIcon>
  );
}

export default MdGetApp;
