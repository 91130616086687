import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArcElement, Chart, DoughnutController, Legend, Tooltip, Title } from 'chart.js';
import { get } from 'lodash';
import { Spinner } from 'components/shared';
import { getChartData } from 'components/Grow/employer/charts/util';
import EmployerGrowContext from '../../EmployerGrowContext';

Chart.register(ArcElement, DoughnutController, Legend, Tooltip, Title);

const formatData = (labels, data) => ({
  labels,
  datasets: [
    {
      label: '# of Employees',
      data,
      backgroundColor: ['#5D5CA9', '#49469D', '#8782BE', '#9897D3', '#B3B2ED', '#ECECF5']
    }
  ]
});

function CompanyBreakdown() {
  const { jobTypes, employees, isMainDataLoading } = useContext(EmployerGrowContext);
  const { chartData, chartLabels } = getChartData(employees, jobTypes);
  const [legend, setLegend] = useState(null);
  const [chartActiveData, setChartActiveData] = useState(null);
  const chartCtxRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartCtxRef.current) {
      if (chartRef.current?.destroy) chartRef.current.destroy();

      chartRef.current = new Chart(chartCtxRef.current, {
        type: 'doughnut',
        data: {
          ...formatData(chartLabels, chartData)
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: 55,
          radius: 83,
          plugins: {
            legend: {
              display: false,
              position: 'right',
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                font: {
                  size: 12
                }
              }
            },
            tooltip: {
              enabled: false,
              external: handleHover
            }
          }
        }
      });
    }
  }, [chartCtxRef.current]);

  useEffect(() => {
    if (chartRef.current && !legend) {
      const { labels } = chartRef.current?.config.data;
      const dataset = chartRef.current?.config.data.datasets[0];

      const formattedLegend = dataset.data.map((item, idx) => ({
        data: item,
        label: labels[idx],
        color: dataset.backgroundColor[idx]
      }));

      setLegend(formattedLegend);
    }
  }, [chartRef.current]);

  const handleHover = (context) => {
    const tooltipModel = context.tooltip;
    const [label, value] = get(tooltipModel, 'body[0].lines[0]', '').split(':');
    if (label && value) {
      const percent = ((Number(value) / chartData.reduce((acc, cur) => acc + cur)) * 100).toFixed();
      setChartActiveData({ label, value, percent });
    }
  };

  return (
    <div className="companyBreakdownContainer">
      {isMainDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="companyBreakdownTitle">Company Breakdown</h4>
      <div className="companyBreakdownChartContainer">
        <div className="companyBreakdownChartPosition">
          {chartActiveData && (
            <div className="companyBreakdownChartPercContainer">
              <span className="companyBreakdownPercText1">
                {chartActiveData.percent}
                <sup className="companyBreakdownPercText2">%</sup>
              </span>
              <span className="companyBreakdownPercText3">
                {chartActiveData.value} {chartActiveData.label}
              </span>
            </div>
          )}
          <canvas id="breakDown-chart" height={175} width={175} ref={chartCtxRef} />
        </div>
        {legend && (
          <div className="companyBreakdownLegendContainer legend">
            {legend.map(({ color, label }) => (
              <div key={label} className="companyBreakdownLabelParent">
                <span style={{ backgroundColor: color }} className="companyBreakdownLegendColor" />
                <span className="companyBreakdownPercText3">{label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CompanyBreakdown;
