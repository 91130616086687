import React from 'react';
import { SvgIcon } from 'components';

function MdFlashOn(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M7 2v11h3v9l7-12h-4l4-8z"/>
    </SvgIcon>
  );
}

export default MdFlashOn;
