import React from 'react';
import { useOutlet, Navigate } from 'react-router-dom';
import { getRoutes } from 'utils';

const ROUTES = getRoutes();

function EmployeeCareerProgress() {
  const outletElement = useOutlet();
  return outletElement || <Navigate to={ROUTES.employee.dashboard} />;
}

export default EmployeeCareerProgress;
