import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import find from 'lodash/find';
import { GET_COUNTRIES, GET_STATES } from 'api';

function useCountryStateQuery(props) {
  const { queryOptions = {} } = props || {};

  const [fetchCountries, { data: countriesData }] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    ...queryOptions
  });
  const countries = countriesData?.getAllCountries || [];

  const [fetchStates, { data: statesData }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    ...queryOptions
  });
  const states = statesData?.getAllStates || [];

  const getStateById = useCallback(
    (id) => {
      if (id) return find(states, ['id', Number(id)]) || {};
      return {};
    },
    [JSON.stringify(states)]
  );

  const getCountryById = useCallback(
    (id) => {
      if (id) return find(countries, ['id', Number(id)]) || {};
      return {};
    },
    [JSON.stringify(countries)]
  );

  const fetchCountryState = useCallback(async (fetchParams = {}) => {
    fetchCountries();
    fetchStates();
  }, []);

  return { fetchCountryState, countries, states, getCountryById, getStateById };
}

export default useCountryStateQuery;
