import React from 'react';
import { SvgIcon } from 'components';

function MdArrowForward(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
    </SvgIcon>
  );
}

export default MdArrowForward;
