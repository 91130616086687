import { gql } from '@apollo/client';

const CREATE_CHART_COLUMN_BY_INDEX = gql`
  mutation createChartColumnByIndex($chartId: Int, $name: String, $index: Int) {
    createChartColumnByIndex(chartId: $chartId, name: $name, index: $index) {
      employerDevChartId
      id
      name
      index
      employeeProfiles {
        index
      }
    }
  }
`;

const UPDATE_CHART_COLUMNS_BY_INDEX = gql`
  mutation updateChartColumnsByIndex($columns: [EmployerChartColumn]) {
    updateChartColumnsByIndex(columns: $columns) {
      success
    }
  }
`;

const PUT_EMPLOYEE_CARDS_TO_COLUMN = gql`
  mutation putEmployeeCardsToColumn($cards: [MoveEmployeeCardInput]) {
    putEmployeeCardsToColumn(cards: $cards) {
      success
    }
  }
`;
const CREATE_CHART = gql`
  mutation createChart($name: String) {
    createChart(name: $name) {
      id
      name
      columns {
        name
        index
        employeeProfiles {
          employeeProfileProfileId
          id
          index
          employeeProfile {
            name
            imageUrl
            user_id
          }
          careerDevelopment {
            curJobTypeId
            futureJobTypeId
            industryId
            currentlySearching
            showEmployerData
          }
        }
      }
    }
  }
`;
const UPDATE_EMPLOYER_CHART_BY_ID = gql`
  mutation updateEmployerChartById($name: String, $id: Int) {
    updateEmployerChartById(name: $name, id: $id) {
      id
      name
    }
  }
`;

const UPDATE_EMPLOYER_CHARTS_BY_ID = gql`
  mutation updateEmployerChartsById($charts: [EmployerChart]) {
    updateEmployerChartsById(charts: $charts) {
      success
    }
  }
`;
const DELETE_EMPLOYER_CHART_BY_ID = gql`
  mutation deleteEmployerChartById($chartId: Int) {
    deleteEmployerChartById(chartId: $chartId) {
      success
    }
  }
`;
const COPY_CHART = gql`
  mutation copyChart($chartId: Int) {
    copyChart(chartId: $chartId) {
      id
      name
      columns {
        name
        index
        employeeProfiles {
          employeeProfileProfileId
          id
          index
          employeeProfile {
            name
            imageUrl
            user_id
          }
          careerDevelopment {
            curJobTypeId
            futureJobTypeId
            industryId
            currentlySearching
            showEmployerData
          }
        }
      }
    }
  }
`;

const DELETE_CHART_COLUMN_BY_INDEX = gql`
  mutation deleteEmployerChartColumnByIndex($chartId: Int, $columnIndex: Int) {
    deleteEmployerChartColumnByIndex(chartId: $chartId, columnIndex: $columnIndex) {
      id
      name
      columns {
        name
        index
        employeeProfiles {
          employeeProfileProfileId
          id
          index
          employeeProfile {
            name
            imageUrl
            user_id
          }
          careerDevelopment {
            curJobTypeId
            futureJobTypeId
            industryId
            currentlySearching
            showEmployerData
          }
        }
      }
    }
  }
`;

const ADD_EMPLOYEE_BY_EMAIL_TO_EMPLOYER_CODE = gql`
  mutation postEmployeeToEmployerCode($email: String!) {
    postEmployeeToEmployerCode(email: $email) {
      profile_id
    }
  }
`;

const ADD_EMPLOYEE_TO_CHARTS = gql`
  mutation addEmployeeToChart($employerProfileProfileId: Int!, $employeeProfileProfileId: Int!) {
    addEmployeeToChart(
      employerProfileProfileId: $employerProfileProfileId
      employeeProfileProfileId: $employeeProfileProfileId
    ) {
      id
    }
  }
`;

const DELETE_EMPLOYEE_FROM_EMPLOYER_GROW = gql`
  mutation removeEmployeeFromChart(
    $employerProfileProfileId: Int!
    $employeeProfileProfileId: Int!
  ) {
    removeEmployeeFromChart(
      employerProfileProfileId: $employerProfileProfileId
      employeeProfileProfileId: $employeeProfileProfileId
    ) {
      id
    }
  }
`;

const POST_EMPLOYER_CHART_SETTINGS = gql`
  mutation changeChartSettings(
    $employerProfileProfileId: Int!
    $jobScores: Boolean!
    $employeeSatisfaction: Boolean!
    $companyBreakdown: Boolean!
    $jobStats: Boolean!
    $avgTen: Boolean!
    $hiringRate: Boolean!
    $equityScore: Boolean!
    $competitionBreakdown: Boolean!
    $employeeChurnRate: Boolean!
  ) {
    changeChartSettings(
      employerProfileProfileId: $employerProfileProfileId
      jobScores: $jobScores
      employeeSatisfaction: $employeeSatisfaction
      companyBreakdown: $companyBreakdown
      jobStats: $jobStats
      avgTen: $avgTen
      hiringRate: $hiringRate
      equityScore: $equityScore
      competitionBreakdown: $competitionBreakdown
      employeeChurnRate: $employeeChurnRate
    ) {
      jobScores
      employeeSatisfaction
      companyBreakdown
      jobStats
      avgTen
      hiringRate
      equityScore
      competitionBreakdown
      employeeChurnRate
    }
  }
`;

export default {
  ADD_EMPLOYEE_TO_CHARTS,
  CREATE_CHART_COLUMN_BY_INDEX,
  PUT_EMPLOYEE_CARDS_TO_COLUMN,
  CREATE_CHART,
  UPDATE_EMPLOYER_CHART_BY_ID,
  UPDATE_EMPLOYER_CHARTS_BY_ID,
  DELETE_EMPLOYER_CHART_BY_ID,
  DELETE_CHART_COLUMN_BY_INDEX,
  COPY_CHART,
  UPDATE_CHART_COLUMNS_BY_INDEX,
  ADD_EMPLOYEE_BY_EMAIL_TO_EMPLOYER_CODE,
  DELETE_EMPLOYEE_FROM_EMPLOYER_GROW,
  POST_EMPLOYER_CHART_SETTINGS
};
