import { gql } from '@apollo/client';

export const SEND_JOB_INVITE = gql`
  mutation sendJobInvite($employeeProfileId: Int!, $phone: String, $jobId: Int!) {
    sendJobInvite(employeeProfileId: $employeeProfileId, phone: $phone, jobId: $jobId) {
      id
    }
  }
`;

export const DEACTIVATE_ACCOUNT = gql`
  mutation postDeactivateAccount($userId: Int!) {
    postDeactivateAccount(userId: $userId) {
      userId
    }
  }
`;

export const SET_USER_ANALYTICS = gql`
  mutation setUserAnalytics($employeeId: Int!, $employerId: Int!, $origin: String!, $jobId: Int!) {
    setUserAnalytics(
      employeeId: $employeeId
      employerId: $employerId
      origin: $origin
      jobId: $jobId
    ) {
      userId
    }
  }
`;

export const POST_USER_TYPE = gql`
  mutation postUserType($profileType: String!, $timeZone: String!) {
    postUserType(profileType: $profileType, timeZone: $timeZone) {
      user_id
      profileType
    }
  }
`;

export const POST_SURVEY = gql`
  mutation setSurveyRating($userId: Int!, $ratingValue: Int!, $ratingNotes: String) {
    setSurveyRating(userId: $userId, ratingValue: $ratingValue, ratingNotes: $ratingNotes) {
      ratingValue
      ratingNotes
      userId
    }
  }
`;

export const SET_REG_STEP = gql`
  mutation setUserStep($userId: Int!, $regStep: Int!) {
    setUserStep(userId: $userId, regStep: $regStep) {
      userId
    }
  }
`;

export const POST_EMPLOYEE_STILL_HIRED = gql`
  mutation stillHired($employeeUserId: Int!, $response: Boolean!) {
    stillHired(employeeUserId: $employeeUserId, response: $response) {
      id
    }
  }
`;
