import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import parse from 'date-fns/parse';
import { getUserId, getProfileId, sortDateRanges, getAgeByBirthDate } from 'utils';
import { styled, useMediaQuery } from 'components';
import { Spinner } from 'components/shared';
import { Account, Profile, Notifications } from 'components/Profile/employee';
import { GET_USER_PROFILE, GET_CERTIFICATES } from 'api';
import styles from 'styles/Profile/EmployeeProfile';

const StyledRoot = styled('div')(styles);

const PROFILE_SEC = 'Profile';
const NOTIFICATIONS_SEC = 'Notifications';
const ACCOUNT_SEC = 'Account';
const sections = [
  { name: PROFILE_SEC, Component: Profile, jumpLink: 'profile' },
  { name: NOTIFICATIONS_SEC, Component: Notifications, jumpLink: 'notifications' },
  { name: ACCOUNT_SEC, Component: Account, jumpLink: 'account' }
];
const SERVER_DATE_FORMAT = 'yyyy-MM-dd';

const parseServerDate = (string) => parse(string, SERVER_DATE_FORMAT, new Date());

const INIT_FORM = {
  address: '',
  age: '',
  certificates: [],
  countryId: 1,
  city: '',
  education: [],
  experience: [],
  gender: '',
  industry: [],
  imageUrl: '',
  name: '',
  newImageFile: null,
  newVideoFile: null,
  newResumeFile: null,
  motto: '',
  phone: '',
  skills: [],
  stateId: '',
  resumeUrl: '',
  videoUrl: '',
  zip: ''
};

const notificationKeys = [
  'emailNewJobs',
  'emailNewMessages',
  'emailTips',
  'inappNewJobs',
  'inappNewMessages',
  'inappTips'
];

const dropTypeName = (arr = []) =>
  arr?.length ? map(arr, ({ __typename, ...rest }) => ({ ...rest })) : [];

function EmployeeProfile(props) {
  const isDesktop = useMediaQuery('(min-width:1024px)', { noSsr: true });

  const [expandedSection, setExpandedSection] = useState(sections[0].name);
  const [fullProfile, setFullProfile] = useState({});
  const [profile, setProfile] = useState(INIT_FORM);
  const [notifications, setNotifications] = useState(() =>
    reduce(
      notificationKeys,
      (res, val) => {
        res[val] = false;
        return res;
      },
      {}
    )
  );

  const [fetchCertificates] = useLazyQuery(GET_CERTIFICATES, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.getCertificates) {
        setProfile((prev) => ({
          ...prev,
          certificates: map(dropTypeName(data.getCertificates), (o) => ({
            ...o,
            completionDate: o.completionDate ? parseServerDate(o.completionDate) : null
          }))
        }));
      }
    }
  });

  const [fetchEmployeeProfile, { loading: profileLoading = true }] = useLazyQuery(
    GET_USER_PROFILE,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const fetchedProfile = data?.employeeProfile || {};
        const {
          address = '',
          birth_date,
          countryId = 1, // default USA
          city = '',
          experience = [],
          education = [],
          gender,
          imageUrl,
          industry = [],
          motto = '',
          name = '',
          phone,
          profileComplete,
          race,
          resumeUrl,
          skills = [],
          stateId = '',
          videoUrl,
          website,
          zip = ''
        } = fetchedProfile;
        let age = getAgeByBirthDate(birth_date);
        const formattedIndustry = dropTypeName(industry);
        const formattedExperience = map(sortDateRanges(dropTypeName(experience)), (o) => ({
          ...o,
          startDate: o.startDate ? parseServerDate(o.startDate) : null,
          endDate: o.endDate ? parseServerDate(o.endDate) : null
        }));
        const formattedSkills = dropTypeName(skills);
        const formattedEducation = map(sortDateRanges(dropTypeName(education)), (o) => ({
          ...o,
          startDate: o.startDate ? parseServerDate(o.startDate) : null,
          endDate: o.endDate ? parseServerDate(o.endDate) : null
        }));
        const formattedNotifications = reduce(
          notificationKeys,
          (res, val) => {
            res[val] = fetchedProfile[val];
            return res;
          },
          {}
        );

        setFullProfile(fetchedProfile);
        setProfile((prev) => ({
          ...prev,
          age,
          address,
          city,
          countryId: countryId || '',
          education: formattedEducation,
          experience: formattedExperience,
          gender,
          industry: formattedIndustry,
          imageUrl,
          motto,
          name,
          phone,
          race,
          resumeUrl,
          stateId: stateId || '',
          skills: formattedSkills,
          videoUrl,
          website,
          zip
        }));
        setNotifications(formattedNotifications);
      }
    }
  );

  const getFullProfile = useCallback(() => {
    const userId = parseInt(getUserId());
    const profileId = parseInt(getProfileId());

    if (userId) fetchEmployeeProfile({ variables: { userId } });
    fetchCertificates({ variables: { employeeProfileId: profileId } });
  }, []);

  useEffect(() => {
    getFullProfile();

    return () => {
      if (scrollableContentRef.current && scrollableContentOnScrollRef.current) {
        scrollableContentRef.current.removeEventListener(
          'scroll',
          scrollableContentOnScrollRef.current
        );
      }
    };
  }, []);

  const scrollableContentRef = useRef();
  const scrollableContentOnScrollRef = useRef();

  const scrollableContentCallBackRef = useCallback((node) => {
    if (node) {
      scrollableContentRef.current = node;
      let headersOffsets = [];
      scrollableContentOnScrollRef.current = (e) => {
        var scrollTop = e.target.scrollTop;
        forEach(headersOffsets, ({ offsetTop, id }) => {
          const header = document.getElementById(id);
          const isSticky = header.classList.contains('sticky-header');
          if (scrollTop > offsetTop && !isSticky) {
            const currentSticky = document.querySelector('.sticky-header');
            if (currentSticky) currentSticky.classList.remove('sticky-header');
            if (header) header.classList.add('sticky-header');
            return;
          }
        });
      };
      if (isDesktop) {
        // on mobile we can use pure css solution as we don't use jumplinks
        headersOffsets = map(sections, ({ jumpLink }) => ({
          offsetTop: document.getElementById(`${jumpLink}-section-header`)?.offsetTop,
          id: `${jumpLink}-section-header`
        }));
        scrollableContentRef.current.addEventListener(
          'scroll',
          scrollableContentOnScrollRef.current
        );
      }
    }
  }, []);

  const onJumpLinkClick = (jumpLink) => {
    const node = document.getElementById(`${jumpLink}-section-header`);
    if (node && node.classList.contains('sticky-header')) {
      // deactivate sticky header, as it could block scrolling to selected jumplink
      node.classList.remove('sticky-header');
    }
  };

  const handleSectionExpand = useCallback((toExpand, name, expanded) => {
    setExpandedSection(toExpand);
  }, []);

  const getSectionProps = (name) => {
    switch (name) {
      case PROFILE_SEC: {
        return { fetchEmployeeProfile: getFullProfile, profile, setExpandedSection };
      }
      case NOTIFICATIONS_SEC: {
        return { notifications };
      }
      default:
        return {};
    }
  };

  const renderSections = () =>
    map(sections, ({ Component, name, jumpLink }, i) => (
      <Component
        key={`section__${i}`}
        sectionLayoutProps={{
          sectionName: name,
          jumpLink,
          jumpLinkProps: {
            onClick: () => onJumpLinkClick(jumpLink)
          },
          expanded: expandedSection === name,
          onExpand: handleSectionExpand
        }}
        {...getSectionProps(name)}
      />
    ));

  const renderDesktopLayout = () => (
    <div className="desktopLayout">
      <div className="column">
        {map(sections, ({ Component, name, jumpLink }, i) => (
          <div key={`contentItem__${i}`} className="contentItem">
            <a href={`#${jumpLink}`} onClick={() => onJumpLinkClick(jumpLink)}>
              {name}
            </a>
          </div>
        ))}
      </div>
      <div ref={scrollableContentCallBackRef} className="column">
        {renderSections()}
      </div>
    </div>
  );

  const renderMobileLayout = () => <div className="mobileLayout">{renderSections()}</div>;

  if (isEmpty(fullProfile)) {
    return (
      <StyledRoot className="container">
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className="container">
      {profileLoading && (
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      )}
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
    </StyledRoot>
  );
}

export default EmployeeProfile;
