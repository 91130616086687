export default ({ theme }) => ({
  '&.container': {
    width: 200
  },
  '& .content': {
    position: 'relative',
    minHeight: 260,
    padding: '11px 9px 9px',
    border: '1px solid #E5E5E5',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    backgroundColor: '#FFF',
    '&.hired': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#8283BF'
    },
    '&.denied': {
      borderColor: theme.palette.error.main,
      backgroundColor: '#FFC2C9'
    }
  },
  '& .cardHeaderBtn': {
    position: 'absolute',
    right: 5,
    top: 5,
    width: 0,
    height: 0,
    color: '#231F20'
  },
  '& .cardTitle': {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#3D3D3D'
  },
  '& .image': {
    width: 50,
    height: 50,
    borderRadius: '100%',
    objectFit: 'cover',
    '&.confirmed': {
      border: `2px solid ${theme.palette.success.main}`
    },
    '&.notComing': {
      border: `2px solid ${theme.palette.error.main}`
    },
    '&.hired': {
      width: 78,
      height: 78
    },
    '&.hired, &.denied': {
      border: '3px solid #FFF'
    }
  },
  '& .name': {
    marginBottom: 3,
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    color: '#3D3D3D'
  },
  '& .time': {
    marginBottom: 6,
    fontSize: 12,
    textAlign: 'center',
    color: '#A4A3A4'
  },
  '& .position': {
    marginBottom: 8,
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.moreColors.purpleLight_1
  },
  '& .status': {
    width: '100%',
    padding: 5,
    textAlign: 'center',
    fontSize: 10,
    boxShadow: '0px 2px 3px #00000026',
    borderRadius: 2,
    color: '#3D3D3D',
    '&.confirmed': {
      color: theme.palette.success.main
    },
    '&.notComing': {
      color: theme.palette.error.main
    }
  },
  '& .cardAction': {
    width: '100%',
    padding: '5px 0',
    fontSize: 10,
    borderRadius: 3,
    '&:not(:first-of-type)': {
      marginLeft: 7
    }
  },
  '& .confirmAction': {
    padding: '5px 12px',
    fontSize: 10,
    borderRadius: 3
  },
  '& .messageAction': {
    width: '100%',
    padding: '9px 7px',
    fontSize: 10,
    borderRadius: 3
  },
  '& .optionButton': {
    width: '100%',
    padding: '9px 0',
    borderTop: '1px solid #E5E5E5',
    borderRadius: 0,
    display: 'block',
    fontSize: 10,
    textAlign: 'center',
    pointer: 'cursor',
    '&.hired': {
      color: '#FFF',
      borderColor: '#FFF'
    },
    '&.denied': {
      color: '#5E6060',
      borderColor: '#707070'
    }
  },
  '& .calendarLink': {
    marginLeft: 5,
    verticalAlign: 'middle',
    '&:first-of-type': {
      marginLeft: 10
    }
  },
  '& .reasonCheckbox': {
    visibility: 'hidden',
    position: 'absolute',
    zIndex: -1
  },
  '& .notesInput': {
    flex: 1,
    maxHeight: 160,
    overflow: 'auto',
    alignItems: 'flex-start',
    borderRadius: 0,
    fontSize: 14,
    '& textarea': {
      padding: 5
    }
  },
  '& .notesInput__label': {
    marginBottom: 3,
    fontSize: 9,
    color: '#A4A3A4'
  },
  '& .loaderOverlay': {
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)'
  }
});
