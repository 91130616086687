import React, { useState, useEffect, useRef, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  client,
  GET_EMPLOYER_PROFILE,
  PAY,
  CREATE_CC,
  UPDATE_CC,
  GET_ALL_CC,
  employerSubscriptionVar
} from 'api';
import cjs from 'crypto-js';
import CCValidator from 'card-validator';
import Cards from 'react-credit-cards';
import clsx from 'clsx';
import { find, map, filter, get } from 'lodash';
import { getUserId, qaAttr, trimStr, numberToUsdFormatter } from 'utils';
import { htmTrackPaymentProcessing } from 'utils/gtm';
import {
  BRAND_NAMES,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  getCommonCardBrandNames,
  getMaskedCardNumber
} from 'utils/payment';
import { Box, Dialog, FormHelperText, styled } from 'components';
import { Button, CouponInput, IconButton, Input, Spinner, CheckBox } from 'components/shared';
import { useForm } from 'components/form';
import { MdArrowBack, MdClose } from 'components/icons';
import styles from 'styles/dialogs/PaymentDialog';
import './stripe-dialog-card.css'; // from 'react-credit-cards/es/styles-compiled.css'

const StyledDialog = styled(Dialog)(styles);

const INPUTS = {
  number: { id: 'number', label: 'Card Number' },
  expiry: { id: 'expiry', label: 'Expiration' },
  cvc: { id: 'cvc', label: 'CVV' },
  name: { id: 'name', label: "Cardholder's name" }
};

const MAX_EXP_LENGTH = 4; // MMYY
const AMEX = BRAND_NAMES.amex.cardValidator;
const NAME_PATTERN = /^[a-zA-Z]+(([`',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

const clearNumber = (value = '') => value.replace(/\D+/g, '');

const prepareCardForSubmit = ({ cvc, number, expiry, name }) => {
  const [month, year] = expiry.split('/');
  const trimmedName = trimStr(name);
  const clearCardNum = clearNumber(number);
  return { number: clearCardNum, name: trimmedName, month, year, cvc };
};

function PaymentDialog(props) {
  const { cardId, isOpen, onClose, onPlanUpgrade, onCardUpdate, type } = props;
  const { nextPlan, nextPlanPrice, nextPlanStrippedPrice, planIdToPayFor } =
    useReactiveVar(employerSubscriptionVar);
  const isCheckout = type === 'checkout';
  const isCardEdit = type === 'card_edit';
  const isNewCard = type === 'card_add';

  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [plan, setPlan] = useState({
    name: nextPlan,
    formattedPrice: nextPlanPrice,
    unFormattedPrice: nextPlanStrippedPrice,
    id: planIdToPayFor,
    couponId: null
  });
  const [submitInProcess, setSubmitInProcess] = useState(false);
  const [focused, setFocused] = useState('number');
  const [isSave, setIsSave] = useState(true);
  const [couponLoading, setCouponLoading] = useState(false);
  // errors
  const [stripeError, setStripeError] = useState(''); // general stripe error
  const [stripeParamError, setStripeParamError] = useState([]); // [['param name', 'error message']]
  const [beError, setBeError] = useState(''); // qh backend error

  const numberInputEl = useRef(null);
  const expiryInputEl = useRef(null);
  const cvcInputEl = useRef(null);
  const nameInputEl = useRef(null);

  const { attrs, $, set, getError, reset, useConfig, withValidation } = useForm({
    initial: {
      cvc: '',
      expiry: '',
      name: '',
      number: '',
      cardType: 'unknown',
      cardLength: 19,
      cardIsValid: false
    },
    validations: {
      number: undefined,
      cvc: undefined,
      expiry: {
        rules: [
          'presence',
          function (value, { attrs }) {
            if (!value) return;
            const [month, year] = value.split('/');
            const monthValidation = month ? CCValidator.expirationMonth(month) : null;
            const yearValidation = year ? CCValidator.expirationYear(year) : null;
            const curYear = new Date().getFullYear();
            const curMonth = new Date().getMonth() + 1;
            const yearDecimal = curYear.toString().substring(2);

            if (
              !monthValidation ||
              !yearValidation ||
              (monthValidation && !monthValidation.isValid) ||
              (yearValidation && !yearValidation.isValid)
            ) {
              return "Your card's expiration date is invalid.";
            }
            if (year < yearDecimal || (yearDecimal === year && curMonth > month)) {
              return 'Your card has expired.';
            }
          }
        ],
        deps: []
      },
      name: {
        rules: [
          'presence',
          function (value) {
            if (!value) return;
            const nameValidation = CCValidator.cardholderName(value);
            if (!nameValidation.isValid || !NAME_PATTERN.test(value)) return 'Name is invalid';
          }
        ],
        deps: []
      }
    }
  });

  useConfig(() => {
    if (type === 'card_edit') {
      return {
        // disable card number and cvc validation on edit
        validations: {
          number: undefined,
          cvc: undefined
        }
      };
    }
    return {
      validations: {
        number: {
          rules: [
            'presence',
            function (value, { attrs }) {
              if (!value) return;
              const clearValue = clearNumber(value);
              const numberValidation = CCValidator.number(clearValue);
              if (!numberValidation.isValid) return 'Your card number is invalid.';
            }
          ],
          deps: []
        },
        cvc: {
          rules: [
            'presence',
            { numericality: { leadingZeros: true } },
            function (value, { attrs }) {
              if (!value) return;
              const cvvValidation = CCValidator.cvv(value, attrs.cardType === AMEX ? 4 : 3);
              if (!cvvValidation.isValid) return "Your card's security code is invalid.";
            }
          ],
          deps: ['cardType']
        }
      }
    };
  }, [type]);

  // const [fetchEmployerProfile, { loading: employerLoading = true }] = useLazyQuery(
  //   GET_EMPLOYER_PROFILE,
  //   {
  //     fetchPolicy: 'cache-first',
  //     errorPolicy: 'all',
  //     onCompleted: (data) => {
  //       if (data?.employerProfile) {
  //         const { nextPlan, nextPlanPrice } = getEmployerSubscriptionInfo(data?.employerProfile);
  //         setPlan({ name: nextPlan, price: nextPlanPrice });
  //       }
  //     }
  //   }
  // );

  const [fetchCards, { loading: cardsLoading }] = useLazyQuery(GET_ALL_CC, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      const cards = data?.getUserCards || {};
      if (cards.cardInfo) {
        const parsedData = JSON.parse(cards.cardInfo);
        if (parsedData.data) {
          const curYear = new Date().getFullYear();
          const curMonth = new Date().getMonth() + 1;
          const activeCards = filter(
            parsedData.data,
            (o) => o.exp_year > curYear || (o.exp_year === curYear && curMonth <= o.exp_month)
          );
          setCards(activeCards);
        }
      }
    }
  });

  const [createCard, { loading: createCardLoading }] = useMutation(CREATE_CC, {
    onCompleted: () => onCardUpdate()
  });
  const [updateCard, { loading: updateCardLoading }] = useMutation(UPDATE_CC, {
    onCompleted: () => onCardUpdate(),
    onError: (error) => {
      const err = new Error(error);
      err.name = '';
      setBeError(err.toString());
    }
  });

  const [postPay, { loading: payLoading }] = useMutation(PAY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      const paymentRes = data?.postPay;
      if (paymentRes) {
        onPlanUpgrade();
        const { price, planEmployer, transactionId, employerId } = paymentRes;
        htmTrackPaymentProcessing(price, get(planEmployer, '[0].name'), transactionId, employerId);
      }
      setSubmitInProcess(false);
    },
    onError: (error) => {
      const err = new Error(error);
      err.name = '';
      setBeError(err.toString());
    }
  });

  const submitLoading = submitInProcess || payLoading || createCardLoading || updateCardLoading;

  useEffect(() => {
    if (isOpen) {
      // Reset all settings if dialog was closed
      reset();
      setCards([]);
      setPlan((prev) => ({
        ...prev,
        name: nextPlan,
        formattedPrice: nextPlanPrice,
        unFormattedPrice: nextPlanStrippedPrice,
        id: planIdToPayFor
      }));
      setFocused('number');
      resetErrors();
      setSubmitInProcess(false);

      (() => {
        const userId = parseInt(getUserId());
        if (userId) {
          // fetchEmployerProfile({ variables: { userId } });

          if (isCheckout || (isCardEdit && cardId)) fetchCards({ variables: { userId } });
        }
      })();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isCardEdit && cardId && cards.length) {
      const selectedCard = find(cards, ['id', cardId]);
      if (selectedCard) {
        const { last4, name, brand, exp_month, exp_year } = selectedCard;
        const month = exp_month < 10 ? `0${exp_month}` : exp_month;
        const yearDecimal = exp_year.toString().substring(2);

        set({
          number: getMaskedCardNumber(brand, last4),
          name,
          cardType: getCommonCardBrandNames(brand).paymentJs,
          expiry: `${month}/${yearDecimal}`
        });
      }
    }
  }, [JSON.stringify(cards)]);

  const onCouponLoading = (flag) => {
    setCouponLoading(flag);
  };

  const onCouponValidationSuccess = ({ id: couponId, discount }) => {
    if (discount && couponId) {
      const newPrice = plan.unFormattedPrice - plan.unFormattedPrice * (discount / 100);
      const formattedNewPrice = numberToUsdFormatter.format(newPrice / 100);

      setPlan((prev) => ({
        ...prev,
        couponId,
        formattedPrice: formattedNewPrice,
        unFormattedPrice: newPrice
      }));
    }
  };

  const pay = async (validAttrs, cardIdArg) => {
    const { couponId } = plan;
    const stripeData = cardIdArg ?? (await handleCardCreate(validAttrs));
    const cid = get(stripeData, 'data.createUserCard.cardId', null) || cardIdArg;
    const doPostPay = (args) => postPay(args);

    if (get(plan, 'id')) {
      const postPayBase = {
        isSave,
        planId: plan.id,
        coupon: couponId ? couponId.toString() : ''
      };

      if (!cid) {
        await doPostPay({
          variables: {
            ...postPayBase,
            token: get(stripeData, 'data.createUserCard.token', null)
          }
        });
      } else {
        await doPostPay({
          variables: {
            ...postPayBase,
            cardId: cid
          }
        });
      }
    } else {
      throw new Error('Missed planId');
    }
    setSubmitInProcess(false);
  };

  const handleCardUpdate = async (validAttrs) => {
    const userId = parseInt(getUserId());
    const { number, month, year, cvc, ...rest } = prepareCardForSubmit(validAttrs);
    const params = cjs.AES.encrypt(
      JSON.stringify({
        ...rest,
        exp_month: month,
        exp_year: year
      }),
      process.env.HASH_KEY
    ).toString();

    if (userId && cardId) {
      updateCard({ variables: { cardId, userId, params } });
    }
    setSubmitInProcess(false);
  };

  const handleCardCreate = async ({ cardIsValid, cardLength, ...validAttrs }) => {
    const userId = parseInt(getUserId());
    const cardDetails = cjs.AES.encrypt(
      JSON.stringify({
        cardNumber: validAttrs.number,
        cardCVC: validAttrs.cvc,
        cardExpiry: validAttrs.expiry,
        cardType: validAttrs.cardType,
        cardName: validAttrs.name
      }),
      process.env.HASH_KEY
    ).toString();

    if (userId) {
      try {
        const cardData = await createCard({ variables: { cardDetails, userId } });
        setSubmitInProcess(false);
        return cardData;
      } catch (err) {
        console.log(err, 'error creating the new card.');
        setSubmitInProcess(false);
        return null;
      }
    }

    return null;
  };

  const submit = withValidation(async (validAttrs) => {
    setSubmitInProcess(true);
    resetErrors();

    if (isCheckout && !isNewCard) pay(validAttrs);
    else if (isNewCard) handleCardCreate(validAttrs);
    else if (isCardEdit) handleCardUpdate(validAttrs);
  });

  const payWithSelectedCard = async () => {
    pay(undefined, selectedCard);
  };

  const resetErrors = () => {
    setBeError('');
    setStripeError('');
    setStripeParamError([]);
  };

  const getStripeErrorByParam = (param = '') => {
    if (param && stripeParamError?.[0] && stripeParamError[0] === param) {
      return stripeParamError?.[1] || '';
    }
    return '';
  };

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };

  const handleInputChange = ({ target }, { name }, widthFocus = true) => {
    let { value } = target;

    if (name === 'number') {
      const clearValue = clearNumber(value);
      value = formatCreditCardNumber(value);

      if (widthFocus && clearValue.length === attrs.cardLength && expiryInputEl.current) {
        expiryInputEl.current.focus();
      }
    } else if (name === 'cvc') {
      const cvcMaxLength = attrs.cardType === AMEX ? 4 : 3;
      const clearValue = clearNumber(value);
      value = formatCVC(value, attrs.cardType);

      if (widthFocus && clearValue.length === cvcMaxLength && nameInputEl.current) {
        nameInputEl.current.focus();
      }
    } else if (name === 'expiry') {
      const clearValue = clearNumber(value);
      value = formatExpirationDate(value);

      if (widthFocus && clearValue.length === MAX_EXP_LENGTH && cvcInputEl.current) {
        cvcInputEl.current.focus();
      }
    }

    set(name, value);
  };

  const handleCardChange = (typeArg = { issuer: 'unknown', maxLength: 19 }, isValid = false) => {
    if (isCardEdit) return; // this func will set 'unknown' cardType for masked number if isCardEdit is true, so it could be disabled
    set({
      cardIsValid: isValid,
      cardLength: typeArg.maxLength,
      cardType: typeArg.issuer
    });
  };

  const handleAutoRenewChange = () => {
    setIsSave(!isSave);
  };

  const getTitle = () => {
    if (isCheckout) return plan.name ? `Your Plan: ${plan.name}` : 'Stripe';
    if (isCardEdit) return 'Edit Card';
    if (isNewCard) return 'Add Card';
    return '';
  };

  const getSubmitBtnTitle = () => {
    if (isCheckout)
      return plan.formattedPrice ? `Subscribe for ${plan.formattedPrice}/month` : 'Subscribe';
    if (isCardEdit) return 'Update';
    if (isNewCard) return 'Add';
    return '';
  };

  const renderCard = () => (
    <div className="plasticContainer">
      <Cards
        cvc={attrs.cvc.replace(/\d/g, '•')}
        expiry={attrs.expiry}
        name={attrs.name}
        number={attrs.number}
        focused={focused}
        locale={{
          valid: 'MONTH/YEAR'
        }}
        callback={handleCardChange}
        preview={isCardEdit}
        issuer={(isCardEdit && attrs.cardType) || ''}
      />
    </div>
  );

  const renderSavedCards = () => (
    <div className="savedCardsContainer">
      <h2 className="subTitle">Choose Existing</h2>
      <div className="savedCardsList">
        {map(cards, ({ id, brand, exp_month, exp_year, last4, name, primary }, i) => {
          const maskedNum = getMaskedCardNumber(brand, last4);
          const isSelected = id === selectedCard;
          return (
            <div
              key={`cardItem__${i}`}
              className={clsx('savedCard', isSelected && 'card_selected')}
            >
              <input
                hidden
                type="checkbox"
                id={`card__${id}`}
                checked={isSelected}
                onChange={() => setSelectedCard(selectedCard === id ? '' : id)}
                {...qaAttr(`saved-card-checkbox-${i}`)}
              />
              <label
                htmlFor={`card__${id}`}
                {...qaAttr(`saved-card-${i}`)}
                className="savedCard__card"
              >
                <Cards
                  name={name}
                  number={maskedNum}
                  expiry={`${exp_month < 10 ? `0${exp_month}` : exp_month}/${exp_year}`}
                  cvc="***"
                  preview
                  issuer={getCommonCardBrandNames(brand).paymentJs}
                  locale={{ valid: 'MONTH/YEAR' }}
                />
                <div className={clsx('savedCard__number', isSelected && 'card_selected')}>
                  {maskedNum}
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderForm = () => {
    const commonProps = {
      variant: 'textfield',
      required: true,
      withHelperText: true,
      InputLabelProps: { shrink: false },
      onFocus: handleInputFocus,
      htmlClassName: 'htmlInput'
    };

    return (
      <div className="formContainer">
        <div className="formItem">
          <Input
            {...$('number', handleInputChange)}
            {...INPUTS.number}
            {...commonProps}
            readOnly={isCardEdit}
            error={getError('number') || getStripeErrorByParam('number')}
            inputProps={{
              inputMode: 'numeric',
              autoComplete: 'cc-number'
            }}
            ref={numberInputEl}
            analyticParams={{
              key: 'Card number focused (payment)',
              trigger: 'focus'
            }}
            testID="stripe-modal-number-input"
          />
        </div>
        <div className="doubleInputRow">
          <div className="formItem">
            <Input
              {...$('expiry', handleInputChange)}
              {...INPUTS.expiry}
              {...commonProps}
              error={
                getError('expiry') ||
                getStripeErrorByParam('exp_month') ||
                getStripeErrorByParam('exp_year')
              }
              inputProps={{ inputMode: 'numeric' }}
              ref={expiryInputEl}
              analyticParams={{
                key: 'Card expiry focused (payment)',
                trigger: 'focus'
              }}
              testID="stripe-modal-expiry-input"
            />
          </div>
          {!isCardEdit && (
            <div className="formItem">
              <Input
                {...$('cvc', handleInputChange)}
                {...INPUTS.cvc}
                {...commonProps}
                type="password"
                error={getError('cvc') || getStripeErrorByParam('cvc')}
                ref={cvcInputEl}
                inputProps={{ inputMode: 'numeric' }}
                analyticParams={{
                  key: 'Card cvc focused (payment)',
                  trigger: 'focus'
                }}
                testID="stripe-modal-cvc-input"
              />
            </div>
          )}
        </div>
        <div className="formItem">
          <Input
            {...$('name', handleInputChange)}
            {...INPUTS.name}
            {...commonProps}
            ref={nameInputEl}
            analyticParams={{
              key: 'Cardholder name focused (payment)',
              trigger: 'focus'
            }}
            testID="stripe-modal-name-input"
          />
        </div>
        <div className="formItem">
          <CouponInput onSuccess={onCouponValidationSuccess} onLoading={onCouponLoading} />
        </div>
        {stripeError ||
          (beError && (
            <FormHelperText error style={{ textAlign: 'center', marginBottom: 10 }}>
              {stripeError || beError}
            </FormHelperText>
          ))}
      </div>
    );
  };

  const renderActions = () => (
    <div className="actionsContainer">
      {isCheckout && (
        <CheckBox
          checked={isSave}
          label="Save card for autorenew"
          onChange={handleAutoRenewChange}
          className="autorenewCheckbox"
          checkboxProps={{
            inputProps: { ...qaAttr('save-for-autorenew-checkbox-input') }
          }}
          {...qaAttr('save-for-autorenew-checkbox')}
        />
      )}
      <Button
        variant="filled-primary"
        endIcon={submitLoading ? <Spinner size={24} /> : null}
        disabled={couponLoading || submitLoading /* || employerLoading */}
        sx={{ width: '100%', maxWidth: 400, height: 50 }}
        onClick={isCheckout && selectedCard ? payWithSelectedCard : submit}
        testID="stripe-modal-submit-button"
      >
        {getSubmitBtnTitle()}
      </Button>
    </div>
  );

  const renderContent = () => (
    <div className="content">
      <div
        className={clsx('content__inner', isCheckout && 'checkout', cards.length && 'withCards')}
      >
        <div className="paymentDetailsContainer">
          {isCheckout && <h2 className="subTitle">Add New Payment Method</h2>}
          <div>
            {renderCard()}
            {renderForm()}
          </div>
        </div>
        {isCheckout && !!cards.length && renderSavedCards()}
      </div>
      {renderActions()}
    </div>
  );

  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      scroll="paper"
      classes={{
        paper: clsx('paper', isCheckout && 'checkout', cards.length && 'withCards')
      }}
      onClose={onClose}
    >
      <div className="header">
        <Box minWidth={36} />
        <h1 className="title" {...qaAttr('stripe-modal-title')}>
          {getTitle()}
          {/* {employerLoading && <Spinner width={22} height={22}/>} */}
        </h1>
        <IconButton edge="end" color="primary" onClick={onClose} testID="stripe-modal-close-button">
          <MdClose />
        </IconButton>
      </div>
      {renderContent()}
    </StyledDialog>
  );
}

PaymentDialog.propTypes = {
  cardId: PT.string,
  isOpen: PT.bool.isRequired,
  type: PT.oneOf(['', 'checkout', 'card_edit', 'card_add']).isRequired,
  onClose: PT.func.isRequired,
  onPlanUpgrade: PT.func,
  onCardUpdate: PT.func
};

PaymentDialog.defaultProps = {
  cardId: '',
  onPlanUpgrade: () => {},
  onCardUpdate: () => {}
};

export default memo(PaymentDialog);
