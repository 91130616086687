import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import { useCommonUI, useRestrictions, useNotifications, useEmployeeTrainings } from 'hooks';
import { GET_EMPLOYER_PROFILE, employerSubscriptionVar } from 'api';
import {
  getUserId,
  getProfileId,
  getRoutes,
  getEmployerSubscriptionInfo,
  isPaymentEnabled
} from 'utils';
import { trackPage } from 'utils/segmentAnalytics';
import { Box, useMediaQuery } from 'components';
import { EmployerHeader, EmployeeHeader } from 'components/Header';
import { ShortCompanyCard } from 'components/Dashboard/employer';
import AppLayout from './AppLayout';

const ROUTES = getRoutes();

function PrivateLayout({ children, userRole }) {
  const isDesktop = useMediaQuery('(min-width:1024px)', { noSsr: true });
  const { id: idParam } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const { closePaymentModal, openUpdatePlanModal, openPaymentModal } = useCommonUI();
  const { fetchRestrictions } = useRestrictions();
  const { show: showNotification } = useNotifications();
  const employerSubsInfo = useReactiveVar(employerSubscriptionVar);

  // const matchEmployeeJob = useMatch(ROUTES.employee.job);
  // const isEmployeeJobPage = matchJob?.pathname === getRoutes({ id: idParam }).employee.job;
  const isEmployerProfilePage = pathname === ROUTES.employer.profile;
  const isEmployerPlansPage = pathname === ROUTES.employer.plans;
  const isNewJobPage = pathname === ROUTES.employer.job_new;
  const isEditJobPage = pathname === getRoutes({ id: idParam }).employer.job_edit;

  const showShortCompanyCard =
    userRole === 'employer' &&
    !isDesktop &&
    !isNewJobPage &&
    !isEditJobPage &&
    !isEmployerProfilePage &&
    !isEmployerPlansPage;

  const { fetchTrainings, trainings } = useEmployeeTrainings();
  const unTakenTrainings = trainings.filter((o) => !o.complete && !o.taken);

  const [getEmployerProfile] = useLazyQuery(GET_EMPLOYER_PROFILE, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.employerProfile) {
        const profile = data.employerProfile;
        const {
          allowPlan,
          allowJobPosting = null,
          profile_id = null,
          showPlanModal
        } = profile || {};
        localStorage.setItem('profileId', profile_id);
        const { isUpgradeRequired, isPaymentRequired } = getEmployerSubscriptionInfo(profile);

        if (isPaymentEnabled() && allowPlan) {
          const { planId } = location?.state || {};
          const selectedPlan = isEmployerProfilePage && Number(planId);
          if (!selectedPlan) {
            if (!isEmployerPlansPage && isUpgradeRequired) {
              openUpdatePlanModal({ leadToPlans: true });
            } else if (isPaymentRequired) {
              openPaymentModal({
                type: 'checkout',
                onPlanUpgrade: () => {
                  closePaymentModal();
                  fetchRestrictions(); // update profile and restrictions with up-to-date data
                }
              });
            }
          }
        }
      }
    }
  });

  const getEmployerProfileByUserId = (params = {}) => {
    const userId = getUserId();
    if (userId) {
      getEmployerProfile({
        variables: { userId: parseInt(userId) },
        fetchPolicy: 'cache-first',
        ...params
      });
    }
  };

  useEffect(() => {
    if (userRole === 'employer') {
      getEmployerProfileByUserId();

      if (isEmployerProfilePage) {
        const { planId } = location?.state || {};
        const planIdToNum = Number(planId);
        const { currentPlanId } = employerSubsInfo;
        if (planIdToNum && currentPlanId !== planIdToNum) {
          openUpdatePlanModal({
            planId: planIdToNum,
            leadToPlans: false
          });
        }
      }
    } else {
      fetchTrainings();
    }
  }, []);

  useEffect(() => {
    if (unTakenTrainings.length) {
      showNotification([{ type: 'newTraining', notificationKey: 'trainingsNotification' }]);
    }
  }, [JSON.stringify(unTakenTrainings)]);

  useEffect(() => {
    if (location?.pathname) trackPage([location.pathname]);
  }, [location]);

  const renderHeader = () => {
    if (userRole === 'employer') return <EmployerHeader />;
    if (userRole === 'employee') return <EmployeeHeader />;
    return null;
  };

  return (
    <AppLayout fullWidth={isEmployerPlansPage}>
      <Box width="100%" maxWidth={1024} mx="auto">
        {renderHeader()}
        {showShortCompanyCard && <ShortCompanyCard />}
      </Box>
      {children}
    </AppLayout>
  );
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  userRole: PropTypes.string.isRequired
};

export default PrivateLayout;
