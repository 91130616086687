import React, { useCallback, useMemo, memo } from 'react';
import PT from 'prop-types';
import { useQuery } from '@apollo/client';
import map from 'lodash/map';
import { qaAttr } from 'utils';
import { Box, styled, useMediaQuery } from 'components';
import { Button, Deck, IconButton, Spinner } from 'components/shared';
import { MdArrowBack, MdClose, MdCheck, MdStarBorder, MdSearch } from 'components/icons';
import { GET_STATES, GET_COUNTRIES } from 'api';
import styles from 'styles/Dashboard/EmployeeDashboard';
import JobCard from './JobCard';

function AllJobs(props) {
  const {
    acceptedIds,
    cardIndex,
    deckRef,
    disableAllActions,
    handleApply,
    handleJobSelect,
    handleJobReject,
    handleReturnPrevCard,
    handleStar,
    isCardSwiped,
    isPaginationEnabled,
    isPaginationLoading,
    isJobApproving,
    isAcceptDisabled,
    isAcceptLoading,
    isRejectDisabled,
    isRejectLoading,
    isRetractDisabled,
    isRetractLoading,
    isStarDisabled,
    isStarLoading,
    jobs,
    loading,
    openInterviewScheduleModal,
    onInterviewAccept,
    onLoadMore,
    rejectedIds,
    setCurrDeckHeight,
    setAcceptedIds
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const { data: countriesData } = useQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const { data: statesData } = useQuery(GET_STATES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const handleRetract = (job = {}) => {
    if (isDesktop) {
      handleReturnPrevCard(job, false, job.isSchedule);
    } else {
      const job = jobs[cardIndex - 1];
      const isAccepted = job?.id ? acceptedIds[job.id] : false;
      handleReturnPrevCard(job, true, job.isSchedule && isAccepted);
    }
  };

  const handleSwipeLeft = (params = {}) => {
    const { item, index } = deckRef.current.swipeLeft();
    onSwipeLeft(item, index, params);
  };

  const handleSwipeRight = (params = {}) => {
    const { item, index } = deckRef.current.swipeRight();
    onSwipeRight(item, index, params);
  };

  const onSwipeLeft = useCallback(
    (item, index, params) => {
      if (item?.id) {
        disableAllActions();
        handleJobReject(item, { isSwiped: true });
      }
    },
    [handleJobReject]
  );
  const onSwipeRight = useCallback(
    (item, index, params) => {
      if (item?.id) {
        disableAllActions();
        handleApply(item, {
          isSwiped: true,
          showSchedule: () => openInterviewScheduleModal(item)
        });
      }
    },
    [handleApply, openInterviewScheduleModal]
  );

  const handleScheduleCall = useCallback((job, opts = {}) => {
    const { showJobCardSchedule } = opts;

    if (isDesktop) handleApply(job, { showSchedule: showJobCardSchedule });
    else handleSwipeRight();
  }, []);

  const renderDeckItem = useCallback(
    (job, { disabled }) => (
      <JobCard
        data={job}
        states={statesData?.getAllStates || []}
        countries={countriesData?.getAllCountries || []}
        onSelect={handleJobSelect}
        onAccept={handleApply}
        onReject={handleJobReject}
        onStar={handleStar}
        onScheduleCall={handleScheduleCall}
        onInterviewConfirm={onInterviewAccept}
        withSchedule
      />
    ),
    [handleJobSelect]
  );
  const deckLeftLabel = useMemo(() => <div className="deckLabel__pass">Pass</div>, []);
  const deckRightLabel = useMemo(() => <div className="deckLabel__apply">Apply</div>, []);
  const deckClassNames = useMemo(
    () => ({
      card: 'mobileJobCard',
      cardChild: 'mobileJobCardContent',
      cardWrapper: 'mobileJobCardWrapper',
      deckWrapper: 'jobsDeckWrapper'
    }),
    []
  );

  const handleDeckHeightChange = useCallback((height) => {
    setCurrDeckHeight(height);
  }, []);

  if (!loading && !jobs.length) {
    return (
      <div className="noJobs">
        <Box mb="25px" display="flex" justifyContent="center">
          <div className="noJobs__icon">
            <MdSearch color="primary" fontSize="inherit" />
          </div>
        </Box>
        <h1 className="noJobs__title">Check Back Soon!</h1>
        <Box maxWidth={330} mb="80px" mx="auto">
          <p className="noJobs__text">
            Looks like there are no jobs in your area at the moment, but we’re always expanding to
            new markets!
          </p>
        </Box>
        <Box maxWidth={360} mx="auto" className="noJobs__box">
          <Box component="p" mb="14px" className="noJobs__text noJobs__subTitle">
            Moving?
          </Box>
          <p className="noJobs__text">
            Change your zip code to the one your moving to and see what jobs await you at your new
            home!
          </p>
        </Box>
      </div>
    );
  }

  return (
    <>
      <div className="allJobsContainer">
        {isDesktop ? (
          <>
            {map(jobs, (job, i) => {
              const isAccepted = job?.id ? acceptedIds[job.id] : false;
              const isRejected = job?.id ? rejectedIds[job.id] : false;
              const status = isAccepted ? 'accepted' : isRejected ? 'rejected' : '';
              return (
                <div className="jobCol" key={`jobCol__${i}`}>
                  <JobCard
                    data={job}
                    states={statesData?.getAllStates || []}
                    countries={countriesData?.getAllCountries || []}
                    status={status}
                    onSelect={handleJobSelect}
                    onAccept={handleApply}
                    onReject={handleJobReject}
                    onRetract={handleRetract}
                    onStar={handleStar}
                    onScheduleCall={handleScheduleCall}
                    onInterviewConfirm={onInterviewAccept}
                    withSchedule
                  />
                </div>
              );
            })}
          </>
        ) : (
          <>
            <Deck
              classNames={deckClassNames}
              disabled={isJobApproving || isPaginationLoading}
              items={jobs}
              initIndex={cardIndex}
              leftLabel={deckLeftLabel}
              renderItem={renderDeckItem}
              ref={deckRef}
              rightLabel={deckRightLabel}
              onSwipeLeft={onSwipeLeft}
              onSwipeRight={onSwipeRight}
              onDeckHeightChange={handleDeckHeightChange}
            />
            {jobs.length > 0 && (
              <div className="mobileControllers">
                <div className="mobileControllerWrapper">
                  <div className="mobileControllerBtnWrapper">
                    <IconButton
                      variant="outlined"
                      color="primary"
                      aria-describedby="mobile-cancel-button"
                      disabled={!isCardSwiped || isRetractDisabled}
                      sx={{ width: 42, height: 42 }}
                      onClick={handleRetract}
                      testID="job-retract-button"
                    >
                      {isRetractLoading ? <Spinner size={24} /> : <MdArrowBack />}
                    </IconButton>
                  </div>
                  <div className="mobileControllerLabel" id="mobile-cancel-button">
                    Cancel
                  </div>
                </div>
                <div className="mobileControllerWrapper">
                  <div className="mobileControllerBtnWrapper">
                    <IconButton
                      variant="outlined"
                      color="primary"
                      aria-describedby="mobile-skip-button"
                      disabled={isRejectDisabled}
                      classes={{ root: `deckAction big` }}
                      sx={{ fontSize: 32 }}
                      onClick={() => handleSwipeLeft()}
                      testID="job-reject-button"
                    >
                      {isRejectLoading ? <Spinner size={30} /> : <MdClose fontSize="inherit" />}
                    </IconButton>
                  </div>
                  <div className="mobileControllerLabel" id="mobile-skip-button">
                    Skip Job
                  </div>
                </div>
                {!jobs[cardIndex]?.isSchedule && (
                  <div className="mobileControllerWrapper">
                    <div className="mobileControllerBtnWrapper">
                      <IconButton
                        variant="outlined"
                        color="primary"
                        aria-describedby="mobile-apply-button"
                        disabled={isAcceptDisabled}
                        classes={{ root: `deckAction big` }}
                        sx={{ fontSize: 32 }}
                        onClick={() => handleSwipeRight()}
                        testID="job-accept-button"
                      >
                        {isAcceptLoading ? <Spinner size={30} /> : <MdCheck fontSize="inherit" />}
                      </IconButton>
                    </div>
                    <div className="mobileControllerLabel" id="mobile-apply-button">
                      Apply Now!
                    </div>
                  </div>
                )}
                <div className="mobileControllerWrapper">
                  <div className="mobileControllerBtnWrapper">
                    <IconButton
                      variant="outlined"
                      color="primary"
                      aria-describedby="mobile-star-button"
                      disabled={isStarDisabled}
                      sx={{ width: 42, height: 42 }}
                      onClick={() => handleStar(jobs[cardIndex], true)}
                      testID="job-star-button"
                    >
                      {isStarLoading ? <Spinner size={24} /> : <MdStarBorder />}
                    </IconButton>
                  </div>
                  <div className="mobileControllerLabel" id="mobile-star-button">
                    Save for Later
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isDesktop && !!jobs.length && isPaginationEnabled && (
        <Box p="50px 20px" display="flex" justifyContent="center">
          <Button
            variant="text"
            sx={{ padding: '20px 30px', fontSize: 20 }}
            endIcon={isPaginationLoading && <Spinner width={20} />}
            disabled={isPaginationLoading}
            onClick={onLoadMore}
            testID="load-more-button"
          >
            Load more
          </Button>
        </Box>
      )}
    </>
  );
}

AllJobs.propTypes = {
  acceptedIds: PT.object.isRequired,
  cardIndex: PT.number.isRequired,
  deckRef: PT.objectOf(PT.any).isRequired,
  disableAllActions: PT.func.isRequired,
  handleApply: PT.func.isRequired,
  handleJobSelect: PT.func.isRequired,
  handleJobReject: PT.func.isRequired,
  handleReturnPrevCard: PT.func.isRequired,
  handleStar: PT.func.isRequired,
  isCardSwiped: PT.bool.isRequired,
  isPaginationEnabled: PT.bool.isRequired,
  isPaginationLoading: PT.bool,
  isJobApproving: PT.bool,
  isAcceptDisabled: PT.bool,
  isAcceptLoading: PT.bool,
  isRejectDisabled: PT.bool,
  isRejectLoading: PT.bool,
  isRetractDisabled: PT.bool,
  isRetractLoading: PT.bool,
  isStarDisabled: PT.bool,
  isStarLoading: PT.bool,
  jobs: PT.arrayOf(PT.any).isRequired,
  loading: PT.bool,
  openInterviewScheduleModal: PT.func.isRequired,
  onInterviewAccept: PT.func.isRequired,
  onLoadMore: PT.func.isRequired,
  rejectedIds: PT.object.isRequired,
  setCurrDeckHeight: PT.func.isRequired,
  setAcceptedIds: PT.func.isRequired
};

AllJobs.defaultProps = {
  isPaginationLoading: false,
  isJobApproving: false,
  isAcceptDisabled: false,
  isAcceptLoading: false,
  isRejectDisabled: false,
  isRejectLoading: false,
  isRetractDisabled: false,
  isRetractLoading: false,
  isStarDisabled: false,
  isStarLoading: false,
  loading: false
};

const StyledAllJobs = styled(AllJobs)(styles);

export default memo(StyledAllJobs);
