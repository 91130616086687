import React, { createContext, useCallback, useReducer, useMemo } from 'react';
import PT from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { fetchEmployerProfile } from 'api';
import { RestrictionsContext } from './context';

const initialState = {
  // custom
  showEnterpriseDashboard: false,
  // from backend
  allowEmployeeActions: false,
  allowEnterprise: false,
  jobPosting: false,
  numberOfJobPosting: 1,
  initFetch: true,
  loading: false
};

const handlers = {
  SET: (state, { payload, loading = false }) => {
    return { ...state, ...payload, loading, initFetch: state.initFetch && false };
  }
};

function reducer(state, action) {
  if (handlers[action.type]) return handlers[action.type](state, action);
}

// eslint-disable-next-line react/prop-types
function RestrictionsProvider({ children, context }) {
  const client = useApolloClient();
  const [restrictions, dispatch] = useReducer(reducer, initialState);

  const fetchRestrictions = useCallback(
    async (fetchParams = {}) => {
      const userId = localStorage.getItem('userId');

      if (userId) {
        dispatch({ type: 'SET', payload: {}, loading: true });
        const [profile, extraResp] = await fetchEmployerProfile({
          queryParams: { fetchPolicy: 'network-only' }, // network-only will guarantee cache update
          client,
          ...fetchParams
        });
        const response = extraResp?.response;
        if (response?.data?.employerProfile?.allowPlan) {
          dispatch({
            type: 'SET',
            payload: {
              ...(response?.data?.employerProfile?.restrictions || {}),
              showEnterpriseDashboard: true
            },
            loading: false
          });
        } else {
          dispatch({
            type: 'SET',
            payload: {
              ...(response?.data?.employerProfile?.restrictions || {}),
              allowEmployeeActions: true,
              allowEnterprise: false,
              showEnterpriseDashboard: false, // if allowPlan === false - allow everything, except enterprise board
              jobPosting: true
            },
            loading: false
          });
        }
        return response?.data;
      }
    },
    [client]
  );

  const setRestrictions = useCallback((payload) => dispatch({ type: 'SET', payload }), []);

  const contextValue = useMemo(
    () => ({
      fetchRestrictions,
      setRestrictions,
      restrictions,
      dispatch
    }),
    [JSON.stringify(restrictions)]
  );

  const Context = context || RestrictionsContext;

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

RestrictionsProvider.propTypes = {
  children: PT.node.isRequired
};

export default RestrictionsProvider;
