import React, { useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, styled } from 'components';
import { Button, CheckBoxGroup, IconButton, Input, Spinner } from 'components/shared';
import { ApplicantProfileDialog, ConfirmationDialog } from 'components/dialogs';
import { GoogleCalendarIcon, MdClose, MdMoreHoriz, MsOutlookIcon } from 'components/icons';
import { GET_HIRE_REASONS, HIRE_EMPLOYEE, CANCEL_INTERVIEW, SET_EMPLOYER_CHAT } from 'api';
import { qaAttr, trimStr, getUserId } from 'utils';
import find from 'lodash/find';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import clsx from 'clsx';
import styles from 'styles/EmployerSchedule/ScheduledEventCard';

import employeeDefaultImg from 'assets/img/employee_default.png';

const StyledRoot = styled('div')(styles);

const VIEWS = {
  default: 'default',
  default_menu: 'default_menu',
  hire_reasons: 'hire_reasons',
  hire_notes: 'hire_notes',
  deny_reasons: 'deny_reasons',
  deny_notes: 'deny_notes',
  message: 'message'
};

const MESSAGE =
  'Thanks for applying, we would love to interview you but you need {fill in reason here} before we can consider you.';

const modalsDefault = {
  profileModal: false,
  cancelConfirmationModal: false,
  cancelWithRequestConfirmationModal: false,
  interviewScheduleModal: false
};

const getHiredStatusState = (hireStatus) => {
  if (hireStatus === 'default') return null;
  return hireStatus === 'true';
};

function ScheduledEventCard(props) {
  const {
    afterCancel,
    afterHire,
    companyTitle,
    formattedTime,
    employeeUserId,
    employeeProfileId,
    employeeImageUrl,
    employeeName,
    hireStatus,
    interviewId,
    interviewDate,
    interviewOriginIsoDate,
    interviewAddress,
    interviewTimeZone,
    isPast,
    jobId,
    jobTitle,
    status,
    statusEnum
  } = props;
  const [hired, setHired] = useState(() => getHiredStatusState(hireStatus));
  const [view, setView] = useState(VIEWS.default);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [notes, setNotes] = useState('');
  const [message, setMessage] = useState(MESSAGE);
  const [modals, setModals] = useState(modalsDefault);

  const cardIdPrefix = `${employeeUserId}-${jobId}`;

  const eventTitle = `Interview @${companyTitle}`;
  const eventLocation = interviewAddress;
  const eventDetails = `Position: ${jobTitle}`;
  const eventDateGoogle = interviewDate ? format(interviewDate, "yyyyMMdd'T'HHmmss") : ''; // not in UTC. Timezone will be displayed separately
  const eventDateOutlook = interviewOriginIsoDate;
  const eventTimeZone = interviewTimeZone;
  const googleCalendarLink = `https://calendar.google.com/calendar/event?action=TEMPLATE&text=${eventTitle}&ctz=${eventTimeZone}&dates=${eventDateGoogle}/${eventDateGoogle}&location=${eventLocation}&details=${eventDetails}&sf=true&output=xml`;
  const outlookCalendarLink = `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&subject=${eventTitle}&&startdt=${eventDateOutlook}&enddt=${eventDateOutlook}&location=${eventLocation}&body=${eventDetails}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`;

  const isDefaultView = view === VIEWS.default;
  const isDefaultMenuView = view === VIEWS.default_menu;
  const isHiredReasonsView = view === VIEWS.hire_reasons;
  const isHiredNotesView = view === VIEWS.hire_notes;
  const isDenyReasonsView = view === VIEWS.deny_reasons;
  const isDenyNotesView = view === VIEWS.deny_notes;
  const isMessageView = view === VIEWS.message;

  const isReasonsView =
    isHiredReasonsView || isHiredNotesView || isDenyReasonsView || isDenyNotesView;

  const { data: hireReasonsData, loading: reasonsLoading } = useQuery(GET_HIRE_REASONS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const [hireEmployee, { loading: hireLoading }] = useMutation(HIRE_EMPLOYEE);

  const [cancelInterview, { loading: cancelLoading }] = useMutation(CANCEL_INTERVIEW);

  const [setEmployerChat] = useMutation(SET_EMPLOYER_CHAT);

  useEffect(() => {
    if (hireStatus) setHired(getHiredStatusState(hireStatus));
  }, [hireStatus]);

  const manageModals = (newConfig = {}) => {
    setModals((prev) => ({ ...prev, ...newConfig }));
  };

  const openProfileModal = () => manageModals({ profileModal: true });
  const closeProfileModal = () => manageModals({ profileModal: false });

  const closeConfirmation = () => {
    manageModals({
      cancelConfirmationModal: false,
      cancelWithRequestConfirmationModal: false
    });
  };

  const handleCancelInterview = () => {
    manageModals({
      cancelConfirmationModal: true,
      cancelWithRequestConfirmationModal: false
    });
  };

  const handleRescheduleInterview = () => {
    manageModals({
      cancelConfirmationModal: false,
      cancelWithRequestConfirmationModal: true
    });
  };

  const confirmInterviewCancel = async () => {
    await cancelInterview({
      variables: {
        isReschedule: modals.cancelWithRequestConfirmationModal,
        interviewId,
        employeeProfileId,
        jobId
      }
    });
    closeConfirmation();
    setDefaultView();
    afterCancel(employeeProfileId, jobId);
  };

  const confirmHire = async (isHired) => {
    await hireEmployee({
      variables: {
        jobId,
        employeeId: employeeUserId,
        notes: trimStr(notes),
        hired: isHired,
        hiredReasons: selectedReasons
      }
    });
    afterHire(isHired, employeeProfileId, jobId);
    setDefaultView();
  };

  const sendMessage = () => {
    const userId = parseInt(getUserId());
    const msg = trimStr(message);

    if (userId && msg) {
      setEmployerChat({
        variables: {
          messageTo: userId,
          messageFrom: employeeUserId,
          jobsId: jobId,
          usersUserId: userId,
          message: msg
        }
      });
    }
    setDefaultView();
  };

  const handleHire = () => setView(VIEWS.hire_reasons);

  const handleDeny = () => setView(VIEWS.deny_reasons);

  const getConfirmationModalProps = () => {
    let confirmMessage = '';

    if (modals.cancelWithRequestConfirmationModal) {
      confirmMessage = 'Applicant will be asked to reschedule your interview';
    }

    return {
      isOpen: modals.cancelConfirmationModal || modals.cancelWithRequestConfirmationModal,
      message: <Box textAlign="center">{confirmMessage}</Box>,
      title: 'Cancel selected interview?',
      confirmBtnName: 'Cancel',
      cancelBtnName: 'Dismiss',
      onConfirm: () => confirmInterviewCancel(modals.cancelWithRequestConfirmationModal)
    };
  };

  const setDefaultView = () => {
    if (isDefaultView || isDefaultMenuView) {
      setView(isDefaultView ? VIEWS.default_menu : VIEWS.default);
    } else if (isHiredReasonsView || isDenyReasonsView || isHiredNotesView || isDenyNotesView) {
      setSelectedReasons([]);
      setNotes('');
      setView(VIEWS.default);
    } else if (isMessageView) {
      setMessage(MESSAGE);
      setView(VIEWS.default);
    }
  };

  const onCheck = (data, checked) => {
    let newChecked = [...selectedReasons];
    if (!checked) newChecked = newChecked.filter((o) => o.id !== data.id);
    else newChecked = [...newChecked, data];
    setSelectedReasons(newChecked);
    setView(isHiredReasonsView ? VIEWS.hire_notes : VIEWS.deny_notes);
  };

  const getInterviewStatusClass = (statusEnumArg) => {
    switch (statusEnumArg) {
      case 'confirmed':
        return 'confirmed';
      case 'not_coming_needs_reschedule':
        return 'needsReSchedule';
      case 'not_coming':
        return 'notComing';
      default:
        return '';
    }
  };

  const getHiredStatusClass = (hiredArg) => {
    switch (hiredArg) {
      case true:
        return 'hired';
      case false:
        return 'denied';
      default:
        return '';
    }
  };

  const renderHiredView = () => {
    const isMenu = view === VIEWS.default_menu;
    const statusClassName = getHiredStatusClass(hired);

    return isMenu ? (
      <Box mt="10px">
        <Button
          className={`optionButton ${statusClassName}`}
          onClick={handleDeny}
          testID={`${cardIdPrefix}-deny-button`}
        >
          Did not hire
        </Button>
        <Button
          className={`optionButton ${statusClassName}`}
          onClick={setDefaultView}
          testID={`${cardIdPrefix}-close-button`}
        >
          Cancel
        </Button>
      </Box>
    ) : (
      <Box textAlign="center" color="#FFF">
        <Box component="span" fontWeight="bold" fontSize="20px">
          HIRED!
        </Box>
        <br />
        <Box component="span" fontSize="12px">{`as ${jobTitle}`}</Box>
      </Box>
    );
  };

  const renderDeniedView = () => {
    const isMenu = view === VIEWS.default_menu;
    const statusClassName = getHiredStatusClass(hired);

    return isMenu ? (
      <Box mt="10px">
        <Button
          className={`optionButton ${statusClassName}`}
          onClick={handleHire}
          testID={`${cardIdPrefix}-hire-button`}
        >
          Still Considering
        </Button>
        <Button
          className={`optionButton ${statusClassName}`}
          onClick={() => setView(VIEWS.message)}
          testID={`${cardIdPrefix}-message-button`}
        >
          Send Message
        </Button>
        <Button
          className={`optionButton ${statusClassName}`}
          onClick={setDefaultView}
          testID={`${cardIdPrefix}-close-button`}
        >
          Cancel
        </Button>
      </Box>
    ) : (
      <>
        <div className="time">{`@${formattedTime}`}</div>
        <div className="position">{jobTitle}</div>
        <Box flex={1} display="flex" alignItems="flex-end" justifyContent="center">
          <Button
            variant="filled-primary"
            className="messageAction"
            onClick={() => setView(VIEWS.message)}
            testID={`${cardIdPrefix}-message-button`}
          >
            Send Message
          </Button>
        </Box>
      </>
    );
  };

  const renderHiredDefaultView = () => {
    const isMenu = view === VIEWS.default_menu;
    const statusClassName = getHiredStatusClass(hired);

    return (
      <Box flex={1} display="flex" flexDirection="column">
        <IconButton
          className="cardHeaderBtn"
          onClick={setDefaultView}
          testID={`${cardIdPrefix}-${isMenu ? 'close' : 'menu'}-button`}
        >
          {isMenu ? <MdClose color="inherit" /> : <MdMoreHoriz color="inherit" />}
        </IconButton>
        <Box mb="9px" textAlign="center">
          <Button onClick={openProfileModal} testID={`${cardIdPrefix}-profile-button`}>
            <img
              src={employeeImageUrl || employeeDefaultImg}
              width={hired ? '78px' : '50px'}
              alt=""
              className={`image ${statusClassName}`}
            />
          </Button>
        </Box>
        <div className="name">{employeeName}</div>
        {hired ? renderHiredView() : renderDeniedView()}
      </Box>
    );
  };

  const renderDefaultView = () => {
    const isMenu = view === VIEWS.default_menu;
    const statusClassName = getInterviewStatusClass(statusEnum);

    if (hired !== null) return renderHiredDefaultView();

    return (
      <div>
        <IconButton
          className="cardHeaderBtn"
          onClick={setDefaultView}
          testID={`${cardIdPrefix}-${isMenu ? 'close' : 'menu'}-button`}
        >
          {isMenu ? <MdClose color="inherit" /> : <MdMoreHoriz color="inherit" />}
        </IconButton>
        <Box mb="9px" textAlign="center">
          <Button onClick={openProfileModal} testID={`${cardIdPrefix}-profile-button`}>
            <img
              src={employeeImageUrl || employeeDefaultImg}
              width="50px"
              alt=""
              className={`image ${statusClassName}`}
            />
          </Button>
        </Box>
        <div className="name">{employeeName}</div>
        {isMenu ? (
          <Box mt="10px">
            {!isPast && (
              <Button
                className="optionButton"
                onClick={handleCancelInterview}
                testID={`${cardIdPrefix}-cancel-interview-button`}
              >
                Cancel Interview
              </Button>
            )}
            {!isPast && (
              <Button
                className="optionButton"
                onClick={handleRescheduleInterview}
                testID={`${cardIdPrefix}-reschedule-button`}
              >
                Reschedule Interview
              </Button>
            )}
            <Button
              className="optionButton"
              onClick={handleHire}
              testID={`${cardIdPrefix}-hire-button`}
            >
              Hired Candidate
            </Button>
            <Button
              className="optionButton"
              onClick={handleDeny}
              testID={`${cardIdPrefix}-deny-button`}
            >
              Did Not Hire
            </Button>
          </Box>
        ) : (
          <>
            <div className="time">{`@${formattedTime}`}</div>
            <div className="position">{jobTitle}</div>
            <Box mb="10px">
              <Box component="span" fontSize="11px">
                Add To Calendar:{' '}
              </Box>
              <a
                href={googleCalendarLink}
                target="_blank"
                rel="noreferrer noopener"
                className="calendarLink"
                testID={`${cardIdPrefix}-google-calendar-link`}
              >
                <GoogleCalendarIcon />
              </a>
              <a
                href={outlookCalendarLink}
                target="_blank"
                rel="noreferrer noopener"
                className="calendarLink"
                testID={`${cardIdPrefix}-outlook-calendar-link`}
              >
                <MsOutlookIcon />
              </a>
            </Box>
            <Box mb="10px" display="flex">
              <Button
                variant="filled-primary"
                className="cardAction"
                onClick={handleHire}
                testID={`${cardIdPrefix}-hire-button`}
              >
                Hired
              </Button>
              <Button
                variant="filled-secondary"
                className="cardAction"
                onClick={handleDeny}
                testID={`${cardIdPrefix}-deny-button`}
              >
                Did not hire
              </Button>
            </Box>
            <div className={`status ${statusClassName}`}>{status}</div>
          </>
        )}
      </div>
    );
  };

  const renderReasonsView = () => {
    const isHired = isHiredNotesView || isHiredReasonsView;
    const isNotes = isHiredNotesView || isDenyNotesView;
    const reasons = hireReasonsData?.getReasons?.filter((o) => o.type === isHired) || [];
    const title = isHired ? 'Hired Reason' : 'Did not hire reason';

    return (
      <Box flex={1} display="flex" flexDirection="column">
        <Box mb="14px" display="flex" justifyContent="space-between" alignItems="center">
          <span className="cardTitle">{title}</span>
          <IconButton
            className="cardHeaderBtn"
            onClick={setDefaultView}
            testID={`${cardIdPrefix}-close-button`}
          >
            <MdClose color="inherit" />
          </IconButton>
        </Box>
        {isNotes ? (
          <>
            <Input
              value={notes}
              label="Notes"
              multiline
              maxRows={8}
              inputClassName="notesInput"
              labelClassName="notesInput__label"
              FormControlProps={{
                sx: { flex: 1, mb: '16px' }
              }}
              onChange={(e) => setNotes(e.target.value)}
              testID={`${cardIdPrefix}-notes-input`}
            />
            <Box textAlign="center">
              <Button
                variant="filled-primary"
                className="confirmAction"
                onClick={() => confirmHire(isHired)}
                testID={`${cardIdPrefix}-confirm-reason-button`}
              >
                Confirm
              </Button>
            </Box>
          </>
        ) : (
          reasons.map(({ __typename, ...opt }, i) => {
            const checked = !!find(selectedReasons, { id: opt.id });
            return (
              <label
                key={`reason__${cardIdPrefix}_${i}`}
                htmlFor={opt.id}
                className="optionButton"
                {...qaAttr(`${cardIdPrefix}-reason-button`)}
              >
                {opt.reason}
                <input
                  type="radio"
                  checked={checked}
                  id={opt.id}
                  value={opt.reason}
                  className="reasonCheckbox"
                  onChange={(e) => onCheck(opt, e.target.checked)}
                />
              </label>
            );
          })
        )}
      </Box>
    );
  };

  const renderMessageView = () => (
    <Box flex={1} display="flex" flexDirection="column">
      <Box mb="14px" display="flex" justifyContent="space-between" alignItems="center">
        <span className="cardTitle">To Candidate</span>
        <IconButton
          className="cardHeaderBtn"
          onClick={setDefaultView}
          testID={`${cardIdPrefix}-close-button`}
        >
          <MdClose color="inherit" />
        </IconButton>
      </Box>
      <Input
        value={message}
        label="Message"
        multiline
        maxRows={8}
        inputClassName="notesInput"
        labelClassName="notesInput__label"
        FormControlProps={{
          sx: { flex: 1, mb: '16px' }
        }}
        onChange={(e) => setMessage(e.target.value)}
        testID={`${cardIdPrefix}-message-input`}
      />
      <Box textAlign="center">
        <Button
          variant="filled-primary"
          className="confirmAction"
          onClick={sendMessage}
          testID={`${cardIdPrefix}-send-message-button`}
        >
          Send
        </Button>
      </Box>
    </Box>
  );

  const renderView = () => {
    switch (view) {
      case VIEWS.default:
      case VIEWS.default_menu:
        return renderDefaultView();
      case VIEWS.message:
        return renderMessageView();
      case VIEWS.deny_reasons:
      case VIEWS.hire_reasons:
      case VIEWS.hire_notes:
      case VIEWS.deny_notes:
        return renderReasonsView();
      default:
        return null;
    }
  };

  return (
    <StyledRoot className="container">
      <div
        className={clsx('content', (isMessageView || isReasonsView) && getHiredStatusClass(hired))}
      >
        {(hireLoading || cancelLoading) && (
          <div className="loaderOverlay">
            <Spinner size={24} />
          </div>
        )}
        {renderView()}
      </div>

      <ConfirmationDialog
        {...getConfirmationModalProps()}
        onClose={closeConfirmation}
        onCancel={closeConfirmation}
      />

      {modals.profileModal && (
        <ApplicantProfileDialog
          isOpen={modals.profileModal}
          id={employeeUserId}
          jobId={jobId}
          onClose={closeProfileModal}
          withActions={false}
        />
      )}
    </StyledRoot>
  );
}

ScheduledEventCard.propTypes = {
  afterCancel: PT.func,
  afterHire: PT.func,
  companyTitle: PT.string,
  formattedTime: PT.string,
  employeeUserId: PT.number,
  employeeProfileId: PT.number,
  employeeImageUrl: PT.string,
  employeeName: PT.string,
  hireStatus: PT.oneOf(['default', 'true', 'false']),
  isoDate: PT.string,
  interviewId: PT.number,
  interviewDate: PT.instanceOf(Date),
  interviewOriginIsoDate: PT.string,
  interviewTimeZone: PT.string,
  isPast: PT.bool,
  jobId: PT.number,
  jobTitle: PT.string,
  status: PT.string,
  statusEnum: PT.string
};

ScheduledEventCard.defaultProps = {
  afterCancel: () => {},
  afterHire: () => {},
  companyTitle: '',
  formattedTime: '',
  employeeUserId: null,
  employeeProfileId: null,
  employeeImageUrl: '',
  employeeName: '',
  hireStatus: 'default',
  isoDate: '',
  interviewId: null,
  interviewDate: new Date(),
  interviewOriginIsoDate: '',
  interviewTimeZone: '',
  isPast: false,
  jobId: null,
  jobTitle: '',
  status: '',
  statusEnum: ''
};

export default memo(ScheduledEventCard);
