import React from 'react';
import { TimeZoneProvider } from 'providers';
import { getDisplayName } from 'utils';

export default function withTimeZone(WrappedComponent, opts = {}) {
  const { initTimeZone = '' } = opts;
  function WithTimeZone(props) {
    return (
      <TimeZoneProvider initTimeZone={initTimeZone}>
        <WrappedComponent {...props} />
      </TimeZoneProvider>
    );
  }
  WithTimeZone.displayName = `WithTimeZone(${getDisplayName(WrappedComponent)})`;
  return WithTimeZone;
}
