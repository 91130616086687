import values from 'lodash/values';
import { enableScheduleAssistant } from './app';

export const getRoutes = (params = { id: null }) => {
  const idParam = params?.id || ':id';
  return {
    employer: {
      dashboard: '/employer-dashboard',
      profile: '/employer-dashboard/profile',
      plans: '/employer-dashboard/plans',
      // !place all /employer-dashboard/... paths before job
      job: `/employer-dashboard/${idParam}`,
      job_new: '/employer-dashboard/new-job',
      job_edit: `/employer-dashboard/${idParam}/edit`,
      job_match: `/employer-dashboard/${idParam}/match`,
      job_approved: `/employer-dashboard/${idParam}/approved`,
      job_starred: `/employer-dashboard/${idParam}/starred`,
      job_rejected: `/employer-dashboard/${idParam}/rejected`,
      enterprise: '/enterprise-dashboard',
      enterprise_search: '/enterprise-dashboard/search',
      enterprise_schedule: '/enterprise-dashboard/schedule',
      enterprise_grow: '/enterprise-dashboard/grow'
    },
    employee: {
      dashboard: '/employee-dashboard',
      profile: '/employee-dashboard/profile',
      grow: '/employee-dashboard/career-development',
      // !place all /employee-dashboard/... paths before job
      job: `/employee-dashboard/${idParam}`,
      progress_skills: `/employee-dashboard/career-progress/skills`,
      progress_connections: `/employee-dashboard/career-progress/connections`,
      progress_hired: `/employee-dashboard/career-progress/still-hired`
    },
    login: {
      default: '/login',
      verify: '/verify',
      newPwd: '/new-password'
    },
    onboarding: {
      default: '/onboarding'
    },
    blog: {
      default: '/blog',
      post: '/blog/:id'
    }
  };
};

const getEmployerRoutesPattern = () => {
  const dashboardPattern =
    '/employer-dashboard' +
    '(?:' +
    '(?:/profile)|' + // profile page
    '(?:/plans)|' + // plans page
    '(?:/([0-9]+))' + // job page (/123)
    '(?:/match|approved|starred|rejected)?' + // job sub page (/123/rejected)
    ')?';
  const enterprisePattern = '/enterprise-dashboard' + '(?:/search|/grow|/schedule)?'; // enterprise dashboard sub page
  return `^(?:${dashboardPattern}|${enterprisePattern})/?`;
};
const getEmployeeRoutesPattern = () => {
  const dashboardPattern =
    '/employee-dashboard' +
    '(?:' +
    '(?:/profile)|' +
    '(?:/career-development)|' +
    '(?:/([0-9]+))' +
    ')?';
  return `^(?:${dashboardPattern})/?`;
};

const employerPathnamesRegex = new RegExp(getEmployerRoutesPattern());
const employeePathnamesRegex = new RegExp(getEmployeeRoutesPattern());

export const dashboardRoutes = {
  employer: [...values(getRoutes().employer)],
  employee: [...values(getRoutes().employee)]
};

export const employeeStartPage = getRoutes().employee.grow;
export const employerStartPage = getRoutes().employer.enterprise;

export function getEmployerJobPaths() {
  return values(getRoutes().employer).filter((s) => s.includes('/:id'));
}

export function isEmployerJobPage(path) {
  const employerJobPaths = getEmployerJobPaths();
  return employerJobPaths.some((s) => s === path);
}

export function isEmployerNewJobPage(path) {
  return path === getRoutes().employer.job_new;
}

const defaultReturn = { isMatch: false, validPath: '', role: '', params: { jobId: '' } };

export function checkEmployerPathname(pathname) {
  if (pathname) {
    const match = pathname.match(employerPathnamesRegex);

    if (match) {
      return { isMatch: true, validPath: match[0], role: 'employer', params: { jobId: match[1] } };
    } else {
      const { isMatch, validPath, params } = checkEmployeePathname(pathname);
      if (isMatch) {
        if (params.jobId)
          return {
            isMatch: false,
            validPath: getRoutes({ id: params.jobId }).employer[params.jobId ? 'job' : 'dashboard'],
            role: 'employee',
            params: { jobId: params.jobId }
          };
        else return { isMatch: false, validPath, role: 'employee', params: { jobId: '' } };
      }
      return defaultReturn;
    }
  }
}

export function checkEmployeePathname(pathname) {
  if (pathname) {
    const match = pathname.match(employeePathnamesRegex);
    if (match) {
      return { isMatch: true, validPath: match[0], role: 'employee', params: { jobId: match[1] } };
    } else {
      const { isMatch, validPath, params } = checkEmployerPathname(pathname);
      if (isMatch) {
        if (params.jobId)
          return {
            isMatch: false,
            validPath: getRoutes({ id: params.jobId }).employee[params.jobId ? 'job' : 'dashboard'],
            role: 'employer',
            params: { jobId: params.jobId }
          };
        else return { isMatch: false, validPath, role: 'employer', params: { jobId: '' } };
      }
      return defaultReturn;
    }
  }
}

export function checkReferrerPage(nextPathname = '', role = '') {
  if (nextPathname && role) {
    if (role === 'employee') {
      return checkEmployeePathname(nextPathname) || {};
    } else if (role === 'employer') {
      return checkEmployerPathname(nextPathname) || {};
    }
    return defaultReturn;
  }
  return defaultReturn;
}
