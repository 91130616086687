import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/public/createUploadLink';
import apolloLogger from 'apollo-link-logger';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import getEmployerSubscriptionInfo from 'utils/getEmployerSubscriptionInfo';
import { reset as resetSegmentAnalytics } from 'utils/segmentAnalytics';
import AuthRef from 'providers/AuthRef';
import { updateEmployerSubscriptionVar, employerSubscriptionVar } from '../reactiveVars';

const { URI, URI_BASE, URI_UPLOAD, NODE_ENV, EXPOSE_QUERY_NAME } = process.env;
const isProd = NODE_ENV === 'production';
const showQueryName = EXPOSE_QUERY_NAME === 'true';

// Development
// const URI = 'http://qh-api.galogram.site:4000/apiV1';
// const URI_BASE = 'http://qh-api.galogram.site:4000/';

// Production
// const URI = 'http://api.getquickhire.com:4000/apiV1';
// const URI_BASE = 'http://api.getquickhire.com:4000';

const httpLink = createUploadLink({
  uri: URI
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `%c[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`,
        'color: red;'
      )
    );

    if (find(graphQLErrors, { message: 'Not authenticated' }) && AuthRef?.current?.signout) {
      AuthRef.current.signout();
    }
  }

  if (networkError) {
    console.log(`%c[Network error]: ${networkError}`, 'color: red;');

    if (networkError.statusCode === 401 && AuthRef?.current?.signout) {
      AuthRef.current.signout();
    }
  }
});

const namedLink = new ApolloLink((operation, forward) => {
  // enable distinguishable graphql query names in browser network tab
  if (showQueryName) {
    operation.setContext(() => ({
      uri: `${URI}?${operation.operationName}`
    }));
  }
  return forward ? forward(operation) : null;
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        employeeJobs: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing, incoming = [], { args: { offset = 0, limit = 0 }, readField }) {
            const incomingArr = incoming || [];
            const merged = existing ? existing.slice(0) : [];
            const existingIds = merged.map((job) => readField('id', job));

            for (let i = 0; i < incomingArr.length; ++i) {
              const id = readField('id', incomingArr[i]);
              if (id && existingIds.indexOf(id) === -1) merged.push(incomingArr[i]);
            }

            return merged;
          }
        },
        employerProfile: {
          merge(existing, incoming, { readField }) {
            if (incoming) {
              const { trialTimePlan, showPlanModal } = incoming;
              const subscriptionPlanEmployerFields = [
                'autorenew',
                'endDate',
                'id',
                'name',
                'paid',
                'paused',
                'pausedDate',
                'planEmployerId',
                'price',
                'restrictions',
                'startDate',
                'status'
              ];
              const subscriptionPlanRef = incoming.subscriptionPlan;
              const newSubscriptionPlanRef = incoming.newSubscriptionPlan;
              let subscriptionPlan;
              let newSubscriptionPlan;

              if (subscriptionPlanRef) {
                subscriptionPlan = {};
                forEach(subscriptionPlanEmployerFields, (field) => {
                  subscriptionPlan[field] = readField(field, subscriptionPlanRef);
                });
              }
              if (newSubscriptionPlanRef) {
                if (newSubscriptionPlanRef !== subscriptionPlanRef) {
                  newSubscriptionPlan = {};
                  forEach(subscriptionPlanEmployerFields, (field) => {
                    newSubscriptionPlan[field] = readField(field, newSubscriptionPlanRef);
                  });
                } else {
                  newSubscriptionPlan = subscriptionPlan;
                }
              }

              updateEmployerSubscriptionVar(
                getEmployerSubscriptionInfo({
                  trialTimePlan,
                  showPlanModal,
                  subscriptionPlan,
                  newSubscriptionPlan
                })
              );
            }

            return incoming || undefined; // do not store null in cache, cause it will be returned from query
          }
        },
        employeeProfile: {
          merge(existing, incoming, { readField }) {
            return incoming || undefined; // do not store null in cache, cause it will be returned from query
          }
        },
        getSkills: {
          read(skills) {
            return skills ? sortBy(skills, ['name']) : skills;
          }
        },
        getIndustry: {
          read(industries) {
            return industries ? sortBy(industries, ['name']) : industries;
          }
        }
      }
    }
  }
});

const client = new ApolloClient({
  cache,
  link: from([apolloLogger, namedLink, authLink, errorLink, httpLink]),
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
});

client.logout = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('profileId');
  localStorage.removeItem('post_signup_referrer');
  resetSegmentAnalytics();
  await client.clearStore();
};

export default client;

export { URI, URI_BASE, URI_UPLOAD };
