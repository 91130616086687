import React from 'react';
import PT from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, styled, useMediaQuery } from 'components';
import { MdExpandMore } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .sectionHeader': {
    minHeight: 'auto',
    padding: '4px 0',
    borderBottom: '1px solid #ECECF5',
    backgroundColor: '#fff',
    '&.Mui-expanded': {
      minHeight: 'auto'
    },
    '&.sticky-header': {
      position: 'sticky',
      top: 0,
      zIndex: 20
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'sticky',
      top: 0,
      zIndex: 20
    }
  },
  '& .sectionHeader__content': {
    margin: '4px 0',
    '&.Mui-expanded': {
      margin: '4px 0'
    }
  },
  '& .sectionHeader__expandIcon': {
    marginRight: 0,
    padding: 0,
    color: '#808080'
  },
  '& .sectionHeader__title': {
    display: 'inline',
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '25px',
    color: '#3D3D3D',
    '& > a': {
      color: 'inherit'
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      color: '#6A6A6A'
    }
  },
  '& .sectionHeader__action': {
    marginLeft: 12,
    fontSize: 13,
    lineHeight: '14px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginLeft: 15,
      fontSize: 14,
      lineHeight: '16px'
    }
  },
  '& .sectionBody': {
    padding: 0
  }
}));

function SectionLayout(props) {
  const {
    children,
    expanded,
    jumpLink,
    jumpLinkProps,
    onExpand,
    sectionName,
    sectionHeaderContent,
    sectionHeaderProps
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const renderSectionHeader = () => (
    <div {...sectionHeaderProps}>
      <h2 className="sectionHeader__title">
        {jumpLink ? (
          <a id={jumpLink} href={`#${jumpLink}`} {...jumpLinkProps}>
            {sectionName}
          </a>
        ) : (
          sectionName
        )}
      </h2>
      {sectionHeaderContent}
    </div>
  );

  const renderDesktopLayout = () => (
    <StyledRoot>
      <div className="sectionHeader" id={`${jumpLink}-section-header`}>
        {renderSectionHeader()}
      </div>
      <div>{children}</div>
    </StyledRoot>
  );

  const renderMobileLayout = () => (
    <StyledRoot>
      <Accordion
        expanded={expanded}
        square
        elevation={0}
        classes={{
          root: 'sectionContainer'
        }}
        onChange={(e, expand) => onExpand(expand ? sectionName : null, sectionName, expand)}
      >
        <AccordionSummary
          id={`${jumpLink}-section-header`}
          expandIcon={<MdExpandMore fontSize="inherit" color="inherit" />}
          classes={{
            root: 'sectionHeader',
            content: 'sectionHeader__content',
            expandIcon: 'sectionHeader__expandIcon'
          }}
        >
          {renderSectionHeader()}
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'sectionBody' }}>{children}</AccordionDetails>
      </Accordion>
    </StyledRoot>
  );

  return isDesktop ? renderDesktopLayout() : renderMobileLayout();
}

SectionLayout.propTypes = {
  children: PT.node,
  expanded: PT.bool.isRequired,
  jumpLink: PT.string,
  jumpLinkProps: PT.objectOf(PT.any),
  sectionName: PT.string.isRequired,
  sectionHeaderContent: PT.node,
  sectionHeaderProps: PT.object,
  onExpand: PT.func.isRequired
};

SectionLayout.defaultProps = {
  children: null,
  expanded: false,
  jumpLink: '',
  jumpLinkProps: {},
  sectionHeaderContent: null,
  sectionHeaderProps: {}
};

export default SectionLayout;
