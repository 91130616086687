import { gql } from '@apollo/client';

export const GET_EMPLOYEE_JOBS = gql`
  query employeeJobs($offset: Int, $limit: Int, $skills: String, $industry: String) {
    employeeJobs(offset: $offset, limit: $limit, skills: $skills, industry: $industry) {
      applicantAmount
      createdAt
      bestFit
      city
      countryId
      description
      endDate
      employerProfileProfileId
      employerProfile {
        name
        imageUrl
        profile_id
      }
      fitOrder
      hireDate
      id
      isSchedule
      imageUrl
      location
      payRange
      payMin
      payMax
      payCurrencyId
      payPeriodId
      questions {
        questions_id
        name
      }
      requirements
      scheduleStatusCount
      stateId
      total
      title
      updatedAt
      webUrl
      zip
    }
  }
`;

export const GET_MY_JOBS = gql`
  query myJobs($query: String) {
    myJobs(query: $query) {
      approved
      createdAt
      id
      jobs {
        active
        applicantAmount
        city
        countryId
        description
        employerProfile {
          name
          imageUrl
        }
        endDate
        hireDate
        id
        imageUrl
        isSchedule
        location
        payRange
        payMin
        payMax
        payCurrencyId
        payPeriodId
        scheduleStatusCount
        stateId
        title
        requirements
        zip
      }
      passed
      starred
      userInterviewDate
      updatedAt
    }
  }
`;

export const AUTOCOMPLETE_ALL_JOBS_SEARCH = gql`
  query allJobsSearch($query: String) {
    allJobsSearch(query: $query) {
      scheduleStatusCount
      isSchedule
      id
      title
      location
      payRange
      payMin
      payMax
      payCurrencyId
      payPeriodId
      imageUrl
      isSchedule
      hireDate
      description
      requirements
      endDate
      webUrl
      applicantAmount
      employerProfileProfileId
      employerProfile {
        name
        imageUrl
      }
      questions {
        questions_id
        name
      }
      createdAt
      updatedAt
      city
      countryId
      stateId
      zip
    }
  }
`;

export const AUTOCOMPLETE_MY_JOBS_SEARCH = gql`
  query myJobsSearch($query: String) {
    myJobsSearch(query: $query) {
      jobs {
        title
        scheduleStatusCount
        isSchedule
        payMin
        payMax
        payCurrencyId
        payPeriodId
        city
        countryId
        stateId
        zip
      }
      userInterviewDate
    }
  }
`;
