import React from 'react';
import PT from 'prop-types';
import { Badge, styled, useMediaQuery } from 'components';
import { IconButton } from 'components/shared';
import { MdMailOutline } from 'components/icons';
import { useCommonUI } from 'hooks';

const MobileButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));
const Dot = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-dot': {
    height: 14,
    minWidth: 14,
    border: '2px solid #FFF',
    borderRadius: '50%'
  }
}));

function ProfileLink(props) {
  const { userRole, isNewMessage } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { openChatModal } = useCommonUI();

  const openChat = () => openChatModal({ dashBoardType: userRole });

  return isDesktop ? (
    <IconButton
      variant="outlined"
      color="primary"
      aria-label="matches"
      className="headerElement"
      onClick={openChat}
      testID="chat-button"
    >
      <Dot invisible={!isNewMessage} variant="dot" color="primary" overlap="circular">
        <MdMailOutline />
      </Dot>
    </IconButton>
  ) : (
    <MobileButton
      color="primary"
      aria-label="matches"
      className="headerElement"
      onClick={openChat}
      testID="chat-button"
    >
      <Dot invisible={!isNewMessage} variant="dot" color="primary" overlap="circular">
        <MdMailOutline />
      </Dot>
    </MobileButton>
  );
}

ProfileLink.propTypes = {
  isNewMessage: PT.bool.isRequired,
  userRole: PT.string.isRequired
};

export default ProfileLink;
