import React, { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { matchRoutes, useLocation } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import { Box, styled } from 'components';
import {
  data as applicantsDashboardData,
  ApprovedApplicants,
  NewApplicants,
  RejectedApplicants,
  StarredApplicants,
  TabsToolbar
} from 'components/ApplicantsDashboard';
import styles from 'styles/ApplicantsDashboard';

const StyledRoot = styled('div')(styles);

const { APPLICANTS_PATH, APPROVED_PATH, REJECTED_PATH, STARRED_PATH, LIST_LAYOUT, QH_PATH, TABS } =
  applicantsDashboardData;

function ApplicantsDashboard(props) {
  const { jobId, job } = props;
  const location = useLocation();

  const matches = useMemo(
    () =>
      matchRoutes(
        TABS.map((o) => ({ path: o.path })),
        location.pathname
      ),
    [location.pathname]
  );
  const matchedRoutePath = matches?.[0]?.route?.path;

  const { webUrl } = job || {};

  const [curTab, setCurTab] = useState(0);
  const [layout, setLayout] = useState(LIST_LAYOUT);

  useEffect(() => {
    const index = findIndex(TABS, ['path', matchedRoutePath]);
    if (index !== -1 && matchedRoutePath !== TABS[curTab].path) {
      setCurTab(index);
    }
  }, [matchedRoutePath]);

  const renderList = () => {
    const commonProps = {
      jobId,
      webUrl,
      layoutType: layout
    };

    switch (matchedRoutePath) {
      case APPLICANTS_PATH: {
        return <NewApplicants {...commonProps} />;
      }
      case APPROVED_PATH: {
        return <ApprovedApplicants {...commonProps} />;
      }
      case REJECTED_PATH: {
        return <RejectedApplicants {...commonProps} />;
      }
      case STARRED_PATH: {
        return <StarredApplicants {...commonProps} />;
      }
      default:
        return null;
    }
  };

  return (
    <StyledRoot className="container">
      <div className="toolbarContainer">
        <TabsToolbar
          jobId={jobId}
          layoutType={layout}
          tab={curTab}
          setTab={setCurTab}
          setLayout={setLayout}
          webUrl={webUrl}
        />
      </div>
      {renderList()}
    </StyledRoot>
  );
}

ApplicantsDashboard.propTypes = {
  jobId: PT.number.isRequired,
  job: PT.shape({
    webUrl: PT.string
  }).isRequired
};

export default ApplicantsDashboard;
