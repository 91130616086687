import React from 'react';

const TimeZoneContext = React.createContext({
  timeZone: '',
  setTimeZone: () => {},
  postUserTimeZone: () => {},
  updateUserTimeZone: () => {}
});

export default TimeZoneContext;
