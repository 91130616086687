export default ({ theme }) => ({
  '&.container': {
    position: 'relative',
    overflowX: 'hidden',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  '& .jobsContainer': {
    position: 'relative',
    // flex: 1,
    padding: '0 30px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 'minmax(275px, 1fr)',
    gridGap: 30,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      margin: 0,
      padding: '0 16px',
      display: 'flex',
      flexFlow: 'column',
      gridGap: 0,
      alignItems: 'center',
      overflow: 'auto'
    }
  },
  '& .cardContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .card': {
      width: '100%',
      flex: 1
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      maxWidth: '100%',
      marginBottom: 10,
      padding: 0,
      display: 'block',
      '&:last-of-type': {
        marginBottom: 50
      }
    }
  },
  '& .jobsLoader': {
    width: '100%',
    // height: 72,
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255,0.7)'
  },
  '& .mobileJobCard': {
    width: '100%',
    margin: '0 auto',
    padding: '18px 12px 8px 14px',
    border: '1px solid #E5E5E5',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  '& .mobileJobCard__hiredBtn': {
    position: 'relative',
    zIndex: 10,
    marginTop: 6,
    padding: '5px 14px',
    fontSize: 9,
    borderRadius: 4
  },
  '& .mobileJobCard__photo': {
    height: 60,
    width: 60,
    backgroundColor: '#D8D8D8',
    borderRadius: 14,
    objectFit: 'cover'
  },
  '& .mobileJobCard__arrow': {
    alignSelf: 'center',
    color: theme.palette.primary.main
  },
  '& .jobsDropDownTrigger': {
    fontSize: 16,
    lineHeight: '18px',
    color: '#000'
  },
  '& .jobsDropDownPaper': {
    minWidth: 255,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      borderRadius: 5
    }
  },
  '& .jobsDropDownOptBtn': {
    width: '100%',
    height: 50,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#000',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 50,
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .draftLabel': {
    backgroundColor: '#e5e5e5',
    color: '#6f6f6f',
    borderRadius: 3,
    lineHeight: 1,
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginLeft: 10,
      fontSize: 12
    }
  },
  '& .filterInput': {
    '&.StyledInput-inputBase__input': {
      padding: '13px 21px',
      fontSize: 12
    }
  },
  '& .hirePrompt': {
    maxWidth: 294
  },
  '& .hirePrompt__content': {
    padding: '23px 20px'
  },
  '& .hirePrompt__title': {
    marginBottom: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  '& .hirePrompt__descr': {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '14px'
  },
  '& .hirePrompt__jobTitle': {
    padding: 10,
    borderRadius: 6,
    fontSize: 11,
    lineHeight: '12px',
    backgroundColor: '#ECECF5'
  },
  '& .hirePrompt__actions': {
    display: 'flex'
  },
  '& .hirePrompt__btn': {
    flex: 1,
    padding: '20px 0',
    borderTop: '1px solid #ECEBF4',
    borderRadius: 0,
    fontSize: 14,
    '&:first-child': {
      color: '#29ABE2'
    },
    '&:not(:first-child)': {
      borderLeft: '1px solid #ECEBF4'
    }
  },
  '& .newJobPrompt__btn': {
    padding: '12px 20px'
  }
});
