import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PublicLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    // userLogOut: PropTypes.func.isRequired,
    // loggedIn: PropTypes.bool.isRequired,
  };

  // componentDidMount() {
  //   const { userLogOut, loggedIn } = this.props;
  //   if (loggedIn && !isAuthenticated()) userLogOut();
  // }

  render() {
    const { children } = this.props;
    return (
      <div>
        {children}
      </div>
    );
  }
}

// const mapDispatchToProps = dispatch => ({
//   userLogOut: () => {
//     dispatch(logout());
//   },
// });

// const mapStateToProps = ({ login }) => ({
//   loggedIn: login.loggedIn,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
export default PublicLayout;
