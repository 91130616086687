import { forEach } from 'lodash';
import {
  parseISO,
  isBefore,
  isWithinInterval,
  addWeeks,
  addDays,
  subDays,
  differenceInCalendarDays
} from 'date-fns';
import { convertUTCToZonedDate, convertZonedDateToUTC } from './index';

const DEFAULT_SCHEDULE_WEEK = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };

const parseZonedISO = (isoDate, zone) =>
  zone ? convertUTCToZonedDate(isoDate, zone) : parseISO(isoDate);

// const toZonedISO = (date, zone) =>
//   zone ? convertZonedDateToUTC(date, zone).toISOString() : date.toISOString();

const formatEmployerSchedule = (schedule, sourceTZ) => {
  const startDate = parseZonedISO(schedule.startDate, sourceTZ);
  const endDate = addWeeks(startDate, schedule.length || 1);
  const selectedCriteria = {
    industry: schedule.industry || null,
    skills: schedule.skills || null,
    careerHealthScore: schedule.careerHealthScore || null,
    experience: schedule.experience || null
  };
  let scheduleSlots = schedule.schedule || {};
  if (Object.keys(scheduleSlots).length < 7) {
    scheduleSlots = { ...DEFAULT_SCHEDULE_WEEK, ...scheduleSlots };
  }
  return {
    id: schedule.id,
    startDate,
    endDate,
    length: schedule.length || 1,
    schedule: scheduleSlots,
    ...selectedCriteria
  };
};

const getClosestScheduleIdx = (formattedSchedules, date) => {
  let closestNextScheduleIdx = -1;
  let currentScheduleIdx = -1;

  forEach(formattedSchedules, ({ startDate, endDate }, i) => {
    const interval = {
      start: startDate,
      end: endDate
    };
    const isWithin = isWithinInterval(date, interval);
    if (isWithin) {
      currentScheduleIdx = i;
      closestNextScheduleIdx = formattedSchedules[i + 1] ? i + 1 : 0;
      return false;
    }
    if (closestNextScheduleIdx === -1 && isBefore(date, interval.start)) closestNextScheduleIdx = i;
  });

  return [currentScheduleIdx, closestNextScheduleIdx];
};

const getActualSchedules = (formattedSchedules, closestStartDate, currentDate) => {
  const newSchedules = [];
  let closestNextScheduleIdx = -1;
  let currentScheduleIdx = -1;

  forEach(formattedSchedules, (schedule, i) => {
    const { startDate, endDate, length, ...rest } = schedule;
    let obj;

    if (i === 0) {
      obj = {
        ...rest,
        length,
        startDate: closestStartDate,
        endDate: addWeeks(closestStartDate, length)
      };
    } else {
      const prevSchedule = newSchedules[i - 1];
      const originPrevEnd = formattedSchedules[i - 1].endDate;
      const gap = Math.floor(differenceInCalendarDays(startDate, originPrevEnd));
      const newStartDate = addDays(prevSchedule.endDate, gap);
      obj = {
        ...rest,
        length,
        startDate: newStartDate,
        endDate: addWeeks(newStartDate, length)
      };
    }

    if (currentScheduleIdx === -1) {
      const [currentIdx] = getClosestScheduleIdx([obj], currentDate);
      currentScheduleIdx = currentIdx;
      closestNextScheduleIdx =
        currentScheduleIdx !== -1 && formattedSchedules[currentScheduleIdx + 1]
          ? currentScheduleIdx + 1
          : 0;
    }

    newSchedules.push(obj);
  });

  return [newSchedules, currentScheduleIdx, closestNextScheduleIdx];
};

const checkSchedulesActuality = (formattedSchedules = [], currentDate) => {
  if (!formattedSchedules || !formattedSchedules.length) {
    return [[], -1, -1, true];
  }
  const now = currentDate || new Date();
  const scheduleStart = formattedSchedules[0].startDate;
  const scheduleEnd = formattedSchedules[formattedSchedules.length - 1].endDate;
  let schedules = [...formattedSchedules];
  let currentScheduleIdx = -1;
  let closestNextScheduleIdx = -1;
  let isSameSchedule = false;

  if (isBefore(scheduleEnd, now)) {
    // if all schedules are passed, generate actual schedule
    const totalDaysInSchedule = differenceInCalendarDays(scheduleEnd, scheduleStart);
    const totalPassedDays = differenceInCalendarDays(now, scheduleEnd);
    const totalPassedSchedules = Math.floor(totalPassedDays / totalDaysInSchedule);
    let closestStartDate;

    if (totalPassedSchedules < 1) {
      closestStartDate = scheduleEnd;
    } else {
      const diff = totalPassedDays - totalPassedSchedules * totalDaysInSchedule;
      closestStartDate = subDays(now, diff);
    }

    const [newSchedules, currentIdx, closestIdx] = getActualSchedules(
      formattedSchedules,
      closestStartDate,
      now
    );
    schedules = newSchedules;
    currentScheduleIdx = currentIdx;
    closestNextScheduleIdx = closestIdx;
    isSameSchedule = false;
  } else {
    const [currentIdx, closestIdx] = getClosestScheduleIdx(formattedSchedules, now);
    currentScheduleIdx = currentIdx;
    closestNextScheduleIdx = closestIdx;
    isSameSchedule = true;
  }

  return [schedules, currentScheduleIdx, closestNextScheduleIdx, isSameSchedule];
};

export {
  DEFAULT_SCHEDULE_WEEK,
  formatEmployerSchedule,
  checkSchedulesActuality,
  getClosestScheduleIdx
};
