import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import forEach from 'lodash/forEach';
import { useMediaQuery } from 'components';
import { MdCareerDevInsights, MdClose, MdHelp } from 'components/icons';
import { Button, IconButton } from 'components/shared';
import { EMPLOYEE_TUTORIAL_URL, getRoutes } from 'utils';
import 'react-circular-progressbar/dist/styles.css';
import CareerHealthScoreDetailRow from '../shared/CareerHealthScoreDetailRow';
import ScoreProgress from '../shared/ScoreProgress';

const windowHeight = window.innerHeight;

const ROUTES = getRoutes();

function CareerHealthScore({ careerDev }) {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { careerHealthScore } = careerDev;
  const [sectionToExpand, setSectionToExpand] = useState(null);
  const [careerHealthDetailsModalIsOpen, setCareerHealthDetailsModalIsOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const openVideo = () => setIsVideoOpen(true);
  const closeVideo = () => setIsVideoOpen(false);

  const expandSection = (section) => {
    if (sectionToExpand === section) {
      setSectionToExpand(null);
    } else {
      setSectionToExpand(section);
    }
  };

  const getSectionLink = (section) => {
    switch (section.toLowerCase().replace(/ /g, '')) {
      // TODO need to make these active
      // case 'profile':
      //   return { text: 'Update your profile', action: () => console.log('update profile') };
      // case 'education':
      //   return { text: 'Take Some Courses', action: () => console.log('take some courses') };
      case 'jobhistory':
        return { text: 'Apply for Jobs', action: ROUTES.employee.dashboard };
      default:
        return null;
    }
  };

  const careerHealthDetails = (
    <div className="careerHealthScoreDetailsContainer">
      <h2 className="careerHealthScoreSubTitle">Impact Items</h2>
      {careerHealthScore.sectionScores.map((section) => (
        <CareerHealthScoreDetailRow
          key={section.careerScoreType.name}
          link={getSectionLink(section.careerScoreType.name)}
          section={section}
          sectionToExpand={sectionToExpand}
          expandSection={expandSection}
        />
      ))}
      {isDesktop && (
        <>
          <h2 className="careerHealthScoreSubTitle">Need assistance?</h2>
          <iframe
            title="grow tutorial"
            width="100%"
            height="100%"
            src={EMPLOYEE_TUTORIAL_URL}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </>
      )}
    </div>
  );

  const healthDetailData = () => {
    if (isDesktop) return careerHealthDetails;

    if (careerHealthDetailsModalIsOpen)
      return (
        <Spring
          from={{ top: windowHeight }}
          to={{ top: 20 }}
          // TODO look into this
          leave={{ top: windowHeight }}
          config={{
            duration: 500,
            easing: easePolyOut.exponent(2.0)
          }}
        >
          {(animStyles) => (
            <animated.div style={animStyles} className="modalContainer">
              {careerHealthDetails}
              <Button
                className="modalCloseButton"
                variant="filled-primary"
                onClick={() => setCareerHealthDetailsModalIsOpen(false)}
                testID="qh-grow-health-details-close-button"
              >
                Close
              </Button>
            </animated.div>
          )}
        </Spring>
      );
    return null;
  };

  const renderMobileVideoModal = () => (
    <div className="mobileVideoModal">
      <IconButton className="mobileVideoModalCloseBtn" onClick={closeVideo}>
        <MdClose style={{ color: '#FFF', fontSize: 30 }} />
      </IconButton>
      <div className="mobileVideoContainer">
        <iframe
          title="grow tutorial"
          className="mobileVideoIframe"
          src={EMPLOYEE_TUTORIAL_URL}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );

  return (
    <div className="careerHealthContainer">
      <div className="careerHealthScoreContainer">
        {!isDesktop && (
          <IconButton variant="filled-primary" className="tutorialTrigger" onClick={openVideo}>
            <MdHelp style={{ fontSize: 30, color: '#8782BE' }} />
          </IconButton>
        )}
        <div className="careerHealthScoreProgress">
          <ScoreProgress score={careerHealthScore.score} />
        </div>
        <div className="careerHealthScoreDetails">
          <h1 className="careerHealthScoreTitle">
            <Spring
              from={{ score: 0 }}
              to={{ score: careerHealthScore.score }}
              config={{
                duration: 1250,
                easing: easePolyOut.exponent(2.0)
              }}
            >
              {({ score }) => <animated.span>{score.to((v) => v.toFixed()) || 0}</animated.span>}
            </Spring>
            <MdCareerDevInsights className="careerHealthScoreIcon" />
          </h1>
          <p className="careerHealthScoreLabel">Career Health Score</p>
          <div className="careerHealthScoreLevel">{careerHealthScore.rating.name}</div>
        </div>
        {!isDesktop && (
          <Button
            className="careerHealthScoreButton"
            variant="filled-primary"
            onClick={() => setCareerHealthDetailsModalIsOpen(true)}
            testID="qh-grow-see-stats-button"
          >
            See Your Stats
          </Button>
        )}
      </div>
      {healthDetailData()}
      {isVideoOpen && renderMobileVideoModal()}
    </div>
  );
}

CareerHealthScore.propTypes = {
  careerDev: PropTypes.objectOf(PropTypes.any)
};

CareerHealthScore.defaultProps = {
  careerDev: null
};

export default CareerHealthScore;
