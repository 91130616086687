import { gql } from '@apollo/client';

export const GET_EMPLOYER_JOBS = gql`
  query employerJobs($active: Boolean) {
    employerJobs(active: $active) {
      active
      applied
      applicantAmount
      city
      countryId
      createdAt
      description
      endDate
      employerProfileProfileId
      hireDate
      id
      imageUrl
      location
      payRange
      payMin
      payMax
      payCurrencyId
      payPeriodId
      stateId
      skills {
        name
        skill_id
      }
      qmCount
      requirements
      title
      updatedAt
      draft
      zip
    }
  }
`;
