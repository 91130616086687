import React, { memo, useState } from 'react';
import PT from 'prop-types';
import { useQuery } from '@apollo/client';
import format from 'date-fns/format';
import map from 'lodash/map';
import find from 'lodash/find';
import { qaAttr } from 'utils';
import { Box, Popover, styled, useMediaQuery } from 'components';
import { Button, ButtonBase, IconButton } from 'components/shared';
import {
  MdClose,
  MdChevronRight,
  MdIosShare,
  MdMoreVert,
  MdStarBorder,
  MdStar
} from 'components/icons';
import { ShareJob } from 'components/Job';
import { GET_STATES, GET_COUNTRIES } from 'api';
import styles from 'styles/Dashboard/EmployeeDashboard';
import defaultImg from 'assets/img/job_default.png';
import navIcon from 'assets/img/navigation_icon.png';

function MyJobs(props) {
  const {
    closeJobOptions,
    handleJobSelect,
    handleRetract,
    handleStar,
    myJobs,
    myJobOptionsPopup,
    openJobOptions,
    tableLayout
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });

  const { data: countriesData } = useQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });
  const countries = countriesData?.getAllCountries || [];

  const { data: statesData } = useQuery(GET_STATES, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });
  const states = statesData?.getAllStates || [];

  const getJobAddress = ({ city, countryId, stateId, zip }) => {
    if (!countryId || !stateId) return '';
    const selectedCountry = find(countries, ['id', Number(countryId)]) || {};
    const selectedState = find(states, ['id', Number(stateId)]) || {};
    return city && selectedCountry.name && selectedState.code && zip
      ? `${city}, ${selectedState.code}, ${zip}, ${selectedCountry.name}`
      : '';
  };

  const renderGridCard = (data, i) => {
    const { approved, jobs, starred, userInterviewDate, updatedAt } = data;
    const { employerProfile, id, imageUrl, payRange, title } = jobs || {};
    const { imageUrl: employerImageUrl, name: employerName } = employerProfile || {};
    const jobAddress = getJobAddress(jobs);

    return (
      <div className="gridColumn" key={`gridCol__${i}`}>
        <div className="card">
          <ShareJob jobId={id} jobTitle={title}>
            {(share) => (
              <IconButton color="primary" className="shareButton" onClick={share}>
                <MdIosShare fontSize="inherit" />
              </IconButton>
            )}
          </ShareJob>

          <ButtonBase
            className="card__btn"
            onClick={() =>
              handleJobSelect(id, starred, {
                userInterviewDate,
                approved
              })
            }
            {...qaAttr(`job-${title}`)}
          >
            <div
              className="card__photoArea"
              style={{ backgroundImage: `url("${imageUrl || defaultImg}")` }}
            />
            <Box p="22px 18px">
              <h2 className="card__name">{title}</h2>
              <Box mb="12px" className="card__jobStats">
                <Box textAlign="left">
                  <span>{`Applied ${format(new Date(updatedAt), 'MM.dd')}`}</span>
                  <img className="navIcon" src={navIcon} alt="" />
                  <span>{`0 mi - ${jobAddress}`}</span> {/* @todo: add job distance */}
                </Box>
              </Box>
              <Box mb="22px" textAlign="left" className="card__jobStats">
                {payRange || ''}
              </Box>
              {/*<Box mb="18px" className={classes.card__jobDescription}>{description}</Box>*/}
              <div className="card__company">
                <div
                  className="card__companyLogo"
                  style={{ backgroundImage: `url("${employerImageUrl}")` }}
                />
                <Box ml="16px" className="card__companyName">
                  {employerName}
                </Box>
              </div>
            </Box>
          </ButtonBase>
          <div className="card__actions">
            <div>
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="retract"
                withTooltip
                toolTipProps={{ title: 'Retract application' }}
                sx={{ padding: '7px' }}
                onClick={() => handleRetract(jobs, false, !!userInterviewDate)}
                {...qaAttr('job-retract-button')}
              >
                <MdClose />
              </IconButton>
              <IconButton
                variant="outlined"
                color="primary"
                aria-label={starred ? 'unstar' : 'star'}
                withTooltip
                toolTipProps={{ title: starred ? 'Unstar' : 'Star' }}
                sx={{ padding: '7px', ml: '16px' }}
                onClick={() => handleStar(jobs, !starred)}
                {...qaAttr('job-star-button')}
              >
                <MdStarBorder />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopListItem = (data, i) => {
    const { approved, jobs, starred, userInterviewDate, updatedAt } = data;
    const { employerProfile, id, imageUrl, payRange, title } = jobs || {};
    const { imageUrl: employerImageUrl, name: employerName } = employerProfile || {};
    const jobAddress = getJobAddress(jobs);

    return (
      <>
        <Button
          sx={{
            padding: '9px 0',
            width: '80%',
            flex: 1,
            justifyContent: 'flex-start',
            fontWeight: 'normal'
          }}
          onClick={() =>
            handleJobSelect(id, starred, {
              userInterviewDate,
              approved
            })
          }
          {...qaAttr(`job-${title}`)}
        >
          <div className="listItem__data">
            <div
              style={{ backgroundImage: `url("${imageUrl || defaultImg}")` }}
              className="listItem__avatar"
            >
              {starred && (
                <div className="avatarMarkerIcon">
                  <MdStar fontSize="inherit" color="inherit" />
                </div>
              )}
            </div>
          </div>
          <div className="listItem__data textMain ellipsisText">{title || ''}</div>
          <Box maxWidth={168} className="listItem__data textSecondary ellipsisText">{`@${
            employerName || ''
          }`}</Box>
          <div className="listItem__data textSecondary">0 mi</div> {/* @todo: add job distance */}
          <Box maxWidth={168} className="listItem__data textSecondary ellipsisText">
            {jobAddress}
          </Box>
          <div className="listItem__data textSecondary">{payRange || ''}</div>
        </Button>
        <Box display="flex" alignItems="center" className="listItem__data listItem_withActions">
          <div className="textSecondary">
            {updatedAt ? `Applied ${format(new Date(updatedAt), 'MMM dd')}` : ''}
          </div>
          <Box ml="16px" className="listItem__actions">
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="job options"
              sx={{ padding: '7px' }}
              onClick={openJobOptions(i)}
              {...qaAttr('job-options-button')}
            >
              <MdMoreVert />
            </IconButton>
            <Popover
              open={myJobOptionsPopup.index === i && !!myJobOptionsPopup.anchorEl}
              anchorEl={myJobOptionsPopup.anchorEl}
              classes={{ paper: 'optionsPopupPaper' }}
              anchorOrigin={{
                vertical: 50,
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              disablePortal
              onClose={closeJobOptions}
            >
              <ButtonBase
                className="optionsPopupBtn"
                onClick={() => {
                  closeJobOptions();
                  handleRetract(jobs, false, !!userInterviewDate);
                }}
                {...qaAttr('job-retract-button')}
              >
                Retract Application
              </ButtonBase>
              <ButtonBase
                className="optionsPopupBtn"
                onClick={() => {
                  closeJobOptions();
                  handleStar(jobs, !starred);
                }}
                {...qaAttr('job-star-button')}
              >
                {starred ? 'Unstar' : 'Star'}
              </ButtonBase>
            </Popover>
          </Box>
        </Box>
      </>
    );
  };

  const renderMobileListItem = (data, i) => {
    const { approved, jobs, starred, userInterviewDate, updatedAt } = data;
    const { employerProfile, id, imageUrl, payRange, title } = jobs || {};
    const { imageUrl: employerImageUrl, name: employerName } = employerProfile || {};
    const jobAddress = getJobAddress(jobs);

    return (
      <Button
        className="mobileListItem__button"
        onClick={() =>
          handleJobSelect(id, starred, {
            userInterviewDate,
            approved
          })
        }
        {...qaAttr(`job-${title}`)}
      >
        <div
          style={{ backgroundImage: `url("${imageUrl || defaultImg}")` }}
          className="listItem__avatar"
        >
          {starred && (
            <div className="avatarMarkerIcon">
              <MdStar fontSize="inherit" color="inherit" />
            </div>
          )}
        </div>
        <div className="mobileListItem__infoContainer">
          <Box width="100%" mb="8px" display="flex" justifyContent="space-between">
            <div className="mobileListItem__title textMain ellipsisText">{title || ''}</div>
            <div className="textSecondary" style={{ minWidth: 58 }}>
              {updatedAt ? format(new Date(updatedAt), 'MMM dd') : ''}
            </div>
          </Box>
          <div className="textSecondary mobileListItem__employer">{`@${employerName || ''}`}</div>
          <div className="textSecondary mobileListItem__jobInfo">
            0 mi {/* @todo: add job distance */} &bull; <span>{jobAddress}</span> &bull;{' '}
            <span>{payRange || ''}</span>
          </div>
        </div>
        <MdChevronRight className="mobileListItem__icon" />
      </Button>
    );
  };

  return (
    <div className={`${tableLayout === 'grid' ? 'gridContainer' : ''} myJobsContainer`}>
      {map(myJobs, (o, i) =>
        tableLayout === 'grid' ? (
          renderGridCard(o, i)
        ) : (
          <div className="listItem" key={`listRow__${i}`}>
            {isDesktop ? renderDesktopListItem(o, i) : renderMobileListItem(o, i)}
          </div>
        )
      )}
    </div>
  );
}

MyJobs.propTypes = {
  closeJobOptions: PT.func.isRequired,
  handleJobSelect: PT.func.isRequired,
  handleRetract: PT.func.isRequired,
  handleStar: PT.func.isRequired,
  myJobs: PT.arrayOf(PT.any).isRequired,
  myJobOptionsPopup: PT.objectOf(PT.any).isRequired,
  openJobOptions: PT.func.isRequired,
  tableLayout: PT.string.isRequired
};

const StyledMyJobs = styled(MyJobs)(styles);

export default memo(StyledMyJobs);
