export default ({ theme }) => ({
  '&.searchDashboardContainer': {
    width: '100%',
    paddingLeft: 30,
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: 0,
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden'
    }
  },
  '& .searchFormWrapper': {
    width: '100%',
    maxWidth: 301,
    marginRight: -5,
    paddingRight: 5,
    overflow: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: '100%',
      margin: 0,
      padding: 0
    }
  },
  '& .searchFormContainer': {
    padding: 16,
    alignSelf: 'flex-start',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: 16,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      maxWidth: '100%',
      marginBottom: 16,
      padding: 0,
      border: 'none'
    }
  },
  '& .searchForm': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .mobileSearchContainer': {
    width: '100%',
    padding: '0 16px',
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'block'
    }
  },
  '& .locationAutoSuggest': {
    marginBottom: 29,
    '& .autosuggest__suggestion': {
      borderRadius: '0 0 10px 10px'
    },
    '& .autosuggest__firstSuggestion': {
      borderRadius: 0
    }
  },
  '& .filtersContainer': {
    display: 'none',
    paddingBottom: 6,
    overflowX: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  '& .filtersContainer__gradient': {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    width: 40,
    backgroundImage: 'linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 42.33%, rgba(255,255,255,0) 100%)'
  },
  '& .searchResultContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      overflow: 'hidden',
      flex: 1,
      marginLeft: 0,
      padding: 0
    }
  },
  '& .candidatesCount': {
    marginBottom: 24,
    paddingLeft: 25,
    paddingRight: 30,
    lineHeight: '24px',
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 8,
      padding: '0 16px',
      lineHeight: '24px',
      fontSize: 14
    }
  },
  '& .candidatesContainer': {
    position: 'relative',
    flex: 1,
    paddingLeft: 25,
    paddingRight: 30,
    overflow: 'auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 16px 60px',
      overflow: 'auto'
    }
  },
  '& .candidatesSpinner': {
    width: '100%',
    position: 'absolute',
    inset: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .textMain': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .textSecondary': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    opacity: 0.4
  },
  '& .tableRow': {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    '&:hover': {
      '& .tableCell__actions': {
        opacity: 1,
        pointerEvents: 'initial'
      }
    }
  },
  '& .tableRowOpen': {
    display: 'flex',
    alignItems: 'center',
    '& .tableCell__actions': {
      opacity: 1,
      pointerEvents: 'initial'
    }
  },
  '& .tableRowContainer': {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    cursor: 'pointer',
    paddingRight: 25,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '12px 0 14px'
    }
  },
  '& .tableCell': {
    padding: '9px 16px 9px 0',
    '&:last-of-type': {
      marginLeft: 'auto',
      marginRight: 0,
      paddingRight: 0
    }
  },
  '& .tableCell__actions': {
    opacity: 0,
    pointerEvents: 'none'
  },
  '& .tableAvatar': {
    position: 'relative',
    display: 'block',
    minWidth: 38,
    height: 38,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '50%',
    backgroundColor: '#F6F5FA',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minWidth: 76,
      height: 76
    }
  },
  '& .mottoExcerpt': {
    maxWidth: 290,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  '& .vacancyPopupPaper': {
    minWidth: 253,
    maxWidth: 263,
    overflow: 'hidden'
  },
  '& .vacancyPopupBody': {
    maxHeight: 168,
    overflow: 'auto'
  },
  '& .vacancyPopupBtn': {
    width: '100%',
    height: 55,
    padding: '9px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:not(:last-of-type)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
  },
  '& .vacancyImg': {
    minWidth: 38,
    height: 38,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 3,
    backgroundColor: '#F6F5FA'
  },
  '& .vacancyName': {
    textAlign: 'left',
    marginLeft: 13,
    lineHeight: '15px',
    fontSize: 14,
    fontWeight: 'bold'
  },
  '& .invitedMessage': {
    maxWidth: 240,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '17px',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  '& .dialogPaper': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      // minHeight: 'calc(100% - 32px)',
      maxHeight: 'calc(100% - 32px)',
      width: 'calc(100% - 32px)',
      margin: 16
    }
  },
  '& .dialogHeader': {
    padding: '13px 16px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '5px 16px'
    }
  },
  '& .dialogContent': {
    flex: 1,
    overflow: 'hidden',
    padding: '0 16px 13px',
    display: 'flex',
    flexDirection: 'column'
  },
  '& .dialogTitle': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0
  },
  '& .headerAction_start': {
    flex: '0 0 44px'
  },
  '& .headerAction_end': {
    flex: '0 0 44px'
  },
  '& .ellipsisText': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '& .employeeDetails_container': {
    display: 'grid',
    borderBottom: '3px solid rgba(0, 0, 0, .1)'
  },
  '& .employeeDetails_header': {
    fontSize: 10,
    color: '#999999',
    textTransform: 'uppercase',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    paddingBottom: 5,
    marginBottom: 10,
    letterSpacing: '.5px'
  },
  '& .employeeDetails_content': { fontSize: 11, paddingBottom: '1rem' },
  '& .empList': {
    listStyle: 'none'
  },
  '& .employeeDetails_listHeader': {
    fontSize: 12,
    fontWeight: 800
  },
  '& .employeeDetails_listDate': {
    color: '#676767',
    fontSize: 10
  },
  '& .employeeDetails_listItem': {
    fontSize: 12,
    paddingBottom: 2.5
  },
  '& .employeeDetails_notAvailable': {
    color: '#818181'
  }
});
