import { get } from 'lodash';
import { client } from 'api/graphql';

export const makeQueryFetch = async ({ query, variables, path }, clientParam) => {
  const token = localStorage.getItem('token');
  if (token) {
    const queryObject = {
      query,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    };
    if (variables) {
      queryObject.variables = variables;
    }
    const queryResp = await (clientParam || client).query(queryObject);
    return get(queryResp, `data.${path}`, null);
  }
};

export const makeMutation = async ({ mutation, variables, path }) => {
  const token = localStorage.getItem('token');
  if (token) {
    const mutationResp = await client.mutate({
      mutation,
      variables,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    return get(mutationResp, `data.${path}`, null);
  }
};
