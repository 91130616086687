import React from 'react';
import PT from 'prop-types';
import { qaAttr } from 'utils';
import { MuiDatePicker } from 'components';
import Input from '../Input';

function DatePicker(props) {
  const {
    disableOpenPicker,
    error,
    // emptyInputText,
    InputComponentProps,
    onChange,
    OpenPickerButtonProps,
    ...rest
  } = props;
  const triggerTestProps = InputComponentProps?.id
    ? { ...qaAttr(`${InputComponentProps.id}-picker-button`) }
    : {};

  return (
    <MuiDatePicker
      inputFormat="MM/dd/yyyy"
      onChange={onChange}
      disableOpenPicker={disableOpenPicker}
      // emptyInputText={emptyInputText}
      OpenPickerButtonProps={{ color: 'primary', ...triggerTestProps, ...OpenPickerButtonProps }}
      renderInput={(params) => (
        <Input
          variant="textfield"
          withHelperText
          {...params}
          error={error}
          {...InputComponentProps}
        />
      )}
      {...rest}
    />
  );
}

DatePicker.propTypes = {
  disableOpenPicker: PT.bool,
  error: PT.string,
  // emptyInputText: PT.string,
  InputComponentProps: PT.objectOf(PT.any),
  OpenPickerButtonProps: PT.objectOf(PT.any),
  onChange: PT.func.isRequired
};

DatePicker.defaultProps = {
  disableOpenPicker: false,
  // emptyInputText: '',
  error: '',
  InputComponentProps: {},
  OpenPickerButtonProps: {}
};

export default DatePicker;
