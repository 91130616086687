import React from 'react';
import { SvgIcon } from 'components';

function MdChevronLeft(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
    </SvgIcon>
  );
}

export default MdChevronLeft;
