export default ({ theme }) => ({
  '&.card': {
    minHeight: 275,
    width: '100%',
    padding: 0,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'block',
    borderRadius: '16px !important',
    transition: 'box-shadow 300ms linear',
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.16)'
    }
  },
  '& .card__photoArea': {
    position: 'relative',
    height: 171,
    width: '100%',
    objectFit: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '16px 16px 0 0'
  },
  '& .card__name': {
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    wordBreak: 'break-word',
    textAlign: 'left'
  },
  '& .card__stats': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0
  },
  '& .draftLabel': {
    backgroundColor: '#e5e5e5',
    color: '#6f6f6f',
    borderRadius: 3,
    lineHeight: 1,
    padding: '5px 10px'
  }
});
