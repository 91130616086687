import React, { useContext, useState } from 'react';
import { Input } from 'components/shared';
import PropTypes from 'prop-types';
import EmployerGrowContext from '../../EmployerGrowContext';

function ChartTitle({ titleValue, label = '', handleOnBlur, onClick = null, ...rest }) {
  const [inputValue, setInputValue] = useState(titleValue);
  const { isEditable } = useContext(EmployerGrowContext);
  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Input
      disabled={!isEditable}
      variant="textfield"
      label={label}
      value={inputValue}
      onChange={handleOnChange}
      onBlur={() => handleOnBlur(inputValue)}
      onClick={onClick}
      {...rest}
    />
  );
}

ChartTitle.propTypes = {
  titleValue: PropTypes.string,
  label: PropTypes.string,
  handleOnBlur: PropTypes.func,
  onClick: PropTypes.func
};

ChartTitle.defaultProps = {
  titleValue: '',
  label: '',
  handleOnBlur: () => {},
  onClick: () => {}
};

export default ChartTitle;
