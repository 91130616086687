import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { track } from 'utils/segmentAnalytics';
import { Box, FormControl, FormHelperText, styled } from 'components';
import CheckBox from './CheckBox';

const PREFIX = 'StyledCheckboxGroup';
const classes = {
  checkboxesContainer: `${PREFIX}-checkboxesContainer`,
  helperText: `${PREFIX}-helperText`
};

function CheckBoxGroup(props) {
  const {
    analyticParams,
    children,
    className,
    checkboxesContainerProps,
    data,
    disabled,
    CheckBoxProps,
    error,
    HelperTextProps,
    helperText,
    onChange,
    onFocus,
    withHelperText,
    ...rest
  } = props;
  const isError = !!error;

  const handleChange = (e, optionData) => {
    if (onChange) onChange(optionData, e.target.checked);
  };

  const handleFocus = (e) => {
    if (analyticParams && analyticParams.trigger === 'focus' && analyticParams.key) {
      track(analyticParams.key, analyticParams?.params);
    }
    if (onFocus) onFocus(e);
  };

  return (
    <FormControl className={className} error={isError} {...rest}>
      <Box className={classes.checkboxesContainer} {...checkboxesContainerProps}>
        {data && data.length > 0
          ? map(data, (o, i) => (
              <CheckBox
                key={`checkbox__${i}`}
                checked={o.checked}
                disabled={disabled}
                label={o.label || ''}
                value={o.value || ''}
                onChange={(e) => handleChange(e, o)}
                checkboxProps={{
                  inputProps: {
                    onFocus: handleFocus
                  }
                }}
                testID={o.value}
                {...CheckBoxProps}
              />
            ))
          : children}
      </Box>
      {withHelperText && (helperText || error) && (
        <FormHelperText error={isError} className={classes.helperText} {...HelperTextProps}>
          {helperText || error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CheckBoxGroup.propTypes = {
  analyticParams: PropTypes.shape({
    trigger: PropTypes.oneOf(['focus']),
    key: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any)
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  checkboxesContainerProps: PropTypes.objectOf(PropTypes.any),
  CheckBoxProps: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool
    })
  ),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  HelperTextProps: PropTypes.objectOf(PropTypes.any),
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  withHelperText: PropTypes.bool
};

CheckBoxGroup.defaultProps = {
  analyticParams: { key: '', trigger: 'focus' },
  children: null,
  className: '',
  checkboxesContainerProps: {},
  CheckBoxProps: {},
  data: [],
  disabled: false,
  error: '',
  HelperTextProps: {},
  helperText: '',
  onChange: undefined,
  onFocus: undefined,
  withHelperText: false
};

const StyledCheckBoxGroup = styled(CheckBoxGroup)(({ theme }) => ({
  [`& .${classes.checkboxesContainer}`]: {
    width: '100%',
    borderRadius: 10,
    border: '1px solid rgba(0,0,0,0.1)',
    '& .MuiFormControlLabel-root': {
      height: 50,
      padding: '0 16px',
      border: 'none',
      borderRadius: 0
    },
    '& .MuiFormControlLabel-root:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    '& .MuiFormControlLabel-root:last-child': {
      borderBottom: 'none'
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold'
    },
    '& .MuiCheckbox-root': {
      border: 'none'
    }
  },
  [`& .${classes.helperText}`]: {
    marginTop: 6,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '17px'
  }
}));

export default StyledCheckBoxGroup;
