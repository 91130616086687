import React from 'react';
import { SvgIcon } from 'components';

function CalendarIcon(props) {
  return (
    <SvgIcon viewBox="0 0 18.22 20.27" {...props}>
      <path fill="currentColor" d="M647.69,710.36h1c.93.42,1,1.24,1,2.12h1.53v-.61a1.56,1.56,0,0,1,.43-1.1,4.39,4.39,0,0,1,.53-.41h1.08a1.84,1.84,0,0,1,.94,2.12l.86,0a2.34,2.34,0,0,1,2.15,2.24c0,.77,0,1.54,0,2.31,0,2.2,0,4.4,0,6.6v4.65a2.4,2.4,0,0,1-.58,1.57,3,3,0,0,1-1.11.75H640.71l-.18-.09a2.35,2.35,0,0,1-1.5-2.09c0-.84,0-1.67,0-2.51v-5.4c0-1.93,0-3.86,0-5.78a2.36,2.36,0,0,1,2.09-2.25c.34,0,.7,0,1.06,0V712a1.83,1.83,0,0,1,.25-1,2,2,0,0,1,.71-.62h1.08a1.69,1.69,0,0,1,1,1.78c0,.11,0,.22,0,.34h1.55v-.3a5.07,5.07,0,0,1,0-.68A1.58,1.58,0,0,1,647.69,710.36ZM640.43,716v.19q0,6,0,12a1,1,0,0,0,1.13,1.12c.8,0,1.61,0,2.41,0,3.57,0,7.14,0,10.72,0a1.09,1.09,0,0,0,1.16-1.16c0-3.73,0-7.47,0-11.21v-1Zm3.91-2.78v-1.38a.65.65,0,0,0-.94-.64.72.72,0,0,0-.37.7c0,.52,0,1.05,0,1.57v1.06a.71.71,0,0,0,.67.74.69.69,0,0,0,.65-.76Zm3.23,0h0c0,.47,0,.94,0,1.41a.66.66,0,0,0,.62.68.65.65,0,0,0,.66-.61,7,7,0,0,0,0-.79c0-.7,0-1.39,0-2.09a.63.63,0,0,0-.66-.65.66.66,0,0,0-.62.71Zm5.83,0h0c0-.46,0-.92,0-1.38a.69.69,0,0,0-.46-.66.58.58,0,0,0-.68.22,1.21,1.21,0,0,0-.16.52c0,.43,0,.85,0,1.28s0,1,0,1.49a.64.64,0,0,0,.68.6.7.7,0,0,0,.61-.72Z" transform="translate(-639.02 -710.36)"/>
      <path fill="currentColor" d="M650.05,725.7h-1.74v-1.52h-2.86a6.59,6.59,0,0,0,0-.77,2.16,2.16,0,0,1,.34-1.33c.74-1.32,1.45-2.66,2.16-4a.25.25,0,0,1,.26-.15c.6,0,1.21,0,1.84,0v4.78h.74v1.46h-.74Zm-1.75-3V720L647,722.71Z" transform="translate(-639.02 -710.36)"/>
    </SvgIcon>
  );
}

export default CalendarIcon;
