import React, { useEffect, useState, useRef } from 'react';
import { Spring, animated } from 'react-spring';
const windowWidth = window.innerWidth;

const Toast = (props) => {
  const {
    isOpen,
    position = { top: true, right: true },
    timeToShow = 5000,
    setToast,
    title,
    icon,
    flourishText
  } = props;
  const [doFadeOut, setDoFadeOut] = useState(false);
  const presenceTimer = useRef();
  const unmountTimer = useRef();

  useEffect(() => {
    clearTimers();

    if (isOpen) {
      startTimer();
      return () => clearTimers();
    } else {
      if (doFadeOut) setDoFadeOut(false);
    }
  }, [isOpen]);

  const startTimer = () => {
    presenceTimer.current = setTimeout(() => {
      setDoFadeOut(true);
      unmountTimer.current = setTimeout(() => {
        setToast({ isOpen: false });
        setDoFadeOut(false);
      }, 1500);
    }, timeToShow);
  };

  const clearTimers = () => {
    if (presenceTimer.current) {
      clearTimeout(presenceTimer.current);
      presenceTimer.current = null;
    }
    if (unmountTimer.current) {
      clearTimeout(unmountTimer.current);
      unmountTimer.current = null;
    }
  };

  if (!isOpen) {
    return null;
  }

  const onClose = () => setToast({ isOpen: false });

  const getPosXStart = () => {
    if (isOpen) return -320;
    return position.right ? -20 : 20;
  };

  const getPosXEnd = () => {
    if (isOpen) return 20;
    return position.right ? 320 : -320;
  };

  return (
    <Spring
      from={{ posX: doFadeOut ? getPosXEnd() : getPosXStart() }}
      to={{ posX: doFadeOut ? getPosXStart() : getPosXEnd() }}
    >
      {(styles) => (
        <animated.div
          style={{
            position: 'fixed',
            top: position.top ? 85 : 'auto',
            right: position.right ? styles.posX : 'auto',
            bottom: !position.top ? 110 : 'auto',
            left: !position.right ? styles.posX : 'auto',
            padding: 10,
            borderRadius: 10,
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.40)',
            background: 'white',
            border: '3px solid #4743A2',
            zIndex: 101,
            maxWidth: 300,
            minWidth: 200,
            minHeight: 65,
            display: 'grid',
            alignItems: 'center'
          }}
        >
          <p
            style={{
              position: 'absolute',
              top: -25,
              left: 10,
              color: '#4743A2',
              fontSize: 12
            }}
          >
            {flourishText}
          </p>
          <button
            style={{
              position: 'absolute',
              top: -7,
              right: -7,
              backgroundColor: '#4743A2',
              color: 'white',
              borderRadius: 50,
              lineHeight: 0,
              height: 16,
              width: 16,
              padding: 0,
              border: 0
            }}
            onClick={onClose}
          >
            x
          </button>
          <div
            style={{
              color: '#58595b',
              fontWeight: 'bold',
              display: 'grid',
              gridTemplateColumns: '25px auto',
              gap: 15,
              alignItems: 'center'
            }}
          >
            <div
              style={{
                backgroundColor: '#4743A2',
                color: 'white',
                borderRadius: 50,
                height: 24,
                width: 24
              }}
            >
              {icon && icon()}
            </div>
            <p>{title}</p>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

export default Toast;
