import React, { useContext } from 'react';
import EmployeeMood from 'components/Grow/employer/charts/EmployeeMood';
import CompanyBreakdown from 'components/Grow/employer/charts/CompanyBreakdown';
import EmployeeTenure from 'components/Grow/employer/charts/EmployeeTenure';
import JobPostingScores from 'components/Grow/employer/charts/JobPostingScores';
import JobStatistics from 'components/Grow/employer/charts/JobStatistics';
import HiringRate from 'components/Grow/employer/charts/HiringRate';
import ChurnRate from 'components/Grow/employer/charts/ChurnRate';
import Competition from 'components/Grow/employer/charts/Competition';
// import EquityScore from 'components/Grow/employer/charts/EquityScore';
import EmployerGrowContext from '../../EmployerGrowContext';

function CompanyCharts() {
  const {
    showJobsAnalytics,
    jobScores,
    employeeSatisfaction,
    companyBreakdown,
    jobStats,
    avgTen,
    hiringRate,
    equityScore,
    competitionBreakdown,
    employeeChurnRate
  } = useContext(EmployerGrowContext);
  return (
    <div className="chartsContainer">
      {showJobsAnalytics && jobScores && <JobPostingScores />}
      {showJobsAnalytics && jobStats && <JobStatistics />}

      {employeeSatisfaction && <EmployeeMood />}
      {companyBreakdown && <CompanyBreakdown />}
      {avgTen && <EmployeeTenure />}
      {hiringRate && <HiringRate />}
      {/* {equityScore && <EquityScore />} */}
      {competitionBreakdown && <Competition />}
      {employeeChurnRate && <ChurnRate />}
    </div>
  );
}

export default CompanyCharts;
