import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { unformatPhone, getProfileId } from 'utils';
import { Box, Dialog, styled } from 'components';
import { Button, Input, PhoneInput, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { SEND_JOB_INVITE } from 'api';

const TYPES = {
  TEXT: 'text',
  EMAIL: 'email'
};

const getMessage = (name, jobUrl) =>
  `Hi ${name},I think you’d be a great fit for this job. Apply on QuickHire! %0D%0A ${jobUrl}`;

function ShareJobModal(props) {
  const { className, isOpen, onClose, jobTitle, jobId, jobUrl } = props;

  const [type, setType] = useState(TYPES.EMAIL);
  const isEmail = type === TYPES.EMAIL;

  const [sendJobInvite] = useMutation(SEND_JOB_INVITE);

  const { $, set, reset, withValidation, useConfig } = useForm({
    initial: {
      name: '',
      email: '',
      phone: ''
    },
    validations: {
      name: [{ presence: { allowEmpty: false } }],
      email: undefined,
      phone: undefined
    }
  });

  useConfig(() => {
    if (type === 'text') {
      return {
        validations: {
          email: undefined,
          phone: ['presence', 'phone']
        }
      };
    }
    return {
      validations: {
        email: [{ presence: { allowEmpty: false } }, 'email'],
        phone: undefined
      }
    };
  }, [type]);

  useEffect(() => {
    if (isOpen) reset();
  }, [isOpen]);

  const submit = withValidation(({ email, name, phone }) => {
    if (isEmail) {
      window.open(`mailto:${email}?subject=QuickHire&body=${getMessage(name, jobUrl)}`);
    } else {
      const pid = getProfileId();

      if (pid && jobId && phone) {
        sendJobInvite({
          variables: {
            employeeProfileId: Number(pid),
            jobId: Number(jobId),
            phone
          }
        });
      }
      onClose();
    }
  });

  const onChange = (e, { name }) => {
    const { value } = e.target;
    set(name, name === 'phone' ? unformatPhone(value) : value);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      className={className}
      sx={{ '& .MuiPaper-root': { maxWidth: 380 } }}
      onClose={onClose}
    >
      <div className="content">
        <div className="title">{jobTitle}</div>
        <div className="subTitle">Who do you want to share this job with?</div>
        <Box mb="21px">
          <Button
            variant={!isEmail ? 'filled-primary' : 'outlined-primary'}
            aria-pressed={!isEmail}
            className="toggleBtn"
            onClick={() => setType(TYPES.TEXT)}
            testID="share-modal-text-toggle-button"
          >
            Text
          </Button>
          <Button
            variant={isEmail ? 'filled-primary' : 'outlined-primary'}
            aria-pressed={isEmail}
            className="toggleBtn"
            onClick={() => setType(TYPES.EMAIL)}
            testID="share-modal-email-toggle-button"
          >
            Email
          </Button>
        </Box>
        <Box mb="22px">
          <Box mb="16px">
            <Input
              {...$('name', onChange)}
              label="Name"
              variant="textfield"
              required
              withHelperText
              testID="share-modal-name-input"
            />
          </Box>
          {isEmail ? (
            <Input
              {...$('email', onChange)}
              label="Email"
              type="email"
              variant="textfield"
              required
              withHelperText
              testID="share-modal-email-input"
            />
          ) : (
            <PhoneInput
              {...$('phone', onChange)}
              label="Phone"
              variant="textfield"
              required
              withHelperText
              testID="share-modal-phone-input"
            />
          )}
        </Box>
        <Box textAlign="right">
          <Button
            variant="filled-primary"
            // endIcon={<Spinner size={12} />}
            className="submitBtn"
            onClick={submit}
            testID="share-modal-submit-button"
          >
            Send
          </Button>
        </Box>
      </div>
    </Dialog>
  );
}

ShareJobModal.propTypes = {
  className: PT.string.isRequired,
  isOpen: PT.bool.isRequired,
  jobTitle: PT.string.isRequired,
  jobUrl: PT.string.isRequired,
  jobId: PT.oneOfType([PT.string, PT.number]).isRequired,
  onClose: PT.func.isRequired
};

const Styled = styled(ShareJobModal)(({ theme }) => ({
  '& .content': {
    padding: '30px 24px 22px'
  },
  '& .title': {
    marginBottom: 17,
    fontWeight: 'bold',
    fontSize: 15,
    color: '#000'
  },
  '& .subTitle': {
    marginBottom: 17,
    fontWeight: 'bold',
    fontSize: 13,
    color: '#000'
  },
  '& .toggleBtn': {
    padding: '9px 20px',
    lineHeight: '14px',
    fontSize: 12,
    fontWeight: 'bold',
    borderRadius: 20,
    '&:not(:first-of-type)': {
      marginLeft: 13
    }
  },
  '& .submitBtn': {
    padding: '9px 20px',
    lineHeight: '14px',
    fontSize: 12,
    fontWeight: 'bold',
    borderRadius: 20
  }
}));

export default Styled;
