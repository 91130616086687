import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useAlerts } from 'hooks';
import { Box, useMediaQuery, styled } from 'components';
import { Button, RadioGroup, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import { POST_USER_RACE } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const NAME = 'race';

function EthnicityForm({ calledToasts, setCalledToasts, formKey, form, onChange, onGoToNextStep }) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { showToast } = useAlerts();
  const [postUserRace, { loading }] = useMutation(POST_USER_RACE);

  useEffect(() => {
    showAnalyticToast();
  }, []);

  const showAnalyticToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      setCalledToasts([...calledToasts, formKey]);
      showToast({
        title: "We're an employee focused tool, our goal is to help you at all costs",
        icon: () => <MdExclamation />,
        flourishText: '',
        position: { top: false, right: false }
      });
    }
  };

  const handleFieldChange = (field) => (e) => onChange({ ...form, [field]: e.target.value });

  const next = () => {
    onGoToNextStep(() => postUserRace({ variables: { [NAME]: form[NAME] } }));
  };

  return (
    <>
      <Box flex={1} display="flex" overflow="auto">
        <RadioGroup
          data={[
            { label: 'American Indian or Alaska Na..', value: 'American Indian or Alaska Na..' },
            { label: 'Asian', value: 'Asian' },
            { label: 'Black or African American', value: 'Black or African American' },
            { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
            { label: 'Native Hawaiian or Other Pac..', value: 'Native Hawaiian or Other Pac..' },
            { label: 'White', value: 'White' },
            { label: 'Two or More Ethnicities', value: 'Two or More Ethnicities' },
            { label: 'I Prefer Not to Answer', value: 'I Prefer Not to Answer' }
          ]}
          disabled={loading}
          value={form[NAME]}
          defaultValue="female"
          aria-label="gender"
          sx={{ maxHeight: 268, mb: '20px' }}
          analyticParams={{
            key: 'Employee ethnicity focused (onboarding)',
            trigger: 'focus'
          }}
          onChange={handleFieldChange('race')}
        />
      </Box>
      <div style={{ maxWidth: 320 }}>
        <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
          Did you know?
        </p>
        <p className="secondaryText" style={{ marginBottom: 16 }}>
          Knowing this helps us build a more equality centric workforce for the world.
        </p>
      </div>
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        className="nextBtn"
        sx={{ mb: isDesktop ? '25px' : '13px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
      <PrivacyClauseButton />
    </>
  );
}

EthnicityForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const StyledEthnicityForm = styled(EthnicityForm)(styles);

export default StyledEthnicityForm;
