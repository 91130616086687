import React, { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { emojiRates, textRates } from 'utils';
import isEmpty from 'lodash/isEmpty';
import { Spinner } from 'components/shared';
import growApi from '../../api';
import { makeQueryFetch } from '../../api/util';
import EmployerGrowContext from '../../EmployerGrowContext';

function EmployeeMood() {
  const client = useApolloClient();
  const [employeeMood, setEmployeeMood] = useState(null);
  const [employeeReasons, setEmployeeReasons] = useState([]);
  const [ratingReasons, setRatingReasons] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  // const { } = useContext(EmployerGrowContext);

  useEffect(() => {
    fetchEmployeeMoodStats();
  }, []);

  const fetchEmployeeMoodStats = async () => {
    setIsDataLoading(true);

    const moodScore = await makeQueryFetch(
      {
        query: growApi.query.EMPLOYEE_RATINGS_BY_EMPLOYER_ID,
        path: 'employeeRatingsByEmployerId'
      },
      client
    ).then((data = []) => {
      // add up all the mood scores and return a single digit rounded number,
      // to be used as our index for emoji and text arrays
      const scoreSum = data.reduce((acc, cur) => acc + cur.score, 0);
      return data.length > 0 ? Math.round(scoreSum / data.length) : 'N/A';
    });
    setEmployeeMood(moodScore);

    const ratingReasonsResp = await makeQueryFetch(
      {
        query: growApi.query.RATING_REASONS,
        path: 'ratingReasons'
      },
      client
    );
    setRatingReasons(ratingReasonsResp);

    const reasonResponse = await makeQueryFetch(
      {
        query: growApi.query.RATING_REASON_BY_EMPLOYER,
        path: 'ratingReasonsByEmployerProfile'
      },
      client
    ).then((data = []) => {
      // group the reasons by most popular with the following structure
      // {[reasonId]: count}
      const groupedReasons = data
        ? data.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.reasonId]: acc[cur.reasonId] ? acc[cur.reasonId] + 1 : 1
            }),
            {}
          )
        : {};
      // group the reaons into a tuple of [[reasonId, count]] for sorting
      return (
        Object.keys(groupedReasons)
          .map((key) => [Number(key), groupedReasons[key]])
          // sort the grouped reasons by most popular
          .sort((a, b) => {
            if (a[1] > b[1]) return -1;
            else return 1;
          })
      );
    });
    setEmployeeReasons(reasonResponse);
    setIsDataLoading(false);
  };

  const getReasons = () => {
    if (isEmpty(employeeReasons)) {
      return ['No Reasons at this time'];
    }
    return employeeReasons.map((reasonTuple, idx) => {
      if (idx <= 2) {
        return ratingReasons.filter((reason) => reason.id === reasonTuple[0])[0]?.reason;
      }
    });
  };

  const getEmojiRating = () => {
    if (employeeMood === 'N/A') {
      return <div>No ratings at this time</div>;
    }
    return (
      <div className="moodChartAlignment">
        <div className="moodChartEmojiParent">
          <span className="moodChartEmoji">{emojiRates[employeeMood - 1 || 0]}</span>
          <span>{textRates[employeeMood - 1 || 0]}</span>
        </div>
        <div className="moodChartReasonsContainer">
          <h5 className="moodChartReasonsTitle">Top Contributors to your score:</h5>
          {getReasons().map((reason, idx) => (
            <p key={`${reason}-${idx}`} className="moodChartReason">
              {reason}
            </p>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="moodChartContainer">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Employee Satisfaction Score</h4>
      <div className="moodChartParent">{getEmojiRating()}</div>
    </div>
  );
}

export default EmployeeMood;
