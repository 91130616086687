import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {
  REQUEST_PLAN_UPGRADE,
  client,
  employerSubscriptionVar,
  updateEmployerSubscriptionVar
} from 'api';
import { qaAttr, getRoutes, numberToUsdFormatter } from 'utils';
import { Box, Dialog, MuiIconButton, styled } from 'components';
import { Button } from 'components/shared';
import { MdArrowUpward, MdCheck, MdClose } from 'components/icons';
import { useRestrictions } from 'hooks';

const ROUTES = getRoutes();

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .paper': {
    maxWidth: 436,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxHeight: 'calc(100% - 16px)',
      width: 'calc(100% - 32px)',
      margin: 16
    }
  },
  '& .header': {
    padding: '7px 27px 12px',
    paddingLeft: 63,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '7px 16px 18px',
      paddingLeft: 52
    }
  },
  '& .title': {
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '18px',
    color: '#000'
  },
  '& .content': {
    padding: '0 44px 53px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 17px 46px'
    }
  },
  '& .icon': {
    minWidth: 124,
    height: 124,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 48,
    color: theme.palette.primary.main,
    borderRadius: '50%',
    backgroundColor: '#F6F5FA'
  },
  '& .message': {
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '20px',
    opacity: 0.8
  }
}));

const successDefault = [false, { formattedEndDate: '', planName: '' }];

function UpgradeExpiredPlanDialog({
  isOpen,
  leadToPlans,
  planId,
  planName,
  onClose,
  openPaymentModal,
  closePaymentModal,
  onSuccess,
  title,
  message,
  ...rest
}) {
  const { fetchRestrictions } = useRestrictions();
  const employerSubsInfo = useReactiveVar(employerSubscriptionVar);
  const { currentPlanId } = employerSubsInfo;

  const [[isSuccess, successData], setIsSuccess] = useState(successDefault);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isPaymentRequired, setIsPaymentRequired] = useState(false);
  const [planIdToUpgrade, setPlanIdToUpgrade] = useState(planId);
  const isUpgrade = !currentPlanId || currentPlanId <= planIdToUpgrade;

  const dialogTitle = (() => {
    if (isSuccess) return 'Request Sent';
    if (title) return title;
    if (planIdToUpgrade) return 'Do you really want to change plan?';
    return 'Your Plan Has Expired';
  })();

  const handleCloseModal = useCallback(() => {
    onClose();
    if (isPaymentRequired) {
      openPaymentModal({
        type: 'checkout',
        onPlanUpgrade: () => {
          setIsPaymentRequired(false);
          fetchRestrictions(); // update profile and restrictions with up-to-date data
          closePaymentModal();
        }
      });
    }
  }, [onClose, isPaymentRequired]);

  async function handleUpgrade() {
    setIsRequestLoading(true);

    const req = await client.mutate({
      mutation: REQUEST_PLAN_UPGRADE,
      variables: {
        planEmployerId: planIdToUpgrade
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    const data = req?.data?.postUpgradePlanEmployer;

    setIsRequestLoading(false);

    if (data) {
      const { endDate, id, name, price, paid } = data;
      const formattedEndDate = endDate ? format(parseISO(endDate), 'dd MMM yyyy') : '';
      setIsSuccess([true, { formattedEndDate, planName: name }]);
      onSuccess(data, isUpgrade);

      if (isUpgrade) {
        // update plan id, name and price to show them in PaymentDialog
        updateEmployerSubscriptionVar({
          nextPlan: name,
          nextPlanPrice: numberToUsdFormatter.format(price / 100),
          nextPlanStrippedPrice: price,
          planIdToPayFor: id
        });
        setIsPaymentRequired(true);
        openPaymentModal({
          type: 'checkout',
          onPlanUpgrade: () => {
            setIsPaymentRequired(false);
            fetchRestrictions(); // update profile and restrictions with up-to-date data
            closePaymentModal();
          }
        });
      }
    } else {
      handleCloseModal();
    }
  }

  useEffect(() => {
    if (isOpen) {
      setPlanIdToUpgrade(planId);
      setIsPaymentRequired(false);
      setIsRequestLoading(false);
      setIsSuccess(successDefault);
    }
  }, [isOpen]);

  const dialogMessage = useMemo(() => {
    let msg = message;
    if (leadToPlans) msg = '';
    if (isSuccess) {
      msg = isUpgrade
        ? `We’re happy to see you’re enjoying our services! ${successData.planName} is now active and will follow your original billing schedule.`
        : `${successData.planName} will become active on ${successData.formattedEndDate}`;
    }
    return msg;
  }, [
    isSuccess,
    isUpgrade,
    message,
    leadToPlans,
    successData.formattedEndDate,
    successData.planName
  ]);

  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      classes={{ paper: 'paper' }}
      onClose={handleCloseModal}
      {...rest}
    >
      <div className="header">
        <h1 className="title" {...qaAttr('upgrade-plan-modal-title')}>
          {dialogTitle}
        </h1>
        <MuiIconButton
          color="primary"
          edge="end"
          onClick={handleCloseModal}
          testID="upgrade-plan-modal-close-button"
        >
          <MdClose color="inherit" />
        </MuiIconButton>
      </div>
      <div className="content">
        <Box mb="24px" display="flex" justifyContent="center">
          <div className="icon">
            {isSuccess ? (
              <MdCheck color="inherit" fontSize="inherit" />
            ) : (
              <MdArrowUpward color="inherit" fontSize="inherit" />
            )}
          </div>
        </Box>
        <p className="message">{dialogMessage}</p>
        <Box textAlign="center">
          {!isSuccess && (
            <Button
              variant="filled-primary"
              disabled={isRequestLoading}
              sx={{ width: 250, height: 50 }}
              {...(leadToPlans
                ? {
                    isRouterLink: true,
                    to: ROUTES.employer.plans,
                    onClick: handleCloseModal
                  }
                : {
                    onClick: handleUpgrade
                  })}
              testID="upgrade-plan-modal-submit-button"
            >
              {leadToPlans ? 'See Plans' : 'Upgrade Now'}
            </Button>
          )}
        </Box>
      </div>
    </StyledDialog>
  );
}

UpgradeExpiredPlanDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  openPaymentModal: PropTypes.func.isRequired,
  closePaymentModal: PropTypes.func.isRequired,
  planId: PropTypes.number,
  message: PropTypes.string,
  title: PropTypes.string,
  leadToPlans: PropTypes.bool
};

UpgradeExpiredPlanDialog.defaultProps = {
  onSuccess: () => {},
  planId: null,
  message: '',
  title: '',
  leadToPlans: false
};

export default UpgradeExpiredPlanDialog;
