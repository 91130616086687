export default ({ theme }) => ({
  '&.pageContainer': {
    position: 'relative',
    height: '100vh',
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto',
    display: 'flex'
    // [theme.breakpoints.down(theme.desktopBreakPoint)]: {
    //   // display: 'flex',
    //   // flexDirection: 'column'
    // }
  },
  '& .commonStepper': {
    marginBottom: 13,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#7A7A7A',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      lineHeight: '10px',
      fontSize: 9
    }
  },
  '& .commonStepper__title': {
    marginBottom: 13,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      lineHeight: '12px',
      fontSize: 13
    }
  },
  '& .commonStepper__subTitle': {
    maxWidth: 360,
    margin: '0 auto 30px',
    textAlign: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 290,
      marginBottom: 52
    }
  },
  '& .header': {
    padding: '20px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '4px 16px'
    }
  },
  '& .contentWrapper': {
    paddingBottom: 74,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      paddingBottom: 0
      // marginBottom: 56
    }
  },
  '& .defaultStepContent': {
    paddingTop: 75,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1
    }
  },
  '& .stepContent': {
    paddingTop: 14,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    // overflow: 'hidden',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '40px 16px 16px',
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
      flex: 1
    }
  },
  '& .footerContainer': {
    position: 'absolute',
    bottom: 0,
    height: 74,
    width: '100%',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 56,
      display: 'none'
    }
  },
  '& .companyName': {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: -0.08,
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    display: 'block',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .stepper': {
    padding: 0,
    display: 'flex',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .progressBar': {
    width: '100%',
    height: 3,
    borderRadius: '0 100px 100px 0',
    backgroundColor: 'transparent'
  },
  '& .desktopPrevBtn': {
    padding: '20px 30px 0',
    display: 'block',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'none'
    }
  },
  '& .mobilePrevBtn': {
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'flex'
    }
  },
  '& .arrowBtn': {
    fontSize: 26
  },
  '& .pageTitle': {
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0.96,
    textAlign: 'center',
    color: '#2E2E2E',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 18,
      lineHeight: '20px'
    }
  },
  '& .pageSubTitle': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '26px',
    letterSpacing: 0.5,
    textAlign: 'center',
    color: '#2E2E2E',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 14,
      lineHeight: '16px'
    }
  },
  '& .surveyTitle': {
    marginBottom: 40,
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: 1,
    letterSpacing: 0.96,
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: 300,
      fontSize: 19,
      lineHeight: '21px'
    }
  },
  '& .pageContent': {
    width: '100%',
    maxWidth: 320,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '& .primaryText': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      lineHeight: '13px',
      fontSize: 12
    }
  },
  '& .secondaryText': {
    fontWeight: 'bold',
    color: '#B3B3B3',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      lineHeight: '12px',
      fontSize: 11
    }
  },
  '& .text': {
    fontWeight: 'bold',
    color: '#313131',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      lineHeight: '12px',
      fontSize: 11
    }
  },
  '& .nextBtn': {
    width: '100%',
    height: 50,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      height: 45,
      maxWidth: 245,
      fontSize: 11,
      lineHeight: '12px'
    }
  },
  '& .input': {
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 12,
      '& input, & select': {
        padding: 11,
        '&::placeholder': {
          fontSize: 12,
          color: '#B3B3B3'
        }
      }
    }
  }
});
