import React, { useContext, memo } from 'react';
import PT from 'prop-types';
import { Spinner } from 'components/shared';
import Pill from './Pill';
import EmployerGrowContext from '../../EmployerGrowContext';

function Pills(props) {
  const { pillData, activePill, setActivePill, isLoading } = props;
  // const {  } = useContext(EmployerGrowContext);

  return (
    <div className="pills">
      {isLoading && (
        <div className="pillsLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      {pillData &&
        pillData.map((data, idx) => (
          <Pill
            key={`pill-${idx}-${data.cityName}`}
            idx={idx}
            isActive={activePill === idx}
            setActivePill={setActivePill}
            {...data}
          />
        ))}
      {/*evens out any layer mess so there are always 4 blocks*/}
      {pillData &&
        pillData.length % 4 > 0 &&
        Array(((pillData.length % 4) - 4) * -1)
          .fill('')
          .map((_) => <Pill empty />)}
    </div>
  );
}

Pills.propTypes = {
  activePill: PT.number.isRequired,
  isLoading: PT.bool.isRequired,
  pillData: PT.array.isRequired,
  setActivePill: PT.func.isRequired
};

export default memo(Pills);
