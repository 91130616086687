import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { getDisplayName } from 'utils';
import { styled } from 'components';
import { Input } from 'components/shared';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import HireReasonsModal from 'components/dialogs/HireReasonsModal';
import { getApplicantUserId } from 'components/ApplicantsDashboard/utils';
import {
  APPROVE_EMPLOYEE,
  GET_APPLICANTS,
  GET_APPROVED,
  GET_REJECTED,
  GET_STARRED,
  GET_QUICK_MATCH,
  HIRE_EMPLOYEE
} from 'api';

const REJECT_MESSAGE =
  'Thanks for submitting your application to our open position. Unfortunately, we don’t think it’s a perfect match right now. Please keep an eye out for more roles from us that may be a better fit!';
const ACCEPT_MESSAGE =
  'Thanks for submitting your application to our open position. We like your profile and would like to consider you for the job. When are you available to chat?';
const getAcceptMessageWithLink = (link) =>
  `Congratulations! We would like to formally invite you to the next step in the interview process. Please follow the link to the next step here: ${link}`;

const modalsDefault = {
  acceptModal: false,
  rejectModal: false,
  hireReasonsModal: false,
  applicantData: {},
  jobId: null,
  onSuccess: () => {},
  onCancel: () => {},
  onError: () => {}
};

const StyledInput = styled(Input)({
  '& .messageInputRoot': {
    padding: 0,
    border: 'none'
  },
  '& .messageInput': {
    padding: 0,
    lineHeight: '24px'
  }
});

function withApplicantActions(WrappedComponent) {
  function WithApplicantActions(props) {
    const [modals, setModals] = useState(modalsDefault);
    const [acceptDialogMessage, setAcceptDialogMessage] = useState(ACCEPT_MESSAGE);
    const [rejectDialogMessage, setRejectDialogMessage] = useState(REJECT_MESSAGE);

    const [hireEmployee, { loading: hireLoading = false }] = useMutation(HIRE_EMPLOYEE);
    const [acceptEmployee, { loading: acceptLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [rejectEmployee, { loading: rejectLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [starEmployee, { loading: starLoading = false }] = useMutation(APPROVE_EMPLOYEE);
    const [cancelEmployee, { loading: cancelLoading = false }] = useMutation(APPROVE_EMPLOYEE);

    const manageModals = (newConfig = {}) => {
      setModals((prev) => ({ ...prev, ...newConfig }));
    };

    const finishHire = useCallback(
      async ({ notes, reasons }) => {
        const { applicantData, hired, jobId, onSuccess, onError } = modals;
        const applicationUID = getApplicantUserId(applicantData);

        manageModals({ ...modalsDefault, acceptModal: false, hireReasonsModal: false });

        if (jobId && applicationUID) {
          try {
            const response = await hireEmployee({
              variables: {
                jobId,
                employeeId: applicationUID,
                notes,
                hired,
                hiredReasons: reasons
              }
            });

            onSuccess(response);
          } catch (error) {
            console.error(error);
            onError(error);
          }
        }
      },
      [modals.hired, modals.jobId, JSON.stringify(modals.applicantData), modals.onSuccess]
    );

    const finishAccept = useCallback(async () => {
      const { applicantData, jobId, onSuccess, onError } = modals;
      const applicantUID = getApplicantUserId(applicantData);

      manageModals({ ...modalsDefault, acceptModal: false, hireReasonsModal: false });

      if (jobId && applicantUID) {
        try {
          const response = await acceptEmployee({
            variables: {
              jobId,
              userId: applicantUID,
              approved: true,
              passed: false,
              starred: false,
              message: acceptDialogMessage
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      }
    }, [acceptDialogMessage, modals.jobId, JSON.stringify(modals.applicantData), modals.onSuccess]);

    const finishReject = useCallback(async () => {
      const { applicantData, jobId, onSuccess, onError } = modals;
      const applicantUID = getApplicantUserId(applicantData);

      manageModals({ ...modalsDefault, rejectModal: false, hireReasonsModal: false });

      if (jobId && applicantUID) {
        try {
          const response = await rejectEmployee({
            variables: {
              jobId,
              userId: applicantUID,
              approved: false,
              passed: true,
              starred: false,
              message: rejectDialogMessage
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      }
    }, [rejectDialogMessage, modals.jobId, JSON.stringify(modals.applicantData), modals.onSuccess]);

    const openAcceptDialog = useCallback((params) => {
      manageModals({ acceptModal: true, ...params });
    }, []);

    const openRejectDialog = useCallback((params) => {
      manageModals({ rejectModal: true, ...params });
    }, []);

    const closeRejectDialog = useCallback((params) => {
      manageModals({ rejectModal: false, ...params });
    }, []);

    const closeAcceptDialog = useCallback((params) => {
      manageModals({ acceptModal: false, ...params });
    }, []);

    const openHireReasonsModal = useCallback((params) => {
      manageModals({ hireReasonsModal: true, ...params });
    }, []);

    const closeHireReasonsModal = useCallback((params) => {
      manageModals({ hireReasonsModal: false, ...params });
    }, []);

    const acceptApplicant = useCallback(
      ({
        applicantData,
        jobId,
        jobWebUrl,
        onSuccess = () => {},
        onCancel = () => {},
        onError = () => {}
      }) => {
        if (jobWebUrl) {
          setAcceptDialogMessage(getAcceptMessageWithLink(jobWebUrl));
          openAcceptDialog({ applicantData, jobId, onSuccess, onCancel, onError });
        } else {
          openHireReasonsModal({ applicantData, hired: true, jobId, onSuccess, onCancel, onError });
        }
      },
      []
    );

    const rejectApplicant = useCallback(
      ({
        applicantData,
        jobId,
        jobWebUrl,
        onSuccess = () => {},
        onCancel = () => {},
        onError = () => {}
      }) => {
        if (jobWebUrl) {
          openRejectDialog({ applicantData, jobId, onSuccess, onCancel, onError });
        } else {
          openHireReasonsModal({
            applicantData,
            hired: false,
            jobId,
            onSuccess,
            onCancel,
            onError
          });
        }
      },
      []
    );

    const starApplicant = useCallback(
      async ({ applicantData, jobId, starred, onSuccess = () => {}, onError = () => {} }) => {
        const applicantUID = getApplicantUserId(applicantData);

        try {
          const response = await starEmployee({
            variables: {
              userId: applicantUID,
              jobId,
              starred
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      },
      []
    );

    const cancelApplicant = useCallback(
      async ({ applicantData, jobId, onSuccess = () => {}, onError = () => {} }) => {
        const applicantUID = getApplicantUserId(applicantData);

        try {
          const response = await cancelEmployee({
            variables: {
              jobId,
              userId: applicantUID,
              approved: false,
              passed: false,
              starred: false
            }
          });

          onSuccess(response);
        } catch (error) {
          console.error(error);
          onError(error);
        }
      },
      []
    );

    return (
      <>
        <WrappedComponent
          {...props}
          acceptApplicant={acceptApplicant}
          rejectApplicant={rejectApplicant}
          starApplicant={starApplicant}
          cancelApplicant={cancelApplicant}
          hireLoading={hireLoading}
          acceptLoading={acceptLoading}
          rejectLoading={rejectLoading}
          starLoading={starLoading}
          cancelLoading={cancelLoading}
        />

        <ConfirmationDialog
          isOpen={modals.acceptModal}
          title="Accept Message"
          message={
            <StyledInput
              value={acceptDialogMessage}
              multiline
              autoFocus
              inputClassName="messageInputRoot"
              htmlInputClassName="messageInput"
              onChange={(e) => setAcceptDialogMessage(e.target.value)}
              testID="candidates-table-accept-input"
            />
          }
          onClose={() => {
            modals.onCancel();
            closeAcceptDialog();
          }}
          onConfirm={finishAccept}
          onCancel={() => {
            modals.onCancel();
            closeAcceptDialog();
          }}
        />

        <ConfirmationDialog
          isOpen={modals.rejectModal}
          title="Reject Message"
          message={
            <StyledInput
              value={rejectDialogMessage}
              multiline
              autoFocus
              inputClassName="messageInputRoot"
              htmlInputClassName="messageInput"
              onChange={(e) => setRejectDialogMessage(e.target.value)}
              testID="candidates-table-reject-input"
            />
          }
          onClose={() => {
            modals.onCancel();
            closeRejectDialog();
          }}
          onConfirm={finishReject}
          onCancel={() => {
            modals.onCancel();
            closeRejectDialog();
          }}
        />

        <HireReasonsModal
          isOpen={modals.hireReasonsModal}
          title={modals.hired ? 'Hire reasons' : 'Deny reasons'}
          hired={modals.hired}
          onClose={() => {
            modals.onCancel();
            closeHireReasonsModal();
          }}
          onConfirm={finishHire}
        />
      </>
    );
  }

  WithApplicantActions.displayName = `WithApplicantActions(${getDisplayName(WrappedComponent)})`;
  return WithApplicantActions;
}

export default withApplicantActions;
