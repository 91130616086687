import React, { useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { MdChevronRight } from 'components/icons';
import { GET_EMPLOYER_PROFILE, employerSubscriptionVar } from 'api';
import { getUserId, getRoutes } from 'utils';
import defaultEmployerImg from 'assets/img/employer_default.png';

const StyledRoot = styled(Button)(({ theme }) => ({
  '&.container': {
    marginBottom: 15,
    padding: '15px 18px',
    borderTop: '1px solid #E5E5E5',
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    borderRadius: 0
  },
  '& .companyLogo': {
    minWidth: 48,
    height: 48,
    marginRight: 24,
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '50%',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minWidth: 50,
      height: 50,
      marginRight: 16
    }
  },
  '& .companyName': {
    marginBottom: 12,
    lineHeight: '24px',
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      marginBottom: 8,
      lineHeight: '18px',
      fontSize: 16,
      color: '#000'
    }
  },
  '& .plan': {
    lineHeight: '17px',
    fontSize: 14,
    color: theme.palette.primary.main
  },
  '& .plan__trial': {
    marginLeft: 12,
    opacity: 0.5
  }
}));

const ROUTES = getRoutes();

function ShortCompanyCard(props) {
  const employerSubsInfo = useReactiveVar(employerSubscriptionVar);
  const { trialStatus, planName } = employerSubsInfo;

  const { data, refetch, loading } = useQuery(GET_EMPLOYER_PROFILE, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: { userId: Number(getUserId()) }
  });
  const { name, imageUrl } = data?.employerProfile || {};

  return (
    <StyledRoot
      isRouterLink
      to={ROUTES.employer.plans}
      disableRipple
      className="container"
      testID="upgrade-plan-button"
    >
      <img className="companyLogo" src={imageUrl || defaultEmployerImg} alt="" />
      {!loading ? (
        <>
          <Box flex={1}>
            <div className="companyName">{name}</div>
            <div className="plan">
              <span>{`Plan: ${planName}`}</span>
              {trialStatus && <span className="plan__trial">{trialStatus}</span>}
            </div>
          </Box>
          <MdChevronRight color="primary" style={{ marginLeft: 'auto', marginRight: 0 }} />
        </>
      ) : (
        <Box flex={1}>
          <Spinner size={30} />
        </Box>
      )}
    </StyledRoot>
  );
}

export default ShortCompanyCard;
