import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { styled } from 'components';
import { qaAttr } from 'utils';
import ButtonBase from './ButtonBase';

const PREFIX = 'StyledButton';
const classes = {
  label: `${PREFIX}-label`,
  startIcon: `${PREFIX}-startIcon`,
  endIcon: `${PREFIX}-endIcon`
};

function Button(props) {
  const {
    component,
    children,
    className,
    endIcon,
    isRouterLink,
    startIcon,
    testID,
    variant,
    ...buttonBaseProps
  } = props;

  return (
    <ButtonBase
      component={isRouterLink ? Link : component}
      className={className}
      {...(testID && qaAttr(testID))}
      {...buttonBaseProps}
    >
      {startIcon || endIcon ? (
        <span className={classes.label}>
          {startIcon && <span className={classes.startIcon}>{startIcon}</span>}
          {children}
          {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
        </span>
      ) : (
        children
      )}
    </ButtonBase>
  );
}

Button.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  endIcon: PropTypes.node,
  isRouterLink: PropTypes.bool,
  startIcon: PropTypes.node,
  testID: PropTypes.string,
  variant: PropTypes.oneOf([
    '',
    'filled-primary',
    'filled-secondary',
    'outlined-secondary',
    'outlined-primary',
    'text'
  ])
};

Button.defaultProps = {
  component: 'button',
  children: null,
  className: '',
  endIcon: null,
  isRouterLink: false,
  startIcon: null,
  testID: '',
  variant: ''
};

const StyledButton = styled(Button)(({ theme, variant, height, width }) => ({
  padding: '8px 12px',
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: '17px',
  letterSpacing: 0,
  borderRadius: 10,
  ...(width !== undefined && { width }),
  ...(height !== undefined && { height }),
  '&.Mui-disabled': {
    opacity: 1
  },
  [`& .${classes.label}`]: {
    width: '100%',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit'
  },
  [`& .${classes.startIcon}`]: {
    marginRight: 8,
    marginLeft: -4,
    display: 'inherit',
    fontSize: 24,
    color: 'inherit'
  },
  [`& .${classes.endIcon}`]: {
    marginRight: -4,
    marginLeft: 8,
    display: 'inherit',
    fontSize: 24,
    color: 'inherit'
  },
  ...(variant === 'text' && {
    padding: 0,
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
      backgroundColor: 'transparent'
    }
  }),
  ...(variant === 'filled-primary' && {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    '&.Mui-disabled': {
      opacity: 1,
      backgroundColor: theme.palette.moreColors.disabled
    }
  }),
  ...(variant === 'filled-secondary' && {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.moreColors.purpleLight,
    '&.Mui-disabled': {
      opacity: 0.4
    }
  }),
  ...(variant === 'outlined-secondary' && {
    border: '1px solid rgba(0,0,0,0.1)',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&.Mui-disabled': {
      opacity: 0.3
    }
  }),
  ...(variant === 'outlined-primary' && {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&.Mui-disabled': {
      opacity: 0.3
    }
  })
}));

export default StyledButton;
