import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import map from 'lodash/map';
import find from 'lodash/find';
import clsx from 'clsx';
import { qaAttr } from 'utils';
import { Box, styled, useMediaQuery } from 'components';
import { ButtonBase, IconButton, JobInterviewSchedule, useSchedule } from 'components/shared';
import {
  CalendarIcon,
  MdArrowBack,
  MdClose,
  MdCheck,
  MdIosShare,
  MdStar,
  MdStarBorder
} from 'components/icons';
import { ShareJob } from 'components/Job';
import navIcon from 'assets/img/navigation_icon.png';
import defaultImg from 'assets/img/job_default.png';

import styles from 'styles/Dashboard/EmployeeJobCard';

const StyledRoot = styled('div')(styles);
const stars = [1, 2, 3, 4, 5];

function JobCard(props) {
  const {
    countries = [],
    data,
    onSelect,
    onAccept,
    onReject,
    onRetract,
    onStar,
    onScheduleCall,
    onInterviewConfirm,
    states = [],
    status,
    qhAttrs: qaAttrs,
    withSchedule
  } = props;
  const {
    bestFit,
    city,
    countryId,
    description = '',
    distance = 0,
    employerProfile = {
      imageUrl: '',
      name: ''
    },
    employerProfileProfileId = null,
    id = '',
    imageUrl,
    isSchedule,
    location = '',
    payRange = '',
    rate = 0,
    stateId,
    title = '',
    webUrl = '',
    zip
  } = data;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const isAccepted = status === 'accepted';
  const isRejected = status === 'rejected';

  const selectedState = useMemo(() => {
    if (states.length && stateId) {
      return find(states, ['id', Number(stateId)]) || {};
    }
    return {};
  }, [JSON.stringify(states), stateId]);

  const selectedCountry = useMemo(() => {
    if (countries.length && countryId) {
      return find(countries, ['id', Number(countryId)]) || {};
    }
    return {};
  }, [JSON.stringify(countries), countryId]);

  const getJobAddress = () =>
    city && selectedCountry.name && selectedState.code && zip
      ? `${city}, ${selectedState.code}, ${zip}, ${selectedCountry.code}`
      : '';

  const jobAddress = getJobAddress();

  const {
    getSelectedInterviewDateLoading,
    handleInterviewConfirm,
    inactiveDates,
    isScheduleOpen,
    postInterviewLoading,
    setIsScheduleOpen,
    selectedInterviewDate,
    setInterviewDate
  } = useSchedule({ jobId: id, role: 'employee' });
  const [interviewDate, employerScheduleId] = selectedInterviewDate;

  const enableSchedule = withSchedule && isSchedule && !isAccepted;

  function handleJobSelect() {
    onSelect(id, false, { approved: isAccepted });
  }

  function handleJobApply() {
    onAccept(data);
  }

  function handleJobReject() {
    onReject(data);
  }

  function handleStar() {
    onStar(data);
  }

  function handleRetract() {
    onRetract(data);
  }

  const handleInterviewDateSelect = useCallback((...params) => {
    setInterviewDate([...params]);
  }, []);

  const confirmNewInterviewDate = useCallback(
    async (date, employerScheduleIdParam) => {
      await handleInterviewConfirm(date, employerScheduleIdParam, () => {
        setIsScheduleOpen(false);
        setInterviewDate();
        onInterviewConfirm(date, id);
      });
    },
    [handleInterviewConfirm, id, onInterviewConfirm, JSON.stringify(data)]
  );

  const openSchedule = () => {
    onScheduleCall(data, {
      showJobCardSchedule: isDesktop ? () => setIsScheduleOpen(true) : undefined // separate modal will be called on mobile
    });
  };
  const closeSchedule = useCallback(() => {
    setIsScheduleOpen(false);
  }, []);

  const renderJobInfo = () => (
    <>
      <ButtonBase
        className={clsx('card__btn')}
        onClick={handleJobSelect}
        {...qaAttr(`job-button-${title}`)}
        {...qaAttrs}
      >
        <div
          className="card__photoArea"
          style={{ backgroundImage: `url("${imageUrl || defaultImg}")` }}
        >
          {isAccepted && (
            <div className="status applied">
              <MdCheck color="inherit" fontSize="inherit" className="status__icon" />
              Applied
            </div>
          )}
          {isRejected && (
            <div className="status rejected">
              <MdClose color="inherit" fontSize="inherit" className="status__icon" />
              Rejected
            </div>
          )}
          {!!rate && (
            <div className="card__ratingContainer">
              <span>{`${rate} with tips`}</span>
              <span className="card__ratingDelimiter">&nbsp;|&nbsp;</span>
              <span className="rating">
                {map(stars, (n, i) => {
                  const isFullStar = n <= rate;
                  const isHalfStar = n - rate === 0.5;
                  return (
                    <span key={`rateStar__${i}`} className="rating__starWrapper">
                      <MdStar
                        className={`rating__star ${
                          isFullStar && !isHalfStar ? 'fullStar' : 'emptyStar'
                        }`}
                      />
                      {isHalfStar && (
                        <span className="halfStar">
                          <MdStar className="rating__star fullStar" />
                        </span>
                      )}
                    </span>
                  );
                })}
              </span>
            </div>
          )}
        </div>
        <Box className="card__mainContent">
          <Box width="100%" flex={1}>
            <h2 className="card__name">
              {title.length > 50 ? truncate(title, { length: 45 }) : title}
            </h2>
            {/*<div className="card__jobStats">*/}
            {/*<Box textAlign="left">*/}
            {/*<span>{payRange}</span>*/}
            {/*<img className="navIcon" src={navIcon} alt=""/>*/}
            {/*<span>{`${distance} mi - ${location}`}</span>*/}
            {/*</Box>*/}
            {/*</div>*/}
            <div className="card__employerContacts">
              {`@${employerProfile?.name}${jobAddress ? ' | ' : ''}`}
              <span className="card__employerAddress">{jobAddress}</span>
            </div>
            <Box mb="18px" className="card__jobDescription">
              {truncate(description, { length: 125, separator: /,? +/ })}
            </Box>
          </Box>
          {!enableSchedule ? (
            <div className="card__company">
              <div
                className="card__companyLogo"
                style={{ backgroundImage: `url("${employerProfile?.imageUrl}")` }}
              />
              <Box ml="16px" className="card__companyName">
                {employerProfile?.name}
              </Box>
            </div>
          ) : (
            <div className="footer__placeholder" />
          )}
        </Box>
      </ButtonBase>
      <div
        className={`card__actions ${
          enableSchedule ? 'card__actions_withSchedule' : 'card__actions_default'
        }`}
      >
        {enableSchedule && (
          <div className="schedule__title">Employer has open interview spots!</div>
        )}
        <div className="actions__container">
          <div className="actions__innerContainer">
            {isAccepted ? (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="Cancel"
                withTooltip
                toolTipProps={{ title: 'Cancel', placement: 'bottom' }}
                className="card__action"
                sx={{ padding: '7px' }}
                onClick={handleRetract}
                testID="job-retract-button"
              >
                <MdArrowBack />
              </IconButton>
            ) : (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="Skip Job"
                withTooltip
                toolTipProps={{ title: 'Skip Job', placement: 'bottom' }}
                disabled={isRejected}
                className="card__action"
                sx={{ padding: '7px' }}
                onClick={handleJobReject}
                testID="job-reject-button"
              >
                <MdClose />
              </IconButton>
            )}
            {!enableSchedule && (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="Apply Now"
                withTooltip
                toolTipProps={{ title: 'Apply Now!', placement: 'bottom' }}
                disabled={isAccepted}
                className="card__action"
                sx={{ padding: '7px', marginLeft: '16px' }}
                onClick={handleJobApply}
                testID="job-accept-button"
              >
                <MdCheck />
              </IconButton>
            )}
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="Save for Later"
              withTooltip
              toolTipProps={{ title: 'Save for Later!', placement: 'bottom' }}
              className="card__action"
              sx={{ padding: '7px', marginLeft: enableSchedule ? 0 : '16px' }}
              onClick={handleStar}
              testID="job-star-button"
            >
              <MdStarBorder />
            </IconButton>
            {enableSchedule && (
              <div className="card__scheduleBtnContainer">
                <IconButton
                  variant="outlined"
                  color="primary"
                  aria-label="interview"
                  className="card__scheduleBtn"
                  onClick={openSchedule}
                  testID="job-interview-button"
                >
                  <span className="scheduleBtnTitle">Schedule your interview</span>
                  <span className="scheduleBtnIconWrapper">
                    <CalendarIcon color="inherit" style={{ fontSize: 20 }} />
                  </span>
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const renderSchedule = () => (
    <JobInterviewSchedule
      employerProfileId={employerProfileProfileId}
      inactiveDates={inactiveDates}
      selectedDate={selectedInterviewDate}
      qaId={title}
      withCancel
      withConfirmation
      onDateSelect={handleInterviewDateSelect}
      onConfirm={confirmNewInterviewDate}
      onClose={closeSchedule}
      confirmLoading={postInterviewLoading}
    />
  );

  return (
    <StyledRoot
      className={clsx('cardRoot', isAccepted && 'card__applied')}
      data-card={id}
      {...qaAttr(`job-card-${title}`)}
    >
      <div className="card__content">
        {bestFit && (
          <div className="bestFitLabel">
            <span>MATCH</span>
          </div>
        )}
        {!isScheduleOpen && (
          <ShareJob jobId={id} jobTitle={title}>
            {(share) => (
              <IconButton color="primary" className="shareButton" onClick={share}>
                <MdIosShare fontSize="inherit" />
              </IconButton>
            )}
          </ShareJob>
        )}
        {isScheduleOpen ? renderSchedule() : renderJobInfo()}
      </div>
    </StyledRoot>
  );
}

JobCard.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onRetract: PropTypes.func,
  onStar: PropTypes.func.isRequired,
  onScheduleCall: PropTypes.func,
  onInterviewConfirm: PropTypes.func,
  states: PropTypes.arrayOf(PropTypes.any),
  status: PropTypes.oneOf(['accepted', 'rejected', '']),
  qhAttrs: PropTypes.objectOf(PropTypes.any),
  withSchedule: PropTypes.bool
};

JobCard.defaultProps = {
  countries: [],
  onScheduleCall: (job, { showJobCardSchedule }) => {},
  onInterviewConfirm: () => {},
  onRetract: () => {},
  states: [],
  status: '',
  qhAttrs: {},
  withSchedule: false
};

export const PureJobCard = JobCard;
export default memo(JobCard);
