import CCValidator from 'card-validator';

const { STRIPE_KEY } = process.env;
const STRIPE_API_KEY = STRIPE_KEY || 'pk_test_51HaPSGJHXtnPeKzVNduWavpstdcXDE76RztkXj1yXPhuP4AEcKKsBbOSFRkZY3lUEYJs5xJ62GQ9fMpLx29UOBPM00t0d1E22J';

export const BRAND_NAMES = {
  // https://github.com/amaroteam/react-credit-cards uses types from https://github.com/jessepollak/payment
  amex: { stripe: 'American Express', paymentJs: 'amex', cardValidator: 'american-express' },
  dinersclub: { stripe: 'Diners Club', paymentJs: 'dinersclub', cardValidator: 'diners-club' },
  discover: { stripe: 'Discover', paymentJs: 'discover', cardValidator: 'discover' },
  jcb: { stripe: 'JCB', paymentJs: 'jcb', cardValidator: 'jcb' },
  mastercard: { stripe: 'MasterCard', paymentJs: 'mastercard', cardValidator: 'mastercard' },
  unionpay: { stripe: 'UnionPay', paymentJs: 'unionpay', cardValidator: 'unionpay' },
  visa: { stripe: 'Visa', paymentJs: 'visa', cardValidator: 'visa' },
};

export const getCommonCardBrandNames = (brand) => {
  switch (brand) {
    case BRAND_NAMES.amex.stripe:
    case BRAND_NAMES.amex.cardValidator:
    case BRAND_NAMES.amex.paymentJs: { return BRAND_NAMES.amex }
    case BRAND_NAMES.dinersclub.stripe:
    case BRAND_NAMES.dinersclub.cardValidator:
    case BRAND_NAMES.dinersclub.paymentJs: { return BRAND_NAMES.dinersclub }
    case BRAND_NAMES.discover.stripe:
    case BRAND_NAMES.discover.cardValidator:
    case BRAND_NAMES.discover.paymentJs: { return BRAND_NAMES.discover }
    case BRAND_NAMES.jcb.stripe:
    case BRAND_NAMES.jcb.cardValidator:
    case BRAND_NAMES.jcb.paymentJs: { return BRAND_NAMES.jcb }
    case BRAND_NAMES.mastercard.stripe:
    case BRAND_NAMES.mastercard.cardValidator:
    case BRAND_NAMES.mastercard.paymentJs: { return BRAND_NAMES.mastercard }
    case BRAND_NAMES.unionpay.stripe:
    case BRAND_NAMES.unionpay.cardValidator:
    case BRAND_NAMES.unionpay.paymentJs: { return BRAND_NAMES.unionpay }
    case BRAND_NAMES.visa.stripe:
    case BRAND_NAMES.visa.cardValidator:
    case BRAND_NAMES.visa.paymentJs: { return BRAND_NAMES.visa }
    default: {
      return {
        stripe: brand,
        paymentJs: brand,
        cardValidator: brand,
      };
    }
  }
}

export const getMaskedCardNumber = (brand = 'visa', last4 = null) => {
  const cardValidatorBrand = getCommonCardBrandNames(brand)['cardValidator'];

  switch (cardValidatorBrand) {
    case BRAND_NAMES.amex.cardValidator:
    case BRAND_NAMES.dinersclub.cardValidator: {
      return `**** ****** *${last4 || '****'}`;
    }
    default: {
      return `**** **** **** ${last4 || '****'}`;
    }
  }
}

const clearNumber = (value = '') => {
  return value.replace(/\D+/g, '');
}

export const formatCreditCardNumber = (value = '') => {
  if (!value) return value;

  const { card } = CCValidator.number(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (card?.type) {
    case BRAND_NAMES.amex.cardValidator:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case BRAND_NAMES.dinersclub.cardValidator:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export const formatCVC = (value = '', brand = 'visa') => {
  const cardValidatorBrand = getCommonCardBrandNames(brand)['cardValidator'];
  const clearValue = clearNumber(value);
  let maxLength = cardValidatorBrand === BRAND_NAMES.amex.cardValidator ? 4 : 3;

  return clearValue.slice(0, maxLength);
}

export const formatExpirationDate = (value = '') => {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export const getStripeToken = async ({ number, month, year, cvc, name }, onSuccess, onError) => {
  try {
    if (STRIPE_API_KEY) {
      const cardDataStr = `card[number]=${number}&card[exp_month]=${month}&card[exp_year]=${year}&card[cvc]=${cvc}&card[name]=${name}`;
      const stripeResponse = await fetch('https://api.stripe.com/v1/tokens', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${STRIPE_API_KEY}`
        },
        method: 'post',
        body: cardDataStr
      });
      const data = await stripeResponse.json();
      if (data.error) throw data;
      else return data;
    }
    return;
  } catch (errors) {
    const params = ['number', 'exp_month', 'exp_year', 'cvc'];
    console.error('Stripe errors', errors);
    if (errors?.error?.param && errors?.error?.message && params.indexOf(errors.error.param) !== -1) {
      if (onError) onError([errors.error.param, errors.error.message]);
    } else if (errors?.error?.message) {
      if (onError) onError(errors.error.message);
    }
    return;
  }
}
