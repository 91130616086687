import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getRoutes } from 'utils';
import { useAuth } from 'hooks';
import {
  RootLayout,
  EmployeeCareerProgress,
  EmployeeProgressSkills,
  EmployeeProgressConnections,
  EmployeeStillHired,
  EmployeeDashboard,
  EmployerSchedule,
  EmployerProfile,
  EmployeeProfile,
  EmployerPlans,
  EmployerJobs,
  EmployerJobDetails,
  EnterpriseDashboard,
  EnterpriseSearch,
  JobPosting,
  Login,
  NotFound,
  Onboarding
} from 'containers';
import { MobileAppBanner } from 'components/shared';
import ProtectedEmployerRoute from './ProtectedEmployerRoute';
import ProtectedEmployeeRoute from './ProtectedEmployeeRoute';
import ProtectedOnboardingRoute from './ProtectedOnboardingRoute';
import EmployerCareerDevelopment from '../components/Grow/employer';

// const ROUTES = getRoutes();

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="verify" element={<Login />} />
        <Route path="new-password" element={<Login />} />
        <Route path="onboarding" element={<ProtectedOnboardingRoute />}>
          <Route index element={<Onboarding />} />
        </Route>
        <Route path="enterprise-dashboard" element={<ProtectedEmployerRoute />}>
          <Route index element={<EnterpriseDashboard />} />
          <Route path="schedule" element={<EmployerSchedule />} />
          <Route path="grow" element={<EmployerCareerDevelopment />} />
          <Route path="search" element={<EnterpriseSearch />} />
        </Route>
        <Route path="employer-dashboard" element={<ProtectedEmployerRoute />}>
          <Route index element={<EmployerJobs />} />
          <Route path="profile" element={<EmployerProfile />} />
          <Route path="plans" element={<EmployerPlans />} />
          <Route path=":id" element={<EmployerJobDetails />}>
            <Route path="match" />
            <Route path="approved" />
            <Route path="starred" />
            <Route path="rejected" />
          </Route>
          <Route path=":id/edit" element={<JobPosting />} />
          <Route path="new-job" element={<JobPosting />} />
        </Route>
        <Route path="employee-dashboard" element={<ProtectedEmployeeRoute />}>
          <Route index element={<EmployeeDashboard />} />
          <Route path="profile" element={<EmployeeProfile />} />
          <Route path="career-development" element={<EmployeeDashboard />} />
          <Route path=":id" element={<EmployeeDashboard />} />
          <Route path="career-progress" element={<EmployeeCareerProgress />}>
            <Route path="skills" element={<EmployeeProgressSkills />} />
            <Route path="connections" element={<EmployeeProgressConnections />} />
            <Route path="still-hired" element={<EmployeeStillHired />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function AppRouter() {
  const { setExternalReferrer } = useAuth();

  useEffect(() => {
    setExternalReferrer(document.referrer);
  }, []);

  return (
    <BrowserRouter>
      <AppRoutes />
      <MobileAppBanner />
    </BrowserRouter>
  );
}

AppRouter.propTypes = {};

export default AppRouter;
