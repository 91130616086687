import React from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { useForm } from 'components/form';
import { Button, PhoneInput, Spinner } from 'components/shared';
import { unformatPhone } from 'utils';
import { SET_EMPLOYER_PHONE } from 'api';

const NAME = 'phone';

function Phone({ form, onChange, onGoToNextStep }) {
  const [postPhone, { loading }] = useMutation(SET_EMPLOYER_PHONE);
  const { $, set, withValidation } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: ['presence', 'phone']
    }
  });

  const handleFieldChange = (field) => (e) => {
    const unformattedValue = unformatPhone(e.target.value);
    set(field, unformattedValue);
    onChange({ ...form, [field]: unformattedValue });
  };

  const next = () => {
    onGoToNextStep(
      withValidation((attrs) => postPhone({ variables: { [NAME]: unformatPhone(attrs[NAME]) } }))
    );
  };

  return (
    <>
      <PhoneInput
        id="phone"
        {...$(NAME)}
        type="tel"
        placeholder="1-123-456-7890"
        autoComplete="off"
        required
        withHelperText
        highlightInputOnError={false}
        disabled={loading}
        sx={{ mb: '20px' }}
        analyticParams={{
          key: 'Employer phone focused (onboarding)',
          trigger: 'focus'
        }}
        onChange={handleFieldChange(NAME)}
        testID="onboarding-phone-input"
      />
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        sx={{ height: 50, width: '100%', mb: '25px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

Phone.propTypes = {
  form: PT.objectOf(PT.any).isRequired,
  onGoToNextStep: PT.func.isRequired,
  onChange: PT.func.isRequired
};

export default Phone;
