import React, { useState, useEffect, useCallback, useMemo, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import { useNavigate, useParams, useMatch, useLocation, Navigate } from 'react-router-dom';
import { findIndex, isEmpty, get, pullAt, debounce, filter, trim, map } from 'lodash';
import { getRoutes, getUserId, qaAttr, urlWithHttp, TIME_SLOT_TO_EMPLOYEE_TZ } from 'utils';
import { gtmTrackJobReject, gtmTrackJobAccept, gtmTrackJobStar } from 'utils/gtm';
import { track } from 'utils/segmentAnalytics';
import { usePrevious, useTimeZone, useCommonUI, useAuth } from 'hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  InputAdornment,
  Popover,
  MuiRadioGroup,
  MuiRadio,
  styled,
  useMediaQuery
} from 'components';
import {
  Autocomplete,
  Button,
  ButtonBase,
  EmployeeInterviewScheduleModal,
  IconButton,
  Spinner
} from 'components/shared';
import {
  ConfirmationDialog,
  EmployerPromptDialog,
  FiltersModal,
  EmployeeInterviewCancelAlert,
  EmployeeInterviewRescheduleAlert
} from 'components/dialogs';
import {
  MdAdd,
  MdArrowDropDown,
  MdClose,
  MdDisplaySettings,
  MdExpandMore,
  MdGridOn,
  MdCareerDevInsights,
  MdMenu,
  MdSearch
} from 'components/icons';
import {
  AllJobs,
  EmployeeDashboardManual,
  EmployeeDashboardMobileManual,
  MyJobs
} from 'components/Dashboard/employee';
import CareerDevelopment from 'components/Grow/employee';
import {
  APPROVE_JOB,
  AUTOCOMPLETE_ALL_JOBS_SEARCH,
  AUTOCOMPLETE_MY_JOBS_SEARCH,
  GET_EMPLOYEE_JOBS,
  GET_MY_JOBS,
  GET_USER_PROFILE,
  VIEWED_JOB,
  POST_EMPLOYEE_REFERRAL
} from 'api';
import styles from 'styles/Dashboard/EmployeeDashboard';
import EmployeeJob from './EmployeeJob';

const DashboardRoot = styled('div')(styles);

const ROUTES = getRoutes();
const CANCEL_LIMIT = 1; // max 2 per week
const ALL_JOBS = 'All Jobs';
const FAVOURITES = 'Favorites';
const PAGE_LIMIT = 10;

const applyAlertModalDefault = {
  applyAlertModal: false,
  onApplyAlertModalConfirm: () => {}
};
const webLinkModalDefault = {
  webLinkModal: false,
  webUrl: '',
  onWebLinkModalConfirm: () => {}
};
const questionModalDefault = {
  questionsModal: false,
  questions: [],
  employerName: '',
  onQuestionsAccept: () => {}
};
const interviewModalsDefault = {
  cancelInterviewAlert: false,
  rescheduleAlert: false,
  scheduleModal: false
};
const modalsConfigDefault = {
  ...applyAlertModalDefault,
  ...webLinkModalDefault,
  ...questionModalDefault,
  ...interviewModalsDefault,
  searchFilterModal: false,
  opener: '',
  isSwiped: false,
  isDeckCard: false,
  job: {}
};

function getIdxToFetchMoreFrom(jobs = [], offset = 0) {
  if (offset && offset > 5) return offset - 3;
  if (offset) return offset - 1;
  if (jobs.length && jobs.length > 5) return jobs.length - 3;
  if (jobs.length) return jobs.length - 1;
  return null;
}

function clearEmployeeJobsFromCache(client) {
  const modified = client.cache.modify({
    fields: {
      employeeJobs() {
        return [];
      }
    },
    broadcast: false
  });
  client.cache.gc();
}

function getFilledQuestions(questions = []) {
  return questions.length ? filter(questions, (q) => q.name && trim(q.name).length > 0) : [];
}

function rejectedIdsSize(rejectedIds = {}) {
  return Object.values(rejectedIds).filter(Boolean).length;
}

function EmployeeDashboard(props) {
  // const { path: matchPath } = useMatch();
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const prevIsDesktop = usePrevious(isDesktop, { withInitValue: true });
  const navigate = useNavigate();
  const location = useLocation();
  const { id: jobIdParam } = useParams();
  const client = useApolloClient();
  const { authed: isAuthenticated, externalReferrer } = useAuth();
  const { employeeJobsTab: jobsTab } = useCommonUI();
  const { jobId: historyJobId, action } = location?.state || {};
  const historyJobIdNum = Number(historyJobId);

  const prevPathName = usePrevious(location.pathname);

  const [acceptJob, { loading: acceptLoading = false }] = useMutation(APPROVE_JOB);
  const [rejectJob, { loading: rejectLoading = false }] = useMutation(APPROVE_JOB);
  const [starJob, { loading: starLoading = false }] = useMutation(APPROVE_JOB);
  const [returnJob, { loading: returnLoading = false }] = useMutation(APPROVE_JOB);
  const [postEmployeeReferral] = useMutation(POST_EMPLOYEE_REFERRAL);

  const [isRookieManualShown, setIsRookieManualShown] = useState(false);
  // modals
  const [modalsConfig, setModalsConfig] = useState(modalsConfigDefault);
  // all jobs page
  const [jobs, setJobs] = useState([]);
  const [rejectedIds, setRejectedIds] = useState({});
  const [acceptedIds, setAcceptedIds] = useState({});
  const [cardIndex, setCardIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [paginationEnabled, setPaginationEnabled] = useState(false);
  const [idxToFetchMoreFrom, setIdxToFetchMoreFrom] = useState(0);
  const [currDeckHeight, setCurrDeckHeight] = useState(100);
  const [isJobsInitFetch, setIsJobsInitFetch] = useState(true);
  const [filterSettings, setFilterSettings] = useState({ skills: [], industries: [] });
  // my jobs page
  const [myJobs, setMyJobs] = useState([]);
  const [myJobsFilter, setMyJobsFilter] = useState(ALL_JOBS);
  const [tableLayout, setTableLayout] = useState('list');
  const [myJobOptionsPopup, setMyJobOptionsPopup] = useState({ index: null, anchorEl: null });
  const [myJobsFilterPopupAnchor, setMyJobsFilterPopupAnchor] = useState(null);
  // search
  const [searchQuery, setSearchQuery] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  // actions state
  const [returnBtnDisabled, setReturnBtnDisabled] = useState(false);
  const [acceptBtnDisabled, setAcceptBtnDisabled] = useState(false);
  const [rejectBtnDisabled, setRejectBtnDisabled] = useState(false);
  const [starBtnDisabled, setStarBtnDisabled] = useState(false);
  // loaders
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const isJobApproving = acceptLoading || rejectLoading || starLoading || returnLoading;
  const isRetractDisabled =
    returnBtnDisabled || isJobApproving || (jobsTab === 0 && !cardIndex) || isPaginationLoading;
  const isRejectDisabled = rejectBtnDisabled || isJobApproving || isPaginationLoading;
  const isAcceptDisabled = acceptBtnDisabled || isJobApproving || isPaginationLoading;
  const isStarDisabled = starBtnDisabled || isJobApproving || isPaginationLoading;

  const debouncedAllJobsSearch = useRef();
  const debouncedMyJobsSearch = useRef();
  const deckRef = useRef();
  const autocompleteInputRef = useRef();

  const isCareerDevelopmentAllowed = process.env.ALLOW_CAREER_DEVELOPMENT === 'true';
  const isCareerDevelopment =
    isCareerDevelopmentAllowed && location.pathname === ROUTES.employee.grow;
  const isDashboard = location.pathname === ROUTES.employee.dashboard;
  const isEmployeeProfile = location.pathname === ROUTES.employee.profile;
  const matchJob = useMatch(ROUTES.employee.job);
  const isJobPage = !isCareerDevelopment && !isEmployeeProfile && !!matchJob;

  const [
    getEmployeeJobs,
    { data: allJobsData = {}, error = {}, loading = true, fetchMore, called: getEmployeeJobsCalled }
  ] = useLazyQuery(GET_EMPLOYEE_JOBS, {
    variables: { offset: 0, limit: PAGE_LIMIT },
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  });
  const [
    getMyJobs,
    {
      data: myJobsData = [],
      error: myJobsError = {},
      loading: myJobsLoading = true,
      called: getMyJobsCalled
    }
  ] = useLazyQuery(GET_MY_JOBS, {
    variables: { query: '' },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  const [viewedJob, { loading: viewLoading = false }] = useMutation(VIEWED_JOB);

  const getFilteringVariables = useCallback(() => {
    const { skills, industries } = filterSettings;
    return {
      skills: skills.length ? map(skills, (o) => o.skill_id).join(', ') : '',
      industry: industries.length ? map(industries, (o) => o.industry_id).join(', ') : ''
    };
  }, [JSON.stringify(filterSettings)]);

  function fetchAllJobsFromScratch() {
    clearEmployeeJobsFromCache(client);
    setJobs([]);
    setCardIndex(0);
    setOffset(0);
    setIsJobsInitFetch(true);
    setPaginationEnabled(false);
    setRejectedIds({});
    setAcceptedIds({});
    setSearchQuery('');
    getEmployeeJobs({ variables: { offset: 0, limit: PAGE_LIMIT, ...getFilteringVariables() } });
  }

  const disableAllActions = useCallback((disable = true) => {
    setReturnBtnDisabled(disable);
    setAcceptBtnDisabled(disable);
    setRejectBtnDisabled(disable);
    setStarBtnDisabled(disable);
  }, []);

  const loadMoreJobs = useCallback(
    (curIdx) => {
      // Some bug in fetchMore doesn't change loading in useLazyQuery, so locale state is used
      const paginate = !isDesktop ? idxToFetchMoreFrom && curIdx === idxToFetchMoreFrom : true;

      if (paginationEnabled && paginate) {
        setIsPaginationLoading(true);
        fetchMore({
          variables: {
            offset,
            limit: PAGE_LIMIT
          }
        }).then((fetchMoreResult) => {
          const incoming = fetchMoreResult?.data?.employeeJobs || [];
          const total = getAllJobsTotal(incoming); // will change if some jobs were applied
          const prevTotalJobs = isDesktop
            ? jobs.length + rejectedIdsSize(rejectedIds)
            : jobs.length; // rejected jobs are removed from array but it has no effect on total from backend, so jobs.length + rejectedIds.size is used
          setPaginationEnabled(incoming.length + prevTotalJobs < total);
        });
      }
    },
    [
      fetchMore,
      offset,
      isDesktop,
      paginationEnabled,
      jobs.length,
      idxToFetchMoreFrom,
      JSON.stringify(rejectedIds)
    ]
  );

  const handleAllJobsSearch = useCallback(async (query, setSuggestions, searchReason) => {
    const searchRes = await client.query({
      query: AUTOCOMPLETE_ALL_JOBS_SEARCH,
      variables: { query },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    if (searchRes?.data?.allJobsSearch) setSuggestions(searchRes.data.allJobsSearch);
  }, []);

  const handleMyJobsSearch = useCallback(
    async (query, setSuggestions, searchReason) => {
      const searchRes = await client.query({
        query: AUTOCOMPLETE_MY_JOBS_SEARCH,
        variables: {
          query: JSON.stringify({ search: query, selectedValue: myJobsFilter })
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      if (searchRes?.data?.myJobsSearch) setSuggestions(searchRes.data.myJobsSearch);
    },
    [myJobsFilter]
  );

  const handleAllJobsSuggestionSelect = useCallback(async (e, params) => {
    const { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } = params;
    const searchRes = await client.query({
      query: AUTOCOMPLETE_ALL_JOBS_SEARCH,
      variables: { query: suggestionValue },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    if (searchRes?.data?.allJobsSearch) setJobs(searchRes.data.allJobsSearch);
    if (autocompleteInputRef.current) autocompleteInputRef.current.blur();
  }, []);

  const handleMyJobsSuggestionSelect = useCallback(
    async (e, params) => {
      const { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } = params;
      const myJobs = await client.query({
        query: GET_MY_JOBS,
        variables: {
          query: JSON.stringify({ search: suggestionValue, selectedValue: myJobsFilter })
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      if (myJobs?.data?.myJobs) setMyJobs(filter(myJobs.data.myJobs, (j) => j?.jobs?.active));
      if (autocompleteInputRef.current) autocompleteInputRef.current.blur();
    },
    [myJobsFilter]
  );

  const shouldRenderSuggestions = useCallback((value) => {
    return value.trim().length > 2;
  }, []);

  const handleSearchChange = useCallback((e, { newValue }) => setSearchQuery(newValue), []);
  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    if (jobsTab === 0) fetchAllJobsFromScratch();
    else
      getMyJobs({
        variables: { query: JSON.stringify({ search: '', selectedValue: myJobsFilter }) }
      });
  }, [jobsTab, myJobsFilter, fetchAllJobsFromScratch]);

  const getAuth = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const isUserInstructed = localStorage.getItem('user_instructed');

    if (token && !isEmpty(userId)) {
      const employeeProfile = await client.query({
        query: GET_USER_PROFILE,
        variables: {
          userId: parseInt(userId)
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      localStorage.setItem('profileId', employeeProfile?.data?.employeeProfile?.profile_id);

      if (!isUserInstructed) setIsRookieManualShown(true);
    }
  };

  const completeManual = useCallback(() => {
    localStorage.setItem('user_instructed', 'true');
    setIsRookieManualShown(false);
  }, []);

  const handleJobSelect = useCallback(
    async (
      selectedId
      // starred = false,
      // restParams = { userInterviewDate: null, approved: false }
    ) => {
      if (selectedId) {
        viewedJob({
          variables: { jobId: selectedId }
        });
        track('Vacancy Viewed');
        navigate(getRoutes({ id: selectedId }).employee.job, {
          state: { employeeDashboardTab: jobsTab }
        });
      }
    },
    [jobsTab]
  );

  const handleJobApply = useCallback(
    async (job) => {
      const jobId = job?.id;
      const cardIndex = findIndex(jobs, ['id', jobId]);
      const uid = getUserId();

      if (!isDesktop) {
        const canSwipe = !modalsConfig.isSwiped && deckRef.current?.swipeRight && cardIndex !== -1;
        if (canSwipe) deckRef.current.swipeRight(cardIndex);
        setCardIndex(cardIndex + 1);
      }

      try {
        if (jobId) {
          const res = await acceptJob({
            variables: {
              jobId: Number(jobId),
              approved: true,
              passed: false
            }
          });
          if (!res?.data?.errors && !res?.errors && job?.employerProfileProfileId) {
            gtmTrackJobAccept(job.employerProfileProfileId);

            if (uid && externalReferrer) {
              try {
                postEmployeeReferral({
                  variables: {
                    jobId: Number(jobId),
                    employeeUserId: Number(uid),
                    referralUrl: externalReferrer
                  }
                });
              } catch (postEmployeeReferralErr) {
                console.error(postEmployeeReferralErr);
              }
            }
          }

          if (jobsTab === 0) {
            const nextAcceptedIds = { ...acceptedIds };
            nextAcceptedIds[jobId] = { id: jobId };
            setAcceptedIds(nextAcceptedIds);
          }
          if (!isDesktop) {
            loadMoreJobs(cardIndex + 1);
            disableAllActions(false);
          }
        }
      } catch (error) {
        console.error('handleJobApply error: ', error);
      } finally {
        disableAllActions(false);
      }
    },
    [
      JSON.stringify(jobs),
      JSON.stringify(modalsConfig),
      JSON.stringify(acceptedIds),
      cardIndex,
      isDesktop,
      handleSearchClear,
      loadMoreJobs
    ]
  );

  const handleJobReject = useCallback(
    async (job) => {
      const jobId = job?.id;

      if (!isDesktop) {
        const canSwipe = !modalsConfig.isSwiped && deckRef.current?.swipeLeft && cardIndex !== -1;
        if (canSwipe) deckRef.current.swipeLeft(cardIndex);
        setCardIndex(cardIndex + 1);
      }
      // Close modals
      manageModalsConfig(modalsConfigDefault);

      try {
        if (jobId) {
          const res = await rejectJob({
            variables: {
              jobId: jobId,
              approved: false,
              passed: true
            }
          });
          if (!res?.data?.errors && !res?.errors && job?.employerProfileProfileId) {
            gtmTrackJobReject(job.employerProfileProfileId);
          }

          if (jobsTab === 0) {
            const nextRejectedIds = { ...rejectedIds };
            nextRejectedIds[jobId] = { id: jobId };
            setRejectedIds(nextRejectedIds);
          }

          if (isDesktop) {
            // modifying jobs array on mobile will cause resetting currentIndex in <Deck> component
            const nextJobs = [...jobs];
            const curJobIdx = findIndex(nextJobs, ['id', jobId]);
            pullAt(nextJobs, curJobIdx);
            if (nextJobs.length) setJobs(nextJobs);
            else handleSearchClear(); // refresh if no jobs
          } else {
            loadMoreJobs(cardIndex + 1);
            disableAllActions(false);
          }
        }
      } catch (error) {
        console.error('handleJobReject error: ', error);
      } finally {
        disableAllActions(false);
      }
    },
    [
      JSON.stringify(jobs),
      JSON.stringify(modalsConfig),
      JSON.stringify(rejectedIds),
      cardIndex,
      isDesktop,
      handleSearchClear,
      loadMoreJobs
    ]
  );

  const handleStar = useCallback(
    async (job, starred = false) => {
      const jobId = job?.id;
      const cardIndex = findIndex(jobs, ['id', jobId]);
      disableAllActions();

      if (!isDesktop) {
        const canSwipe = deckRef.current?.swipeRight && cardIndex !== -1;
        if (canSwipe) deckRef.current.swipeRight(cardIndex);
        setCardIndex(cardIndex + 1);
      }

      try {
        if (jobId) {
          if (jobsTab === 0) {
            const res = await starJob({
              variables: {
                jobId: jobId,
                passed: false,
                approved: false,
                starred: true
              }
            });
            if (job?.employerProfileProfileId) {
              gtmTrackJobStar(job.employerProfileProfileId);
            }
            if (!isDesktop) {
              loadMoreJobs(cardIndex + 1);
              disableAllActions(false);
            }
          } else {
            const approveJobData = await acceptJob({
              variables: {
                jobId: jobId,
                passed: false,
                approved: true,
                starred
              }
            });
            if (myJobOptionsPopup.anchorEl) setMyJobOptionsPopup({ index: null, anchorEl: null });
            if (!starred && myJobsFilter === FAVOURITES) {
              handleSearchClear();
            } else {
              getMyJobs({
                variables: {
                  query: JSON.stringify({ search: searchQuery, selectedValue: myJobsFilter })
                }
              });
            }
          }
          disableAllActions(false);
        }
      } catch (error) {
        console.error('handleStar error: ', error);
      } finally {
        disableAllActions(false);
      }
    },
    [
      JSON.stringify(jobs),
      cardIndex,
      jobsTab,
      searchQuery,
      myJobsFilter,
      myJobOptionsPopup.anchorEl,
      handleSearchClear,
      loadMoreJobs,
      isDesktop
    ]
  );

  const confirmRetract = useCallback(
    async (job, isDeckCard = false, isInterviewCancel = false) => {
      const jobId = job?.id;
      disableAllActions();

      try {
        if (jobId) {
          const response = await returnJob({
            variables: {
              jobId: jobId,
              approved: false,
              passed: true
            }
          });

          // if (!res?.data?.errors && !res?.errors) {
          if (!isDesktop) setCardIndex(cardIndex - 1);
          if (isDeckCard) deckRef.current.swipeBack();
          disableAllActions(false);
          // }

          if (jobsTab === 1) {
            // close popup on desktop
            setMyJobOptionsPopup({ index: null, anchorEl: null });
            // refresh jobs
            handleSearchClear();
          } else {
            if (isInterviewCancel) {
              const { scheduleStatusCount, isSchedule } = response?.data?.approveJob || {};
              const jobIdx = findIndex(jobs, ['id', jobId]);
              if (jobIdx !== -1) {
                const updJob = { ...jobs[jobIdx], scheduleStatusCount, isSchedule };
                const updJobs = [...jobs.slice(0, jobIdx), updJob, ...jobs.slice(jobIdx + 1)];
                setJobs(updJobs);
              }
            }

            if (acceptedIds[jobId]) {
              const nextAcceptedIds = { ...acceptedIds };
              nextAcceptedIds[jobId] = undefined;
              setAcceptedIds(nextAcceptedIds);
            }
            if (rejectedIds[jobId]) {
              const nextRejectedIds = { ...rejectedIds };
              nextRejectedIds[jobId] = undefined;
              setRejectedIds(nextRejectedIds);
            }
          }
        }
        disableAllActions(false);
      } catch (error) {
        disableAllActions(false);
        console.error('handleRetract error: ', error);
      }
    },
    [
      JSON.stringify(jobs),
      JSON.stringify(rejectedIds),
      JSON.stringify(acceptedIds),
      cardIndex,
      jobsTab,
      handleSearchClear
    ]
  );

  const handleRetract = useCallback(
    (job, isDeckCard = false, interviewAppointed = false) => {
      if (interviewAppointed) {
        manageModalsConfig({ cancelInterviewAlert: true, job, isDeckCard });
      } else {
        confirmRetract(job, isDeckCard, false);
      }
    },
    [confirmRetract]
  );

  const confirmInterviewCanceling = useCallback(() => {
    const { job, isDeckCard } = modalsConfig;
    const scheduleStatusCount = job?.scheduleStatusCount || 0;

    if (scheduleStatusCount >= CANCEL_LIMIT) {
      manageModalsConfig({ cancelInterviewAlert: false, rescheduleAlert: true, job, isDeckCard });
    } else {
      closeInterviewCancelAlert();
      confirmRetract(job, isDeckCard, true);
    }
  }, [
    modalsConfig.cancelInterviewAlert,
    modalsConfig.isDeckCard,
    JSON.stringify(modalsConfig.job),
    closeInterviewCancelAlert,
    confirmRetract
  ]);

  const confirmRescheduleAlert = useCallback(() => {
    const { job, isDeckCard } = modalsConfig;
    manageModalsConfig({
      cancelInterviewAlert: false,
      rescheduleAlert: false,
      job: {},
      isDeckCard: false
    });
    confirmRetract(job, isDeckCard, true);
  }, [modalsConfig.isDeckCard, JSON.stringify(modalsConfig.job), confirmRetract]);

  const manageModalsConfig = useCallback((newConfig = {}) => {
    setModalsConfig((prev) => ({ ...prev, ...newConfig }));
  }, []);

  const onInterviewAccept = useCallback(
    (date, jobId) => {
      const idx = findIndex(jobs, ['id', jobId]);
      if (idx !== -1 && jobs?.[idx]?.webUrl) showWebUrlModal(jobs[idx].webUrl);

      if (isDesktop) {
        const nextAcceptedIds = { ...acceptedIds };
        nextAcceptedIds[jobId] = { id: jobId };
        setAcceptedIds(nextAcceptedIds);
      } else {
        setCardIndex(cardIndex + 1);
        disableAllActions(false);
        manageModalsConfig({ scheduleModal: false, job: {} });
      }
    },
    [cardIndex, isDesktop, JSON.stringify(acceptedIds), JSON.stringify(jobs)]
  );

  const openInterviewScheduleModal = useCallback((job) => {
    if (job?.id) {
      manageModalsConfig({ scheduleModal: true, job });
    }
  }, []);
  const closeInterviewScheduleModal = useCallback(() => {
    manageModalsConfig({ scheduleModal: false, job: {} });
    deckRef.current.swipeBack();
    disableAllActions(false);
  }, []);

  const closeInterviewCancelAlert = useCallback(() => {
    manageModalsConfig({ cancelInterviewAlert: false, job: {}, isDeckCard: false });
  }, []);

  const closeRescheduleAlert = useCallback(() => {
    manageModalsConfig({ rescheduleAlert: false, job: {}, isDeckCard: false });
  }, []);

  const closeEmployerPromptDialog = useCallback(() => {
    if (!isDesktop && modalsConfig.isSwiped) deckRef.current.swipeBack();
    manageModalsConfig({ ...questionModalDefault, job: {}, isSwiped: false });
    disableAllActions(false);
  }, [isDesktop, modalsConfig.isSwiped]);

  const closeJobApplyAlert = useCallback(() => {
    if (!isDesktop && modalsConfig.isSwiped) deckRef.current.swipeBack();
    manageModalsConfig({ applyAlertModal: false, job: {}, isSwiped: false });
    disableAllActions(false);
  }, [isDesktop, modalsConfig.isSwiped]);

  const closeWebUrlModal = useCallback(() => {
    manageModalsConfig({ ...webLinkModalDefault });
  }, []);

  const showWebUrlModal = useCallback((url) => {
    manageModalsConfig({
      webLinkModal: true,
      webUrl: url,
      onWebLinkModalConfirm: () => {
        window.open(urlWithHttp(url), '__blank', 'noopener, noreferrer');
      }
    });
  }, []);

  const closeModalsAfterApply = () => {
    manageModalsConfig({
      ...applyAlertModalDefault,
      ...questionModalDefault,
      ...interviewModalsDefault,
      job: {}
    });
  };

  const handleApply = useCallback(
    (job, params = {}) => {
      const { isSwiped = false, onQuestionsAccept, showSchedule } = params;
      // look at showSchedule in AllJobs.jsx
      const filledQuestions = getFilledQuestions(job?.questions);
      const common = {
        employerName: job?.employerProfile?.name,
        isSwiped,
        job
      };

      if (job.isSchedule) {
        if (filledQuestions.length) {
          manageModalsConfig({
            ...common,
            questionsModal: true,
            questions: filledQuestions,
            onQuestionsAccept: (_, answers) => {
              manageModalsConfig({ ...questionModalDefault });
              disableAllActions();
              // apply
              if (answers.filter((a) => !a).length) {
                if (job.webUrl) showWebUrlModal(job.webUrl);
                handleJobApply(job);
                closeModalsAfterApply();
              } else {
                showSchedule();
              }
            }
          });
        } else {
          disableAllActions();
          // apply
          showSchedule();
        }
      } else {
        manageModalsConfig({
          ...common,
          applyAlertModal: true,
          onApplyAlertModalConfirm: () => {
            if (filledQuestions.length) {
              manageModalsConfig({
                questionsModal: true,
                questions: filledQuestions,
                onQuestionsAccept: (_, answers) => {
                  manageModalsConfig({ ...questionModalDefault });
                  disableAllActions();
                  if (job.webUrl) showWebUrlModal(job.webUrl);
                  // apply
                  handleJobApply(job);
                  closeModalsAfterApply();
                }
              });
            } else {
              disableAllActions();
              if (job.webUrl) showWebUrlModal(job.webUrl);
              // apply
              handleJobApply(job);
              closeModalsAfterApply();
            }
          }
        });
      }
    },
    [JSON.stringify(modalsConfig), handleJobApply, showWebUrlModal]
  );

  const openJobOptions = useCallback((index) => {
    return (e) => setMyJobOptionsPopup({ index, anchorEl: e.currentTarget });
  }, []);

  const closeJobOptions = useCallback(() => {
    setMyJobOptionsPopup({ index: null, anchorEl: null });
  }, []);

  const openFilterModal = useCallback(() => {
    manageModalsConfig({ searchFilterModal: true });
  });

  const closeFilterModal = useCallback(() => {
    manageModalsConfig({ searchFilterModal: false });
  }, []);

  const changeFilterSettings = useCallback(({ skills = [], industries = [] }) => {
    setFilterSettings({ skills, industries });
    closeFilterModal();
  }, []);

  useEffect(() => {
    if (isAuthenticated) getAuth();
  }, []);

  useEffect(() => {
    fetchAllJobsFromScratch();
  }, [JSON.stringify(filterSettings)]);

  useEffect(() => {
    const allowedActions = ['pass', 'accept', 'accept_interview', 'retract'];
    if (isDashboard && isAuthenticated) {
      if (jobsTab === 0 && prevPathName === ROUTES.employee.profile) fetchAllJobsFromScratch();

      if (jobsTab === 1) {
        // refresh myjJobs after return from job page
        getMyJobs();
      } else if (jobsTab === 0 && historyJobIdNum) {
        const nextRejectedIds = { ...rejectedIds };
        const nextAcceptedIds = { ...acceptedIds };

        if (isDesktop) {
          // scroll to card
          const card = document.querySelector(`[data-card="${historyJobIdNum}"]`);
          if (card) card.scrollIntoView({ behavior: 'smooth' });
        }

        if (allowedActions.indexOf(action !== -1)) {
          // delete card from list, highlight accepted card or swipe on mobile depending on performed action

          if (
            !isDesktop &&
            jobs[cardIndex]?.id === historyJobIdNum &&
            (action === 'accept' || action === 'accept_interview' || action === 'pass')
          ) {
            // swipe card on mobile
            setCardIndex(cardIndex + 1);
            deckRef.current?.jumpToCardIndex(cardIndex + 1);
            loadMoreJobs(cardIndex + 1);
          }

          if (action === 'accept' || action === 'accept_interview') {
            nextAcceptedIds[historyJobIdNum] = { id: historyJobIdNum };
            setAcceptedIds(nextAcceptedIds);
          } else if (action === 'pass') {
            nextRejectedIds[historyJobIdNum] = { id: historyJobIdNum };
            setRejectedIds(nextRejectedIds);

            if (isDesktop) {
              // delete card on desktop
              const nextJobs = [...jobs];
              const curJobIdx = findIndex(nextJobs, ['id', historyJobIdNum]);
              pullAt(nextJobs, curJobIdx);
              if (nextJobs.length) setJobs(nextJobs);
              else handleSearchClear(); // refresh if no jobs
            }
          } else if (action === 'retract') {
            if (nextAcceptedIds[historyJobIdNum]) {
              nextAcceptedIds[historyJobIdNum] = undefined;
              setAcceptedIds(nextAcceptedIds);
            }
            if (nextRejectedIds[historyJobIdNum]) {
              nextRejectedIds[historyJobIdNum] = undefined;
              setRejectedIds(nextRejectedIds);
            }
          }
        }

        navigate(location.pathname, { replace: true, state: {} }); // clear navigate state
      }
    }
  }, [location]);

  useEffect(() => {
    if (prevIsDesktop !== isDesktop && isAuthenticated) {
      if (jobsTab === 0) {
        // refetch jobs on resolution change
        fetchAllJobsFromScratch();
      } else {
        setTableLayout('list');
      }
    }
  }, [isDesktop]);

  useEffect(() => {
    debouncedAllJobsSearch.current = debounce(handleAllJobsSearch, 500);
  }, [handleAllJobsSearch]);

  useEffect(() => {
    debouncedMyJobsSearch.current = debounce(handleMyJobsSearch, 500);
  }, [handleMyJobsSearch]);

  useEffect(() => {
    if (isAuthenticated) {
      disableAllActions(false);
      setSearchQuery('');
      if (jobsTab === 0) {
        fetchAllJobsFromScratch();
      } else if (jobsTab === 1) {
        setMyJobsFilter(ALL_JOBS);
        getMyJobs();
      }
    }
  }, [jobsTab]);

  useEffect(() => {
    if (
      isAuthenticated &&
      jobsTab === 0 &&
      !isJobsInitFetch &&
      jobs.length &&
      cardIndex >= jobs.length
    ) {
      fetchAllJobsFromScratch();
    }
  }, [cardIndex]);

  useEffect(() => {
    let fetchedJobs =
      map(allJobsData?.employeeJobs || [], ({ __typename, ...o }) => ({ ...o })) || [];

    if (fetchedJobs.length) {
      if (!isDesktop) {
        setIdxToFetchMoreFrom((prevIdx) => {
          const idx = getIdxToFetchMoreFrom(fetchedJobs, fetchedJobs.length);
          return idx < 0 ? 0 : idx <= cardIndex ? fetchedJobs.length - 1 : idx;
        });
      } else {
        fetchedJobs = map(
          filter(fetchedJobs, (o) => !rejectedIds[o.id]),
          (o) => {
            // if (acceptedIds.has(o.id)) o._applied = true;
            return o;
          }
        );
      }

      setOffset(fetchedJobs.length);
      setJobs(fetchedJobs);

      if (isJobsInitFetch) {
        setIsJobsInitFetch(false);
        setPaginationEnabled(getAllJobsTotal(fetchedJobs) > PAGE_LIMIT);
      }
    }
    setIsPaginationLoading(false);
  }, [JSON.stringify(allJobsData)]);

  useEffect(() => {
    if (getMyJobsCalled) {
      const fetchedJobs = myJobsData?.myJobs || [];
      const active = filter(fetchedJobs, (j) => j?.jobs?.active);
      setMyJobs(active);
    }
  }, [JSON.stringify(myJobsData)]);

  useEffect(() => {
    if (jobsTab === 1 && isAuthenticated)
      getMyJobs({
        variables: { query: JSON.stringify({ search: searchQuery, selectedValue: myJobsFilter }) }
      });
  }, [myJobsFilter]);

  function getAllJobsTotal(jobs = []) {
    return jobs.length && jobs[0].hasOwnProperty('total') ? jobs[0].total : 0;
  }

  function openMyJobsFilterMenu(e) {
    setMyJobsFilterPopupAnchor(e.currentTarget);
  }

  function closeMyJobsFilterMenu() {
    return setMyJobsFilterPopupAnchor(null);
  }

  function changeTableLayout(e) {
    setTableLayout(e.target.value);
  }

  function changeMyJobsFilter(value) {
    return () => {
      setMyJobsFilter(value);
      closeMyJobsFilterMenu();
    };
  }

  function showSearch() {
    setSearchVisible(true);
  }

  function hideSearch() {
    setSearchVisible(false);
  }

  const deleteFilterOption = (option, filterName) => {
    let array = [...filterSettings[filterName]];
    array = array.filter(({ name }) => name !== option.name);
    setFilterSettings((prev) => ({ ...prev, [filterName]: array }));
  };

  const renderSelectedFilters = () => (
    <div className="filterOptionContainer">
      {map(filterSettings.skills, (obj, i) => (
        <div key={`skillsFilter__${i}`} className="filterOption">
          <span>{obj.name}</span>
          <IconButton
            aria-label={`remove ${obj.name} filter option`}
            className="filterOption__deleteBtn"
            onClick={() => deleteFilterOption(obj, 'skills')}
            testID={`remove-filter-by-${obj.name}`}
          >
            <MdClose fontSize="inherit" />
          </IconButton>
        </div>
      ))}
      {map(filterSettings.industries, (obj, i) => (
        <div key={`industriesFilter__${i}`} className="filterOption">
          <span>{obj.name}</span>
          <IconButton
            aria-label={`remove ${obj.name} filter option`}
            className="filterOption__deleteBtn"
            onClick={() => deleteFilterOption(obj, 'industries')}
            testID={`remove-filter-by-${obj.name}`}
          >
            <MdClose fontSize="inherit" />
          </IconButton>
        </div>
      ))}
      <IconButton
        variant="outlined"
        color="primary"
        aria-label="open filters modal"
        className="filterAddBtn"
        onClick={openFilterModal}
      >
        <MdAdd fontSize="inherit" />
      </IconButton>
    </div>
  );

  const renderDesktopSelectedFilters = () =>
    filterSettings.skills.length || filterSettings.industries.length
      ? renderSelectedFilters()
      : null;

  const renderMobileSelectedFilters = () => (
    <Box mb="10px">
      <Accordion square elevation={1}>
        <AccordionSummary
          expandIcon={<MdExpandMore fontSize="inherit" color="inherit" />}
          classes={{
            root: 'filtersAccordionHeader',
            content: 'filtersAccordionHeader__content',
            expandIcon: 'filtersAccordionHeader__expandIcon'
          }}
        >
          Filters
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'sectionBody' }}>
          {renderSelectedFilters()}
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  function renderSearch(withFilter) {
    return (
      <div className="autocompleteContainer">
        <Autocomplete
          key={jobsTab === 0 ? 'allJobsSearch' : 'myJobsSearch'}
          onFetch={jobsTab === 0 ? debouncedAllJobsSearch.current : debouncedMyJobsSearch.current}
          onSuggestionSelected={
            jobsTab === 0 ? handleAllJobsSuggestionSelect : handleMyJobsSuggestionSelect
          }
          shouldRenderSuggestions={shouldRenderSuggestions}
          nativeInputProps={{
            value: searchQuery,
            onChange: handleSearchChange,
            placeholder: 'Search by keyword',
            type: 'text',
            className: 'searchInput'
          }}
          inputCompProps={{
            inputRef: autocompleteInputRef,
            startAdornment: (
              <InputAdornment position="start" sx={{ color: 'rgba(0,0,0,0.5)' }}>
                <MdSearch color="inherit" />
              </InputAdornment>
            ),
            endAdornment:
              searchQuery.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={handleSearchClear}
                    testID="search-input-clear-button"
                  >
                    <MdClose color="inherit" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            FormControlProps: {
              sx: {
                flex: 1,
                '& .StyledInput-inputBase': { paddingLeft: '16px' }
              }
            },
            analyticParams: {
              key: 'Employee jobs search focused',
              trigger: 'focus'
            },
            testID: 'search-input'
          }}
          suggestionValPath={jobsTab === 0 ? 'title' : 'jobs.title'}
        />
        {withFilter && (
          <IconButton
            variant="outlined"
            color="primary"
            aria-label="open filters modal"
            sx={{ ml: '17px' }}
            onClick={openFilterModal}
            testID="search-filter-button"
          >
            <MdDisplaySettings />
          </IconButton>
        )}
        {jobsTab === 1 && renderMyJobsFilters()}
      </div>
    );
  }

  function renderQhGrowBanner() {
    return (
      process.env.ALLOW_CAREER_DEVELOPMENT === 'true' && (
        <Button
          isRouterLink
          to={ROUTES.employee.grow}
          endIcon={<MdCareerDevInsights />}
          className="qhGrowBanner"
          testID="qh-grow-button"
        >
          New! QH Grow
        </Button>
      )
    );
  }

  const renderMobileSearchWithGrowBanner = () => (
    <div className={`toggleContainer ${searchVisible ? 'toggleContainer_active' : ''}`}>
      {renderQhGrowBanner()}
      <div className="animatedSearchContainer">
        <IconButton className="searchToggleOutBtn" onClick={hideSearch}>
          <MdClose color="inherit" />
        </IconButton>
        <Autocomplete
          onFetch={debouncedAllJobsSearch.current}
          onSuggestionSelected={handleAllJobsSuggestionSelect}
          shouldRenderSuggestions={shouldRenderSuggestions}
          nativeInputProps={{
            value: searchQuery,
            onChange: handleSearchChange,
            placeholder: 'Search jobs',
            type: 'text',
            className: 'searchInput'
          }}
          inputCompProps={{
            inputRef: autocompleteInputRef,
            className: 'animatedSearch__input',
            testID: 'search-input'
          }}
          suggestionValPath="title"
        />
      </div>
      <IconButton
        className="searchToggleInBtn"
        onClick={() => {
          if (!searchVisible) showSearch();
          else if (searchQuery.length > 0) handleSearchClear();
        }}
      >
        {searchVisible && searchQuery.length > 0 ? (
          <MdClose color="inherit" />
        ) : (
          <MdSearch color="inherit" />
        )}
      </IconButton>
    </div>
  );

  function renderAllJobsSearch() {
    return (
      <>
        {isDesktop || (!isDesktop && process.env.ALLOW_CAREER_DEVELOPMENT !== 'true') ? (
          renderSearch(true)
        ) : (
          <Box px="16px">{renderMobileSearchWithGrowBanner()}</Box>
        )}
        {isDesktop ? renderDesktopSelectedFilters() : renderMobileSelectedFilters()}
        <FiltersModal
          isOpen={modalsConfig.searchFilterModal}
          onClose={closeFilterModal}
          onSubmit={changeFilterSettings}
          skills={filterSettings.skills}
          industries={filterSettings.industries}
        />
      </>
    );
  }

  function renderMyJobsSearch() {
    return (
      <>
        {!isDesktop && (
          <Box px="16px" mb="16px">
            {renderQhGrowBanner()}
          </Box>
        )}
        {renderSearch()}
      </>
    );
  }

  function renderAllJobs() {
    return (
      <AllJobs
        acceptedIds={acceptedIds}
        cardIndex={cardIndex}
        deckRef={deckRef}
        disableAllActions={disableAllActions}
        handleApply={handleApply}
        handleJobSelect={handleJobSelect}
        handleJobReject={handleJobReject}
        handleReturnPrevCard={handleRetract}
        handleStar={handleStar}
        isCardSwiped={!!deckRef.current?.isCardSwiped}
        isPaginationEnabled={paginationEnabled}
        isPaginationLoading={isPaginationLoading}
        isJobApproving={isJobApproving}
        isAcceptDisabled={isAcceptDisabled}
        isAcceptLoading={acceptLoading}
        isRejectDisabled={isRejectDisabled}
        isRejectLoading={rejectLoading}
        isRetractDisabled={isRetractDisabled}
        isRetractLoading={returnLoading}
        isStarDisabled={isStarDisabled}
        isStarLoading={starLoading}
        jobs={jobs}
        loading={loading}
        openInterviewScheduleModal={openInterviewScheduleModal}
        onInterviewAccept={onInterviewAccept}
        onLoadMore={loadMoreJobs}
        rejectedIds={rejectedIds}
        setCurrDeckHeight={setCurrDeckHeight}
        setRejectedIds={setRejectedIds}
        setAcceptedIds={setAcceptedIds}
      />
    );
  }

  function renderMyJobs() {
    return (
      <MyJobs
        closeJobOptions={closeJobOptions}
        handleJobSelect={handleJobSelect}
        handleRetract={handleRetract}
        handleStar={handleStar}
        myJobs={myJobs}
        myJobOptionsPopup={myJobOptionsPopup}
        openJobOptions={openJobOptions}
        tableLayout={tableLayout}
      />
    );
  }

  function renderMyJobsFilters() {
    return (
      <div className="myJobsFilterContainer">
        <Button
          endIcon={<MdArrowDropDown color="inherit" />}
          sx={{
            width: 91,
            padding: 0,
            color: 'primary.main',
            '& .StyledButton-endIcon': { marginLeft: 0, marginRight: 0 }
          }}
          onClick={openMyJobsFilterMenu}
          testID="my-jobs-filter-button"
        >
          {myJobsFilter}
        </Button>
        <Popover
          open={!!myJobsFilterPopupAnchor}
          anchorEl={myJobsFilterPopupAnchor}
          classes={{ paper: 'optionsPopupPaper' }}
          anchorOrigin={{
            vertical: 40,
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          disablePortal
          onClose={closeMyJobsFilterMenu}
        >
          <ButtonBase
            className="optionsPopupBtn"
            onClick={changeMyJobsFilter(ALL_JOBS)}
            {...qaAttr('all-jobs-filter-option-button')}
          >
            All Jobs
          </ButtonBase>
          <ButtonBase
            className="optionsPopupBtn"
            onClick={changeMyJobsFilter(FAVOURITES)}
            {...qaAttr('favorites-filter-option-button')}
          >
            Favorites
          </ButtonBase>
        </Popover>
        {isDesktop && (
          <MuiRadioGroup
            value={tableLayout}
            aria-label="table layout"
            row
            className="layoutRadioGroup"
            onChange={changeTableLayout}
          >
            <FormControlLabel
              value="list"
              control={
                <MuiRadio
                  color="primary"
                  icon={<MdMenu />}
                  checkedIcon={<MdMenu />}
                  inputProps={{
                    'aria-label': 'list layout',
                    ...qaAttr('my-jobs-list-layout-radio-input')
                  }}
                  {...qaAttr('my-jobs-list-layout-radio')}
                  classes={{ root: 'layoutRadio', checked: 'layoutRadio_checked' }}
                />
              }
            />
            <FormControlLabel
              value="grid"
              control={
                <MuiRadio
                  color="primary"
                  icon={<MdGridOn />}
                  checkedIcon={<MdGridOn />}
                  inputProps={{
                    'aria-label': 'grid layout',
                    ...qaAttr('my-jobs-grid-layout-radio-input')
                  }}
                  {...qaAttr('my-jobs-grid-layout-radio')}
                  classes={{ root: 'layoutRadio', checked: 'layoutRadio_checked' }}
                />
              }
            />
          </MuiRadioGroup>
        )}
      </div>
    );
  }

  function renderSearchSection() {
    if (jobsTab === 0) return renderAllJobsSearch();
    if (jobsTab === 1) return renderMyJobsSearch();
    return null;
  }

  function renderDashboardContent() {
    if (jobsTab === 0) return renderAllJobs();
    if (jobsTab === 1) return renderMyJobs();
    return null;
  }

  function renderDashboardPage() {
    return (
      <DashboardRoot>
        {isRookieManualShown && isDesktop && (
          <EmployeeDashboardManual
            isOpen={isRookieManualShown}
            jobExampleData={jobs?.[0]}
            onComplete={completeManual}
          />
        )}
        {isRookieManualShown && !isDesktop && (
          <EmployeeDashboardMobileManual
            isOpen={isRookieManualShown}
            deckHeight={currDeckHeight}
            onComplete={completeManual}
          />
        )}

        {renderSearchSection()}
        <div className="contentWrapper">
          {(loading || myJobsLoading) && (
            <Box
              width="100%"
              position="fixed"
              top={0}
              left={0}
              bottom={0}
              zIndex={1000}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size={30} />
            </Box>
          )}
          {renderDashboardContent()}
          <div className="fusion" />
        </div>

        <EmployerPromptDialog
          isOpen={modalsConfig.questionsModal}
          employer={modalsConfig.employerName}
          questions={modalsConfig.questions}
          job={modalsConfig.job}
          onConfirm={(job, answers) => modalsConfig.onQuestionsAccept(job, answers)}
          onCancel={handleJobReject}
          onClose={closeEmployerPromptDialog}
        />

        <ConfirmationDialog
          isOpen={modalsConfig.applyAlertModal}
          title="Apply to job?"
          message={
            modalsConfig.applyAlertModal ? (
              <Box textAlign="center">{`Do you want apply ${modalsConfig?.job?.title}?`}</Box>
            ) : null
          }
          confirmBtnName="Ok"
          onConfirm={modalsConfig.onApplyAlertModalConfirm}
          onCancel={closeJobApplyAlert}
          onClose={closeJobApplyAlert}
        />

        <ConfirmationDialog
          isOpen={modalsConfig.webLinkModal}
          title="External Job Weblink"
          message={
            modalsConfig.webLinkModal ? (
              <Box textAlign="center">
                {`By pressing Ok you agree to be redirected to the external website with selected job: ${modalsConfig.webUrl}`}
              </Box>
            ) : null
          }
          confirmBtnName="Ok"
          onConfirm={modalsConfig.onWebLinkModalConfirm}
          onCancel={closeWebUrlModal}
          onClose={closeWebUrlModal}
        />

        {modalsConfig.scheduleModal && (
          <EmployeeInterviewScheduleModal
            isOpen={modalsConfig.scheduleModal}
            employerProfileName={modalsConfig.job?.employerProfile?.name}
            employerProfileId={modalsConfig.job?.employerProfileProfileId}
            jobId={modalsConfig.job?.id}
            jobTitle={modalsConfig.job?.title}
            onClose={closeInterviewScheduleModal}
            onConfirm={onInterviewAccept}
          />
        )}

        <EmployeeInterviewCancelAlert
          isOpen={modalsConfig.cancelInterviewAlert}
          onClose={closeInterviewCancelAlert}
          onConfirm={confirmInterviewCanceling}
        />
        <EmployeeInterviewRescheduleAlert
          isOpen={modalsConfig.rescheduleAlert}
          onClose={closeRescheduleAlert}
          onConfirm={confirmRescheduleAlert}
        />
      </DashboardRoot>
    );
  }

  const renderPageContent = () => {
    const isAuth = isAuthenticated;

    if (isJobPage) return <EmployeeJob />;

    if (isAuth) {
      if (isCareerDevelopment) {
        return (
          <CareerDevelopment
            onSelect={handleJobSelect}
            onAccept={handleApply}
            onReject={handleJobReject}
            onStar={handleStar}
          />
        );
      }
      return renderDashboardPage();
    }

    return <Navigate to={ROUTES.login.default} />;
  };

  return renderPageContent();
}

EmployeeDashboard.propTypes = {};

export default memo(EmployeeDashboard);
