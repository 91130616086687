import React from 'react';
import { styled } from 'components';
import { qaAttr, landingPublicPath } from 'utils';

const { LANDING_PUBLIC_PATH } = process.env;

const StyledLink = styled('a')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.08,
  lineHeight: '20px',
  textAlign: 'center',
  color: theme.palette.primary.main
}));

export default function LogoLink(props) {
  return (
    <StyledLink
      href={LANDING_PUBLIC_PATH || landingPublicPath}
      {...qaAttr('landing-home-link')}
      {...props}
    >
      QuickHire
    </StyledLink>
  );
}
