import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import clsx from 'clsx';
import isBefore from 'date-fns/isBefore';
import head from 'lodash/head';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { qaAttr } from 'utils';
import { useMediaQuery } from 'components';
import { MdClose, MdStar, MdMail, MdChevronRight } from 'components/icons';
import { IconButton, Button, Spinner } from 'components/shared';

const windowWidth = window.innerWidth;

const orderExperience = (experience) =>
  experience.sort((a, b) => {
    if (a.endDate && isBefore(new Date(a.endDate), new Date(b.startDate))) return -1;
    return 1;
  });

function ConnectionProfile({
  className,
  isStarred,
  isStarredLoading,
  employeeCareerDev,
  next,
  prev,
  closeModal,
  jobTypes,
  canNavigate,
  suggestedJobs,
  openJob,
  activeJobIdx,
  onStar,
  onUnStar,
  withNavigateActions = true
}) {
  const { employeeProfile } = employeeCareerDev;
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const scrollToImage = useRef(null);
  const [isMsgRequestSent, setIsMsgRequestSent] = useState(false);

  const chatWithConnection = async () => {
    try {
      // @todo: make request
      // @todo: on success set setIsMsgRequestSent
      setIsMsgRequestSent(true);
    } catch (error) {
      console.error('chatWithConnection error: ', error);
    }
  };

  return (
    <Spring
      className={className}
      from={{ right: isDesktop ? -200 : windowWidth * -1 }}
      to={{ right: isDesktop ? 0 : 20 }}
      config={{
        duration: 250,
        easing: easePolyOut.exponent(2.0)
      }}
    >
      {(animStyles) => (
        <animated.div className="profileContainer" style={animStyles}>
          <div className="scrollToTop" ref={scrollToImage} />
          <div className="profilePadContainer">
            <div className="profileHeaderContainer">
              <IconButton
                variant="filled-primary"
                aria-label="Close this connection"
                className="profileCloseButton"
                onClick={closeModal}
                testID="qh-grow-connection-close-button"
              >
                <MdClose />
              </IconButton>
              <div className="profileImageContainer">
                {employeeProfile.imageUrl ? (
                  <img className="profileImage" src={employeeProfile.imageUrl} alt="" />
                ) : (
                  <div className="profileNoImageContainer">
                    <span>{employeeProfile.name.slice(0, 1).toUpperCase()}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="profileNameContainer">
              <p className="profileName">{employeeProfile.name}</p>
              {employeeProfile.motto && <p className="profileMotto">{employeeProfile.motto}</p>}
            </div>
            <div style={{ paddingTop: 10, marginBottom: 16, display: 'flex' }}>
              <IconButton
                aria-label="Star this user"
                className={clsx('profileStarredBtn', isStarred && 'profileStarredBtn_active')}
                disabled={isStarredLoading}
                onClick={
                  isStarred
                    ? () => onUnStar(employeeProfile?.profile_id)
                    : () => onStar(employeeProfile?.profile_id)
                }
              >
                {isStarredLoading ? (
                  <Spinner width={24} height={24} />
                ) : (
                  <MdStar
                    className={clsx('profileStarredIcon', isStarred && 'profileStarredIcon_active')}
                  />
                )}
              </IconButton>
              <IconButton
                variant="filled-primary"
                aria-label="Favorite this user"
                className="profileMailBtn"
                sx={{ marginLeft: '17px' }}
                // onClick={() => chatWithConnection()}
              >
                <MdMail style={{ fontSize: 20 }} />
              </IconButton>
            </div>
            {isMsgRequestSent && (
              <div className="sentMessage">
                Message request sent
                <Button variant="text" className="viewMessagesBtn">
                  View Messages
                </Button>
              </div>
            )}
            <div>
              <h1 className="profileSectionTitle">Current Industry</h1>
              {!isEmpty(employeeProfile.industry) ? (
                <p>
                  {employeeProfile.industry.map((ind) => (
                    <span key={ind.name}>{ind.name}, </span>
                  ))}
                </p>
              ) : (
                <div>Information Not Available</div>
              )}
            </div>
            <div className="profileCareerPathContainer">
              <h1 className="profileSectionTitle">Career Path</h1>
              <div className="profileSectionContainer">
                {!isEmpty(employeeProfile.experience) ? (
                  orderExperience(employeeProfile.experience).map((exp) => (
                    <div
                      className="profileExpContainer"
                      key={exp.currentJob}
                      style={{
                        borderLeft: `1px solid ${exp.currentJob ? '#8787F9' : '#CCCCCC'}`
                      }}
                    >
                      <div
                        className="profileExpCircle"
                        style={{
                          backgroundColor: exp.currentJob ? '#8787F9' : '#CCCCCC'
                        }}
                      />
                      <span className="profileExpName">{exp.name}</span>
                      <span className="profileExpName profileSubTitle">{exp.pos}</span>
                    </div>
                  ))
                ) : (
                  <div>Information Not Available</div>
                )}
              </div>
              <div className="profileSectionContainer">
                <h1 className="profileSectionTitle">Career Goal</h1>
                <div className="profileCareerGoalSection">
                  <span className="profileCareerGoalSubTitle">I am a</span>
                  <span className="profileCareerGoalTitle">
                    {get(
                      head(
                        jobTypes.filter((jt) => jt.id === Number(employeeCareerDev.curJobTypeId))
                      ),
                      'name'
                    )}
                  </span>
                </div>
                <div>
                  <span className="profileCareerGoalSubTitle">I want to be a</span>
                  <span className="profileCareerGoalTitle">
                    {get(
                      head(
                        jobTypes.filter((jt) => jt.id === Number(employeeCareerDev.futureJobTypeId))
                      ),
                      'name'
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="profileSectionContainer">
              <h1 className="profileSectionTitle">Education</h1>
              {!isEmpty(employeeProfile.education) ? (
                employeeProfile.education.map((edu) => (
                  <div key={edu.name} className="profileEduContainer">
                    <p>{edu.name}</p>
                    <span className="profileSubTitle">
                      {edu.pos} | {edu.currentJob ? 'inProgress' : 'diploma'}
                    </span>
                  </div>
                ))
              ) : (
                <div>Information Not Available</div>
              )}
            </div>
            <div className="profileSectionContainer">
              <h1 className="profileSectionTitle">Certifications</h1>
              <div>Information Not Available</div>
            </div>
          </div>
          <div className="profileSectionContainer">
            <h1
              className="profileSectionTitle"
              style={{
                margin: '0 1rem 2px'
              }}
            >
              Suggested Jobs
            </h1>
            {!isEmpty(suggestedJobs) ? (
              suggestedJobs.map((job, idx) =>
                // only display three jobs here
                idx < 3 ? (
                  <div
                    onClick={(e) => openJob(e, job, idx)}
                    className={clsx(
                      'suggestedJobsContainer',
                      activeJobIdx === idx && 'suggestedJobsContainerActive'
                    )}
                    key={job.id}
                    {...qaAttr(`qh-grow-suggested-job-button-${job.title}`)}
                  >
                    <h5 className="suggestedJobsTitle">{job.title}</h5>
                    <p className="suggestedJobsCompany">{job.employerProfile.name}</p>
                  </div>
                ) : null
              )
            ) : (
              <div>No Jobs Available</div>
            )}
          </div>
          {withNavigateActions && (
            <div className="profileNavContainer">
              <Button
                variant="filled-primary"
                aria-label="Star this user"
                className="profileNavButton"
                onClick={() => {
                  prev();
                  scrollToImage.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                disabled={!canNavigate}
                startIcon={<MdChevronRight style={{ transform: 'rotate(180deg)' }} />}
                testID="qh-grow-prev-connection-button"
              >
                <span>Prev</span>
              </Button>
              <Button
                className="profileNavButton"
                variant="filled-primary"
                aria-label="Star this user"
                onClick={() => {
                  next();
                  scrollToImage.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                disabled={!canNavigate}
                endIcon={<MdChevronRight />}
                testID="qh-grow-next-connection-button"
              >
                <span>Next</span>
              </Button>
            </div>
          )}
        </animated.div>
      )}
    </Spring>
  );
}

ConnectionProfile.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  employeeCareerDev: PropTypes.objectOf(PropTypes.any),
  jobTypes: PropTypes.arrayOf(PropTypes.object),
  canNavigate: PropTypes.bool.isRequired,
  suggestedJobs: PropTypes.arrayOf(PropTypes.any),
  openJob: PropTypes.func.isRequired,
  activeJobIdx: PropTypes.number,
  isStarred: PropTypes.bool,
  isStarredLoading: PropTypes.bool,
  onStar: PropTypes.func.isRequired,
  onUnStar: PropTypes.func.isRequired
};

ConnectionProfile.defaultProps = {
  employeeCareerDev: null,
  jobTypes: [],
  suggestedJobs: [],
  activeJobIdx: null,
  isStarred: false,
  isStarredLoading: false
};

export default ConnectionProfile;
