import React from 'react';
import PropTypes from 'prop-types';
import { landingPublicPath, privacyPolicyPath, termsPath } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { Box, styled } from 'components';
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedIn } from '../../icons';

function Footer({ className, containerProps }) {
  return (
    <Box
      component="footer"
      p="28px 70px"
      display="flex"
      justifyContent="space-between"
      bgcolor="#fff"
      className={className}
      {...containerProps}
    >
      <Box component="nav" display="flex" alignItems="center">
        {/* <a href="#" className={classes.pageLink}>About</a> */}
        {/* <a href="#" className={classes.pageLink}>How it Works</a> */}
        {/* <a href="#" className={classes.pageLink}>Employers</a> */}
        <a
          href="mailto:hello@getquickhire.com"
          className="pageLink"
          onClick={() => {
            track('Contact Us Clicked');
          }}
        >
          Contact
        </a>
        {/* <a href="#" className={classes.pageLink}>Careers</a> */}
        <a href={privacyPolicyPath} target="_blank" className="pageLink" rel="noreferrer noopener">
          Privacy Policy
        </a>
        <a href={termsPath} target="_blank" className="pageLink" rel="noreferrer noopener">
          Terms of Services
        </a>
      </Box>
      <Box display="flex" alignItems="center">
        <a
          href="https://www.instagram.com/getquickhire/"
          target="_blank"
          className="socialLink"
          title="instagram"
          rel="noreferrer noopener"
        >
          <FaInstagram color="inherit" fontSize="inherit" />
        </a>
        <a
          href="https://www.facebook.com/getquickhire/"
          target="_blank"
          className="socialLink"
          title="facebook"
          rel="noreferrer noopener"
        >
          <FaFacebook color="inherit" fontSize="inherit" />
        </a>
        <a
          href="https://www.linkedin.com/company/getquickhire/?viewAsMember=true"
          target="_blank"
          className="socialLink"
          title="linkedIn"
          rel="noreferrer noopener"
        >
          <FaLinkedIn color="inherit" fontSize="inherit" />
        </a>
      </Box>
    </Box>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.objectOf(PropTypes.any)
};

Footer.defaultProps = {
  className: '',
  containerProps: {}
};

const StyledFooter = styled(Footer)(({ theme }) => ({
  [theme.breakpoints.down(theme.desktopBreakPoint)]: {
    display: 'none'
  },
  '& .pageLink': {
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '17px',
    opacity: 0.5,
    color: theme.palette.moreColors.black,
    '&:not(:first-of-type)': {
      marginLeft: 16
    }
  },
  '& .socialLink': {
    display: 'flex',
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '17px',
    opacity: 0.2,
    color: '#000',
    '&:not(:first-of-type)': {
      marginLeft: 26
    }
  }
}));

export default StyledFooter;
