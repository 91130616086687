import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import map from 'lodash/map';
import head from 'lodash/head';
import get from 'lodash/get';
import { qaAttr } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useEmployeeTrainings } from 'hooks';
import { Box } from 'components';
import { Button, CheckBox, IconButton, Select, Spinner } from 'components/shared';
import BlogPost from 'components/Grow/employee/BlogPost';
// import { BlogShareButtons } from 'components/Blog/BlogShareButton';
import { MdSave } from 'components/icons';
import { careerDevBlogs } from 'components/Grow/employee/employeeBlogsData';
import { GET_QUIZZES } from 'api';

const randomize = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    // eslint-disable-next-line no-param-reassign
    array[currentIndex] = array[randomIndex];
    // eslint-disable-next-line no-param-reassign
    array[randomIndex] = temporaryValue;
  }

  return array;
};

function CareerDevContent({
  // classes,
  renderConnections,
  jobTypes,
  careerDev,
  saveCareerDev,
  isLoading
}) {
  const { fetchTrainings, editTraining, trainings } = useEmployeeTrainings();
  const inCompleteTrainings = trainings.filter((o) => !o.complete);

  const [fetchQuizzes, { data: quizzesData }] = useLazyQuery(GET_QUIZZES, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  const quizzes = quizzesData?.getQuiz || [];

  const [currentJob, setCurrentJob] = useState(
    get(head(jobTypes.filter((jobType) => jobType.id === careerDev.curJobTypeId)), 'id', '')
  );
  const [futureJob, setFutureJob] = useState(
    get(head(jobTypes.filter((jobType) => jobType.id === careerDev.futureJobTypeId)), 'id', '')
  );
  const [currentlySearching, setCurrentlySearching] = useState(
    careerDev.currentlySearching || false
  );
  const [showEmployerData, setShowEmployerData] = useState(careerDev.showEmployerData || false);
  const [blogs, setBlogs] = useState(null);
  const [formIsDirty, setFormIsDirty] = useState(false);

  useEffect(() => {
    fetchTrainings();
    fetchQuizzes();

    const filteredBlogs = careerDevBlogs.filter(
      (blog) => blog.industryId == null || careerDev.industryId === blog.industryId
    );
    setBlogs(randomize(filteredBlogs));
  }, []);

  const handleChange = (e, key) => {
    setFormIsDirty(true);
    switch (key) {
      case 'current':
        setCurrentJob(e.target.value);
        break;
      case 'future':
        setFutureJob(e.target.value);
        break;
      case 'searching':
        setCurrentlySearching(!currentlySearching);
        setCurrentJob('');
        break;
      case 'showEmployerData':
        setShowEmployerData(!showEmployerData);
        break;
      default:
        return null;
    }
  };

  const renderCourse = (training, i) => (
    <div key={`training__${training.id}`} className="course">
      <Box flex={1}>
        <Box mb="4px">
          <div className="course__title">{training.name}</div>
        </Box>
        <a className="course__link" href={training.link} target="_blank" rel="noopener noreferrer">
          {training.link}
        </a>
      </Box>
      {!training.taken && (
        <Button
          variant="filled-primary"
          className="course__action"
          onClick={() => editTraining({ ...training, taken: true })}
        >
          Take Course
        </Button>
      )}
    </div>
  );

  return (
    <div className="careerDevContent">
      <div className="introContentContainer topContainer">
        <div className="introSelectContainer">
          <p className="introSelectLabel">I am a:</p>
          <Select
            value={currentJob}
            native
            disabled={currentlySearching || isLoading}
            className="introSelect"
            inputVariant="outlined"
            inputProps={{
              'aria-label': 'Your current job',
              required: true,
              ...qaAttr('qh-grow-current-job-select'),
              onFocus: () => {
                track('Qh Grow current job focused');
              }
            }}
            options={map(jobTypes, (t, i) => ({
              key: `currentJob__${i}`,
              value: t.id,
              label: t.name,
              ...qaAttr(`qh-grow-current-job-option-${t.id}`)
            }))}
            emptyOption={
              <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
                Current Job
              </Box>
            }
            onChange={(e) => handleChange(e, 'current')}
          />
        </div>
        <div className="introSelectContainer">
          <p className="introSelectLabel">I want to be a:</p>
          <Select
            value={futureJob}
            native
            disabled={isLoading}
            className="introSelect"
            inputVariant="outlined"
            inputProps={{
              'aria-label': 'Job you want to have',
              required: true,
              onFocus: () => {
                track('Qh Grow future job focused');
              }
            }}
            options={map(jobTypes, (t, i) => ({
              key: `futureJob__${i}`,
              value: t.id,
              label: t.name,
              ...qaAttr(`qh-grow-future-job-option-${t.id}`)
            }))}
            emptyOption={
              <Box component="option" disabled value="" color="rgba(0,0,0,0.5)">
                Future Job
              </Box>
            }
            onChange={(e) => handleChange(e, 'future')}
            {...qaAttr('qh-grow-future-job-select')}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <CheckBox
            value={currentlySearching}
            checked={currentlySearching}
            label="Currently searching for a career"
            disabled={isLoading}
            className="introCheckbox"
            checkboxProps={{
              inputProps: { ...qaAttr('qh-grow-currently-searching-checkbox-input') }
            }}
            onChange={(e) => handleChange(e, 'searching')}
            {...qaAttr('qh-grow-currently-searching-checkbox-input')}
          />
          <CheckBox
            value={showEmployerData}
            checked={showEmployerData}
            label="Employer can see my stats"
            disabled={isLoading}
            className="introCheckbox"
            checkboxProps={{
              inputProps: { ...qaAttr('qh-grow-employer-can-see-checkbox-input') }
            }}
            onChange={(e) => handleChange(e, 'showEmployerData')}
            {...qaAttr('qh-grow-employer-can-see-checkbox')}
          />
          <IconButton
            disabled={!formIsDirty || isLoading}
            variant="filled-primary"
            aria-label="Save Changes"
            className="saveCareerDevButton"
            onClick={() =>
              saveCareerDev({
                industryId: careerDev.industryId,
                curJobTypeId: currentJob,
                futureJobTypeId: futureJob,
                currentlySearching,
                showEmployerData
              })
            }
            testID="qh-grow-submit-button"
          >
            {isLoading ? <Spinner size={26} /> : <MdSave />}
          </IconButton>
        </div>
      </div>
      <div className="bottomContainer">
        <div className="blogContainer">
          <div className="blogScrollableContainer">
            {!!quizzes.length && (
              <div className="blogSection">
                <h3 className="blogSectionTitle">Take these quizzes to jumpstart your career!</h3>
                {map(quizzes, (quiz, i) => (
                  <BlogPost key={`quiz__${i}`} title={quiz.name} link={quiz.value} />
                ))}
              </div>
            )}
            <div className="blogSection">
              <h3 className="blogSectionTitle">Assigned Courses</h3>
              {inCompleteTrainings.length ? inCompleteTrainings.map(renderCourse) : <div>N/A</div>}
            </div>
            <div className="blogSection">
              <h3 className="blogSectionTitle">Resources to guide your journey</h3>
              {blogs &&
                blogs.map((blog) => (
                  <BlogPost key={blog.title} title={blog.title} link={blog.link} />
                ))}
            </div>
          </div>
        </div>
        <div className="connectionContainer">{renderConnections()}</div>
      </div>
    </div>
  );
}

CareerDevContent.propTypes = {
  renderConnections: PropTypes.func.isRequired,
  jobTypes: PropTypes.arrayOf(PropTypes.any),
  careerDev: PropTypes.objectOf(PropTypes.any),
  saveCareerDev: PropTypes.func.isRequired
};

CareerDevContent.defaultProps = {
  careerDev: null,
  jobTypes: null
};

export default CareerDevContent;
