export default ({ theme }) => ({
  '& .mainContent': {
    padding: '0 16px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flex: 1
    }
  },
  '& .secondaryContent': {
    maxWidth: 320,
    margin: '0 auto 32px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      width: '100%',
      maxWidth: '100%',
      margin: '0 0 34px'
    }
  },
  '& .helperText': {
    marginTop: 6,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '17px'
  },
  '& .homeBtnWrapper': {
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      minHeight: 56,
      display: 'block',
      marginBottom: 37,
      padding: 4,
      paddingRight: 16
    }
  },
  '& .subPageContent': {
    width: '100%',
    maxWidth: 352,
    margin: '0 auto',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      flex: 1
    }
  },
  '& .subPageHeader': {
    width: '100%',
    maxWidth: 352,
    margin: '0 auto',
    marginBottom: 34,
    padding: 4,
    paddingRight: 16,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      maxWidth: '100%',
      marginBottom: 0
    }
  },
  '& .subPageFooter': {
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'block',
      minHeight: 56
    }
  },
  '& .resendLink': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});
