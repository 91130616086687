import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { getUserId } from 'utils';
import { useRestrictions } from 'hooks';
import { styled, useMediaQuery } from 'components';
import { Spinner } from 'components/shared';
import { Account, Profile, ManagePlan, Notifications } from 'components/Profile/employer';
import { GET_EMPLOYER_PROFILE, GET_ALL_CC } from 'api';
import styles from 'styles/Profile/EmployerProfile';

const StyledRoot = styled('div')(styles);

const PROFILE_SEC = 'Profile';
const NOTIFICATIONS_SEC = 'Notifications';
const ACCOUNT_SEC = 'Account';
const PLAN_SEC = 'Manage Plan';
const sections = [
  { name: PROFILE_SEC, Component: Profile, jumpLink: 'profile' },
  { name: PLAN_SEC, Component: ManagePlan, jumpLink: 'manage-plan' },
  { name: NOTIFICATIONS_SEC, Component: Notifications, jumpLink: 'notifications' },
  { name: ACCOUNT_SEC, Component: Account, jumpLink: 'account' }
];

const INIT_FORM = {
  address: '',
  imageUrl: '',
  industry: [],
  name: '',
  newImageFile: null,
  phone: '',
  size: '',
  timeZone: '',
  website: ''
};

const notificationKeys = [
  'inappNewApplicants',
  'emailNewApplicants',
  'inappInboxMessages',
  'emailInboxMessages',
  'inappTimeRespondEnds',
  'emailTimeRespondEnds'
];

function EmployerProfile(props) {
  const isDesktop = useMediaQuery('(min-width:1024px)', { noSsr: true });
  const { setRestrictions } = useRestrictions();
  const [expandedSection, setExpandedSection] = useState(sections[0].name);
  const [fullProfile, setFullProfile] = useState({});
  const [profile, setProfile] = useState(INIT_FORM);
  const [subsData, setSubsData] = useState({});
  const [cards, setCards] = useState([]);
  const [notifications, setNotifications] = useState(() =>
    reduce(
      notificationKeys,
      (res, val) => {
        res[val] = false;
        return res;
      },
      {}
    )
  );

  const [fetchEmployerProfile, { loading: profileLoading = true }] = useLazyQuery(
    GET_EMPLOYER_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const profile = data?.employerProfile || {};
        const {
          allowPlan = false,
          address = '',
          city = '',
          countryId = 1, // default USA
          industry = [],
          imageUrl = '',
          name = '',
          newSubscriptionPlan,
          phone = '',
          restrictions = {},
          size = '',
          showPlanModal = 'false',
          stateId = '',
          subscriptionPlan,
          timeZone = '',
          trialTimePlan,
          website = '',
          zip = ''
        } = profile;
        const formattedIndustry =
          industry?.length > 0 ? map(industry, ({ __typename, ...rest }) => ({ ...rest })) : [];
        const notifications = reduce(
          notificationKeys,
          (res, val) => {
            res[val] = profile[val];
            return res;
          },
          {}
        );

        setRestrictions(
          allowPlan
            ? { ...restrictions, showEnterpriseDashboard: true }
            : {
                allowEmployeeActions: true,
                allowEnterprise: false,
                showEnterpriseDashboard: false, // if allowPlan === false - allow everything, except enterprise board
                jobPosting: true
              }
        );

        setFullProfile(profile);
        setProfile((prev) => ({
          ...prev,
          address: address || '',
          city: city || '',
          countryId: countryId || '',
          industry: formattedIndustry,
          imageUrl,
          name: name || '',
          phone: phone || '',
          size,
          stateId: stateId || '',
          timeZone,
          website: website || '',
          zip: zip || ''
        }));
        setNotifications(notifications);
        setSubsData({
          allowPlan,
          showPlanModal,
          subscriptionPlan,
          newSubscriptionPlan,
          trialTimePlan
        });
      }
    }
  );

  const [fetchCards, { loading: cardsLoading }] = useLazyQuery(GET_ALL_CC, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      const fetchedCards = data?.getUserCards || {};
      if (fetchedCards.cardInfo) {
        const parsedData = JSON.parse(fetchedCards.cardInfo);
        if (parsedData.data) setCards(parsedData.data);
      }
    }
  });

  useEffect(() => {
    const userId = parseInt(getUserId());
    if (userId) {
      fetchEmployerProfile({ variables: { userId } });
      fetchCards({ variables: { userId } });
    }
  }, []);

  const handleSectionExpand = useCallback((toExpand, name, expanded) => {
    setExpandedSection(toExpand);
  }, []);

  const getFullProfile = useCallback(() => {
    const userId = parseInt(getUserId());
    if (userId) fetchEmployerProfile({ variables: { userId } });
  }, []);

  const getCreditCards = useCallback(() => {
    const userId = parseInt(getUserId());
    if (userId) fetchCards({ variables: { userId } });
  }, []);

  const getSectionProps = (name) => {
    switch (name) {
      case PROFILE_SEC: {
        return { fetchEmployerProfile: getFullProfile, profile, setExpandedSection };
      }
      case NOTIFICATIONS_SEC: {
        return { notifications };
      }
      case PLAN_SEC: {
        return {
          fetchEmployerProfile: getFullProfile,
          fetchCards: getCreditCards,
          subsData,
          cards,
          cardsLoading
        };
      }
      default:
        return {};
    }
  };

  const renderSections = () =>
    map(sections, ({ Component, name, jumpLink }, i) => {
      if (name === PLAN_SEC && !fullProfile.allowPlan) return null;
      return (
        <Component
          key={`section__${i}`}
          sectionName={name}
          jumpLink={jumpLink}
          expanded={expandedSection === name}
          onExpand={handleSectionExpand}
          {...getSectionProps(name)}
        />
      );
    });

  const renderDesktopLayout = () => (
    <div className="desktopLayout">
      <div className="column">
        {map(sections, ({ Component, name, jumpLink }, i) => (
          <div key={`contentItem__${i}`} className="contentItem">
            <a href={`#${jumpLink}`}>{name}</a>
          </div>
        ))}
      </div>
      <div className="column">{renderSections()}</div>
    </div>
  );

  const renderMobileLayout = () => <div className="mobileLayout">{renderSections()}</div>;

  if (isEmpty(fullProfile)) {
    return (
      <StyledRoot className="container">
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className="container">
      {profileLoading && (
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      )}
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
    </StyledRoot>
  );
}

EmployerProfile.propTypes = {};

export default EmployerProfile;
