import React, { useState, useEffect, useMemo, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  format,
  eachDayOfInterval,
  isBefore,
  isFuture,
  isToday,
  isSameDay,
  isEqual as isEqualDates,
  isThisWeek,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  getDay
} from 'date-fns';
import { map, find, orderBy, forEach, filter } from 'lodash';
import clsx from 'clsx';
import {
  qaAttr,
  WEEK_DAYS_LONG,
  convertDateToTZ,
  convertUTCToZonedDate,
  convertZonedDateToUTC
} from 'utils';
import { formatEmployerSchedule, checkSchedulesActuality } from 'utils/schedules';
import { useTimeZone } from 'hooks';
import { Accordion, AccordionDetails, AccordionSummary, styled } from 'components';
import { GET_EMPLOYER_INTERVIEW_SCHEDULE } from 'api';
import styles from 'styles/Dashboard/EmployeeJobInterviewSchedule';
import { MdArrowBack, MdArrowForward, MdClose, MdExpandMore } from '../../icons';
import Button, { IconButton } from '../Button';
import Spinner from '../Spinner';

const StyledRoot = styled('div')(styles);

const getCurrentWeekDates = (date = new Date()) => {
  const interval = {
    start: startOfWeek(date),
    end: endOfWeek(date)
  };
  const weekDates = eachDayOfInterval(interval);
  weekDates[weekDates.length - 1] = interval.end;
  return { ...interval, weekDates };
};

const defaultWeek = () => Array(7).fill([]);

function JobInterviewSchedule(props) {
  const {
    confirmLoading,
    employerProfileId,
    inactiveDates,
    onClose,
    onConfirm,
    onDateSelect,
    role,
    selectedDate,
    qaId,
    withConfirmation,
    withCancel
  } = props;
  const [interviewDate, employerScheduleId] = selectedDate || [null, null];
  const { timeZone: userTimeZone } = useTimeZone({ checkByProfile: true, role });
  const [employerTZ, setEmployerTZ] = useState('');
  const [currentWeekSlots, setCurrentWeekSlots] = useState(defaultWeek);
  const [initSchedules, setInitSchedules] = useState([]);
  const [currentWeekDates, setCurrentWeekDates] = useState(getCurrentWeekDates);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [openedAccordion, setOpenedAccordion] = useState();
  const isCurrentWeek = useMemo(() => isThisWeek(currentWeekDates.start), [currentWeekDates.start]);

  const [getSchedule, { error: getScheduleError = {}, loading: getScheduleLoading = true }] =
    useLazyQuery(GET_EMPLOYER_INTERVIEW_SCHEDULE, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const result = data?.getScheduleTimesEmployer;
        if (result) {
          const employerTimeZone = find(result, 'timeZone')?.timeZone;
          setEmployerTZ(employerTimeZone || '');
          const formattedInitSchedules = formatFetchedData(result, employerTimeZone);
          setInitSchedules(formattedInitSchedules);
        }
      }
    });

  const formatFetchedData = (data = [], employerTZ) =>
    map(data, (obj) => formatEmployerSchedule(obj));

  const formatScheduleSlots = (schedule) => {
    const { startDate, endDate, schedule: weekSlots, id } = schedule;
    const intervalDates = eachDayOfInterval({ start: startDate, end: endDate });

    let allConvertedSlots = [];
    forEach(intervalDates, (date, i, arr) => {
      const day = getDay(date);
      const convertedDaySlots = map(weekSlots[day], (s) => {
        const strWithDelimiter = [s.slice(0, 2), '-', s.slice(2)].join('');
        const [hours, minutes] = strWithDelimiter.split('-');
        const timeToDate = new Date(date);
        timeToDate.setHours(+hours, +minutes, 0, 0);
        const zonedDate = convertDateToTZ(timeToDate, employerTZ, userTimeZone);
        if (i === arr.length - 1 && !isBefore(zonedDate, intervalDates.end)) return; // create slots only before schedule date
        return {
          scheduleId: id,
          key: s,
          _key: strWithDelimiter,
          date: zonedDate
        };
      });
      const truthy = filter(convertedDaySlots, Boolean);
      const ordered = orderBy(truthy, [(o) => o.date.valueOf()], ['asc']);
      allConvertedSlots = [...allConvertedSlots, ...ordered];
    });

    return allConvertedSlots;
  };

  const filterSlotsForCurrentDay = (date, formattedSlots) =>
    filter(formattedSlots, ({ date: slotDate }) => isSameDay(date, slotDate));

  const filterSlotsBeforeDate = (date, formattedSlots) =>
    filter(formattedSlots, ({ date: slotDate }) => isBefore(slotDate, date));

  const filterSlotsSameOrAfterDate = (date, formattedSlots) =>
    filter(formattedSlots, ({ date: slotDate }) => !isBefore(slotDate, date));

  useEffect(() => {
    setOpenedAccordion(null);

    if (initSchedules.length && currentWeekDates.start) {
      let actualSchedules = [...initSchedules];
      let currSlotsReCalcCounter = 0;
      let currentSlots = [];
      let initCurrIdx = -1;

      const weekDaysWithSlots = map(currentWeekDates.weekDates, (weekDayDate, dayIdx) => {
        const initCounter = currSlotsReCalcCounter;
        const date = startOfDay(weekDayDate);
        let [newActualSchedules, currentIdx, closestScheduleIdx, isSameSchedule] =
          checkSchedulesActuality(actualSchedules, date);
        let currDaySlots = [];
        actualSchedules = [...newActualSchedules];

        if (!isSameSchedule) currSlotsReCalcCounter += 1;

        if (currentIdx !== -1) {
          const currentSchedule = actualSchedules[currentIdx];
          const currentEndDate = currentSchedule.endDate;
          let nextStartDate =
            closestScheduleIdx !== -1 ? actualSchedules[closestScheduleIdx].startDate : null;

          if (nextStartDate && isSameDay(currentEndDate, nextStartDate)) {
            // day could be on the edge of the two inner schedules (Schedule A and Schedule B)
            const currSlots = formatScheduleSlots(currentSchedule);
            let nextSlots = formatScheduleSlots(actualSchedules[closestScheduleIdx]);
            currDaySlots = filterSlotsForCurrentDay(date, [...currSlots, ...nextSlots]);
            const scheduleSlotsForCurrentDay = filterSlotsBeforeDate(currentEndDate, currDaySlots);
            const nextScheduleSlotsForCurrentDay = filterSlotsSameOrAfterDate(
              nextStartDate,
              currDaySlots
            );
            currDaySlots = [...scheduleSlotsForCurrentDay, ...nextScheduleSlotsForCurrentDay];
          } else if (currentIdx > closestScheduleIdx && isSameDay(currentEndDate, date)) {
            // day could be on the edge of passed schedules and next one (Schedule B and Schedule A)
            const dateEnd = endOfDay(currentEndDate);
            let [newActualSchedules, currentIdx] = checkSchedulesActuality(
              actualSchedules,
              dateEnd
            );
            const nextSlots = formatScheduleSlots(newActualSchedules[currentIdx]);
            const nextScheduleSlotsForCurrentDay = filterSlotsSameOrAfterDate(
              newActualSchedules[currentIdx].startDate,
              filterSlotsForCurrentDay(date, nextSlots)
            );
            currDaySlots = [...currDaySlots, ...nextScheduleSlotsForCurrentDay];
          } else {
            let formattedSlots = currentSlots;

            if (
              !currentSlots.length ||
              initCounter !== currSlotsReCalcCounter ||
              initCurrIdx !== currentIdx
            ) {
              // initCounter and currSlotsReCalcCounter are to prevent multiple formatScheduleSlots calls
              formattedSlots = formatScheduleSlots(actualSchedules[currentIdx]);
              currentSlots = formattedSlots;
              initCurrIdx = currentIdx;
            }

            currDaySlots = filterSlotsForCurrentDay(date, formattedSlots);
          }
        }

        return currDaySlots;
      });

      setCurrentWeekSlots(weekDaysWithSlots);
    }
  }, [JSON.stringify(initSchedules), currentWeekDates.start]);

  useEffect(() => {
    if (employerProfileId) getSchedule({ variables: { employerProfileId } });
  }, []);

  const handleConfirmDate = () => {
    if (onConfirm && interviewDate && employerScheduleId)
      onConfirm(interviewDate, employerScheduleId);
  };

  const cancelConfirmation = () => {
    onDateSelect();
    setIsConfirmation(false);
  };

  const selectTime = (newDate, scheduleId, isSelected) => {
    if (!isSelected) {
      if (withConfirmation) setIsConfirmation(true);
      if (onDateSelect) onDateSelect(newDate, scheduleId);
    } else {
      onDateSelect();
    }
  };

  const showNextWeek = () =>
    setCurrentWeekDates(
      getCurrentWeekDates(currentWeekDates.end.setDate(currentWeekDates.end.getDate() + 1))
    );

  const showPrevWeek = () =>
    setCurrentWeekDates(
      getCurrentWeekDates(currentWeekDates.start.setDate(currentWeekDates.start.getDate() - 1))
    );

  const handleAccordionChange = (e, expanded, index) => {
    setOpenedAccordion(expanded ? index : null);
  };

  if (isConfirmation && interviewDate) {
    return (
      <StyledRoot>
        <div className="confirm__header">
          <IconButton
            color="primary"
            className="confirm__closeBtn"
            onClick={cancelConfirmation}
            testID={`job-schedule-close-confirm-button-${qaId}`}
          >
            <MdClose />
          </IconButton>
        </div>
        <div className="confirm__content">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="confirm__title">Confirm interview on</div>
            <div className="confirm__date">
              {format(interviewDate, "EEEE, MMM do 'at' hh:mm a?")}
            </div>
            <Button
              variant="filled-primary"
              disabled={confirmLoading}
              endIcon={confirmLoading ? <Spinner size={24} /> : null}
              width={162}
              height={46}
              onClick={handleConfirmDate}
              testID={`job-schedule-submit-button-${qaId}`}
            >
              Go get it!
            </Button>
          </div>
        </div>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot>
      <div className="header">
        <IconButton
          edge="start"
          disabled={isCurrentWeek}
          className="header__action"
          onClick={showPrevWeek}
          testID={`job-schedule-prev-week-button-${qaId}`}
        >
          <MdArrowBack fontSize="inherit" />
        </IconButton>
        <div className="header__title">
          {`${format(currentWeekDates.start, 'MMM do')} - ${format(
            currentWeekDates.end,
            'MMM do'
          )}`}
        </div>
        <IconButton
          edge="end"
          className="header__action"
          onClick={showNextWeek}
          testID={`job-schedule-next-week-button-${qaId}`}
        >
          <MdArrowForward fontSize="inherit" />
        </IconButton>
      </div>
      <div className="content">
        {getScheduleLoading && (
          <div className="scheduleLoaderContainer">
            <Spinner size={24} />
          </div>
        )}
        <div className="days__container">
          {map(WEEK_DAYS_LONG, (day, i) => {
            const weekDayToDate = currentWeekDates.weekDates[i];
            const isDayBefore = !isToday(weekDayToDate) && !isFuture(weekDayToDate);
            const currentDaySlots = currentWeekSlots[i];
            const isDisabled = isDayBefore || !currentDaySlots.length;

            return (
              <Accordion
                key={`day__${i}`}
                expanded={openedAccordion === i}
                square
                elevation={0}
                disabled={isDisabled}
                classes={{
                  root: 'expansionPanel__root'
                }}
                onChange={(...params) => handleAccordionChange(...params, i)}
              >
                <AccordionSummary
                  expandIcon={
                    <MdExpandMore
                      fontSize="inherit"
                      color="primary"
                      className="expansionPanel__icon"
                      style={{ ...(isDisabled && { display: 'none' }) }}
                    />
                  }
                  classes={{
                    root: 'expansionPanel__summary',
                    content: 'expansionPanel__summaryContent'
                  }}
                  {...qaAttr(`job-schedule-accordion-button-${day}-${qaId}`)}
                >
                  <span className="weekDay">{day}</span>
                  &nbsp;
                  {!isDisabled && (
                    <span className="openTimesCount">
                      {`| ${currentDaySlots.length} time${
                        currentDaySlots.length > 1 ? 's' : ''
                      } open`}
                    </span>
                  )}
                </AccordionSummary>
                <AccordionDetails classes={{ root: 'expansionPanel__details' }}>
                  <div
                    className="timesContainer"
                    style={{
                      justifyContent: currentDaySlots.length > 1 ? 'flex-start' : 'center'
                    }}
                  >
                    {map(currentDaySlots, ({ date, key, _key, scheduleId }, k) => {
                      const slotStr = `${i}-${_key}`;
                      const isTimeBefore = isBefore(date, new Date());
                      const isSelected = interviewDate ? isEqualDates(date, interviewDate) : false;
                      const isInactiveSlot = !!find(inactiveDates, (d) => isEqualDates(d, date));
                      const isDisabled = isTimeBefore || isInactiveSlot;

                      return (
                        <div key={`time__${k}`} className="timeItem">
                          <Button
                            disabled={isDisabled}
                            aria-pressed={isSelected}
                            className={clsx(
                              'timeButton',
                              isDisabled && 'disabled',
                              isSelected && 'selected'
                            )}
                            onClick={() => selectTime(date, scheduleId, isSelected)}
                            testID={`job-schedule-time-button-${slotStr}-${qaId}`}
                          >
                            {format(date, 'hh:mm aaa')}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      {withCancel && (
        <div className="footer">
          <Button
            variant="filled-primary"
            width={170}
            height={40}
            onClick={onClose}
            testID={`job-schedule-cancel-button-${qaId}`}
          >
            Cancel
          </Button>
        </div>
      )}
    </StyledRoot>
  );
}

JobInterviewSchedule.propTypes = {
  confirmLoading: PT.bool,
  employerProfileId: PT.number.isRequired,
  inactiveDates: PT.array.isRequired,
  onClose: PT.func,
  onConfirm: PT.func,
  onDateSelect: PT.func.isRequired,
  role: PT.oneOf(['employee', 'employer']),
  selectedDate: PT.array.isRequired,
  qaId: PT.string,
  withCancel: PT.bool,
  withConfirmation: PT.bool
};

JobInterviewSchedule.defaultProps = {
  confirmLoading: false,
  onClose: () => undefined,
  onConfirm: () => undefined,
  role: 'employee',
  qaId: '',
  withCancel: true,
  withConfirmation: true
};

export const PureJobInterviewSchedule = JobInterviewSchedule;
export default memo(JobInterviewSchedule);
