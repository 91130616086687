import { gql } from '@apollo/client';

export const GET_CHAT = gql`
  query chat($jobsId: Int!, $messageTo: Int!, $messageFrom: Int!, $role: String!) {
    chat(jobsId: $jobsId, messageTo: $messageTo, messageFrom: $messageFrom, role: $role) {
      createdAt
      id
      jobsId
      messageTo
      messageFrom
      message
      readed
      jobs {
        employerProfile {
          name
          imageUrl
        }
      }
      users {
        employeeProfile {
          name
          imageUrl
        }
      }
      usersUserId
    }
  }
`;

export const GET_APPROVED_APPLICANTS_MESSAGES = gql`
  query getApprovedEmployerJobMessages($usersUserId: Int!) {
    getApprovedEmployerJobMessages(usersUserId: $usersUserId) {
      createdAt
      id
      jobs {
        id
        imageUrl
        title
      }
      message
      messageTo
      messageFrom
      users {
        employeeProfile {
          imageUrl
          name
        }
      }
      updatedAt
      usersUserId
    }
  }
`;


export const GET_APPROVED_JOBS_MESSAGES = gql`
  query getApprovedJobMessages($usersUserId: Int!) {
    getApprovedJobMessages(usersUserId: $usersUserId) {
      createdAt
      id
      jobs {
        id
        imageUrl
        title
      }
      message
      messageTo
      usersUserId
      updatedAt
    }
  }
`;
