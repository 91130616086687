import React, { useState } from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { MenuItem, Popover } from 'components';
import Button from '../Button';

function PopupMenu(props) {
  const { children, id, items, getTrigger, onItemClick, triggerTitle, triggerProps, ...rest } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const popupId = open ? id : undefined;

  const openPopup = (e) => setAnchorEl(e.currentTarget);
  const closePopup = () => setAnchorEl(null);

  const renderTrigger = () => {
    if (getTrigger) return getTrigger({ anchorEl, setAnchorEl, ...triggerProps });
    return (
      <Button aria-describedby={popupId} onClick={openPopup} disableRipple {...triggerProps}>
        {triggerTitle}
      </Button>
    );
  };

  const renderItems = () =>
    map(items, (item, i) => (
      <MenuItem
        key={`item__${i}`}
        {...item}
        onClick={(e) => onItemClick(e, i, item, { setAnchorEl, closePopup })}
      />
    ));

  return (
    <>
      {renderTrigger()}
      <Popover
        id={popupId}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        {...rest}
      >
        {children({ anchorEl, setAnchorEl, closePopup }) || renderItems()}
      </Popover>
    </>
  );
}

PopupMenu.propTypes = {
  children: PT.any,
  getTrigger: PT.func,
  id: PT.any,
  items: PT.arrayOf(PT.objectOf(PT.any)),
  onItemClick: PT.func,
  popoverProps: PT.objectOf(PT.any),
  triggerTitle: PT.string,
  triggerProps: PT.objectOf(PT.any)
};

export default PopupMenu;
