import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from 'components/shared';

function GenderForm({ form, onChange }) {
  function handleFieldChange(field) {
    return (e) => onChange({ ...form, [field]: e.target.value });
  }

  return (
    <Fragment>
      <RadioGroup
        data={[
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
          { label: 'Non-Binary', value: 'non-binary' },
          { label: 'I Prefer Not to Answer', value: 'no answer' },
        ]}
        value={form.gender}
        defaultValue="female"
        aria-label="gender"
        mb="20px"
        onChange={handleFieldChange('gender')}
      />
    </Fragment>
  );
}

GenderForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GenderForm;
