import React, { useContext, useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  CategoryScale,
  Chart,
  BarController,
  BarElement,
  Legend,
  LinearScale,
  LineController
} from 'chart.js';
import { getBarChartData, calculateAverageTenure } from 'components/Grow/employer/charts/util';
import { Spinner } from 'components/shared';
import get from 'lodash/get';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import { makeQueryFetch } from '../../api/util';
import growApi from '../../api';
import EmployerGrowContext from '../../EmployerGrowContext';

Chart.register(CategoryScale, BarController, BarElement, Legend, LinearScale, LineController);

function EmployeeTenure() {
  const client = useApolloClient();
  const [chartData, setChartData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const chartCtxRef = useRef(null);
  const chartRef = useRef(null);
  const { jobTypes } = useContext(EmployerGrowContext);

  //{'jobTypeName': count}
  const fetchEmployeeTenure = async () => {
    setIsDataLoading(true);

    const employeeTenureData = await makeQueryFetch(
      {
        query: growApi.query.EMPLOYEE_TENURE_BY_EMPLOYER,
        path: 'employeeTenureByEmployer'
      },
      client
    ).then((data) =>
      // map job type id to job type name
      data
        .map((emp) => ({
          ...emp,
          curJobType: get(jobTypes, `${[emp.curJobTypeId]}.name`, null)
        }))
        // reduce to {[jobTypeName]: [tenureByMonthsToDate]}
        .reduce(
          (acc, emp) => ({
            ...acc,
            [emp.curJobType || emp.currentPosition]: [
              ...(acc[emp.curJobType || emp.currentPosition] || []),
              differenceInCalendarMonths(new Date(), new Date(emp.startDate))
            ]
          }),
          {}
        )
    );
    // format chart data by averaging month values and turning the data into
    // {labels: [label: string], data: [averageMonth: number]}
    const formattedChartData = Object.keys(employeeTenureData).reduce(
      (acc, key) => ({
        labels: [...acc.labels, key],
        data: [...acc.data, calculateAverageTenure(employeeTenureData[key])]
      }),
      { labels: [], data: [] }
    );
    setChartData(formattedChartData);
    setIsDataLoading(false);
  };

  useEffect(() => {
    fetchEmployeeTenure();
  }, []);

  useEffect(() => {
    if (chartCtxRef.current && chartData != null) {
      if (chartRef.current) chartRef.current.destroy();

      chartRef.current = new Chart(chartCtxRef.current, {
        type: 'bar',
        data: {
          ...getBarChartData({ ...chartData, rootLabel: '# of Months' })
        },
        options: {
          indexAxis: 'y',
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        }
      });
    }
  }, [chartCtxRef.current, chartData]);

  return (
    <div className="tenureChartContainer">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Avg Tenure</h4>
      <div className="tenureChartParent">
        <canvas id="tenure-chart" height="225" className="tenureChartCanvas" ref={chartCtxRef} />
      </div>
    </div>
  );
}

export default EmployeeTenure;
