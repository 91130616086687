import React, { useState, useEffect, useCallback } from 'react';
import PT from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { filter, map } from 'lodash';
import { useTimeZone } from 'hooks';
import { APPROVE_JOB, GET_INTERVIEW_SCHEDULE_FOR_JOB } from 'api';

const useSchedule = ({ isOpen = false, jobId, role = 'employee', employeeProfileId }) => {
  // shared logic for <JobCard/> and <EmployeeInterviewScheduleModal/>, <EmployerInterviewScheduleModal/>
  const { timeZone } = useTimeZone();
  const [isScheduleOpen, setIsScheduleOpen] = useState(isOpen);
  const [selectedInterviewDate, setInterviewDate] = useState([null, null]);
  const [inactiveDates, setInactiveDates] = useState([]);
  const isEmployer = role === 'employer';
  const employeeId = isEmployer ? employeeProfileId : localStorage.getItem('profileId');

  const [postInterview, { error: postInterviewError = {}, loading: postInterviewLoading = false }] =
    useMutation(APPROVE_JOB);

  const [
    getSelectedInterviewDate,
    { error: getSelectedInterviewDateError = {}, loading: getSelectedInterviewDateLoading = true }
  ] = useLazyQuery(GET_INTERVIEW_SCHEDULE_FOR_JOB, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      const result = data?.getScheduleTimesForJob || {};

      if (employeeId) {
        const selectedDays = filter(result, (o) => o.employeeProfileId === Number(employeeId));
        const inactiveDays = filter(result, (o) => o.employeeProfileId !== Number(employeeId));
        const inactiveDates = map(inactiveDays, (o) => utcToZonedTime(o.interviewDate, timeZone));

        if (selectedDays?.[0]?.interviewDate) {
          const { interviewDate: fetchedInterviewDate } = selectedDays[0];
          const date = utcToZonedTime(fetchedInterviewDate, timeZone);
          setInterviewDate([date, null]);
        }

        setInactiveDates(inactiveDates);
      }
    }
  });

  useEffect(() => {
    setIsScheduleOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isScheduleOpen) {
      if (jobId && employeeId)
        getSelectedInterviewDate({ variables: { employeeProfileId: Number(employeeId), jobId } });
    }
  }, [isScheduleOpen]);

  const handleInterviewConfirm = useCallback(
    async (date, employerScheduleId, onSuccess) => {
      if (employeeId && jobId && date && employerScheduleId) {
        // In case, if device local time and employee timeZone are different, we need to get proper utc based on zone
        const utcDate = zonedTimeToUtc(date, timeZone);
        await postInterview({
          variables: {
            approved: true,
            passed: false,
            employeeProfileId: Number(employeeId),
            jobId,
            days: [
              {
                employerScheduleId: Number(employerScheduleId),
                interviewDate: utcDate.toISOString()
              }
            ]
          }
        });
        if (onSuccess) onSuccess();
      }
    },
    [jobId]
  );

  const handleSetInterviewDate = useCallback((params) => {
    const val = !params ? [null, null] : [...params];
    setInterviewDate(val);
  }, []);

  return {
    getSelectedInterviewDateLoading,
    handleInterviewConfirm,
    inactiveDates,
    isScheduleOpen,
    postInterviewLoading,
    setIsScheduleOpen,
    selectedInterviewDate,
    setInterviewDate: handleSetInterviewDate
  };
};

useSchedule.propTypes = {
  isOpen: PT.bool,
  jobId: PT.number.isRequired
};

useSchedule.defaultProps = {
  isOpen: false
};

export default useSchedule;
