import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'api/graphql';
import { easePolyOut } from 'd3-ease';
import { Spring, animated } from 'react-spring';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import head from 'lodash/head';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { Box, useMediaQuery } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdClose, MdPlayArrow } from 'components/icons';
import JobCard from 'components/Dashboard/employee/JobCard';
import ConnectionProfile from 'components/Grow/employee/ConnectionProfile';
import ConnectionListDetail from 'components/Grow/employee/ConnectionListDetail';
import ManagedConnections from 'components/Grow/employee/ManagedConnections';
import {
  CAREER_DEV_BY_USER_ID,
  GET_EMPLOYEE_JOBS,
  GET_EMPLOYEES_BY_INDUSTRY_ID,
  GET_EMPLOYEES_BY_JOB_TYPE_ID,
  GET_EMPLOYEE_STARRED,
  POST_EMPLOYEE_STARRED,
  REMOVE_EMPLOYEE_STARRED
} from 'api';

const windowHeight = window.innerHeight;

const getCompanyInfo = (exp) => {
  const companyInfo = {
    companyName: null,
    companyPosition: null
  };
  if (isEmpty(exp)) {
    return companyInfo;
  }

  const currentJob = head(exp.filter((e) => e?.currentJob));
  companyInfo.companyName = currentJob?.name || null;
  companyInfo.companyPosition = currentJob?.pos || null;
  return companyInfo;
};

const fetchConnectionsByJobTypeId = async (jobTypeId) =>
  jobTypeId
    ? client.query({
        query: GET_EMPLOYEES_BY_JOB_TYPE_ID,
        variables: {
          jobTypeId
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      })
    : [];

const fetchConnectionsByIndustryId = async (industryId) =>
  client.query({
    query: GET_EMPLOYEES_BY_INDUSTRY_ID,
    variables: {
      industryId
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

const fetchEmployeeJobs = async () =>
  client.query({
    query: GET_EMPLOYEE_JOBS,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

const formatConnections = (data, key) =>
  get(data, `data.${key}`, []).map((e) => ({
    user_id: e.user_id,
    profile_id: e.profile_id,
    name: e.name,
    imageUrl: e.imageUrl,
    ...getCompanyInfo(e.experience)
  }));

const formatStarredConnections = (data) =>
  get(data, 'data.getEmployeeStarred', [])
    .map(
      ({ employeeProfile = {} }) =>
        employeeProfile && {
          user_id: employeeProfile.user_id,
          profile_id: employeeProfile.profile_id,
          name: employeeProfile.name,
          imageUrl: employeeProfile.imageUrl,
          ...getCompanyInfo(employeeProfile.experience)
        }
    )
    .filter(Boolean);

const filterConnectionsByUnStarred = (starred, connections) =>
  filter(connections, (o) => !find(starred, ['profile_id', o.profile_id]));

const MANAGED_CON_TYPE = 'managed';
const CURRENT_CON_TYPE = 'current';
const FUTURE_CON_TYPE = 'future';

function CareerConnections({ jobTypes, careerDev, ...jobCardProps }) {
  const [sameConnections, setSameConnections] = useState([]);
  const [futureConnections, setFutureConnections] = useState([]);
  const [starredConnections, setStarredConnections] = useState([]);
  // const [managedConnections, setManagedConnections] = useState([]);
  const [token, setToken] = useState(null);
  const [connectionCareerDevelopment, setConnectionCareerDevelopment] = useState(null);
  const [connectionIndex, setConnectionIndex] = useState(null);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [showMobileConnections, setShowMobileConnections] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobCard, setShowJobCard] = useState(false);
  const [jobCardStyles, setJobCardStyles] = useState({});
  const [activeJobIdx, setActiveJobIdx] = useState(null);
  const [[isOnStarLoading, starredProfileIdLoading], setIsOnStarLoading] = useState([false, null]);
  const [showMangedConnections, setShowMangedConnections] = useState(false);

  const getEmployeeJobs = async () => {
    // GET_EMPLOYEE_JOBS
    const localToken = localStorage.getItem('token');
    setToken(localToken);
    const userId = localStorage.getItem('userId');
    if (localToken && !isEmpty(userId)) {
      const jobs = await fetchEmployeeJobs();
      setSuggestedJobs(get(jobs, 'data.employeeJobs'));
    }
  };

  const getConnections = async () => {
    const localToken = localStorage.getItem('token');
    setToken(localToken);
    const userId = localStorage.getItem('userId');
    if (localToken && !isEmpty(userId)) {
      const fetchedSameConnections = await fetchConnectionsByJobTypeId(careerDev.curJobTypeId);
      const fetchedFutureConnections = await fetchConnectionsByJobTypeId(careerDev.futureJobTypeId);
      const fetchedStarredConnections = await getEmployeeStarred(careerDev.id);

      const formattedStarredConnections = formatStarredConnections(fetchedStarredConnections);

      const formattedSameConnections = formatConnections(
        fetchedSameConnections,
        'employeesByJobTypeId'
      );

      const formattedFutureConnections = formatConnections(
        fetchedFutureConnections,
        'employeesByJobTypeId'
      );

      if (formattedFutureConnections.length === 0) {
        // fetch employees by industry because there are none with that job type yet.
        const connectionsByIndustry = await fetchConnectionsByIndustryId(careerDev.industryId);
        const formattedConnections = formatConnections(
          connectionsByIndustry,
          'employeesByIndustryId'
        );
        setFutureConnections(
          filterConnectionsByUnStarred(formattedStarredConnections, formattedConnections)
        );
      } else {
        setFutureConnections(
          filterConnectionsByUnStarred(formattedStarredConnections, formattedFutureConnections)
        );
      }

      setSameConnections(formattedSameConnections);
      setStarredConnections(formattedStarredConnections);
    }
  };

  const getEmployeeStarred = async (careerDevId) => {
    if (careerDevId) {
      try {
        const resp = await client.mutate({
          mutation: GET_EMPLOYEE_STARRED,
          variables: {
            careerDevelopmentId: careerDevId
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        // return resp?.data?.getEmployeeStarred || [];
        return resp;
      } catch (error) {
        console.error('getEmployeeStarred error', error);
      }
    }
  };

  const starConnection = async (connectionProfileId) => {
    if (careerDev.id && connectionProfileId) {
      setIsOnStarLoading([true, connectionProfileId]);
      try {
        const resp = await client.mutate({
          mutation: POST_EMPLOYEE_STARRED,
          variables: {
            careerDevelopmentId: Number(careerDev.id),
            employeeProfileId: Number(connectionProfileId)
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        const fetchedStarredConnections = await getEmployeeStarred(careerDev.id);
        const formattedStarredConnections = formatStarredConnections(fetchedStarredConnections);
        setStarredConnections(formattedStarredConnections);
      } catch (error) {
        console.error('starConnection error: ', error);
      } finally {
        setIsOnStarLoading([false, null]);
      }
    }
  };

  const unStarConnection = async (connectionProfileId) => {
    if (careerDev.id && connectionProfileId) {
      try {
        setIsOnStarLoading([true, connectionProfileId]);
        const resp = await client.mutate({
          mutation: REMOVE_EMPLOYEE_STARRED,
          variables: {
            careerDevelopmentId: Number(careerDev.id),
            employeeProfileId: Number(connectionProfileId)
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        const fetchedStarredConnections = await getEmployeeStarred(careerDev.id);
        const formattedStarredConnections = formatStarredConnections(fetchedStarredConnections);
        setStarredConnections(formattedStarredConnections);
      } catch (error) {
        console.error('unStarConnection error: ', error);
      } finally {
        setIsOnStarLoading([false, null]);
      }
    }
  };

  const openProfile = async (connectionUserId, idx, type) => {
    setConnectionIndex([idx, type]);
    setConnectionLoading(true);
    if (token && connectionUserId) {
      const fetchConnectionCareerDev = await client.query({
        query: CAREER_DEV_BY_USER_ID,
        variables: {
          connectionUserId: Number(connectionUserId)
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      setConnectionCareerDevelopment(
        get(fetchConnectionCareerDev, 'data.connectionCareerDevelopmentByUserId')
      );
      setConnectionLoading(false);
    }
  };

  useEffect(() => {
    getConnections();
    getEmployeeJobs();
  }, []);

  const navNextConnection = async () => {
    const [index, type] = connectionIndex;
    const connections =
      type === MANAGED_CON_TYPE
        ? starredConnections
        : type === CURRENT_CON_TYPE
        ? sameConnections
        : futureConnections;
    const idx = index === connections.length - 1 ? 0 : index + 1;
    const connectionUserId = get(head(connections.slice(idx, idx + 1)), 'user_id');
    await openProfile(connectionUserId, idx, type);
  };
  const navPrevConnection = async () => {
    const [index, type] = connectionIndex;
    const connections =
      type === MANAGED_CON_TYPE
        ? starredConnections
        : type === CURRENT_CON_TYPE
        ? sameConnections
        : futureConnections;
    const idx = index === connections.length - 1 ? index - 1 : connections.length - 1;
    const connectionUserId = get(head(connections.slice(idx, idx + 1)), 'user_id');
    await openProfile(connectionUserId, idx, type);
  };

  const renderManageConnectionsButton = () => (
    <div style={{ paddingLeft: '0.75rem' }}>
      <Button
        variant="text"
        endIcon={<MdPlayArrow color="primary" style={{ fontSize: 10 }} />}
        className="connectionListAction"
        onClick={() => setShowMangedConnections(true)}
        testID="qh-grow-manage-connections-button"
      >
        Manage Connections
      </Button>
    </div>
  );

  const connectionDetail = (
    <div style={{ padding: '1rem 0', overflow: 'auto' }}>
      {isEmpty(futureConnections) && isEmpty(sameConnections) && (
        <>
          <h3 className="connectionListTitle">No Connections Available</h3>
          {isDesktop && renderManageConnectionsButton()}
        </>
      )}
      {!isEmpty(futureConnections) && (
        <>
          <h3 className="connectionListTitle">Suggested Connections</h3>
          {isDesktop && renderManageConnectionsButton()}
          <ConnectionListDetail
            connections={futureConnections}
            openProfile={openProfile}
            onStar={starConnection}
            onUnStar={unStarConnection}
            starredConnections={starredConnections}
            starredProfileIdLoading={starredProfileIdLoading}
            type={FUTURE_CON_TYPE}
            connectionLoading={connectionLoading}
            withHoverActions={isDesktop}
            withStarBtn={!isDesktop}
          />
        </>
      )}
      {!isEmpty(sameConnections) && (
        <>
          <h3 className="connectionListTitle">Connections in Your Position</h3>
          <ConnectionListDetail
            connections={sameConnections}
            openProfile={openProfile}
            onStar={starConnection}
            onUnStar={unStarConnection}
            starredConnections={starredConnections}
            starredProfileIdLoading={starredProfileIdLoading}
            type={CURRENT_CON_TYPE}
            connectionLoading={connectionLoading}
            withHoverActions={isDesktop}
            withStarBtn={!isDesktop}
          />
        </>
      )}
      {isEmpty(futureConnections) && isEmpty(sameConnections) && (
        <Box pl="0.75rem">No Connections at the moment.</Box>
      )}
    </div>
  );

  const renderConnectionDetail = () => {
    if (isDesktop) {
      return connectionDetail;
    }
    return (
      <div>
        {!isEmpty(futureConnections) && (
          <>
            <div className="connectionListTitleContainer">
              <h3 className="connectionListTitle">Suggested Connections</h3>
              <div style={{ marginBottom: 30 }}>
                <Button
                  variant="filled-primary"
                  className="connectionListActions"
                  onClick={() => setShowMobileConnections(true)}
                  testID="qh-grow-see-all-connections-button"
                >
                  See All
                </Button>
                <Button
                  variant="filled-primary"
                  sx={{ marginLeft: '16px' }}
                  className="connectionListActions"
                  onClick={() => setShowMangedConnections(true)}
                  testID="qh-grow-manage-connections-button"
                >
                  Manage
                </Button>
              </div>
            </div>
            <div className="connectionListContainer">
              <ConnectionListDetail
                row
                connections={futureConnections}
                openProfile={openProfile}
                onStar={starConnection}
                onUnStar={unStarConnection}
                starredConnections={starredConnections}
                starredProfileIdLoading={starredProfileIdLoading}
                type={FUTURE_CON_TYPE}
                connectionLoading={connectionLoading}
                withHoverActions={false}
                withStarBtn={false}
                withAvatarStarBtn
              />
              <div className="connectionSpacer" />
            </div>
          </>
        )}
        {showMobileConnections && (
          <Spring
            from={{ top: windowHeight }}
            to={{ top: 20 }}
            // TODO look into this
            leave={{ top: windowHeight }}
            config={{
              duration: 500,
              easing: easePolyOut.exponent(2.0)
            }}
          >
            {(animStyles) => (
              <animated.div className="modalContainer" style={animStyles}>
                {connectionDetail}
                <Button
                  className="modalCloseButton"
                  variant="filled-primary"
                  onClick={() => setShowMobileConnections(false)}
                  testID="qh-grow-connections-close-button"
                >
                  Close
                </Button>
              </animated.div>
            )}
          </Spring>
        )}
      </div>
    );
  };

  const modalHeight = 400;

  const openJob = (e, job, idx) => {
    setSelectedJob(job);
    setShowJobCard(true);
    setActiveJobIdx(idx);
    setJobCardStyles({
      top: e.pageY - modalHeight
    });
  };

  const closeJobCard = () => setSelectedJob(null) || setShowJobCard(false) || setActiveJobIdx(null);

  const closeModal = () => {
    setConnectionCareerDevelopment(null);
    closeJobCard();
  };

  const closeManagedConnections = () => setShowMangedConnections(false);

  return (
    <>
      {showJobCard && (
        <div style={{ height: modalHeight, ...jobCardStyles }} className="jobCardContainer">
          <IconButton
            variant="filled-primary"
            aria-label="Close this Job Card"
            className="profileCloseButton"
            onClick={closeJobCard}
            testID="qh-grow-job-close-button"
          >
            <MdClose />
          </IconButton>
          <JobCard {...jobCardProps} data={selectedJob} />
        </div>
      )}
      {connectionCareerDevelopment && (
        <ConnectionProfile
          employeeCareerDev={connectionCareerDevelopment}
          next={navNextConnection}
          prev={navPrevConnection}
          closeModal={closeModal}
          openJob={openJob}
          onStar={starConnection}
          onUnStar={unStarConnection}
          activeJobIdx={activeJobIdx}
          jobTypes={jobTypes}
          suggestedJobs={suggestedJobs}
          canNavigate={(() => {
            if (connectionIndex[1] === MANAGED_CON_TYPE) return starredConnections.length > 1;
            if (connectionIndex[1] === CURRENT_CON_TYPE) return sameConnections.length > 1;
            return futureConnections.length > 1;
          })()}
          isStarred={
            !!find(starredConnections, [
              'profile_id',
              connectionCareerDevelopment?.employeeProfile?.profile_id
            ])
          }
          isStarredLoading={
            connectionCareerDevelopment?.employeeProfile?.profile_id === starredProfileIdLoading
          }
          starredProfileIdLoading={starredProfileIdLoading}
        />
      )}
      {showMangedConnections && (
        <ManagedConnections
          closeModal={closeManagedConnections}
          isStarredLoading={isOnStarLoading}
          managedConnections={starredConnections}
          onProfileOpen={openProfile}
          onStar={starConnection}
          onUnStar={unStarConnection}
          starredProfileIdLoading={starredProfileIdLoading}
        />
      )}
      {renderConnectionDetail()}
    </>
  );
}

CareerConnections.propTypes = {
  careerDev: PropTypes.objectOf(PropTypes.any),
  jobTypes: PropTypes.arrayOf(PropTypes.any)
};

CareerConnections.defaultProps = {
  careerDev: null,
  jobTypes: []
};

export default CareerConnections;
