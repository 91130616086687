import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { client } from 'api/graphql';
import get from 'lodash/get';
import { emojiRates } from 'utils';
import { useAlerts } from 'hooks';
import { Button } from 'components/shared';
import {
  CREATE_EMPLOYER_RATING_REASONS_TO_EMPLOYER_PROFILE,
  EMPLOYEE_RATING_TO_EMPLOYER_PROFILE,
  RATING_REASONS_TO_EMP_PROFILE_BY_EXPERIENCE_ID
} from 'api';

function ChooseRating({ ratingReasons, exp, ratingRef, reasonRef }) {
  // have to use local state as well as reference so it changes this modal code
  const [rating, setRating] = useState(ratingRef.current);
  const [reasonIds, setReasonIds] = useState(reasonRef.current);

  const handleReasonClick = (id) => (e) => {
    if (reasonIds.includes(id)) {
      // filter out the existing id
      const reasons = [...reasonIds.filter((i) => i !== id)];
      reasonRef.current = reasons;
      setReasonIds(reasons);
    } else {
      const reasons = [...reasonIds, id];
      reasonRef.current = reasons;
      setReasonIds(reasons);
    }
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          padding: '1rem 2.5rem',
          marginTop: '4rem'
        }}
      >
        {emojiRates.map((emoji, idx) => (
          <Button
            key={emoji}
            sx={{
              fontSize: rating - 1 === idx ? 75 : 55,
              opacity: exp.currentJob ? 1 : 0.5
            }}
            disabled={!exp.currentJob}
            onClick={() => {
              ratingRef.current = idx + 1;
              setRating(idx + 1);
            }}
            testID={`emoji-button-${emoji}`}
          >
            {emoji}
          </Button>
        ))}
      </div>
      <div style={{ paddingTop: 35 }}>
        <h3 style={{ paddingBottom: 10 }}>Reason:</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {ratingReasons.map((reason) => (
            <Button
              variant={reasonIds.indexOf(reason.id) > -1 ? 'filled-primary' : 'filled-secondary'}
              key={`${reason.reason}-${reason.id}`}
              sx={{
                marginRight: '10px',
                marginBottom: '10px',
                padding: '10px 15px'
              }}
              onClick={handleReasonClick(reason.id)}
              testID={`reason-button-${reason.reason}`}
            >
              {reason.reason}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}

function RateEmployer({ icon, exp, ratingRef, ratingReasons, renderRateButton }) {
  const { setInputAlert } = useAlerts();
  const ratingToSave = ratingRef;
  const reasonsToSave = useRef([]);
  const [postEmployeeRating, { loading: postEmployeeRatingLoading }] = useMutation(
    EMPLOYEE_RATING_TO_EMPLOYER_PROFILE
  );
  const [postEmployeeReason, { loading: postEmployeeReasonLoading }] = useMutation(
    CREATE_EMPLOYER_RATING_REASONS_TO_EMPLOYER_PROFILE
  );

  const saveRating = async () => {
    if (exp.currentJob) {
      const ratingRes = await postEmployeeRating({
        variables: { score: ratingToSave.current, experienceId: exp.experience_id }
      });
      ratingRef.current = get(ratingRes, 'data.postEmployeeRatingToEmployerProfile.score');
    }
    if (reasonsToSave.current) {
      const reasonRes = await postEmployeeReason({
        variables: {
          reasonIds: reasonsToSave.current,
          experienceId: exp.experience_id
        }
      });
      reasonsToSave.current = get(
        reasonRes,
        'data.createEmployerRatingReasonsToEmployerProfile'
      ).map((reason) => reason.reasonId);
    }
  };

  const doRateEmployer = async () => {
    const reasons = await client.query({
      query: RATING_REASONS_TO_EMP_PROFILE_BY_EXPERIENCE_ID,
      variables: {
        experienceId: exp.experience_id
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    reasonsToSave.current = get(reasons, 'data.ratingReasonsToEmpProfileByExperienceId').map(
      (reason) => reason.reasonId
    );

    setInputAlert({
      isOpen: true,
      title: 'Rate Employer',
      subtitle: 'Choose a rating that reflects your experience with your employer',
      inputs: (
        <ChooseRating
          ratingReasons={ratingReasons}
          exp={exp}
          ratingRef={ratingToSave}
          reasonRef={reasonsToSave}
        />
      ),
      onSuccess: saveRating
    });
  };

  const rateBtnProps = {
    onClick: doRateEmployer
  };

  if (renderRateButton) {
    return renderRateButton(rateBtnProps, icon);
  }

  return (
    <Button
      sx={{
        marginLeft: '10px',
        cursor: 'pointer',
        transform: 'scale(1.5)',
        background: 'transparent'
      }}
      variant="filled-secondary"
      {...rateBtnProps}
    >
      {icon}
    </Button>
  );
}

export default RateEmployer;
