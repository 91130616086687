import React, { useContext, useState, memo } from 'react';
import PT from 'prop-types';
import { qaAttr } from 'utils';
import { MuiButtonBase } from 'components';
import { Alert, Checkmark, Trash } from 'components/icons';
import employerDefault from 'assets/img/employer_default.png';
import EmployerGrowContext from '../../EmployerGrowContext';

function Pill(props) {
  const { empty, idx, isActive, setActivePill, ...pillData } = props;
  const { updateEmployerCtx, getEmployerAnalytic } = useContext(EmployerGrowContext);
  const { analytic = {}, imageUrl = '', name = '' } = pillData;
  const {
    approvedCount = 0,
    clicksCount = 0,
    needActionCount = 0,
    openJobsCount = 0,
    rejectedCount = 0,
    referralCount = 0,
    starredCount = 0,
    totalApplicants = 0,
    viewedCount = 0
  } = analytic;

  if (empty) {
    return <div className="pill empty" />;
  }

  const setPillData = async () => {
    const { jobStatistics = [] } = await getEmployerAnalytic(pillData.user_id);
    setActivePill(idx);
    updateEmployerCtx({
      jobStatistics,
      jobPostingScores: {
        openedJobs: openJobsCount,
        totalApplicants,
        totalStarred: starredCount,
        totalPassed: rejectedCount,
        totalViewed: viewedCount,
        totalReferral: referralCount,
        totalClicks: clicksCount
      }
    });
  };

  return (
    <MuiButtonBase
      className={`pill ${isActive ? 'active' : ''}`}
      sx={{ display: 'block' }}
      onClick={setPillData}
      {...qaAttr(`store-item-${idx}`)}
    >
      <div>
        <div>
          <img src={imageUrl || employerDefault} alt="" className="pillImg" />
        </div>
        <div className="pillStore">
          <div className="pillName">{name}</div>
          <div className="pillApplicants">{totalApplicants} Applicants</div>
          <div>
            <div className="trash">
              <span>{rejectedCount}</span>
              <Trash fill="#CC6A76" />
            </div>
            <div className="check">
              <span>{approvedCount}</span>
              <Checkmark fill="#72C488" />
            </div>
            <div className="needAttention">
              <span>{needActionCount}</span>
              <Alert fill="#49469D" />
            </div>
          </div>
        </div>
      </div>
    </MuiButtonBase>
  );
}

Pill.propTypes = {
  empty: PT.bool,
  idx: PT.number,
  isActive: PT.bool,
  setActivePill: PT.func
};

Pill.defaultProps = {
  empty: false,
  isActive: false,
  idx: 0,
  setActivePill: () => {}
};

export default memo(Pill);
