import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { employerStartPage, employeeStartPage, getRoutes } from 'utils';
import { fetchEmployerProfile, fetchEmployeeProfile } from 'api';
import { Spinner } from 'components/shared';
import { useAuth } from 'hooks';
import { OnboardingLayout } from 'containers';

const ROUTES = getRoutes();

function ProtectedOnboardingRoute() {
  const { authed } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);

  const checkProfileByRole = async () => {
    const [employerProfile] = await fetchEmployerProfile({
      queryParams: { fetchPolicy: 'cache-first' }
    });

    if (employerProfile) {
      if (employerProfile.profileWizard) {
        navigate(employerStartPage);
        return false;
      }
      // identify({ name: profile?.name, email: profile?.email, profileType: role });
      return true;
    }

    const [employeeProfile] = await fetchEmployeeProfile({
      queryParams: { fetchPolicy: 'cache-first' }
    });

    if (employeeProfile) {
      if (employeeProfile.profileWizard) {
        navigate(employeeStartPage);
        return false;
      }
      // identify({ name: profile?.name, email: profile?.email, profileType: role });
      return true;
    }

    return true;
  };

  const authMiddleware = async () => {
    if (authed) {
      const checked = await checkProfileByRole();
      setIsUserRoleChecked(checked);
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <OnboardingLayout>
        <Outlet />
      </OnboardingLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed ? renderContent() : <Navigate to={ROUTES.login.default} />;
}

export default ProtectedOnboardingRoute;
