import React, { forwardRef } from 'react';
import PT from 'prop-types';
import NumberFormat from 'react-number-format';
import Input from './Input';
// import { isUSPhone } from 'utils';

const MaskedNumber = forwardRef((props, ref) => (
  <NumberFormat
    format="1-###-###-####"
    mask="_"
    getInputRef={ref}
    isNumericString // value should be unformatted
    {...props}
  />
));

const PhoneInput = forwardRef(({ value, ...props }, ref) => {
  const val = value ? value.replace(/^1/, '') : ''; // this is to fix typing bug

  return <Input ref={ref} inputComponent={MaskedNumber} value={val} {...props} />;
});

PhoneInput.propTypes = {
  value: PT.string.isRequired
};

export default PhoneInput;
