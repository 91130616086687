import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import clsx from 'clsx';
import { qaAttr } from 'utils';
import { Button, IconButton, Spinner } from 'components/shared';
import { MdAdd, MdStar } from 'components/icons';

const ConnectionListDetail = (props) => {
  const {
    row,
    connections,
    connectionLoading,
    onStar,
    onUnStar,
    openProfile,
    starredConnections,
    starredProfileIdLoading,
    type,
    withHoverActions,
    withStarBtn,
    withAvatarStarBtn
  } = props;

  const handleStar = (e, profileId) => {
    e.stopPropagation();
    onStar(profileId);
  };

  const handleUnStar = (e, profileId) => {
    e.stopPropagation();
    onUnStar(profileId);
  };

  return map(connections, (connection, idx) => {
    const isStarred = find(starredConnections, ['profile_id', connection.profile_id]);
    const isStarLoading = starredProfileIdLoading === connection.profile_id;

    return (
      <div
        key={`${connection.name}-future`}
        className={clsx('connectionDetailContainer', row && 'row')}
        style={{ marginBottom: idx === connections.length - 1 ? 10 : 0 }}
      >
        <div
          className="connectionDetailContainerContent"
          onClick={
            connectionLoading || withHoverActions
              ? null
              : () => openProfile(connection.user_id, idx, type)
          }
          {...qaAttr(`qh-grow-connection-button-${connection.name}`)}
        >
          {connectionLoading && (
            <div className="connectionOverlay">
              <Spinner size={26} />
            </div>
          )}
          <div className="connectionImgWrapper">
            <div className="connectionImgContainer">
              {connection.imageUrl ? (
                <img src={connection.imageUrl} className="connectionImg" alt="" />
              ) : (
                <div className="connectionNoImage">
                  <span>{connection.name.slice(0, 1).toUpperCase()}</span>
                </div>
              )}
            </div>
            {withAvatarStarBtn && (
              <IconButton
                disabled={isStarLoading}
                className={clsx('connectionAvatarStarBtn', isStarred && 'starred')}
                onClick={(e) =>
                  isStarred
                    ? handleUnStar(e, connection.profile_id)
                    : handleStar(e, connection.profile_id)
                }
                testID={`qh-grow-connection-button-star-${connection.name}`}
              >
                {(() => {
                  if (isStarLoading) return <Spinner size={24} />;
                  if (isStarred) return <MdStar color="inherit" fontSize="inherit" />;
                  return <MdAdd color="inherit" fontSize="inherit" />;
                })()}
              </IconButton>
            )}
          </div>
          <div className="connectionDetailsContainer">
            <p className="connection connectionName">{connection.name}</p>
            <p className="connection connectionTitle">
              {connection.companyName}
              {connection.companyPosition ? ` | ${connection.companyPosition}` : ''}
            </p>
          </div>
          {withHoverActions && (
            <div className="connectionDetailActions">
              <Button
                variant="filled-primary"
                className={clsx('connectionDetailAction', isStarred && 'added')}
                testID={`qh-grow-connection-button-add-${connection.name}`}
                onClick={(e) =>
                  isStarred
                    ? handleUnStar(e, connection.profile_id)
                    : handleStar(e, connection.profile_id)
                }
              >
                {(() => {
                  if (isStarLoading) return <Spinner size={24} />;
                  return isStarred ? 'Remove' : 'Add +';
                })()}
              </Button>
              <Button
                variant="filled-primary"
                className="connectionDetailAction"
                sx={{ marginLeft: '12px' }}
                onClick={() => openProfile(connection.user_id, idx, type)}
                testID={`qh-grow-connection-button-view-all-${connection.name}`}
              >
                View
              </Button>
            </div>
          )}
        </div>
        {withStarBtn && (
          <IconButton
            disabled={isStarLoading}
            onClick={(e) =>
              isStarred
                ? handleUnStar(e, connection.profile_id)
                : handleStar(e, connection.profile_id)
            }
            testID={`qh-grow-connection-button-star-${connection.name}`}
          >
            {isStarLoading ? (
              <Spinner width={24} height={24} />
            ) : (
              <MdStar htmlColor={isStarred ? '#FFB810' : '#B5B5B6'} />
            )}
          </IconButton>
        )}
      </div>
    );
  });
};

ConnectionListDetail.propTypes = {
  connections: PropTypes.arrayOf(PropTypes.any),
  openProfile: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  withHoverActions: PropTypes.bool.isRequired
};

export default ConnectionListDetail;
