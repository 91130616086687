import React from 'react';
import { SvgIcon } from 'components';

function MdShare(props) {
  return (
    <SvgIcon viewBox="0 0 20.17 13.37" {...props}>
      <path
        d="M14.2,10.65c-1.33.86-2.64,1.74-4.11,2.72,0-1,0-1.77,0-2.57s-.25-1.3-2-1.37a11.07,11.07,0,0,0-6.71,2.68C1,12.47.54,12.85,0,13.3A9.5,9.5,0,0,1,1.65,8.75c1.93-2.82,3.89-4.52,6-4.8s2.38-.43,2.38-1.17,0-1.74,0-2.78c.32.19.53.32.73.46,2.89,1.92,9.35,6.13,9.35,6.13S16.19,9.34,14.2,10.65Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default MdShare;
