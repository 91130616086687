import React, { useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { getUserId, getLocaleTimeZone } from 'utils';
import { GET_USER_PROFILE, fetchEmployerProfile } from 'api';
import { TimeZoneContext } from 'providers/context';

export default function useTimeZone(props) {
  const { checkByProfile = false, role } = props || {};
  const client = useApolloClient();
  const ctx = useContext(TimeZoneContext);

  const refreshTZ = async (role, userId) => {
    const isEmployer = role === 'employer';
    let profile;

    if (isEmployer) {
      const [p] = await fetchEmployerProfile({
        queryParams: { fetchPolicy: 'cache-first' }
      });
      profile = p;
    } else {
      profile = await client.query({
        query: GET_USER_PROFILE,
        variables: {
          userId: parseInt(userId)
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
    }
    const tz = profile?.data?.[isEmployer ? 'employerProfile' : 'employeeProfile']?.timeZone;
    if (!tz) ctx.updateUserTimeZone(tz);
    else ctx.setTimeZone(getLocaleTimeZone());
  };

  useEffect(() => {
    const userId = getUserId();

    if (checkByProfile && !ctx.timeZone && userId && (role === 'employer' || role === 'employee')) {
      refreshTZ(role, userId);
    }
  }, [checkByProfile]);

  return ctx;
}
