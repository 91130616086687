export default ({ theme }) => ({
  '& .container': {
    position: 'relative',
    maxWidth: 1024,
    margin: '0 auto'
  },
  '& .paper': {
    position: 'absolute',
    right: 0,
    top: 0,
    maxWidth: 414,
    minHeight: 'calc(100vh - 22px)',
    margin: '11px 13px',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      position: 'relative',
      top: 'initial',
      right: 'initial',
      minHeight: 'calc(100% - 50px)',
      maxHeight: 'calc(100% - 50px)',
      width: 'calc(100% - 32px)',
      margin: '20px 16px 15px'
    }
  },
  '& .backdrop': {
    backgroundColor: 'transparent',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  '& .dialogHeader': {
    padding: '13px 13px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    '&.matchesPage': {
      padding: '24px 13px 12px',
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        padding: '5px 17px',
        paddingRight: 53
      }
    },
    '&.chatPage': {
      padding: '13px 17px 8px',
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        padding: '5px 17px'
      }
    }
  },
  '& .dialogTitle': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      fontSize: 16,
      lineHeight: '18px',
      color: '#000'
    },
    '&.emptyRightElement': {
      paddingRight: 20
    }
  },
  '& .dialogCloseBtn': {
    display: 'none',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      display: 'flex'
    }
  },
  '& .headerAction_start': {
    flex: '0 0 44px'
  },
  '& .headerAction_end': {
    flex: '0 0 44px'
  },
  '& .dialogContent': {
    padding: '0 17px 33px',
    flex: '1 1 auto',
    overflowY: 'auto',
    '&.matchesPage': {
      padding: '8px 17px 33px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      [theme.breakpoints.down(theme.desktopBreakPoint)]: {
        padding: '1px 17px 33px'
      }
    },
    '&.chatPage': {
      padding: '8px 17px'
    }
  },
  '& .dialogFooter': {
    padding: 17,
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .matchItemBtn': {
    width: '100%',
    padding: '12px 0',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      padding: '14px 0 12px'
    }
  },
  '& .userPhoto': {
    minWidth: 76,
    height: 76,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#F6F5FA',
    borderRadius: '50%'
  },
  '& .secondaryText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    color: '#000'
  },
  '& .mainText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: 0,
    color: '#000',
    opacity: 0.4
  },
  '& .counter': {
    width: 23,
    height: 23,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    '& > span': {
      marginTop: 2
    }
  },
  '& .chatInput': {
    '&::placeholder': {
      color: '#000',
      opacity: 0.5,
      fontSize: 14
    }
  },
  '& .chatSendBtn': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    color: theme.palette.primary.main
  },
  '& .chatDate': {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    color: '#000',
    opacity: 0.4
  },
  '& .chatMessage': {
    maxWidth: 258,
    padding: '11px 9px 11px 13px',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0,
    color: '#000',
    borderRadius: 4,
    backgroundColor: '#F4F2F2',
    wordBreak: 'break-word'
  },
  '& .my_message': {
    backgroundColor: '#ECECF5'
  },
  '& .chatAddresseePhoto': {
    minWidth: 32,
    height: 32,
    marginTop: 6,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#F6F5FA',
    borderRadius: '50%'
  },
  '& .jobLink': {
    padding: 10,
    paddingRight: 12,
    backgroundColor: '#ECECF5'
  },
  '& .jobPhoto': {
    minWidth: 44,
    height: 44,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#F6F5FA',
    borderRadius: 6
  },
  '& .matchOptionsPaper': {
    minWidth: 255
  },
  '& .matchOptionsBtn': {
    width: '100%',
    height: 55,
    extAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: 0,
    color: '#000',
    '&.Mui-disabled': {
      opacity: 0.5
    }
  },
  '& .tipMessage': {
    margin: '16px 0',
    width: '100%',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    backgroundColor: '#ECECF5',
    borderRadius: 10
  },
  '& .tipMessage__text': {
    marginLeft: 14,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0,
    color: '#000'
  },
  '& .messageInputRoot': {
    padding: 0,
    border: 'none'
  },
  '& .messageInput': {
    padding: 0,
    lineHeight: '24px'
  },
  '& .emptyMatchesMessage': {
    width: '100%',
    maxWidth: 260,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '22px',
    color: 'rgba(0,0,0,0.4)'
  },
  '& .templateMessageModal__title': {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold'
  },
  '& .templateMessageModal__message': {
    marginBottom: 20,
    padding: 8,
    borderRadius: 6,
    backgroundColor: '#ECECF5'
  },
  '& .templateMessageModal__cancel': {
    padding: '6px 12px'
  },
  '& .templateMessageModal__confirm': {
    marginLeft: 10,
    padding: '6px 12px'
  }
});
