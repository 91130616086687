import { gql } from '@apollo/client';

export const GET_EMPLOYER_PROFILE = gql`
  query employerProfile($userId: Int!) {
    employerProfile(userId: $userId) {
      address
      allowPlan
      allowJobPosting
      createdAt
      city
      countryId
      email
      imageUrl
      industry {
        id: industry_id
        name
      }
      jobCount
      name
      newSubscriptionPlan {
        autorenew
        endDate
        id
        name
        paid
        paused
        pausedDate
        planEmployerId
        price
        restrictions
        startDate
        status
      }
      phone
      profile_id
      profileWizard
      restrictions
      regStep
      size
      subscriptionPlan {
        autorenew
        endDate
        id
        name
        paid
        paused
        pausedDate
        planEmployerId
        price
        restrictions
        startDate
        status
      }
      stateId
      timeZone
      trialTimePlan
      user_id
      website
      zip
      updatedAt
      showPlanModal
      inappNewApplicants
      emailNewApplicants
      inappInboxMessages
      emailInboxMessages
      inappTimeRespondEnds
      emailTimeRespondEnds
    }
  }
`;
