import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { styled, useMediaQuery } from 'components';
import CareerDevSetup from 'components/Grow/employee/CareerDevSetup';
import { Spinner } from 'components/shared';
import {
  client,
  GET_CAREER_DEVELOPMENT_WITH_HEALTH_SCORE,
  GET_JOB_TYPES_BY_INDUSTRY,
  SAVE_INITIAL_CAREER_DEV
} from 'api';
import { getRoutes } from 'utils/routes';
import styles from 'styles/Grow/employee';
import CareerHealthScore from './CareerHealthScore';
import CareerConnections from './Connections';
import CareerDevContent from './CareerDevContent';

const ROUTES = getRoutes();

const StyledRoot = styled('div')(styles);

function CareerDevelopment(parentProps) {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const location = useLocation();
  const [careerDev, setCareerDev] = useState(null);
  const [jobTypes, setJobTypes] = useState([]);
  const [careerDevIsLoading, setCareerDevIsLoading] = useState(true);
  const [careerDevUpdateIsLoading, setCareerDevUpdateIsLoading] = useState(true);
  const [isGrowSetupFailed, setIsGrowSetupFailed] = useState(false);
  const getCareerDevObservable = useRef();

  const fetchJobTypesByIndustry = async (industryId) => {
    const token = localStorage.getItem('token');

    if (token && industryId != null) {
      const industryResp = await client.query({
        query: GET_JOB_TYPES_BY_INDUSTRY,
        variables: { industryId: Number(industryId) },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      setJobTypes(get(industryResp, 'data.jobTypesByIndustryId'));
    }
  };

  const getCareerDevData = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (getCareerDevObservable.current && token && userId) {
      return getCareerDevObservable.current.refetch({ userId: Number(userId) });
    }
  };

  const getCareerDevWithSubscription = () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && !isEmpty(userId)) {
      // every changes in cached data will cause rerender
      const observable = client.watchQuery({
        query: GET_CAREER_DEVELOPMENT_WITH_HEALTH_SCORE,
        variables: { userId: Number(userId) },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      });
      getCareerDevObservable.current = observable;
      return observable.subscribe(
        ({ data, loading }) => {
          if (!loading) {
            const careerDevData = data.careerDevelopmentByUserId;
            setCareerDev(careerDevData);
            setCareerDevIsLoading(false);
            setCareerDevUpdateIsLoading(false);
            if (get(careerDevData, 'industryId') != null) {
              fetchJobTypesByIndustry(careerDevData.industryId);
            }
          }
        },
        (error) => console.error('getCareerDevWithSubscription error:', error),
        () => console.log('Finished')
      );
    }
  };

  useEffect(() => {
    const subscription = getCareerDevWithSubscription();
    return () => {
      if (subscription) subscription.unsubscribe();
    };
    // if (location.pathname === ROUTES.employee.grow) {
    //   (async () => {
    //     const token = localStorage.getItem('token');
    //     const userId = localStorage.getItem('userId');

    //     if (token && !isEmpty(userId)) {
    //       const response = await client.query({
    //         query: GET_CAREER_DEVELOPMENT_WITH_HEALTH_SCORE,
    //         variables: { userId: Number(userId) },
    //         fetchPolicy: 'no-cache',
    //         errorPolicy: 'all'
    //       });
    //       const careerDevData = response?.data?.careerDevelopmentByUserId;
    //       setCareerDev(careerDevData);
    //       setCareerDevIsLoading(false);
    //       setCareerDevUpdateIsLoading(false);
    //       if (get(careerDevData, 'industryId') != null) {
    //         fetchJobTypesByIndustry(careerDevData.industryId);
    //       }
    //     }
    //   })();
    // }
  }, [location]);

  const saveInitialCareerDev = async ({
    curJobTypeId,
    futureJobTypeId,
    industryId,
    currentlySearching = false,
    showEmployerData = true
  }) => {
    const payload = {
      curJobTypeId: !Number(curJobTypeId) ? null : Number(curJobTypeId),
      futureJobTypeId: Number(futureJobTypeId),
      industryId: Number(industryId),
      currentlySearching,
      showEmployerData
    };
    setIsGrowSetupFailed(false);
    setCareerDevIsLoading(true);
    try {
      const careerDevResp = await client.mutate({
        mutation: SAVE_INITIAL_CAREER_DEV,
        variables: payload
      });
      if (get(careerDevResp, 'data.postCareerDevelopment.id')) {
        await getCareerDevData();
      } else {
        throw new Error('There was an error creating your career development portal');
      }
    } catch (error) {
      console.error(error);
      setIsGrowSetupFailed(true);
      setCareerDevIsLoading(false);
    }
  };

  if (careerDevIsLoading) {
    return (
      <StyledRoot
        className="pageContainer"
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <Spinner size={26} />
      </StyledRoot>
    );
  }

  if (careerDev == null) {
    return (
      <CareerDevSetup
        saveInitialCareerDev={saveInitialCareerDev}
        isSetupFailed={isGrowSetupFailed}
      />
    );
  }

  return (
    <StyledRoot className="pageContainer">
      <CareerHealthScore careerDev={careerDev} />
      {!isEmpty(jobTypes) && (
        <CareerDevContent
          careerDev={careerDev}
          jobTypes={jobTypes}
          isLoading={careerDevUpdateIsLoading}
          saveCareerDev={(props) => {
            setCareerDevUpdateIsLoading(true);
            saveInitialCareerDev(props);
          }}
          renderConnections={() => (
            <CareerConnections {...parentProps} jobTypes={jobTypes} careerDev={careerDev} />
          )}
        />
      )}
    </StyledRoot>
  );
}

export default CareerDevelopment;
