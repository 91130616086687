import ComfortaaLightTtf from 'assets/fonts/Comfortaa-Light.ttf';
import ComfortaaRegularTtf from 'assets/fonts/Comfortaa-Regular.ttf';
import ComfortaaMediumTtf from 'assets/fonts/Comfortaa-Medium.ttf';
import ComfortaaSemiBoldTtf from 'assets/fonts/Comfortaa-SemiBold.ttf';
import ComfortaaBoldTtf from 'assets/fonts/Comfortaa-Bold.ttf';

const comfortaaLightTtf = `
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Comfortaa'), local('Comfortaa-Light'), url(${ComfortaaLightTtf}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;
const comfortaaRegularTtf = `
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    src: local('Comfortaa'), local('Comfortaa-Regular'), url(${ComfortaaRegularTtf}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;
const comfortaaMediumTtf = `
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Comfortaa'), local('Comfortaa-Medium'), url(${ComfortaaMediumTtf}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;
const comfortaaSemiBoldTtf = `
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Comfortaa'), local('Comfortaa-SemiBold'), url(${ComfortaaSemiBoldTtf}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;
const comfortaaBoldTtf = `
  @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    src: local('Comfortaa'), local('Comfortaa-Bold'), url(${ComfortaaBoldTtf}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`;

export default [
  comfortaaLightTtf,
  comfortaaRegularTtf,
  comfortaaMediumTtf,
  comfortaaSemiBoldTtf,
  comfortaaBoldTtf
];
