import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation logIn(
    $email: String!
    $password: String
    $googleToken: String
    $fbToken: String
    $appleToken: String
  ) {
    logIn(
      email: $email
      password: $password
      googleToken: $googleToken
      fbToken: $fbToken
      appleToken: $appleToken
    ) {
      token
      userId
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp(
    $email: String
    $password: String
    $googleToken: String
    $fbToken: String
    $appleToken: String
    $planId: Int
  ) {
    signUp(
      email: $email
      password: $password
      googleToken: $googleToken
      fbToken: $fbToken
      appleToken: $appleToken
      profileType: "employee"
      planId: $planId
    ) {
      token
      userId
    }
  }
`;

export const ASK_PASSWORD_RESET = gql`
  mutation postEmployeeResetPasswordLinkWeb($email: String!) {
    postEmployeeResetPasswordLinkWeb(email: $email) {
      email
    }
  }
`;

export const POST_NEW_PASSWORD = gql`
  mutation postEmployeeResetPassword($token: String!, $password: String!) {
    postEmployeeResetPassword(token: $token, password: $password) {
      email
    }
  }
`;

export const POST_VERIFY = gql`
  mutation postEmployeeVerify($token: String!) {
    postEmployeeVerify(token: $token) {
      token
      userId
    }
  }
`;

export const SET_TIMEZONE = gql`
  mutation setUserTimeZone($user_id: Int!, $timeZone: String!) {
    setUserTimeZone(user_id: $user_id, timeZone: $timeZone) {
      user_id
    }
  }
`;

export const RESEND_VERIFICATION_MAIL = gql`
  mutation resendVerificationLink($email: String!) {
    resendVerificationLink(email: $email) {
      email
    }
  }
`;
