import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import TeamChartColumn from './ChartColumn';

const TeamChartColumns = ({ columns, chartId }) =>
  columns.map((column, idx) => (
    <Droppable
      key={`${chartId}_${columns[idx].id}`}
      droppableId={JSON.stringify({ index: column.index, id: column.id })}
    >
      {(provided) => (
        <div ref={provided.innerRef} className="chartColumnContainer" {...provided.droppableProps}>
          <TeamChartColumn {...column} />
        </div>
      )}
    </Droppable>
  ));

TeamChartColumns.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any)
};

export default TeamChartColumns;
